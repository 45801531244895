import { ReactElement, useState } from 'react'
import { Assembly, WarehouseId } from '../../../models/models'
import InspectAssemblyModal from '../../modals/inspect-assembly-modal/InspectAssemblyModal'
import {
    Box,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import Card from '../../ui/card/Card'
import CardHeader from '../../ui/card/CardHeader'
import CardRow from '../../ui/card/CardRow'
import CardFooter from '../../ui/card/CardFooter'
import { styled } from '@mui/styles'
import moment from 'moment'
import Button from '@mui/material/Button'
import UpdateAssemblyModal from '../UpdateAssemblyModal'
import { toastWarn } from '../../../util/toast'
import { getAbsentDaySubtractedMinutes } from '../util'
import { useSchedule } from '../../../util/production-schedule'
import { Moment } from 'moment'
import { TaskItem } from '../../worksheets/tasks/TaskBar'
import GeneralAssemblyCard from './GeneralAssemblyCard'

interface Props {
    warehouseId: WarehouseId
    date: Moment
}

const HeaderLeft = styled(Box)({
    width: '33%',
})

const HeaderMiddle = styled(Box)({
    width: '33%',
})

const HeaderRight = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    widtth: '33%',
})

const AssemblyCardContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '50px',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
})

const TaskCardContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '50px',
    flexWrap: 'wrap',
    gap: '100px',
})

const GeneralAssemblyCardContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '50px',
    flexWrap: 'wrap',
    gap: '100px',
})

const Container = styled(Box)({})

const StatusChip = (assembly: Assembly): React.ReactElement => {
    enum BackgroundColor {
        Done = '#DCF7E9',
        NotDone = '#FFF7E6',
    }

    enum BorderColor {
        Done = '#00898A',
        NotDone = '#FA8C16',
    }

    enum Color {
        Done = '#00898A',
        NotDone = '#FA8C16',
    }

    enum Label {
        Done = 'Done',
        NotDone = 'Not done',
    }

    let backgroundColor: string
    let borderColor: string
    let color: string
    let label: string

    if (assembly.completedAt) {
        backgroundColor = BackgroundColor.Done
        borderColor = BorderColor.Done
        color = Color.Done
        label = Label.Done
    } else {
        backgroundColor = BackgroundColor.NotDone
        borderColor = BorderColor.NotDone
        color = Color.NotDone
        label = Label.NotDone
    }

    return (
        <Chip
            sx={{ color, whiteSpace: 'normal', flexWrap: 'wrap', borderColor, backgroundColor }}
            label={label}
            variant="outlined"
        />
    )
}

enum ActiveModal {
    None = 'None',
    Amend = 'Amend',
    Inspect = 'Inspect',
}

export default function ProductionScheduleSingleDateView({
    warehouseId,
    date,
}: Props): ReactElement {
    const [activeModal, setActiveModal] = useState<ActiveModal>(ActiveModal.None)
    const [modalAssembly, setModalAssembly] = useState<Assembly>()

    const { schedule } = useSchedule({ date, warehouseId, withDelayed: false })

    const openAmendModal = (assembly: Assembly) => {
        setActiveModal(ActiveModal.Amend)
        setModalAssembly(assembly)
    }

    const openInspectModal = (assembly: Assembly) => {
        setActiveModal(ActiveModal.Inspect)
        setModalAssembly(assembly)
    }

    const closeModal = () => {
        setActiveModal(ActiveModal.None)
        setModalAssembly(undefined)
    }

    return (
        <Container>
            {activeModal === ActiveModal.Amend && modalAssembly && (
                <UpdateAssemblyModal
                    onClose={closeModal}
                    assemblyId={modalAssembly.id}
                    visible={true}
                    onOk={() => null}
                />
            )}
            {activeModal === ActiveModal.Inspect && modalAssembly && (
                <InspectAssemblyModal
                    assemblyId={modalAssembly.id}
                    closeModal={closeModal}
                    isOpen={true}
                />
            )}
            <Table aria-label="simple table" style={{ minWidth: '400px' }}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                                Assembler
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                                Deducted minutes
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                                Reason
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {schedule?.absentDays.map((absence) => {
                        return (
                            <TableRow key={absence.id}>
                                <TableCell>{`${absence?.daysOff?.user?.firstName} ${absence?.daysOff?.user?.lastName}`}</TableCell>
                                <TableCell>{getAbsentDaySubtractedMinutes(absence)}</TableCell>
                                <TableCell>{absence.daysOff.type}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            <TaskCardContainer>
                {schedule?.tasks.map((t) => (
                    <TaskItem task={t} key={t.id} />
                ))}
            </TaskCardContainer>
            <GeneralAssemblyCardContainer>
                {schedule?.generalAssemblies.map((ga) => (
                    <GeneralAssemblyCard generalAssembly={ga} key={ga.id} />
                ))}
            </GeneralAssemblyCardContainer>
            <AssemblyCardContainer>
                {schedule?.assemblies
                    .sort((a: Assembly, b: Assembly) => a.id - b.id)
                    .map((assembly) => {
                        return (
                            <Box key={assembly.id} sx={{ marginBottom: '20px' }}>
                                <Card key={assembly.id}>
                                    <CardHeader>
                                        <HeaderLeft>{`id: ${assembly.id}`}</HeaderLeft>
                                        <HeaderMiddle>
                                            {(assembly.isOnHold || assembly.callOff.isOnHold) && (
                                                <Chip
                                                    sx={{
                                                        color: '#ff1a1a',
                                                        whiteSpace: 'normal',
                                                        flexWrap: 'wrap',
                                                        borderColor: '#ff1a1a',
                                                        backgroundColor: '#ffcccc',
                                                    }}
                                                    label="Is on hold"
                                                    variant="outlined"
                                                />
                                            )}
                                        </HeaderMiddle>
                                        <HeaderRight>{StatusChip(assembly)}</HeaderRight>
                                    </CardHeader>
                                    <CardRow
                                        descriptor="No. of Units to build"
                                        value={assembly.amount.toString()}
                                    />
                                    <CardRow
                                        descriptor="Project"
                                        value={assembly.callOff?.project?.name}
                                    />
                                    <CardRow
                                        descriptor="Project Managers"
                                        value={assembly.callOff?.project?.projectManagers
                                            ?.map((pm) => `${pm.firstName} ${pm.lastName}`)
                                            .join(', ')}
                                    />
                                    <CardRow
                                        descriptor="BOM code"
                                        value={String(
                                            assembly.builtItemCallOff.sopBuiltItemOrderline
                                                ?.builtItem?.bomCode
                                        )}
                                    />
                                    <CardRow
                                        descriptor="Estimated time"
                                        value={(() => {
                                            const assemblyMinutes =
                                                assembly.builtItemCallOff.sopBuiltItemOrderline
                                                    ?.builtItem?.assemblyMinutes
                                            if (assemblyMinutes === undefined) {
                                                return 'Cannot calculate'
                                            }
                                            const timeEstimate = assemblyMinutes * assembly.amount
                                            return `${timeEstimate} minutes`
                                        })()}
                                    />
                                    <CardRow
                                        descriptor="Date to be completed by"
                                        value={moment(assembly.dateToBeCompletedBy).format(
                                            'DD-MM-YYYY'
                                        )}
                                    />
                                    <CardFooter>
                                        {assembly.completedAt === null &&
                                            !assembly.isOnHold &&
                                            !assembly.callOff.isOnHold && (
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => {
                                                        if (assembly.isOnHold) {
                                                            toastWarn(
                                                                'Cannot amend an assembly when it is on hold'
                                                            )
                                                            return
                                                        }
                                                        if (assembly.callOff.isOnHold) {
                                                            toastWarn(
                                                                'Cannot amend an assembly when the call off is on hold'
                                                            )
                                                            return
                                                        }
                                                        openAmendModal(assembly)
                                                    }}
                                                >
                                                    Amend
                                                </Button>
                                            )}
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                openInspectModal(assembly)
                                            }}
                                        >
                                            Inspect
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Box>
                        )
                    })}
            </AssemblyCardContainer>
        </Container>
    )
}
