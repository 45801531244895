import { OrderType, PurchaseOrder, WarehouseId } from '../models/models'
import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'

export enum GetManyPurchaseOrderRelation {
    Orderlines = 'Orderlines',
    OrderConfirmations = 'OrderConfirmations',
    Deliveries = 'Deliveries',
}

export enum PurchaseOrderDocumentStatus {
    EnumDocumentStatusComplete = 'EnumDocumentStatusComplete',
    EnumDocumentStatusOnHold = 'EnumDocumentStatusOnHold',
    EnumDocumentStatusLive = 'EnumDocumentStatusLive',
}

export interface getPurchaseOrdersQueryParams {
    skip?: number
    relations?: GetManyPurchaseOrderRelation[]
    limit?: number
    completed?: boolean
    activated?: boolean
    withCount?: boolean
    supplier?: string
    createdBy?: string
    isActivated?: boolean
    status?: PurchaseOrderDocumentStatus
    likeDocumentNo?: string
    warehouseId?: WarehouseId
}

export const getPurchaseOrdersRequest = (
    queryParams: getPurchaseOrdersQueryParams
): Promise<Response<{ count?: number; purchaseOrders: PurchaseOrder[] }>> =>
    backendConnection.request({
        url: `/purchase-orders`,
        method: 'GET',
        params: queryParams,
    })

export const getPurchaseOrderCreatorsRequest = (): Promise<Response<string[]>> =>
    backendConnection.request({
        url: `/purchase-orders/creators`,
        method: 'GET',
    })

export const getPurchaseOrderSuppliersRequest = (): Promise<Response<string[]>> =>
    backendConnection.request({
        url: `/purchase-orders/suppliers`,
        method: 'GET',
    })

export enum GetPurchaseOrderRelation {
    Orderlines = 'Orderlines',
    OrderConfirmations = 'OrderConfirmations',
    Deliveries = 'Deliveries',
}

export interface GetPurchaseOrderDTO {
    relations?: GetPurchaseOrderRelation[]
    withAmounts: boolean
}

export const getPurchaseOrderByIdRequest = (
    id: number,
    dto: GetPurchaseOrderDTO
): Promise<Response<PurchaseOrder>> =>
    backendConnection.request({
        url: `/purchase-orders/${id}`,
        method: 'GET',
        params: dto,
    })

export interface ActivatePurchaseOrderBody {
    supplierDispatchDate?: Date
    expectedDeliveryDate: Date
    orderType: OrderType
    warehouseId: number
}

export const activatePurchaseOrderRequest = (
    id: number,
    dto: ActivatePurchaseOrderBody
): Promise<Response<PurchaseOrder>> =>
    backendConnection.request({
        url: `/purchase-orders/activate/${id}`,
        method: 'PUT',
        data: dto,
    })
