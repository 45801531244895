import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { ComponentsVariants } from '@mui/material/styles/variants'
import { BorderColors } from './colors'

export const InputThemeStyles: {
    styleOverrides: ComponentsOverrides['MuiInput']
    variants: ComponentsVariants['MuiInput']
} = {
    styleOverrides: {
        root: {
            height: 45,
            border: `1px solid ${BorderColors.lighterGrey}`,
            padding: '0 14px',
            '&:active': {
                border: `2px solid ${BorderColors.purple}`,
            },
            '&:hover': {
                border: `1px solid ${BorderColors.black}`,
            },
            '&.Mui-error': {
                border: `1px solid ${BorderColors.red}`,
            },
            '&.Mui-focused': {
                border: `2px solid ${BorderColors.purple}`,
            },
            '&.Mui-error.Mui-focused': {
                border: `2px solid ${BorderColors.red}`,
            },
            '&.MuiInputBase-multiline': {
                padding: 14,
            },
            '&:before': {
                display: 'none',
            },
            '&:after': {
                display: 'none',
            },
        },
    },

    variants: [
        {
            props: { color: 'error' },
            style: {
                borderColor: BorderColors.black,
            },
        },
    ],
}
