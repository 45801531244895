import moment from 'moment'

export const setGlobalConfiguration = () => {
    //antd Calendar will start the week from Monday
    moment.updateLocale('en', {
        week: {
            dow: 1,
        },
    })
}
