import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import HoverFilter from './HoverFilter'
import {
    setTypeFilter,
    setUserFilter,
    typeFilterOptionsView,
    userFilterOptionsView,
} from '../../../../store/project-documents-slice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { ProjectDocument } from '../../../../models/models'
import { useState } from 'react'
import DocumentTableRow from './DocumentTableRow'
import SingleProjectViewNoData from '../SingleProjectViewNoData'

interface Props {
    documents: ProjectDocument[]
}

function DocumentTable(props: Props) {
    const dispatch = useAppDispatch()
    const typeFilterOptions = useAppSelector(typeFilterOptionsView)
    const userFilterOptions = useAppSelector(userFilterOptionsView)
    const typeFilter = useAppSelector((state) => state.projectDocuments.typeFilter)
    const userFilter = useAppSelector((state) => state.projectDocuments.userFilter)
    const user = useAppSelector((state) => state.authentication.user)!

    const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({})

    return (
        <>
            <Table sx={{ width: '100%', border: 'none' }}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                Type{' '}
                                <HoverFilter
                                    selected={typeFilter}
                                    onChange={(selected) => dispatch(setTypeFilter(selected))}
                                    options={typeFilterOptions.map((type) => ({
                                        id: type.id,
                                        label: type.name,
                                    }))}
                                />
                            </Box>
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Document</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Submit</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                User{' '}
                                <HoverFilter
                                    selected={userFilter}
                                    onChange={(selected) => {
                                        dispatch(setUserFilter(selected))
                                    }}
                                    options={userFilterOptions.map((user) => ({
                                        id: user.id,
                                        label: `${user.firstName} ${user.lastName}`,
                                    }))}
                                />
                            </Box>
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Comment</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.documents.map((document) => {
                        return document.versions.map((documentVersion, i) => {
                            const rowIsExpanded = expanded[document.id]

                            if (!rowIsExpanded && i > 0) {
                                return null
                            }

                            return (
                                <DocumentTableRow
                                    key={documentVersion.id}
                                    isFirstVersion={i === 0}
                                    hasManyVersions={document.versions.length > 1}
                                    isExpanded={rowIsExpanded}
                                    document={document}
                                    documentVersion={documentVersion}
                                    user={user}
                                    setExpanded={(newState) =>
                                        setExpanded({
                                            ...expanded,
                                            [document.id]: newState,
                                        })
                                    }
                                />
                            )
                        })
                    })}
                </TableBody>
            </Table>
            {!props.documents.length && <SingleProjectViewNoData />}
        </>
    )
}

export default DocumentTable
