import { styled } from '@mui/material'
import { GeneralAssembly } from '../../../../models/models'
import { toastFailure } from '../../../../util/toast'

interface Props {
    generalAssembly: GeneralAssembly
}

const Container = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 15px;
`

const HeaderItem = styled('div')``

const Bold = styled('span')`
    font-weight: 500;
    margin-right: 10px;
`

function ActiveGeneralAssemblyTableHeader({ generalAssembly }: Props) {
    return (
        <Container>
            <HeaderItem>
                <Bold>General Assembly ID:</Bold>
                {generalAssembly.id}
            </HeaderItem>

            {generalAssembly?.builtItem?.instructionLink && (
                <HeaderItem
                    sx={{ cursor: 'pointer', color: '#3500A0', textDecoration: 'underline' }}
                    onClick={() => {
                        if (generalAssembly?.builtItem?.instructionLink) {
                            toastFailure('not implemented yet')
                        }
                    }}
                >
                    <Bold>Show instructions</Bold>
                </HeaderItem>
            )}
        </Container>
    )
}

export default ActiveGeneralAssemblyTableHeader
