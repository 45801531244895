import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { forgotPasswordRequest } from '../api/auth'
import { AuthenticationStage } from './authentication-slice'

export interface ForgotPasswordStoreState {
    state: AuthenticationStage
    email: string | null
    error: any
}

const forgotPasswordInitialState: ForgotPasswordStoreState = {
    email: null,
    state: AuthenticationStage.Initial,
    error: null,
}

export const forgotPassword = createAsyncThunk('forgotPassword', async (email: string) => {
    return await forgotPasswordRequest(email)
})

export const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState: forgotPasswordInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(forgotPassword.fulfilled, (state, action) => {
            if (action.payload.successful) {
                state.state = AuthenticationStage.Initial
            } else {
                state.state = AuthenticationStage.ServerError
            }
        })
        builder.addCase(forgotPassword.pending, (state) => {
            state.state = AuthenticationStage.Loading
        })
        builder.addCase(forgotPassword.rejected, (state, action) => {
            state.state = AuthenticationStage.ServerError
            state.error = action.error
        })
    },
})

export default forgotPasswordSlice.reducer
