import { Notification, NotificationType } from '../models/models'
import backendConnection from './util/backend-connection'
import { Response } from './util/with-response-formatter-interceptor'

export class GetNotificationsQueryParamsDTO {
    //defaults to 0
    skip?: number
    //defaults to 50
    limit?: number
    userId?: number
    type?: NotificationType
    onlyUnseen?: boolean
    onlyUnreacted?: boolean
}
export const getNotificationsRequest = (
    dto: GetNotificationsQueryParamsDTO
): Promise<Response<{ notifications: Notification[]; count: number }>> => {
    return backendConnection.request({
        method: 'GET',
        url: '/notifications',
        params: dto,
    })
}

export interface SetSeenNotificationsDTO {
    notificationIds: number[]
}

export const setSeenNotificationsRequest = (
    dto: SetSeenNotificationsDTO
): Promise<Response<{ notifications: Notification[]; count: number }>> => {
    return backendConnection.request({
        method: 'PUT',
        url: '/notifications/seen',
        data: dto,
    })
}

export interface SetReactedNotificationsDTO {
    notificationIds: number[]
}

export const setReactedNotificationsRequest = (
    dto: SetReactedNotificationsDTO
): Promise<Response<{ notifications: Notification[]; count: number }>> => {
    return backendConnection.request({
        method: 'PUT',
        url: '/notifications/react',
        data: dto,
    })
}
