import { OrderConfirmation } from '../../../models/models'
import OrderConfirmationOrderlineTable from '../../tables/OrderConfirmationOrderlineTable'

interface Props {
    orderConfirmation: OrderConfirmation
}

export default function OrderlinesTab({ orderConfirmation }: Props) {
    return <OrderConfirmationOrderlineTable orderConfirmation={orderConfirmation} />
}
