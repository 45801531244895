import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { AccountMemo } from '../../../../api/customers'
import moment, { Moment } from 'moment'
import { Button } from 'antd'

const Container = styled(Box)({})

interface MemoProps {
    onNextStep: () => void
    accountMemos: AccountMemo[]
}

export default function Delivery(props: MemoProps) {
    const { onNextStep, accountMemos } = props
    return (
        <Container>
            <Box
                sx={{
                    marginTop: '30px',
                    padding: '0px 15px 15px 0px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button type="primary" onClick={onNextStep}>
                    Continue
                </Button>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Created by</TableCell>
                        <TableCell>Created at</TableCell>
                        <TableCell>Note</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accountMemos
                        .sort(
                            (accountMemo1, accountMemo2) =>
                                moment(accountMemo2.dateTimeCreated).unix() -
                                moment(accountMemo1.dateTimeCreated).unix()
                        )
                        .map((accountMemo) => {
                            return (
                                <TableRow key={accountMemo.id}>
                                    <TableCell>{accountMemo.createdBy}</TableCell>
                                    <TableCell>
                                        {moment(accountMemo.dateTimeCreated).format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell>{accountMemo.note}</TableCell>
                                    <TableCell>{}</TableCell>
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
        </Container>
    )
}
