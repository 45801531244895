import backendConnection from './util/backend-connection'
import { Response } from './util/with-response-formatter-interceptor'

export enum SopOrderlineType {
    Bom = 'Bom',
    StockItem = 'StockItem',
    Charge = 'Charge',
    FreeText = 'FreeText',
    Comment = 'Comment',
}

export interface ImportSageSalesOrderDTO {
    salesOrderId: number
    type: SopOrderlineType
}

export const deleteSopOrderlineRequest = (
    sopOrderlineId: number,
    type: SopOrderlineType
): Promise<Response<void>> =>
    backendConnection.request({
        url: `/sop-orderlines/${type}/${sopOrderlineId}`,
        method: 'DELETE',
    })
