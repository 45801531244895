import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { addAssembly } from './assembly-worksheets-slice'
import { toastFailure, toastSuccess } from '../util/toast'
import { AssemblyLine } from '../models/models'
import { returnWorksheetLineRequest, reworkWorksheetLineRequest } from '../api/assembly-line'

export enum SerialLogView {
    TABLE_VIEW = 'TABLE_VIEW',
    GRID_VIEW = 'GRID_VIEW',
}

interface State {
    activeView: SerialLogView
}

const initialState: State = {
    activeView: SerialLogView.GRID_VIEW,
}

export const serialLogSlice = createSlice({
    name: 'serialLog',
    initialState,
    reducers: {
        setActiveView(state, action: PayloadAction<SerialLogView>) {
            state.activeView = action.payload
        },
    },
})

export const { setActiveView } = serialLogSlice.actions

export const startReworking = (item: AssemblyLine) => async (dispatch: Dispatch) => {
    const response = await reworkWorksheetLineRequest(item)
    if (!response.successful) {
        toastFailure('An error occurred while reworking the item.')
        return
    }

    await dispatch(addAssembly(response.data))
    toastSuccess('Successfully marked the item reworked.')
}

export const confirmReturn =
    (item: AssemblyLine, comment: string) => async (dispatch: Dispatch) => {
        const response = await returnWorksheetLineRequest(item, comment)

        if (!response.successful) {
            toastFailure('An error occurred while returning the item.')
            return
        }

        await dispatch(addAssembly(response.data))
        toastSuccess('Successfully returned the item.')
    }

export default serialLogSlice.reducer
