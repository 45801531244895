import { TaskType, TaskDepartment, WarehouseId } from '../models/models'
import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'

export enum TaskTypeRelation {
    CreatedBy = 'CreatedBy',
}

export interface GetTaskTypesDto {
    relations?: TaskTypeRelation[]
    department?: TaskDepartment
    withDeleted?: boolean
    ids?: number[]
    warehouseId?: number
    skip?: number
    limit?: number
}

export const getTaskTypeByIdRequest = (
    id: number,
    dto: GetTaskTypesDto
): Promise<Response<TaskType>> =>
    backendConnection.request({
        url: `/task-types/${id}`,
        params: dto,
        method: 'GET',
    })

export interface UpdateTaskTypeDto {
    title?: string
    description?: string
    link?: string
    department?: TaskDepartment
    minutes?: number
    warehouseId?: number
}

export const updateTaskTypeRequest = (
    id: number,
    dto: UpdateTaskTypeDto
): Promise<Response<TaskType>> =>
    backendConnection.request({
        url: `/task-types/${id}`,
        data: dto,
        method: 'PATCH',
    })

export const getTaskTypesRequest = (
    dto: GetTaskTypesDto
): Promise<Response<{ entities: TaskType[]; count: number }>> =>
    backendConnection.request({
        url: `/task-types`,
        params: dto,
        method: 'GET',
    })

export const deleteTaskTypesRequest = (taskTypeId: number): Promise<Response<TaskType>> =>
    backendConnection.request({
        url: `/task-types/${taskTypeId}`,
        method: 'DELETE',
    })

export interface CreateTaskTypeDto {
    department: TaskDepartment
    title: string
    description: string
    warehouseId: WarehouseId
    link: string
    minutes: number
}

export const createTaskTypeRequest = (dto: CreateTaskTypeDto): Promise<Response<TaskType>> =>
    backendConnection.request({
        url: `/task-types`,
        method: 'POST',
        data: dto,
    })
