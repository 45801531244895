import { useContext, useEffect, useState } from 'react'
import { Box, styled } from '@mui/system'
import { Button, Input, Select, Table } from 'antd'
import {
    getStockItemsRequest,
    pinStockItemRequest,
    getProductGroupsRequest,
    ProductGroup,
} from '../../../../../../api/stock-item'
import { Product, UserRole } from '../../../../../../models/models'
import AddedItemsOverview, { AddedItemsTabKeys } from '../../AddedItemsOverview'
import { Grid } from '@mui/material'
import { CartItemsContext } from '../../../project-info/CartItems'
import AddStockItemModal from './AddStockItemModal'
import { CreateProductModal } from '../../../../../products/modals/CreateProductModal'
import { PushpinFilled, PushpinOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import { useAppSelector } from '../../../../../../store/hooks'

const InputsContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
}))

enum ActiveModal {
    None = 'None',
    AddStockItem = 'AddStockItem',
    Create = 'Create',
}

interface AmountStockInCart {
    [key: number]: number
}

export default function StockItems() {
    const [stockItems, setStockItems] = useState<Product[]>([])
    const { user } = useAppSelector((state) => state.authentication)
    const isValidPinRole =
        user?.role === UserRole.SavAdmin ||
        user?.role === UserRole.SystemAdmin ||
        user?.role === UserRole.ApplicationEngineer
    const { addedStockItems, setAddedStockItems } = useContext(CartItemsContext)
    const amountStockInCart: AmountStockInCart = addedStockItems.reduce(
        (a, c) => ({
            ...a,
            [c.id]: c.amount,
        }),
        {}
    )
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)
    //is not used?
    const [isUpdatingPin, setIsUpdatingPin] = useState(false)
    const [activeModal, setActiveModal] = useState<ActiveModal>(ActiveModal.None)
    const [code, setCode] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [stockItemsCount, setStockItemsCount] = useState<number>(0)
    const [modalAddStockItemId, setModalAddStockItemId] = useState<number>()
    const [productGroups, setProductGroups] = useState<ProductGroup[]>([])
    const [selectedProductGroupCode, setSelectedProductGroupCode] = useState<string>()

    const retrieveAndSetStockItems = async () => {
        const response = await getStockItemsRequest({
            limit: rowsPerPage,
            likeCode: code,
            likeName: name,
            userId: user?.id,
            productGroupCode: selectedProductGroupCode,
            //antd tables pages are 1-indexed
            skip: page * rowsPerPage - rowsPerPage,
            withCount: true,
        })

        if (response.successful) {
            setStockItems(response.data.entities)
            setStockItemsCount(response.data.count || response.data.entities.length)
        }
    }

    const retrieveAndSetProductGroups = async () => {
        const response = await getProductGroupsRequest()
        if (response.successful) {
            setProductGroups(response.data)
        }
    }

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const onPinStockItem = async (
        event:
            | React.MouseEvent<HTMLAnchorElement, MouseEvent>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>,
        stockItem: Product
    ) => {
        event.preventDefault()
        const response = await pinStockItemRequest(stockItem.id)
        if (response.successful) {
            const updatedItems = stockItems.map((item) => {
                if (item.id === stockItem.id) {
                    return response.data
                }
                return item
            })

            setStockItems(updatedItems)
        } else {
            toast.error('Something went wrong!')
        }
    }

    useEffect(() => {
        retrieveAndSetProductGroups()
    }, [])

    useEffect(() => {
        retrieveAndSetStockItems()
    }, [code, name, selectedProductGroupCode, rowsPerPage, page])

    const addProductsColumns = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            width: '220px',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '275px',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '350px',
            key: 'name',
        },
        {
            title: 'Add',
            key: 'add',
            width: '200px',
            render: (stockItem: Product) => (
                <>
                    <Button
                        style={{ marginRight: '10px' }}
                        type="primary"
                        onClick={() => openAddStockItemModal(stockItem.id)}
                    >
                        Add
                    </Button>
                    <Button
                        shape="circle"
                        icon={
                            stockItem.pinnedByUsers.find((u) => u.id === user?.id) ? (
                                <PushpinFilled />
                            ) : (
                                <PushpinOutlined />
                            )
                        }
                        onClick={(event) => onPinStockItem(event, stockItem)}
                        disabled={!isValidPinRole || isUpdatingPin}
                    ></Button>
                </>
            ),
        },
    ]

    const openAddStockItemModal = (stockItemId: number) => {
        setActiveModal(ActiveModal.AddStockItem)
        setModalAddStockItemId(stockItemId)
    }

    const openCreateStockItemModal = () => {
        setActiveModal(ActiveModal.Create)
    }

    const closeModal = () => {
        setActiveModal(ActiveModal.None)
    }

    const addUpdateStockItem = (stockItem: Product, amount: number) => {
        const item = { ...stockItem, amount }
        if (amountStockInCart[item.id]) {
            setAddedStockItems(addedStockItems.map((x) => (x.id === item.id ? item : x)))
            return
        }
        setAddedStockItems([...addedStockItems, item])
    }

    return (
        <Grid container justifyContent="space-around" spacing={1} sx={{ paddingTop: '26px' }}>
            <Grid item>
                <div>
                    <InputsContainer>
                        <Input
                            prefix={'code'}
                            size="middle"
                            style={{
                                padding: 10,
                                borderRadius: '10px',
                                width: '30%',
                            }}
                            value={code}
                            onChange={(e) => {
                                setPage(1)
                                setCode(e.target.value)
                            }}
                        />
                        <Input
                            prefix={'name'}
                            size="middle"
                            style={{
                                padding: 10,
                                borderRadius: '10px',
                                width: '30%',
                            }}
                            value={name}
                            onChange={(e) => {
                                setPage(1)
                                setName(e.target.value)
                            }}
                        />
                        <Select
                            placeholder={'Product Group'}
                            value={selectedProductGroupCode}
                            onChange={(value) => setSelectedProductGroupCode(value)}
                            size={'large'}
                            allowClear={true}
                            style={{ width: 250 }}
                            optionFilterProp={'children'}
                        >
                            {productGroups.map((productGroup) => (
                                <Select.Option key={productGroup.code} value={productGroup.code}>
                                    {productGroup.description}
                                </Select.Option>
                            ))}
                        </Select>
                    </InputsContainer>
                    <Table
                        className="product-tables"
                        size="small"
                        dataSource={stockItems}
                        columns={addProductsColumns}
                        pagination={{
                            defaultPageSize: 10,
                            current: page,
                            onChange: pageChange,
                            total: stockItemsCount,
                        }}
                    />
                </div>
            </Grid>
            <Grid item>
                <AddedItemsOverview tab={AddedItemsTabKeys.StockItems} />
            </Grid>

            {activeModal === ActiveModal.AddStockItem && modalAddStockItemId && (
                <AddStockItemModal
                    onClose={closeModal}
                    isOpen={true}
                    stockItemId={modalAddStockItemId}
                    onConfirm={(stockItem: Product, amount: number) => {
                        addUpdateStockItem(stockItem, amount)
                    }}
                />
            )}
            {activeModal === ActiveModal.Create && (
                <CreateProductModal onClose={closeModal} isOpen={true} />
            )}
        </Grid>
    )
}
