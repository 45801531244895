import { Button, Input, Table, Typography } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, Paper, IconButton, Divider, Stepper, Step, StepButton, Alert } from '@mui/material'
import { toastFailure, toastSuccess, toastWarn } from '../../../../util/toast'
import {
    getPurchaseOrderByIdRequest,
    GetPurchaseOrderRelation,
} from '../../../../api/purchase-orders'
import {
    OrderConfirmationOrderline,
    Project,
    PurchaseOrder,
    PurchaseOrderOrderline,
} from '../../../../models/models'
import CTRLoader from '../../../ui/loader/CTRloader'
import { useAppSelector } from '../../../../store/hooks'
import OrderConfirmationsTable, {
    OrderConfirmationsTableColumns,
} from '../../../tables/OrderConfirmationsTable'
import {
    CreateDeliveryDTO,
    createDeliveryRequest,
    CreateOcAndDeliveryDTO,
    createOcAndDeliveryRequest,
    DeliveryPalletDto,
} from '../../../../api/logistics-worksheet'
import {
    GetOrderConfirmationRelations,
    getOrderConfirmationsRequest,
} from '../../../../api/order-confirmation'
import { getProjectByIdRequest, getProjectsRequest } from '../../../../api/projects/projects'
import { SearchInput } from '../../../ui/SearchInput'
import { Label } from '../../../ui/Label'
import { FieldContainer } from '../../../ui/FIeldContainer'

const Container = styled('div')({
    margin: '25px',
})

const Text = styled(Typography)(() => ({}))

const RowContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
})

interface ModalProps {
    active: boolean
    purchaseOrderId: number
    onOk: () => void
    onClose: () => void
}

enum PurchaseOrderState {
    Unused = 'Unused',
    Loading = 'Loading',
    Found = 'Found',
    NotFound = 'NotFound',
    Error = 'Error',
}

export enum ModalStep {
    Select,
    Products,
    Pallets,
    Confirm,
}

export enum OrderConfirmationScope {
    Create,
    Existing,
}

interface AmountInputs {
    [orderlineId: number]: string
}

interface Pallet {
    [orderlineId: number]: string
}

type Orderline = PurchaseOrderOrderline | OrderConfirmationOrderline

type OrderlineWithPallets = Orderline & {
    pallets: Pallet[]
}
const isPurchaseOrderOrderline = (
    orderline: OrderConfirmationOrderline | PurchaseOrderOrderline
): orderline is PurchaseOrderOrderline => {
    return (orderline as PurchaseOrderOrderline).lineType !== undefined
}

const isOrderConfirmationOrderline = (
    orderline: OrderConfirmationOrderline | PurchaseOrderOrderline
): orderline is OrderConfirmationOrderline => {
    return (orderline as PurchaseOrderOrderline).lineType === undefined
}

export default function BookInModal({ active, onOk, onClose, purchaseOrderId }: ModalProps) {
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>()
    const [purchaseOrderState, setPurchaseOrderState] = useState<PurchaseOrderState>(
        PurchaseOrderState.Unused
    )
    const [selectedProject, setSelectedProject] = useState<Project>()
    const [activeStep, setActiveStep] = useState<ModalStep>(ModalStep.Select)
    const [ocScope, setOcScope] = useState<OrderConfirmationScope>(OrderConfirmationScope.Existing)
    const { user: currentUser } = useAppSelector((state) => state.authentication)
    const [amountInputs, setAmountInputs] = useState<AmountInputs>({})
    //create oc specifc
    const [code, setCode] = useState<string>('')
    //update oc specific
    const [orderConfirmationId, setOrderConfirmationId] = useState<number>()
    const [pallets, setPallets] = useState<Pallet[]>([])

    const palletsAllocatedProductAmounts = useMemo((): { [orderlineId: number]: number } => {
        const amounts: { [orderlineId: number]: number } = {}
        for (const pallet of pallets) {
            for (const entry of Object.entries(pallet)) {
                const [orderlineId, amount] = entry
                if (amounts[Number(orderlineId)]) {
                    amounts[Number(orderlineId)] += Number(amount)
                    continue
                }
                amounts[Number(orderlineId)] = Number(amount)
            }
        }

        return amounts
    }, [pallets, purchaseOrder])

    const isPalletsValid = useMemo(() => {
        //check if any pallets are empty
        for (const pallet of pallets) {
            if (Object.keys(pallet).length <= 0) {
                return false
            }
        }
        //check if allocated amount on pallets are larger than the incoming OC
        for (const entry of Object.entries(amountInputs)) {
            const [orderlineId, orderlineAmount] = entry
            const allocatedPalletsAmount = palletsAllocatedProductAmounts[Number(orderlineId)]
            if (allocatedPalletsAmount > Number(orderlineAmount)) {
                return false
            }
        }
        return true
    }, [pallets, amountInputs])

    const selectedOrderConfirmation = useMemo(() => {
        if (!orderConfirmationId) {
            return
        }
        return purchaseOrder?.orderConfirmations.find((o) => o.id === orderConfirmationId)
    }, [orderConfirmationId])

    const createAmountInputs = () => {
        if (!purchaseOrder) {
            return
        }
        if (ocScope === OrderConfirmationScope.Create) {
            setAmountInputs(
                purchaseOrder.orderlines
                    .filter((o) => o.product)
                    .reduce<AmountInputs>((acc, cur) => {
                        acc[cur.id] = String(cur.lineQuantity - cur.allocatedAmount)
                        return acc
                    }, {})
            )
        }
        if (ocScope === OrderConfirmationScope.Existing) {
            if (!selectedOrderConfirmation) {
                return
            }
            setAmountInputs(
                selectedOrderConfirmation.orderlines.reduce<AmountInputs>((acc, cur) => {
                    acc[cur.id] = String(cur.amount - cur.deliveredAmount)
                    return acc
                }, {})
            )
        }
    }
    useEffect(() => {
        if (activeStep === ModalStep.Select) {
            setOcScope(OrderConfirmationScope.Existing)
            setOrderConfirmationId(undefined)
            setCode('')
            setAmountInputs({})
            setPallets([])
        }
        if (activeStep === ModalStep.Products) {
            //create amount inputs - only happens when navigating from select to products
            if (Object.keys(amountInputs).length === 0) {
                createAmountInputs()
            }
            setPallets([])
        }
    }, [activeStep])

    const close = () => {
        setPurchaseOrder(undefined)
        onClose()
    }

    if (!currentUser) {
        toastFailure('Current user is not present')
        close()
    }

    const createDelivery = async () => {
        if (!purchaseOrder) {
            return
        }

        if (ocScope === OrderConfirmationScope.Existing) {
            if (!selectedOrderConfirmation) {
                toastWarn('Something went wrong in the front end, finding the selected orderline')
                return
            }
            const body: CreateDeliveryDTO = {
                products: [],
                pallets: [],
            }
            Object.entries(amountInputs).forEach((amountInput) => {
                // eslint-disable-next-line prefer-const
                const [orderlineId, amount] = amountInput

                if (Number(amount) > 0) {
                    body?.products.push({
                        orderlineId: Number(orderlineId),
                        amount: Number(amount),
                    })
                }
            })

            for (const pallet of pallets) {
                console.log('selectedProject', selectedProject)
                const bodyPallet: DeliveryPalletDto = {
                    products: [],
                    projectId: selectedProject?.id,
                }

                for (const entry of Object.entries(pallet)) {
                    const [orderlineId, amount] = entry

                    const orderConfirmationOrderline = selectedOrderConfirmation?.orderlines.find(
                        (o) => {
                            return o.id === Number(orderlineId)
                        }
                    )
                    const orderline = purchaseOrder.orderlines.find((o) => {
                        return o.id === orderConfirmationOrderline?.purchaseOrderOrderlineId
                    })
                    if (!orderline?.productId) {
                        toastFailure('Could not find product on PO for pallet')
                        return
                    }

                    orderline?.productId
                    bodyPallet.products.push({
                        id: orderline.productId,
                        amount: Number(amount),
                    })
                }
                body.pallets.push(bodyPallet)
            }

            const response = await createDeliveryRequest(selectedOrderConfirmation?.id, body)
            if (response.successful) {
                toastSuccess('Recorded the delivery')
                onOk()
                close()
            } else {
                toastFailure(response.message)
                return
            }
        }
        if (ocScope === OrderConfirmationScope.Create) {
            if (!code) {
                toastWarn('The order confirmation code is required')
                return
            }

            const body: CreateOcAndDeliveryDTO = {
                products: [],
                pallets: [],
                code,
            }

            Object.entries(amountInputs).forEach((amountInput) => {
                // eslint-disable-next-line prefer-const
                let [orderlineId, amount] = amountInput
                amount = Number(amount)

                if (Number(amount) > 0) {
                    body?.products.push({ orderlineId: Number(orderlineId), amount })
                }
            })

            for (const pallet of pallets) {
                const bodyPallet: DeliveryPalletDto = {
                    products: [],
                    projectId: selectedProject?.id,
                }

                for (const entry of Object.entries(pallet)) {
                    const [orderlineId, amount] = entry

                    const orderline = purchaseOrder.orderlines.find((o) => {
                        return o.id === Number(orderlineId)
                    })

                    if (!orderline?.productId) {
                        toastFailure('Could not find product on PO for pallet')
                        return
                    }

                    orderline?.productId
                    bodyPallet.products.push({
                        id: orderline.productId,
                        amount: Number(amount),
                    })
                }
                body.pallets.push(bodyPallet)
            }

            const response = await createOcAndDeliveryRequest(purchaseOrderId, body)
            if (response.successful) {
                toastSuccess('Recorded the delivery')
                onOk()
                close()
            } else {
                toastFailure(response.message)
                return
            }
        }
    }

    const getPurchaseOrder = async () => {
        if (!currentUser) {
            return
        }
        setPurchaseOrderState(PurchaseOrderState.Loading)
        const response = await getPurchaseOrderByIdRequest(purchaseOrderId, {
            relations: [
                GetPurchaseOrderRelation.Deliveries,
                GetPurchaseOrderRelation.OrderConfirmations,
                GetPurchaseOrderRelation.Orderlines,
            ],
            withAmounts: true,
        })
        if (response.successful) {
            if (!response.data.activatedDate) {
                setPurchaseOrderState(PurchaseOrderState.Error)
                toastFailure('The purchase order has not been activated')
                return
            }
            setPurchaseOrder(response.data)
            const amountInputs: AmountInputs = response.data.orderlines
                .filter((o) => o.product)
                .reduce<AmountInputs>((acc, cur) => {
                    acc[cur.id] = ''
                    return acc
                }, {})
            setAmountInputs(amountInputs)
            setPurchaseOrderState(PurchaseOrderState.Found)
        } else if (response.status === 404) {
            toastFailure(response.message)
            setPurchaseOrderState(PurchaseOrderState.NotFound)
        } else {
            setPurchaseOrderState(PurchaseOrderState.Error)
            toastFailure(response.message)
        }
    }

    useEffect(() => {
        getPurchaseOrder()
    }, [])

    const productsColumns = [
        {
            title: 'code',
            key: 'code',
            render: (orderline: Orderline) => {
                if (isPurchaseOrderOrderline(orderline)) {
                    return orderline?.product?.code
                } else {
                    return orderline.purchaseOrderOrderline?.product?.code
                }
            },
        },
        {
            title: 'description',
            key: 'description',
            render: (orderline: Orderline) => {
                if (isPurchaseOrderOrderline(orderline)) {
                    return orderline?.description
                } else {
                    return orderline.purchaseOrderOrderline?.description
                }
            },
        },
        {
            title: 'Amount Ordered',
            key: 'Amount',
            render: (orderline: Orderline) => {
                if (isPurchaseOrderOrderline(orderline)) {
                    return orderline.lineQuantity
                } else {
                    return orderline.amount
                }
            },
        },
        {
            title: () => {
                if (ocScope === OrderConfirmationScope.Create) {
                    return 'Allocated amount'
                }
                if (ocScope === OrderConfirmationScope.Existing) {
                    return 'Delivered amount'
                }
            },
            key: 'AlreadyConsumed',
            render: (orderline: Orderline) => {
                if (isPurchaseOrderOrderline(orderline)) {
                    return `${orderline.allocatedAmount}/${orderline.lineQuantity}`
                } else {
                    return `${orderline.deliveredAmount}/${orderline.amount}`
                }
            },
        },
        {
            title: 'Amount on OC',
            key: 'AmountOnOc',
            render: (orderline: Orderline) => {
                if (isPurchaseOrderOrderline(orderline)) {
                    if (orderline.lineType !== 'EnumLineTypeStandard') {
                        return 'Not a product'
                    }
                    return (
                        <Input
                            type="number"
                            value={amountInputs[orderline.id]}
                            disabled={
                                orderline?.allocatedAmount === orderline.lineQuantity &&
                                (amountInputs[orderline.id] === '' ||
                                    amountInputs[orderline.id] === '0')
                            }
                            onChange={(e) => {
                                const clone = { ...amountInputs }
                                if (orderline?.allocatedAmount === undefined) {
                                    return
                                }
                                if (Number(e.target.value) < 0) {
                                    e.target.value = '0'
                                }
                                if (e.target.value === '') {
                                    clone[orderline.id] = e.target.value
                                    setAmountInputs(clone)
                                    return
                                }
                                const newValue = Number(e.target.value)
                                if (newValue > orderline.lineQuantity - orderline.allocatedAmount) {
                                    clone[orderline.id] = String(
                                        orderline.lineQuantity - orderline.allocatedAmount
                                    )
                                } else {
                                    clone[orderline.id] = newValue.toString()
                                }
                                setAmountInputs(clone)
                            }}
                        />
                    )
                } else {
                    if (orderline.purchaseOrderOrderline.lineType !== 'EnumLineTypeStandard') {
                        return 'Not a product'
                    }
                    return (
                        <Input
                            type="number"
                            value={amountInputs[orderline.id]}
                            disabled={
                                orderline?.deliveredAmount === orderline.amount &&
                                (amountInputs[orderline.id] === '' ||
                                    amountInputs[orderline.id] === '0')
                            }
                            onChange={(e) => {
                                const clone = { ...amountInputs }
                                if (orderline?.deliveredAmount === undefined) {
                                    return
                                }
                                if (Number(e.target.value) < 0) {
                                    e.target.value = '0'
                                }
                                if (e.target.value === '') {
                                    clone[orderline.id] = e.target.value
                                    setAmountInputs(clone)
                                    return
                                }
                                const newValue = Number(e.target.value)
                                if (newValue > orderline.amount - orderline.deliveredAmount) {
                                    clone[orderline.id] = String(
                                        orderline.amount - orderline.deliveredAmount
                                    )
                                } else {
                                    clone[orderline.id] = newValue.toString()
                                }
                                setAmountInputs(clone)
                            }}
                        />
                    )
                }
            },
        },
    ]

    const palletColumns = useMemo(() => {
        const columns: any[] = [
            {
                title: 'code',
                key: 'code',
                render: (orderline: OrderlineWithPallets) => {
                    if (isPurchaseOrderOrderline(orderline)) {
                        return orderline?.product?.code
                    } else {
                        return orderline.purchaseOrderOrderline?.product?.code
                    }
                },
            },
            {
                title: 'Amount on PO',
                key: 'Amount',
                render: (orderline: OrderlineWithPallets) => {
                    if (isPurchaseOrderOrderline(orderline)) {
                        return orderline.lineQuantity
                    } else {
                        return orderline.purchaseOrderOrderline.lineQuantity
                    }
                },
            },
            {
                title: 'OC amount',
                key: 'OCAmount',
                render: (orderline: OrderlineWithPallets) => {
                    return amountInputs[orderline.id]
                },
            },
        ]

        pallets.forEach((pallet, i) => {
            columns.push({
                title: (
                    <div style={{ paddingTop: '10px', display: 'flex', flexDirection: 'row' }}>
                        {`Pallet-${i + 1}`}
                        <div
                            style={{ paddingLeft: '20px', paddingBottom: '5px', cursor: 'pointer' }}
                            onClick={() => {
                                const copy = [...pallets]
                                copy.splice(i, 1)
                                setPallets(copy)
                            }}
                        >
                            <CloseIcon />
                        </div>
                    </div>
                ),
                key: `Pallet-${i}`,
                render: (orderline: OrderlineWithPallets) => {
                    const maxAvailableValue =
                        Number(amountInputs[orderline.id]) -
                        pallets.reduce((total, pallet, index) => {
                            if (index !== i && pallet[orderline.id]) {
                                total += Number(pallet[orderline.id])
                            }
                            return total
                        }, 0)

                    if (isPurchaseOrderOrderline(orderline)) {
                        if (orderline.lineType !== 'EnumLineTypeStandard') {
                            return 'Not a product'
                        }

                        return (
                            <Input
                                type="number"
                                value={pallets[i][orderline.id]}
                                disabled={
                                    orderline?.allocatedAmount === orderline.lineQuantity ||
                                    (maxAvailableValue <= 0 &&
                                        (!pallets[i][orderline.id] ||
                                            pallets[i][orderline.id] === '' ||
                                            pallets[i][orderline.id] === '0'))
                                }
                                onChange={(e) => {
                                    const clone = [...pallets]
                                    const newValue = e.target.value
                                    //this value

                                    if (orderline?.allocatedAmount === undefined) {
                                        return
                                    }
                                    if (newValue === '') {
                                        clone[i][orderline.id] = newValue
                                        setPallets(clone)
                                        return
                                    }
                                    if (Number(newValue) > maxAvailableValue) {
                                        clone[i][orderline.id] = String(maxAvailableValue)
                                    } else {
                                        clone[i][orderline.id] = newValue.toString()
                                    }
                                    setPallets(clone)
                                }}
                            />
                        )
                    } else {
                        if (orderline.purchaseOrderOrderline.lineType !== 'EnumLineTypeStandard') {
                            return 'Not a product'
                        }

                        return (
                            <Input
                                type="number"
                                value={pallets[i][orderline.id]}
                                disabled={
                                    orderline?.deliveredAmount === orderline.amount ||
                                    (maxAvailableValue <= 0 &&
                                        (!pallets[i][orderline.id] ||
                                            pallets[i][orderline.id] === '' ||
                                            pallets[i][orderline.id] === '0'))
                                }
                                onChange={(e) => {
                                    const clone = [...pallets]
                                    const newValue = e.target.value

                                    if (orderline?.deliveredAmount === undefined) {
                                        return
                                    }
                                    if (Number(e.target.value) < 0) {
                                        e.target.value = '0'
                                    }
                                    if (e.target.value === '') {
                                        clone[i][orderline.id] = e.target.value
                                        setPallets(clone)
                                        return
                                    }
                                    if (Number(newValue) > maxAvailableValue) {
                                        clone[i][orderline.id] = String(maxAvailableValue)
                                    } else {
                                        clone[i][orderline.id] = newValue.toString()
                                    }
                                    setPallets(clone)
                                }}
                            />
                        )
                    }
                },
            })
        })
        return columns
    }, [pallets, amountInputs])

    const confirmStepColumns = [
        {
            title: 'code',
            key: 'code',
            render: (orderline: Orderline) => {
                if (isPurchaseOrderOrderline(orderline)) {
                    return orderline?.product?.code
                } else {
                    return orderline.purchaseOrderOrderline?.product?.code
                }
            },
        },
        {
            title: 'description',
            key: 'description',
            render: (orderline: Orderline) => {
                if (isPurchaseOrderOrderline(orderline)) {
                    return orderline?.product?.description
                } else {
                    return orderline.purchaseOrderOrderline?.product?.description
                }
            },
        },
        {
            title: 'Amount on PO',
            key: 'Amount',
            render: (orderline: Orderline) => {
                if (isPurchaseOrderOrderline(orderline)) {
                    return orderline.lineQuantity
                } else {
                    return orderline.purchaseOrderOrderline.lineQuantity
                }
            },
        },
        {
            title: 'OC amount',
            key: 'OCAmount',
            render: (orderline: Orderline) => {
                return amountInputs[orderline.id]
            },
        },
        {
            title: () => {
                if (ocScope === OrderConfirmationScope.Create) {
                    return 'PO orderline allocated post delivery'
                }
                if (ocScope === OrderConfirmationScope.Existing) {
                    return 'PO orderline delivered post delivery'
                }
            },
            key: 'After allocation',
            render: (orderline: Orderline) => {
                if (isPurchaseOrderOrderline(orderline)) {
                    if (orderline?.lineQuantity !== undefined) {
                        return `${
                            Number(orderline.allocatedAmount) + Number(amountInputs[orderline.id])
                        }/${orderline.lineQuantity}`
                    }
                    return 'Indecisive'
                } else {
                    const purchaseOrderOrderline = purchaseOrder?.orderlines.find(
                        (o) => o.id === orderline.purchaseOrderOrderlineId
                    )
                    if (!purchaseOrderOrderline) {
                        toastWarn('System could not find PO orderline')
                        return 'Could not find PO orderline'
                    }
                    return `${
                        Number(purchaseOrderOrderline.deliveredAmount) +
                        Number(amountInputs[orderline.id])
                    }/${purchaseOrderOrderline.lineQuantity}`
                }
            },
        },
    ]

    return (
        <Dialog open={active} onClose={close} fullWidth sx={{ zIndex: '100' }} maxWidth={'lg'}>
            <Paper>
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        Book in
                        {purchaseOrder?.documentNo && ` for ${purchaseOrder?.documentNo}`}{' '}
                    </div>
                    <IconButton aria-label="close" onClick={close}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider />
                <Container>
                    {purchaseOrderState === PurchaseOrderState.Unused && <></>}
                    {purchaseOrderState === PurchaseOrderState.Loading && <></>}
                    {purchaseOrderState === PurchaseOrderState.Error && (
                        <>Something went wrong finding the purchase order</>
                    )}
                    {purchaseOrderState === PurchaseOrderState.NotFound && (
                        <>Could not find the purchase order</>
                    )}
                    {purchaseOrderState === PurchaseOrderState.Loading && (
                        <>
                            <CTRLoader />
                        </>
                    )}
                    {purchaseOrder && purchaseOrderState === PurchaseOrderState.Found && (
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Stepper
                                    activeStep={activeStep}
                                    nonLinear
                                    orientation="horizontal"
                                    style={{ width: '500px', marginBottom: '20px' }}
                                >
                                    <Step>
                                        <StepButton onClick={() => setActiveStep(ModalStep.Select)}>
                                            <Typography>Select or create OC</Typography>
                                        </StepButton>
                                    </Step>
                                    <Step>
                                        <StepButton
                                            disabled={activeStep === ModalStep.Select}
                                            onClick={() => setActiveStep(ModalStep.Products)}
                                        >
                                            <Typography>Products</Typography>
                                        </StepButton>
                                    </Step>
                                    <Step>
                                        <StepButton
                                            disabled={activeStep === ModalStep.Select}
                                            onClick={() => setActiveStep(ModalStep.Pallets)}
                                        >
                                            <Typography>Pallets</Typography>
                                        </StepButton>
                                    </Step>
                                    <Step>
                                        <StepButton>
                                            <Typography>Confirm</Typography>
                                        </StepButton>
                                    </Step>
                                </Stepper>
                            </div>

                            {activeStep === ModalStep.Select && (
                                <div>
                                    <Button
                                        onClick={() => {
                                            setAmountInputs(
                                                purchaseOrder.orderlines
                                                    .filter((o) => o.product)
                                                    .reduce<AmountInputs>((acc, cur) => {
                                                        acc[cur.id] = String(
                                                            cur.lineQuantity - cur.allocatedAmount
                                                        )
                                                        return acc
                                                    }, {})
                                            )
                                            setOcScope(OrderConfirmationScope.Create)
                                            setActiveStep(ModalStep.Products)
                                        }}
                                    >
                                        Create missing OC
                                    </Button>
                                    <OrderConfirmationsTable
                                        request={() =>
                                            getOrderConfirmationsRequest({
                                                relations: [GetOrderConfirmationRelations.Author],
                                                purchaseOrderId: purchaseOrder.id,
                                                hasBeenDelivered: false,
                                            })
                                        }
                                        columns={[
                                            OrderConfirmationsTableColumns.Code,
                                            OrderConfirmationsTableColumns.Author,
                                            OrderConfirmationsTableColumns.ExpectedDeliveryDate,
                                        ]}
                                        actionComponents={[
                                            (orderConfirmationId: number) => (
                                                <Button
                                                    onClick={() => {
                                                        const orderConfirmation =
                                                            purchaseOrder.orderConfirmations.find(
                                                                (o) => o.id === orderConfirmationId
                                                            )
                                                        if (!orderConfirmation) {
                                                            toastWarn(
                                                                'Could not find the order confirmation'
                                                            )
                                                            return
                                                        }
                                                        setAmountInputs(
                                                            orderConfirmation.orderlines.reduce<AmountInputs>(
                                                                (acc, cur) => {
                                                                    acc[cur.id] = String(
                                                                        cur.amount -
                                                                            cur.deliveredAmount
                                                                    )
                                                                    return acc
                                                                },
                                                                {}
                                                            )
                                                        )
                                                        setOrderConfirmationId(orderConfirmationId)
                                                        setOcScope(OrderConfirmationScope.Existing)
                                                        setActiveStep(ModalStep.Products)
                                                    }}
                                                >
                                                    Select
                                                </Button>
                                            ),
                                        ]}
                                    />
                                </div>
                            )}
                            {activeStep === ModalStep.Products && (
                                <div>
                                    {ocScope === OrderConfirmationScope.Create && (
                                        <div>
                                            <FieldContainer>
                                                <Label>OC Code</Label>
                                                <Input
                                                    style={{
                                                        borderRadius: '10px',
                                                        width: '300px',
                                                    }}
                                                    value={code}
                                                    onChange={(e) => setCode(e.target.value)}
                                                />
                                            </FieldContainer>
                                            <Table
                                                size={'small'}
                                                dataSource={
                                                    purchaseOrder?.orderlines?.sort(
                                                        (a, b) => a.lineNumber - b.lineNumber
                                                    ) || []
                                                }
                                                columns={productsColumns}
                                                pagination={false}
                                            />
                                        </div>
                                    )}
                                    {ocScope === OrderConfirmationScope.Existing &&
                                        selectedOrderConfirmation && (
                                            <div>
                                                <Table
                                                    size={'small'}
                                                    dataSource={
                                                        selectedOrderConfirmation.orderlines
                                                    }
                                                    columns={productsColumns}
                                                    pagination={false}
                                                />
                                            </div>
                                        )}
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            margin: '15px',
                                        }}
                                    >
                                        <Button
                                            disabled={(() => {
                                                if (ocScope === OrderConfirmationScope.Create) {
                                                    return (
                                                        code.length < 5 ||
                                                        Object.values(amountInputs).reduce(
                                                            (acc, cur) => acc + Number(cur),
                                                            0
                                                        ) < 1
                                                    )
                                                }
                                                if (ocScope === OrderConfirmationScope.Existing) {
                                                    return (
                                                        Object.values(amountInputs).reduce(
                                                            (acc, cur) => acc + Number(cur),
                                                            0
                                                        ) < 1
                                                    )
                                                }
                                                return true
                                            })()}
                                            onClick={() => setActiveStep(ModalStep.Pallets)}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {activeStep === ModalStep.Pallets && (
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '50px',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '10px',
                                        }}
                                    >
                                        <div>
                                            <Label>Project</Label>
                                            <SearchInput
                                                style={{ width: '200px' }}
                                                placeholder="Select Project "
                                                selectedValue={selectedProject?.id}
                                                setSelectedValue={(id) => {
                                                    if (!id) {
                                                        return
                                                    }
                                                    getProjectByIdRequest(id).then((r) => {
                                                        if (r.successful) {
                                                            setSelectedProject(r.data)
                                                        }
                                                    })
                                                }}
                                                request={(search: string) =>
                                                    getProjectsRequest({ like: search }).then((r) =>
                                                        r.successful
                                                            ? r.data.projects.map((project) => ({
                                                                  label: project.name,
                                                                  value: project.id,
                                                              }))
                                                            : [{ label: 'None', value: 2 }]
                                                    )
                                                }
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <Button onClick={() => setPallets([...pallets, {}])}>
                                                Add pallets
                                            </Button>
                                        </div>
                                    </div>
                                    {ocScope === OrderConfirmationScope.Create && (
                                        <Table
                                            size={'small'}
                                            dataSource={purchaseOrder?.orderlines?.filter(
                                                (o: PurchaseOrderOrderline) =>
                                                    Number(amountInputs[o.id]) > 0
                                            )}
                                            columns={palletColumns}
                                            pagination={{ pageSize: 100 }}
                                        />
                                    )}
                                    {ocScope === OrderConfirmationScope.Existing && (
                                        <Table
                                            size={'small'}
                                            dataSource={selectedOrderConfirmation?.orderlines?.filter(
                                                (o: OrderConfirmationOrderline) =>
                                                    Number(amountInputs[o.id]) > 0
                                            )}
                                            columns={palletColumns}
                                            pagination={{ pageSize: 100 }}
                                        />
                                    )}
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            type="primary"
                                            disabled={!isPalletsValid}
                                            onClick={() => setActiveStep(ModalStep.Confirm)}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {activeStep === ModalStep.Confirm && (
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '50px',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Alert severity="info">
                                        Make sure that the amounts specified are correct. If they
                                        are not, then it might result in blocking call-offs related
                                        to the OC.
                                    </Alert>
                                    {ocScope === OrderConfirmationScope.Create && (
                                        <Table
                                            size={'small'}
                                            dataSource={purchaseOrder?.orderlines?.filter(
                                                (o: PurchaseOrderOrderline) =>
                                                    Number(amountInputs[o.id]) > 0
                                            )}
                                            columns={confirmStepColumns}
                                            pagination={{ pageSize: 100 }}
                                        />
                                    )}
                                    {ocScope === OrderConfirmationScope.Existing && (
                                        <Table
                                            size={'small'}
                                            dataSource={selectedOrderConfirmation?.orderlines?.filter(
                                                (o: OrderConfirmationOrderline) =>
                                                    Number(amountInputs[o.id]) > 0
                                            )}
                                            columns={confirmStepColumns}
                                            pagination={{ pageSize: 100 }}
                                        />
                                    )}
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginRight: '50px',
                                        }}
                                    >
                                        <Button style={{ width: '150px' }} onClick={createDelivery}>
                                            Record Delivery
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Container>
            </Paper>
        </Dialog>
    )
}
