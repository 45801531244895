import Box from '@mui/material/Box'
import { Button, Dialog, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { useDispatch } from 'react-redux'
import { dispatchPallets } from '../../../store/warehouse-map-slice'
import { useAppSelector } from '../../../store/hooks'

const ModalFooter = (props: { onConfirm: () => void; onClose: () => void }) => {
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
    }))

    const ModalButton = styled(Button)(() => ({
        padding: '0px',
        minWidth: '0px',
        margin: '10px 10px 5px 0px',
    }))

    return (
        <Row>
            <ModalButton variant="outlined" size="small" onClick={props.onClose}>
                Close
            </ModalButton>
            <ModalButton
                variant="outlined"
                size="small"
                onClick={() => {
                    props.onConfirm()
                    props.onClose()
                }}
            >
                Confirm
            </ModalButton>
        </Row>
    )
}

interface AssemblyTransferModalProps {
    isOpen: boolean
    onClose: () => void
}

export const ConfirmDispatchModal = ({ isOpen, onClose }: AssemblyTransferModalProps) => {
    const { assemblyToDispatch } = useAppSelector((state) => state.warehouseMap)

    const dispatch = useDispatch()
    if (!assemblyToDispatch) {
        if (isOpen) {
            onClose()
        }
        return <></>
    }

    const onConfirm = () => {
        dispatch(
            dispatchPallets({
                assemblyId: assemblyToDispatch.id,
            })
        )
    }

    return (
        <Dialog open={isOpen} onClose={onClose} style={{ zIndex: 100 }} fullWidth maxWidth={'sm'}>
            <Box style={{ width: '100%', padding: '20px' }}>
                <Typography fontSize={20}>Confirm transfer</Typography>
                <Typography>Are you sure that you want to confirm the transfer?</Typography>
                <ModalFooter onConfirm={onConfirm} onClose={onClose} />
            </Box>
        </Dialog>
    )
}
