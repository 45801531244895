import { Table } from 'antd'
import { GoodsDelivered } from '../../models/models'

export default function GoodsDeliveredTable({
    goodsDelivered,
}: {
    goodsDelivered: GoodsDelivered[]
}) {
    const columns = [
        {
            title: 'Amount Received',
            key: 'code',
            render: (goodsDelivered: GoodsDelivered) => {
                return goodsDelivered.amountReceived
            },
        },
        {
            title: 'Code',
            key: 'Code',
            render: (goodsDelivered: GoodsDelivered) => {
                return goodsDelivered?.ocOrderline?.purchaseOrderOrderline?.product?.code
            },
        },
        {
            title: 'Name',
            key: 'name',
            render: (goodsDelivered: GoodsDelivered) => {
                return goodsDelivered?.ocOrderline?.purchaseOrderOrderline?.product?.name
            },
        },
        {
            title: 'Description',
            key: 'Description',
            render: (goodsDelivered: GoodsDelivered) => {
                return goodsDelivered?.ocOrderline?.purchaseOrderOrderline?.product?.description
            },
        },
    ]

    return <Table size={'small'} dataSource={goodsDelivered} columns={columns} pagination={false} />
}
