import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import './index.css'
import App from './app/App'
import reportWebVitals from './report-web-vitals'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { mainSAVTheme } from './theme/Theme'
import { Provider } from 'react-redux'
import { store } from './store/store'
import 'antd/dist/reset.css'
import { setGlobalConfiguration } from './configurations/global-configuration'

setGlobalConfiguration()

if (process.env.REACT_APP_SENTRY_DNS) {
    Sentry.init({
        environment: process.env.REACT_APP_ENVIRONMENT,
        dsn: process.env.REACT_APP_SENTRY_DNS,
    })
}

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <ThemeProvider theme={mainSAVTheme}>
                <Provider store={store}>
                    <App />
                </Provider>
            </ThemeProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
