import { Box } from '@mui/system'
import Typography from '@mui/material/Typography'
import PageHeader from '../../ui/PageHeader'
import { Button, IconButton } from '@mui/material'
import { ArrowBack, ArrowCircleLeftOutlined, ArrowCircleRightOutlined } from '@mui/icons-material'
import moment, { Moment } from 'moment'
import { getProductionSchedules } from '../../../api/production-schedule'
import { Schedule, Warehouse } from '../../../models/models'
import { useEffect, useState } from 'react'
import LoadingOverlay from '../../project-master/single-project-view/LoadingOverlay'
import ProductionScheduleSingleDateView from './ProductionScheduleSingleDateView'
import { Urls } from '../../../util/urls'
import { getScheduleAvailableMinutes } from '../../../util/production-schedule'

interface Props {
    selectedDate: Moment
    selectedWarehouse: Warehouse
    setSelectedDate: (date: Moment | null) => void
    onClose: () => void
}

export const ProductionScheduleView = ({
    selectedDate,
    selectedWarehouse,
    setSelectedDate,
    onClose,
}: Props) => {
    const [schedule, setSchedule] = useState<Schedule | null>(null)
    const [warehouse, setWarehouse] = useState<Warehouse | null>(null)

    const loadSchedules = async () => {
        const from = moment(selectedDate).utcOffset(0, true).startOf('day').toDate()
        const to = moment(selectedDate).utcOffset(0, true).endOf('day').toDate()
        const response = await getProductionSchedules({
            from,
            to,
            warehouseId: selectedWarehouse.id,
        })
        if (response.successful) {
            setSchedule(response.data[0])
        }
    }

    useEffect(() => {
        loadSchedules()
    }, [selectedDate])

    const changeDate = (amount: number) => {
        setSelectedDate(selectedDate.clone().add(amount, 'day'))
        setSchedule(null)
        onClose()
    }

    const breadcrumbs = [
        { link: Urls.Landing, name: 'Main Page' },
        {
            link: Urls.ProductionSchedule,
            name: 'Production Schedule',
            onClickAction: () => setSelectedDate(null),
        },
        {
            link: Urls.ProductionSchedule,
            name: selectedDate.format('DD-MM-YYYY'),
        },
    ]

    const title = (
        <>
            <IconButton
                onClick={() => {
                    setSelectedDate(null)
                }}
            >
                <ArrowBack />
            </IconButton>
            {selectedDate.format('MMMM Do YYYY')}
        </>
    )

    if (!schedule) {
        return <LoadingOverlay />
    }

    return (
        <>
            <PageHeader title={title} breadcrumbs={breadcrumbs}>
                <Box
                    style={{
                        display: 'flex',
                        gap: 32,
                        alignItems: 'center',
                        marginTop: 24,
                    }}
                >
                    <Box>
                        <Typography color={'textSecondary'}>Time available</Typography>
                        <Typography component="h2" variant="h6">
                            <span style={{ fontSize: '22px' }}>
                                {getScheduleAvailableMinutes(schedule)}
                            </span>
                            /
                            <span style={{ fontSize: '12px', fontStyle: 'italic' }}>
                                {selectedWarehouse.assemblyMinutesDaily}
                            </span>
                        </Typography>
                    </Box>
                    <Button variant={'outlined'} color={'secondary'}>
                        Re-allocate
                    </Button>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                        <IconButton onClick={() => changeDate(-1)}>
                            <ArrowCircleLeftOutlined />
                        </IconButton>
                        {selectedDate.format('D MMMM, YYYY')}
                        <IconButton onClick={() => changeDate(1)}>
                            <ArrowCircleRightOutlined />
                        </IconButton>
                    </Box>
                </Box>
            </PageHeader>
            <ProductionScheduleSingleDateView
                date={selectedDate}
                warehouseId={selectedWarehouse.id}
            />
        </>
    )
}
