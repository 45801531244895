import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { Button, Input, Select } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { AdditionalCharge } from '../../../../api/sales-orders'
import { FieldContainer } from '../../../ui/FIeldContainer'
import { Label } from '../../../ui/Label'
import { getChargeOrderlines, Orderline } from './CreateSaleOrder'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: '30px',
})

const NewChargeContainer = styled(Box)({
    marginTop: '30px',
    display: 'flex',
    width: '400px',
    flexDirection: 'column',
    gap: '15px',
})

const AddedChargeContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
})

interface AdditionalChargesProps {
    onNextStep: () => void
    additionChargeOptions: AdditionalCharge[]
    orderlines: Orderline[]
    setOrderlines: React.Dispatch<React.SetStateAction<Orderline[]>>
}

export default function AdditionalCharges(props: AdditionalChargesProps) {
    const [selectedAdditionChargeCode, setSelectedAdditionalChargeCode] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [netValue, setNetValue] = useState<string>('')
    const { onNextStep, additionChargeOptions, orderlines, setOrderlines } = props

    const chargeOrderlines = useMemo(() => {
        return getChargeOrderlines(orderlines)
    }, [orderlines])

    useEffect(() => {
        const charge = findOptionByCode(selectedAdditionChargeCode)
        if (!charge) {
            setNetValue('')
            setDescription('')
        }
    }, [selectedAdditionChargeCode])

    const handleSelectedChargeChange = (value: string) => {
        setSelectedAdditionalChargeCode(value)
        const charge = findOptionByCode(value)
        if (charge) {
            setNetValue(charge.chargeValue.toString())
            setDescription(charge.name)
            return
        }
    }

    const disableAddChargeButton = () => {
        return !findOptionByCode(selectedAdditionChargeCode) || !description
    }

    const findOptionByCode = (code: string) => {
        return additionChargeOptions.find(
            (additionalChargeOption) => additionalChargeOption.code === code
        )
    }

    return (
        <Container>
            <NewChargeContainer>
                <FieldContainer>
                    <Label>Charge option</Label>
                    <Select
                        style={{ width: '100%' }}
                        value={selectedAdditionChargeCode}
                        onChange={(e) => handleSelectedChargeChange(e)}
                        options={additionChargeOptions.map((additionalChargeOption) => ({
                            label: additionalChargeOption.name,
                            value: additionalChargeOption.code,
                        }))}
                    />
                </FieldContainer>

                <FieldContainer>
                    <Label> Description</Label>
                    <Input
                        autoComplete="off"
                        disabled={!findOptionByCode(selectedAdditionChargeCode)}
                        value={description}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setDescription(e.target.value)
                        }}
                    />
                </FieldContainer>
                <Label> Unit price</Label>
                <Input
                    autoComplete="off"
                    type="number"
                    addonAfter="£"
                    value={netValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setNetValue(e.target.value)
                    }}
                />

                <Button
                    disabled={disableAddChargeButton()}
                    onClick={() => {
                        const charge = findOptionByCode(selectedAdditionChargeCode)
                        if (!charge) {
                            return
                        }
                        setSelectedAdditionalChargeCode('')
                        setOrderlines([
                            ...orderlines,
                            { ...charge, description, netValue: Number(netValue) },
                        ])
                    }}
                >
                    Add Charge
                </Button>
            </NewChargeContainer>
            <AddedChargeContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Net Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {chargeOrderlines.map((charge) => {
                            return (
                                <TableRow key={charge.description}>
                                    <TableCell>{charge.code}</TableCell>
                                    <TableCell>{charge.description}</TableCell>
                                    <TableCell>{charge.netValue}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                <Button type="primary" onClick={onNextStep}>
                    Continue
                </Button>
            </AddedChargeContainer>
        </Container>
    )
}
