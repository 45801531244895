import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { Table } from 'antd'
import moment from 'moment'
import { DayDTO } from '../../../api/days-off'
import { AbsentDayType, Schedule, User } from '../../../models/models'
import { getScheduleAvailableMinutes } from '../../../util/production-schedule'
import { getAbsentDaySubtractedMinutes } from '../util'

interface Props {
    days: DayDTO[]
    setDays: (days: DayDTO[]) => void
    user: User
    schedules: Schedule[]
}
export const CreateDaysOffTable = ({ user, days, setDays, schedules }: Props) => {
    const handleTypeChange = (e: SelectChangeEvent<AbsentDayType>, date: Date) => {
        if (!Object.values(AbsentDayType)?.includes(e.target.value as AbsentDayType)) {
            return
        }

        const updatedDays = days.map((d) => {
            if (moment(d.date).isSame(moment(date))) {
                d.type = e.target.value as AbsentDayType
            }
            return d
        })
        setDays(updatedDays)
    }

    const columns = [
        {
            key: 'Day',
            title: 'Date',
            render: (dayDTO: DayDTO) => {
                return moment(dayDTO.date).format('DD/MM/YYYY')
            },
        },
        {
            key: 'Type',
            title: 'Type',
            render: (dayDTO: DayDTO) => {
                return (
                    <FormControl sx={{ width: '200px', paddingLeft: 1, paddingRight: 1 }}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={dayDTO.type}
                            label="Warehouse"
                            onChange={(e) => handleTypeChange(e, dayDTO.date)}
                        >
                            <MenuItem value={AbsentDayType.FullDay}>Full day</MenuItem>
                            <MenuItem value={AbsentDayType.HalfDay}>Half day</MenuItem>
                        </Select>
                    </FormControl>
                )
            },
        },
        {
            key: 'Minutes',
            title: 'Remaining minutes',
            render: (dayDTO: DayDTO) => {
                const schedule = schedules.find((s) =>
                    moment(s.day).isSame(moment(dayDTO.date), 'day')
                )
                if (!schedule) {
                    return 'Could not find schedule'
                }
                return `${
                    getScheduleAvailableMinutes(schedule) -
                    getAbsentDaySubtractedMinutes(moment(schedule.day), user, dayDTO.type)
                }`
            },
        },
    ]

    return <Table columns={columns} dataSource={days} size="small" />
}
