import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { RootState } from '../../store/store'
import { useSelector } from 'react-redux'
import ProductsTable, { ProductsTableColumns } from '../tables/ProductsTable'
import { getStockItemsRequest, pinStockItemRequest } from '../../api/stock-item'
import { ProductsTopBar } from './ProductsTopBar'
import { Button } from 'antd'
import { Product, UserRole } from '../../models/models'
import { PushpinFilled, PushpinOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import { setUpdateTableToken } from '../../store/products-slice'

export const Products = () => {
    const { productFilters, updateTableToken } = useSelector((state: RootState) => state.products)
    const dispatch = useAppDispatch()
    const { user } = useAppSelector((state) => state.authentication)

    const isValidPinRole =
        user?.role === UserRole.SavAdmin ||
        user?.role === UserRole.SystemAdmin ||
        user?.role === UserRole.ApplicationEngineer

    const isPinnedByUser = (product: Product) =>
        product.pinnedByUsers.find((u) => u.id === user?.id)

    const onPinProduct = async (
        event:
            | React.MouseEvent<HTMLAnchorElement, MouseEvent>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>,
        product: Product
    ) => {
        event.preventDefault()
        const response = await pinStockItemRequest(product.id)
        if (!response.successful) {
            toast.error(response.message)
            return
        }

        dispatch(
            setUpdateTableToken(
                isPinnedByUser(product) ? `pinned_${product.id}` : `unpinned_${product.id}`
            )
        )
    }

    const actionComponents = () => {
        if (isValidPinRole) {
            return [
                (product: Product) => {
                    return (
                        <Button
                            shape="circle"
                            icon={isPinnedByUser(product) ? <PushpinFilled /> : <PushpinOutlined />}
                            onClick={(event) => onPinProduct(event, product)}
                            disabled={!isValidPinRole}
                        ></Button>
                    )
                },
            ]
        }

        return undefined
    }

    return (
        <div>
            <ProductsTopBar />
            <ProductsTable
                pagination={true}
                actionComponents={actionComponents()}
                dependencies={{ productFilters, updateTableToken }}
                columns={[
                    ProductsTableColumns.Id,
                    ProductsTableColumns.Code,
                    ProductsTableColumns.Name,
                    ProductsTableColumns.Description,
                    ProductsTableColumns.ProductGroup,
                ]}
                actionColumnTitle="Pin"
                request={(pagination) =>
                    getStockItemsRequest({
                        userId: user?.id, // retrieves pins for current user
                        ids: productFilters.productId ? [productFilters.productId] : undefined,
                        likeCode: productFilters.code ? productFilters.code : undefined,
                        likeName: productFilters.name ? productFilters.name : undefined,
                        likeGroup: productFilters.productGroup
                            ? productFilters.productGroup
                            : undefined,
                        withCount: true,
                        skip: pagination?.skip,
                        limit: pagination?.limit,
                    })
                }
            />
        </div>
    )
}
