import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { useState } from 'react'
import AssignSalesOrders from './assign/AssignSalesOrders'
import CreateSaleOrder from './create/CreateSaleOrder'
import ViewSalesOrder from './view/ViewSalesOrders'

const Container = styled(Box)({})

export type Tabs = 'View' | 'Create' | 'Assign'
export default function SalesOrders() {
    const [activeTab, setActiveTab] = useState<Tabs>('View')

    const handleTabChange = (event: React.SyntheticEvent, tabKey: Tabs) => {
        event.preventDefault()
        setActiveTab(tabKey)
    }

    return (
        <Container>
            <TabContext value={activeTab}>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '20px 50px 0px 50px',
                    }}
                >
                    <TabList onChange={handleTabChange}>
                        <Tab label="View" value="View" style={{ textTransform: 'none' }} />
                        <Tab label="Create" value="Create" style={{ textTransform: 'none' }} />
                        <Tab label="Assign" value="Assign" style={{ textTransform: 'none' }} />
                    </TabList>
                </Box>

                <TabPanel value="View">
                    <ViewSalesOrder />
                </TabPanel>
                <TabPanel value="Create">
                    <CreateSaleOrder tabChange={(tabKey: Tabs) => setActiveTab(tabKey)} />
                </TabPanel>
                <TabPanel value="Assign">
                    <AssignSalesOrders />
                </TabPanel>
            </TabContext>
        </Container>
    )
}
