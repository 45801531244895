import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { ComponentsVariants } from '@mui/material/styles/variants'
import { BackgroundColors, BorderColors, TextColors } from './colors'

export const ButtonThemeStyles: {
    styleOverrides: ComponentsOverrides['MuiButton']
    variants: ComponentsVariants['MuiButton']
} = {
    styleOverrides: {
        root: {
            borderRadius: 10,
            padding: '8px 16px',
            height: 40,
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textTransform: 'none',
            '&.MuiButton-sizeSmall': {
                fontSize: 14,
                padding: '4px 16px',
                height: 34,
            },
        },
    },
    variants: [
        {
            props: { variant: 'contained', color: 'primary' },
            style: {
                backgroundColor: BackgroundColors.purple,
                color: TextColors.white,
                textTransform: 'none',
                '&:disabled': {
                    backgroundColor: BackgroundColors.lightGrey,
                    color: TextColors.lighterGrey,
                },
                boxShadow: 'none',
                '&:hover': {
                    background:
                        'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #3500A0',
                    boxShadow: 'none',
                },
                '&:focus': {
                    background: BackgroundColors.lightPurple,
                },
                '&:active': {
                    background:
                        'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #3500A0',
                },
            },
        },
        {
            props: { variant: 'outlined', color: 'secondary' },
            style: {
                color: TextColors.black,
                border: '1px solid',
                borderColor: BorderColors.lighterGrey,
                textTransform: 'none',
                boxShadow: 'none',
                '&:hover': {
                    borderColor: BorderColors.black,
                },
                '&:disabled': {
                    color: TextColors.lightGrey,
                },
            },
        },
        {
            props: { variant: 'contained', color: 'secondary' },
            style: {
                textTransform: 'none',
                backgroundColor: BackgroundColors.white,
                color: TextColors.black,
                '&:hover': {
                    backgroundColor: BackgroundColors.darkGrey,
                },
            },
        },
    ],
}
