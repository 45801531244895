import * as _ from 'lodash'
import moment, { Moment } from 'moment'
import { Bom, CallOff, GeneralAssembly, OrderConfirmation, Product } from '../models/models'
import { toastFailure } from './toast'

export const screamCaseToRegularText = (input: string): string => {
    return _.startCase(_.toLower(input.replace(/_/g, ' ')))
}
export const makeCommaDelimiterForNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function camelCaseToWords(str: string): string {
    return str
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        .replace(/(\b[a-z](?!\s))/g, (match: string) => match.toUpperCase())
}

// if it is type Product, check that its code starts with "FS/" if it is Bom check that its bomCode starts with "FS/"
export const isFlatstation = (product?: Product | Bom): boolean => {
    if (!product) {
        return false
    }
    if ('code' in product) {
        return product.code.startsWith('FS/')
    } else {
        return product.bomCode.startsWith('FS/')
    }
}

export const isHiu = (product: Product): boolean => {
    return product.name.startsWith('TS/')
}

export const findHiu = (bom: Bom) => {
    return bom?.bomComponents?.find((component) => {
        return isHiu(component.product)
    })?.product
}

export const uniqueBy = <T>(a: Array<T>, cond: (e1: T, e2: T) => boolean): Array<T> => {
    return a.filter((e, i) => a.findIndex((e2) => cond(e, e2)) === i)
}

//To turn Enums into regular title case with spaces
export const formatEnumName = (input: string): string => {
    return _.startCase(_.toLower(input.replace(/_/g, ' ')))
}

export function snakeCaseToTitleCase(str: string): string {
    return str
        .split('_')
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
}

export function escapeRegex(s: string) {
    return s.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
}

export const camelCaseToTitleCase = (input: string): string => {
    const result = input.replace(/([A-Z])/g, ' $1')
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    return finalResult
}

export interface BuiltItemsCompletedDetails {
    [key: number]: {
        callOffAmount: number
        assemblyAllocatedAmount: number
        completedAmount: number
    }
}

export const callOffBuiltItemsCompletedDetails = (callOff: CallOff): BuiltItemsCompletedDetails => {
    const itemQuantitiesToBuild: BuiltItemsCompletedDetails = {}

    if (callOff.builtItemCallOffs === undefined || callOff.assemblies === undefined) {
        throw new Error('call off has undefined properties')
    }
    callOff.builtItemCallOffs.forEach((pbico) => {
        itemQuantitiesToBuild[pbico.id] = {
            callOffAmount: pbico.amount,
            assemblyAllocatedAmount: 0,
            completedAmount: 0,
        }
    })

    callOff.assemblies.forEach((assembly) => {
        itemQuantitiesToBuild[assembly.builtItemCallOffId].assemblyAllocatedAmount +=
            assembly.amount
        if (assembly.completedAt) {
            itemQuantitiesToBuild[assembly.builtItemCallOffId].completedAmount += assembly.amount
        }
    })
    return itemQuantitiesToBuild
}

export enum OrderConfirmationStatus {
    Created = 'Created',
    PartiallyBookedIn = 'PartiallyBookedIn',
    BookedIn = 'BookedIn',
}

/**
 * Requires that the order confirmation orderlines has references to their goods delivered
 * and that the order confirmation has a reference to its deliveries
 * @param orderConfirmation
 * @returns OrderConfirmationStatus | undefined
 */
export const getOrderConfirmationStatus = (
    orderConfirmation: OrderConfirmation
): OrderConfirmationStatus | undefined => {
    if (!orderConfirmation.orderlines || !orderConfirmation.deliveries) {
        return
    }

    if (orderConfirmation.deliveries.length === 0) {
        return OrderConfirmationStatus.Created
    }

    for (const orderline of orderConfirmation.orderlines) {
        if (!orderline.goodsDelivered) {
            return
        }
        const amountDelivered = orderline.goodsDelivered.reduce((a, c) => a + c.amountReceived, 0)
        if (amountDelivered < orderline.amount) {
            return OrderConfirmationStatus.PartiallyBookedIn
        }
    }

    return OrderConfirmationStatus.BookedIn
}

export const isPastOrWeekend = (date: Moment) => {
    return (
        !date ||
        date.weekday() === 5 ||
        date.weekday() === 6 ||
        moment().subtract(1, 'day').isSameOrAfter(date, 'day')
    )
}

export const orderconfirmationStatusToString = (status: OrderConfirmationStatus | undefined) => {
    if (status === OrderConfirmationStatus.Created) {
        return 'Created'
    }
    if (status === OrderConfirmationStatus.PartiallyBookedIn) {
        return 'Partially Booked In'
    }
    if (status === OrderConfirmationStatus.BookedIn) {
        return 'Booked In'
    }
    return 'Incalculatable'
}

export const isLocalHost = (): boolean => {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
}

export const getWeekDayDateRangeArray = (startDate: Moment, stopDate: Moment): Date[] => {
    const dateArray = []
    let currentDate = startDate.utcOffset(0, true)
    while (currentDate <= stopDate.utcOffset(0, true)) {
        if (currentDate.isoWeekday() === 6 || currentDate.isoWeekday() === 7) {
            currentDate = currentDate.add(1, 'days')
            continue
        }
        dateArray.push(currentDate.toDate())
        currentDate = currentDate.add(1, 'days')
    }
    return dateArray
}

export const numberInputOnWheelPreventChange = (event: React.FormEvent<HTMLElement>) => {
    // Prevent the input value change
    event.currentTarget.blur()

    // Prevent the page/container scrolling
    event.stopPropagation()
}

export interface GeneralAssemblyMinutes {
    total: number
    completed: number
    remainding: number
}

export const calculateGeneralAssemblyMinutes = (
    generalAssembly: GeneralAssembly
): GeneralAssemblyMinutes => {
    if (!generalAssembly.builtItem || !generalAssembly.lines) {
        toastFailure('failed calculating minutes for general assembly')
        return { total: 0, completed: 0, remainding: 0 }
    }
    const minutes = { total: 0, completed: 0, remainding: 0 }
    minutes.total = generalAssembly.builtItem.assemblyMinutes * generalAssembly.lines.length
    for (const line of generalAssembly.lines) {
        if (line.completedAt) {
            minutes.completed += generalAssembly.builtItem.assemblyMinutes
        } else {
            minutes.remainding += generalAssembly.builtItem.assemblyMinutes
        }
    }
    return minutes
}

export enum GeneralAssemblyStatus {
    UNASSIGNED = 'UNASSIGNED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
}

export function convertCsvToJson<T = object>(file: File): Promise<T[]> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = (event) => {
            let csvData = event.target!.result as string

            // Normalize line breaks
            csvData = csvData.replace(/\r\n/g, '\n')

            const lines = csvData.split('\n')
            const headers = lines[0].split(',').map((header) => header.trim())

            const jsonData: T[] = []

            for (let i = 1; i < lines.length; i++) {
                if (lines[i].trim() === '') {
                    continue
                }
                const currentLine = lines[i].split(',').map((cell) => cell.trim())

                if (currentLine.length !== headers.length) {
                    toastFailure(`Invalid CSV format at line ${i + 1}.`)
                    reject(new Error(`Invalid CSV format at line ${i + 1}.`))
                    return
                }

                const lineObject: any = {}

                for (let j = 0; j < headers.length; j++) {
                    lineObject[headers[j]] = currentLine[j]
                }

                jsonData.push(lineObject)
            }

            resolve(jsonData)
        }

        reader.onerror = (event) => {
            toastFailure('Error occurred while reading the file.')
            reject(new Error('Error occurred while reading the file.'))
        }

        reader.readAsText(file)
    })
}

export const formatNumberToUkString = (number: number) => {
    if (number === 0) {
        return 0
    }
    const formatter = new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
    return formatter.format(number)
}
