import React, { useEffect, useState } from 'react'
import Modal from 'antd/lib/modal/Modal'
import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { getUserRequest, updateUserPassword } from '../../../api/users'
import { Box, Button, Input, Tooltip } from '@mui/material'
import LockResetIcon from '@mui/icons-material/LockReset'
import { useAppSelector } from '../../../store/hooks'
import { User, UserRole } from '../../../models/models'
import CTRLoader from '../../ui/loader/CTRloader'
import { toastFailure, toastSuccess } from '../../../util/toast'

export interface EditUserPasswordPayload {
    withSendEmail: boolean
    updatedPassword: string
}

interface Props {
    isVisible: boolean
    userId: number | null
    onSubmit?: (user: User) => void
    onCancel?: () => void
}

enum ModalState {
    Loading = 'Loading',
    Ready = 'Ready',
}

export const UpdateUserPasswordModal = ({ isVisible, onCancel, onSubmit, userId }: Props) => {
    const [updatedPassword, setUpdatedPassword] = useState<string>('')
    const [withSendEmail, setWithSendEmail] = useState<boolean>(false)
    const [user, setUser] = useState<User>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const currentUser = useAppSelector((state) => state.authentication.user)
    const {
        modalState: { modalEntityId },
    } = useAppSelector((state) => state.users)

    const allowedRoles = [UserRole.SavAdmin, UserRole.SystemAdmin]

    useEffect(() => {
        if (
            isVisible &&
            (!currentUser || !allowedRoles.includes(currentUser.role) || userId === null)
        ) {
            toastFailure('You are not allowed to edit users password')
            onClose()
        }
    }, [isVisible])

    useEffect(() => {
        if (isVisible && modalEntityId) {
            getUserRequest(Number(modalEntityId)).then((response) => {
                if (response.successful) {
                    setUser(response.data)
                    setModalState(ModalState.Ready)
                } else {
                    toastFailure(response.message)
                    onClose()
                }
            })
        }
    }, [isVisible, modalEntityId])

    const onSubmitChangepasswordHandler = async (e: React.MouseEvent<HTMLElement>) => {
        const payload: EditUserPasswordPayload = {
            withSendEmail,
            updatedPassword,
        }
        const response = await updateUserPassword(payload, Number(userId))
        if (response.successful) {
            toastSuccess('Password updated successfully')

            if (onSubmit) {
                onSubmit(response.data)
            }
        } else {
            toastFailure(response.message)
        }
    }

    const generatePassword = () => {
        const createdPassword =
            Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8)
        setUpdatedPassword(createdPassword)
    }

    const onCheckHandler = (e: CheckboxChangeEvent) => {
        setWithSendEmail(e.target.checked)
    }

    const onClose = () => {
        if (onCancel) {
            onCancel()
        }
        setUser(undefined)
        setModalState(ModalState.Loading)
        setUpdatedPassword('')
        setWithSendEmail(false)
    }

    return (
        <Modal
            open={isVisible}
            onCancel={onClose}
            onOk={onSubmitChangepasswordHandler}
            title={
                user ? `Change password for ${user.firstName} ${user.lastName}` : `Change password`
            }
            footer={
                <Box>
                    <Button
                        onClick={onClose}
                        color="secondary"
                        sx={{ margin: '5px' }}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onSubmitChangepasswordHandler}
                        sx={{ margin: '5px' }}
                        variant="outlined"
                    >
                        {'Change Password'}
                    </Button>
                </Box>
            }
        >
            <div
                style={{
                    padding: '0.8rem',
                    backgroundColor: '#F2F3F5 ',
                }}
            >
                <i>
                    Set the new password for the user, or autogenerate one. Click the checkbox below
                    to chose if the user should also receive a new email with the new password as
                    well
                </i>
            </div>
            <br />
            {modalState === ModalState.Loading && <CTRLoader />}
            {modalState === ModalState.Ready && user && (
                <div>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '15px',
                            marginBottom: '15px',
                            alignItems: 'center',
                            ...{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 0,
                                },
                            },
                        }}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                alignItems: 'flex-end',
                                overflow: 'hidden',
                            }}
                        >
                            <Input
                                fullWidth
                                type="password"
                                value={updatedPassword}
                                onChange={(e) => setUpdatedPassword(e.target.value)}
                                placeholder="New password"
                                style={{ borderRadius: '10px 0 0 10px' }}
                            />
                            <Tooltip title="Generate random password" arrow>
                                <Box
                                    onClick={generatePassword}
                                    sx={{
                                        minWidth: '40px',
                                        height: '45px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        border: '1px solid #d9d9d9',
                                        borderLeft: 'none',
                                    }}
                                >
                                    <LockResetIcon />
                                </Box>
                            </Tooltip>
                        </Box>
                    </Box>
                    <br />
                    <Checkbox.Group>
                        <Checkbox checked={withSendEmail} onChange={onCheckHandler}>
                            Send notification email to user
                        </Checkbox>
                        <br />

                        <label style={{ fontStyle: 'italic' }}>
                            The user will receive a notification with the new password to the
                            account
                        </label>
                    </Checkbox.Group>
                </div>
            )}
        </Modal>
    )
}
