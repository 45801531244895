import { Pallet, PalletEvent, WarehouseAreaSpot } from '../models/models'
import backendConnection from './util/backend-connection'
import { Response } from './util/with-response-formatter-interceptor'

export const getSpotsByAreaIdRequest = (areaId: number): Promise<Response<WarehouseAreaSpot[]>> =>
    backendConnection.request({
        url: `/warehouse-map/spots/${areaId}`,
        method: 'GET',
    })

export const getSpotsRequest = (): Promise<Response<WarehouseAreaSpot[]>> =>
    backendConnection.request({
        url: `/warehouse-map/spots`,
        method: 'GET',
    })

export interface ProductDTO {
    amount: number
    id: number
}

export enum PalletEventsRelations {
    Pallet = 'Pallet',
    Spots = 'Spots',
    User = 'User',
    Assembly = 'Assembly',
    CallOff = 'CallOff',
    Project = 'Project',
}

export interface GetPalletEventsDTO {
    relations?: PalletEventsRelations[]
    projectId?: number
    startDate?: Date
    endDate?: Date
    userId?: number
    withCount?: boolean
    skip: number
    limit: number
}

export interface PalletEventsLog {
    events: PalletEvent[]
    count: number
}

export const getPalletEvents = (dto: GetPalletEventsDTO): Promise<Response<PalletEventsLog>> =>
    backendConnection.request({
        url: '/warehouse-map/pallet/events',
        method: 'GET',
        params: dto,
    })

export interface CreatePalletDTO {
    products: ProductDTO[]
    areaId: number
    deliveryid?: number
    comment?: string
    projectId?: number
    projectManagerId?: number
    spotId?: number
}

export const createPalletRequest = (body: CreatePalletDTO): Promise<Response<Pallet>> =>
    backendConnection.request({
        url: '/warehouse-map/pallet',
        method: 'POST',
        data: body,
    })

export interface DeletePalletDTO {
    palletId: number
    spotId: number
}

export const deletePalletRequest = (body: DeletePalletDTO): Promise<Response<WarehouseAreaSpot>> =>
    backendConnection.request({
        url: `/warehouse-map/pallet`,
        method: 'DELETE',
        data: body,
    })

export interface DeleteStackDTO {
    palletIds: number[]
    spotId: number
}

export const deleteStackRequest = (body: DeleteStackDTO): Promise<Response<WarehouseAreaSpot>> =>
    backendConnection.request({
        url: `/warehouse-map/stack`,
        method: 'DELETE',
        data: body,
    })

export interface MovePalletDTO {
    palletId: number
    fromSpotId: number
    toSpotId: number
}

export const movePalletRequest = (
    body: MovePalletDTO
): Promise<
    Response<{
        fromSpot: WarehouseAreaSpot
        toSpot: WarehouseAreaSpot
    }>
> =>
    backendConnection.request({
        url: '/warehouse-map/pallet/move',
        method: 'PUT',
        data: body,
    })

export interface MoveStackDTO {
    palletIds: number[]
    fromSpotId: number
    toSpotId: number
}

export const moveStackRequest = (
    body: MoveStackDTO
): Promise<
    Response<{
        fromSpot: WarehouseAreaSpot
        toSpot: WarehouseAreaSpot
    }>
> =>
    backendConnection.request({
        url: '/warehouse-map/stack/move',
        method: 'PUT',
        data: body,
    })

export interface UpdatePalletDTO {
    palletId: number
    productsBefore: {
        amount: number
        id: number
    }[]
    productsToUpdate?: {
        amount: number
        id: number
    }[]
    projectManagerId?: number
    projectId?: number
    comment?: string
    productsToRemove?: number[]
}

export const updatePalletRequest = (body: UpdatePalletDTO): Promise<Response<Pallet>> =>
    backendConnection.request({
        url: '/warehouse-map/pallet',
        method: 'PUT',
        data: body,
    })

export interface SplitPalletDTO {
    productsBefore: ProductDTO[]
    productsToSplit: ProductDTO[]
    palletId: number
}

export const splitPalletRequest = (
    body: SplitPalletDTO
): Promise<
    Response<{
        newPallet: Pallet
        oldPallet: Pallet
    }>
> =>
    backendConnection.request({
        url: '/warehouse-map/pallet/split',
        method: 'PUT',
        data: body,
    })

export const getPalletRequest = (id: number): Promise<Response<Pallet>> =>
    backendConnection.request({
        url: `/warehouse-map/pallet/${id}`,
        method: 'GET',
    })

export const getSpotRequest = (id: number): Promise<Response<WarehouseAreaSpot>> =>
    backendConnection.request({
        url: `/warehouse-map/spot/${id}`,
        method: 'GET',
    })
