import { useEffect } from 'react'
import { getToken } from '../api/sage'
import { SAGE_TOKEN_KEY } from '../api/util/backend-connection'
import { toastFailure } from '../util/toast'

export enum SAGE_AUTH_CB_MESSAGES {
    Closed = 'CLOSED',
    LoggedIn = 'LOGGED_IN',
}

export type AuthMessage = {
    type: SAGE_AUTH_CB_MESSAGES
    payload: string | null
}

export interface LoggedInMessage {
    type: SAGE_AUTH_CB_MESSAGES.LoggedIn
    payload: string
}

export interface ClosedMessage {
    type: SAGE_AUTH_CB_MESSAGES.Closed
    payload: string
}

export function isLoggedInMessage(
    message: LoggedInMessage | ClosedMessage
): message is LoggedInMessage {
    return (message as LoggedInMessage).type === SAGE_AUTH_CB_MESSAGES.LoggedIn
}

export default function SageAuth() {
    const CB = new BroadcastChannel('ctr')
    useEffect(() => {
        const code = new URLSearchParams(location.search).get('code')
        if (code) {
            getToken(code).then((response) => {
                if (response.successful) {
                    const { access_token } = response.data
                    localStorage.setItem(SAGE_TOKEN_KEY, access_token)
                    CB.postMessage({ type: SAGE_AUTH_CB_MESSAGES.LoggedIn, payload: access_token })
                    window.close()
                } else {
                    toastFailure(response.message)
                }
            })
        }
        window.addEventListener('beforeunload', (e) => {
            e.preventDefault()
            CB.postMessage({ type: SAGE_AUTH_CB_MESSAGES.Closed, payload: null })
        })
        return () => {
            window.removeEventListener('beforeunload', () => {
                return null
            })
        }
    }, [])

    return <div>Awaiting response</div>
}
