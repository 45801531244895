import {
    GetOrderConfirmationRelations,
    getOrderConfirmationsRequest,
} from '../../../api/order-confirmation'
import { PurchaseOrder } from '../../../models/models'
import OrderConfirmationsTable from '../../tables/OrderConfirmationsTable'

interface Props {
    purchaseOrder: PurchaseOrder
}

export default function OrderConfirmationsTab({ purchaseOrder }: Props) {
    return (
        <OrderConfirmationsTable
            request={() =>
                getOrderConfirmationsRequest({
                    purchaseOrderId: purchaseOrder.id,
                    relations: [GetOrderConfirmationRelations.Author],
                })
            }
        />
    )
}
