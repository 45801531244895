import React from 'react'
import { CSSObject, styled, Theme } from '@mui/material/styles'
import List from '@mui/material/List'
import { Box, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuIcon from '@mui/icons-material/Menu'
import { NavigationOption, navigationOptions } from './navigationOptions'
import MuiDrawer from '@mui/material/Drawer'
import { useAppSelector } from '../../store/hooks'
import { DrawerItem } from './DrawerItem'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    position: 'absolute',
    zIndex: 99,
    height: '100%',
    [theme.breakpoints.up('md')]: {
        position: 'static',
    },
})

const closedMixin = (theme: Theme): CSSObject => ({
    width: `calc(${theme.spacing(7)} + 1px)`,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    position: 'absolute',
    zIndex: 99,
    height: '100%',
    [theme.breakpoints.up('md')]: {
        position: 'static',
    },
})

const DrawerContent = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        minWidth: `calc(${theme.spacing(7)} + 1px)`,
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    })
)

export const Drawer = (): React.ReactElement => {
    const [open, setOpen] = React.useState<boolean>(true)
    const { user } = useAppSelector((state) => state.authentication)

    const handleNavbarClick = () => {
        setOpen(!open)
    }

    const renderListItem = (option: NavigationOption, index: number) => {
        if (user && option.permissions && !option.permissions?.includes(user.role)) {
            return null
        }
        return <DrawerItem key={index} option={option} isMenuOpen={open} />
    }

    return (
        <>
            <DrawerContent variant="permanent" open={open} onClose={handleNavbarClick}>
                <List>
                    <div style={{ height: 48 }} />
                    {open ? (
                        <ListItem button key={'hamburger'} onClick={handleNavbarClick}>
                            <ListItemIcon>
                                <ChevronLeftIcon />
                            </ListItemIcon>
                        </ListItem>
                    ) : (
                        <ListItem button key={'hamburger'} onClick={handleNavbarClick}>
                            <ListItemIcon>
                                <MenuIcon />
                            </ListItemIcon>
                        </ListItem>
                    )}
                    {navigationOptions.map((option, index) => renderListItem(option, index))}
                </List>
            </DrawerContent>
            <Box sx={{ display: { sm: 'block', md: 'none' }, minWidth: 56 }} />
        </>
    )
}
