import { EditUserPasswordPayload } from '../components/modals/update-user-password-modal/UpdateUserPasswordModal'
import { User, UserRole } from '../models/models'
import backendConnection from './util/backend-connection'
import { Response } from './util/with-response-formatter-interceptor'

export const getCurrentUser = (): Promise<Response<User>> =>
    backendConnection.request({
        url: '/users/me',
        method: 'GET',
    })

export interface CreateUserDto {
    id?: number
    firstName?: string
    lastName?: string
    role?: UserRole
    email?: string
    warehouseId?: number
    phoneNumber?: string
    country?: string
}

export const createUserRequest = (user: CreateUserDto): Promise<Response<User>> =>
    backendConnection.request({
        url: '/users',
        method: 'POST',
        data: user,
    })

export enum UserRelations {
    AssemblerSpots = 'AssemblerSpots',
    Warehouse = 'Warehouse',
}

export enum GetUsersSortBy {
    Id = 'Id',
}

export interface GetUsersDTO {
    relations?: UserRelations[]
    roles?: UserRole[]
    email?: string
    firstName?: string
    lastName?: string
    verificationToken?: string
    userIds?: number[]
    warehouseId?: number
    id?: number
    role?: UserRole
    name?: string
    sortBy?: GetUsersSortBy
    skip?: number
    limit?: number
}

export const getUsersRequest = (
    params?: GetUsersDTO
): Promise<Response<{ entities: User[]; count: number }>> =>
    backendConnection.request({
        url: `/users`,
        method: 'GET',
        params,
    })

export const getUserRequest = (id: number, params?: GetUsersDTO): Promise<Response<User>> =>
    backendConnection.request({
        url: `/users/${id}`,
        method: 'GET',
        params,
    })

export interface UpdateUserDto extends CreateUserDto {
    timeCreatedAt?: string | null
    password?: string
    scheduledMinutesMonday?: number
    scheduledMinutesTuesday?: number
    scheduledMinutesWednesday?: number
    scheduledMinutesThursday?: number
    scheduledMinutesFriday?: number
}

export const updateUserRequest = (id: number, user: UpdateUserDto): Promise<Response<User>> =>
    backendConnection.request({
        url: `/users/${id}`,
        data: user,
        method: 'PUT',
    })

export const getRoles = (): Promise<Response<string[]>> =>
    backendConnection.request({
        url: `/users/roles`,
        method: 'GET',
    })

export const updateUserPassword = (
    updateUserPasswordPayload: EditUserPasswordPayload,
    userId: number
): Promise<Response<User>> =>
    backendConnection.request({
        url: `/users/password/${userId}`,
        data: updateUserPasswordPayload,
        method: 'PUT',
    })

export const deleteUserRequest = (id: number): Promise<Response<User>> =>
    backendConnection.request({
        url: `/users/${id}`,
        method: 'DELETE',
    })
