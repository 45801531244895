import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { Project, ProjectEvent } from '../models/models'
import { projectHistoryRequest } from '../api/projects/projects'
import { toastFailure } from '../util/toast'
import { startLoading, stopLoading } from './single-project-view-slice'

export interface State {
    history: ProjectEvent[]
}

const initialState: State = {
    history: [],
}

export const projectHistorySlice = createSlice({
    name: 'projectHistory',
    initialState,
    reducers: {
        setEvents(state, action: PayloadAction<ProjectEvent[]>) {
            state.history = action.payload
        },
    },
})

export const { setEvents } = projectHistorySlice.actions

export const fetchHistory = (project: Project) => async (dispatch: Dispatch) => {
    await dispatch(startLoading())

    const historyResponse = await projectHistoryRequest(project.id)

    if (!historyResponse.successful) {
        toastFailure('An error occurred while retrieving the project history.')
        return
    }

    await dispatch(setEvents(historyResponse.data))
    await dispatch(stopLoading())
}

export default projectHistorySlice.reducer
