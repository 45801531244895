import { Step, StepLabel, Stepper } from '@mui/material'
import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Project, ProjectBuiltItem, ProjectStockItem, WarehouseId } from '../../../../models/models'
import { useAppSelector } from '../../../../store/hooks'
import Confirm from './Confirm'
import Products from './Products'
import Details from './Details'
import {
    AdditionalCharge,
    CustomerSopTemplate,
    getAdditionalChargeOptions,
    getSopTemplate,
    createSalesOrder,
    CreateSalesOrderDTO,
    getAnalysisCodeDetailsRequest,
    AnalysisCodeValueDTO,
    ContactDTO,
} from '../../../../api/sales-orders'
import { toastFailure, toastSuccess } from '../../../../util/toast'
import { Moment } from 'moment'
import Memos from './Memos'
import {
    DeliveryAddress,
    getCustomersDeliveryAddresses,
    AccountMemo,
    getCustomerAccountMemos,
} from '../../../../api/customers'
import Comments from './Comments'
import AdditionalCharges from './AdditionalCharges'
import Delivery from './Delivery'
import { Tabs } from '../SalesOrders'
import CTRLoader from '../../../ui/loader/CTRloader'
import { isFlatstation } from '../../../../util/util'

const Container = styled(Box)({ display: 'flex', flexDirection: 'column' })

const HeaderContainer = styled(Box)({ display: 'flex', flexDirection: 'row' })

const ContentContainer = styled(Box)({ display: 'flex', flexDirection: 'row', width: '100%' })

export interface AddedProjectStockItem extends ProjectStockItem {
    addedAmount: number
    unitPrice: number
    unitDiscount: number
}

export interface AddedProjectBuiltItem extends ProjectBuiltItem {
    addedAmount: number
    unitPrice: number
    unitDiscount: number
}

export interface AddedSopAdditionalCharge extends AdditionalCharge {
    description: string
    netValue: number
}

export interface AddedComment {
    description: string
}

enum Steps {
    Details = 0,
    Products = 1,
    Comments = 2,
    Additional = 3,
    Memos = 4,
    Delivery = 5,
    Confirm = 6,
}

enum State {
    Loading = 'Loading',
    Ready = 'Ready',
    Error = 'Error',
}

interface CreateSaleOrderProps {
    tabChange: (tabKey: Tabs) => void
}

export type Orderline =
    | AddedProjectBuiltItem
    | AddedProjectStockItem
    | AddedComment
    | AddedSopAdditionalCharge

//Built item
export const isAddedSopProjectBuiltItem = (item: Orderline): item is AddedProjectBuiltItem => {
    return (item as AddedProjectBuiltItem).builtItemId !== undefined
}

export const getBuiltItemOrderlines = (orderlines: Orderline[]): AddedProjectBuiltItem[] => {
    return orderlines.filter((o, i) => isAddedSopProjectBuiltItem(o)) as AddedProjectBuiltItem[]
}

//Stock item
export const isAddedSopProjectStockItem = (item: Orderline): item is AddedProjectStockItem => {
    return (item as AddedProjectStockItem).stockItemId !== undefined
}

export const getStockItemOrderlines = (orderlines: Orderline[]): AddedProjectStockItem[] => {
    return orderlines.filter((o, i) => isAddedSopProjectStockItem(o)) as AddedProjectStockItem[]
}

//Comment item
export const isAddedComment = (item: Orderline): item is AddedComment => {
    return Object.keys(item as AddedComment).length === 1
}

export const getCommentOrderlines = (orderlines: Orderline[]): AddedComment[] => {
    return orderlines.filter((o, i) => isAddedComment(o)) as AddedComment[]
}

//Charges
export const isAddedSopAdditionalCharge = (item: Orderline): item is AddedSopAdditionalCharge => {
    return (item as AddedSopAdditionalCharge).chargeValue !== undefined
}

export const getChargeOrderlines = (orderlines: Orderline[]): AddedSopAdditionalCharge[] => {
    return orderlines.filter((o, i) => isAddedSopAdditionalCharge(o)) as AddedSopAdditionalCharge[]
}

export default function CreateSaleOrder(props: CreateSaleOrderProps) {
    const [template, setTemplate] = useState<CustomerSopTemplate>()
    const project: Project | null = useAppSelector(
        (state) => state.singleProjectView.projectBeingUpdated
    )
    const [warehouseId, setWarehouseId] = useState<WarehouseId>(WarehouseId.Monument)
    const [datePromised, setDatePromised] = useState<Moment | null>(null)
    const [customerOrderNumber, setCustomerOrderNumber] = useState<string>('')
    const [state, setState] = useState<State>(State.Loading)
    const [contacts, setContacts] = useState<ContactDTO[]>([])
    const [orderlines, setOrderlines] = useState<Orderline[]>([])
    const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<DeliveryAddress[]>([])
    const [additionChargeOptions, setAdditionalChargeOptions] = useState<AdditionalCharge[]>([])
    const [accountMemos, setAccountMemos] = useState<AccountMemo[]>([])
    const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState<DeliveryAddress>()
    const [analysisCode1, setAnalysisCode1] = useState<string>('')
    const [analysisCode2, setAnalysisCode2] = useState<string>('')
    const [analysisCode3, setAnalysisCode3] = useState<number>()
    const [analysisCode4, setAnalysisCode4] = useState<number>()
    const [analysisCode5, setAnalysisCode5] = useState<string>('')
    const [analysisCode6, setAnalysisCode6] = useState<number>()
    const [analysisCode3Options, setAnalysisCode3Options] = useState<AnalysisCodeValueDTO[]>([])
    const [analysisCode4Options, setAnalysisCode4Options] = useState<AnalysisCodeValueDTO[]>([])
    const [analysisCode6Options, setAnalysisCode6Options] = useState<AnalysisCodeValueDTO[]>([])
    const [waiting, setWaiting] = useState(false)

    const [activeStep, setActiveStep] = useState<Steps>(Steps.Details)
    const dispatch = useDispatch()

    if (!project?.customer) {
        return <Container>Cannot create sales orders on a project without a customer.</Container>
    }
    /**
     * Based on the customer of the project, we get some of the values from sage
     * that should be used for the sales order
     */
    const getData = async () => {
        const response = await getSopTemplate(project.id)
        if (response.successful) {
            setTemplate(response.data)
            const [
                deliveryAddressesResponse,
                chargeOptionsResponse,
                customerMemosResponse,
                analysisCodeDetailsResponse,
            ] = await Promise.all([
                getCustomersDeliveryAddresses(response.data.customerId),
                getAdditionalChargeOptions(),
                getCustomerAccountMemos(response.data.customerId),
                getAnalysisCodeDetailsRequest(),
            ])
            if (
                !deliveryAddressesResponse.successful ||
                !chargeOptionsResponse.successful ||
                !customerMemosResponse.successful ||
                !analysisCodeDetailsResponse.successful
            ) {
                toastFailure('Something went wrong getting customer details')
                setState(State.Error)
            } else {
                setDeliveryAddressOptions(deliveryAddressesResponse.data)
                setAdditionalChargeOptions(chargeOptionsResponse.data)

                //Checks whether or not a analysis code field is a set of possible values from sage
                //If so it creates dropdowns

                setAnalysisCode1(project.name)
                setAnalysisCode2(project.opportunityReference)

                const analysisCode3Mapping = analysisCodeDetailsResponse.data.find(
                    (analysisCodeMapping) => analysisCodeMapping.propertyName === 'AnalysisCode3'
                )
                if (analysisCode3Mapping?.analysisCode.allowOvertype === false) {
                    setAnalysisCode3Options(analysisCode3Mapping?.analysisCode.values)
                    setAnalysisCode3(analysisCode3Mapping.analysisCode.values[1].id)
                }

                const analysisCode4Mapping = analysisCodeDetailsResponse.data.find(
                    (analysisCodeMapping) => analysisCodeMapping.propertyName === 'AnalysisCode4'
                )
                if (analysisCode4Mapping?.analysisCode.allowOvertype === false) {
                    setAnalysisCode4Options(analysisCode4Mapping?.analysisCode.values)
                    setAnalysisCode4(analysisCode4Mapping.analysisCode.values[0].id)
                }

                setAnalysisCode5('')

                const analysisCode6Mapping = analysisCodeDetailsResponse.data.find(
                    (analysisCodeMapping) => analysisCodeMapping.propertyName === 'AnalysisCode6'
                )
                if (analysisCode6Mapping?.analysisCode.allowOvertype === false) {
                    setAnalysisCode6Options(analysisCode6Mapping?.analysisCode.values)
                    setAnalysisCode6(analysisCode6Mapping.analysisCode.values[0].id)
                }

                setAccountMemos(customerMemosResponse.data)
                setState(State.Ready)
            }
        } else {
            toastFailure(response.message)
            setState(State.Error)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    if (state === State.Loading) {
        return (
            <Container
                sx={{
                    width: '100%',
                    height: '500px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CTRLoader size="xl" />
            </Container>
        )
    }

    if (state === State.Error) {
        return <p>Something went wrong</p>
    }

    const onCreate = () => {
        if (!datePromised || !selectedDeliveryAddress || !template || !project?.id) {
            return
        }

        setWaiting(true)

        const body: CreateSalesOrderDTO = {
            deliveryAddressId: selectedDeliveryAddress.id,
            warehouseId,
            contacts,
            projectId: project.id,
            customerId: template.customerId,
            customerOrderNumber,
            promisedDeliveryDate: datePromised?.utcOffset(0, true).toDate(),
            analysisCode1: analysisCode1,
            analysisCode2: analysisCode2,
            analysisCode3: analysisCode3Options.find((a3) => a3.id === analysisCode3)?.value ?? '',
            analysisCode4: analysisCode4Options.find((a4) => a4.id === analysisCode4)?.value ?? '',
            analysisCode5: analysisCode5,
            analysisCode6: analysisCode6Options.find((a6) => a6.id === analysisCode6)?.value ?? '',
            orderlines: orderlines.map((orderline) => {
                if (isAddedSopProjectBuiltItem(orderline)) {
                    return {
                        builtItemId: orderline.builtItemId,
                        productId: orderline.builtItem.product.id,
                        amount: orderline.addedAmount,
                        price: orderline.unitPrice,
                    }
                } else if (isAddedSopProjectStockItem(orderline)) {
                    return {
                        productId: orderline.stockItemId,
                        amount: orderline.addedAmount,
                        price: orderline.unitPrice,
                    }
                } else if (isAddedSopAdditionalCharge(orderline)) {
                    return {
                        code: orderline.code,
                        description: orderline.description,
                        price: orderline.netValue,
                    }
                } else {
                    return { description: orderline.description }
                }
            }),
        }

        createSalesOrder(body).then((response) => {
            if (response.successful) {
                toastSuccess('Created the sales order')
                props.tabChange('View')
            } else {
                toastFailure(response.message)
            }
        })
    }

    return (
        <Container>
            <HeaderContainer>
                <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '100%' }}>
                    <Step
                        style={{
                            cursor: activeStep > Steps.Details ? 'pointer' : 'cursor',
                        }}
                        onClick={() => {
                            if (activeStep > Steps.Details) {
                                setActiveStep(Steps.Details)
                            }
                        }}
                        key={Steps.Details}
                    >
                        <StepLabel style={{ wordBreak: 'break-all' }}>Details</StepLabel>
                    </Step>
                    <Step
                        style={{
                            cursor: activeStep > Steps.Products ? 'pointer' : 'cursor',
                        }}
                        onClick={() => {
                            if (activeStep > Steps.Products) {
                                setActiveStep(Steps.Products)
                            }
                        }}
                        key={Steps.Products}
                    >
                        <StepLabel style={{ wordBreak: 'break-all' }}>Products</StepLabel>
                    </Step>

                    <Step
                        style={{
                            cursor: activeStep > Steps.Comments ? 'pointer' : 'cursor',
                        }}
                        onClick={() => {
                            if (activeStep > Steps.Comments) {
                                setActiveStep(Steps.Comments)
                            }
                        }}
                        key={Steps.Comments}
                    >
                        <StepLabel style={{ wordBreak: 'break-all' }}>Comments</StepLabel>
                    </Step>
                    <Step
                        style={{
                            cursor: activeStep > Steps.Additional ? 'pointer' : 'cursor',
                        }}
                        onClick={() => {
                            if (activeStep > Steps.Additional) {
                                setActiveStep(Steps.Additional)
                            }
                        }}
                        key={Steps.Additional}
                    >
                        <StepLabel style={{ wordBreak: 'break-all' }}>Additional</StepLabel>
                    </Step>
                    <Step
                        style={{
                            cursor: activeStep > Steps.Memos ? 'pointer' : 'cursor',
                        }}
                        onClick={() => {
                            if (activeStep > Steps.Memos) {
                                setActiveStep(Steps.Memos)
                            }
                        }}
                        key={Steps.Memos}
                    >
                        <StepLabel style={{ wordBreak: 'break-all' }}>Memos</StepLabel>
                    </Step>
                    <Step
                        style={{
                            cursor: activeStep > Steps.Delivery ? 'pointer' : 'cursor',
                        }}
                        onClick={() => {
                            if (activeStep > Steps.Delivery) {
                                setActiveStep(Steps.Delivery)
                            }
                        }}
                        key={Steps.Delivery}
                    >
                        <StepLabel style={{ wordBreak: 'break-all' }}>Delivery</StepLabel>
                    </Step>
                    <Step key={Steps.Confirm}>
                        <StepLabel>Confirm</StepLabel>
                    </Step>
                </Stepper>
            </HeaderContainer>
            <ContentContainer>
                {activeStep === Steps.Details && template && (
                    <Details
                        customerOrderNumber={customerOrderNumber}
                        setCustomerOrderNumber={setCustomerOrderNumber}
                        datePromised={datePromised}
                        setDatePromised={setDatePromised}
                        warehouseId={warehouseId}
                        setWarehouseId={setWarehouseId}
                        template={template}
                        onNextStep={() => setActiveStep(Steps.Products)}
                    />
                )}
                {activeStep === Steps.Products && (
                    <Products
                        orderlines={orderlines}
                        setOrderlines={setOrderlines}
                        onNextStep={() => setActiveStep(Steps.Comments)}
                        warehouseId={warehouseId}
                    />
                )}
                {activeStep === Steps.Comments && (
                    <Comments
                        orderlines={orderlines}
                        setOrderlines={setOrderlines}
                        onNextStep={() => setActiveStep(Steps.Additional)}
                    />
                )}
                {activeStep === Steps.Additional && (
                    <AdditionalCharges
                        onNextStep={() => setActiveStep(Steps.Memos)}
                        additionChargeOptions={additionChargeOptions}
                        orderlines={orderlines}
                        setOrderlines={setOrderlines}
                    />
                )}
                {activeStep === Steps.Memos && (
                    <Memos
                        accountMemos={accountMemos}
                        onNextStep={() => setActiveStep(Steps.Delivery)}
                    />
                )}
                {activeStep === Steps.Delivery && template && (
                    <Delivery
                        onNextStep={() => setActiveStep(Steps.Confirm)}
                        template={template}
                        deliveryAddressOptions={deliveryAddressOptions}
                        contacts={contacts}
                        setContacts={setContacts}
                        setDeliveryAddressOptions={setDeliveryAddressOptions}
                        setSelectedDeliveryAddress={setSelectedDeliveryAddress}
                        selectedDeliveryAddress={selectedDeliveryAddress}
                        analysisCode1={analysisCode1}
                        setAnalysisCode1={setAnalysisCode1}
                        analysisCode2={analysisCode2}
                        setAnalysisCode2={setAnalysisCode2}
                        analysisCode3={analysisCode3}
                        setAnalysisCode3={setAnalysisCode3}
                        analysisCode3Options={analysisCode3Options}
                        analysisCode4={analysisCode4}
                        setAnalysisCode4={setAnalysisCode4}
                        analysisCode4Options={analysisCode4Options}
                        analysisCode5={analysisCode5}
                        setAnalysisCode5={setAnalysisCode5}
                        analysisCode6={analysisCode6}
                        setAnalysisCode6={setAnalysisCode6}
                        analysisCode6Options={analysisCode6Options}
                    />
                )}
                {activeStep === Steps.Confirm &&
                    selectedDeliveryAddress &&
                    customerOrderNumber &&
                    template &&
                    datePromised && (
                        <Confirm
                            orderlines={orderlines}
                            contacts={contacts}
                            setOrderlines={setOrderlines}
                            analysisCode1={analysisCode1}
                            analysisCode2={analysisCode2}
                            analysisCode3={
                                analysisCode3Options.find((a3) => a3.id === analysisCode3)?.value ??
                                ''
                            }
                            analysisCode4={
                                analysisCode4Options.find((a4) => a4.id === analysisCode3)?.value ??
                                ''
                            }
                            analysisCode5={analysisCode5}
                            analysisCode6={
                                analysisCode6Options.find((a6) => a6.id === analysisCode3)?.value ??
                                ''
                            }
                            selectedDeliveryAddress={selectedDeliveryAddress}
                            customerOrderNumber={customerOrderNumber}
                            datePromised={datePromised}
                            warehouseId={warehouseId}
                            template={template}
                            waiting={waiting}
                            onCreate={onCreate}
                        />
                    )}
            </ContentContainer>
        </Container>
    )
}
