import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import PageHeader from '../ui/PageHeader'
import Booking from './logistics/booking/Booking'
import { useEffect, useMemo, useState } from 'react'
import Dispatch from './logistics/dispatch/Dispatch'
import Transfer from './logistics/transfer/Transfer'
import { CallOff, TaskDepartment } from '../../models/models'
import { Urls } from '../../util/urls'
import TaskBar from './tasks/TaskBar'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import {
    getDispatchWorksheetsRequest,
    getTransferWorksheetsRequest,
    TransferType,
    TransferWorksheet,
} from '../../api/logistics-worksheet'
import moment from 'moment'
import { toastFailure } from '../../util/toast'
import {
    TransferFilter,
    setActiveView,
    LogisticsWorksheetView,
    FilterDispatchDate,
} from '../../store/logistics-worksheet-slice'
import { useAppDispatch } from '../../store/hooks'
import { Tabs, TabsProps } from 'antd'

const Container = styled(Box)({
    margin: '15px',
})

export enum TransferSortBy {
    AssemblyDateToBeCompletedBy = 'AssemblyDateToBeCompletedBy',
    CallOffDateToDispatch = 'CallOffDateToDispatch',
}

export default function WorksheetsLogistics() {
    const { user } = useSelector((state: RootState) => state.authentication)
    const [callOffs, setCallOffs] = useState<CallOff[]>([])
    const [transfers, setTransfers] = useState<TransferWorksheet[]>([])
    const dispatch = useAppDispatch()
    const {
        activeView,
        filterCallOffId,
        filterProjectName,
        filterSavNumber,
        filterDispatchDate,
        filterTransfer,
        filterAssemblyId,
        filterWarehouseId,
    } = useSelector((state: RootState) => state.logisticsWorksheets)

    const fetchCallOffs = async () => {
        const response = await getDispatchWorksheetsRequest({ warehouseId: filterWarehouseId })
        if (response.successful) {
            setCallOffs(response.data)
        }
    }

    const dispatchCallOffs = useMemo(() => {
        return callOffs
            .filter((c) => {
                return (
                    c.warehouseId === filterWarehouseId &&
                    (!filterProjectName ||
                        c.project.name.toLowerCase().includes(filterProjectName.toLowerCase())) &&
                    (!filterSavNumber ||
                        c.project.opportunityReference
                            .toLowerCase()
                            .includes(filterSavNumber.toLowerCase())) &&
                    (!filterCallOffId || c.id.toString().includes(filterCallOffId)) &&
                    (filterDispatchDate === FilterDispatchDate.Upcoming ||
                        (filterDispatchDate === FilterDispatchDate.Today &&
                            moment(c.customerDispatchDate).isSame(moment(), 'day')))
                )
            })
            .sort((callOff1: CallOff, callOff2: CallOff) => {
                return (
                    moment(callOff1.customerDispatchDate).unix() -
                    moment(callOff2.customerDispatchDate).unix()
                )
            })
    }, [callOffs, filterProjectName, filterSavNumber, filterCallOffId, filterDispatchDate])

    useEffect(() => {
        fetchCallOffs()
    }, [filterWarehouseId])

    const fetchTransfers = async () => {
        const response = await getTransferWorksheetsRequest(filterWarehouseId)
        if (response.successful) {
            const { transfers } = response.data
            setTransfers(transfers)
        } else {
            toastFailure('Could not fetch worksheets')
        }
    }

    useEffect(() => {
        fetchTransfers()
    }, [filterWarehouseId, filterTransfer])

    const filteredTransfers = useMemo(() => {
        return transfers
            .filter((transfer) => {
                const c = transfer.item.callOff
                return (
                    (!filterProjectName ||
                        c.project.name.toLowerCase().includes(filterProjectName.toLowerCase())) &&
                    (!filterSavNumber ||
                        c.project.opportunityReference
                            .toLowerCase()
                            .includes(filterSavNumber.toLowerCase())) &&
                    (!filterCallOffId || c.id.toString().includes(filterCallOffId))
                )
            })
            .filter((transfer) => {
                const orderConfirmations = transfer.item.callOff?.orderConfirmations
                if (orderConfirmations) {
                    for (const orderConfirmation of orderConfirmations) {
                        if (!orderConfirmation.deliveredDate) {
                            return false
                        }
                    }
                }
                return true
            })
            .filter((transfer) => {
                if (filterTransfer === TransferFilter.None) {
                    return transfer
                } else if (
                    filterTransfer === TransferFilter.Assembly &&
                    transfer.type === TransferType.AssemblyTransfer
                ) {
                    return transfer
                } else if (
                    filterTransfer === TransferFilter.Dispatch &&
                    transfer.type === TransferType.DispatchTransfer
                ) {
                    return transfer
                }
            })
            .filter((transfer) => {
                return transfer.item.id.toString().includes(filterAssemblyId)
            })
            .sort((transfer1: TransferWorksheet, transfer2: TransferWorksheet) => {
                if (filterTransfer === TransferFilter.Assembly) {
                    return (
                        moment(transfer1.item.dateToBeCompletedBy).unix() -
                        moment(transfer2.item.dateToBeCompletedBy).unix()
                    )
                }
                if (filterTransfer === TransferFilter.Dispatch) {
                    return (
                        moment(transfer1.item.callOff.customerDispatchDate).unix() -
                        moment(transfer2.item.callOff.customerDispatchDate).unix()
                    )
                }
                if (filterTransfer === TransferFilter.None) {
                    return transfer1.item.id - transfer2.item.id
                }

                return 0
            })
    }, [
        transfers,
        filterAssemblyId,
        filterTransfer,
        filterCallOffId,
        filterCallOffId,
        filterSavNumber,
        filterProjectName,
    ])

    const tabsOptions: TabsProps['items'] = [
        {
            key: LogisticsWorksheetView.Booking,
            label: `Booking`,
            children: <Booking warehouseId={filterWarehouseId} />,
        },
        {
            key: LogisticsWorksheetView.Transfer,
            label: `Transfer`,
            children: (
                <Transfer
                    warehouseId={filterWarehouseId}
                    fetchTransfers={fetchTransfers}
                    transferWorksheets={filteredTransfers}
                />
            ),
        },
        {
            key: LogisticsWorksheetView.Dispatch,
            label: `Dispatch`,
            children: <Dispatch callOffs={dispatchCallOffs} fetchCallOffs={fetchCallOffs} />,
        },
    ]

    return (
        <Container>
            <PageHeader
                title="Logistics Worksheets"
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        link: Urls.WorksheetsLogistics,
                        name: 'Logistics Worksheets',
                    },
                ]}
            />

            <TaskBar department={TaskDepartment.LOGISTICS} warehouseId={filterWarehouseId} />

            <Tabs
                type="card"
                activeKey={activeView}
                onChange={(view) => dispatch(setActiveView(view as LogisticsWorksheetView))}
                defaultActiveKey={LogisticsWorksheetView.Booking}
                items={tabsOptions}
            />
        </Container>
    )
}
