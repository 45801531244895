import { useSelector } from 'react-redux'
import { BomRelation, getBomsRequest } from '../../api/bom'
import { useAppDispatch } from '../../store/hooks'
import BomsTable, { BomsTableColumns } from '../tables/BomTable'
import { RootState } from '../../store/store'
import { BomsTopBar } from './BomsTopBar'
import { Bom } from '../../models/models'
import { Button } from 'antd'
import { ActiveModal, openModal } from '../../store/products-slice'

export const Boms = () => {
    const { bomFilters, updateTableToken } = useSelector((state: RootState) => state.products)
    const dispatch = useAppDispatch()

    return (
        <div>
            <BomsTopBar />
            <BomsTable
                pagination={true}
                actionComponents={[
                    (bom: Bom) => {
                        return (
                            <Button
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            activeModal: ActiveModal.CreateBomVersion,
                                            modalEntityId: bom.id,
                                        })
                                    )
                                }
                            >
                                Create Version
                            </Button>
                        )
                    },
                    (bom: Bom) => {
                        return (
                            <Button
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            activeModal: ActiveModal.BomInspect,
                                            modalEntityId: bom.id,
                                        })
                                    )
                                }
                            >
                                Inspect
                            </Button>
                        )
                    },
                ]}
                columns={[
                    BomsTableColumns.Id,
                    BomsTableColumns.Code,
                    BomsTableColumns.ProductName,
                    BomsTableColumns.Description,
                    BomsTableColumns.BomGroupName,
                    BomsTableColumns.AssemblyTime,
                    BomsTableColumns.Instruction,
                ]}
                dependencies={{ bomFilters, updateTableToken }}
                request={(pagination) =>
                    getBomsRequest({
                        excludeBomCode: bomFilters?.excludeCode
                            ? bomFilters.excludeCode
                            : undefined,
                        bomCode: bomFilters?.code ? bomFilters.code : undefined,
                        productName: bomFilters ? bomFilters.productName : undefined,
                        ids: bomFilters?.bomId ? [bomFilters.bomId] : undefined,
                        relations: [BomRelation.BomGroup, BomRelation.Product],
                        withCount: true,
                        skip: pagination?.skip,
                        limit: pagination?.limit,
                    })
                }
            />
        </div>
    )
}
