import { Divider } from '@mui/material'
import { Box, styled } from '@mui/system'

const Row = styled(Box)({
    marginBottom: '15px',
})
const RowContent = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
})
const RowDescriptor = styled(Box)({
    padding: '0px 5px 0px 10px',
    width: '50%',
})

const DescriptorParagraph = styled('p')({
    color: '262626',
    opacity: '45%',
})

const RowValue = styled(Box)({
    padding: '0px 10px 0px 5px',
    width: '50%',
})

export default function CardRow({ descriptor, value }: { descriptor: string; value: string }) {
    return (
        <Row>
            <RowContent>
                <RowDescriptor>
                    <DescriptorParagraph>{descriptor}</DescriptorParagraph>
                </RowDescriptor>

                <RowValue>
                    <p>{value}</p>
                </RowValue>
            </RowContent>
            <Divider />
        </Row>
    )
}
