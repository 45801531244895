import { AbsentDayType, User } from '../../models/models'
import moment, { Moment } from 'moment'
import { isKeyOfObject } from '../../util/is-key-of-object'
import { toastFailure } from '../../util/toast'

export const getAssemblerAvailableMinutes = (day: Moment, user: User): number => {
    const currentDay = day.isoWeekday().toString()
    if (currentDay === '6' || currentDay === '7') {
        return 0
    }

    const getAvailableMinutes = {
        '1': user.scheduledMinutesMonday,
        '2': user.scheduledMinutesTuesday,
        '3': user.scheduledMinutesWednesday,
        '4': user.scheduledMinutesThursday,
        '5': user.scheduledMinutesFriday,
    }

    if (!isKeyOfObject(currentDay, getAvailableMinutes)) {
        toastFailure('Could not get the amount of available minutes for the user')
        return 0
    }

    return getAvailableMinutes[currentDay]
}

export const getAbsentDaySubtractedMinutes = (
    day: Moment,
    user: User,
    type: AbsentDayType
): number => {
    const availableMinutes = getAssemblerAvailableMinutes(day, user)
    const minutesToSubtractFromSchedule = {
        [AbsentDayType.FullDay]: () => availableMinutes,

        [AbsentDayType.HalfDay]: () => Math.round(availableMinutes / 2),

        [AbsentDayType.Minutes]: () => {
            toastFailure('The absence type is not implemented yet')
        },
    }

    if (!isKeyOfObject(type, minutesToSubtractFromSchedule)) {
        toastFailure('Could not get the amount of available minutes for the user')
        return 0
    }

    return minutesToSubtractFromSchedule[type]() || 0
}
