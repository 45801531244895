import React, { useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { Typography, Table } from 'antd'
import moment, { Moment } from 'moment'
import {
    BuiltItemCallOff,
    CallOff,
    SalesOrder,
    StockItemCallOff,
    SopBuiltItemOrderline,
    SopStockItemOrderline,
} from '../../../models/models'
import {
    getCallOffById,
    ProductAmount,
    SingleCallOffRelations,
    updateCallOff,
    UpdateCallOffDTO,
} from '../../../api/call-off'
import { toastFailure, toastSuccess } from '../../../util/toast'
import {
    callOffBuiltItemsCompletedDetails,
    BuiltItemsCompletedDetails,
    numberInputOnWheelPreventChange,
} from '../../../util/util'
import CloseIcon from '@mui/icons-material/Close'
import {
    Button,
    CircularProgress,
    Dialog,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Tooltip,
    Input,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import RefreshIcon from '@mui/icons-material/Refresh'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { getSalesOrderByIdRequest, SingleSalesOrderRelations } from '../../../api/sales-orders'
import '../../tables/table-styles.css'
import DatePicker from '../../ui/calendar/DatePicker'

const Label = (props: { text: string; isInvalid?: boolean }) => {
    const { text } = props
    return (
        <Box style={{ width: '270px' }}>
            <Typography style={{ color: '#262626', opacity: '45%' }}>{text}</Typography>
        </Box>
    )
}

const InputContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
})

interface ProductAmountInput {
    [key: number]: string
}

interface ApproveCallOffModalProps {
    callOffId: number
    onClose: () => void
    onUpdate: (callOff: CallOff) => void
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

interface AddedSopStockItemOrderline extends SopStockItemOrderline {
    amountToAdd: string
}

interface AddedSopBuiltItemOrderline extends SopBuiltItemOrderline {
    amountToAdd: string
}

const isAddedSopBuiltItemOrderline = (
    itemSop: AddedSopBuiltItemOrderline | BuiltItemCallOff
): itemSop is AddedSopBuiltItemOrderline => {
    return (itemSop as AddedSopBuiltItemOrderline).salesOrderId !== undefined
}

const isAddedSopStockItemOrderline = (
    itemSop: AddedSopStockItemOrderline | StockItemCallOff
): itemSop is AddedSopStockItemOrderline => {
    return (itemSop as AddedSopStockItemOrderline).salesOrderId !== undefined
}

export default function UpdateCallOffModal({
    callOffId,
    onClose,
    onUpdate,
}: ApproveCallOffModalProps) {
    const [dispatchDate, setDispatchDate] = useState<Moment | null>()
    const [dateToBeCompletedBy, setDateToBeCompletedBy] = useState<Moment | null>()
    const [deliveryDate, setDeliveryDate] = useState<Moment | null>()
    const [title, setTitle] = useState<string>()
    const [dispatchComment, setDispatchComment] = useState<string>()
    const [stockItemAmountInputs, setStockItemAmountInputs] = useState<ProductAmountInput>({})
    const [builtItemAmountInputs, setBuiltItemAmountInputs] = useState<ProductAmountInput>({})
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const [callOff, setCallOff] = useState<CallOff>()
    const [salesOrder, setSalesOrder] = useState<SalesOrder>()
    const [builtItemCompletedDetails, setbuiltItemCompletedDetails] =
        useState<BuiltItemsCompletedDetails>()
    const [deletedBuiltItemIds, setDeletedBuiltItemIds] = useState<number[]>([])
    const [deletedStockItemIds, setDeletedStockItemIds] = useState<number[]>([])
    const [addedBuiltItems, setAddedBuiltItems] = useState<AddedSopBuiltItemOrderline[]>([])
    const [addedStockItems, setAddedStockItems] = useState<AddedSopStockItemOrderline[]>([])

    useEffect(() => {
        getCallOff()
    }, [])

    useEffect(() => {
        if (callOff) {
            getSalesOrder(callOff.salesOrderId)
        }
    }, [callOff])

    const getCallOff = async () => {
        const response = await getCallOffById(callOffId, {
            relations: [
                SingleCallOffRelations.Assemblies,
                SingleCallOffRelations.AssembliesPallets,
                SingleCallOffRelations.CallOffProducts,
                SingleCallOffRelations.SalesOrder,
                SingleCallOffRelations.SalesOrderCustomer,
                SingleCallOffRelations.SalesOrderProducts,
                SingleCallOffRelations.OrderConfirmations,
            ],
        })

        if (response.successful) {
            const callOff = response.data
            setCallOff(callOff)
            setbuiltItemCompletedDetails(callOffBuiltItemsCompletedDetails(callOff))

            const builtItemInputs: ProductAmountInput = {}
            callOff.builtItemCallOffs?.forEach((pbi) => {
                builtItemInputs[pbi.id] = String(pbi.amount)
            })
            setBuiltItemAmountInputs(builtItemInputs)

            const stockItemAmountInputs: ProductAmountInput = {}
            callOff.stockItemCallOffs?.forEach((psi) => {
                stockItemAmountInputs[psi.id] = String(psi.amount)
            })
            setStockItemAmountInputs(stockItemAmountInputs)

            setDateToBeCompletedBy(moment(callOff.dateToBeCompletedBy))
            setDeliveryDate(moment(callOff.deliveryDate))
            setDispatchDate(moment(callOff.customerDispatchDate))
            setTitle(callOff.title)
            setDispatchComment(callOff.dispatchComment)
        } else {
            if (response.status === 404) {
                setModalState(ModalState.NotFound)
            } else {
                setModalState(ModalState.Error)
            }
            toastFailure(response.message)
        }
    }

    const getSalesOrder = async (salesOrderId: number) => {
        const response = await getSalesOrderByIdRequest(salesOrderId, {
            relations: [SingleSalesOrderRelations.Orderlines, SingleSalesOrderRelations.Projects],
            withReservedOrderlineAmounts: true,
        })

        if (response.successful) {
            setSalesOrder(response.data)
            setModalState(ModalState.Success)
        } else if (response.status === 404) {
            setModalState(ModalState.NotFound)
            toastFailure('Could not find sales order')
        } else {
            setModalState(ModalState.Error)
            toastFailure('Something went wrong retrieving the sales order')
        }
    }

    const availableSopStockItemOrderlines = useMemo(() => {
        if (!salesOrder) {
            return []
        }
        return salesOrder.stockItemOrderlines
            .filter(
                (stockItemOrderline) =>
                    !addedStockItems.find(
                        (addedStockItem) => addedStockItem.id === stockItemOrderline.id
                    )
            )
            .filter(
                (stockItemOrderline) =>
                    !callOff?.stockItemCallOffs?.find(
                        (stockItemCallOff) =>
                            stockItemCallOff.stockItemSopId === stockItemOrderline.id
                    )
            )
    }, [addedStockItems, callOff, salesOrder])

    const availableSopBuiltItemOrderlines = useMemo(() => {
        if (!salesOrder) {
            return []
        }
        return salesOrder.builtItemOrderlines
            .filter(
                (builtItemOrderline) =>
                    !addedBuiltItems.find(
                        (addedBuiltItem) => addedBuiltItem.id === builtItemOrderline.id
                    )
            )
            .filter(
                (builtItemOrderline) =>
                    !callOff?.builtItemCallOffs?.find(
                        (builtItemCallOff) =>
                            builtItemCallOff.builtItemSopId === builtItemOrderline.id
                    )
            )
    }, [addedBuiltItems, callOff, salesOrder])

    const disabledDate = (current: Moment) => {
        // Can not select days before today
        return current < moment().endOf('day').subtract(1, 'd')
    }

    const restoreDeletedStockItem = (stockItemCallOffId: number) => {
        setDeletedStockItemIds(deletedStockItemIds.filter((v) => v !== stockItemCallOffId))
    }

    const isStockItemDeleted = (stockItemCallOffId: number) => {
        return deletedStockItemIds.includes(stockItemCallOffId)
    }

    const restoreDeletedBuiltItem = (builtItemCallOffId: number) => {
        setDeletedBuiltItemIds(deletedBuiltItemIds.filter((v) => v !== builtItemCallOffId))
    }

    const isBuiltItemDeleted = (builtItemCallOffId: number) => {
        return deletedBuiltItemIds.includes(builtItemCallOffId)
    }

    const updateBuiltItemInput = (id: number, value: string) => {
        const inputState = { ...builtItemAmountInputs }
        inputState[id] = value
        setBuiltItemAmountInputs(inputState)
    }

    const updateAddedBuiltItemAmountInput = (addedBuiltItemId: number, amount: string) => {
        setAddedBuiltItems(
            addedBuiltItems.map((abi) => {
                if (abi.id === addedBuiltItemId) {
                    abi.amountToAdd = amount
                }
                return abi
            })
        )
    }

    const updateStockItemInput = (id: number, value: string) => {
        const inputState = { ...stockItemAmountInputs }
        inputState[id] = value
        setStockItemAmountInputs(inputState)
    }

    const updateAddedStockItemAmountInput = (addedStockItemid: number, amount: string) => {
        setAddedStockItems(
            addedStockItems.map((asi) => {
                if (asi.id === addedStockItemid) {
                    asi.amountToAdd = amount
                }
                return asi
            })
        )
    }

    const getUpdatedBuiltItems = (): ProductAmount[] => {
        if (!callOff) {
            return []
        }
        const updated: ProductAmount[] = []
        callOff.builtItemCallOffs
            ?.filter((bico) => !deletedBuiltItemIds.includes(bico.id))
            .forEach((bico) => {
                if (isBuiltItemUpdated(bico)) {
                    updated.push({ id: bico.id, amount: Number(builtItemAmountInputs[bico.id]) })
                }
            })
        return updated
    }

    const getUpdatedStockItems = (): ProductAmount[] => {
        if (!callOff) {
            return []
        }
        const updated: ProductAmount[] = []
        callOff.stockItemCallOffs
            ?.filter((sico) => !deletedStockItemIds.includes(sico.id))
            .forEach((sico) => {
                if (isStockItemUpdated(sico)) {
                    updated.push({ id: sico.id, amount: Number(stockItemAmountInputs[sico.id]) })
                }
            })
        return updated
    }

    const isStockItemUpdated = (stockItemCallOff: StockItemCallOff): boolean => {
        return stockItemAmountInputs[stockItemCallOff.id] !== String(stockItemCallOff.amount)
    }

    const isBuiltItemUpdated = (builtItemCallOff: BuiltItemCallOff): boolean => {
        return builtItemAmountInputs[builtItemCallOff.id] !== String(builtItemCallOff.amount)
    }

    const isSubmitDisabled = () => {
        const builtItemCallOffs =
            callOff?.builtItemCallOffs.filter((bic) => !deletedBuiltItemIds.includes(bic.id)) || []
        for (const builtItemCallOff of builtItemCallOffs) {
            if (isBuiltItemInputFaulty(builtItemCallOff)) {
                return true
            }
        }

        for (const addedBuiltItem of addedBuiltItems) {
            if (isAddedBuiltItemInputFaulty(addedBuiltItem)) {
                return true
            }
        }

        const stockItemCallOffs =
            callOff?.stockItemCallOffs.filter((sic) => !deletedStockItemIds.includes(sic.id)) || []
        for (const stockItemCallOff of stockItemCallOffs) {
            if (isStockItemInputFaulty(stockItemCallOff)) {
                return true
            }
        }

        for (const addedStockItem of addedStockItems) {
            if (isAddedStockItemInputFaulty(addedStockItem)) {
                return true
            }
        }

        return (
            !callOff ||
            !deliveryDate ||
            !dispatchDate ||
            !dateToBeCompletedBy ||
            (getUpdatedStockItems().length === 0 &&
                getUpdatedBuiltItems().length === 0 &&
                deletedBuiltItemIds.length > 0 &&
                deletedStockItemIds.length > 0 &&
                addedStockItems.length > 0 &&
                addedBuiltItems.length > 0 &&
                deliveryDate.isSame(moment(callOff.deliveryDate), 'day') &&
                dispatchDate.isSame(moment(callOff.customerDispatchDate), 'day') &&
                dateToBeCompletedBy.isSame(moment(callOff.dateToBeCompletedBy), 'day'))
        )
    }

    const onOk = async () => {
        if (!isSubmitDisabled() && deliveryDate && dispatchDate && dateToBeCompletedBy && callOff) {
            const body: UpdateCallOffDTO = {}
            if (title !== callOff.title) {
                body.title = title
            }
            if (dispatchComment !== callOff.dispatchComment) {
                body.dispatchComment = dispatchComment
            }
            if (!deliveryDate.isSame(moment(callOff.deliveryDate), 'day')) {
                body.deliveryDate = deliveryDate.utcOffset(0, true).toDate()
            }
            if (!dispatchDate.isSame(moment(callOff.customerDispatchDate), 'day')) {
                body.customerDispatchDate = dispatchDate.utcOffset(0, true).toDate()
            }
            if (!dateToBeCompletedBy.isSame(moment(callOff.dateToBeCompletedBy), 'day')) {
                body.dateToBeCompletedBy = dateToBeCompletedBy.utcOffset(0, true).toDate()
            }
            if (getUpdatedBuiltItems().length !== 0) {
                body.updateBuiltItems = getUpdatedBuiltItems()
            }
            if (getUpdatedStockItems().length !== 0) {
                body.updateStockItems = getUpdatedStockItems()
            }
            if (deletedBuiltItemIds.length > 0) {
                body.deleteBuiltItems = deletedBuiltItemIds.map((dbi) => ({
                    builtItemCalloffId: dbi,
                }))
            }
            if (deletedStockItemIds.length > 0) {
                body.deleteStockItems = deletedStockItemIds.map((dsi) => ({
                    stockItemCallOffId: dsi,
                }))
            }
            if (addedBuiltItems.length > 0) {
                body.createBuiltItems = addedBuiltItems.map((abi) => ({
                    builtItemSopId: abi.id,
                    amount: Number(abi.amountToAdd),
                }))
            }
            if (addedStockItems.length > 0) {
                body.createStockItems = addedStockItems.map((asi) => ({
                    stockItemSopId: asi.id,
                    amount: Number(asi.amountToAdd),
                }))
            }

            const response = await updateCallOff(callOffId, body)

            if (response.successful) {
                onUpdate(response.data)
                toastSuccess(`Updated Call Off ${response.data}`)
                onClose()
            } else {
                toastFailure(response.message)
            }
        }
    }

    //check if it is added just now or exists
    const onDeleteStockItem = (stockItemCallOffId: number) => {
        setDeletedStockItemIds([stockItemCallOffId, ...deletedStockItemIds])
    }

    const onDeleteAddedStockItem = (sopStockItemOrderlineId: number) => {
        setAddedStockItems(addedStockItems.filter((a) => a.id !== sopStockItemOrderlineId))
    }

    //check if it is added just now or exists
    const onDeleteBuiltItem = (builtItemCallOffId: number) => {
        setDeletedBuiltItemIds([builtItemCallOffId, ...deletedBuiltItemIds])
    }

    const onDeleteAddedBuiltItem = (sopBuiltItemOrderlineId: number) => {
        setAddedBuiltItems(addedBuiltItems.filter((a) => a.id !== sopBuiltItemOrderlineId))
    }

    const onAddStockItemSop = (sopStockItemOrderlineId: number) => {
        const sopStockItemOrderline = availableSopStockItemOrderlines.find(
            (sopStockItemOrderline) => sopStockItemOrderline.id === sopStockItemOrderlineId
        )
        if (!sopStockItemOrderline) {
            return
        }
        const addedOrderline: AddedSopStockItemOrderline = {
            amountToAdd: '1',
            ...sopStockItemOrderline,
        }
        setAddedStockItems([addedOrderline, ...addedStockItems])
    }

    const onAddBuiltItemSop = (sopBuiltItemOrderlineId: number) => {
        const sopBuiltItemOrderline = availableSopBuiltItemOrderlines.find(
            (sopBuiltItemOrderline) => sopBuiltItemOrderline.id === sopBuiltItemOrderlineId
        )
        if (!sopBuiltItemOrderline) {
            return
        }
        const addedOrderline: AddedSopBuiltItemOrderline = {
            amountToAdd: '1',
            ...sopBuiltItemOrderline,
        }
        setAddedBuiltItems([addedOrderline, ...addedBuiltItems])
    }

    const isBuiltItemInputFaulty = (builtItem: BuiltItemCallOff) => {
        const soOrderline = salesOrder?.builtItemOrderlines.find(
            (bio) => bio.id === builtItem.sopBuiltItemOrderline.id
        )

        if (!builtItemCompletedDetails || !soOrderline) {
            return
        }
        const inputAmount = Number(builtItemAmountInputs[builtItem.id])

        if (inputAmount <= 0) {
            return true
        }

        const builtSopAmount = builtItem.sopBuiltItemOrderline.amount
        const assemblyAllocated = builtItemCompletedDetails[builtItem.id].assemblyAllocatedAmount

        return (
            builtSopAmount < inputAmount ||
            assemblyAllocated > inputAmount ||
            soOrderline.reserved + inputAmount - builtItem.amount > soOrderline.amount
        )
    }

    const isAddedBuiltItemInputFaulty = (builtItem: AddedSopBuiltItemOrderline) => {
        const inputAmount = Number(
            addedBuiltItems.find((abi) => abi.id === builtItem.id)?.amountToAdd
        )

        if (inputAmount <= 0 || isNaN(inputAmount)) {
            return true
        }

        return inputAmount + builtItem.reserved > builtItem.amount
    }

    const isStockItemInputFaulty = (stockItem: StockItemCallOff) => {
        const soOrderline = salesOrder?.stockItemOrderlines.find(
            (bio) => bio.id === stockItem.sopStockItemOrderline.id
        )

        if (!soOrderline) {
            return
        }

        const inputAmount = Number(stockItemAmountInputs[stockItem.id])

        if (inputAmount <= 0) {
            return true
        }
        const sopStockAmount = stockItem.sopStockItemOrderline.amount

        return (
            sopStockAmount < inputAmount ||
            soOrderline.reserved + inputAmount - stockItem.amount > soOrderline.amount
        )
    }
    const isAddedStockItemInputFaulty = (stockItem: AddedSopStockItemOrderline) => {
        const inputAmount = Number(stockItemAmountInputs[stockItem.id])

        if (inputAmount <= 0) {
            return true
        }

        return inputAmount + stockItem.reserved > stockItem.amount
    }

    const stockItemColumns = [
        {
            title: 'Code',
            width: 300,
            render: (stockItem: StockItemCallOff | AddedSopStockItemOrderline) => {
                if (isAddedSopStockItemOrderline(stockItem)) {
                    return stockItem?.product?.code
                } else {
                    return stockItem.sopStockItemOrderline.product?.code
                }
            },
        },
        {
            title: 'SOP amount/allocated',
            width: 200,
            render: (stockItem: StockItemCallOff | AddedSopStockItemOrderline) => {
                if (isAddedSopStockItemOrderline(stockItem)) {
                    return `${stockItem.amount}/${stockItem.reserved}`
                } else {
                    return `${stockItem.sopStockItemOrderline.amount}/${stockItem.sopStockItemOrderline.reserved}`
                }
            },
        },
        {
            title: 'Current amount',
            render: (stockItem: StockItemCallOff | AddedSopStockItemOrderline) => {
                if (isAddedSopStockItemOrderline(stockItem)) {
                    return ''
                } else {
                    return stockItem.amount
                }
            },
        },
        {
            title: 'Update amount',
            width: 200,
            render: (stockItem: StockItemCallOff | AddedSopStockItemOrderline) => {
                if (isAddedSopStockItemOrderline(stockItem)) {
                    return (
                        <Input
                            startAdornment={
                                isAddedStockItemInputFaulty(stockItem) ? (
                                    <InputAdornment position="start">
                                        <Tooltip title="Check the input values!">
                                            <WarningAmberIcon htmlColor="red" />
                                        </Tooltip>
                                    </InputAdornment>
                                ) : null
                            }
                            prefix={'quantity:'}
                            size="medium"
                            style={{
                                padding: 10,
                                borderRadius: '10px',
                                width: '100%',
                            }}
                            type="number"
                            value={
                                addedStockItems.find((asi) => asi.id === stockItem.id)?.amountToAdd
                            }
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                updateAddedStockItemAmountInput(stockItem.id, e.target.value)
                            }}
                            onWheel={numberInputOnWheelPreventChange}
                        />
                    )
                } else {
                    return (
                        <Input
                            startAdornment={
                                isStockItemInputFaulty(stockItem) ? (
                                    <InputAdornment position="start">
                                        <Tooltip title="Check the input values!">
                                            <WarningAmberIcon htmlColor="red" />
                                        </Tooltip>
                                    </InputAdornment>
                                ) : null
                            }
                            prefix={'quantity:'}
                            size="medium"
                            style={{
                                padding: 10,
                                borderRadius: '10px',
                                width: '100%',
                            }}
                            type="number"
                            disabled={isStockItemDeleted(stockItem.id)}
                            value={stockItemAmountInputs[stockItem.id]}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                updateStockItemInput(stockItem.id, e.target.value)
                            }}
                            onWheel={numberInputOnWheelPreventChange}
                        />
                    )
                }
            },
        },
        {
            title: '',
            width: 80,
            render: (stockItem: StockItemCallOff | AddedSopStockItemOrderline) => {
                if (isAddedSopStockItemOrderline(stockItem)) {
                    return (
                        <IconButton onClick={() => onDeleteAddedStockItem(stockItem.id)}>
                            <DeleteIcon />
                        </IconButton>
                    )
                } else {
                    return (
                        <>
                            {!isStockItemDeleted(stockItem.id) && (
                                <IconButton onClick={() => onDeleteStockItem(stockItem.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                            {isStockItemDeleted(stockItem.id) && (
                                <IconButton onClick={() => restoreDeletedStockItem(stockItem.id)}>
                                    <RefreshIcon />
                                </IconButton>
                            )}
                        </>
                    )
                }
            },
        },
    ]
    const builtItemColumns = [
        {
            title: 'Code',
            width: 300,
            render: (builtItem: BuiltItemCallOff | AddedSopBuiltItemOrderline) => {
                if (isAddedSopBuiltItemOrderline(builtItem)) {
                    return builtItem?.builtItem?.bomCode
                } else {
                    return builtItem.sopBuiltItemOrderline?.builtItem?.bomCode
                }
            },
        },
        {
            title: 'SOP amount/allocated',
            width: 200,
            render: (builtItem: BuiltItemCallOff | AddedSopBuiltItemOrderline) => {
                if (isAddedSopBuiltItemOrderline(builtItem)) {
                    return `${builtItem.amount}/${builtItem.reserved}`
                } else {
                    return `${builtItem.sopBuiltItemOrderline.amount}/${builtItem.sopBuiltItemOrderline.reserved}`
                }
            },
        },
        {
            title: 'Current amount/Allocated for assembly',
            render: (builtItem: BuiltItemCallOff | AddedSopBuiltItemOrderline) => {
                if (isAddedSopBuiltItemOrderline(builtItem)) {
                    return ''
                } else {
                    return `${builtItem.amount}/${
                        builtItemCompletedDetails &&
                        builtItemCompletedDetails[Number(builtItem.id)].assemblyAllocatedAmount
                    }`
                }
            },
        },
        {
            title: 'Update amount',
            width: 200,
            render: (builtItem: BuiltItemCallOff | AddedSopBuiltItemOrderline) => {
                if (isAddedSopBuiltItemOrderline(builtItem)) {
                    return (
                        <Input
                            startAdornment={
                                isAddedBuiltItemInputFaulty(builtItem) ? (
                                    <InputAdornment position="start">
                                        <Tooltip title="Check the input values!">
                                            <WarningAmberIcon htmlColor="red" />
                                        </Tooltip>
                                    </InputAdornment>
                                ) : null
                            }
                            error={isAddedBuiltItemInputFaulty(builtItem)}
                            prefix={'quantity:'}
                            size="medium"
                            style={{
                                padding: 10,
                                borderRadius: '10px',
                                width: '100%',
                            }}
                            type="number"
                            value={
                                addedBuiltItems.find((abi) => abi.id === builtItem.id)?.amountToAdd
                            }
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                updateAddedBuiltItemAmountInput(builtItem.id, e.target.value)
                            }}
                            onWheel={numberInputOnWheelPreventChange}
                        />
                    )
                } else {
                    return (
                        <Input
                            startAdornment={
                                isBuiltItemInputFaulty(builtItem) ? (
                                    <InputAdornment position="start">
                                        <Tooltip title="Check the input values!">
                                            <WarningAmberIcon htmlColor="red" />
                                        </Tooltip>
                                    </InputAdornment>
                                ) : null
                            }
                            error={isBuiltItemInputFaulty(builtItem)}
                            prefix={'quantity:'}
                            size="medium"
                            style={{
                                padding: 10,
                                borderRadius: '10px',
                                width: '100%',
                            }}
                            type="number"
                            disabled={isBuiltItemDeleted(builtItem.id)}
                            value={builtItemAmountInputs[builtItem.id]}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                updateBuiltItemInput(builtItem.id, e.target.value)
                            }}
                            onWheel={numberInputOnWheelPreventChange}
                        />
                    )
                }
            },
        },
        {
            title: '',
            width: 80,
            render: (builtItem: BuiltItemCallOff | AddedSopBuiltItemOrderline) => {
                if (isAddedSopBuiltItemOrderline(builtItem)) {
                    return (
                        <IconButton onClick={() => onDeleteAddedBuiltItem(builtItem.id)}>
                            <DeleteIcon />
                        </IconButton>
                    )
                } else {
                    return (
                        <>
                            {!isBuiltItemDeleted(builtItem.id) && (
                                <IconButton onClick={() => onDeleteBuiltItem(builtItem.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                            {isBuiltItemDeleted(builtItem.id) && (
                                <IconButton onClick={() => restoreDeletedBuiltItem(builtItem.id)}>
                                    <RefreshIcon />
                                </IconButton>
                            )}
                        </>
                    )
                }
            },
        },
    ]

    return (
        <Dialog open={true} onClose={onClose} style={{ zIndex: 100 }} fullWidth maxWidth="lg">
            <Paper style={{ maxHeight: '1000px', padding: '25px' }}>
                <Box
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box> Update Call Off {callOffId} </Box>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                {modalState === ModalState.Loading && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            margin: '20px',
                            width: '100%',
                            height: '500px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                    </Box>
                )}
                {modalState === ModalState.Error && <Box>Something went wrong</Box>}
                {modalState === ModalState.NotFound && (
                    <Box>Could not find the call off with the id of {callOffId}</Box>
                )}
                {modalState === ModalState.Success && (
                    <Box>
                        <InputContainer>
                            <Label text="Date to be completed by" />
                            <DatePicker
                                style={{ width: '100%' }}
                                format="DD-MM-YYYY"
                                value={dateToBeCompletedBy}
                                disabledDate={disabledDate}
                                onChange={(value) => setDateToBeCompletedBy(value)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <Label text="Dispatch date" />
                            <DatePicker
                                style={{ width: '100%' }}
                                format="DD-MM-YYYY"
                                value={dispatchDate}
                                disabledDate={disabledDate}
                                onChange={(value) => setDispatchDate(value)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <Label text="Customer delivery date" />
                            <DatePicker
                                style={{ width: '100%' }}
                                format="DD-MM-YYYY"
                                value={deliveryDate}
                                disabledDate={disabledDate}
                                onChange={(value) => setDeliveryDate(value)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <Label text="Title" />
                            <Input
                                size="medium"
                                style={{
                                    padding: 10,
                                    borderRadius: '10px',
                                    width: '100%',
                                }}
                                value={title}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setTitle(e.target.value)
                                }
                            />
                        </InputContainer>
                        <InputContainer>
                            <Label text="Dispatch Comment" />
                            <Input
                                size="medium"
                                style={{
                                    padding: 10,
                                    borderRadius: '10px',
                                    width: '100%',
                                }}
                                value={dispatchComment}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setDispatchComment(e.target.value)
                                }
                            />
                        </InputContainer>
                        {callOff?.builtItemCallOffs && callOff.builtItemCallOffs.length > 0 && (
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <h2>Built items</h2>
                                    <FormControl sx={{ m: 1, width: 300 }}>
                                        <InputLabel>Add Built item</InputLabel>
                                        <Select
                                            disabled={availableSopBuiltItemOrderlines.length === 0}
                                            value=""
                                            defaultValue="Add built item"
                                            onChange={(event: any) =>
                                                onAddBuiltItemSop(event.target.value)
                                            }
                                            input={<OutlinedInput label="Add Built item" />}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 48 * 4.5 + 8,
                                                        width: 250,
                                                    },
                                                },
                                            }}
                                        >
                                            {availableSopBuiltItemOrderlines.map(
                                                (builtItemOrderline) => (
                                                    <MenuItem
                                                        key={builtItemOrderline.id}
                                                        value={builtItemOrderline.id}
                                                    >
                                                        {builtItemOrderline.code}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Table
                                    rowClassName={(builtItem, index) => {
                                        if (isAddedSopBuiltItemOrderline(builtItem)) {
                                            return 'added-row'
                                        } else if (isBuiltItemDeleted(builtItem.id)) {
                                            return 'deleted-row'
                                        } else if (isBuiltItemUpdated(builtItem)) {
                                            return 'updated-row'
                                        } else {
                                            return ''
                                        }
                                    }}
                                    size="small"
                                    className="no-hover"
                                    dataSource={[...callOff.builtItemCallOffs, ...addedBuiltItems]}
                                    columns={builtItemColumns}
                                    pagination={false}
                                />
                            </Box>
                        )}
                        {callOff?.stockItemCallOffs && callOff.stockItemCallOffs.length > 0 && (
                            <Box style={{ marginTop: '20px' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <h2>Stock items</h2>
                                    <FormControl sx={{ m: 1, width: 300 }}>
                                        <InputLabel>Add Stock Item</InputLabel>
                                        <Select
                                            value=""
                                            disabled={availableSopStockItemOrderlines.length === 0}
                                            defaultValue="Add Stock Item"
                                            onChange={(event: any) =>
                                                onAddStockItemSop(event.target.value)
                                            }
                                            input={<OutlinedInput label="Add Stock Item" />}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 48 * 4.5 + 8,
                                                        width: 250,
                                                    },
                                                },
                                            }}
                                        >
                                            {availableSopStockItemOrderlines.map(
                                                (stockItemOrderlines) => (
                                                    <MenuItem
                                                        key={stockItemOrderlines.id}
                                                        value={stockItemOrderlines.id}
                                                    >
                                                        {stockItemOrderlines.code}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Table
                                    rowClassName={(stockItem, index) => {
                                        if (isAddedSopStockItemOrderline(stockItem)) {
                                            return 'added-row'
                                        } else if (isStockItemDeleted(stockItem.id)) {
                                            return 'deleted-row'
                                        } else if (isStockItemUpdated(stockItem)) {
                                            return 'updated-row'
                                        } else {
                                            return ''
                                        }
                                    }}
                                    size="small"
                                    className="no-hover"
                                    dataSource={[...callOff.stockItemCallOffs, ...addedStockItems]}
                                    columns={stockItemColumns}
                                    pagination={false}
                                />
                            </Box>
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                            <Button variant="outlined" disabled={isSubmitDisabled()} onClick={onOk}>
                                Update
                            </Button>
                        </Box>
                    </Box>
                )}
            </Paper>
        </Dialog>
    )
}
