import React from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import MapIcon from '@mui/icons-material/Map'
import ContentPasteSearch from '@mui/icons-material/ContentPasteSearch'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import BuildIcon from '@mui/icons-material/Build'
import AssignmentIcon from '@mui/icons-material/Assignment'
import ConstructionIcon from '@mui/icons-material/Construction'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import { FiPackage } from 'react-icons/fi'
import PeopleIcon from '@mui/icons-material/People'
import { UserRole } from '../../models/models'
import { FilterNone, ThumbUpOffAlt } from '@mui/icons-material'
import { Urls } from '../../util/urls'

export interface NavigationOption {
    text: string
    icon: React.ReactElement
    link?: string
    onClick?: () => void
    options?: NavigationOption[]
    permissions?: UserRole[]
}

export const navigationOptions: NavigationOption[] = [
    {
        text: 'Project Master',
        link: Urls.ProjectMaster,
        icon: <AccessTimeIcon />,
        permissions: [
            UserRole.SystemAdmin,
            UserRole.SavAdmin,
            UserRole.ProjectManager,
            UserRole.AccountManager,
            UserRole.ApplicationEngineer,
            UserRole.Scheduler,
        ],
    },
    {
        text: 'Production Schedule',
        link: Urls.ProductionSchedule,
        icon: <CalendarViewMonthIcon />,
        permissions: [
            UserRole.SystemAdmin,
            UserRole.SavAdmin,
            UserRole.ProjectManager,
            UserRole.AccountManager,
            UserRole.ApplicationEngineer,
            UserRole.Scheduler,
        ],
    },
    {
        text: 'Warehouse Map',
        link: Urls.WarehouseMap,
        icon: <MapIcon />,
        permissions: [
            UserRole.SystemAdmin,
            UserRole.SavAdmin,
            UserRole.ProjectManager,
            UserRole.AccountManager,
            UserRole.ApplicationEngineer,
            UserRole.Scheduler,
            UserRole.Assembler,
            UserRole.LogisticsEmployee,
        ],
    },
    {
        text: 'Stock Manager',
        link: Urls.StockManager,
        icon: <ContentPasteSearch />,
        permissions: [
            UserRole.SystemAdmin,
            UserRole.SavAdmin,
            UserRole.LogisticsEmployee,
            UserRole.ProjectManager,
            UserRole.Scheduler,
        ],
    },
    {
        text: 'Product Authorization',
        link: Urls.ProductAuthorization,
        icon: <ThumbUpOffAlt />,
        permissions: [
            UserRole.SystemAdmin,
            UserRole.SavAdmin,
            UserRole.Scheduler,
            UserRole.ApplicationEngineer,
        ],
    },
    {
        text: 'Products',
        link: Urls.Products,
        icon: <BuildIcon />,
        permissions: [
            UserRole.SystemAdmin,
            UserRole.SavAdmin,
            UserRole.Scheduler,
            UserRole.ApplicationEngineer,
        ],
    },
    {
        text: 'Assembly worksheets',
        link: Urls.WorksheetsAssembly,
        icon: <BuildIcon />,
        permissions: [UserRole.Assembler],
    },
    {
        text: 'Assemblies',
        icon: <PrecisionManufacturingIcon />,
        link: Urls.Assemblies,
        permissions: [
            UserRole.ProjectManager,
            UserRole.SavAdmin,
            UserRole.SystemAdmin,
            UserRole.LogisticsEmployee,
            UserRole.ApplicationEngineer,
            UserRole.Scheduler,
        ],
    },
    {
        text: 'General Assemblies',
        icon: <ConstructionIcon />,
        link: Urls.GeneralAssemblies,
        permissions: [
            UserRole.ProjectManager,
            UserRole.SavAdmin,
            UserRole.SystemAdmin,
            UserRole.Scheduler,
        ],
    },
    {
        text: 'Call Offs',
        icon: <FilterNone />,
        link: Urls.CallOffs,
        permissions: [
            UserRole.ProjectManager,
            UserRole.SavAdmin,
            UserRole.SystemAdmin,
            UserRole.ApplicationEngineer,
            UserRole.Scheduler,
        ],
    },
    {
        text: 'Orders',
        link: Urls.Orders,
        icon: <ReceiptLongIcon />,
        permissions: [
            UserRole.SystemAdmin,
            UserRole.SavAdmin,
            UserRole.ProjectManager,
            UserRole.LogisticsEmployee,
            UserRole.AccountManager,
            UserRole.Scheduler,
        ],
    },
    {
        text: 'Worksheets',
        icon: <DescriptionOutlinedIcon />,
        permissions: [
            UserRole.SystemAdmin,
            UserRole.ProjectManager,
            UserRole.Scheduler,
            UserRole.AccountManager,
            UserRole.LogisticsEmployee,
        ],
        options: [
            {
                text: 'Logistics',
                link: Urls.WorksheetsLogistics,
                icon: <FiPackage />,
                permissions: [
                    UserRole.SystemAdmin,
                    UserRole.ProjectManager,
                    UserRole.Scheduler,
                    UserRole.LogisticsEmployee,
                ],
            },
            {
                text: 'Assembly',
                link: Urls.WorksheetsAssembly,
                icon: <BuildIcon />,
                permissions: [
                    UserRole.SystemAdmin,
                    UserRole.ProjectManager,
                    UserRole.Scheduler,
                    UserRole.LogisticsEmployee,
                    UserRole.Assembler,
                ],
            },
            {
                text: 'Tasks',
                link: Urls.Tasks,
                icon: <AssignmentIcon />,
                permissions: [
                    UserRole.SystemAdmin,
                    UserRole.ProjectManager,
                    UserRole.Scheduler,
                    UserRole.ProjectManager,
                ],
            },
        ],
    },
    {
        text: 'Calendar',
        link: Urls.Calendar,
        icon: <InsertInvitationIcon />,
        permissions: [
            UserRole.SystemAdmin,
            UserRole.SavAdmin,
            UserRole.Scheduler,
            UserRole.AccountManager,
        ],
    },
    {
        text: 'Users',
        link: Urls.Users,
        icon: <PeopleIcon />,
        permissions: [UserRole.SystemAdmin, UserRole.SavAdmin],
    },
]
