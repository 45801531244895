import { Table, TableColumnType } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { Response } from '../../api/util/with-response-formatter-interceptor'
import { UnauthorizedProduct } from '../../models/models'
import { toastFailure } from '../../util/toast'
import useDebounce from '../project-master/single-project-view/hooks'
import moment from 'moment'

interface Props {
    pagination?: boolean
    //antd ColumnType<T> does not have title property
    columns?: Array<TableColumnType<UnauthorizedProduct>>
    actionComponents?: Array<(UnauthorizedProduct: UnauthorizedProduct) => JSX.Element>
    dependencies?: any
    request: (pagination?: {
        skip: number
        limit: number
    }) => Promise<Response<{ count: number; entities: UnauthorizedProduct[] }>>
}

export const UnauthorizedProductsTableColumns: {
    [key in string]: TableColumnType<UnauthorizedProduct>
} = {
    Code: {
        title: 'Code',
        render: (product: UnauthorizedProduct) => {
            return product.code
        },
    },
    Description: {
        title: 'Description',
        render: (product: UnauthorizedProduct) => {
            return <div>{product.description}</div>
        },
    },
    ProductGroup: {
        title: 'Product Group',
        render: (product: UnauthorizedProduct) => {
            return <div>{product.productGroup}</div>
        },
    },
    Name: {
        title: 'Name',
        render: (product: UnauthorizedProduct) => {
            return <div>{product.name}</div>
        },
    },
    RequestedBy: {
        title: 'Requested by',
        render: (product: UnauthorizedProduct) => {
            return (
                <div>
                    {product?.requestedByUser ? `${product?.requestedByUser?.email}` : 'Unknown'}
                </div>
            )
        },
    },
    RequestedFor: {
        title: 'Requested for',
        render: (product: UnauthorizedProduct) => {
            return <div>{product?.requestedForProject?.name || 'Unknown'}</div>
        },
    },
    RequestedAt: {
        title: 'Requested at',
        render: (product: UnauthorizedProduct) => {
            return <div>{moment(product.timeCreatedAt).format('DD/MM/YYYY')}</div>
        },
    },
} as const

const DEFAULT_COLUMNS = [
    UnauthorizedProductsTableColumns.Code,
    UnauthorizedProductsTableColumns.Description,
    UnauthorizedProductsTableColumns.Name,
    UnauthorizedProductsTableColumns.Note,
    UnauthorizedProductsTableColumns.RequestedBy,
    UnauthorizedProductsTableColumns.RequestedFor,
    UnauthorizedProductsTableColumns.RequestedAt,
]

export default function UnauthorizedProductsTable({
    request,
    columns,
    pagination,
    actionComponents,
    dependencies,
}: Props) {
    const [UnauthorizedProducts, setUnauthorizedProducts] = useState<UnauthorizedProduct[]>([])
    const [UnauthorizedProductsAmount, setUnauthorizedProductsAmount] = useState<number>(0)
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)

    const getUnauthorizedProductsDeps = useDebounce(JSON.stringify(dependencies), 200)

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const tableColumns = useMemo(() => {
        let cols = columns ? Object.values(columns) : DEFAULT_COLUMNS
        if (actionComponents) {
            cols = [
                ...cols,
                {
                    title: 'Actions',
                    key: 'actions',
                    render: (UnauthorizedProduct: UnauthorizedProduct) => {
                        return (<>{actionComponents.map((a) => a(UnauthorizedProduct))}</>) as any
                    },
                },
            ]
        }
        return cols
    }, [columns])

    const getUnauthorizedProducts = async (resetPage = false) => {
        const response = await request({
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        })

        if (response.successful) {
            const { count, entities } = response.data
            resetPage && setPage(1)
            setUnauthorizedProductsAmount(count)
            setUnauthorizedProducts(entities)
        } else {
            toastFailure(response.message)
        }
    }

    // this happens when dependencies change outside the table, e.g filters given in with getUnauthorizedProductsDeps
    useEffect(() => {
        getUnauthorizedProducts(true)
    }, [getUnauthorizedProductsDeps, rowsPerPage])

    // this happens when dependencies change outside the table, e.g filters given in with getUnauthorizedProductsDeps
    useEffect(() => {
        getUnauthorizedProducts()
    }, [page])

    return (
        <Table
            rowKey="id"
            columns={tableColumns}
            dataSource={UnauthorizedProducts}
            expandable={undefined}
            size="small"
            pagination={
                pagination
                    ? {
                          defaultPageSize: 10,
                          current: page,
                          onChange: pageChange,
                          total: UnauthorizedProductsAmount,
                      }
                    : false
            }
        />
    )
}
