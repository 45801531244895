import React, { ReactElement, useEffect } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import {
    changeForgottenPasswordRequest,
    validateResetPasswordTokenRequest,
} from '../../../api/auth'
import Grid from '@mui/material/Grid'
import SAV_landing from '../../../images/SAV_Frame_Landing.svg'
import { mainSAVTheme } from '../../../theme/Theme'
import { Button, TextField, Typography } from '@mui/material'
import { User } from '../../../models/models'
import { toast, ToastContainer } from 'react-toastify'
import { Urls } from '../../../util/urls'

interface RouteParams {
    token: string
}

interface ResetPasswordState {
    password?: string
    resetPassword?: string
}

type Props = RouteComponentProps<RouteParams>

export default function ResetPassword({
    match: {
        params: { token },
    },
    history,
}: Props): ReactElement {
    const [user, setUser] = React.useState<User | null>(null)
    const [loading, setLoading] = React.useState(false)
    const [invalidUser, setInvalidUser] = React.useState(false)
    const [resetPasswordState, setResetPasswordState] = React.useState<ResetPasswordState>({})
    const [redirect, setRedirect] = React.useState<true | null>(null)
    useEffect(() => {
        ;(async () => {
            setLoading(true)
            const response = await validateResetPasswordTokenRequest(token)
            if (response.successful) {
                setLoading(false)
                setUser(response.data)
            } else {
                setInvalidUser(true)
            }
        })()
    }, [])

    const onSubmitPasswordChange = (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault()
        const { password, resetPassword } = resetPasswordState
        if (
            password &&
            password.length >= 5 &&
            password.length <= 20 &&
            resetPassword &&
            resetPassword?.length >= 5 &&
            resetPassword.length <= 20
        ) {
            if (password === resetPassword) {
                ;(async () => {
                    setLoading(true)
                    const response = await changeForgottenPasswordRequest(
                        token,
                        password,
                        resetPassword
                    )
                    setLoading(false)
                    if (response.successful) {
                        //toast.success(data)
                        setRedirect(true)
                    }
                })()
            } else {
                toast.warning('Passwords do not match')
            }
        } else {
            toast.warning('Password must be between 5 and 20 characters')
        }
    }

    if (invalidUser) {
        history.push(Urls.Landing)
    }

    if (redirect) {
        return (
            <Redirect
                push
                to={{
                    pathname: Urls.Landing,
                    state: {
                        toastMessage: 'Password changed successfully',
                    },
                }}
            />
        )
    }
    return (
        <>
            {user && (
                <div
                    className="Login"
                    style={{
                        backgroundColor: `${mainSAVTheme.palette.primary.main}`,
                        height: '100vh',
                    }}
                >
                    <Grid container component="main" sx={{ height: '100vh', padding: '90px 91px' }}>
                        <Grid
                            item
                            xs={false}
                            sm={4}
                            md={7}
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                src={SAV_landing}
                                alt="SAV_landing_img"
                                style={{
                                    flexShrink: 0,
                                    minWidth: '100%',
                                    minHeight: '100%',
                                    maxHeight: '80vh',
                                }}
                            ></img>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} style={{ position: 'relative' }}>
                            <Typography
                                variant="h4"
                                style={{ color: 'white', textAlign: 'center' }}
                            >
                                Reset Password for {user.firstName} {user.lastName}
                                <br />
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                style={{ color: 'white', textAlign: 'center' }}
                            >
                                Type in your new password below and we can sign you in <br />
                            </Typography>
                            <TextField
                                color="secondary"
                                margin="normal"
                                required
                                fullWidth
                                type="password"
                                id="password"
                                label="Password"
                                name="password"
                                onChange={(e) =>
                                    setResetPasswordState({
                                        ...resetPasswordState,
                                        password: e.target.value,
                                    })
                                }
                                autoFocus
                            />
                            <TextField
                                color="secondary"
                                margin="normal"
                                required
                                fullWidth
                                name="password-confirm"
                                label="Password Confirmation"
                                type="password"
                                id="passwordConfirm"
                                onChange={(e) =>
                                    setResetPasswordState({
                                        ...resetPasswordState,
                                        resetPassword: e.target.value,
                                    })
                                }
                                autoComplete="current-password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={onSubmitPasswordChange}
                            >
                                Change Password
                            </Button>
                        </Grid>
                    </Grid>
                    <ToastContainer />
                </div>
            )}
        </>
    )
}
