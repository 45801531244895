import { Dialog, IconButton, Divider, Paper, Tab } from '@mui/material'
import { Box, styled } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useState } from 'react'
import { OrderConfirmation } from '../../../models/models'
import { toastFailure } from '../../../util/toast'
import CTRLoader from '../../ui/loader/CTRloader'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import DetailsTab from './DetailsTab'
import {
    GetOrderConfirmationRelations,
    getOrderConfirmationRequest,
} from '../../../api/order-confirmation'
import OrderlinesTab from './OrderlinesTab'
import DeliveriesTab from './DeliveriesTab'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

const Content = styled(Box)({
    width: '100%',
})

interface Props {
    orderConfirmationId: number
    onClose: () => void
    active: boolean
}

enum State {
    Loading = 'Loading',
    Found = 'Found',
    NotFound = 'NotFound',
    Error = 'Error',
}

export type Tabs = 'Details' | 'Orderlines' | 'Deliveries'

export default function InspectOrderConfirmationModal(props: Props) {
    const { orderConfirmationId, onClose, active } = props
    const [activeTab, setActiveTab] = useState<Tabs>('Details')
    const handleTabChange = (event: React.SyntheticEvent, tabKey: Tabs) => {
        event.preventDefault()
        setActiveTab(tabKey)
    }
    const [orderConfirmation, setOrderConfirmation] = useState<OrderConfirmation>()
    const [state, setState] = useState<State>(State.Loading)

    const getPurchaseOrder = async () => {
        const response = await getOrderConfirmationRequest(orderConfirmationId, {
            relations: [
                GetOrderConfirmationRelations.Orderlines,
                GetOrderConfirmationRelations.Author,
                GetOrderConfirmationRelations.PurchaseOrder,
                GetOrderConfirmationRelations.OrderlinePurchaseOrderOrderline,
            ],
        })

        if (response.successful) {
            setOrderConfirmation(response.data)
            setState(State.Found)
        } else if (response.status === 404) {
            setState(State.NotFound)
            toastFailure('Could not find sales order')
        } else {
            setState(State.Error)
            toastFailure('Something went wrong retrieving the sales order')
        }
    }
    useEffect(() => {
        getPurchaseOrder()
    }, [])

    return (
        <Dialog open={active} onClose={onClose} fullWidth maxWidth="md">
            <Paper style={{ height: '100%', maxHeight: '800px' }}>
                <Box
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div> Order Confirmation </div>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Container>
                    {state === State.Loading && (
                        <Content
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                margin: '20px',
                                width: '100%',
                                height: '400px',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CTRLoader size="xl" />
                        </Content>
                    )}
                    {orderConfirmation && state === State.Found && (
                        <Content>
                            <TabContext value={activeTab}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        padding: '20px 50px 0px 50px',
                                    }}
                                >
                                    <TabList onChange={handleTabChange}>
                                        <Tab
                                            label="Details"
                                            value="Details"
                                            style={{ textTransform: 'none' }}
                                        />
                                        <Tab
                                            label="Orderlines"
                                            value="Orderlines"
                                            style={{ textTransform: 'none' }}
                                        />
                                        <Tab
                                            label="Deliveries"
                                            value="Deliveries"
                                            style={{ textTransform: 'none' }}
                                        />
                                    </TabList>
                                </Box>

                                <TabPanel value="Details">
                                    <DetailsTab orderConfirmation={orderConfirmation} />
                                </TabPanel>
                                <TabPanel value="Orderlines">
                                    <OrderlinesTab orderConfirmation={orderConfirmation} />
                                </TabPanel>
                                <TabPanel value="Deliveries">
                                    <DeliveriesTab orderConfirmation={orderConfirmation} />
                                </TabPanel>
                            </TabContext>
                        </Content>
                    )}
                </Container>
            </Paper>
        </Dialog>
    )
}
