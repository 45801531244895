import { Table, TableColumnType } from 'antd'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { Response } from '../../api/util/with-response-formatter-interceptor'
import { GeneralAssembly } from '../../models/models'
import { toastFailure } from '../../util/toast'
import useDebounce from '../project-master/single-project-view/hooks'
import GeneralAssemblyLinesTable from './GeneralAssemblyLinesTable'

interface Props {
    pagination?: boolean
    //antd ColumnType<T> does not have title property
    expandable?: boolean
    columns?: Array<TableColumnType<GeneralAssembly>>
    actionComponents?: Array<(generalAssembly: GeneralAssembly) => JSX.Element>
    dependencies?: any
    request: (pagination?: {
        skip: number
        limit: number
    }) => Promise<Response<{ count: number; entities: GeneralAssembly[] }>>
}

export const GeneralAssembliesTableColumns = {
    Id: {
        title: 'Id',
        key: 'id',
        render: (generalAssembly: GeneralAssembly) => {
            return generalAssembly.id
        },
    },
    Description: {
        title: 'Description',
        key: 'description',
        width: '350px',
        render: (generalAssembly: GeneralAssembly) => {
            return generalAssembly.description
        },
    },
    Author: {
        title: 'Created by',
        key: 'createdBy',
        render: (generalAssembly: GeneralAssembly) => {
            return generalAssembly?.createdByUser
                ? `${generalAssembly?.createdByUser?.firstName} ${generalAssembly?.createdByUser?.lastName}`
                : 'None'
        },
    },
    CompletedAt: {
        title: 'Completed at',
        key: 'completedAt',
        render: (generalAssembly: GeneralAssembly) => {
            return generalAssembly.completedAt
                ? moment(generalAssembly.completedAt).format('DD/MM/YYYY')
                : 'Not completed'
        },
    },
    DateToBeCompletedBy: {
        title: 'Date to be completed',
        key: 'dateToBeCompletedBy',
        render: (generalAssembly: GeneralAssembly) => {
            return moment(generalAssembly.dateToBeCompletedBy).format('DD/MM/YYYY')
        },
    },
    Amount: {
        title: 'Amount',
        key: 'amount',
        render: (generalAssembly: GeneralAssembly) => {
            return generalAssembly?.lines.length || `Don't know`
        },
    },
} as const

const DEFAULT_COLUMNS = [
    GeneralAssembliesTableColumns.Author,
    GeneralAssembliesTableColumns.Amount,
    GeneralAssembliesTableColumns.Description,
]

const expandedRowRender = (generalAssembly: GeneralAssembly) => {
    return <GeneralAssemblyLinesTable datasource={generalAssembly.lines} pagination={true} />
}

export default function GeneralAssembliesTable({
    request,
    columns,
    pagination,
    actionComponents,
    dependencies,
    expandable,
}: Props) {
    const [generalAssemblies, setGeneralAssemblies] = useState<GeneralAssembly[]>([])
    const [generalAssembliesAmount, setGeneralAssembliesAmount] = useState<number>(0)
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)

    const getGeneralAssembliesDeps = useDebounce(JSON.stringify(dependencies), 200)

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const tableColumns = useMemo(() => {
        let cols = columns ? Object.values(columns) : DEFAULT_COLUMNS
        if (actionComponents) {
            cols = [
                ...cols,
                {
                    title: 'Actions',
                    key: 'actions',
                    render: (generalAssembly: GeneralAssembly) => {
                        return (<>{actionComponents.map((a) => a(generalAssembly))}</>) as any
                    },
                },
            ]
        }
        return cols
    }, [columns])

    const getGeneralAssemblies = async (resetPage = false) => {
        const response = await request({
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        })
        if (response.successful) {
            const { count, entities } = response.data
            resetPage && setPage(1)
            setGeneralAssembliesAmount(count)
            setGeneralAssemblies(entities)
        } else {
            toastFailure(response.message)
        }
    }

    // this happens when dependencies change outside the table, e.g filters given in with getGeneralAssembliesDeps
    useEffect(() => {
        getGeneralAssemblies(true)
    }, [getGeneralAssembliesDeps, rowsPerPage])

    // this happens when dependencies change outside the table, e.g filters given in with getGeneralAssembliesDeps
    useEffect(() => {
        getGeneralAssemblies()
    }, [page])

    return (
        <Table
            rowKey="id"
            columns={tableColumns}
            dataSource={generalAssemblies}
            expandable={expandable ? { expandedRowRender } : undefined}
            size="small"
            pagination={
                pagination
                    ? {
                          defaultPageSize: 10,
                          current: page,
                          onChange: pageChange,
                          total: generalAssembliesAmount,
                      }
                    : false
            }
        />
    )
}
