import { ProjectDocumentVersion, User } from '../../../../models/models'
import { Box, Typography } from '@mui/material'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Modal, Tooltip } from 'antd'
import { useState } from 'react'

const { confirm } = Modal

interface Props {
    documentVersion: ProjectDocumentVersion
    onSubmit: (documentVersion: ProjectDocumentVersion) => void
    onArchive: (documentVersion: ProjectDocumentVersion) => void
    user: User
}

export default function SubmitControl(props: Props) {
    const { documentVersion, onSubmit, onArchive } = props
    const [isHovering, setIsHovering] = useState<boolean>(false)

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '15px',
                alignItems: 'center',
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            {!documentVersion.timeSubmittedAt && !documentVersion.isArchived && (
                <>
                    <Tooltip title={'Submit document'}>
                        <CheckCircleOutlined
                            style={{ fontSize: 22, color: 'turquoise' }}
                            onClick={() => onSubmit(documentVersion)}
                        />
                    </Tooltip>
                    <Tooltip title={'Archive document'}>
                        <CloseCircleOutlined
                            style={{ fontSize: 22, color: 'grey' }}
                            onClick={() => {
                                confirm({
                                    title: 'Archive document version?',
                                    icon: <CloseCircleOutlined size={24} color={'grey'} />,
                                    content:
                                        'Are you sure you want to archive the document version? The document version will no longer be shown.',
                                    onOk() {
                                        onArchive(documentVersion)
                                    },
                                })
                            }}
                        />
                    </Tooltip>
                </>
            )}
            {!!documentVersion.timeSubmittedAt && !documentVersion.isArchived && (
                <>
                    {isHovering ? (
                        <Tooltip title={'Archive document'} visible={true}>
                            <CloseCircleOutlined
                                style={{ fontSize: 22, color: 'grey' }}
                                onClick={() => {
                                    confirm({
                                        title: 'Archive document version?',
                                        icon: <CloseCircleOutlined size={24} color={'grey'} />,
                                        content:
                                            'Are you sure you want to revert the submission, and archive the document version?',
                                        onOk() {
                                            onArchive(documentVersion)
                                        },
                                    })
                                }}
                            />
                        </Tooltip>
                    ) : (
                        <CheckCircleOutlined style={{ fontSize: 22, color: 'grey' }} />
                    )}
                    <Typography fontSize={14}>Submitted</Typography>
                </>
            )}
            {!documentVersion.timeSubmittedAt && documentVersion.isArchived && (
                <>
                    <CloseCircleOutlined style={{ fontSize: 22, color: 'grey' }} />
                    <Typography fontSize={14}>Archived</Typography>
                </>
            )}
        </Box>
    )
}
