import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

export enum ProductAuthorizationView {
    Products = 'Products',
    Boms = 'Boms',
    ExportBoms = 'ExportBoms',
}

export enum ActiveModal {
    ImportSageBoms = 'ImportSageBoms',
    ImportInternalBom = 'ImportInternalBom',
    ExportBoms = 'ExportBoms',
    AuthorizeBomModal = 'AuthorizeBomModal',
    InspectBom = 'InspectBom',
    InspectUnauthorizedBom = 'InspectUnauthorizedBom',
    None = 'None',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProductFilters {}

export interface BomFilters {
    code?: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ExportBomFilters {}

export interface ProductState {
    activeView: ProductAuthorizationView
    activeModal: ActiveModal
    modalEntityId?: number
    exportBomFilters: ExportBomFilters
    productFilters: ProductFilters
    bomFilters: BomFilters
    // token that tells to update the table
    updateTableToken?: string
}

export interface AssemblyFieldFilters {
    assemblyFieldId?: number
    name?: string
}

const initialState: ProductState = {
    activeView: ProductAuthorizationView.Products,
    productFilters: {},
    bomFilters: {},
    exportBomFilters: {},
    activeModal: ActiveModal.None,
    modalEntityId: undefined,
    updateTableToken: undefined,
}

export const productAuthorizationSlice = createSlice({
    name: 'product-authorization',
    initialState,
    reducers: {
        resetState(state) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state = initialState
        },
        openModal(
            state,
            action: PayloadAction<{ activeModal: ActiveModal; modalEntityId?: number }>
        ) {
            state.activeModal = action.payload.activeModal
            state.modalEntityId = action.payload.modalEntityId
        },
        setProductFilters(state, action: PayloadAction<ProductFilters>) {
            state.productFilters = action.payload
        },
        setBomFilters(state, action: PayloadAction<BomFilters>) {
            state.bomFilters = action.payload
        },
        setExportBomFilters(state, action: PayloadAction<ExportBomFilters>) {
            state.exportBomFilters = action.payload
        },
        setUpdateTableToken(state, action: PayloadAction<string | undefined>) {
            state.updateTableToken = action.payload
        },
        closeModal(state, action: PayloadAction<string | undefined>) {
            state.activeModal = ActiveModal.None
            state.updateTableToken = action.payload
            state.modalEntityId = undefined
        },
        setActiveView(state, action: PayloadAction<ProductAuthorizationView>) {
            state.activeView = action.payload
        },
    },
})

export const selectActiveView = (state: RootState) => state.callOff.activeView

export const {
    resetState,
    setActiveView,
    openModal,
    closeModal,
    setProductFilters,
    setBomFilters,
    setUpdateTableToken,
} = productAuthorizationSlice.actions

export default productAuthorizationSlice.reducer
