import { Box, styled } from '@mui/system'
import { Button, Input, Select } from 'antd'
import { CustomerSopTemplate } from '../../../../api/sales-orders'
import { WarehouseId } from '../../../../models/models'
import { Moment } from 'moment'
import DatePicker from '../../../ui/calendar/DatePicker'
import InformationButton from '../../../ui/buttons/InfromationButton'
import { FieldContainer } from '../../../ui/FIeldContainer'
import { ButtonContainer } from '../../../ui/ButtonContainer'
import { Label } from '../../../ui/Label'

interface DetailsProps {
    warehouseId: number
    setWarehouseId: (warehouseId: WarehouseId) => void
    customerOrderNumber: string
    setCustomerOrderNumber: (customerOrderNumber: string) => void
    datePromised: Moment | null
    setDatePromised: (date: Moment | null) => void
    template: CustomerSopTemplate
    onNextStep: () => void
}

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: '50px',
})

const CustomerContainer = styled(Box, {})({ width: '400px', marginTop: '30px' })
const InputsContainer = styled(
    Box,
    {}
)({ width: '350px', marginTop: '30px', display: 'flex', flexDirection: 'column', gap: '10px' })
const DetailsContainer = styled(Box, {})({ display: 'flex', flexDirection: 'column', gap: '10px' })

export default function Details(props: DetailsProps) {
    const {
        warehouseId,
        setWarehouseId,
        customerOrderNumber,
        datePromised,
        setCustomerOrderNumber,
        setDatePromised,
        template,
        onNextStep,
    } = props

    const shouldDisableNext = () => {
        return !datePromised || !customerOrderNumber
    }
    return (
        <Container>
            <InputsContainer>
                <FieldContainer>
                    <Label>Warehouse</Label>
                    <Select
                        style={{ width: '350px' }}
                        value={warehouseId}
                        onChange={(value: WarehouseId) => setWarehouseId(value)}
                        options={[
                            { value: WarehouseId.Monument, label: 'Monument' },
                            { value: WarehouseId.Scandia, label: 'Scandia' },
                        ]}
                    />
                </FieldContainer>

                <FieldContainer>
                    <Label>Promised delivery date</Label>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MM-YYYY"
                            value={datePromised}
                            onChange={(value) => {
                                setDatePromised(value)
                            }}
                        />
                        <InformationButton infoText="The date the customer is promised that the ordered products are delivered to them" />
                    </div>
                </FieldContainer>

                <FieldContainer>
                    <Label>Customer order number</Label>
                    <Input
                        value={customerOrderNumber}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setCustomerOrderNumber(e.target.value)
                        }}
                    />
                </FieldContainer>

                <ButtonContainer>
                    <Button type="primary" disabled={shouldDisableNext()} onClick={onNextStep}>
                        Continue
                    </Button>
                </ButtonContainer>
            </InputsContainer>

            <CustomerContainer>
                <DetailsContainer>
                    <h2>Customer Information</h2>
                    <FieldContainer>
                        <Label>Customer name</Label>
                        <Input
                            defaultValue={template.customer.name}
                            readOnly={true}
                            disabled={!template.customer.name}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Customer Credit Limit</Label>
                        <Input
                            defaultValue={template.customer.creditLimit}
                            disabled={!template.customer.creditLimit}
                            readOnly={true}
                        />
                    </FieldContainer>
                </DetailsContainer>
            </CustomerContainer>
        </Container>
    )
}
