import {
    Dialog,
    Paper,
    Divider,
    IconButton,
    Input,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    Select,
    MenuItem,
    InputAdornment,
    Tooltip,
} from '@mui/material'
import { Box, styled } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import CTRLoader from '../../ui/loader/CTRloader'
import { useEffect, useMemo, useState } from 'react'
import { Table, Typography } from 'antd'
import moment, { Moment } from 'moment'
import { toastSuccess, toastFailure } from '../../../util/toast'
import {
    GetOrderConfirmationRelations,
    getOrderConfirmationRequest,
    ProductAmount,
    UpdateOrderConfirmationDTO,
    updateOrderConfirmationRequest,
} from '../../../api/order-confirmation'
import {
    CallOff,
    LineType,
    OrderConfirmation,
    OrderConfirmationOrderline,
    Project,
    PurchaseOrder,
    PurchaseOrderOrderline,
} from '../../../models/models'
import { isInPastOrIsBritishHolidayOrIsWeekend } from '../../../util/british-holidays'
import { getPurchaseOrderByIdRequest, GetPurchaseOrderRelation } from '../../../api/purchase-orders'
import DeleteIcon from '@mui/icons-material/Delete'
import RefreshIcon from '@mui/icons-material/Refresh'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { numberInputOnWheelPreventChange } from '../../../util/util'
import {
    getCallOffsRequest,
    GetManyCallOffRelations,
    GetManyCallOffSortBy,
} from '../../../api/call-off'
import { getProjectsRequest } from '../../../api/projects/projects'
import DatePicker from '../../ui/calendar/DatePicker'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: '25px',
})

const Content = styled(Box)({
    width: '100%',
})

const RowContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
})

const Text = styled(Typography)(() => ({}))

const Label = (props: { text: string; isInvalid?: boolean }) => {
    const { text, isInvalid } = props
    return (
        <Box style={{ width: '180px' }}>
            <Text style={{ color: '#262626', opacity: '45%' }}>{text}</Text>
        </Box>
    )
}

interface Props {
    orderConfirmationId: number
    onClose: () => void
    active: boolean
}

enum State {
    Loading = 'Loading',
    Found = 'Found',
    NotFound = 'NotFound',
    Error = 'Error',
}

interface OrderLineAmountInput {
    [key: number]: string
}

interface AddedOrderline extends PurchaseOrderOrderline {
    amountToAdd: string
}

const isAddedOrderline = (
    item: AddedOrderline | OrderConfirmationOrderline
): item is AddedOrderline => {
    return (item as AddedOrderline).amountToAdd !== undefined
}

export default function UpdateOrderConfirmationModal(props: Props) {
    const { orderConfirmationId, onClose, active } = props
    const [isLoading, setIsLoading] = useState<State>(State.Loading)
    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState<Moment | null>(null)
    const [orderConfirmation, setOrderConfirmation] = useState<OrderConfirmation>()
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>()
    const [orderlineAmountInput, setOrderlineAmountInput] = useState<OrderLineAmountInput>({})
    const [deletedOrderlineIds, setDeletedOrderlineIds] = useState<number[]>([])
    const [addedOrderlines, setAddedOrderlines] = useState<AddedOrderline[]>([])

    const [projectOptions, setProjectOptions] = useState<Project[]>([])
    const [projectNameSearchText, setProjectNameSearchText] = useState<string>('')
    const [projectSelectOpen, setProjectSelectOpen] = useState<boolean>(false)
    const [loadingProjectOptions, setLoadingProjectOptions] = useState<boolean>(false)
    const [selectedProject, setSelectedProject] = useState<Project>()
    const [projectCallOffs, setProjectCallOffs] = useState<CallOff[]>([])
    const [selectedCallOff, setSelectedCallOff] = useState<CallOff>()

    useEffect(() => {
        getOrderConfirmation()
    }, [])

    useEffect(() => {
        if (orderConfirmation) {
            getPurchaseOrder(orderConfirmation.purchaseOrderId)
        }
    }, [orderConfirmation])

    const getOrderConfirmation = async () => {
        const response = await getOrderConfirmationRequest(orderConfirmationId, {
            relations: [
                GetOrderConfirmationRelations.Orderlines,
                GetOrderConfirmationRelations.OrderlinePurchaseOrderOrderline,
                GetOrderConfirmationRelations.PurchaseOrder,
                GetOrderConfirmationRelations.CallOff,
                GetOrderConfirmationRelations.CallOffProject,
            ],
        })
        if (response.successful) {
            setOrderConfirmation(response.data)
            setExpectedDeliveryDate(moment(response.data.expectedCompletelyArrivedDate))

            const orderlineInputs: OrderLineAmountInput = {}
            response.data.orderlines.forEach((oli) => {
                orderlineInputs[oli.id] = String(oli.amount)
            })
            setOrderlineAmountInput(orderlineInputs)
        } else if (response.status === 404) {
            setIsLoading(State.NotFound)
            toastFailure('Could not find the Order Confirmation')
        } else {
            setIsLoading(State.Error)
            toastFailure(response.message)
        }
    }

    useEffect(() => {
        if (orderConfirmation) {
            if (orderConfirmation.callOff && orderConfirmation.callOff.project) {
                setSelectedProject(orderConfirmation.callOff.project)
                setSelectedCallOff(orderConfirmation.callOff)
            }
        }
    }, [orderConfirmation])

    useEffect(() => {
        setLoadingProjectOptions(true)
        getProjectsRequest({
            //mui tables pages are zero-indexed
            like: projectNameSearchText,
        }).then((response) => {
            if (response.successful) {
                setProjectOptions(response.data?.projects || [])
            } else {
                setProjectOptions([])
                toastFailure(response.message)
            }
        })
    }, [projectNameSearchText, loadingProjectOptions])

    const getProjectCallOffs = async () => {
        if (!selectedProject) {
            return
        }

        const response = await getCallOffsRequest({
            relations: [
                GetManyCallOffRelations.Assemblies,
                GetManyCallOffRelations.BuiltItemCallOff,
                GetManyCallOffRelations.StockItemCallOff,
                GetManyCallOffRelations.ProjectBuiltItem,
                GetManyCallOffRelations.ProjectStockItem,
                GetManyCallOffRelations.Bom,
                GetManyCallOffRelations.BuiltItemProduct,
                GetManyCallOffRelations.Product,
                GetManyCallOffRelations.SalesOrder,
                GetManyCallOffRelations.Project,
            ],
            hasBeenDispatched: false,
            sortBy: GetManyCallOffSortBy.CustomerDispatchDate,
            projectId: selectedProject.id,
        })

        if (response.successful) {
            setProjectCallOffs(response.data.callOffs)
        } else {
            toastFailure(response.message)
        }
    }

    useEffect(() => {
        if (selectedProject) {
            getProjectCallOffs()
        }
    }, [selectedProject])

    const getPurchaseOrder = async (purchaseOrderId: number) => {
        const response = await getPurchaseOrderByIdRequest(purchaseOrderId, {
            relations: [
                GetPurchaseOrderRelation.Deliveries,
                GetPurchaseOrderRelation.OrderConfirmations,
                GetPurchaseOrderRelation.Orderlines,
            ],
            withAmounts: true,
        })

        if (response.successful) {
            setPurchaseOrder(response.data)
            setIsLoading(State.Found)
        } else if (response.status === 404) {
            setIsLoading(State.NotFound)
            toastFailure('Could not find the Purchase Order')
        } else {
            setIsLoading(State.Error)
            toastFailure('An error has occurred while retrieving the Purchase Order')
        }
    }

    const updateOrderConfirmation = async () => {
        const body: UpdateOrderConfirmationDTO = {}
        body.expectedDeliveryDate = expectedDeliveryDate?.utcOffset(0, true).toDate()
        body.callOffId = selectedCallOff?.id

        if (deletedOrderlineIds.length > 0) {
            body.deleteOrderlines = deletedOrderlineIds.map((doi) => ({
                orderlineId: doi,
            }))
        }

        if (addedOrderlines.length > 0) {
            body.createOrderlines = addedOrderlines.map((ao) => ({
                orderlineId: Number(ao.id),
                amount: Number(ao.amountToAdd),
            }))
        }

        if (getUpdatedOrderlines().length > 0) {
            body.updateOrderlines = getUpdatedOrderlines()
        }

        const response = await updateOrderConfirmationRequest(orderConfirmationId, body)

        if (response.successful) {
            toastSuccess(`The order has been updated`)
            onClose()
        } else {
            toastFailure(response.message)
        }
    }

    const onDeleteOrderline = (orderlineId: number) => {
        setDeletedOrderlineIds([orderlineId, ...deletedOrderlineIds])
    }

    const onDeleteAddedOrderlineItem = (orderlineId: number) => {
        setAddedOrderlines(addedOrderlines.filter((a) => a.id !== orderlineId))
    }

    const restoreDeletedOrderline = (orderLineId: number) => {
        setDeletedOrderlineIds(deletedOrderlineIds.filter((v) => v !== orderLineId))
    }

    const isOrderlineDeleted = (orderLineId: number) => {
        return deletedOrderlineIds.includes(orderLineId)
    }

    const updateOrderlineInput = (id: number, value: string) => {
        const inputState = { ...orderlineAmountInput }
        inputState[id] = value
        setOrderlineAmountInput(inputState)
    }

    const updateAddedOrderlineAmountInput = (id: number, value: string) => {
        setAddedOrderlines(
            addedOrderlines.map((aol) => {
                if (aol.id === id) {
                    aol.amountToAdd = value
                }
                return aol
            })
        )
    }

    const isSubmitDisabled = () => {
        const orderlines =
            orderConfirmation?.orderlines.filter((ol) => !deletedOrderlineIds.includes(ol.id)) || []
        for (const orderline of orderlines) {
            if (isUpdateOrderlineInputFaulty(orderline)) {
                return true
            }
        }

        for (const addedOrderline of addedOrderlines) {
            if (isAddedOrderlineInputFaulty(addedOrderline)) {
                return true
            }
        }
        return false
    }

    const isUpdateOrderlineInputFaulty = (ocOrderline: OrderConfirmationOrderline) => {
        const inputAmount = Number(orderlineAmountInput[ocOrderline.id])

        const poOrderline = purchaseOrder?.orderlines.find(
            (poOrderline) => poOrderline.id === ocOrderline.purchaseOrderOrderlineId
        )

        if (!poOrderline) {
            return false
        }

        if (inputAmount <= 0) {
            return true
        }

        return (
            poOrderline.lineQuantity <
                inputAmount - ocOrderline.amount + poOrderline.allocatedAmount ||
            inputAmount < poOrderline.deliveredAmount
        )
    }

    const isAddedOrderlineInputFaulty = (poOrderline: AddedOrderline) => {
        const inputAmount = Number(
            addedOrderlines.find((aol) => aol.id === poOrderline.id)?.amountToAdd
        )

        if (inputAmount <= 0) {
            return true
        }

        return poOrderline.lineQuantity < inputAmount + poOrderline.allocatedAmount
    }

    const isOrderlineUpdated = (orderline: OrderConfirmationOrderline): boolean => {
        return orderlineAmountInput[orderline.id] !== String(orderline.amount)
    }

    const purchaseOrderOrderlines = purchaseOrder?.orderlines || []

    const availableOrderlines = useMemo(() => {
        if (!purchaseOrder) {
            return []
        }

        return purchaseOrder.orderlines
            .filter((orderline) => orderline.lineType === LineType.Standard)
            .filter(
                (orderlines) =>
                    !addedOrderlines.find(
                        (addedOrderlineItem) => addedOrderlineItem.id === orderlines.id
                    )
            )
            .filter(
                (orderlines) =>
                    !orderConfirmation?.orderlines?.find(
                        (orderConfirmationOrderline) =>
                            orderConfirmationOrderline.purchaseOrderOrderline.id === orderlines.id
                    )
            )
            .filter((orderlines) => orderlines.allocatedAmount !== orderlines.lineQuantity)
    }, [addedOrderlines, orderConfirmation, purchaseOrder])

    const disableAddOrderline = (id: number) => !!availableOrderlines.find((f) => f.id === id)

    const onAddOrderlines = (orderlinesId: number) => {
        const orderlineItem = availableOrderlines.find((orderline) => orderline.id === orderlinesId)

        if (!orderlineItem) {
            return
        }
        const addedOrderline: AddedOrderline = {
            amountToAdd: '1',
            ...orderlineItem,
        }
        setAddedOrderlines([addedOrderline, ...addedOrderlines])
    }

    const getUpdatedOrderlines = (): ProductAmount[] => {
        if (!orderConfirmation) {
            return []
        }
        const updated: ProductAmount[] = []
        orderConfirmation.orderlines
            .filter((ol) => !deletedOrderlineIds.includes(ol.id))
            .forEach((ol) => {
                if (isOrderlineUpdated(ol)) {
                    updated.push({ id: ol.id, amount: Number(orderlineAmountInput[ol.id]) })
                }
            })
        return updated
    }

    const tableColumns = [
        {
            title: 'Product Code',
            key: 'code',
            render: (orderline: OrderConfirmationOrderline) => {
                if (isAddedOrderline(orderline)) {
                    return orderline.product.code
                } else {
                    return orderline.purchaseOrderOrderline.product.code
                }
            },
        },
        {
            title: 'Name',
            key: 'name',
            render: (orderline: OrderConfirmationOrderline) => {
                if (isAddedOrderline(orderline)) {
                    return orderline.product.name
                } else {
                    return orderline.purchaseOrderOrderline.product.name
                }
            },
        },
        {
            title: 'Description',
            key: 'description',
            render: (orderline: OrderConfirmationOrderline) => {
                if (isAddedOrderline(orderline)) {
                    return orderline.product.description
                } else {
                    return orderline.purchaseOrderOrderline.product.description
                }
            },
        },
        {
            title: 'OC Allocated / Total Allocated / Total',
            key: 'amount',
            render: (orderline: OrderConfirmationOrderline) => {
                if (isAddedOrderline(orderline)) {
                    if (orderline?.lineQuantity !== undefined && purchaseOrder) {
                        return `${orderline.amountToAdd}/${
                            Number(
                                purchaseOrder?.orderlines.find((i) => i.id === orderline.id)
                                    ?.allocatedAmount
                            ) + Number(orderline.amountToAdd)
                        }/${orderline.lineQuantity}`
                    }
                    return 'Indecisive'
                } else {
                    if (
                        orderline?.purchaseOrderOrderline?.lineQuantity !== undefined &&
                        purchaseOrder
                    ) {
                        return `${orderlineAmountInput[orderline.id]}/${
                            Number(
                                purchaseOrder?.orderlines.find(
                                    (i) => i.id === orderline.purchaseOrderOrderlineId
                                )?.allocatedAmount
                            ) -
                            Number(orderline.amount) +
                            Number(orderlineAmountInput[orderline.id])
                        }/${orderline.purchaseOrderOrderline.lineQuantity}`
                    }
                    return 'Indecisive'
                }
            },
        },
        {
            title: 'Update Amount',
            key: 'updateAmount',
            render: (orderline: OrderConfirmationOrderline) => {
                if (isAddedOrderline(orderline)) {
                    return (
                        <Input
                            startAdornment={
                                isAddedOrderlineInputFaulty(orderline) ? (
                                    <InputAdornment position="start">
                                        <Tooltip title="Check the input values! Make sure the values do not exceed total amount or allocated amount.">
                                            <WarningAmberIcon htmlColor="red" />
                                        </Tooltip>
                                    </InputAdornment>
                                ) : null
                            }
                            prefix={'quantity:'}
                            size="medium"
                            style={{
                                padding: 10,
                                borderRadius: '10px',
                                width: '400px',
                            }}
                            type="number"
                            error={isAddedOrderlineInputFaulty(orderline)}
                            value={
                                addedOrderlines.find((aol) => aol.id === orderline.id)?.amountToAdd
                            }
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                updateAddedOrderlineAmountInput(orderline.id, e.target.value)
                            }}
                            onWheel={numberInputOnWheelPreventChange}
                        />
                    )
                } else {
                    return (
                        <>
                            <Input
                                startAdornment={
                                    isUpdateOrderlineInputFaulty(orderline) ? (
                                        <InputAdornment position="start">
                                            <Tooltip title="Check the input values! Make sure the values do not exceed total amount, allocated amount or are less then the delivered amount.">
                                                <WarningAmberIcon htmlColor="red" />
                                            </Tooltip>
                                        </InputAdornment>
                                    ) : null
                                }
                                prefix={'quantity:'}
                                size="medium"
                                style={{
                                    padding: 10,
                                    borderRadius: '10px',
                                    width: '400px',
                                }}
                                type="number"
                                error={isUpdateOrderlineInputFaulty(orderline)}
                                value={orderlineAmountInput[orderline.id]}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    updateOrderlineInput(orderline.id, e.target.value)
                                }}
                                disabled={isOrderlineDeleted(orderline.id)}
                                onWheel={numberInputOnWheelPreventChange}
                            />
                        </>
                    )
                }
            },
        },
        {
            title: '',
            render: (orderline: OrderConfirmationOrderline | AddedOrderline) => {
                if (isAddedOrderline(orderline)) {
                    return (
                        <IconButton onClick={() => onDeleteAddedOrderlineItem(orderline.id)}>
                            <DeleteIcon />
                        </IconButton>
                    )
                } else {
                    return (
                        <>
                            {!isOrderlineDeleted(orderline.id) && (
                                <IconButton onClick={() => onDeleteOrderline(orderline.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                            {isOrderlineDeleted(orderline.id) && (
                                <IconButton onClick={() => restoreDeletedOrderline(orderline.id)}>
                                    <RefreshIcon />
                                </IconButton>
                            )}
                        </>
                    )
                }
            },
        },
    ]

    return (
        <Dialog open={active} onClose={onClose} sx={{ zIndex: '100' }} fullWidth maxWidth="md">
            <Paper>
                <Box
                    sx={{
                        fontsize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div> Update Order Confirmation </div>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Container>
                    {isLoading === State.Loading && (
                        <Content
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                margin: '20px',
                                width: '100%',
                                height: '400px',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CTRLoader />
                        </Content>
                    )}
                    {orderConfirmationId && isLoading === State.Found && (
                        <Content>
                            <Box sx={{ display: 'flex', gap: '50px', flexDirection: 'column' }}>
                                <RowContainer>
                                    <Label text="Expected Delivery Date" />
                                    <DatePicker
                                        style={{ width: '300px' }}
                                        format="DD-MM-YYYY"
                                        value={expectedDeliveryDate}
                                        disabledDate={isInPastOrIsBritishHolidayOrIsWeekend}
                                        onChange={(value) => {
                                            setExpectedDeliveryDate(value)
                                        }}
                                    />
                                </RowContainer>

                                {/*
                                {!selectedProject && (
                                    <InputSection text="Set Project">
                                        <Autocomplete
                                            id="tags-outlined"
                                            sx={{
                                                width: '400px',
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            fullWidth
                                            disablePortal
                                            open={projectSelectOpen}
                                            onOpen={() => {
                                                setProjectSelectOpen(true)
                                            }}
                                            onClose={() => {
                                                setProjectSelectOpen(false)
                                            }}
                                            value={null}
                                            onChange={(_, project) => {
                                                if (!project) {
                                                    return
                                                }
                                                setSelectedProject(project)
                                            }}
                                            inputValue={projectNameSearchText}
                                            onInputChange={(_, newInputValue) => {
                                                setProjectNameSearchText(newInputValue)
                                            }}
                                            getOptionLabel={(option) => option.name || ''}
                                            options={projectOptions}
                                            renderInput={(
                                                params: AutocompleteRenderInputParams
                                            ) => <TextField {...params} />}
                                        />
                                    </InputSection>
                                )}

                                {selectedProject && (
                                    <InputSection text="Selected Project">
                                        <TextField
                                            key="Project"
                                            id="outlined-read-only-input"
                                            //removing the label
                                            sx={{
                                                width: '400px',
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={selectedProject.name}
                                            disabled={true}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            edge="end"
                                                            color="primary"
                                                            onClick={() => {
                                                                setSelectedProject(undefined)
                                                                setSelectedCallOff(undefined)
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </InputSection>
                                )}

                                {selectedProject && !selectedCallOff && (
                                    <>
                                        <CallOffsTable
                                            callOffs={projectCallOffs}
                                            actionComponents={[
                                                (callOffId: number) => {
                                                    return (
                                                        <Button
                                                            variant={'outlined'}
                                                            onClick={() => {
                                                                setSelectedCallOff(
                                                                    projectCallOffs.find(
                                                                        (c) => c.id === callOffId
                                                                    )
                                                                )
                                                            }}
                                                        >
                                                            Select
                                                        </Button>
                                                    )
                                                },
                                            ]}
                                        />
                                    </>
                                )}

                                {selectedProject && selectedCallOff && (
                                    <InputSection text="Selected Call Off">
                                        <TextField
                                            key="Call Off"
                                            id="outlined-read-only-input"
                                            //removing the label
                                            sx={{
                                                width: '400px',
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={selectedCallOff.id}
                                            disabled={true}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            edge="end"
                                                            color="primary"
                                                            onClick={() => {
                                                                setSelectedCallOff(undefined)
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </InputSection>
                                )}
                                */}

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <h2>Orderlines</h2>
                                    <FormControl sx={{ m: 1, width: 300 }}>
                                        <InputLabel>Add Orderlines</InputLabel>
                                        <Select
                                            disabled={purchaseOrderOrderlines?.length === 0}
                                            value=""
                                            defaultValue="Add Orderlines"
                                            onChange={(event: any) =>
                                                onAddOrderlines(event.target.value)
                                            }
                                            input={<OutlinedInput label="Add Orderlines" />}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 48 * 4.5 + 8,
                                                        width: 250,
                                                    },
                                                },
                                            }}
                                        >
                                            {purchaseOrderOrderlines.map(
                                                (purchaseOrderOrderline) => (
                                                    <MenuItem
                                                        disabled={
                                                            !disableAddOrderline(
                                                                purchaseOrderOrderline.id
                                                            )
                                                        }
                                                        key={purchaseOrderOrderline.id}
                                                        value={purchaseOrderOrderline.id}
                                                    >
                                                        {purchaseOrderOrderline.code}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>
                                {orderConfirmation && (
                                    <Table
                                        rowClassName={(orderlineItem) => {
                                            if (isAddedOrderline(orderlineItem)) {
                                                return 'added-row'
                                            } else if (isOrderlineDeleted(orderlineItem.id)) {
                                                return 'deleted-row'
                                            } else if (isOrderlineUpdated(orderlineItem)) {
                                                return 'updated-row'
                                            } else {
                                                return ''
                                            }
                                        }}
                                        rowKey="id"
                                        columns={tableColumns}
                                        dataSource={[
                                            ...orderConfirmation.orderlines,
                                            ...addedOrderlines,
                                        ]}
                                        pagination={false}
                                        className="no-hover"
                                    />
                                )}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginRight: '50px',
                                    }}
                                >
                                    <Button
                                        disabled={isSubmitDisabled()}
                                        sx={{ width: '150px' }}
                                        variant="outlined"
                                        onClick={() => {
                                            updateOrderConfirmation()
                                            onClose()
                                        }}
                                    >
                                        Update
                                    </Button>
                                </Box>
                            </Box>
                        </Content>
                    )}
                </Container>
            </Paper>
        </Dialog>
    )
}
