import { LeftOutlined } from '@ant-design/icons'
import { CheckCircle, CheckCircleOutline, CheckOutlined } from '@mui/icons-material'
import { styled } from '@mui/material'
import { Button } from 'antd'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { GeneralAssembly, User, UserRole } from '../../../../models/models'
import {
    completeGeneralAssembly,
    setActiveGeneralAssembly,
    showStartGeneralAssemblyActivityModal,
} from '../../../../store/assembly-worksheets-slice'
import { useAppDispatch } from '../../../../store/hooks'
import { FieldContainer } from '../../../ui/FIeldContainer'
import { FieldLabel } from '../../../ui/FieldLabel'
import ActiveGeneralAssemblyLinesTable from './ActiveGeneralAssemblyLinesTable'
import { GeneralAssemblyActivityStartModal } from '../modals/GeneralAssemblyActivityStartModal'

interface Props {
    user: User
    generalAssembly: GeneralAssembly
    hideHeader?: boolean
    hideCompleteButton?: boolean
}

const WhiteContainer = styled('div')`
    padding-bottom: 20px;
    background: #fafafa;
    overflow-x: auto;
`

const FooterContainer = styled('div')`
    margin: 10px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
`

const CommentContainer = styled(WhiteContainer)`
    margin-bottom: 15px;
    padding: 10px !important;
    display: flex;
    gap: 10px;
`

const CommentPre = styled('pre')`
    overflow-y: auto;
    font-family: inherit;
    margin: 0;
    white-space: pre-wrap;
`

const CommentHeader = styled('p')`
    font-weight: bold;
    margin: 0;
`

function ActiveGeneralAssembly({ generalAssembly, user }: Props) {
    const dispatch = useDispatch()

    const disableCompleteButton = useMemo((): boolean => {
        return !generalAssembly.completedAt && !!generalAssembly.lines.find((l) => !l.completedAt)
    }, [generalAssembly])

    useEffect(() => {
        if (user.role !== UserRole.Assembler) {
            return
        }

        if (generalAssembly.completedAt) {
            return
        }

        const hasActivity = generalAssembly.activities.some((activity) => {
            return activity.userId === user.id && !activity.endedAt
        })

        if (!hasActivity) {
            dispatch(showStartGeneralAssemblyActivityModal(generalAssembly.id))
        }
    }, [])

    return (
        <>
            {generalAssembly.description && (
                <CommentContainer>
                    <CommentHeader>Comment:</CommentHeader>
                    <CommentPre>{generalAssembly.description}</CommentPre>
                </CommentContainer>
            )}

            <ActiveGeneralAssemblyLinesTable generalAssembly={generalAssembly} user={user} />
            <FooterContainer>
                <Button
                    type="default"
                    onClick={() => dispatch(setActiveGeneralAssembly(null))}
                    icon={<LeftOutlined />}
                    size="large"
                >
                    Back
                </Button>

                {!generalAssembly.completedAt && (
                    <Button
                        type="primary"
                        onClick={() => dispatch(completeGeneralAssembly(generalAssembly.id))}
                        disabled={disableCompleteButton}
                        size="large"
                    >
                        Complete
                    </Button>
                )}
            </FooterContainer>
            <GeneralAssemblyActivityStartModal />
        </>
    )
}

export default ActiveGeneralAssembly
