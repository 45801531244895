import { Table } from 'antd'
import { SalesOrderDTO } from '../../../api/sales-orders'
import { SopOrderlineType } from '../../../api/sop-orderlines'
import {
    LineType,
    SalesOrder,
    SopBuiltItemOrderline,
    SopChargeOrderline,
    SopCommentOrderline,
    SopFreeTextOrderline,
    SopStockItemOrderline,
} from '../../../models/models'

const isSalesOrder = (salesOrder: SalesOrder | SalesOrderDTO): salesOrder is SalesOrder => {
    return (
        (salesOrder as SalesOrder).warehouseId !== undefined &&
        (salesOrder as SalesOrder).documentNo !== undefined
    )
}

export const isSopBuiltItemOrderline = (
    orderline:
        | SopCommentOrderline
        | SopChargeOrderline
        | SopFreeTextOrderline
        | SopStockItemOrderline
        | SopBuiltItemOrderline
): orderline is SopBuiltItemOrderline => {
    return (orderline as SopBuiltItemOrderline).builtItemId !== undefined
}

export const isSopStockItemOrderline = (
    orderline:
        | SopCommentOrderline
        | SopChargeOrderline
        | SopFreeTextOrderline
        | SopStockItemOrderline
        | SopBuiltItemOrderline
): orderline is SopStockItemOrderline => {
    return (
        (orderline as SopStockItemOrderline).lineType === LineType.Standard &&
        (orderline as SopBuiltItemOrderline)['builtItemId'] === undefined
    )
}

export const isSopCommentOrderline = (
    orderline:
        | SopCommentOrderline
        | SopChargeOrderline
        | SopFreeTextOrderline
        | SopStockItemOrderline
        | SopBuiltItemOrderline
): orderline is SopCommentOrderline => {
    return (orderline as SopCommentOrderline).lineType === LineType.Comment
}

export const isSopChargeOrderline = (
    orderline:
        | SopCommentOrderline
        | SopChargeOrderline
        | SopFreeTextOrderline
        | SopStockItemOrderline
        | SopBuiltItemOrderline
): orderline is SopChargeOrderline => {
    return (orderline as SopChargeOrderline).lineType === LineType.Charge
}

export const isSopFreeTextOrderline = (
    orderline:
        | SopCommentOrderline
        | SopChargeOrderline
        | SopFreeTextOrderline
        | SopStockItemOrderline
        | SopBuiltItemOrderline
): orderline is SopFreeTextOrderline => {
    return (orderline as SopFreeTextOrderline).lineType === LineType.FreeText
}

const getSopOrderlineItemType = (
    orderline:
        | SopCommentOrderline
        | SopChargeOrderline
        | SopFreeTextOrderline
        | SopStockItemOrderline
        | SopBuiltItemOrderline
): SopOrderlineType => {
    if (isSopBuiltItemOrderline(orderline)) {
        return SopOrderlineType.Bom
    } else if (isSopStockItemOrderline(orderline)) {
        return SopOrderlineType.StockItem
    } else if (isSopCommentOrderline(orderline)) {
        return SopOrderlineType.Comment
    } else if (isSopFreeTextOrderline(orderline)) {
        return SopOrderlineType.FreeText
    } else {
        return SopOrderlineType.Charge
    }
}

export default function SalesOrderOrderlineTable({
    salesOrder,
    actionComponents,
}: {
    salesOrder: SalesOrderDTO | SalesOrder
    actionComponents?: Array<(salesOrderOrderlineId: number, type: SopOrderlineType) => JSX.Element>
}) {
    const dataSource = (() => {
        if (
            isSalesOrder(salesOrder) &&
            (salesOrder?.builtItemOrderlines === undefined ||
                salesOrder?.stockItemOrderlines === undefined ||
                salesOrder?.commentOrderlines === undefined ||
                salesOrder?.chargeOrderlines === undefined ||
                salesOrder?.freeTextOrderlines === undefined)
        ) {
            return []
        }
        return isSalesOrder(salesOrder)
            ? ([
                  ...salesOrder.builtItemOrderlines,
                  ...salesOrder.stockItemOrderlines,
                  ...salesOrder.commentOrderlines,
                  ...salesOrder.chargeOrderlines,
                  ...salesOrder.freeTextOrderlines,
              ] as any[])
            : salesOrder.lines
    })()

    const columns = [
        {
            title: 'code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Amount',
            key: 'Amount',
            render: (
                orderline:
                    | SopCommentOrderline
                    | SopChargeOrderline
                    | SopFreeTextOrderline
                    | SopStockItemOrderline
                    | SopBuiltItemOrderline
            ) => {
                const type = getSopOrderlineItemType(orderline)
                if (type === SopOrderlineType.Bom || type == SopOrderlineType.StockItem) {
                    return orderline.amount
                } else if (type === SopOrderlineType.Comment) {
                    return ''
                } else {
                    return 'Indecisive'
                }
            },
        },
        {
            title: 'On active call offs',
            key: 'On active call offs',
            render: (
                orderline:
                    | SopCommentOrderline
                    | SopChargeOrderline
                    | SopFreeTextOrderline
                    | SopStockItemOrderline
                    | SopBuiltItemOrderline
            ) => {
                if (isSopBuiltItemOrderline(orderline) || isSopStockItemOrderline(orderline)) {
                    return orderline.reserved
                } else {
                    return ''
                }
            },
        },
        {
            title: 'Despatched amount',
            dataIndex: 'despatchReceiptQuantity',
            key: 'despatchReceiptQuantity',
        },
    ]

    if (actionComponents) {
        columns.push({
            title: 'Actions',
            key: 'actions',
            render: (
                orderline:
                    | SopCommentOrderline
                    | SopChargeOrderline
                    | SopFreeTextOrderline
                    | SopStockItemOrderline
                    | SopBuiltItemOrderline
            ) => {
                return (
                    <>
                        {actionComponents.map((a) => {
                            return a(orderline.id, getSopOrderlineItemType(orderline))
                        })}
                    </>
                ) as any
            },
        })
    }

    return (
        <Table
            dataSource={dataSource.sort((a, b) => a.lineNumber - b.lineNumber) || []}
            columns={columns}
            pagination={{ pageSize: 100 }}
        />
    )
}
