import { styled } from '@mui/styles'
import { useSelector } from 'react-redux'
import { Button, Input } from 'antd'
import { ChangeEvent } from 'react'
import { RootState } from '../../../store/store'
import { useAppDispatch } from '../../../store/hooks'
import {
    ActiveModal,
    BomFilters,
    openModal,
    setBomFilters,
} from '../../../store/product-authorization-slice'
import { Label } from '../../ui/Label'
import { FieldContainer } from '../../ui/FIeldContainer'

const Container = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}))

const InputsContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0px 0px 10px 0px',
    gap: '5px',
}))

const ActionsContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0px 0px 10px 0px',
    gap: '5px',
}))

export const BomsTopBar = () => {
    const { bomFilters } = useSelector((state: RootState) => state.productAuthorization)
    const dispatch = useAppDispatch()

    const mutateBomFilters = (key: keyof BomFilters, value: string | undefined) => {
        dispatch(setBomFilters({ ...bomFilters, [key]: value }))
    }

    return (
        <Container>
            <InputsContainer>
                <FieldContainer>
                    <Label>Code</Label>
                    <Input
                        style={{ width: '100px' }}
                        value={bomFilters.code}
                        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                            mutateBomFilters('code', event.target.value)
                        }}
                    />
                </FieldContainer>
            </InputsContainer>
            <ActionsContainer>
                <Button
                    type="primary"
                    onClick={() =>
                        dispatch(
                            openModal({
                                activeModal: ActiveModal.ImportInternalBom,
                            })
                        )
                    }
                >
                    Import Internal Boms
                </Button>
                <Button
                    type="primary"
                    onClick={() =>
                        dispatch(
                            openModal({
                                activeModal: ActiveModal.ImportSageBoms,
                            })
                        )
                    }
                >
                    Import Sage Boms
                </Button>
            </ActionsContainer>
        </Container>
    )
}
