import React, { useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import MuiAppBar from '@mui/material/AppBar'
import List from '@mui/material/List'
import CTR_LOGO from '../../images/CTR-LOGO.svg'
import { Switch, Route, useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import ProductionSchedule from '../production-schedule/ProductionSchedule'
import ProjectMaster from '../project-master/ProjectMaster'
import WarehouseMap from '../warehouse-map/WarehouseMap'
import WorksheetsLogistics from '../worksheets/LogisticWorksheets'
import WorksheetsAssembly from '../worksheets/AssemblyWorksheets'
import Calendar from '../calendar/AvailabilityCalendar'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import PersonIcon from '@mui/icons-material/Person'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import LogoutIcon from '@mui/icons-material/Logout'
import { useAppDispatch } from '../../store/hooks'
import { logout } from '../../store/authentication-slice'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SingleProjectView from '../project-master/single-project-view/SingleProjectView'
import Users from '../users/Users'
import StockManager from '../stock-manager/StockManager'
import { Drawer } from './Drawer'
import ProductAuthorization from '../product-authorization/ProductAuthorization'
import { Badge, IconButton, Slide, Toolbar } from '@mui/material'
import { Urls } from '../../util/urls'
import Profile from '../Profile'
import { BackgroundColors } from '../../theme/colors'
import { ConditionalRoute } from '../ConditionalRoute'
import { UserRole } from '../../models/models'
import Orders from '../orders/Orders'
import { BugFilled } from '@ant-design/icons'
import BugReporting from '../bug-reporting/BugReporting'
import { TransitionProps } from '@mui/material/transitions'
import { NotificationsOutlined } from '@mui/icons-material'
import Notifications from '../notifications/Notifications'
import { fetchUnreactedAmount, fetchUnseenAmount } from '../../store/notifications-slice'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import Assemblies from '../assemblies/Assemblies'
import CallOffs from '../call-offs/CallOffs'
import TasksRoute from '../worksheets/tasks/TasksRoute'
import GeneralAssembliesRoute from '../general-assemblies/GeneralAssembliesRoute'
import { ProductsRoute } from '../products/ProductsRoute'

//Modal transition for bug reporting
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

function DashboardContent() {
    const [profileButtonDropdownEl, setProfileButtonDropdownEl] =
        React.useState<null | HTMLElement>(null)
    const [displayBugReportModal, setIsDisplayBugReportModal] = React.useState(false)
    const history = useHistory()
    const { path } = useRouteMatch()
    const { pathname } = useLocation()
    const { user } = useSelector((state: RootState) => state.authentication)
    const { unreactedAmount } = useSelector((state: RootState) => state.notifications)
    const dispatch = useAppDispatch()

    const handleProfileButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setProfileButtonDropdownEl(profileButtonDropdownEl ? null : event.currentTarget)
    }

    useEffect(() => {
        if (!user?.id) {
            return
        }
        dispatch(fetchUnreactedAmount(user.id))
    }, [user])

    if (user) {
        return (
            <>
                <BugReporting
                    isOpen={displayBugReportModal}
                    handleClose={() => setIsDisplayBugReportModal(false)}
                    modalTransition={Transition}
                />
                <Box sx={{ display: 'flex' }}>
                    <Drawer />
                    <CssBaseline />
                    <MuiAppBar position="absolute">
                        <Toolbar variant="dense">
                            <Button component={Link} to={Urls.Landing}>
                                <img src={CTR_LOGO} alt="SAV_logo" height="32px" />
                            </Button>
                            <Box sx={{ flexGrow: 1 }} />
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                startIcon={<BugFilled />}
                                onClick={() => setIsDisplayBugReportModal(!displayBugReportModal)}
                            >
                                Bug Reporting
                            </Button>
                            <IconButton
                                style={{ marginLeft: '22px' }}
                                onClick={() => history.push(Urls.Notifications)}
                            >
                                <Badge badgeContent={unreactedAmount} color="warning">
                                    <NotificationsOutlined sx={{ color: '#fff' }} />
                                </Badge>
                            </IconButton>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={handleProfileButtonClick}
                            >
                                <PersonIcon style={{ marginRight: '14px' }} />
                                {user?.firstName}
                                {Boolean(profileButtonDropdownEl) ? (
                                    <ArrowDropUpIcon style={{ marginLeft: '4px' }} />
                                ) : (
                                    <ArrowDropDownIcon style={{ marginLeft: '4px' }} />
                                )}
                            </Button>

                            <Popper
                                open={Boolean(profileButtonDropdownEl)}
                                anchorEl={profileButtonDropdownEl}
                                placement="bottom-end"
                                transition
                            >
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper elevation={3}>
                                            <List>
                                                {pathname !== Urls.Profile && (
                                                    <ListItemButton
                                                        component={Link}
                                                        to={Urls.Profile}
                                                    >
                                                        <ListItemIcon>
                                                            <AccountCircleIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Profile" />
                                                    </ListItemButton>
                                                )}
                                                <ListItemButton onClick={() => dispatch(logout())}>
                                                    <ListItemIcon>
                                                        <LogoutIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Log Out" />
                                                </ListItemButton>
                                            </List>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </Toolbar>
                    </MuiAppBar>

                    <Box
                        component="main"
                        sx={{
                            backgroundColor: BackgroundColors.lighterGrey,
                            flexGrow: 1,
                            height: 'calc(100vh - 48px)',
                            overflow: 'auto',
                            zIndex: 0,
                        }}
                        style={{ marginTop: '48px', position: 'relative' }}
                    >
                        <Switch>
                            <Route exact path={path}>
                                <h3>Please select a topic.</h3>
                            </Route>
                            <ConditionalRoute
                                condition={[
                                    UserRole.SystemAdmin,
                                    UserRole.SavAdmin,
                                    UserRole.ProjectManager,
                                    UserRole.AccountManager,
                                    UserRole.ApplicationEngineer,
                                    UserRole.Scheduler,
                                ].includes(user.role)}
                                path={Urls.ProductionSchedule}
                                render={() => <ProductionSchedule />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.SystemAdmin,
                                    UserRole.SavAdmin,
                                    UserRole.ProjectManager,
                                    UserRole.AccountManager,
                                    UserRole.ApplicationEngineer,
                                    UserRole.Scheduler,
                                ].includes(user.role)}
                                path={Urls.SingleProjectView}
                                render={() => <SingleProjectView />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.SystemAdmin,
                                    UserRole.SavAdmin,
                                    UserRole.ProjectManager,
                                    UserRole.AccountManager,
                                    UserRole.ApplicationEngineer,
                                    UserRole.Scheduler,
                                ].includes(user.role)}
                                path={Urls.ProjectMaster}
                                render={() => <ProjectMaster />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.SystemAdmin,
                                    UserRole.SavAdmin,
                                    UserRole.ProjectManager,
                                    UserRole.AccountManager,
                                    UserRole.ApplicationEngineer,
                                    UserRole.Scheduler,
                                    UserRole.Assembler,
                                    UserRole.LogisticsEmployee,
                                ].includes(user.role)}
                                path={Urls.WarehouseMap}
                                render={() => <WarehouseMap />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.SystemAdmin,
                                    UserRole.SavAdmin,
                                    UserRole.ProjectManager,
                                    UserRole.AccountManager,
                                    UserRole.LogisticsEmployee,
                                    UserRole.Scheduler,
                                ].includes(user.role)}
                                path={Urls.Orders}
                                render={() => <Orders />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.SystemAdmin,
                                    UserRole.SavAdmin,
                                    UserRole.ProjectManager,
                                    UserRole.LogisticsEmployee,
                                    UserRole.Scheduler,
                                ].includes(user.role)}
                                path={Urls.StockManager}
                                render={() => <StockManager />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.SystemAdmin,
                                    UserRole.SavAdmin,
                                    UserRole.ProjectManager,
                                    UserRole.AccountManager,
                                    UserRole.ApplicationEngineer,
                                    UserRole.Scheduler,
                                    UserRole.Assembler,
                                    UserRole.LogisticsEmployee,
                                ].includes(user.role)}
                                path={Urls.Notifications}
                                render={() => <Notifications />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.SystemAdmin,
                                    UserRole.SavAdmin,
                                    UserRole.Scheduler,
                                    UserRole.ApplicationEngineer,
                                ].includes(user.role)}
                                path={Urls.ProductAuthorization}
                                render={() => <ProductAuthorization />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.SystemAdmin,
                                    UserRole.SavAdmin,
                                    UserRole.Scheduler,
                                    UserRole.ApplicationEngineer,
                                ].includes(user.role)}
                                path={Urls.Products}
                                render={() => <ProductsRoute />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.SystemAdmin,
                                    UserRole.ProjectManager,
                                    UserRole.AccountManager,
                                    UserRole.Scheduler,
                                    UserRole.Assembler,
                                ].includes(user.role)}
                                path={Urls.Assemblies}
                                render={() => <Assemblies />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.SystemAdmin,
                                    UserRole.ProjectManager,
                                    UserRole.AccountManager,
                                    UserRole.Scheduler,
                                    UserRole.LogisticsEmployee,
                                ].includes(user.role)}
                                path={Urls.WorksheetsLogistics}
                                render={() => <WorksheetsLogistics />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.SystemAdmin,
                                    UserRole.ProjectManager,
                                    UserRole.Scheduler,
                                    UserRole.AccountManager,
                                    UserRole.LogisticsEmployee,
                                    UserRole.Assembler,
                                ].includes(user.role)}
                                path={Urls.WorksheetsAssemblyWithID}
                                render={() => <WorksheetsAssembly />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.SystemAdmin,
                                    UserRole.ProjectManager,
                                    UserRole.Scheduler,
                                    UserRole.AccountManager,
                                ].includes(user.role)}
                                path={Urls.Tasks}
                                render={() => <TasksRoute />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.SystemAdmin,
                                    UserRole.SavAdmin,
                                    UserRole.AccountManager,
                                    UserRole.Scheduler,
                                ].includes(user.role)}
                                path={Urls.Calendar}
                                render={() => <Calendar />}
                            />
                            <ConditionalRoute
                                condition={[UserRole.SystemAdmin, UserRole.SavAdmin].includes(
                                    user.role
                                )}
                                path={Urls.Users}
                                render={() => <Users />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.ProjectManager,
                                    UserRole.SavAdmin,
                                    UserRole.SystemAdmin,
                                    UserRole.Scheduler,
                                ].includes(user.role)}
                                path={Urls.GeneralAssemblies}
                                render={() => <GeneralAssembliesRoute />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.SystemAdmin,
                                    UserRole.SavAdmin,
                                    UserRole.ProjectManager,
                                    UserRole.AccountManager,
                                    UserRole.LogisticsEmployee,
                                    UserRole.Scheduler,
                                ].includes(user.role)}
                                path={Urls.Orders}
                                render={() => <Orders />}
                            />
                            <ConditionalRoute
                                condition={[
                                    UserRole.ProjectManager,
                                    UserRole.SavAdmin,
                                    UserRole.SystemAdmin,
                                    UserRole.ApplicationEngineer,
                                    UserRole.Scheduler,
                                ].includes(user.role)}
                                path={Urls.CallOffs}
                                render={() => <CallOffs />}
                            />
                            <Route path={Urls.Profile} render={() => <Profile />} />
                        </Switch>
                    </Box>
                </Box>
            </>
        )
    } else {
        return <></>
    }
}

export default function Dashboard(props: any) {
    return <DashboardContent {...props} />
}
