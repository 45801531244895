import { MouseEventHandler, ReactNode } from 'react'
import { Button } from 'antd'

export interface Props {
    text: string
    color?: 'green' | 'blue'
    onClick: MouseEventHandler
    icon?: ReactNode
    disabled?: boolean
}

/**
 * @deprecated  material ui <Button/>
 */
export default function SmallButton(props: Props) {
    const { text, color, onClick, icon, disabled = false } = props

    const hexColors = {
        green: '#00be94',
        blue: '#3500A0',
    }

    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            icon={icon}
            style={{
                borderRadius: '5px',
                background: color ? hexColors[color] : 'white',
                border: color ? 'none' : '1px solid #ccc',
                color: color ? 'white' : 'black',
                opacity: disabled ? 0.3 : 1,
            }}
        >
            {text}
        </Button>
    )
}
