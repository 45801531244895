import { SelectChangeEvent } from '@mui/material'
import { Box, styled } from '@mui/system'
import { useEffect, useMemo, useState } from 'react'
import { Button, Select, Table } from 'antd'
import { getStockItemsRequest, updateTrackedWarehouses } from '../../api/stock-item'
import { StockView, Warehouse, WarehouseId } from '../../models/models'
import PageHeader from '../ui/PageHeader'
import AddStockTrackingModal, { Changes } from './AddStockTrackingModal'
import { getWarehouses } from '../../api/warehouse'
import { SuccessfulResponse } from '../../api/util/with-response-formatter-interceptor'
import { Urls } from '../../util/urls'
import { getStockView } from '../../api/stock-manager'
import { toastFailure } from '../../util/toast'
import moment from 'moment'
import Loading from '../project-master/sales-orders/create/modals/createCustomerModal/Loading'
import { makeCommaDelimiterForNumber } from '../../util/util'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { FieldContainer } from '../ui/FIeldContainer'
import { FieldLabel } from '../ui/FieldLabel'
import { ButtonContainer } from '../ui/ButtonContainer'

const Container = styled(Box)(() => ({
    margin: '50px',
}))

const Header = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '10px',
    margin: '10px',
}))

const LeftColumnHeaderContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
}))

enum ShowModal {
    None = 'None',
    TrackStock = 'TrackStock',
}

export default function StockManager() {
    const [stockViews, setStockViews] = useState<StockView[]>([])
    const [modal, setModal] = useState<ShowModal>(ShowModal.None)
    const [activeWarehouseId, setActiveWarehouseId] = useState<WarehouseId>(WarehouseId.Monument)
    const [warehouses, setWarehouses] = useState<Warehouse[]>([])
    //reach represents how far into the future / past it will retrieve products. reach=4 will get current week, and the following 3 weeks.
    const [reach, setReach] = useState<number>(-4)
    const [loading, setLoading] = useState<boolean>(false)

    const addTrackedStock = (updatedStock: Changes) => {
        updateTrackedWarehouses(updatedStock).then((response) => {
            setModal(ShowModal.None)
        })
    }

    const handleWarehouseChange = (e: SelectChangeEvent<WarehouseId>) => {
        if (!Object.values(WarehouseId)?.includes(e.target.value)) {
            return
        }
        setActiveWarehouseId(e.target.value as WarehouseId)
    }

    const activeWarehouse = useMemo(() => {
        return warehouses.find((warehouse) => warehouse.id === activeWarehouseId)
    }, [warehouses, activeWarehouseId])

    const columns: any[] = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            width: 200,
            fixed: 'left',
        },
        {
            title: 'Incoming PO',
            key: 'incomingPurchaseOrders',
            render: (stockView: StockView) => {
                return makeCommaDelimiterForNumber(stockView.incomingPurchaseOrders)
            },
            width: 140,
            fixed: 'left',
        },
        {
            title: 'Out SO',
            key: 'outgoingSalesOrders',
            width: 140,
            render: (stockView: StockView) => {
                return makeCommaDelimiterForNumber(stockView.outgoingSalesOrders)
            },
            fixed: 'left',
        },
        {
            title: `Week ${moment()
                .add(reach > 0 ? reach - 4 : reach, 'weeks')
                .isoWeek()}`,
            children: [
                {
                    title: reach === 4 ? 'Current stock' : 'Period start',
                    key: 'w1os',
                    width: 110,
                    render: (stockView: StockView) => {
                        return makeCommaDelimiterForNumber(stockView.week1.periodStart)
                    },
                },
                {
                    title: 'Consumption',
                    key: 'w1as',
                    width: 120,
                    render: (stockView: StockView) => {
                        return makeCommaDelimiterForNumber(stockView.week1.consumption)
                    },
                },
                /*
                {
                    title: 'Ordered',
                    key: 'w1sbe',
                    width: 110,
                    render: (stockView: StockView) => {
                        return stockView.week1.ordered
                    },
                },
                */
            ],
        },
        {
            title: `Week ${moment()
                .add(reach > 0 ? reach - 3 : reach + 1, 'weeks')
                .isoWeek()}`,
            children: [
                {
                    title: 'Period Start',
                    key: 'w2os',
                    width: 110,
                    render: (stockView: StockView) => {
                        return makeCommaDelimiterForNumber(stockView.week2.periodStart)
                    },
                },
                {
                    title: 'Consumption',
                    key: 'w2as',
                    width: 120,
                    render: (stockView: StockView) => {
                        return makeCommaDelimiterForNumber(stockView.week2.consumption)
                    },
                },
                /*
                {
                    title: 'Ordered',
                    key: 'w2sbe',
                    width: 110,
                    render: (stockView: StockView) => {
                        return stockView.week2.ordered
                    },
                },
                */
            ],
        },
        {
            title: `Week ${moment()
                .add(reach > 0 ? reach - 2 : reach + 3, 'weeks')
                .isoWeek()}`,
            children: [
                {
                    title: 'Period start',
                    key: 'w3os',
                    width: 110,
                    render: (stockView: StockView) => {
                        return makeCommaDelimiterForNumber(stockView.week3.periodStart)
                    },
                },
                {
                    title: 'Consumption',
                    key: 'w3as',
                    width: 120,
                    render: (stockView: StockView) => {
                        return makeCommaDelimiterForNumber(stockView.week3.consumption)
                    },
                },
                /*
                {
                    title: 'Ordered',
                    key: 'w3sbe',
                    width: 110,
                    render: (stockView: StockView) => {
                        return stockView.week3.ordered
                    },
                },
                */
            ],
        },
        {
            title: `Week ${moment()
                .add(reach > 0 ? reach - 1 : reach + 4, 'weeks')
                .isoWeek()}`,
            children: [
                {
                    title: 'Period start',
                    key: 'w4os',
                    width: 110,
                    render: (stockView: StockView) => {
                        return makeCommaDelimiterForNumber(stockView.week4.periodStart)
                    },
                },
                {
                    title: 'Consumption',
                    key: 'w4as',
                    width: 120,
                    render: (stockView: StockView) => {
                        return makeCommaDelimiterForNumber(stockView.week4.consumption)
                    },
                },
                /*
                {
                    title: 'Ordered',
                    key: 'w4sbe',
                    width: 110,
                    render: (stockView: StockView) => {
                        return stockView.week4.ordered
                    },
                },
                */
            ],
        },
    ]

    const getStockViews = async () => {
        if (activeWarehouse === undefined) {
            return
        }
        try {
            setLoading(true)

            const stockItemsResponse = await getStockItemsRequest({
                trackedWarehouseIds: [activeWarehouse.id],
            })

            if (!stockItemsResponse.successful) {
                return
            }

            const promises = stockItemsResponse.data.entities.map((stockItem) =>
                getStockView(stockItem.id, {
                    reach,
                    warehouseId: activeWarehouse.id,
                    limit: 4,
                })
            )

            const stockViewResponses = await Promise.all(promises)

            const stockViews = stockViewResponses
                .filter((item): item is SuccessfulResponse<StockView> => !!item)
                .map((response) => response.data)

            setStockViews(stockViews)
        } catch (e) {
            toastFailure('Something went wrong fetching the stock levels')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (modal === ShowModal.None && activeWarehouse !== undefined) {
            getStockViews()
        }
    }, [modal, activeWarehouse, reach])

    useEffect(() => {
        getWarehouses().then((response) => {
            if (response.successful) {
                setWarehouses(response.data)
            }
        })
    }, [])

    return (
        <>
            {modal === ShowModal.TrackStock && activeWarehouse && (
                <AddStockTrackingModal
                    warehouse={activeWarehouse}
                    visible={modal === ShowModal.TrackStock}
                    onOk={addTrackedStock}
                    onCancel={() => {
                        setModal(ShowModal.None)
                    }}
                />
            )}
            <PageHeader
                title="Stock Manager"
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        link: Urls.StockManager,
                        name: 'Stock Manager',
                    },
                ]}
            />
            <Container>
                <Box>
                    <Header>
                        <LeftColumnHeaderContainer>
                            <ButtonContainer>
                                <div>
                                    <Button
                                        style={{ width: '100px' }}
                                        type="default"
                                        onClick={() =>
                                            reach === 4 ? setReach(-4) : setReach(reach - 4)
                                        }
                                        icon={<LeftOutlined />}
                                    >
                                        Past
                                    </Button>
                                    <Button
                                        style={{ width: '100px' }}
                                        type="default"
                                        onClick={() =>
                                            reach === -4 ? setReach(4) : setReach(reach + 4)
                                        }
                                        icon={<RightOutlined />}
                                    >
                                        Future
                                    </Button>
                                </div>
                            </ButtonContainer>

                            <FieldContainer>
                                <FieldLabel>Warehouse</FieldLabel>
                                <Select
                                    style={{ width: '200px' }}
                                    value={activeWarehouseId}
                                    onChange={(value: WarehouseId) => setActiveWarehouseId(value)}
                                    options={[
                                        { value: WarehouseId.Monument, label: 'Monument' },
                                        { value: WarehouseId.Scandia, label: 'Scandia' },
                                    ]}
                                />
                            </FieldContainer>
                        </LeftColumnHeaderContainer>

                        <ButtonContainer>
                            <FieldLabel> </FieldLabel>
                            <Button type="primary" onClick={() => setModal(ShowModal.TrackStock)}>
                                Edit stock {activeWarehouse?.name}
                            </Button>
                        </ButtonContainer>
                    </Header>
                    {loading && <Loading />}
                    {!loading && (
                        <Table columns={columns} dataSource={stockViews} bordered size="middle" />
                    )}
                </Box>
            </Container>
        </>
    )
}
