import { Table, TableColumnType } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { Response } from '../../api/util/with-response-formatter-interceptor'
import { BomGroup } from '../../models/models'
import { toastFailure } from '../../util/toast'
import useDebounce from '../project-master/single-project-view/hooks'

interface Props {
    pagination?: boolean
    //antd ColumnType<T> does not have title property
    columns?: Array<TableColumnType<BomGroup>>
    actionComponents?: Array<(bomGroup: BomGroup) => JSX.Element>
    dependencies?: any
    request: (pagination?: {
        skip: number
        limit: number
    }) => Promise<Response<{ count: number; entities: BomGroup[] }>>
}

export const BomGroupsTableColumns: { [key in string]: TableColumnType<BomGroup> } = {
    Id: {
        title: 'Id',
        key: 'id',
        render: (bomGroup: BomGroup) => {
            return bomGroup.id
        },
    },
    Name: {
        title: 'name',
        key: 'Name',
        width: '350px',
        render: (bomGroup: BomGroup) => {
            return bomGroup.name
        },
    },
    Type: {
        title: 'Type',
        key: 'type',
        render: (bomGroup: BomGroup) => {
            // make the pascal case property bomGroup?.worksheetCompletionType to split by spaces
            const type = bomGroup?.worksheetCompletionType?.replace(/([A-Z])/g, ' $1').trim()
            return type
        },
    },
} as const

const DEFAULT_COLUMNS = [
    BomGroupsTableColumns.Id,
    BomGroupsTableColumns.Name,
    BomGroupsTableColumns.Type,
]

export default function BomGroupsTable({
    request,
    columns,
    pagination,
    actionComponents,
    dependencies,
}: Props) {
    const [bomGroups, setBomGroups] = useState<BomGroup[]>([])
    const [bomGroupsAmount, setBomGroupsAmount] = useState<number>(0)
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)

    const getBomGroupsDeps = useDebounce(JSON.stringify(dependencies), 200)

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const tableColumns = useMemo(() => {
        let cols = columns ? Object.values(columns) : DEFAULT_COLUMNS
        if (actionComponents) {
            cols = [
                ...cols,
                {
                    title: 'Actions',
                    key: 'actions',
                    render: (bomGroup: BomGroup) => {
                        return (<>{actionComponents.map((a) => a(bomGroup))}</>) as any
                    },
                },
            ]
        }
        return cols
    }, [columns])

    const getBomGroups = async (resetPage = false) => {
        const response = await request({
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        })

        if (response.successful) {
            const { count, entities } = response.data
            resetPage && setPage(1)
            setBomGroupsAmount(count)
            setBomGroups(entities)
        } else {
            toastFailure(response.message)
        }
    }

    // this happens when dependencies change outside the table, e.g filters given in with getBomGroupsDeps
    useEffect(() => {
        getBomGroups(true)
    }, [getBomGroupsDeps, rowsPerPage])

    // this happens when dependencies change outside the table, e.g filters given in with getBomGroupsDeps
    useEffect(() => {
        getBomGroups()
    }, [page])

    return (
        <Table
            rowKey="id"
            columns={tableColumns}
            dataSource={bomGroups}
            expandable={undefined}
            size="small"
            pagination={
                pagination
                    ? {
                          defaultPageSize: 10,
                          current: page,
                          onChange: pageChange,
                          total: bomGroupsAmount,
                      }
                    : false
            }
        />
    )
}
