import moment, { Moment } from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/system'
import {
    getAssembliesCompletedMinutes,
    getAssembliesMinutes,
    getScheduleAvailableMinutes,
    useSchedule,
} from '../../util/production-schedule'
import { styled } from '@mui/material'
import { AssemblyRelation, getAssembliesRequest } from '../../api/assemblies'
import { toastFailure } from '../../util/toast'
import { getTasksRequest } from '../../api/tasks'
import { isBritishHoliday } from '../../util/british-holidays'

interface DateCellProps {
    date: Moment
    warehouseId: number
}

const DateContainer = styled(Box)(() => ({
    position: 'relative',
    height: '1px',
    margin: '5px',
}))

const DateText = styled('p')(() => ({
    fontFamily: 'roboto',
    lineHeight: 2,
    fontSize: 20,
    fontWeight: 400,
    textAlign: 'right',
}))

const Text = styled('p')(() => ({
    fontFamily: 'roboto',
    fontSize: 15,
    fontWeight: 300,
    textAlign: 'left',
    lineHeight: 1,
}))

const CellContent = styled(Box)(() => ({}))

const CellContainer = styled(Box)(() => ({
    display: 'flex',
    height: '110px',
    margin: '5px',
    flexDirection: 'column',
    boxShadow: '1px 1px 1px #ccc',
}))

enum Type {
    Past = 'Past',
    Holiday = 'Holiday',
    Full = 'Full',
    Space = 'Space',
    Empty = 'Empty',
    Weekend = 'Weekend',
    Error = 'Error',
}
enum Color {
    Grey = '#D9D9D9',
    White = '#FFF',
    Green = '#DCF7E9',
    Yellow = '#FEF8E6',
    Red = '#FFE1E2',
}

const getColor = (type: Type): Color => {
    if (type === Type.Error) {
        return Color.Red
    } else if (type === Type.Past) {
        return Color.Grey
    } else if (type === Type.Holiday) {
        return Color.Grey
    } else if (type === Type.Weekend) {
        return Color.White
    } else if (type === Type.Full) {
        return Color.Red
    } else if (type === Type.Space) {
        return Color.Yellow
    } else if (type === Type.Empty) {
        return Color.Green
    } else {
        return Color.Red
    }
}

const getCursor = (type: Type, date: Moment) => {
    if (
        type === Type.Empty ||
        type === Type.Space ||
        type === Type.Full ||
        date.isBefore(moment().startOf('month')) ||
        date.isAfter(moment().endOf('month'))
    ) {
        return 'pointer'
    } else {
        return 'default'
    }
}

export default function DateCell({ date, warehouseId }: DateCellProps) {
    const withDelayed = moment().isSame(date, 'day')
    const {
        absenceMinutes,
        assembliesCompletedMinutes,
        assembliesMinutes,
        totalMinutes,
        schedule,
        assembliesDelayedMinutes,
        taskMinutes,
        taskCompletedMinutes,
        completedMinutes,
        availableMinutes,
        taskDelayedMinutes,
        delayedMinutes,
        remainingMinutes,
        allocatedMinutes,
        error,
        loading,
    } = useSchedule({ date, warehouseId, withDelayed })

    const type = useMemo(() => {
        if (date.day() === 6 || date.day() === 0) {
            return Type.Weekend
        } else if (date.isBefore(moment(), 'day')) {
            return Type.Past
        } else if (isBritishHoliday(date)) {
            return Type.Holiday
        } else if (schedule !== undefined) {
            const usedPercentage = (availableMinutes / totalMinutes) * 100
            if (usedPercentage > 70) {
                return Type.Empty
            } else if (usedPercentage > 20) {
                return Type.Space
            } else {
                return Type.Full
            }
        } else {
            return Type.Past
        }
    }, [loading])

    if (loading) {
        return <Box>Loading</Box>
    }

    if (error) {
        return (
            <Box>
                <p>Error</p>
            </Box>
        )
    }

    if (!schedule) {
        return (
            <CellContainer
                style={{
                    backgroundColor: getColor(type),
                    cursor: getCursor(type, date),
                }}
            />
        )
    }

    return (
        <CellContainer
            style={{
                backgroundColor: getColor(type),
                cursor: getCursor(type, date),
            }}
        >
            <DateContainer style={{ margin: '5px' }}>
                <DateText>{date.date()}</DateText>
            </DateContainer>
            <CellContent>
                <Box>
                    <Text style={{ fontSize: 12 }}>
                        <b>Allocated</b> {allocatedMinutes}/{totalMinutes - absenceMinutes} mins
                    </Text>
                </Box>
                <Box>
                    <Text style={{ fontSize: 12 }}>
                        <b>Completed</b> {completedMinutes}/{allocatedMinutes} mins
                    </Text>
                </Box>
                <Box>
                    {date.isSameOrBefore(moment().startOf('day'), 'day') ? (
                        <Text>
                            <b>Remaining</b> {remainingMinutes} mins
                        </Text>
                    ) : (
                        <Text>
                            <b>Available</b> {availableMinutes} mins
                        </Text>
                    )}
                </Box>
                {withDelayed && (
                    <Box>
                        <Text>
                            <b>Delayed</b> {delayedMinutes} mins
                        </Text>
                    </Box>
                )}
            </CellContent>
        </CellContainer>
    )
}
