import { Assembly } from '../models/models'
import { AssemblyStatusKey } from '../store/assembly-worksheets-slice'
import backendConnection from './util/backend-connection'
import { Response } from './util/with-response-formatter-interceptor'

export interface AssemblyToCreate {
    dateToBeCompletedBy: Date
    amount: number
    assemblyComment: string
}
export interface CreateAssembliesBodyDTO {
    callOffId: number
    builtItemCallOffId: number
    assemblies: AssemblyToCreate[]
}

export enum SortOrder {
    Ascending = 'ASC',
    Descending = 'DESC',
}

export const createAssemblies = (body: CreateAssembliesBodyDTO): Promise<Response<Assembly[]>> =>
    backendConnection.request({
        url: `assemblies`,
        method: 'POST',
        data: body,
    })

/**
 * @description Completes an assembly
 * @author Jonas Grønbek
 * @date 29/08/2022
 * @deprecated This function is deprecated, should use the complete worksheet instead. Important side-effects
 * will not be considered when this endpoint is called.
 * @param id assembly/worksheet id
 * @return {*}
 */
export const completeAssembly = (id: number): Promise<Response<void>> => {
    return backendConnection.request({
        url: `assemblies/complete/${id}`,
        method: 'PUT',
    })
}

export const deleteAssemblyRequest = (id: number): Promise<Response<void>> =>
    backendConnection.request({
        url: `assemblies/${id}`,
        method: 'DELETE',
    })

export interface UpdateAssemblyDTO {
    removeAssemblyLineIds?: number[]
    addAssemblyLinesAmount?: number
    dateToBeCompletedBy?: Date
    assemblyComment: string
}

export const updateAssembly = (id: number, body: UpdateAssemblyDTO): Promise<Response<Assembly>> =>
    backendConnection.request({
        url: `assemblies/${id}`,
        method: 'PUT',
        data: body,
    })

export enum AssemblyRelation {
    CallOff = 'CallOff',
    CallOffOrders = 'CallOffOrders',
    CallOffProject = 'CallOffProject',
    CallOffSalesOrder = 'CallOffSalesOrder',
    Pallets = 'Pallets',
    PalletsProducts = 'PalletsProducts',
    Bom = 'Bom',
    Assemblers = 'Assemblers',
}

export enum SortBy {
    AssemblyId = 'AssemblyId',
    CallOffId = 'CallOffId',
    ProjectName = 'ProjectName',
    BomCode = 'BomCode',
    AssemblyTransfer = 'AssemblyTransfer',
    CompletedAssembly = 'CompletedAssembly',
    DispatchTransfer = 'DispatchTransfer',
    DateToBeCompletedBy = 'DateToBeCompletedBy',
}

export enum DateSearchType {
    AssemblyTransfer = 'AssemblyTransfer',
    CompletedAssembly = 'CompletedAssembly',
    DispatchTransfer = 'DispatchTransfer',
    DateToBeCompletedBy = 'DateToBeCompletedBy',
}

export interface DateSearch {
    dateRangeBegin?: Date
    dateRangeEnd?: Date
    dateSearchType: DateSearchType
}

export interface GetAssembliesDTO {
    hasPallets?: boolean
    hasBeenTransferred?: boolean
    hasBeenAssembled?: boolean
    sortOrder?: SortOrder
    hasBeenDispatched?: boolean
    allCallOffOrdersDelivered?: boolean
    dateToBeCompletedByDate?: Date
    isDelayed?: boolean
    relations?: AssemblyRelation[]
    projectName?: string
    bomCode?: string
    callOffId?: number
    id?: number
    callOffWarehouseId?: number
    sortBy?: SortBy
    dateSearch?: DateSearch
    skip?: number
    limit?: number
}

export const getAssembliesRequest = (
    params: GetAssembliesDTO
): Promise<Response<{ entities: Assembly[]; count: number }>> =>
    backendConnection.request({
        url: `assemblies`,
        method: 'GET',
        params,
    })

export const getAssemblyByIdRequest = (
    id: number,
    params?: GetAssembliesDTO
): Promise<Response<Assembly>> =>
    backendConnection.request({
        url: `assemblies/${id}`,
        method: 'GET',
        params: params,
    })

export interface GetAssemblyWorksheetsDTO {
    id?: number
    status?: AssemblyStatusKey
    projectId?: number
    showPrecedingUncompleted?: boolean
    dateToBeCompletedByStart?: Date
    dateToBeCompletedByEnd?: Date
    warehouseId?: number
    isOnHold?: boolean
    assemblerId?: number
}
export const getAssemblyWorksheets = (
    filters: GetAssemblyWorksheetsDTO
): Promise<Response<Assembly[]>> =>
    backendConnection.request({
        url: `assemblies/worksheets`,
        params: filters,
    })

export const completeWorksheetRequest = (worksheetId: number): Promise<Response<Assembly>> =>
    backendConnection.request({
        url: `assemblies/complete/${worksheetId}`,
        method: 'POST',
    })

export interface CompleteAssemblyFlatstationPalletDto {
    amount: number
    warehouseAreaSpotId: number
}

export const completeFlatstationWorksheetRequest = (
    worksheetId: number,
    data: CompleteAssemblyFlatstationPalletDto[]
): Promise<Response<Assembly>> =>
    backendConnection.request({
        url: `assemblies/complete/flatstation/${worksheetId}`,
        method: 'POST',
        data: { pallets: data },
    })

export interface CreateAssemblyDataSplit {
    assemblerId: number
    quantity: number
}

export const getAssemblyWorksheetRequest = (assemblyId: number): Promise<Response<Assembly>> =>
    backendConnection.request({
        url: `assemblies/worksheets/${assemblyId}`,
        method: 'GET',
    })

export interface StartAssemblyWorksheetDTO {
    assemblyId: number
}

export const startAssemblyWorksheetRequest = (
    dto: StartAssemblyWorksheetDTO
): Promise<Response<Assembly>> =>
    backendConnection.request({
        url: `assemblies/start`,
        method: 'POST',
        data: dto,
    })

export const updateWorksheetRequest = (
    worksheetId: number,
    data: {
        note?: string | null
    } = {}
): Promise<Response<Assembly>> =>
    backendConnection.request({
        url: `assemblies/${worksheetId}`,
        method: 'PATCH',
        data,
    })
