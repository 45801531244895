import { Card as MaterialUICard, CardContent } from '@mui/material'
import { styled } from '@mui/styles'

const ContentContainer = styled(CardContent)({
    width: '320px',
    paddingBottom: '10px !important ',
})

interface CardProps {
    children: React.ReactNode[] | React.ReactNode
    key?: number
}

export default function Card({ key, children }: CardProps) {
    return (
        <MaterialUICard variant="outlined" key={key}>
            <ContentContainer>{children}</ContentContainer>
        </MaterialUICard>
    )
}
