import { Response } from './util/with-response-formatter-interceptor'
import { Customer } from '../models/models'
import backendConnection from './util/backend-connection'

export interface GetCustomersQueryParamsDTO {
    skip?: number
    limit?: number
    like?: string
    withCount?: boolean
    onHold?: boolean
}

export const getCustomersRequest = (
    params?: GetCustomersQueryParamsDTO
): Promise<Response<{ customers: Customer[]; count?: number }>> =>
    backendConnection.request({
        url: '/customers',
        method: 'GET',
        params,
    })

export const getCustomerByIdRequest = (id: number): Promise<Response<Customer>> =>
    backendConnection.request({
        url: `/customers/id/${id}`,
        method: 'GET',
    })

export interface GetCustomersCountQueryParamsDTO {
    likeName?: string
    onHold?: boolean
}

export const getCustomersCountRequest = (
    params?: GetCustomersCountQueryParamsDTO
): Promise<Response<number>> =>
    backendConnection.request({
        url: '/customers/count',
        method: 'GET',
        params,
    })

export interface DeliveryAddress {
    id: number
    contact: string
    country: string
    customerId: number
    description: string
    email: string
    fax: string
    isDefault: boolean
    postalName: string
    taxCodeId: number
    taxNumber: string
    telephone: string
    countryCodeId: number
    address1: string
    address2: string
    address3: string
    address4: string
    city: string
    county: string
    postcode: string
    addressCountryCodeId: number
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export const getCustomersDeliveryAddresses = (
    customerId: number
): Promise<Response<DeliveryAddress[]>> =>
    backendConnection.request({
        url: `customers/delivery-addresses/${customerId}`,
        method: 'GET',
    })

export interface AccountMemo {
    customerId: number
    createdBy: string
    isActive: boolean
    note: string
    id: number
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export const getCustomerAccountMemos = (customerId: number): Promise<Response<AccountMemo[]>> =>
    backendConnection.request({
        url: `customers/account-memos/${customerId}`,
        method: 'GET',
    })

export interface CreateCustomerAddressDTO {
    address1: string
    address2: string
    address3: string
    address4: string
    description: string
    postcode: string
    county: string
    taxCodeId: number
    customerId: number
}

export const createCustomerAddressRequest = (
    data: CreateCustomerAddressDTO
): Promise<Response<DeliveryAddress>> =>
    backendConnection.request({
        url: `customers/delivery-addresses`,
        method: 'POST',
        data,
    })
