import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'

const URL_PREFIX = '/product-authorization'

export const getBomFileRequest = (bomId: number): Promise<any> =>
    backendConnection
        .request({
            url: `${URL_PREFIX}/bom-file/${bomId}`,
            responseType: 'blob',
            method: 'GET',
        })
        .then((response) => {
            if (response.successful) {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `bom-${bomId}.xml`) //or any other extension
                document.body.appendChild(link)
                link.click()
            }
        })

export interface AuthorizeStockItemDTO {
    name: string
    description: string
}

export interface StockItemDTO {
    id: number
    amount: number
}

export interface AuthorizeBomDTO {
    assemblyMinutes: number
    name?: string
    description?: string
    bomVersion?: number
    instructionLink?: string
    bomGroupId?: number
    components?: StockItemDTO[]
}

export const acceptBomAuthorizationRequest = (
    unauthorizedBomId: number,
    body: AuthorizeBomDTO
): Promise<Response<void>> =>
    backendConnection.request({
        url: `${URL_PREFIX}/accept/bom/${unauthorizedBomId}`,
        method: 'PUT',
        data: body,
    })

export const acceptStockItemAuthorizationRequest = (stockItemid: number): Promise<Response<void>> =>
    backendConnection.request({
        url: `${URL_PREFIX}/accept/stock-item/${stockItemid}`,
        method: 'PUT',
    })

export const denyAuthorizationStockItemRequest = (stockItemid: number): Promise<Response<void>> =>
    backendConnection.request({
        url: `${URL_PREFIX}/deny/stock-item/${stockItemid}`,
        method: 'PUT',
    })

export const denyAuthorizationBomRequest = (stockItemid: number): Promise<Response<void>> =>
    backendConnection.request({
        url: `${URL_PREFIX}/deny/bom/${stockItemid}`,
        method: 'PUT',
    })
