import { Button, Dialog, Divider, Stepper, Step, StepLabel, TextField } from '@mui/material'
import { Box, styled } from '@mui/system'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useMemo, useState } from 'react'
import { getCallOffById, SingleCallOffRelations } from '../../../../api/call-off'
import {
    AssemblyLine,
    BuiltItemCallOff,
    CallOff,
    GoodsDispatched,
    StockItemCallOff,
} from '../../../../models/models'
import { toastFailure, toastSuccess } from '../../../../util/toast'
import { Checkbox, Input, Select, Table, Tooltip } from 'antd'
import { dispatchCallOffRequest } from '../../../../api/logistics-worksheet'
import { ColumnsType } from 'antd/lib/table'
import { WarningOutlined } from '@mui/icons-material'
import { ConditionalToolTip } from '../../../ui/ConditionalToolTip'
import { Urls } from '../../../../util/urls'
import { useHistory } from 'react-router-dom'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '450px',
    minWidth: '350px',
    margin: '30px',
    justifyContent: 'space-between',
})
const Row = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
}))

const TextFieldContainer = styled(Box)(() => ({
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
}))

const ModalButton = styled(Button)(() => ({
    padding: '0px',
    minWidth: '0px',
    margin: '10px 10px 5px 0px',
}))

const InfoTextContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
}))

interface DispatchCallOffModalProps {
    isOpen: boolean
    closeModal: () => void
    onOk: () => void
    callOffId: number
}
enum Steps {
    Products = 0,
    Details = 1,
    Confirm = 2,
}

enum ModalState {
    Loading = 'Loading',
    Success = 'Success',
    Error = 'Error',
}

const isCalloffBuiltItem = (
    callOffProduct: BuiltItemCallOff | StockItemCallOff
): callOffProduct is BuiltItemCallOff => {
    return (callOffProduct as BuiltItemCallOff).builtItemSopId !== undefined
}

const isCallOffStockItem = (
    callOffProduct: BuiltItemCallOff | StockItemCallOff
): callOffProduct is StockItemCallOff => {
    return (callOffProduct as StockItemCallOff).stockItemSopId !== undefined
}

// a function that returns true if is StockItemCallOff
const isStockItemCallOff = (
    callOffProduct: BuiltItemCallOff | StockItemCallOff
): callOffProduct is StockItemCallOff => {
    return (callOffProduct as StockItemCallOff).stockItemSopId !== undefined
}

// a function that returns true if is StockItemCallOff
const isBuiltItemCallOff = (
    callOffProduct: BuiltItemCallOff | StockItemCallOff
): callOffProduct is BuiltItemCallOff => {
    return (callOffProduct as BuiltItemCallOff).builtItemSopId !== undefined
}

const dispatchCouriers = [
    'Hi Speed',
    'APC',
    'Whites TLS',
    'Mixed freight',
    'Express messenger',
    'Other',
] as const

const getProductCallOffKey = (callOffProduct: BuiltItemCallOff | StockItemCallOff) => {
    if (isBuiltItemCallOff(callOffProduct)) {
        return `built-item-call-off:${callOffProduct.id}`
    }
    return `stock-item-call-off:${callOffProduct.id}`
}

// e.g { 'built-item-call-off:1342': '10', 'stock-item-call-off:23234': '20' }
interface AmountInputs {
    [productCallOffKey: string]: string
}
interface DispatchInputs {
    [productCallOffKey: string]: number[]
}

export const DispatchCallOffModal = ({
    callOffId,
    isOpen,
    closeModal,
    onOk,
}: DispatchCallOffModalProps) => {
    const [activeStep, setActiveStep] = useState<Steps>(Steps.Products)
    const [amountInputs, setAmountInputs] = useState<AmountInputs>({})
    const [dispatchInputs, setDispatchInputs] = useState<DispatchInputs>({})
    const [dispatchCourier, setDispatchCourier] = useState<string>(dispatchCouriers[5])
    const [consignmentNumber, setConsignmentNumber] = useState<string>('')
    const [dispatchNote, setDispatchNote] = useState<string>('')
    const [callOff, setCallOff] = useState<CallOff>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const [continueButtonActive, setContinueButtonActive] = useState<boolean>(false)
    const history = useHistory()

    const fetchCallOff = async () => {
        const response = await getCallOffById(callOffId, {
            relations: [
                SingleCallOffRelations.Assemblies,
                SingleCallOffRelations.AssembliesLines,
                SingleCallOffRelations.AssembliesLinesGoodsDispatched,
                SingleCallOffRelations.AssembliesPallets,
                SingleCallOffRelations.CallOffProducts,
                SingleCallOffRelations.CallOffProductsBomGroups,
                SingleCallOffRelations.CallOffProductsGoodsDispathced,
            ],
        })
        if (response.successful) {
            setModalState(ModalState.Success)
            setCallOff(response.data)
        } else {
            setModalState(ModalState.Error)
            toastFailure(response.message)
        }
    }

    useEffect(() => {
        fetchCallOff()
    }, [])

    // set  inputs
    useEffect(() => {
        if (!callOff) {
            return
        }
        const newAmountInputs: AmountInputs = {}
        const newDispatchInputs: DispatchInputs = {}
        callOff.stockItemCallOffs.forEach((callOffStockItem) => {
            newAmountInputs[getProductCallOffKey(callOffStockItem)] = `${getAvailableAmount(
                callOffStockItem
            )}`
        })
        callOff.builtItemCallOffs.forEach((callOffBuiltItem) => {
            newAmountInputs[getProductCallOffKey(callOffBuiltItem)] = `${getAvailableAmount(
                callOffBuiltItem
            )}`
            if (hasSerialNumberAssemblyField(callOffBuiltItem)) {
                newDispatchInputs[getProductCallOffKey(callOffBuiltItem)] = []
            }
        })

        setDispatchInputs(newDispatchInputs)
        setAmountInputs(newAmountInputs)
    }, [callOff])

    // when amount input changes, check that if the amount equals the amount of undispatched assembly lines, then add them all to amount input
    useEffect(() => {
        if (!callOff) {
            return
        }

        callOff.builtItemCallOffs.forEach((callOffBuiltItem) => {
            if (amountDispatchingMatchesAmountAvailableToDispatch(callOffBuiltItem)) {
                dispatchRemaindingAssemblyLinesCallOffBuiltItem(callOffBuiltItem)
            }
        })
    }, [amountInputs])

    // if the amountInput amount less than the dispatching amount for a call off built item, then remove all the dispatching amount
    useEffect(() => {
        if (!callOff) {
            return
        }
        callOff.builtItemCallOffs.forEach((callOffBuiltItem) => {
            const { amountInput, dispatchInput } =
                getAmountInputAndDispatchingAmount(callOffBuiltItem)
            if (!dispatchInput) {
                return
            }
            if (amountInput < dispatchInput.length) {
                setDispatchInputs((prev) => ({
                    ...prev,
                    [getProductCallOffKey(callOffBuiltItem)]: [],
                }))
            }
        })
    }, [callOff, dispatchInputs, amountInputs])

    const showAmountDispatchingWarning = (callOffProduct: BuiltItemCallOff | StockItemCallOff) => {
        // Check if stock item only
        if ('stockItemSopId' in callOffProduct && !('builtItemSopId' in callOffProduct)) {
            setContinueButtonActive(() => false)
            return false
        }

        const assemblesCompleted = checkPartialDispatchAssemblyLineStatus(callOffProduct)
        //disabling continue button
        setContinueButtonActive(() => !assemblesCompleted)

        if (!assemblesCompleted) {
            return !assemblesCompleted
        }

        const showWarning =
            hasSerialNumberAssemblyField(callOffProduct) &&
            dispatchInputs[getProductCallOffKey(callOffProduct)]?.length <
                Number(amountInputs[getProductCallOffKey(callOffProduct)])

        return showWarning
    }

    const checkPartialDispatchAssemblyLineStatus = (
        callOffProduct: BuiltItemCallOff | StockItemCallOff
    ): boolean => {
        const productCallOffKeys = dispatchInputs[getProductCallOffKey(callOffProduct)]
        if (!productCallOffKeys) {
            return false
        }

        //check if there are any selected lines
        if (dispatchInputs[getProductCallOffKey(callOffProduct)].length === 0) {
            return false
        }

        //check if input amount equals selected lines
        if (
            dispatchInputs[getProductCallOffKey(callOffProduct)].length !==
            Number(amountInputs[getProductCallOffKey(callOffProduct)])
        ) {
            return false
        }

        let assemblyCompleted = true
        productCallOffKeys.forEach((element) =>
            callOff?.assemblies.forEach((asem) =>
                asem.lines.forEach((line) => {
                    if (line.id === element && asem.id === line.assemblyId) {
                        if (asem.completedAt === null) {
                            assemblyCompleted = false
                        }
                    }
                })
            )
        )
        return assemblyCompleted
    }

    const getAmountInputAndDispatchingAmount = (
        callOffBuiltItem: BuiltItemCallOff
    ): { dispatchInput: number[]; amountInput: number } => {
        const productCallOffKey = getProductCallOffKey(callOffBuiltItem)
        const amountInput = Number(amountInputs[productCallOffKey])
        const dispatchInput = dispatchInputs[productCallOffKey]
        return { dispatchInput, amountInput }
    }

    const amountDispatchingMatchesAmountAvailableToDispatch = (
        callOffBuiltItem: BuiltItemCallOff
    ) => {
        const productCallOffKey = getProductCallOffKey(callOffBuiltItem)
        const amountInput = amountInputs[productCallOffKey]
        const assemblyLineIds = getUndispatchedAssemblyLinesForCallOffBuiltItem(callOffBuiltItem)
        if (amountInput && assemblyLineIds) {
            return amountInput === `${assemblyLineIds.length}`
        }
        return false
    }

    const dispatchRemaindingAssemblyLinesCallOffBuiltItem = (
        callOffBuiltItem: BuiltItemCallOff
    ) => {
        const productCallOffKey = getProductCallOffKey(callOffBuiltItem)
        const assemblyLineIds = getUndispatchedAssemblyLinesForCallOffBuiltItem(
            callOffBuiltItem
        )?.map((assemblyLine) => assemblyLine.id)
        if (assemblyLineIds) {
            setDispatchInputs({ ...dispatchInputs, [productCallOffKey]: assemblyLineIds })
        }
    }

    const getDispatchingIds = (callOffBuiltItem: BuiltItemCallOff): number[] => {
        const productCallOffKey = getProductCallOffKey(callOffBuiltItem)
        const dispatchInput = dispatchInputs[productCallOffKey]
        if (dispatchInput) {
            return dispatchInput
        }
        return []
    }

    // if the dispatchInput does not have the assemblyLineId and the amountInput is less than or equal to the dispatchInput length, then return true otherwise false
    const disableAssemblyLineCheckbox = (assemblyLineId: number, productCallOffKey: string) => {
        const dispatchInput = dispatchInputs[productCallOffKey]
        const amountInput = Number(amountInputs[productCallOffKey])
        if (dispatchInput) {
            return !dispatchInput.includes(assemblyLineId) && amountInput <= dispatchInput.length
        }
        return false
    }

    const disableProductsStep = () => continueButtonActive

    const disableConfirmDetailsStep = () => !dispatchCourier || !consignmentNumber

    const addDispatchAmountInput = (assemblyLineIds: number[], productCallOffKey: string) => {
        const dispatchInput = dispatchInputs[productCallOffKey]
        if (dispatchInput) {
            const newDispatchInput = [...dispatchInput, ...assemblyLineIds]
            setDispatchInputs({ ...dispatchInputs, [productCallOffKey]: newDispatchInput })
        }
    }

    const removeDispatchAmountInput = (assemblyLineId: number, productCallOffKey: string) => {
        const dispatchInput = dispatchInputs[productCallOffKey]
        if (dispatchInput) {
            const newDispatchInput = dispatchInput.filter((id) => id !== assemblyLineId)
            setDispatchInputs({ ...dispatchInputs, [productCallOffKey]: newDispatchInput })
        }
    }

    const getUndispatchedAssemblyLinesForCallOffBuiltItem = (
        callOffBuiltItem: BuiltItemCallOff
    ): AssemblyLine[] | undefined => {
        return getAssemblyLinesForCallOffBuiltItem(callOffBuiltItem)?.filter(
            (assemblyLine) => !assemblyLine.goodsDispatchedId
        )
    }

    const getAssemblyLinesForCallOffBuiltItem = (
        callOffBuiltItem: BuiltItemCallOff
    ): AssemblyLine[] | undefined => {
        const builtItemAssemblies = callOff?.assemblies.filter((assemblies) => {
            return assemblies.builtItemCallOffId === callOffBuiltItem.id
        })
        const assemblyLines = builtItemAssemblies
            ?.map((assembly) => {
                return assembly.lines
            })
            .flat()
            .sort((a, b) => a.assemblyId - b.assemblyId)

        const completedAssemblies: AssemblyLine[] = []
        assemblyLines?.forEach((line) => {
            const assembly = callOff?.assemblies.find((asem) => asem.id === line.assemblyId)
            if (assembly?.completedAt !== null) {
                completedAssemblies.push(line)
            }
        })
        return completedAssemblies
    }

    const hasSerialNumberAssemblyField = (
        callOffProduct: BuiltItemCallOff | StockItemCallOff
    ): boolean => {
        return !!(
            isBuiltItemCallOff(callOffProduct) &&
            callOffProduct.sopBuiltItemOrderline.builtItem.group?.bomGroupAssemblyFields.find(
                (bomGroupAssemblyField) => bomGroupAssemblyField.assemblyField.isSerial
            )
        )
    }

    const getBuiltItemCodeByAssemblyId = (assemblyId: number) => {
        const builtItemCallOffId = callOff?.assemblies.find(
            (assembly) => assembly.id === assemblyId
        )?.builtItemCallOffId
        return callOff?.builtItemCallOffs.find(
            (builtItemCallOff) => builtItemCallOff.id === builtItemCallOffId
        )?.sopBuiltItemOrderline?.builtItem?.bomCode
    }

    const getTotalAmountGoodsDispatched = (goodsDispatched?: GoodsDispatched[]) => {
        let totalAmountDispatched = 0
        if (goodsDispatched) {
            goodsDispatched.forEach((goodsDispatched) => {
                totalAmountDispatched += goodsDispatched.amountDispatched
            })
        }
        return totalAmountDispatched
    }

    const getAvailableAmount = (callOffProduct: BuiltItemCallOff | StockItemCallOff) => {
        return callOffProduct.amount - getTotalAmountGoodsDispatched(callOffProduct.goodsDispatched)
    }

    const getDispatchingAmount = (callOffProduct: BuiltItemCallOff | StockItemCallOff) => {
        return Number(amountInputs[getProductCallOffKey(callOffProduct)])
    }

    const productsExpandedTableColumns = useMemo((): {
        [key: string]: ColumnsType<AssemblyLine>
    } => {
        const assemblyLineTableColumns: {
            [key: string]: ColumnsType<AssemblyLine>
        } = {}

        if (!callOff) {
            return assemblyLineTableColumns
        }

        callOff.builtItemCallOffs.forEach((callOffProduct) => {
            const key = getProductCallOffKey(callOffProduct)
            if (hasSerialNumberAssemblyField(callOffProduct) && key) {
                const assemblyLineColumns: ColumnsType<AssemblyLine> = [
                    {
                        title: 'BOM Code',
                        key: 'assemblyLineTableColumns-bomCode',
                        render: (assemblyLine: AssemblyLine) => {
                            return getBuiltItemCodeByAssemblyId(assemblyLine.assemblyId)
                        },
                    },
                ]
                callOffProduct.sopBuiltItemOrderline.builtItem.group?.bomGroupAssemblyFields.forEach(
                    (bomGroupAssemblyField) => {
                        if (bomGroupAssemblyField.assemblyField.isSerial) {
                            assemblyLineColumns.push({
                                title: bomGroupAssemblyField.assemblyField.name,
                                render: (assemblyLine: AssemblyLine) => {
                                    return assemblyLine.assemblyLineFields.find(
                                        (assemblyLineField) => {
                                            return (
                                                assemblyLineField.assemblyFieldId ===
                                                bomGroupAssemblyField.assemblyFieldId
                                            )
                                        }
                                    )?.value
                                },
                                key: `assemblyLine-${bomGroupAssemblyField.assemblyField.name}`,
                            })
                        }
                    }
                )
                assemblyLineColumns.push({
                    title: 'Select Assembly Line',
                    key: 'check',
                    render: (assemblyLine: AssemblyLine) => (
                        <Checkbox
                            onChange={(e) => {
                                if (e.target.checked) {
                                    addDispatchAmountInput(
                                        [assemblyLine.id],
                                        getProductCallOffKey(callOffProduct)
                                    )
                                } else {
                                    removeDispatchAmountInput(
                                        assemblyLine.id,
                                        getProductCallOffKey(callOffProduct)
                                    )
                                }
                            }}
                            disabled={
                                !!assemblyLine.goodsDispatchedId ||
                                disableAssemblyLineCheckbox(
                                    assemblyLine.id,
                                    getProductCallOffKey(callOffProduct)
                                )
                            }
                            checked={dispatchInputs[getProductCallOffKey(callOffProduct)]?.includes(
                                assemblyLine.id
                            )}
                        />
                    ),
                })
                assemblyLineTableColumns[key] = assemblyLineColumns
            }
        })
        return assemblyLineTableColumns
    }, [callOff, dispatchInputs, amountInputs])

    // create following columns: code, description and quantity input
    const productColumns: ColumnsType<StockItemCallOff | BuiltItemCallOff> = [
        {
            title: 'Code',
            key: 'productColumns-code',
            render: (element: StockItemCallOff | BuiltItemCallOff) => {
                if (isStockItemCallOff(element)) {
                    return `${element.sopStockItemOrderline.product.code}`
                } else if (isBuiltItemCallOff(element)) {
                    return `${element.sopBuiltItemOrderline.builtItem.bomCode}`
                }
            },
        },
        {
            title: 'Description',
            key: 'productColumns-description',
            render: (element: StockItemCallOff | BuiltItemCallOff) => {
                if (isStockItemCallOff(element)) {
                    return `${element.sopStockItemOrderline.product.description}`
                } else if (isBuiltItemCallOff(element)) {
                    return `${element.sopBuiltItemOrderline.builtItem.product.description}`
                }
            },
        },
        {
            title: 'Dispatched / Total Amount',
            key: 'productColumns-amount',
            render: (element: StockItemCallOff | BuiltItemCallOff) => {
                return (
                    <div>
                        {getTotalAmountGoodsDispatched(element.goodsDispatched)} / {element.amount}
                    </div>
                )
            },
        },

        {
            title: 'Quantity To dispatch',
            key: 'productColumns-quantity',
            render: (element: StockItemCallOff | BuiltItemCallOff) => {
                return (
                    <ConditionalToolTip
                        condition={showAmountDispatchingWarning(element)}
                        text="The amount of serial numbers dispatched does not match the amount selected to dispatch or some of the assembly lines are incomplete. Expand the table and select the serial numbers"
                    >
                        <Input
                            value={amountInputs[getProductCallOffKey(element)]}
                            suffix={
                                showAmountDispatchingWarning(element) && (
                                    <WarningOutlined style={{ color: '#F58216' }} />
                                )
                            }
                            onChange={(event) => {
                                // check if the string is a positive integer 0 included, if not don't update the state. there are no helper function implemented
                                const amount = Number(event.target.value)
                                if (!Number.isInteger(amount) || amount < 0) {
                                    return
                                }

                                const newAmountInputs = { ...amountInputs }
                                const newValue = (newAmountInputs[getProductCallOffKey(element)] =
                                    amount.toString())

                                // if newValue is more than the available amount, don't update the state
                                if (Number(newValue) > getAvailableAmount(element)) {
                                    return
                                }
                                setAmountInputs(newAmountInputs)
                            }}
                        />
                    </ConditionalToolTip>
                )
            },
        },
    ]

    const productColumnsExpandedTable = (callOffProduct: BuiltItemCallOff | StockItemCallOff) => {
        const key = getProductCallOffKey(callOffProduct)
        if (!isBuiltItemCallOff(callOffProduct)) {
            return null
        }
        if (!key) {
            return null
        }
        if (!callOffProduct.sopBuiltItemOrderline.builtItem.group?.bomGroupAssemblyFields) {
            return null
        }

        const assemblyLines = getAssemblyLinesForCallOffBuiltItem(callOffProduct)

        if (!assemblyLines) {
            return null
        }

        if (!hasSerialNumberAssemblyField(callOffProduct) || !key) {
            return null
        }

        return (
            <Table
                rowKey="id"
                columns={productsExpandedTableColumns[key]}
                dataSource={assemblyLines}
                pagination={false}
            />
        )
    }

    const confirmColumns: ColumnsType<StockItemCallOff | BuiltItemCallOff> = [
        {
            title: 'Code',
            key: 'productColumns-code',
            render: (element: StockItemCallOff | BuiltItemCallOff) => {
                if (isStockItemCallOff(element)) {
                    return `${element.sopStockItemOrderline.product.code}`
                } else if (isBuiltItemCallOff(element)) {
                    return `${element.sopBuiltItemOrderline.builtItem.bomCode}`
                }
            },
        },
        {
            title: 'Description',
            key: 'productColumns-description',
            render: (element: StockItemCallOff | BuiltItemCallOff) => {
                if (isStockItemCallOff(element)) {
                    return `${element.sopStockItemOrderline.product.description}`
                } else if (isBuiltItemCallOff(element)) {
                    return `${element.sopBuiltItemOrderline.builtItem.product.description}`
                }
            },
        },
        {
            title: 'Dispatched / Total Amount',
            key: 'productColumns-amount',
            render: (element: StockItemCallOff | BuiltItemCallOff) => {
                return (
                    <div>
                        {getTotalAmountGoodsDispatched(element.goodsDispatched)} / {element.amount}
                    </div>
                )
            },
        },

        {
            title: 'Quantity To dispatch',
            key: 'productColumns-quantity',
            render: (element: StockItemCallOff | BuiltItemCallOff) => {
                return (
                    <ConditionalToolTip
                        condition={showAmountDispatchingWarning(element)}
                        text="The amount of serial numbers dispatched does not match the amount selected to dispatch. Expand the table and select the serial numbers"
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {amountInputs[getProductCallOffKey(element)]}
                            {showAmountDispatchingWarning(element) && (
                                <WarningOutlined
                                    style={{ color: '#F58216', paddingBottom: '5px ' }}
                                />
                            )}
                        </div>
                    </ConditionalToolTip>
                )
            },
        },
    ]

    const confirmExpandedTableColumns = useMemo((): {
        [key: string]: ColumnsType<AssemblyLine>
    } => {
        const assemblyLineTableColumns: {
            [key: string]: ColumnsType<AssemblyLine>
        } = {}

        if (!callOff) {
            return assemblyLineTableColumns
        }

        callOff.builtItemCallOffs.forEach((callOffProduct) => {
            const key = getProductCallOffKey(callOffProduct)
            if (hasSerialNumberAssemblyField(callOffProduct) && key) {
                const assemblyLineColumns: ColumnsType<AssemblyLine> = [
                    {
                        title: 'BOM Code',
                        key: 'assemblyLineTableColumns-bomCode',
                        render: (assemblyLine: AssemblyLine) => {
                            return getBuiltItemCodeByAssemblyId(assemblyLine.assemblyId)
                        },
                    },
                ]
                callOffProduct.sopBuiltItemOrderline.builtItem.group?.bomGroupAssemblyFields.forEach(
                    (bomGroupAssemblyField) => {
                        if (bomGroupAssemblyField.assemblyField.isSerial) {
                            assemblyLineColumns.push({
                                title: bomGroupAssemblyField.assemblyField.name,
                                render: (assemblyLine: AssemblyLine) => {
                                    return assemblyLine.assemblyLineFields.find(
                                        (assemblyLineField) => {
                                            return (
                                                assemblyLineField.assemblyFieldId ===
                                                bomGroupAssemblyField.assemblyFieldId
                                            )
                                        }
                                    )?.value
                                },
                                key: `assemblyLine-${bomGroupAssemblyField.assemblyField.name}`,
                            })
                        }
                    }
                )
                assemblyLineColumns.push({
                    title: 'Select Assembly Line',
                    key: 'check',
                    render: (assemblyLine: AssemblyLine) => (
                        <Checkbox
                            onChange={(e) => {
                                if (e.target.checked) {
                                    addDispatchAmountInput(
                                        [assemblyLine.id],
                                        getProductCallOffKey(callOffProduct)
                                    )
                                } else {
                                    removeDispatchAmountInput(
                                        assemblyLine.id,
                                        getProductCallOffKey(callOffProduct)
                                    )
                                }
                            }}
                            disabled={true}
                            checked={dispatchInputs[getProductCallOffKey(callOffProduct)]?.includes(
                                assemblyLine.id
                            )}
                        />
                    ),
                })
                assemblyLineTableColumns[key] = assemblyLineColumns
            }
        })
        return assemblyLineTableColumns
    }, [callOff, dispatchInputs, amountInputs])

    const confirmColumnExpandedTable = (callOffProduct: BuiltItemCallOff | StockItemCallOff) => {
        const key = getProductCallOffKey(callOffProduct)
        if (!isBuiltItemCallOff(callOffProduct)) {
            return null
        }
        if (!key) {
            return null
        }
        if (!callOffProduct.sopBuiltItemOrderline.builtItem.group?.bomGroupAssemblyFields) {
            return null
        }

        const assemblyLines = getAssemblyLinesForCallOffBuiltItem(callOffProduct)

        if (!assemblyLines) {
            return null
        }

        if (!hasSerialNumberAssemblyField(callOffProduct) || !key) {
            return null
        }

        return (
            <Table
                rowKey="id"
                columns={confirmExpandedTableColumns[key]}
                dataSource={assemblyLines}
                pagination={false}
            />
        )
    }

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth={'lg'} style={{ zIndex: '100' }}>
            <Box
                sx={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: '15px 15px 10px 23px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div> Dispatch Call Off </div>
                <IconButton aria-label="close" onClick={closeModal}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider />
            <Container>
                {modalState === ModalState.Success && callOff && (
                    <>
                        <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '100%' }}>
                            <Step
                                style={{
                                    cursor: activeStep > Steps.Products ? 'pointer' : 'cursor',
                                }}
                                onClick={() => {
                                    if (activeStep > Steps.Products) {
                                        setActiveStep(Steps.Products)
                                    }
                                }}
                                key={Steps.Details}
                            >
                                <StepLabel style={{ wordBreak: 'break-all' }}>Products</StepLabel>
                            </Step>
                            <Step
                                style={{
                                    cursor: activeStep > Steps.Details ? 'pointer' : 'cursor',
                                }}
                                onClick={() => {
                                    if (activeStep > Steps.Details) {
                                        setActiveStep(Steps.Details)
                                    }
                                }}
                                key={Steps.Details}
                            >
                                <StepLabel style={{ wordBreak: 'break-all' }}>Details</StepLabel>
                            </Step>

                            <Step
                                style={{
                                    cursor: 'cursor',
                                }}
                                key={Steps.Confirm}
                            >
                                <StepLabel style={{ wordBreak: 'break-all' }}>Confirm</StepLabel>
                            </Step>
                        </Stepper>
                        {/** MODAL CONTENT */}
                        {activeStep === Steps.Products && (
                            <>
                                <Table
                                    columns={productColumns}
                                    dataSource={[
                                        ...callOff.stockItemCallOffs,
                                        ...callOff.builtItemCallOffs,
                                    ]}
                                    expandable={{
                                        expandedRowRender: productColumnsExpandedTable,
                                        rowExpandable: (record) =>
                                            hasSerialNumberAssemblyField(record),
                                    }}
                                    pagination={false}
                                />
                            </>
                        )}
                        {activeStep === Steps.Details && (
                            <>
                                <InfoTextContainer>
                                    <h4>
                                        Confirm that the corret products and their respective
                                        amounts are being shipped
                                    </h4>
                                </InfoTextContainer>
                                <TextFieldContainer>
                                    <Select
                                        size={'large'}
                                        style={{ width: '100%' }}
                                        value={dispatchCourier || 'Other'}
                                        onChange={(v) => setDispatchCourier(v)}
                                    >
                                        {dispatchCouriers.map((v) => (
                                            <Select.Option key={v} value={v}>
                                                {v}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        label="Consignment Number"
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setConsignmentNumber(e.target.value)
                                        }}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        label="Dispatch Note"
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setDispatchNote(e.target.value)
                                        }}
                                    />
                                </TextFieldContainer>
                            </>
                        )}
                        {activeStep === Steps.Confirm && (
                            <>
                                <InfoTextContainer>
                                    <h4>
                                        Confirm that the corret products and their respective
                                        amounts are being shipped
                                    </h4>
                                </InfoTextContainer>
                                <Box>
                                    <Table
                                        rowKey="id"
                                        columns={confirmColumns}
                                        dataSource={[
                                            ...callOff.stockItemCallOffs,
                                            ...callOff.builtItemCallOffs,
                                        ]}
                                        expandable={{
                                            expandedRowRender: confirmColumnExpandedTable,
                                            rowExpandable: (record) =>
                                                hasSerialNumberAssemblyField(record),
                                        }}
                                        pagination={false}
                                    />
                                </Box>
                            </>
                        )}
                    </>
                )}
                {/** FOOTER */}
                <Row>
                    <ModalButton variant="outlined" size="small" onClick={closeModal}>
                        Close
                    </ModalButton>
                    {activeStep === Steps.Products && (
                        <ModalButton
                            variant="outlined"
                            size="small"
                            disabled={disableProductsStep()}
                            onClick={() => {
                                setActiveStep(Steps.Details)
                            }}
                        >
                            Continue
                        </ModalButton>
                    )}
                    {activeStep === Steps.Details && (
                        <ModalButton
                            variant="outlined"
                            size="small"
                            disabled={disableConfirmDetailsStep()}
                            onClick={() => {
                                setActiveStep(Steps.Confirm)
                            }}
                        >
                            Continue
                        </ModalButton>
                    )}
                    {activeStep === Steps.Confirm && callOff && (
                        <ModalButton
                            variant="outlined"
                            size="small"
                            onClick={async () => {
                                if (!dispatchCourier) {
                                    return
                                }
                                const response = await dispatchCallOffRequest(callOff.id, {
                                    builtItemCallOffs: callOff.builtItemCallOffs
                                        .map((v) => ({
                                            builtItemCallOffId: v.id,
                                            amount: getDispatchingAmount(v),
                                            assemblyLineIds: getDispatchingIds(v),
                                        }))
                                        .filter((v) => v.amount > 0),
                                    stockItemCallOffs: callOff.stockItemCallOffs
                                        .map((v) => ({
                                            stockItemCallOffId: v.id,
                                            amount: getDispatchingAmount(v),
                                        }))
                                        .filter((v) => v.amount > 0),
                                    dispatchCourier,
                                    consignmentNumber,
                                    dispatchNote,
                                })
                                if (response.successful) {
                                    toastSuccess('Dispatched Call Off')
                                    onOk()
                                    //check if call off is dispatched
                                    if (!response.data.dispatchedDate) {
                                        history.push(Urls.WarehouseMap)
                                    }
                                } else {
                                    toastFailure(response.message)
                                }
                            }}
                        >
                            Complete
                        </ModalButton>
                    )}
                </Row>
            </Container>
        </Dialog>
    )
}
