import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import React, { useRef } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
    isAddedSopAdditionalCharge,
    isAddedSopProjectBuiltItem,
    isAddedSopProjectStockItem,
    Orderline,
} from './CreateSaleOrder'

interface Props {
    orderlines: Array<Orderline>
    setOrderlines: React.Dispatch<React.SetStateAction<Orderline[]>>
}
export const OrderlinesTable = ({ orderlines, setOrderlines }: Props) => {
    const onMove = (fromIndex: number, toIndex: number) => {
        const temp = [...orderlines]
        const element = orderlines[fromIndex]
        temp.splice(fromIndex, 1)
        temp.splice(toIndex, 0, element)
        setOrderlines(temp)
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <Table
                columns={columns}
                dataSource={orderlines}
                components={{
                    body: {
                        row: DraggableBodyRow,
                    },
                }}
                onRow={(_, index) => {
                    const attr = {
                        index,
                        onMove,
                    }
                    return attr as React.HTMLAttributes<any>
                }}
            />
        </DndProvider>
    )
}

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    index: number
    onMove: (dragIndex: number, hoverIndex: number) => void
}

const type = 'DraggableBodyRow'

export const DraggableBodyRow = ({
    index,
    onMove,
    className,
    style,
    ...restProps
}: DraggableBodyRowProps) => {
    const ref = useRef<HTMLTableRowElement>(null)
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const item = monitor.getItem<DraggableBodyRowProps>() || {}
            if (item.index === index) {
                return {}
            }

            return {
                isOver: monitor.isOver(),
                dropClassName: item.index < index ? ' drop-over-downward' : ' drop-over-upward',
            }
        },
        drop: (item: DraggableBodyRowProps) => {
            if (!item) {
                return
            }
            onMove(item.index, index)
        },
    })
    const [, drag] = useDrag({
        type,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    drop(drag(ref))

    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{ cursor: 'move', ...style }}
            {...restProps}
        />
    )
}

const columns: ColumnsType<Orderline> = [
    {
        title: 'Line type',
        render: (item: Orderline) => {
            if (isAddedSopProjectBuiltItem(item)) {
                return 'Built item'
            } else if (isAddedSopProjectStockItem(item)) {
                return 'Stock item'
            } else if (isAddedSopAdditionalCharge(item)) {
                return 'Charge'
            } else {
                return 'Comment'
            }
        },
        key: 'lineValue',
    },
    {
        title: 'Line value',
        render: (item: Orderline) => {
            if (isAddedSopProjectBuiltItem(item)) {
                return item.builtItem.bomCode
            } else if (isAddedSopProjectStockItem(item)) {
                return item.stockItem.code
            } else if (isAddedSopAdditionalCharge(item)) {
                return item.code
            } else {
                return item.description
            }
        },
        key: 'lineValue',
    },
    {
        title: 'Amount',
        render: (item: Orderline) => {
            if (isAddedSopProjectBuiltItem(item)) {
                return item.addedAmount
            } else if (isAddedSopProjectStockItem(item)) {
                return item.addedAmount
            } else if (isAddedSopAdditionalCharge(item)) {
                return '1'
            } else {
                return ''
            }
        },
        key: 'amount',
    },
    {
        title: 'Price each',
        render: (item: Orderline) => {
            if (isAddedSopProjectBuiltItem(item)) {
                return item.unitPrice
            } else if (isAddedSopProjectStockItem(item)) {
                return item.unitPrice
            } else if (isAddedSopAdditionalCharge(item)) {
                return item.netValue
                //is comment
            } else {
                return null
            }
        },
        key: 'priceEach',
    },
    {
        title: 'Total',
        render: (item: Orderline) => {
            if (isAddedSopProjectBuiltItem(item)) {
                return item.addedAmount * item.unitPrice
            } else if (isAddedSopProjectStockItem(item)) {
                return item.addedAmount * item.unitPrice
            } else if (isAddedSopAdditionalCharge(item)) {
                return item.netValue
                //is comment
            } else {
                return null
            }
        },
        key: 'Total',
    },
]
