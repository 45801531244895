import Box from '@mui/material/Box'
import {
    CircularProgress,
    Dialog,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { Pallet, User } from '../../../models/models'
import { InputSection as InputSectionComponent } from '../../ui/InputFields'
import { useEffect, useState } from 'react'
import { getPalletRequest } from '../../../api/warehouse-map'
import { toastFailure } from '../../../util/toast'

interface PalletInfoModalProps {
    isOpen: boolean
    onClose: () => void
    palletId: number
}

const InputSection = (props: { children: React.ReactNode; text: string }) => {
    return (
        <InputSectionComponent width={120} text={props.text}>
            {props.children}
        </InputSectionComponent>
    )
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

export const InspectPalletModal = ({ isOpen, onClose, palletId }: PalletInfoModalProps) => {
    const [pallet, setPallet] = useState<Pallet>()
    const [projectManager, setProjectManager] = useState<User>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)

    useEffect(() => {
        getPalletRequest(palletId).then((response) => {
            if (response.successful) {
                if (!response.data) {
                    toastFailure('Could not find the pallet')
                    setModalState(ModalState.NotFound)
                    onClose()
                }
                setProjectManager(response.data?.projectManager)
                setModalState(ModalState.Success)
                setPallet(response.data)
            } else {
                if (response.status === 404) {
                    toastFailure('Could not find the pallet')
                    setModalState(ModalState.NotFound)
                    onClose()
                } else {
                    toastFailure('Something went wrong retrieving the pallet')
                    setModalState(ModalState.Error)
                    onClose()
                }
            }
        })
    }, [])

    return (
        <Dialog open={isOpen} onClose={onClose} style={{ zIndex: 100 }} fullWidth maxWidth={'sm'}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '15px',
                }}
            >
                <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            {modalState === ModalState.Loading && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        margin: '20px',
                        width: '100%',
                        height: '500px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                </Box>
            )}
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.NotFound && (
                <Box>Could not find the pallet with id {palletId}</Box>
            )}
            {modalState === ModalState.Success && (
                <Box style={{ width: '100%' }}>
                    <Box>
                        <Box style={{ margin: '30px' }}>
                            <InputSection text={'Total quantity'}>
                                {pallet?.palletProducts.reduce((a, c) => a + c.amount, 0)}
                            </InputSection>
                            {pallet?.project && (
                                <InputSection text={'Project'}>{pallet.project.name}</InputSection>
                            )}
                            {pallet?.projectManager && (
                                <InputSection text={'Project manager'}>
                                    {pallet.projectManager
                                        ? `${projectManager?.firstName} ${projectManager?.lastName}`
                                        : 'None'}
                                </InputSection>
                            )}
                            <InputSection text={'Stack level'}>{pallet?.stackLevel}</InputSection>
                            {pallet?.assembly && (
                                <InputSection text={'Call off id'}>
                                    {pallet?.assembly.callOffId}
                                </InputSection>
                            )}
                            <InputSection text={'Assembly id'}>{pallet?.assemblyId}</InputSection>
                            <InputSection text={'Pallet id'}>{pallet?.id}</InputSection>
                            <InputSection text={'Order confirmation'}>
                                {pallet?.delivery?.orderConfirmation?.code}
                            </InputSection>
                            <InputSection text={'Comment'}>{pallet?.comment}</InputSection>
                            <InputSection text={'System comment'}>
                                {pallet?.systemComment}
                            </InputSection>
                            <InputSection text={'Products'}>
                                <Table size="medium">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Code</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Quantity</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pallet?.palletProducts.map((pp) => {
                                            const { amount, product } = pp
                                            return (
                                                <TableRow key={product.code}>
                                                    <TableCell>{product.code}</TableCell>
                                                    <TableCell>{product.description}</TableCell>
                                                    <TableCell>{amount}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </InputSection>
                        </Box>
                    </Box>
                </Box>
            )}
        </Dialog>
    )
}
