import { EnvironmentValue } from './sage-configuration'

export const BACKEND_URL: string = (() => {
    const backendUrls: EnvironmentValue = {
        DEVELOPMENT: process.env.REACT_APP_DEVELOPMENT_BACKEND_URL,
        STAGING: process.env.REACT_APP_STAGING_BACKEND_URL,
        PRODUCTION: process.env.REACT_APP_PRODUCTION_BACKEND_URL,
    }

    const backendUrl = backendUrls[process.env.REACT_APP_ENVIRONMENT as keyof EnvironmentValue]
    if (!backendUrl) {
        throw new Error('Could not find sage client id')
    }
    return backendUrl
})()

export const BACKEND_WEBSOCKET: string = (() => {
    const backendWebsocketUrls: EnvironmentValue = {
        DEVELOPMENT: process.env.REACT_APP_DEVELOPMENT_BACKEND_URL,
        STAGING: process.env.REACT_APP_STAGING_BACKEND_URL,
        PRODUCTION: process.env.REACT_APP_PRODUCTION_BACKEND_URL,
    }

    const backendWebsocketUrl =
        backendWebsocketUrls[process.env.REACT_APP_ENVIRONMENT as keyof EnvironmentValue]
    if (!backendWebsocketUrl) {
        throw new Error('Could not find sage client id')
    }

    return backendWebsocketUrl.split('/api')[0]
})()
