import { Box, styled } from '@mui/system'

const Header = styled(Box)({})

const HeaderContent = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    margin: '15px 5px 35px 5px',
    width: '100%',
})

interface CardHeaderProps {
    children: React.ReactNode | React.ReactNode[]
}

export default function CardHeader({ children }: CardHeaderProps) {
    return (
        <Header>
            <HeaderContent>{children}</HeaderContent>
        </Header>
    )
}
