import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { ComponentsVariants } from '@mui/material/styles/variants'

export const SelectThemeStyles: {
    styleOverrides: ComponentsOverrides['MuiSelect']
    variants: ComponentsVariants['MuiSelect']
} = {
    styleOverrides: {
        select: {
            borderRadius: 10,
        },
    },
    variants: [
        {
            props: { color: 'primary', variant: 'outlined' },
            style: {
                '& fieldset': {
                    display: 'none',
                },
            },
        },
    ],
}
