import { WarehouseAreaSpot } from '../../models/models'
import { WarehouseMapColors } from '../../theme/colors'

export const SPOT_SIZE_HEIGHT_PX = 44
export const SPOT_SIZE_WIDTH_PX = 44

//should use variables for readability instead of numbers
export const getAssemblySpotColor = ({
    x,
    y,
    isCreationSpot,
    isDeskSpot,
}: {
    x?: number
    y?: number
    isCreationSpot: boolean | undefined
    isDeskSpot: number | undefined
}): string => {
    if (x === undefined || y === undefined) {
        return 'transparent'
    }

    if (isCreationSpot) {
        return '#d3d3d3'
    }

    if (isDeskSpot) {
        return '#fff'
    }

    switch (true) {
        case x <= 7:
            return WarehouseMapColors.lightGreen
        case x >= 21 && x % 2 === 0:
            return WarehouseMapColors.lighterBlue
        case x >= 21 && x % 2 === 1:
            return WarehouseMapColors.white
        default:
            return 'transparent'
    }
}

export const getLogisticsSpotColor = ({
    x,
    y,
    isCreationSpot,
}: {
    x?: number
    y?: number
    isCreationSpot?: boolean
}): string => {
    if (x === undefined || y === undefined) {
        return 'transparent'
    }

    if (isCreationSpot) {
        return '#d3d3d3'
    }

    switch (true) {
        case x <= 3 && y <= 6:
            return WarehouseMapColors.lightGreen
        case x >= 4 && x <= 15 && y <= 6: //19
            return WarehouseMapColors.red
        case x >= 4 && x <= 19 && y <= 6:
            return WarehouseMapColors.longStayColor
        case (y == 9 && x >= 8 && x <= 35) ||
            (y == 10 && x >= 8 && x <= 35) ||
            (y == 11 && x >= 8 && x <= 35) ||
            (y == 8 && x >= 8 && x <= 35):
            return 'white'
        case (x >= 20 && y <= 6) || (x == 27 && y <= 4):
            return WarehouseMapColors.blue
        case (x >= 20 && y <= 6) || (x == 27 && y <= 4):
            return WarehouseMapColors.blue
        default:
            return 'transparent'
    }
}

export const getSpotBackgroundColor = (spot?: Partial<Omit<WarehouseAreaSpot, 'id'>>) => {
    if (spot && spot.warehouseAreaId === 1) {
        return getLogisticsSpotColor({
            x: spot.x,
            y: spot.y,
            isCreationSpot: spot.isCreationSpot,
        })
    }
    if (spot && spot.warehouseAreaId === 2) {
        return getAssemblySpotColor({
            x: spot.x,
            y: spot.y,
            isCreationSpot: spot.isCreationSpot,
            isDeskSpot: spot.assemblerId,
        })
    }
    return 'transparent'
}
