import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { useState } from 'react'
import CreateCustomerAddressModal from './modals/CreateCustomerAddressModal'
import { DeliveryAddress } from '../../../../api/customers'
import { AnalysisCodeValueDTO, ContactDTO, CustomerSopTemplate } from '../../../../api/sales-orders'
import { Button, Input, Select } from 'antd'
import { SelectValue } from 'antd/lib/select'
import SelectDeliveryAddressModal from '../../../modals/select-delivery-address-modal/SelectDeliveryAddressModal'
import { FieldContainer } from '../../../ui/FIeldContainer'
import { Label } from '../../../ui/Label'

const { Option } = Select

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: '50px',
})

const DeliveryAddressContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
})

const BillingAddressContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
})

const AnalysisCodesContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
})

const ContactsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
})

enum ActiveModal {
    None = 'None',
    CreateCustomerAddress = 'CreateCustomerAddress',
    SelectCustomerDeliveryAddress = 'SelectCustomerDeliveryAddress',
}

interface DeliveryProps {
    template: CustomerSopTemplate
    onNextStep: () => void
    deliveryAddressOptions: DeliveryAddress[]
    setDeliveryAddressOptions: React.Dispatch<React.SetStateAction<DeliveryAddress[]>>
    setSelectedDeliveryAddress: React.Dispatch<React.SetStateAction<DeliveryAddress | undefined>>
    selectedDeliveryAddress: DeliveryAddress | undefined

    analysisCode1: string
    setAnalysisCode1: React.Dispatch<React.SetStateAction<string>>

    analysisCode2: string
    setAnalysisCode2: React.Dispatch<React.SetStateAction<string>>

    analysisCode3: number | undefined
    setAnalysisCode3: React.Dispatch<React.SetStateAction<number | undefined>>
    analysisCode3Options: AnalysisCodeValueDTO[]

    analysisCode4: number | undefined
    setAnalysisCode4: React.Dispatch<React.SetStateAction<number | undefined>>
    analysisCode4Options: AnalysisCodeValueDTO[]

    analysisCode5: string
    setAnalysisCode5: React.Dispatch<React.SetStateAction<string>>

    analysisCode6: number | undefined
    setAnalysisCode6: React.Dispatch<React.SetStateAction<number | undefined>>
    analysisCode6Options: AnalysisCodeValueDTO[]

    contacts: ContactDTO[]
    setContacts: (contacts: ContactDTO[]) => void
}

export default function Delivery(props: DeliveryProps) {
    const {
        template,
        contacts,
        setContacts,
        onNextStep,
        deliveryAddressOptions,
        setDeliveryAddressOptions,
        setSelectedDeliveryAddress,
        selectedDeliveryAddress,
        analysisCode1,
        setAnalysisCode1,
        analysisCode2,
        setAnalysisCode2,
        analysisCode3,
        setAnalysisCode3,
        analysisCode4,
        setAnalysisCode4,
        analysisCode5,
        setAnalysisCode5,
        analysisCode6,
        setAnalysisCode6,
        analysisCode3Options,
        analysisCode4Options,
        analysisCode6Options,
    } = props

    const [createCustomerAddressModalTemplate, setCreateCustomerAddressModalTemplate] = useState<
        DeliveryAddress | undefined
    >(selectedDeliveryAddress)
    const [activeModal, setActiveModal] = useState<ActiveModal>(ActiveModal.None)
    const [contactName, setContactName] = useState<string>('')
    const [contactDescription, setContactDescription] = useState<string>('')
    const [contactPhoneNumber, setContactPhoneNumber] = useState<string>('')
    const [deliveryAddressSearchText, setDeliveryAddressSearchText] = useState<string>('')

    const disableOnNextButton = () => {
        return !selectedDeliveryAddress
    }

    const findAnalysisCodeOptionById = (id: number, options: any[]): AnalysisCodeValueDTO => {
        return options.find((option) => option.id === id)
    }

    const findOptionByAddress1 = (address: string, options: DeliveryAddress[]) => {
        return options.find((option) => option.address1 === address)
    }

    const handleSelectedDeliveryAddressChange = (address1?: string) => {
        if (!address1) {
            setSelectedDeliveryAddress(undefined)
            return
        }
        setSelectedDeliveryAddress(findOptionByAddress1(address1, deliveryAddressOptions))
    }

    const handleSelectedAnalysisCodeOptionsChange = (
        e: SelectValue | undefined,
        array: any[],
        setValue: React.Dispatch<React.SetStateAction<number | undefined>>
    ) => {
        const analysisCodeOption = findAnalysisCodeOptionById(Number(e), array)
        setValue(analysisCodeOption.id)
    }

    const openCreateCustomerAddressModal = () => {
        setActiveModal(ActiveModal.CreateCustomerAddress)
        setCreateCustomerAddressModalTemplate(selectedDeliveryAddress)
    }

    const closeModal = () => {
        setActiveModal(ActiveModal.None)
        setCreateCustomerAddressModalTemplate(undefined)
    }

    const onNewAddressCreated = (deliveryAddress: DeliveryAddress) => {
        setDeliveryAddressOptions([...deliveryAddressOptions, deliveryAddress])
        setSelectedDeliveryAddress(deliveryAddress)
    }

    return (
        <Container>
            {activeModal === ActiveModal.CreateCustomerAddress && (
                <CreateCustomerAddressModal
                    closeModal={closeModal}
                    isOpen={true}
                    customerId={template.customerId}
                    delivery={createCustomerAddressModalTemplate}
                    onOk={onNewAddressCreated}
                />
            )}
            {activeModal === ActiveModal.SelectCustomerDeliveryAddress && (
                <SelectDeliveryAddressModal
                    closeModal={closeModal}
                    isOpen={true}
                    deliveryAddresses={deliveryAddressOptions}
                    onSelect={(deliveryAddress: DeliveryAddress) =>
                        setSelectedDeliveryAddress(deliveryAddress)
                    }
                />
            )}
            <DeliveryAddressContainer>
                <h2>Delivery Address</h2>

                <FieldContainer>
                    <Label>Address 1</Label>
                    <Input value={selectedDeliveryAddress?.address1} readOnly={true} />
                </FieldContainer>
                <FieldContainer>
                    <Label>Address 2</Label>
                    <Input value={selectedDeliveryAddress?.address2} readOnly={true} />
                </FieldContainer>
                <FieldContainer>
                    <Label>Address 3</Label>
                    <Input value={selectedDeliveryAddress?.address3} readOnly={true} />
                </FieldContainer>
                <FieldContainer>
                    <Label>Address 4</Label>
                    <Input value={selectedDeliveryAddress?.address4} readOnly={true} />
                </FieldContainer>
                <FieldContainer>
                    <Label>Postcode</Label>
                    <Input
                        key={selectedDeliveryAddress?.postcode}
                        value={selectedDeliveryAddress?.postcode}
                        readOnly={true}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Label>County</Label>
                    <Input
                        key={selectedDeliveryAddress?.county}
                        value={selectedDeliveryAddress?.county}
                        readOnly={true}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Label>Country</Label>
                    <Input
                        key={selectedDeliveryAddress?.country}
                        value={selectedDeliveryAddress?.country}
                        readOnly={true}
                    />
                </FieldContainer>
                <Button onClick={() => setActiveModal(ActiveModal.SelectCustomerDeliveryAddress)}>
                    Select Delivery Address
                </Button>
                <Button onClick={openCreateCustomerAddressModal}>Create new Address</Button>
            </DeliveryAddressContainer>

            <BillingAddressContainer>
                <h2>Billing Address</h2>
                <FieldContainer>
                    <Label>Address 1</Label>
                    <Input value={template.customer.mainAddress?.address1} readOnly={true} />
                </FieldContainer>

                <FieldContainer>
                    <Label>Address 2</Label>
                    <Input value={template.customer.mainAddress?.address2} readOnly={true} />
                </FieldContainer>
                <FieldContainer>
                    <Label>Address 3</Label>
                    <Input value={template.customer.mainAddress?.address3} readOnly={true} />
                </FieldContainer>
                <FieldContainer>
                    <Label>Address 4</Label>
                    <Input
                        id="outlined-read-only-input"
                        value={template.customer.mainAddress?.address4}
                        readOnly={true}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Label>Postcode</Label>
                    <Input value={template.customer.mainAddress?.postcode} readOnly={true} />
                </FieldContainer>
                <FieldContainer>
                    <Label>County</Label>
                    <Input value={template.customer.mainAddress?.county} readOnly={true} />
                </FieldContainer>
                <FieldContainer>
                    <Label>Country</Label>
                    <Input
                        value={template.customer.mainAddress?.country}
                        readOnly={true}
                        disabled={!template.customer.mainAddress?.country}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Label>City</Label>
                    <Input value={template.customer.mainAddress?.city} readOnly={true} />
                </FieldContainer>
            </BillingAddressContainer>

            <ContactsContainer>
                <h2>Contacts</h2>
                <FieldContainer style={{ width: '300px' }}>
                    <Label>Contact Name</Label>
                    <Input
                        autoComplete="off"
                        value={contactName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setContactName(e.target.value)
                        }}
                    />
                </FieldContainer>
                <FieldContainer style={{ width: '300px' }}>
                    <Label>Contact Description</Label>
                    <Input
                        autoComplete="off"
                        value={contactDescription}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setContactDescription(e.target.value)
                        }}
                    />
                </FieldContainer>
                <FieldContainer style={{ width: '300px' }}>
                    <Label>Contact Phone number</Label>
                    <Input
                        autoComplete="off"
                        value={contactPhoneNumber}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setContactPhoneNumber(e.target.value)
                        }}
                    />
                </FieldContainer>

                <Button
                    style={{ width: '300px', marginTop: '10px' }}
                    disabled={contactPhoneNumber.length < 6 && contactName.length < 2}
                    onClick={() => {
                        setContacts([
                            ...contacts,
                            {
                                name: contactName,
                                description: contactDescription,
                                phoneNumber: contactPhoneNumber,
                            },
                        ])
                        setContactDescription('')
                        setContactName('')
                        setContactPhoneNumber('')
                    }}
                >
                    Add Contact
                </Button>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Phone Number</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contacts.map((contact) => {
                            return (
                                <TableRow key={contact.description}>
                                    <TableCell>{contact.name}</TableCell>
                                    <TableCell>{contact.description}</TableCell>
                                    <TableCell>{contact.phoneNumber}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </ContactsContainer>

            <AnalysisCodesContainer>
                <h2>Analysis Codes</h2>
                <FieldContainer>
                    <Label>Project Name</Label>
                    <Input
                        autoComplete="off"
                        value={analysisCode1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setAnalysisCode1(e.target.value)
                        }}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Label>Project Number</Label>
                    <Input
                        autoComplete="off"
                        value={analysisCode2}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setAnalysisCode2(e.target.value)
                        }}
                    />
                </FieldContainer>

                <FieldContainer>
                    <Label>Project Managers</Label>
                    <Select
                        style={{ width: '100%' }}
                        value={analysisCode3}
                        onChange={(e: SelectValue) =>
                            handleSelectedAnalysisCodeOptionsChange(
                                e,
                                analysisCode3Options,
                                setAnalysisCode3
                            )
                        }
                    >
                        {analysisCode3Options.map((analysisCode3Option) => (
                            <Option key={analysisCode3Option.id} value={analysisCode3Option.id}>
                                {analysisCode3Option.value}
                            </Option>
                        ))}
                    </Select>
                </FieldContainer>
                <FieldContainer>
                    <Label>Project Region</Label>
                    <Select
                        style={{ width: '100%' }}
                        value={analysisCode4}
                        onChange={(e: SelectValue) =>
                            handleSelectedAnalysisCodeOptionsChange(
                                e,
                                analysisCode4Options,
                                setAnalysisCode4
                            )
                        }
                    >
                        {analysisCode4Options.map((analysisCode4Option) => (
                            <Option key={analysisCode4Option.id} value={analysisCode4Option.id}>
                                {analysisCode4Option.value}
                            </Option>
                        ))}
                    </Select>
                </FieldContainer>
                <FieldContainer>
                    <Label>Case Number</Label>
                    <Input
                        placeholder="case number"
                        autoComplete="off"
                        value={analysisCode5}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setAnalysisCode5(e.target.value)
                        }}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Label>Dispatch from master order</Label>
                    <Select
                        style={{ width: '100%' }}
                        value={analysisCode6}
                        onChange={(e: SelectValue) =>
                            handleSelectedAnalysisCodeOptionsChange(
                                e,
                                analysisCode6Options,
                                setAnalysisCode6
                            )
                        }
                    >
                        {analysisCode6Options.map((analysisCode6Option) => (
                            <Option key={analysisCode6Option.id} value={analysisCode6Option.id}>
                                {analysisCode6Option.value}
                            </Option>
                        ))}
                    </Select>
                </FieldContainer>
                <Button type="primary" disabled={disableOnNextButton()} onClick={onNextStep}>
                    Continue
                </Button>
            </AnalysisCodesContainer>
        </Container>
    )
}
