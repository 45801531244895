import { Button, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { ReactElement, useState } from 'react'
import { AuthenticationStage } from '../store/authentication-slice'
import { forgotPassword } from '../store/forgot-password-slice'
import { useAppSelector } from '../store/hooks'
import { store } from '../store/store'
import { UnderlineButton } from './ui/buttons/Buttons'
import { validateEmail } from '../util/validate-input'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import CTRLoader from './ui/loader/CTRloader'

interface Props {
    onCloseHandler: () => void
}

export default function ForgotPassword({ onCloseHandler }: Props): ReactElement {
    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<null | true>(null)
    const { state } = useAppSelector((state) => state.forgotPassword)

    const onSubmitForgetPassword = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!validateEmail(email)) return setEmailError(true)

        store.dispatch(forgotPassword(email))

        if (state === AuthenticationStage.Initial) {
            toast.success('Please check your email to change your password', {
                position: toast.POSITION.TOP_CENTER,
            })
        }

        if (state === AuthenticationStage.ServerError) {
            toast.error('Something went wrong', { position: toast.POSITION.TOP_CENTER })
        }
    }

    return (
        <div>
            <Box component="form" onSubmit={onSubmitForgetPassword}>
                <Typography color="white" style={{ width: '100%' }}>
                    Type in your email to receive an email to reset your password{' '}
                </Typography>
                <TextField
                    color="secondary"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                />
                {state === AuthenticationStage.Loading ? (
                    <div style={{ textAlign: 'center', padding: '5px' }}>
                        <CTRLoader />
                    </div>
                ) : (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Submit
                    </Button>
                )}
                {emailError && (
                    <Typography style={{ color: '#ff6060' }}>
                        Please type in a valid email
                    </Typography>
                )}
                <ToastContainer />
                <UnderlineButton onClick={onCloseHandler}>Go back</UnderlineButton>
            </Box>
        </div>
    )
}
