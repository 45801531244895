import { Paper, TableContainer } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { Project, ProjectDocument } from '../../../../models/models'
import { useEffect, useState } from 'react'
import {
    fetchDocuments,
    fetchDocumentTypes,
    startUploadingDocument,
} from '../../../../store/project-documents-slice'
import moment from 'moment'
import { PlusOutlined } from '@ant-design/icons'
import DocumentModal from './DocumentModal'
import DocumentTable from './DocumentTable'
import { FieldContainer } from '../../../ui/FIeldContainer'
import { Label } from '../../../ui/Label'
import DatePicker from '../../../ui/calendar/DatePicker'
import { Button } from 'antd'

function DocumentControl() {
    const dispatch = useAppDispatch()
    const projectBeingUpdated: Project | null = useAppSelector(
        (state) => state.singleProjectView.projectBeingUpdated
    )
    const [filterDateRangeBegin, setFilterDateRangeBegin] = useState<Date | null>(null)
    const [filterDateRangeEnd, setFilterDateRangeEnd] = useState<Date | null>(null)

    const projectMatchingOpportunityReference: Project | null = useAppSelector(
        (state) => state.singleProjectView.projectMatchingOpportunityReference
    )

    const project = projectBeingUpdated || projectMatchingOpportunityReference
    if (!project) {
        return null
    }

    useEffect(() => {
        dispatch(fetchDocumentTypes())
        dispatch(fetchDocuments(project))
    }, [])

    const documents: ProjectDocument[] = useAppSelector((state) => state.projectDocuments.documents)
    const typeFilter = useAppSelector((state) => state.projectDocuments.typeFilter)
    const userFilter = useAppSelector((state) => state.projectDocuments.userFilter)

    const displayDocuments = documents
        .filter((document) => !typeFilter.length || typeFilter.includes(document.type.id))
        .filter((document) => {
            if (filterDateRangeBegin === null || filterDateRangeEnd === null) {
                return true
            }
            const documentTime = moment(document.timeCreatedAt)
            const filterRangeBegin = moment(filterDateRangeBegin)
            const filterRangeEnd = moment(filterDateRangeEnd)
            return documentTime.isBetween(filterRangeBegin, filterRangeEnd)
        })
        .map((document) => ({
            ...document,
            versions: document.versions
                .filter(
                    (version) =>
                        !userFilter.length ||
                        (version.creatorUser && userFilter.includes(version.creatorUser.id))
                )
                .sort((a, b) => {
                    return (
                        moment(b.timeSubmittedAt || b.timeCreatedAt).unix() -
                        moment(a.timeSubmittedAt || a.timeCreatedAt).unix()
                    )
                }),
        }))
        .filter((document) => document.versions.length > 0)

    const onDateChange = (dates: [moment.Moment | null, moment.Moment | null] | null) => {
        if (dates === null) {
            setFilterDateRangeBegin(null)
            setFilterDateRangeEnd(null)
            return
        }

        if (dates[0] !== null) {
            setFilterDateRangeBegin(new Date(dates[0]?.format('MM-DD-YYYY') ?? ''))
        } else {
            setFilterDateRangeBegin(null)
        }

        if (dates[1] !== null) {
            setFilterDateRangeEnd(new Date(dates[1]?.format('MM-DD-YYYY') ?? ''))
        } else {
            setFilterDateRangeEnd(null)
        }
    }

    return (
        <div>
            <div
                style={{
                    paddingBottom: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                }}
            >
                <FieldContainer
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        onClick={() => dispatch(startUploadingDocument(null))}
                        icon={<PlusOutlined />}
                    >
                        Add Document
                    </Button>
                </FieldContainer>
                <FieldContainer>
                    <Label>Date</Label>
                    <DatePicker.RangePicker
                        style={{ width: '240px' }}
                        value={
                            filterDateRangeBegin === null || filterDateRangeEnd === null
                                ? [null, null]
                                : [moment(filterDateRangeBegin), moment(filterDateRangeEnd)]
                        }
                        onChange={onDateChange}
                    />
                </FieldContainer>
            </div>

            <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                <DocumentTable documents={displayDocuments} />
            </TableContainer>
            <DocumentModal />
        </div>
    )
}

export default DocumentControl
