export interface EnvironmentValue {
    DEVELOPMENT?: string
    STAGING?: string
    PRODUCTION?: string
}

export const CLIENT_ID: string = (() => {
    const clientIds: EnvironmentValue = {
        DEVELOPMENT: process.env.REACT_APP_DEVELOPMENT_SAGE_CLIENT_ID,
        STAGING: process.env.REACT_APP_STAGING_SAGE_CLIENT_ID,
        PRODUCTION: process.env.REACT_APP_PRODUCTION_SAGE_CLIENT_ID,
    }

    const clientId = clientIds[process.env.REACT_APP_ENVIRONMENT as keyof EnvironmentValue]
    if (!clientId) {
        throw new Error('Could not find sage client id')
    }
    return clientId
})()

export const AUTH_REDIRECT_URI: string = (() => {
    const authRedirectUris: EnvironmentValue = {
        DEVELOPMENT: process.env.REACT_APP_DEVELOPMENT_SAGE_AUTHENTICATION_REDIRECT_URI,
        STAGING: process.env.REACT_APP_STAGING_SAGE_AUTHENTICATION_REDIRECT_URI,
        PRODUCTION: process.env.REACT_APP_PRODUCTION_SAGE_AUTHENTICATION_REDIRECT_URI,
    }

    const authRedirectUri =
        authRedirectUris[process.env.REACT_APP_ENVIRONMENT as keyof EnvironmentValue]
    if (!authRedirectUri) {
        throw new Error('Could not find sage auth redirect URI')
    }
    return authRedirectUri
})()
