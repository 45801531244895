/* eslint-disable @typescript-eslint/no-empty-function */
import { Box } from '@mui/system'
import { WarehouseAreaSpot } from '../../models/models'
import PalletStack from './Stack'
import Pallet from './Pallet'
import { styled } from '@mui/material'
import { getSpotBackgroundColor, SPOT_SIZE_HEIGHT_PX, SPOT_SIZE_WIDTH_PX } from './style'
import { useMemo } from 'react'
import { useAppSelector } from '../../store/hooks'
import {
    ActiveModal,
    openModal,
    SelectedType,
    updateCompleteAssemblyState,
    ViewType,
} from '../../store/warehouse-map-slice'
import { useDispatch } from 'react-redux'

type SpotProperties = Partial<Omit<WarehouseAreaSpot, 'id'>>
type ContainerProps = { spot: SpotProperties; isBeingMovedTo: boolean }

const Container = styled(Box, { shouldForwardProp: (propName) => propName !== 'isBeingMovedTo' })<
    Partial<ContainerProps>
>((props) => {
    const { spot, isBeingMovedTo } = props

    const RED_OUTLINE = '1px solid red'

    const outline = () => {
        let outline = 'none'
        if (isBeingMovedTo) {
            outline = RED_OUTLINE
        }
        return outline
    }

    return {
        backgroundColor: getSpotBackgroundColor(spot),
        width: SPOT_SIZE_WIDTH_PX,
        height: SPOT_SIZE_HEIGHT_PX,
        outline: outline(),
        margin: '2px 2px',
        position: 'relative',
        cursor: 'inherit',
    }
})

export default function Spot({ spot }: { spot: WarehouseAreaSpot | undefined }) {
    const { selected, viewType, modalSpotId, activeModal, completeAssemblyState } = useAppSelector(
        (state) => state.warehouseMap
    )
    const dispatch = useDispatch()

    const isPallet = useMemo(() => {
        return spot !== undefined && spot.pallets.length === 1
    }, [spot])

    const isStack = useMemo(() => {
        return spot !== undefined && spot.pallets.length > 1
    }, [spot])

    const assemblyCompletedPalletsOnSpot = useMemo(() => {
        return (
            completeAssemblyState?.pallets.filter((p) => p?.spot?.id && p?.spot?.id === spot?.id) ??
            []
        )
    }, [spot, completeAssemblyState])

    const isBeingMovedTo = useMemo(() => {
        return (
            spot !== undefined &&
            (completeAssemblyState?.pallets.some((p) => p?.spot?.id === spot.id) ||
                (spot.id === modalSpotId &&
                    (activeModal === ActiveModal.ConfirmStackMovement ||
                        activeModal === ActiveModal.ConfirmPalletMovement)))
        )
    }, [spot, activeModal, modalSpotId, completeAssemblyState])

    const onClicks: { [key in ViewType]: (e: React.MouseEvent<HTMLElement>) => void } = {
        [ViewType.AssemblyComplete]: () => {
            if (
                !spot ||
                !completeAssemblyState ||
                !completeAssemblyState?.selectedPallet ||
                spot.pallets.length > 0
            ) {
                return
            }
            //check if there are already 3 pallets on this spot, if there are, do nothing
            if (assemblyCompletedPalletsOnSpot.length >= 3) {
                return
            }

            dispatch(
                updateCompleteAssemblyState({
                    ...completeAssemblyState,
                    pallets: completeAssemblyState.pallets.map((p) => {
                        if (p.key === completeAssemblyState?.selectedPallet) {
                            return {
                                ...p,
                                spot,
                            }
                        }
                        return p
                    }),
                    selectedPallet: null,
                })
            )
        },
        [ViewType.AssemblyDispatch]: () => {
            if (!spot) {
                return
            }
            if (selected.type === SelectedType.Stack) {
                dispatch(openModal({ modal: ActiveModal.ConfirmStackMovement, spotId: spot.id }))
            }
            if (selected.type === SelectedType.Pallet) {
                dispatch(openModal({ modal: ActiveModal.ConfirmPalletMovement, spotId: spot.id }))
            }
        },
        [ViewType.AssemblyStart]: () => {},
        [ViewType.CallOffDispatch]: () => {
            if (!spot) {
                return
            }
            if (selected.type === SelectedType.Stack) {
                dispatch(openModal({ modal: ActiveModal.ConfirmStackMovement, spotId: spot.id }))
            }
            if (selected.type === SelectedType.Pallet) {
                dispatch(openModal({ modal: ActiveModal.ConfirmPalletMovement, spotId: spot.id }))
            }
        },
        [ViewType.Regular]: () => {
            if (!spot) {
                return
            }
            if (selected.type === SelectedType.Stack) {
                dispatch(openModal({ modal: ActiveModal.ConfirmStackMovement, spotId: spot.id }))
            }
            if (selected.type === SelectedType.Pallet) {
                dispatch(openModal({ modal: ActiveModal.ConfirmPalletMovement, spotId: spot.id }))
            }
        },
        [ViewType.Transfer]: () => {},
    }

    //id is a protected string value in JSX components
    const { id, ...containerProps } = spot || { id: 1 }

    return (
        <Container
            spot={containerProps}
            isBeingMovedTo={isBeingMovedTo}
            onClick={(e: React.MouseEvent<HTMLElement>) => onClicks[viewType](e)}
        >
            {ViewType.AssemblyComplete &&
                assemblyCompletedPalletsOnSpot.length > 0 &&
                assemblyCompletedPalletsOnSpot.map((p) => (
                    <p
                        style={{ fontSize: 10, paddingTop: '5px', lineHeight: 0 }}
                        key={p.key}
                    >{`x${p.amount}`}</p>
                ))}
            {isPallet && spot && <Pallet pallet={spot.pallets[0]} spot={spot} />}
            {isStack && spot && <PalletStack spot={spot} />}
        </Container>
    )
}
