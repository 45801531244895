import styled from '@emotion/styled'
import { Button, Select } from 'antd'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { WarehouseId } from '../../models/models'
import { useAppDispatch } from '../../store/hooks'
import { RootState } from '../../store/store'
import {
    ActiveModal,
    openModal,
    setFilterCompletedDateRangeBegin,
    setFilterCompletedDateRangeEnd,
    setFilterDateToBeCompletedByDateRangeBegin,
    setFilterDateToBeCompletedByDateRangeEnd,
    setFilterWarehouseId,
    GeneralAssemblyView,
} from '../../store/general-assemblies-slice'
import DatePicker from '../ui/calendar/DatePicker'

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

const Container = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-between;
`

const CreateContainer = styled('div')`
    margin-top: 30px;
`

const RowElement = styled('div')``

const FilterContainer = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
`

export default function FilterBar() {
    const {
        filterWarehouseId,
        activeView,
        filterCompletedDateRangeBegin,
        filterCompletedDateRangeEnd,
        filterDateToBeCompletedByDateRangeEnd,
        filterDateToBeCompletedBydDateRangeBegin,
    } = useSelector((state: RootState) => state.generalAssemblies)
    const dispatch = useAppDispatch()

    return (
        <Container>
            <FilterContainer>
                <RowElement>
                    <Label>Warehouse</Label>
                    <Select
                        style={{ width: '200px' }}
                        value={filterWarehouseId}
                        onChange={(value: WarehouseId) => dispatch(setFilterWarehouseId(value))}
                        options={[
                            { value: WarehouseId.Monument, label: 'Monument' },
                            { value: WarehouseId.Scandia, label: 'Scandia' },
                        ]}
                    />
                </RowElement>
                {activeView === GeneralAssemblyView.GeneralAssemblies && (
                    <RowElement>
                        <Label>Date to be completed by</Label>
                        <DatePicker.RangePicker
                            format="DD/MM/YYYY"
                            size={'large'}
                            allowClear={true}
                            value={
                                filterDateToBeCompletedBydDateRangeBegin &&
                                filterDateToBeCompletedByDateRangeEnd
                                    ? [
                                          moment.unix(filterDateToBeCompletedBydDateRangeBegin),
                                          moment.unix(filterDateToBeCompletedByDateRangeEnd),
                                      ]
                                    : null
                            }
                            onChange={(values) => {
                                if (!values) {
                                    dispatch(setFilterDateToBeCompletedByDateRangeBegin(undefined))
                                    dispatch(setFilterDateToBeCompletedByDateRangeEnd(undefined))
                                    return
                                }
                                dispatch(
                                    setFilterDateToBeCompletedByDateRangeBegin(values[0]!.unix())
                                )
                                dispatch(
                                    setFilterDateToBeCompletedByDateRangeEnd(values[1]!.unix())
                                )
                            }}
                        />
                    </RowElement>
                )}
                {activeView === GeneralAssemblyView.GeneralAssemblies && (
                    <RowElement>
                        <Label>Completed at</Label>
                        <DatePicker.RangePicker
                            format="DD/MM/YYYY"
                            size={'large'}
                            allowClear={true}
                            value={
                                filterCompletedDateRangeBegin && filterCompletedDateRangeEnd
                                    ? [
                                          moment.unix(filterCompletedDateRangeBegin),
                                          moment.unix(filterCompletedDateRangeEnd),
                                      ]
                                    : null
                            }
                            onChange={(values) => {
                                if (!values) {
                                    dispatch(setFilterCompletedDateRangeBegin(undefined))
                                    dispatch(setFilterCompletedDateRangeEnd(undefined))
                                    return
                                }

                                dispatch(setFilterCompletedDateRangeBegin(values[0]!.unix()))
                                dispatch(setFilterCompletedDateRangeEnd(values[1]!.unix()))
                            }}
                        />
                    </RowElement>
                )}
            </FilterContainer>
            <CreateContainer>
                {activeView === GeneralAssemblyView.GeneralAssemblies && (
                    <Button
                        type="primary"
                        onClick={() =>
                            dispatch(openModal({ activeModal: ActiveModal.GeneralAssemblyCreate }))
                        }
                    >
                        Create General Assembly
                    </Button>
                )}
            </CreateContainer>
        </Container>
    )
}
