import { ReactElement } from 'react'
import moment, { Moment } from 'moment'
import './calendar.css'
import { Warehouse } from '../../models/models'
import DateCell from './DateCell'
import Calendar from '../ui/calendar/Calendar'

interface Props {
    setCurrentDate(date: Moment): void
    currentDate: Moment
    selectedWarehouse: Warehouse
    setSelectedDate: (date: Moment) => void
}

export default function ProductionCalendarItem({
    setCurrentDate,
    selectedWarehouse,
    currentDate,
    setSelectedDate,
}: Props): ReactElement {
    const onChangeHandler = (date: Moment) => {
        if (currentDate.month() === date.month() && currentDate.year() === date.year()) {
            setSelectedDate(date)
        } else {
            setCurrentDate(date)
        }
    }

    return (
        <Calendar
            headerRender={() => null}
            className="production-calendar"
            mode="month"
            dateFullCellRender={(moment) => (
                <DateCell date={moment} warehouseId={selectedWarehouse.id} />
            )}
            value={currentDate}
            onSelect={onChangeHandler}
        />
    )
}
