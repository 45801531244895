import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { Project, ProjectEvent, ProjectEventType } from '../../../../models/models'
import { ReactNode, useEffect } from 'react'
import { fetchHistory } from '../../../../store/project-history-slice'
import { formatEnumName } from '../../../../util/util'
import moment from 'moment'
import SingleProjectViewNoData from '../SingleProjectViewNoData'

const ColorBubble = ({ hexColor }: { hexColor: string }) => {
    return (
        <span
            style={{
                height: '10px',
                width: '10px',
                background: hexColor,
                borderRadius: '10px',
                display: 'inline-block',
            }}
        />
    )
}

const Special = ({ children }: { children: ReactNode }) => {
    return (
        <span
            style={{
                background: '#f2f2f2',
                border: '1px solid #ddd',
                borderRadius: '5px',
                padding: '5px',
            }}
        >
            {children}
        </span>
    )
}

const createChangeDescription = (event: ProjectEvent) => {
    if (event.type === ProjectEventType.UpdatedOpportunityStatus && event.opportunityStatus) {
        return (
            <Typography>
                Changed opportunity status to{' '}
                <Special>
                    <ColorBubble hexColor={event.opportunityStatus.hexColor} />{' '}
                    {event.opportunityStatus.name}
                </Special>
            </Typography>
        )
    }

    if (event.type === ProjectEventType.UpdatedCreditStatus && event.creditStatus) {
        return (
            <Typography>
                Changed credit status to <Special>{event.creditStatus.name}</Special>
            </Typography>
        )
    }

    if (event.type === ProjectEventType.AddedUser && event.user) {
        return (
            <Typography>
                <Special>
                    {event.user.firstName} {event.user.lastName}
                </Special>{' '}
                added as <Special>{formatEnumName(event.user.role)}</Special>
            </Typography>
        )
    }

    if (event.type === ProjectEventType.RemovedUser && event.user) {
        return (
            <Typography>
                <Special>
                    {event.user.firstName} {event.user.lastName}
                </Special>{' '}
                removed as <Special>{formatEnumName(event.user.role)}</Special>
            </Typography>
        )
    }

    if (event.type === ProjectEventType.DispatchedCallOff && event.callOff) {
        return (
            <Typography>
                <Special>Call off {event.callOffId}</Special> Has been dispatched
            </Typography>
        )
    }

    if (event.type === ProjectEventType.SubmittedDocumentVersion) {
        if (event.documentVersion) {
            const document = event.documentVersion.document

            if (document) {
                const type = document.type

                if (event.documentVersion.version === 1) {
                    return (
                        <Typography>
                            Added new <Special>{type.name}</Special> document with name{' '}
                            <Special>{event.documentVersion.name}</Special>.
                        </Typography>
                    )
                }

                return (
                    <Typography>
                        Added version <Special>{event.documentVersion.version}</Special> of{' '}
                        <Special>{type.name}</Special> document with name{' '}
                        <Special>{event.documentVersion.name}</Special>.
                    </Typography>
                )
            }
        }
    }

    return <Typography>-</Typography>
}

function ProjectHistory() {
    const dispatch = useAppDispatch()
    const project: Project | null = useAppSelector(
        (state) => state.singleProjectView.projectBeingUpdated
    )
    const history: ProjectEvent[] = useAppSelector((state) => state.projectHistory.history)

    if (!project) {
        return null
    }

    useEffect(() => {
        dispatch(fetchHistory(project))
    }, [])

    return (
        <Box>
            <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                <Table sx={{ width: '100%', border: 'none' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>User</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Change</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Comment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history.map((event) => (
                            <TableRow
                                key={event.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    {moment(event.timeCreatedAt).format('MMMM Do YYYY, hh:mm')}
                                </TableCell>
                                <TableCell>{`${event?.author?.firstName} ${event?.author?.lastName}`}</TableCell>
                                <TableCell>{createChangeDescription(event)}</TableCell>
                                <TableCell>-</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {!history.length && <SingleProjectViewNoData />}
            </TableContainer>
        </Box>
    )
}

export default ProjectHistory
