import { styled } from '@mui/styles'
import { Box } from '@mui/system'

export const RoundBorderContainer = styled(Box)(() => ({
    border: '1px solid #D9D9D9',
    boxSizing: 'border-box',
    borderRadius: 16,
    padding: 24,
    height: '100%',
}))
