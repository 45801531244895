import { Box } from '@mui/system'
import { useState } from 'react'
import BuiltItems from './BuiltItems'
import StockItems from './StockItems'
import { RoundBorderContainer } from '../../../../../ui/container/RoundBorderContainer'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'

enum Views {
    BuiltItems = 'BuiltItems',
    StockItems = 'StockItems',
}
export default function AddedItems() {
    const [activeTab, setActiveTab] = useState<Views>(Views.BuiltItems)

    const handleTabChange = (event: React.SyntheticEvent, tabKey: Views) => {
        event.preventDefault()
        setActiveTab(tabKey)
    }
    return (
        <RoundBorderContainer
            sx={{ marginTop: '40px', paddingBottom: '0px !important', width: '80%' }}
        >
            <TabContext value={activeTab}>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '20px 50px 0px 50px',
                    }}
                >
                    <TabList onChange={handleTabChange}>
                        <Tab
                            label="Built Items"
                            value="BuiltItems"
                            style={{ textTransform: 'none' }}
                        />
                        <Tab
                            label="Stock Items"
                            value="StockItems"
                            style={{ textTransform: 'none' }}
                        />
                    </TabList>
                </Box>

                <TabPanel value="BuiltItems">
                    <BuiltItems />
                </TabPanel>
                <TabPanel value="StockItems">
                    <StockItems />
                </TabPanel>
            </TabContext>
        </RoundBorderContainer>
    )
}
