import { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/system'
import { Alert, Button, Divider, Input, InputNumber, Modal, Radio, Table } from 'antd'
import { Tooltip, styled } from '@mui/material'
import '../../tables/table-styles.css'
import { DeleteOutlined, EditOutlined, OrderedListOutlined } from '@ant-design/icons'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../store/hooks'
import { ActiveModal, closeModal } from '../../../store/products-slice'
import { Bom, BomGroup, Product } from '../../../models/models'
import { BomRelation, getBomById } from '../../../api/bom'
import { Response } from '../../../api/util/with-response-formatter-interceptor'
import { SearchInput } from '../../ui/SearchInput'
import { getBomGroupByIdRequest, getBomGroupsRequest } from '../../../api/bom-groups'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DraggableBodyRow } from '../../project-master/sales-orders/create/OrderlinesTable'
import {
    GetStockItemQueryParams,
    ProductGroup,
    getProductGroupByCodeRequest,
    getStockItemByIdRequest,
    getStockItemsRequest,
} from '../../../api/stock-item'
import {
    createUnauthorizedBomRequest,
    CreateUnauthorizedBomDto,
} from '../../../api/unauthorized-bom'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 50%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

enum ModalState {
    Error = 'Error',
    Ready = 'Ready',
}

interface ProductWithAmount extends Product {
    amount: number
}

enum ComponentSearchOptions {
    Code = 'Code',
    Name = 'Name',
}
const componentSearchOptions: Array<{ label: string; value: ComponentSearchOptions }> = [
    { label: 'Code', value: ComponentSearchOptions.Code },
    { label: 'Name', value: ComponentSearchOptions.Name },
]

export const CreateBomVersionModal = () => {
    const { activeModal, modalEntityId } = useSelector((state: RootState) => state.products)
    const [bom, setBom] = useState<Bom>()
    // setState for all the property fields
    const [bomGroup, setBomGroup] = useState<BomGroup>()
    const [code, setCode] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [modalState, setModalState] = useState<ModalState>(ModalState.Ready)
    const [selectedProductGroup, setSelectedProductGroup] = useState<ProductGroup>()
    const [componentSearchOption, setComponentSearchOption] = useState<ComponentSearchOptions>(
        ComponentSearchOptions.Code
    )
    const [assemblyMinutes, setAssemblyMinutes] = useState<number>()
    const [instructionLink, setInstructionLink] = useState<string>()
    const [components, setComponents] = useState<ProductWithAmount[]>([])
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (activeModal === ActiveModal.CreateBomVersion && modalEntityId) {
            getBomById(modalEntityId, {
                relations: [BomRelation.BomComponents, BomRelation.BomGroup, BomRelation.Product],
            }).then((response: Response<Bom>) => {
                if (!response.successful || !response?.data?.product) {
                    setModalState(ModalState.Error)
                    return
                } else {
                    setBom(response.data)
                    setAssemblyMinutes(response.data.assemblyMinutes)
                    setInstructionLink(response.data.instructionLink)
                    setBomGroup(response.data.group)
                    //setSelectedProductGroup(response.data.product?.grou)

                    getProductGroupByCodeRequest(response.data.product.productGroupCode).then(
                        (response: Response<ProductGroup>) => {
                            if (response.successful) {
                                setSelectedProductGroup(response.data)
                            }
                        }
                    )

                    setName(response.data.product.name)
                    setDescription(response.data.product.description)
                    setCode(response.data.product.code)
                    setComponents(
                        response.data.bomComponents.map((c) => ({ ...c.product, amount: c.amount }))
                    )
                    setBomGroup(response.data.group)
                    setModalState(ModalState.Ready)
                }
            })
        }
    }, [activeModal, modalEntityId])

    const resetState = () => {
        setBom(undefined)
        setModalState(ModalState.Ready)
    }

    const onClose = () => {
        resetState()
        dispatch(closeModal())
    }

    const onMove = (fromIndex: number, toIndex: number) => {
        const temp = [...components]
        const element = components[fromIndex]
        temp.splice(fromIndex, 1)
        temp.splice(toIndex, 0, element)
        setComponents(temp)
    }

    const isSubmitDisabled = useMemo(() => {
        if (!bom) {
            return true
        }
        return (
            JSON.stringify(bom.bomComponents.map((c) => ({ ...c.product, amount: c.amount }))) ===
            JSON.stringify(components)
        )
    }, [components])

    const onOk = async () => {
        if (
            !bom ||
            !bom.product ||
            !bom.product ||
            !bomGroup ||
            !components.length ||
            !assemblyMinutes
        ) {
            return
        }

        const dto: CreateUnauthorizedBomDto = {
            productId: bom.product?.id,
            bomGroupId: bomGroup.id,
            assemblyMinutes,
            instructionLink,
            code: bom.bomCode,
            components: components.map((c) => ({
                id: c.id,
                amount: c.amount,
            })),
            description: bom.product.description,
            name: bom.product.name,
        }

        const response = await createUnauthorizedBomRequest(dto)
        if (!response.successful) {
            toastFailure(response.message ?? 'Failed to create bom version')
            return
        }

        toastSuccess('Unauthorized bom version has been submitted')
        resetState()
        dispatch(closeModal(JSON.stringify(response.data)))
    }

    return (
        <Modal
            open={activeModal === ActiveModal.CreateBomVersion}
            footer={null}
            onCancel={onClose}
            width={900}
        >
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.Ready && bom && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Create Bom Version</Title>
                    </Header>
                    <Divider />
                    <Alert
                        style={{ marginBottom: '20px' }}
                        message={`
                    This modal is used to create a new Bom Version for Sage, therefore it requires the user to change the components. If desired to change assembly minutes, instruction link or the brom group and not components, it can be done through the update many boms modal.`}
                        type="info"
                        showIcon
                    />
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Code</Label>
                            <Input style={{ width: '100%' }} value={bom.bomCode} readOnly={true} />
                        </RowElement>
                        <RowElement>
                            <Label>Name</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={bom.product?.name}
                                readOnly={true}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Product Group</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={selectedProductGroup?.code ?? 'Not found'}
                                readOnly={true}
                            />
                        </RowElement>

                        <RowElement>
                            <Label>Bom Group</Label>
                            <SearchInput
                                style={{ width: '100%' }}
                                placeholder="Bom Group"
                                selectedValue={bomGroup?.id}
                                addonAfter={<EditOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
                                setSelectedValue={(id) => {
                                    if (!id) {
                                        return
                                    }
                                    getBomGroupByIdRequest(id, {}).then((r) => {
                                        if (r.successful) {
                                            setBomGroup(r.data)
                                        }
                                    })
                                }}
                                request={(search: string) =>
                                    getBomGroupsRequest({ name: search }).then((r) =>
                                        r.successful
                                            ? r.data.entities.map((bomGroup) => ({
                                                  label: bomGroup.name,
                                                  value: bomGroup.id,
                                              }))
                                            : [{ label: 'None', value: 2 }]
                                    )
                                }
                            />
                        </RowElement>
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Assembly Minutes</Label>
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0.1}
                                value={assemblyMinutes}
                                addonAfter={
                                    <Tooltip title="The assembly minutes is editable">
                                        <EditOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                                status={!assemblyMinutes || assemblyMinutes > 0 ? '' : 'error'}
                                onChange={(value: number | null) => {
                                    if (!value) {
                                        return
                                    }
                                    setAssemblyMinutes(value)
                                }}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Instructions</Label>
                            <Input
                                style={{ width: '100%' }}
                                suffix={
                                    <Tooltip title="The instruction link is editable">
                                        <EditOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                                value={instructionLink}
                                onChange={(e) => setInstructionLink(e.target.value)}
                            />
                        </RowElement>
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Description</Label>
                            <Input.TextArea
                                style={{ width: '100%', minHeight: 120 }}
                                readOnly={true}
                                value={bom.product?.description}
                            />
                        </RowElement>
                        <RowElement />
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px', gap: '' }}>
                        <RowElement>
                            <Label>Components</Label>
                            <SearchInput
                                style={{ width: '100%' }}
                                placeholder="Components"
                                selectedValue={undefined}
                                excludeValues={components.map((af) => af.id)}
                                setSelectedValue={(id) => {
                                    if (!id) {
                                        return
                                    }
                                    getStockItemByIdRequest(id).then((r) => {
                                        if (r.successful) {
                                            setComponents([...components, { ...r.data, amount: 1 }])
                                        }
                                    })
                                }}
                                request={(search: string) => {
                                    const requestConfig: GetStockItemQueryParams = {
                                        excludeIds: components.map((af) => af.id),
                                        limit: 100,
                                    }
                                    if (componentSearchOption === ComponentSearchOptions.Code) {
                                        requestConfig.likeCode = search
                                    }
                                    if (componentSearchOption === ComponentSearchOptions.Name) {
                                        requestConfig.likeName = search
                                    }

                                    return getStockItemsRequest(requestConfig).then(
                                        (r): Array<{ label: string; value: number }> => {
                                            if (!r.successful) {
                                                return [{ label: 'None', value: 2 }]
                                            }

                                            return r.data.entities.map((component) => {
                                                if (
                                                    componentSearchOption ===
                                                    ComponentSearchOptions.Code
                                                ) {
                                                    return {
                                                        label: component.code,
                                                        value: component.id,
                                                    }
                                                } else {
                                                    return {
                                                        label: component.name,
                                                        value: component.id,
                                                    }
                                                }
                                            })
                                        }
                                    )
                                }}
                            />
                        </RowElement>
                        <RowElement style={{ marginTop: 'auto' }}>
                            <Radio.Group
                                options={componentSearchOptions}
                                onChange={(e) => setComponentSearchOption(e.target.value)}
                                value={componentSearchOption}
                                optionType="button"
                                buttonStyle="solid"
                            />
                        </RowElement>
                    </InputRow>
                    <DndProvider backend={HTML5Backend}>
                        <Table
                            dataSource={components}
                            components={{
                                body: {
                                    row: DraggableBodyRow,
                                },
                            }}
                            onRow={(_, index) => {
                                const attr = {
                                    index,
                                    onMove,
                                }
                                return attr as React.HTMLAttributes<any>
                            }}
                            columns={[
                                {
                                    title: 'Code',
                                    dataIndex: 'code',
                                    key: 'code',
                                },
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    key: 'name',
                                },
                                {
                                    title: 'Amount',

                                    key: 'amount',
                                    render: (product: ProductWithAmount) => {
                                        const component = components.find(
                                            (c) => c.id === product.id
                                        )
                                        if (!component) {
                                            return
                                        }
                                        return (
                                            <InputNumber
                                                min={0.01}
                                                value={component.amount}
                                                status={component.amount > 0 ? '' : 'error'}
                                                onChange={(value) => {
                                                    if (!value) {
                                                        return
                                                    }
                                                    setComponents(
                                                        components.map((c) =>
                                                            c.id === product.id
                                                                ? { ...c, amount: value }
                                                                : c
                                                        )
                                                    )
                                                }}
                                            />
                                        )
                                    },
                                },
                                {
                                    title: 'Delete',
                                    key: 'id',
                                    render: (product: ProductWithAmount) => (
                                        <Button
                                            shape="circle"
                                            onClick={() => {
                                                setComponents(
                                                    components.filter(
                                                        (component) => component.id !== product.id
                                                    )
                                                )
                                            }}
                                            icon={<DeleteOutlined />}
                                        />
                                    ),
                                },
                            ]}
                            pagination={false}
                        />
                    </DndProvider>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                        <Button type={'primary'} disabled={isSubmitDisabled} onClick={onOk}>
                            Update
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    )
}
