import FormControl from '@mui/material/FormControl'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, styled } from '@mui/material'
import Divider from '@mui/material/Divider'
import { Select } from 'antd'
import { AbsentDayType, DaysOff, DaysOffType, Schedule, UserRole } from '../../../models/models'
import Button from '@mui/material/Button'
import moment from 'moment'
import DeleteIcon from '@mui/icons-material/Delete'
import { useEffect, useState } from 'react'
import {
    DayDTO,
    DaysOffDTO,
    deleteDaysOffRequest,
    updateDaysOffRequest,
    getDaysOffByIdRequest,
} from '../../../api/days-off'
import { isInPastOrIsBritishHolidayOrIsWeekend } from '../../../util/british-holidays'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { getWeekDayDateRangeArray, snakeCaseToTitleCase } from '../../../util/util'
import CloseIcon from '@mui/icons-material/Close'
import { getProductionSchedules } from '../../../api/production-schedule'
import _ from 'lodash'
import { UpdateDaysOffTable } from './UpdateDaysOffTable'
import DatePicker from '../../ui/calendar/DatePicker'

interface UpdateOrDeleteDaysOffModalProps {
    isOpen: boolean
    onClose: () => void
    daysOffId: number
}

const Header = styled(Box)(() => ({
    margin: '20px',
    display: 'flex',
    justifyContent: 'space-between',
}))

export const UpdateDaysOffModal = ({
    isOpen,
    onClose,
    daysOffId,
}: UpdateOrDeleteDaysOffModalProps) => {
    const [daysOffType, setDaysOffType] = useState<DaysOffType>()
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [days, setDays] = useState<DayDTO[]>([])
    const [isFullDaysOff, setIsFullDaysOff] = useState<boolean>(false)
    const [daysOff, setDaysOff] = useState<DaysOff>() //Current days off stored
    const [scheduleBeforeAllocation, setScheduleBeforeAllocation] = useState<Schedule[]>([])

    const fetchDaysOff = async () => {
        const response = await getDaysOffByIdRequest(daysOffId)
        if (response.successful) {
            if (!response.data?.user) {
                toastFailure('The user on the days off could not be found')
                onClose()
                return
            }
            setDaysOff(response.data)
            setDays(response.data.absentDays)
            setDaysOffType(response.data.type)
            setStartDate(
                response.data?.absentDays?.sort(
                    (a, b) => moment(a.date).unix() - moment(b.date).unix()
                )[0]?.date
            )
            setEndDate(
                response.data?.absentDays?.sort(
                    (a, b) => moment(b.date).unix() - moment(a.date).unix()
                )[0]?.date
            )
            setIsFullDaysOff(response.data.absentDays[0]?.type === AbsentDayType.FullDay)
            loadSchedules(response.data.user.role, response.data.user.warehouseId, response.data)
        }
    }

    useEffect(() => {
        fetchDaysOff()
    }, [])

    const loadSchedules = async (userRole: UserRole, warehouseId: number, res?: DaysOff) => {
        if (userRole !== UserRole.Assembler) return

        const response = await getProductionSchedules({
            from:
                startDate && !res
                    ? startDate
                    : _.min(res?.absentDays.map((absentDay) => absentDay.date)),
            to:
                endDate && !res
                    ? endDate
                    : _.max(res?.absentDays.map((absentDay) => absentDay.date)),
            warehouseId,
        })
        if (response.successful) {
            setScheduleBeforeAllocation(response.data)
        }
    }

    useEffect(() => {
        if (daysOff?.user?.role === UserRole.Assembler && (startDate || endDate)) {
            loadSchedules(daysOff?.user?.role, daysOff?.user?.warehouseId)
        }
    }, [startDate, endDate])

    const handleChangeDates = (dates: [moment.Moment | null, moment.Moment | null] | null) => {
        if (dates != null) {
            if (dates[0] != null) {
                setStartDate(dates[0].toDate())
            } else {
                setStartDate(null)
            }

            if (dates[1] != null) {
                setEndDate(dates[1].toDate())
            } else {
                setEndDate(null)
            }
        }
    }

    async function handleDeleteDaysOff() {
        if (!daysOff) {
            return
        }
        const response = await deleteDaysOffRequest(daysOff.id)
        if (response.successful) {
            toastSuccess('Days off successfully deleted.')
        } else {
            toastFailure('Days off could not be deleted')
        }
        onCloseModalSpecific()
    }

    async function handleUpdateDaysOff() {
        if (!daysOff || !daysOffType || !daysOff?.user) {
            return
        }

        const body: DaysOffDTO = {
            userId: daysOff.user.id,
            type: daysOffType,
            absentDays: days,
        }
        const response = await updateDaysOffRequest(body, daysOff.id)
        if (response.successful) {
            toastSuccess('Days off successfully updated.')
        } else {
            toastFailure('Days off could not be updated.')
        }
        onCloseModalSpecific()
    }

    useEffect(() => {
        if (startDate && endDate) {
            //create individual day dtos based on the range
            const dateRange = getWeekDayDateRangeArray(moment(startDate), moment(endDate)).filter(
                (d) => moment(d)
            )
            const absentDays: DayDTO[] = dateRange.map((day) => {
                const existingDay = days.find((d) => moment(d.date).isSame(day, 'date'))
                if (existingDay) {
                    return existingDay
                }
                return {
                    date: day,
                    type: AbsentDayType.FullDay,
                }
            })

            setDays(absentDays)
        }
    }, [startDate, endDate])

    const onCloseModalSpecific = () => {
        onClose()
        setIsFullDaysOff(false)
    }

    const disableSaveChanges = !startDate || !endDate || days.length === 0 || !daysOffType

    return (
        <Dialog open={isOpen} onClose={onCloseModalSpecific} sx={{ zIndex: '100' }} maxWidth="xl">
            <Box
                sx={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: '15px 15px 10px 23px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Box>
                    Update Days Off{` for ${daysOff?.user?.firstName} ${daysOff?.user?.lastName}`}
                </Box>
                <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent sx={{ width: '800px' }}>
                <Header>
                    <FormControl sx={{ minWidth: 160 }} size="small">
                        <Select
                            placeholder={'Type'}
                            size={'large'}
                            style={{ width: '100%' }}
                            onChange={(value) => setDaysOffType(value as DaysOffType)}
                            value={daysOffType}
                        >
                            {Object.values(DaysOffType).map((type) =>
                                type == DaysOffType.MaternityPaternityLeave ? (
                                    <Select.Option
                                        value={DaysOffType.MaternityPaternityLeave}
                                        key={DaysOffType.MaternityPaternityLeave}
                                        name="Maternity/Paternity Leave"
                                    >
                                        Maternity/Paternity Leave
                                    </Select.Option>
                                ) : (
                                    <Select.Option
                                        value={type}
                                        key={type}
                                        name={snakeCaseToTitleCase(type.toString())}
                                    >
                                        {snakeCaseToTitleCase(type.toString())}
                                    </Select.Option>
                                )
                            )}
                        </Select>
                    </FormControl>
                    <Box>
                        <DatePicker.RangePicker
                            disabledDate={isInPastOrIsBritishHolidayOrIsWeekend}
                            onCalendarChange={handleChangeDates}
                        />
                    </Box>
                </Header>

                {daysOff?.user?.role === UserRole.Assembler && (
                    <UpdateDaysOffTable
                        schedules={scheduleBeforeAllocation}
                        user={daysOff.user}
                        days={days}
                        setDays={setDays}
                    />
                )}

                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        {' '}
                        <Button
                            variant="outlined"
                            startIcon={<DeleteIcon />}
                            style={{ margin: '10px 0px 10px 30px', textTransform: 'none' }}
                            onClick={() => handleDeleteDaysOff()}
                        >
                            Delete days off
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            disabled={disableSaveChanges}
                            variant={'contained'}
                            color={'primary'}
                            style={{ margin: '10px 10px 10px 10px' }}
                            onClick={handleUpdateDaysOff}
                        >
                            Save changes
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
