import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Button,
    Checkbox,
    Dialog,
    InputAdornment,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material'
import Divider from '@mui/material/Divider'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { useEffect, useState } from 'react'
import { InputSection, TextInput } from '../../ui/InputFields'
import { useAppDispatch } from '../../../store/hooks'
import { createPallet } from '../../../store/warehouse-map-slice'
import { Project, Product } from '../../../models/models'
import {
    GetStockItemQueryParams,
    getStockItemsRequest,
    ProductFilter,
} from '../../../api/stock-item'
import { styled } from '@mui/styles'
import { getProjectsRequest } from '../../../api/projects/projects'
import { toastFailure } from '../../../util/toast'
import DeleteIcon from '@mui/icons-material/Delete'
import TextArea from 'antd/lib/input/TextArea'

interface AddPalletModalProps {
    isOpen: boolean
    onClose: () => void
    areaId: number
}

const Text = styled(Typography)(() => ({}))

export const AddPalletModal = ({ isOpen, onClose, areaId }: AddPalletModalProps) => {
    const [comment, setComment] = useState<string>('')
    const [projectManagerId, setProjectManagerId] = useState<number | undefined>()
    const [productSelectOpen, setProductSelectOpen] = useState<boolean>(false)
    const [productOptions, setProductOptions] = useState<Product[]>([])
    const [productCodeSearchText, setProductCodeSearchText] = useState<string>('')
    const [loadingProductOptions, setLoadingProductOptions] = useState<boolean>(false)
    const [projectOptions, setProjectOptions] = useState<Project[]>([])
    const [projectNameSearchText, setProjectNameSearchText] = useState<string>('')
    const [projectSelectOpen, setProjectSelectOpen] = useState<boolean>(false)
    const [loadingProjectOptions, setLoadingProjectOptions] = useState<boolean>(false)
    const [selectedProject, setSelectedProject] = useState<Project>()

    const [activeProductFilter, setActiveProductFilter] = useState<ProductFilter>(
        ProductFilter.None
    )
    const [productQuantities, setProductQuantities] = useState<{
        [productId: number]: {
            quantity: string
            //need for presentation
            code: string
        }
    }>({})

    const dispatch = useAppDispatch()

    const resetState = () => {
        setProjectManagerId(undefined)
        setProductSelectOpen(false)
        setProductOptions([])
        setProductCodeSearchText('')
        setLoadingProductOptions(false)
        setProjectSelectOpen(false)
        setProjectOptions([])
        setProjectNameSearchText('')
        setActiveProductFilter(ProductFilter.None)
        setProductQuantities({})
    }

    const closeModal = () => {
        resetState()
        onClose()
    }

    const addProductQuantity = (product: Product) => {
        const clone = { ...productQuantities }
        clone[product.id] = { quantity: '', code: product.code }
        setProductQuantities(clone)
        setProductSelectOpen(false)
    }

    const setProductQuantity = (productId: number, quantity: string) => {
        const clone = { ...productQuantities }
        clone[productId].quantity = quantity
        setProductQuantities(clone)
        setProductSelectOpen(false)
    }

    const removeProductQuantity = (productId: number) => {
        const clone = { ...productQuantities }
        delete clone[productId]
        setProductQuantities(clone)
        setProductSelectOpen(false)
    }

    useEffect(() => {
        setLoadingProjectOptions(true)
        getProjectsRequest({
            //mui tables pages are zero-indexed
            like: projectNameSearchText,
        }).then((response) => {
            if (response.successful) {
                setProjectOptions(response.data?.projects || [])
            } else {
                setProjectOptions([])
                toastFailure(response.message)
            }
        })
    }, [projectNameSearchText, loadingProjectOptions])

    useEffect(() => {
        setLoadingProductOptions(true)
        const params: GetStockItemQueryParams = {
            //hasSageStockWithWarehouseIds: [1004],
            limit: 100,
            likeCode: productCodeSearchText,
        }
        if (activeProductFilter) {
            params.productTypeFilter = activeProductFilter
        }
        getStockItemsRequest(params).then((response) => {
            if (response.successful) {
                setProductOptions(response?.data?.entities ?? [])
            } else {
                setProductOptions([])
                toastFailure(response.message)
            }
            setLoadingProjectOptions(false)
        })
    }, [productCodeSearchText, activeProductFilter, loadingProductOptions])

    function handleAddNewPallet() {
        if (Object.keys(productQuantities).length === 0) {
            return
        }
        dispatch(
            createPallet({
                projectManagerId,
                areaId,
                comment,
                projectId: selectedProject?.id,
                products: Object.entries(productQuantities).map((entry) => {
                    const [productId, product] = entry
                    return {
                        id: Number(productId),
                        amount: Number(product.quantity),
                    }
                }),
            })
        )
        closeModal()
    }

    const disableSubmit = () => {
        return (
            Object.keys(productQuantities).length === 0 ||
            Object.values(productQuantities).find((product) => product.quantity === '') !==
                undefined
        )
    }

    return (
        <Dialog open={isOpen} onClose={closeModal} fullWidth>
            <Box
                sx={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: '15px 15px 10px 23px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div> Add pallet </div>
                <IconButton aria-label="close" onClick={closeModal}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider />
            <Box style={{ margin: '30px' }}>
                <InputSection text="Product filters">
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderRadius: '10px',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginBottom: '10px',
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text>Hiu</Text>
                            <Checkbox
                                onChange={() => {
                                    if (activeProductFilter === ProductFilter.Hiu) {
                                        setActiveProductFilter(ProductFilter.None)
                                    } else {
                                        setActiveProductFilter(ProductFilter.Hiu)
                                    }
                                }}
                                //odd, but required for controlled MUI checkboxes
                                inputProps={{ 'aria-label': 'controlled' }}
                                checked={activeProductFilter === ProductFilter.Hiu}
                            ></Checkbox>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text>Flatstation</Text>
                            <Checkbox
                                //there is a bug, the property it filters on is not set in backend SAV-1000
                                disabled
                                onChange={() => {
                                    if (activeProductFilter === ProductFilter.Flatstation) {
                                        setActiveProductFilter(ProductFilter.None)
                                    } else {
                                        setActiveProductFilter(ProductFilter.Flatstation)
                                    }
                                }}
                                //odd, but required for controlled MUI checkboxes
                                inputProps={{ 'aria-label': 'controlled' }}
                                checked={activeProductFilter === ProductFilter.Flatstation}
                            ></Checkbox>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text>Other</Text>
                            <Checkbox
                                //there is a bug, the property it filters on is not set in backend SAV-1000
                                disabled
                                onChange={() => {
                                    if (activeProductFilter === ProductFilter.Other) {
                                        setActiveProductFilter(ProductFilter.None)
                                    } else {
                                        setActiveProductFilter(ProductFilter.Other)
                                    }
                                }}
                                //odd, but required for controlled MUI checkboxes
                                inputProps={{ 'aria-label': 'controlled' }}
                                checked={activeProductFilter === ProductFilter.Other}
                            ></Checkbox>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text>Stock</Text>
                            <Checkbox
                                onChange={() => {
                                    if (activeProductFilter === ProductFilter.Stock) {
                                        setActiveProductFilter(ProductFilter.None)
                                    } else {
                                        setActiveProductFilter(ProductFilter.Stock)
                                    }
                                }}
                                //odd, but required for controlled MUI checkboxes
                                inputProps={{ 'aria-label': 'controlled' }}
                                checked={activeProductFilter === ProductFilter.Stock}
                            ></Checkbox>
                        </Box>
                    </Box>
                </InputSection>
                <InputSection text="Add product">
                    <Autocomplete
                        id="tags-outlined"
                        sx={{ paddingTop: '12px' }}
                        fullWidth
                        disablePortal
                        open={productSelectOpen}
                        onOpen={() => {
                            setProductSelectOpen(true)
                        }}
                        onClose={() => {
                            setProductSelectOpen(false)
                        }}
                        value={null}
                        onChange={(_, product) => {
                            if (!product) {
                                return
                            }
                            setProductSelectOpen(false)
                            addProductQuantity(product)
                        }}
                        inputValue={productCodeSearchText}
                        onInputChange={(_, newInputValue) => {
                            setProductCodeSearchText(newInputValue)
                        }}
                        getOptionLabel={(option) => option.code || ''}
                        options={productOptions}
                        loading={loadingProductOptions}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <TextField {...params} label="Products" />
                        )}
                    />
                </InputSection>
                {Object.keys(productQuantities).length > 0 && (
                    <InputSection text="Quantities">
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Code</TableCell>
                                    <TableCell>Quantity</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries(productQuantities).map((productQuantity) => {
                                    const [id, product] = productQuantity
                                    return (
                                        <TableRow key={product.code}>
                                            <TableCell>{product.code}</TableCell>
                                            <TableCell>
                                                <TextInput
                                                    type="number"
                                                    value={product.quantity}
                                                    onChange={(value) =>
                                                        setProductQuantity(Number(id), value)
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() =>
                                                        removeProductQuantity(Number(id))
                                                    }
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </InputSection>
                )}
                {!selectedProject && (
                    <InputSection text="Set Project">
                        <Autocomplete
                            id="tags-outlined"
                            sx={{
                                width: '100%',
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }}
                            fullWidth
                            disablePortal
                            open={projectSelectOpen}
                            onOpen={() => {
                                setProjectSelectOpen(true)
                            }}
                            onClose={() => {
                                setProjectSelectOpen(false)
                            }}
                            value={null}
                            onChange={(_, project) => {
                                if (!project) {
                                    return
                                }
                                setSelectedProject(project)
                                setProjectManagerId(undefined)
                            }}
                            inputValue={projectNameSearchText}
                            onInputChange={(_, newInputValue) => {
                                setProjectNameSearchText(newInputValue)
                            }}
                            getOptionLabel={(option) => option.name || ''}
                            options={projectOptions}
                            loading={loadingProductOptions}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                                <TextField {...params} />
                            )}
                        />
                    </InputSection>
                )}
                {selectedProject && (
                    <>
                        <InputSection text="Selected Project">
                            <TextField
                                key="Project"
                                id="outlined-read-only-input"
                                //removing the label
                                sx={{
                                    width: '100%',
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                value={selectedProject.name}
                                disabled={true}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                color="primary"
                                                onClick={() => {
                                                    setSelectedProject(undefined)
                                                    setProjectManagerId(undefined)
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </InputSection>
                        <InputSection text={'Project manager'}>
                            <Select
                                //todo Mode to Theme
                                sx={{
                                    marginTop: '5px',
                                    '& legend': {
                                        display: 'none',
                                    },
                                }}
                                color="primary"
                                variant="outlined"
                                fullWidth
                                value={projectManagerId}
                                onChange={(e) => setProjectManagerId(Number(e.target.value))}
                            >
                                {selectedProject?.projectManagers.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {user.firstName} {user.lastName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </InputSection>
                    </>
                )}
                <InputSection text={'Comment'}>
                    <TextArea
                        rows={4}
                        placeholder="Pallet comment"
                        maxLength={255}
                        onChange={(e) => setComment(e.target.value)}
                        value={comment}
                    />
                </InputSection>
            </Box>

            <Divider />
            <Box sx={{ textAlign: 'right', padding: '15px 23px 15px 0px' }}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    disabled={disableSubmit()}
                    onClick={handleAddNewPallet}
                    style={{
                        paddingRight: '16px',
                    }}
                >
                    <AddIcon style={{ marginRight: '4px' }} />
                    Add
                </Button>
            </Box>
        </Dialog>
    )
}
