import AddIcon from '@mui/icons-material/Add'
import Divider from '@mui/material/Divider'
import { ProjectBuiltItem } from '../../../../../models/models'
import { useState } from 'react'
import { Button, Input, Modal } from 'antd'
import { styled } from '@mui/system'
import { Label } from '../../../../ui/Label'
import { PlusOutlined } from '@ant-design/icons'
import { FieldContainer } from '../../../../ui/FIeldContainer'

const InputContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    margin: '20px',
})

interface CommentsProps {
    projectBuiltItem: ProjectBuiltItem
    closeModal: () => void
    addProjectBuiltItem: (
        projectBuiltItem: ProjectBuiltItem,
        amount: number,
        unitDiscount: number,
        unitPrice: number
    ) => void
}

export default function AddBuiltItemModal(props: CommentsProps) {
    const { closeModal, projectBuiltItem, addProjectBuiltItem } = props
    const [amount, setAmount] = useState<string>('')
    const [unitPrice, setUnitPrice] = useState<string>('')
    const [unitDiscount, setUnitDiscount] = useState<string>('')

    const disableSubmit = () => {
        return !amount || Number(amount) < 0.1
    }
    return (
        <Modal open={true} footer={null} onCancel={() => closeModal()} width={400}>
            <div
                style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: '15px 15px 10px 23px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div> Add built item </div>
            </div>
            <Divider />
            <InputContainer>
                <FieldContainer>
                    <Label>Amount</Label>
                    <Input
                        placeholder="Amount of units"
                        autoComplete="off"
                        type="number"
                        value={amount}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (
                                projectBuiltItem.used !== undefined &&
                                Number(e.target.value) >
                                    projectBuiltItem.amount - projectBuiltItem.used
                            ) {
                                setAmount(
                                    (projectBuiltItem.amount - projectBuiltItem.used).toString()
                                )
                            } else {
                                setAmount(e.target.value)
                            }
                        }}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Label>Price</Label>
                    <Input
                        placeholder="Price per unit"
                        addonAfter="£"
                        type="number"
                        value={unitPrice}
                        onChange={(e) => {
                            setUnitPrice(e.target.value)
                        }}
                    />
                </FieldContainer>
            </InputContainer>
            <div style={{ textAlign: 'right', padding: '15px 23px 15px 0px' }}>
                <Button
                    type="primary"
                    disabled={disableSubmit()}
                    onClick={() => {
                        addProjectBuiltItem(
                            projectBuiltItem,
                            Number(amount),
                            Number(unitDiscount) || 0,
                            Number(unitPrice)
                        )
                        closeModal()
                    }}
                    style={{
                        paddingRight: '16px',
                    }}
                    icon={<PlusOutlined style={{ marginRight: '4px' }} />}
                >
                    Add
                </Button>
            </div>
        </Modal>
    )
}
