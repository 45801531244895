import {
    Autocomplete,
    AutocompleteRenderInputParams,
    CircularProgress,
    Dialog,
    Divider,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Button,
    TableRow,
    TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getPalletRequest, ProductDTO } from '../../../api/warehouse-map'
import { Pallet, Project, Product, User } from '../../../models/models'
import { toastFailure } from '../../../util/toast'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import { getProjectsRequest } from '../../../api/projects/projects'
import { GetStockItemQueryParams, getStockItemsRequest } from '../../../api/stock-item'
import TextArea from 'antd/lib/input/TextArea'
import { InputSection as InputSectionComponent, TextInput } from '../../ui/InputFields'
import { updatePallet } from '../../../store/warehouse-map-slice'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
    isOpen: boolean
    onClose: () => void
    palletId: number
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

const InputSection = (props: { children: React.ReactNode; text: string }) => {
    return (
        <InputSectionComponent width={120} text={props.text}>
            {props.children}
        </InputSectionComponent>
    )
}

interface UpdateProductsQuantities {
    [productId: number]: {
        quantity: string
        code: string
    }
}

export const UpdatePalletModal = ({ isOpen, onClose, palletId }: Props) => {
    const [pallet, setPallet] = useState<Pallet>()
    const [productSelectOpen, setProductSelectOpen] = useState<boolean>(false)
    const [projectSelectOpen, setProjectSelectOpen] = useState<boolean>(false)
    const [productOptions, setProductOptions] = useState<Product[]>([])
    const [projectOptions, setProjectOptions] = useState<Project[]>([])
    const [productCodeSearchText, setProductCodeSearchText] = useState<string>('')
    const [projectNameSearchText, setProjectNameSearchText] = useState<string>('')
    const [loadingProductOptions, setLoadingProductOptions] = useState<boolean>(false)
    const [loadingProjectOptions, setLoadingProjectOptions] = useState<boolean>(false)
    const [selectedProject, setSelectedProject] = useState<Project>()
    const [projectManager, setProjectManager] = useState<User>()
    const [comment, setComment] = useState<string>('')
    const [updateProductQuantitiesForm, setUpdateProductQuantitiesForm] =
        useState<UpdateProductsQuantities>({})
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const dispatch = useDispatch()

    const addProductQuantity = (product: Product) => {
        const clone = { ...updateProductQuantitiesForm }
        clone[product.id] = { quantity: '', code: product.code }
        setUpdateProductQuantitiesForm(clone)
        setProductSelectOpen(false)
    }

    const setProductQuantity = (productId: number, quantity: string) => {
        const clone = { ...updateProductQuantitiesForm }
        clone[productId].quantity = quantity
        setUpdateProductQuantitiesForm(clone)
        setProductSelectOpen(false)
    }

    const removeProductQuantity = (productId: number) => {
        const clone = { ...updateProductQuantitiesForm }
        delete clone[productId]
        setUpdateProductQuantitiesForm(clone)
        setProductSelectOpen(false)
    }

    const isUpdateButtonEnabled = () => {
        let hasUpdate = false
        for (const productQuantity of Object.entries(updateProductQuantitiesForm)) {
            const [id, product] = productQuantity
            const { quantity } = product
            //if added item, needs specified amount
            if (!getPalletProductById(Number(id)) && Number(quantity) < 1) {
                return false
            }
            if (Number(quantity) > 0) {
                hasUpdate = true
            }
            if (selectedProject?.id !== pallet?.projectId) {
                return true
            }
            if (projectManager?.id !== pallet?.projectManagerId) {
                return true
            }
            if (comment !== (pallet?.comment ?? '')) {
                return true
            }
        }
        return hasUpdate
    }

    const getPalletProductById = (id: number) => {
        if (!pallet) {
            return
        }
        const palletProduct = pallet.palletProducts.find((pp) => pp.productId === Number(id))
        if (!palletProduct) {
            return
        }
        return { amount: palletProduct.amount, ...palletProduct.product }
    }

    const handleUpdate = () => {
        if (!pallet) {
            return
        }
        const productsBefore = pallet.palletProducts.map((pp) => ({
            amount: pp.amount,
            id: pp.productId,
        }))

        const productsToUpdate = Object.entries(updateProductQuantitiesForm)
            //get products where quantity has been provided in form
            .filter((entry): ProductDTO | undefined => {
                const [_, product] = entry
                return product.quantity
            })
            .map((entry) => {
                const [id, product] = entry
                return {
                    amount: Number(product.quantity),
                    id: Number(id),
                }
            })

        const productsToRemove = Object.entries(productsBefore)
            //get products that are in "before" but not in current form
            .filter((entry): boolean => {
                const [_, product] = entry
                return !Object.keys(updateProductQuantitiesForm).includes(product.id.toString())
            })
            .map((entry) => entry[1].id)

        dispatch(
            updatePallet({
                palletId: pallet.id,
                productsBefore,
                productsToUpdate,
                productsToRemove,
                comment,
                projectManagerId: projectManager?.id,
                projectId: selectedProject?.id,
            })
        )
        onClose()
    }

    useEffect(() => {
        getPalletRequest(palletId).then((response) => {
            if (response.successful) {
                if (!response.data) {
                    toastFailure('Could not find the pallet')
                    setModalState(ModalState.NotFound)
                    onClose()
                }
                setModalState(ModalState.Success)
                setUpdateProductQuantitiesForm(
                    response.data.palletProducts.reduce<UpdateProductsQuantities>((acc, cur) => {
                        acc[cur.productId] = {
                            quantity: '',
                            code: cur.product.code,
                        }
                        return acc
                    }, {})
                )
                setPallet(response.data)
            } else {
                if (response.status === 404) {
                    toastFailure('Could not find the pallet')
                    setModalState(ModalState.NotFound)
                    onClose()
                } else {
                    toastFailure('Something went wrong retrieving the pallet')
                    setModalState(ModalState.Error)
                    onClose()
                }
            }
        })
    }, [])

    useEffect(() => {
        setLoadingProjectOptions(true)
        getProjectsRequest({
            //mui tables pages are zero-indexed
            like: projectNameSearchText,
        }).then((response) => {
            if (response.successful) {
                setProjectOptions(response.data?.projects || [])
            } else {
                setProjectOptions([])
                toastFailure(response.message)
            }
        })
    }, [projectNameSearchText /*loadingProjectOptions*/])

    useEffect(() => {
        setLoadingProjectOptions(true)
        const params: GetStockItemQueryParams = {
            //hasSageStockWithWarehouseIds: [1004],
            limit: 100,
            likeCode: productCodeSearchText,
        }
        getStockItemsRequest(params).then((response) => {
            if (response.successful) {
                setProductOptions(response?.data?.entities ?? [])
            } else {
                setProductOptions([])
                toastFailure(response.message)
            }
            setLoadingProjectOptions(false)
        })
    }, [productCodeSearchText /*loadingProjectOptions*/])

    useEffect(() => {
        setLoadingProductOptions(true)
        const params: GetStockItemQueryParams = {
            hasSageStockWithWarehouseIds: [1004],
            limit: 100,
            likeCode: productCodeSearchText,
        }
        getStockItemsRequest(params).then((response) => {
            if (response.successful) {
                setProductOptions(response.data.entities || [])
            } else {
                setProductOptions([])
            }
            setLoadingProductOptions(false)
        })
    }, [productCodeSearchText])
    return (
        <Dialog open={isOpen} onClose={onClose} style={{ zIndex: 100 }} fullWidth maxWidth={'md'}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '15px',
                }}
            >
                <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            {modalState === ModalState.Loading && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        margin: '20px',
                        width: '100%',
                        height: '500px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                </Box>
            )}
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.NotFound && (
                <Box>Could not find the pallet with id {palletId}</Box>
            )}
            {modalState === ModalState.Success && (
                <Box>
                    <Box style={{ margin: '30px' }}>
                        {!selectedProject && (
                            <InputSection text="Set Project">
                                <Autocomplete
                                    id="tags-outlined"
                                    sx={{
                                        width: '100%',
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                    fullWidth
                                    disablePortal
                                    open={projectSelectOpen}
                                    onOpen={() => {
                                        setProjectSelectOpen(true)
                                    }}
                                    onClose={() => {
                                        setProjectSelectOpen(false)
                                    }}
                                    value={selectedProject}
                                    onChange={(_, project) => {
                                        if (!project) {
                                            return
                                        }
                                        setSelectedProject(project)
                                        setProjectManager(undefined)
                                    }}
                                    inputValue={projectNameSearchText}
                                    onInputChange={(_, newInputValue) => {
                                        setProjectNameSearchText(newInputValue)
                                    }}
                                    getOptionLabel={(option) => option.name || ''}
                                    options={projectOptions}
                                    loading={loadingProductOptions}
                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </InputSection>
                        )}
                        {selectedProject && (
                            <>
                                <InputSection text="Selected Project">
                                    <TextField
                                        key="Project"
                                        id="outlined-read-only-input"
                                        //removing the label
                                        sx={{
                                            width: '100%',
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        value={selectedProject.name}
                                        disabled={true}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        color="primary"
                                                        onClick={() => {
                                                            setSelectedProject(undefined)
                                                            setProjectManager(undefined)
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </InputSection>
                                <InputSection text={'Project manager'}>
                                    <Select
                                        //todo Mode to Theme
                                        sx={{
                                            marginTop: '5px',
                                            '& legend': {
                                                display: 'none',
                                            },
                                        }}
                                        color="primary"
                                        variant="outlined"
                                        fullWidth
                                        value={projectManager?.id}
                                        onChange={(e) =>
                                            setProjectManager(
                                                selectedProject?.projectManagers?.find(
                                                    (pm) => pm.id === Number(e.target.value)
                                                )
                                            )
                                        }
                                    >
                                        {selectedProject?.projectManagers?.map((user) => (
                                            <MenuItem key={user.id} value={user.id}>
                                                {user.firstName} {user.lastName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </InputSection>
                            </>
                        )}
                        <InputSection text={'Comment'}>
                            <TextArea
                                rows={4}
                                placeholder="Pallet comment"
                                maxLength={255}
                                onChange={(e) => setComment(e.target.value)}
                                value={comment}
                            />
                        </InputSection>
                        <InputSection text="Add product">
                            <Autocomplete
                                id="tags-outlined"
                                sx={{ paddingTop: '12px' }}
                                fullWidth
                                disablePortal
                                open={productSelectOpen}
                                onOpen={() => {
                                    setProductSelectOpen(true)
                                }}
                                onClose={() => {
                                    setProductSelectOpen(false)
                                }}
                                value={null}
                                onChange={(_, product) => {
                                    if (!product) {
                                        return
                                    }
                                    setProductSelectOpen(false)
                                    addProductQuantity(product)
                                }}
                                inputValue={productCodeSearchText}
                                onInputChange={(_, newInputValue) => {
                                    setProductCodeSearchText(newInputValue)
                                }}
                                getOptionLabel={(option) => option.code || ''}
                                options={productOptions}
                                loading={loadingProductOptions}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <TextField {...params} label="Products" />
                                )}
                            />
                        </InputSection>
                        <InputSection text="Quantities">
                            <Table size="medium">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Quantity</TableCell>
                                        <TableCell>New quantity</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(updateProductQuantitiesForm).map(
                                        (productQuantity) => {
                                            const [id, product] = productQuantity
                                            const palletProduct = getPalletProductById(Number(id))
                                            return (
                                                <TableRow key={product?.code}>
                                                    <TableCell>{product?.code}</TableCell>
                                                    <TableCell>
                                                        {palletProduct?.amount || 'None'}
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextInput
                                                            type="number"
                                                            value={product.amount}
                                                            onChange={(value) =>
                                                                setProductQuantity(
                                                                    Number(id),
                                                                    value
                                                                )
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            onClick={() =>
                                                                removeProductQuantity(Number(id))
                                                            }
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    )}
                                </TableBody>
                            </Table>
                        </InputSection>
                    </Box>
                    <Divider />
                    <Box sx={{ textAlign: 'right', padding: '15px 23px 15px 0px' }}>
                        <Button
                            onClick={handleUpdate}
                            variant="contained"
                            color="primary"
                            style={{
                                textTransform: 'none',
                                margin: '0px 23px',
                            }}
                            disabled={!isUpdateButtonEnabled()}
                        >
                            <DoneIcon style={{ marginRight: '4px' }} />
                            Update
                        </Button>
                    </Box>
                </Box>
            )}
        </Dialog>
    )
}
