import {
    acceptStockItemAuthorizationRequest,
    denyAuthorizationStockItemRequest,
} from '../../../api/product-authorization'
import {
    getManyUnauthorizedProductsRequest,
    ManyUnauthorizedProductRelation,
    updateUnauthorizedProductNoteRequest,
} from '../../../api/unauthorized-product'
import { AuthorizationState, ProductType, UnauthorizedProduct } from '../../../models/models'
import { toastFailure, toastSuccess } from '../../../util/toast'
import '../antd-tables.css'
import UnauthorizedProductsTable, {
    UnauthorizedProductsTableColumns,
} from '../../tables/UnauthorizedProductsTable'
import { Button } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { setUpdateTableToken } from '../../../store/product-authorization-slice'
import { TableTextareaInput } from '../../worksheets/assembly/active-assembly/TableInputs'
import { ConfimrationModal } from '../../modals/genral-common-modal/confirmationModal'
import { useState } from 'react'
import { ChildProcessWithoutNullStreams } from 'child_process'

export default function ProductAuthorizationProductPage() {
    const { updateTableToken } = useAppSelector((state) => state.productAuthorization)
    const dispatch = useAppDispatch()

    const [checkConfirm, SetCheckConfirm] = useState<boolean>(false)
    const [prodId, SetProdId] = useState<number | undefined>()
    const [confirmAction, SetConfirmAction] = useState<'accept' | 'deny' | 'none'>()

    const deny = async (productId: number) => {
        const response = await denyAuthorizationStockItemRequest(productId)
        confirmReset()
        if (response.successful) {
            toastSuccess('Successfully removed the stock item')
            dispatch(setUpdateTableToken(`deny-${productId}`))
        } else {
            toastFailure('Something went wrong trying to remove the stock item')
        }
    }

    const accept = async (productId: number) => {
        const response = await acceptStockItemAuthorizationRequest(productId)
        confirmReset()
        if (response.successful) {
            toastSuccess('Successfully authorized the stock item')
            dispatch(setUpdateTableToken(`accept-${productId}`))
        } else {
            toastFailure(response.message)
        }
    }

    const confirm = (prodId: number, confirmAction: 'accept' | 'deny' | 'none') => {
        SetProdId(prodId)
        SetConfirmAction(confirmAction)
        SetCheckConfirm(true)
    }

    const performConfirmAction = () => {
        if (confirmAction === 'accept') {
            prodId ? accept(prodId) : null
        } else if (confirmAction === 'deny') {
            prodId ? deny(prodId) : null
        }
    }

    const confirmReset = () => {
        SetProdId(undefined)
        SetConfirmAction('none')
        SetCheckConfirm(false)
    }

    return (
        <div>
            <ConfimrationModal
                isVisible={checkConfirm}
                action={performConfirmAction}
                onClose={confirmReset}
            ></ConfimrationModal>
            <UnauthorizedProductsTable
                dependencies={{ updateTableToken }}
                columns={[
                    UnauthorizedProductsTableColumns.Code,
                    UnauthorizedProductsTableColumns.Description,
                    UnauthorizedProductsTableColumns.Name,
                    UnauthorizedProductsTableColumns.ProductGroup,
                    UnauthorizedProductsTableColumns.RequestedBy,
                    UnauthorizedProductsTableColumns.RequestedFor,
                    UnauthorizedProductsTableColumns.RequestedAt,
                    {
                        title: 'Note',
                        render: (unauthorizedProduct: UnauthorizedProduct) => {
                            return (
                                <TableTextareaInput
                                    active
                                    placeholder="No note"
                                    value={unauthorizedProduct.note}
                                    maxLength={60}
                                    maxLengthError={`The note must not be longer than 60 characters long.`}
                                    noNewlines
                                    onSave={async (value, afterSave) => {
                                        if (value !== null) {
                                            await updateUnauthorizedProductNoteRequest(
                                                unauthorizedProduct.id,
                                                value
                                            )
                                            dispatch(
                                                setUpdateTableToken(
                                                    `deny-${unauthorizedProduct.id}${unauthorizedProduct.note}`
                                                )
                                            )
                                        }
                                        afterSave()
                                    }}
                                />
                            )
                        },
                    },
                ]}
                actionComponents={[
                    (product: UnauthorizedProduct) => {
                        return (
                            <div>
                                <Button onClick={() => confirm(product.id, 'accept')}>
                                    Authorize
                                </Button>
                            </div>
                        )
                    },
                    (product: UnauthorizedProduct) => {
                        return (
                            <div>
                                <Button onClick={() => confirm(product.id, 'deny')}>Deny</Button>
                            </div>
                        )
                    },
                ]}
                request={(pagination) =>
                    getManyUnauthorizedProductsRequest({
                        withCount: true,
                        relations: [
                            ManyUnauthorizedProductRelation.Bom,
                            ManyUnauthorizedProductRelation.RequestedByUser,
                            ManyUnauthorizedProductRelation.RequestedForProject,
                        ],
                        productType: ProductType.StockItem,
                        authorizationState: AuthorizationState.Unauthorized,
                        skip: pagination?.skip,
                        limit: pagination?.limit,
                    })
                }
            />
        </div>
    )
}
