import { Response } from '../util/with-response-formatter-interceptor'
import { ProjectStockItem } from '../../models/models'
import backendConnection from '../util/backend-connection'

type GetProjectStockItemsQueryParams = {
    stockItem?: boolean
    used?: boolean
    skip?: number
    withCount?: boolean
    code?: string
    name?: string
    withWarehouseHoldings?: boolean
    group?: string
    limit?: number
}

export const getProjectStockItems = (
    projectId: number,
    queryParams: GetProjectStockItemsQueryParams
): Promise<Response<{ count?: number; projectStockItems: ProjectStockItem[] }>> =>
    backendConnection.request({
        url: `/projects/stock-items/${projectId}`,
        method: 'GET',
        params: queryParams,
    })

export const getProjectStockItemByIdRequest = (
    projectStockItemId: number
): Promise<Response<ProjectStockItem>> =>
    backendConnection.request({
        url: `/projects/stock-items/project-stock-item/${projectStockItemId}`,
        method: 'GET',
    })

export interface UpdateProjectStockItemDTO {
    projectId: number
    projectStockItemId: number
    amount: number
}

export const updateProjectStockItemRequest = (
    dto: UpdateProjectStockItemDTO
): Promise<Response<ProjectStockItem>> =>
    backendConnection.request({
        url: `/projects/stock-items`,
        method: 'PATCH',
        data: dto,
    })

export const deleteProjectStockItemRequest = (
    projectStockItemId: number
): Promise<Response<void>> =>
    backendConnection.request({
        url: `/projects/stock-items/${projectStockItemId}`,
        method: 'DELETE',
    })

interface CreateProjectStockItemsDTO {
    stockItems: { id: number; amount: number }[]
}

export const createProjectStockItems = (
    projectId: number,
    body: CreateProjectStockItemsDTO
): Promise<Response<ProjectStockItem[]>> =>
    backendConnection.request({
        url: `/projects/stock-items/${projectId}`,
        method: 'POST',
        data: body,
    })

export const getProjectStockItemsCount = (projectId: number): Promise<Response<number>> =>
    backendConnection.request({
        url: `/projects/stock-items/count/${projectId}`,
        method: 'GET',
    })
