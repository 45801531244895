import { styled } from '@mui/material'
import { Task, TaskDepartment, WarehouseId } from '../../../models/models'
import { useEffect, useState } from 'react'
import { completeTasksRequest, getTasksRequest, TaskRelation } from '../../../api/tasks'
import { toastFailure, toastSuccess } from '../../../util/toast'
import moment from 'moment'
import {
    CheckCircleOutlined,
    LinkOutlined,
    OrderedListOutlined,
    PlusOutlined,
    SolutionOutlined,
} from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { useAppSelector } from '../../../store/hooks'
import CreateTaskModal from '../../modals/create-task-modal/CreateTaskModal'

const { confirm } = Modal

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Container = styled('div')`
    border: 1px solid #ededed;
    margin-top: 15px;
    margin-bottom: 15px;
    background: #fff;
    padding: 15px;
`

const ListContainer = styled('div')`
    display: flex;
    gap: 15px;
    overflow-x: auto;
    flex-grow: 1;
    margin-top: 15px;
`

interface Props {
    department: TaskDepartment
    warehouseId: WarehouseId
}

const TaskItemContainer = styled('div')`
    background: #ededed;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    height: 310px;
    min-width: 250px;
    max-width: 250px;
    flex-direction: column;
    display: flex;
    gap: 10px;
`

const TaskItemTitle = styled('p')`
    font-size: 14px;
    font-weight: bold;
    margin: 0;
`

const TaskItemAssignee = styled('p')`
    font-size: 14px;
    font-weight: bold;
    margin: 0;
`

const TaskItemMinutes = styled('p')`
    font-size: 14px;
    font-weight: bold;
    margin: 0;
`

const TaskItemDescription = styled('pre')`
    flex-grow: 1;
    overflow-y: auto;
    font-family: inherit;
    margin: 0;
    white-space: pre-wrap;
`

const TaskItemLink = styled('p')`
    font-size: 14px;
    font-weight: bold;
    margin: 0;
`

const Footer = styled('div')`
    display: flex;
    align-items: center;
    gap: 10px;
`

const DateText = styled('p')`
    font-weight: bold;
    margin-right: auto;
    margin-bottom: 0;
`

const CompletedText = styled('p')`
    margin: 0;
    font-weight: bold;
    color: #40bd40;
`

const IconContainer = styled('div')`
    background: #fff;
    border-radius: 8px;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

interface TaskItemProps {
    task: Task
    onCompleted?: (task: Task) => void
}

export const TaskItem = ({ task, onCompleted }: TaskItemProps) => {
    return (
        <TaskItemContainer>
            <TaskItemTitle>{task?.type?.title}</TaskItemTitle>
            <TaskItemAssignee>
                {`Assignee: ${
                    task?.assignedUser
                        ? `${task.assignedUser.firstName} ${task.assignedUser.lastName}`
                        : 'None'
                }`}
            </TaskItemAssignee>
            <TaskItemAssignee>
                {`Project: ${task?.project ? `${task.project.name}` : 'None'}`}
            </TaskItemAssignee>
            <TaskItemMinutes>{`Minutes: ${task.minutes}`}</TaskItemMinutes>
            <TaskItemDescription>{task.description}</TaskItemDescription>
            <Footer>
                <DateText
                    style={{
                        color:
                            moment(task.dateToBeCompletedBy) < moment().startOf('day')
                                ? 'red'
                                : 'inherit',
                    }}
                >
                    {moment(task.dateToBeCompletedBy).format('LL')}
                </DateText>
                {task.link && (
                    <IconContainer onClick={() => window.open(task.link, '_blank')}>
                        <SolutionOutlined size={34} color="blue" />
                    </IconContainer>
                )}

                {!task.completedAt && onCompleted && (
                    <IconContainer
                        style={{ background: '#00ff0033' }}
                        onClick={() => {
                            confirm({
                                title: 'Complete task?',
                                icon: (
                                    <CheckCircleOutlined size={24} style={{ color: '#00be94' }} />
                                ),
                                content: 'Are you sure you want to complete the chosen task?',
                                onOk() {
                                    completeTasksRequest(task.id).then((response) => {
                                        if (!response.successful) {
                                            toastFailure(
                                                'An error occurred while completing the task'
                                            )
                                            return
                                        }

                                        toastSuccess('The task was successfully marked completed.')
                                        onCompleted(response.data)
                                    })
                                },
                            })
                        }}
                    >
                        <CheckCircleOutlined size={42} style={{ color: 'green' }} />
                    </IconContainer>
                )}

                {task.completedAt && <CompletedText>Completed</CompletedText>}
            </Footer>
        </TaskItemContainer>
    )
}

export default function TaskBar(props: Props) {
    const { user } = useAppSelector((state) => state.authentication)
    const [tasks, setTasks] = useState<Task[]>([])
    const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false)

    const sortedTasks = [...tasks].sort((taskA, taskB) => {
        const taskATime = moment(taskA.dateToBeCompletedBy).unix()
        const taskBTime = moment(taskB.dateToBeCompletedBy).unix()
        if (taskATime === taskBTime) {
            return taskA.id - taskB.id
        }

        return taskATime - taskBTime
    })
    const getTasks = async () => {
        if (!user) {
            return
        }

        const response = await getTasksRequest({
            isCompleted: false,
            departments: [props.department, TaskDepartment.ANY],
            relations: [
                TaskRelation.AssignedTo,
                TaskRelation.CreatedBy,
                TaskRelation.Type,
                TaskRelation.Project,
            ],
        })

        if (!response.successful) {
            toastFailure('Error while retrieving tasks')
            return
        }

        setTasks(
            response.data.entities.filter((t) => t.assignedUserId === user.id || !t.assignedUserId)
        )
    }

    useEffect(() => {
        getTasks()
    }, [])

    useEffect(() => {
        const timeout = setTimeout(() => {
            getTasks()
        }, 30_000)

        return () => clearTimeout(timeout)
    }, [])

    return (
        <Container>
            {isCreateModalVisible && (
                <CreateTaskModal
                    warehouseId={props.warehouseId}
                    department={props.department}
                    closeModal={(task) => {
                        if (task) {
                            getTasks()
                        }

                        setIsCreateModalVisible(false)
                    }}
                />
            )}

            <Header>
                <OrderedListOutlined style={{ fontSize: '20px' }} />
                <Title>My tasks</Title>
                <PlusOutlined
                    style={{ marginLeft: 'auto', cursor: 'pointer', fontSize: '20px' }}
                    onClick={() => setIsCreateModalVisible(true)}
                />
            </Header>
            {sortedTasks.length > 0 && (
                <ListContainer>
                    {sortedTasks.map((task) => (
                        <TaskItem
                            key={task.id}
                            task={task}
                            onCompleted={(task) => {
                                setTasks([...tasks.filter((t) => t.id !== task.id), task])
                            }}
                        />
                    ))}
                </ListContainer>
            )}
            {sortedTasks.length < 1 && (
                <p style={{ marginTop: '5px', marginBottom: '0px', color: '#555' }}>
                    There are currently no tasks.
                </p>
            )}
        </Container>
    )
}
