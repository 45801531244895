import { User } from '../models/models'
import backendConnection from './util/backend-connection'
import { Response } from './util/with-response-formatter-interceptor'
export enum IssueType {
    'Bug' = 'Bug',
    'Task' = 'Task',
}

export interface CreateBugReportDTO {
    summary: string
    description: string
    issueType: IssueType
}

export interface BugReportDTO extends CreateBugReportDTO {
    id: number
    timeCreatedAt: string
    jiraTicketUrl: string
    completedAt: string | null
    owner: User
}

export const createBugReport = (body: CreateBugReportDTO): Promise<Response<{ id: number }>> => {
    return backendConnection.request({
        method: 'POST',
        url: '/bug-reporting',
        data: body,
    })
}

export const getUsersBugReport = () => {
    return backendConnection.request({
        method: 'GET',
        url: '/bug-reporting',
    })
}

export const getAllBugReports = () => {
    return backendConnection.request({
        method: 'GET',
        url: '/bug-reporting/all',
    })
}

export const updateBugReport = (
    body: CreateBugReportDTO,
    bugReportId: number
): Promise<Response<{ id: number }>> => {
    return backendConnection.request({
        method: 'PUT',
        url: `/bug-reporting/${bugReportId}`,
        data: body,
    })
}

export const attachFileToBugReport = (formData: FormData, bugReportId: number | null) => {
    return backendConnection.request({
        data: formData,
        method: 'PUT',
        url: `/bug-reporting/attach-image/${bugReportId}`,
    })
}
