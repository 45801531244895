import { TextField } from '@mui/material'
import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { OrderConfirmation, PurchaseOrder } from '../../../models/models'
import moment from 'moment'
import {
    getOrderConfirmationStatus,
    OrderConfirmationStatus,
    orderconfirmationStatusToString,
} from '../../../util/util'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

const GeneralDetailsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    margin: '20px',
})

interface Props {
    orderConfirmation: OrderConfirmation
}
export default function DetailsTab({ orderConfirmation }: Props) {
    return (
        <Container>
            <GeneralDetailsContainer>
                <TextField
                    key="Author"
                    id="outlined-read-only-input"
                    label="Creator"
                    defaultValue={`${orderConfirmation?.author?.firstName} ${orderConfirmation?.author?.lastName}`}
                    InputProps={{
                        readOnly: true,
                    }}
                    disabled={true}
                />

                <TextField
                    key={orderConfirmation.code}
                    id="outlined-read-only-input"
                    label="Code"
                    defaultValue={orderConfirmation.code}
                    InputProps={{
                        readOnly: true,
                    }}
                    disabled={true}
                />
                <TextField
                    key="Booked in"
                    id="outlined-read-only-input"
                    label="Status"
                    defaultValue={orderconfirmationStatusToString(
                        getOrderConfirmationStatus(orderConfirmation)
                    )}
                    InputProps={{
                        readOnly: true,
                    }}
                    disabled={true}
                />
                <TextField
                    key="Delivered Date"
                    id="outlined-read-only-input"
                    label="Delivered Date"
                    defaultValue={
                        orderConfirmation.deliveredDate
                            ? moment(orderConfirmation.deliveredDate).format('DD-MM-YYYY')
                            : 'Not delievered yet'
                    }
                    InputProps={{
                        readOnly: true,
                    }}
                    disabled={true}
                />
            </GeneralDetailsContainer>
        </Container>
    )
}
