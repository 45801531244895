import { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Modal, Button, Typography } from 'antd'
import { GeneralAssembly } from '../../../models/models'
import { toastFailure } from '../../../util/toast'
import { CircularProgress, styled } from '@mui/material'
import '../../tables/table-styles.css'
import {
    deleteGeneralAssembliesRequest,
    getGeneralAssemblyByIdRequest,
    GeneralAssemblyRelation,
} from '../../../api/general-assemblies'
import { OrderedListOutlined } from '@ant-design/icons'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

interface DeleteGeneralAssemblyModalProps {
    generalAssemblyId: number
    closeModal: (generalAssembly?: GeneralAssembly) => void
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

export default function DeleteGeneralAssemblyModal({
    generalAssemblyId,
    closeModal,
}: DeleteGeneralAssemblyModalProps) {
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const [generalAssembly, setGeneralAssembly] = useState<GeneralAssembly>()

    useEffect(() => {
        getGeneralAssembly()
    }, [])

    const getGeneralAssembly = async () => {
        const response = await getGeneralAssemblyByIdRequest(generalAssemblyId, {
            joins: [GeneralAssemblyRelation.CreatedBy],
        })

        if (response.successful) {
            const generalAssembly = response.data
            setGeneralAssembly(generalAssembly)
            setModalState(ModalState.Success)
        } else {
            if (response.status === 404) {
                setModalState(ModalState.NotFound)
            } else {
                setModalState(ModalState.Error)
            }
            toastFailure(response.message)
        }
    }

    const deleteGeneralAssembly = async () => {
        const response = await deleteGeneralAssembliesRequest(generalAssemblyId)
        if (response.successful) {
            closeModal(response.data)
            return
        }
        toastFailure(`Something went wrong deleting the general assembly ${generalAssemblyId}`)
    }

    return (
        <Modal visible={true} footer={null} onCancel={() => closeModal()}>
            {modalState === ModalState.Loading && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        margin: '20px',
                        width: '100%',
                        height: '500px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                </Box>
            )}
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.NotFound && (
                <Box>Could not find the general assembly with the id of {generalAssemblyId}</Box>
            )}
            {modalState === ModalState.Success && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Delete General Assembly {generalAssemblyId}</Title>
                    </Header>
                    <Divider />

                    <Typography.Text type="danger">
                        Are you sure you want to delete this general assembly? Its time allocation
                        might be subtracted from the production-schedule.
                    </Typography.Text>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                        <Button type="primary" danger onClick={deleteGeneralAssembly}>
                            Delete
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    )
}
