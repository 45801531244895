import Box from '@mui/material/Box'
import {
    Button,
    Dialog,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { styled } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { selectPalletTransfers, transferPallets } from '../../../store/warehouse-map-slice'
import { useAppSelector } from '../../../store/hooks'
import { AssemblyTransferMonumentHiuDTO } from '../../../api/logistics-worksheet'

const ModalFooter = (props: { onConfirm: () => void; onClose: () => void }) => {
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
    }))

    const ModalButton = styled(Button)(() => ({
        padding: '0px',
        minWidth: '0px',
        margin: '10px 10px 5px 0px',
    }))

    return (
        <Row>
            <ModalButton variant="outlined" size="small" onClick={props.onClose}>
                Close
            </ModalButton>
            <ModalButton
                variant="outlined"
                size="small"
                onClick={() => {
                    props.onConfirm()
                    props.onClose()
                }}
            >
                Confirm
            </ModalButton>
        </Row>
    )
}

interface AssemblyTransferModalProps {
    isOpen: boolean
    onClose: () => void
}

export const ConfirmTransferModal = ({ isOpen, onClose }: AssemblyTransferModalProps) => {
    const { assemblyToTransfer, transfers } = useAppSelector((state) => state.warehouseMap)
    const palletTransfers = useSelector(selectPalletTransfers)

    const dispatch = useDispatch()
    if (!assemblyToTransfer) {
        if (isOpen) {
            onClose()
        }
        return <></>
    }

    const onConfirm = () => {
        const transferPalletsArgs: {
            assemblyId: number
            transfers: AssemblyTransferMonumentHiuDTO[]
        } = {
            assemblyId: assemblyToTransfer.id,
            transfers: [],
        }
        Object.entries(transfers).map((entry) => {
            const [palletId, transfer] = entry
            transferPalletsArgs.transfers.push({
                amountToTransfer: transfer.amountToTransfer,
                palletProductId: transfer.palletProductId,
                palletId: Number(palletId),
            })
        })
        dispatch(transferPallets(transferPalletsArgs))
    }

    return (
        <Dialog open={isOpen} onClose={onClose} style={{ zIndex: 100 }} fullWidth maxWidth={'sm'}>
            <Box style={{ width: '100%', padding: '20px' }}>
                <Typography fontSize={20}>Confirm transfer</Typography>
                <Typography>Are you sure that you want to confirm the transfer?</Typography>
                <Table size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell>Pallet location</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Document no</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {palletTransfers.map((palletTransfer) => {
                            const { amountToTransfer, palletProduct, x, y, documentNo } =
                                palletTransfer

                            return (
                                <TableRow key={palletProduct.id}>
                                    <TableCell>{`column: ${x + 1} row: ${y + 1}`}</TableCell>
                                    <TableCell>{amountToTransfer}</TableCell>
                                    <TableCell>{`${documentNo || 'None'}`}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                <ModalFooter onConfirm={onConfirm} onClose={onClose} />
            </Box>
        </Dialog>
    )
}
