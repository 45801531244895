/* eslint-disable @typescript-eslint/no-empty-function */
import { Pallet as PalletModel, PalletProduct, WarehouseAreaSpot } from '../../models/models'
import { useAppSelector } from '../../store/hooks'
import {
    ActiveModal,
    isPalletSearchedFor,
    openModal,
    SearchedEffect,
    select,
    SelectedType,
    unSelect,
    ViewType,
} from '../../store/warehouse-map-slice'
import { useDispatch } from 'react-redux'
import { Box, styled } from '@mui/material'
import { getSpotBackgroundColor, SPOT_SIZE_HEIGHT_PX, SPOT_SIZE_WIDTH_PX } from './style'
import { useMemo } from 'react'

interface PalletDisplayProps {
    pallet: PalletModel
    spot: WarehouseAreaSpot
}

const BASE_FONT_SIZE = 4 // in px

const Container = styled(Box)(() => ({
    position: 'relative',
}))

type PalletProperties = Omit<PalletModel, 'id'>
type SpotProperties = Partial<Omit<WarehouseAreaSpot, 'id'>>

type ContentContainerProps = {
    pallet: PalletProperties
    spot: SpotProperties
    isSearchedFor: boolean
    searchedEffect: SearchedEffect
    viewType: ViewType
    transferred: boolean
    isSelected: boolean
}
const ContentContainer = styled(Box, {
    shouldForwardProp: (propName) =>
        propName !== 'transferred' &&
        propName !== 'viewType' &&
        propName !== 'isSearchedFor' &&
        propName !== 'searchedEffect' &&
        propName !== 'isSelected' &&
        propName !== 'isPalletsWithinAssemblyTransferred' &&
        propName !== 'isPalletWithinSelected' &&
        propName !== 'isPalletsWithinOnAssemblyToStart',
})<ContentContainerProps>((props) => {
    const { isSearchedFor, searchedEffect, transferred, spot, isSelected } = props
    const GREEN_OUTLINE = '1px solid green'

    const outline = () => {
        let outline = 'none'
        if (isSelected) {
            outline = GREEN_OUTLINE
        }
        return outline
    }

    const backgroundColor = () => {
        if (transferred || (isSearchedFor && searchedEffect === SearchedEffect.Glow)) {
            return 'yellow'
        }
        return getSpotBackgroundColor(spot)
    }

    return {
        backgroundColor: backgroundColor(),
        border: 'none',
        color: 'inherit',
        outline: outline(),
        fontSize: `${BASE_FONT_SIZE}px`,
        fontWeight: 'bold',
        cursor: 'pointer',
        zIndex: '0',
        width: `${SPOT_SIZE_WIDTH_PX}px`,
        height: `${SPOT_SIZE_HEIGHT_PX}px`,
        paddingBottom: '100%',
        top: '0px',
        left: '0px',
        lineHeight: 'normal',
        padding: '0px',
        margin: '0px',
        textAlign: 'left',
    }
})

const MainTextContainer = styled(Box)(() => ({
    padding: '3px',
    overflow: 'hidden',
}))

const StackLevelContainer = styled(Box)(() => ({
    position: 'absolute',
    right: '2px',
    bottom: '3px',
    fontSize: `${BASE_FONT_SIZE + 3}px`,
}))

const ProjectContainer = styled(Box)(() => ({
    fontSize: '5.5px',
}))

const ProductContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
}))

/**
 * This component is a child to Spot, and is rendered when a spot have a single pallet as opposed
 * to Stack when it has multiple.
 * @param param0
 * @returns
 */
export default function Pallet({ pallet, spot }: PalletDisplayProps) {
    const { searchText, searchType, viewType, searchedEffect, selected, transfers } =
        useAppSelector((state) => state.warehouseMap)
    const dispatch = useDispatch()

    const transferred = useMemo(() => {
        return (
            viewType === ViewType.Transfer &&
            !!pallet?.palletProducts.find((palletProduct) =>
                Object.values(transfers).find(
                    (transfer) => transfer.palletProductId === palletProduct.id
                )
            )
        )
    }, [viewType, pallet, spot])

    const displayedProject = useMemo(() => {
        if (!pallet?.assembly?.callOff?.project?.name && !pallet?.project?.name) {
            return null
        }
        return <span>{pallet?.project?.name ?? pallet?.assembly?.callOff?.project?.name}</span>
    }, [pallet])

    const displayedProducts = useMemo(() => {
        if (!pallet?.palletProducts || pallet.palletProducts.length < 1) {
            return null
        }
        //if the pallet has a project, it should only display one product
        if (displayedProject) {
            return (
                <span>{`${pallet.palletProducts[0].amount} x ${pallet.palletProducts[0].product.code}`}</span>
            )
        }

        return pallet.palletProducts.map<JSX.Element>((palletProduct: PalletProduct) => {
            return (
                <span
                    key={palletProduct.product.code}
                >{`${palletProduct.amount} x ${palletProduct.product.code}`}</span>
            )
        })
    }, [pallet, displayedProject])

    const isSelected = useMemo(() => {
        return selected.selectedId === pallet.id && selected.type === SelectedType.Pallet
    }, [selected, pallet])

    const isSearchedFor = useMemo(() => {
        return pallet ? isPalletSearchedFor(searchType, searchText, pallet) : false
    }, [pallet, searchType, searchText])

    const onClicks: { [key in ViewType]: (e: React.MouseEvent<HTMLElement>) => void } = {
        [ViewType.AssemblyComplete]: (e: React.MouseEvent<HTMLElement>) => {},
        [ViewType.AssemblyDispatch]: (e: React.MouseEvent<HTMLElement>) => {
            //if other is selected, then use onClick in Spot.tsx to open movement modal
            if (!isSelected && selected.selectedId) {
                return
            }
            e.stopPropagation()
            //if currently selected, unselect
            if (isSelected) {
                dispatch(unSelect())
                return
            }
            //if it is not selected and nothing else is selected, select it
            dispatch(select({ selectedId: pallet.id, type: SelectedType.Pallet }))
        },
        [ViewType.AssemblyStart]: () => {
            dispatch(openModal({ modal: ActiveModal.InspectPallet, palletId: pallet.id }))
        },
        [ViewType.CallOffDispatch]: (e: React.MouseEvent<HTMLElement>) => {
            //if other is selected, then use onClick in Spot.tsx to open movement modal
            if (!isSelected && selected.selectedId) {
                return
            }
            e.stopPropagation()
            //if currently selected, unselect
            if (isSelected) {
                dispatch(unSelect())
                return
            }
            //if it is not selected and nothing else is selected, select it
            dispatch(select({ selectedId: pallet.id, type: SelectedType.Pallet }))
        },
        [ViewType.Regular]: (e: React.MouseEvent<HTMLElement>) => {
            //if other is selected, then use onClick in Spot.tsx to open movement modal
            if (!isSelected && selected.selectedId) {
                return
            }
            e.stopPropagation()
            //if currently selected, unselect
            if (isSelected) {
                dispatch(unSelect())
                return
            }
            //if it is not selected and nothing else is selected, select it
            dispatch(select({ selectedId: pallet.id, type: SelectedType.Pallet }))
        },
        [ViewType.Transfer]: () => {
            dispatch(openModal({ modal: ActiveModal.AssemblyTransfer, palletId: pallet.id }))
        },
    }

    //id is a protected string value in JSX components
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...palletProperties } = pallet || { id: NaN }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: _, ...spotProperties } = spot || { id: NaN }

    return (
        <Container onClick={(e: React.MouseEvent<HTMLElement>) => onClicks[viewType](e)}>
            <ContentContainer
                isSearchedFor={isSearchedFor}
                transferred={transferred}
                searchedEffect={searchedEffect}
                viewType={viewType}
                isSelected={isSelected}
                pallet={palletProperties}
                spot={spotProperties}
            >
                <MainTextContainer>
                    <ProjectContainer>{displayedProject}</ProjectContainer>
                    <ProductContainer>{displayedProducts}</ProductContainer>
                </MainTextContainer>
                <StackLevelContainer>{pallet && pallet.stackLevel}</StackLevelContainer>
            </ContentContainer>
        </Container>
    )
}
