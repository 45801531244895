import { GeneralAssembly } from '../models/models'
import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'

export interface UpdateGeneralAssemblyLineDto {
    serial?: string | null
    note?: string | null
    completedAt?: Date | null
}

export const updateGeneralAssemblyLineRequest = (
    itemId: number,
    dto: UpdateGeneralAssemblyLineDto
): Promise<Response<GeneralAssembly>> =>
    backendConnection.request({
        url: `general-assembly-lines/${itemId}`,
        method: 'PATCH',
        data: dto,
    })
