import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    TextField,
} from '@mui/material'
import { Select } from 'antd'
import { DaysOffType, User, UserRole, Schedule, AbsentDayType } from '../../../models/models'
import {
    isBritishHolidayOrIsWeekend,
    isInPastOrIsBritishHolidayOrIsWeekend,
} from '../../../util/british-holidays'
import { useEffect, useState } from 'react'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { getWeekDayDateRangeArray, snakeCaseToTitleCase } from '../../../util/util'
import moment from 'moment'
import { createDaysOffRequest, DayDTO, DaysOffDTO } from '../../../api/days-off'
import { getProductionSchedules } from '../../../api/production-schedule'
import { CreateDaysOffTable } from './CreateDaysOffTable'
import { styled } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import DatePicker from '../../ui/calendar/DatePicker'

interface AddDaysOffModalProps {
    isOpen: boolean
    onClose: () => void
    users: User[]
}

const Header = styled(Box)(() => ({
    margin: '20px',
    display: 'flex',
    justifyContent: 'space-between',
}))

export const CreateDaysOffModal = ({ isOpen, onClose, users }: AddDaysOffModalProps) => {
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [days, setDays] = useState<DayDTO[]>([])
    const [user, setUser] = useState<User | null>(null)
    const [dayOffType, setDayOffType] = useState<DaysOffType>(DaysOffType.Holiday)
    const [isFullDaysOff, setIsFullDaysOff] = useState(false)
    const [scheduleBeforeAllocation, setScheduleBeforeAllocation] = useState<Schedule[]>([])

    const onCloseModalSpecific = () => {
        setIsFullDaysOff(false)
        onClose()
    }

    const handleChangeDates = (dates: [moment.Moment | null, moment.Moment | null] | null) => {
        if (dates != null) {
            if (dates[0] != null) {
                setStartDate(dates[0].toDate())
            } else {
                setStartDate(null)
            }

            if (dates[1] != null) {
                setEndDate(dates[1].toDate())
            } else {
                setEndDate(null)
            }
        }
    }

    const loadSchedules = async () => {
        if (!user || !startDate || !endDate) {
            return
        }

        const response = await getProductionSchedules({
            from: startDate,
            to: endDate,
            warehouseId: user.warehouseId,
        })
        if (response.successful) {
            setScheduleBeforeAllocation(response.data)
        }
    }

    useEffect(() => {
        if (user?.role === UserRole.Assembler && startDate && endDate) {
            loadSchedules()
        }
    }, [user, startDate, endDate])

    useEffect(() => {
        if (startDate && endDate) {
            //create individual day dtos based on the range
            const dateRange = getWeekDayDateRangeArray(moment(startDate), moment(endDate)).filter(
                (d) => moment(d)
            )
            const absentDays: DayDTO[] = dateRange.map((day) => {
                const existingDay = days.find((d) => moment(d.date).isSame(day, 'date'))
                if (existingDay) {
                    return existingDay
                }
                return {
                    date: day,
                    type: AbsentDayType.FullDay,
                }
            })

            setDays(absentDays)
        }
    }, [startDate, endDate])

    const disableSubmit = !user || !startDate || !endDate

    async function createDaysOff() {
        if (user !== null && startDate && endDate) {
            const body: DaysOffDTO = {
                type: dayOffType,
                absentDays: days,
                userId: user.id,
            }
            const response = await createDaysOffRequest(body)
            if (response.successful) {
                toastSuccess('Days off successfully added.')
            } else {
                toastFailure(response.message)
            }
            onCloseModalSpecific()
        }
    }

    return (
        <Dialog open={isOpen} onClose={onCloseModalSpecific} style={{ zIndex: 100 }} maxWidth="xl">
            <Box
                sx={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: '15px 15px 10px 23px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Box>Create Days Off{user && ` for ${user?.firstName} ${user?.lastName}`}</Box>
                <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent sx={{ width: '800px' }}>
                <Header>
                    <FormControl sx={{ minWidth: 160 }} size="small">
                        <Select
                            placeholder={'Type'}
                            size={'large'}
                            style={{ width: '100%' }}
                            onChange={(value) => setDayOffType(value as DaysOffType)}
                            value={dayOffType}
                        >
                            {Object.values(DaysOffType).map((type) =>
                                type == DaysOffType.MaternityPaternityLeave ? (
                                    <Select.Option
                                        value={DaysOffType.MaternityPaternityLeave}
                                        key={DaysOffType.MaternityPaternityLeave}
                                        name="Maternity/Paternity Leave"
                                    >
                                        Maternity/Paternity Leave
                                    </Select.Option>
                                ) : (
                                    <Select.Option
                                        value={type}
                                        key={type}
                                        name={snakeCaseToTitleCase(type.toString())}
                                    >
                                        {snakeCaseToTitleCase(type.toString())}
                                    </Select.Option>
                                )
                            )}
                        </Select>
                    </FormControl>
                    <Box>
                        <DatePicker.RangePicker
                            disabledDate={isBritishHolidayOrIsWeekend}
                            onCalendarChange={handleChangeDates}
                        />
                    </Box>
                    <FormControl sx={{ minWidth: 160 }} size="small">
                        <Autocomplete
                            fullWidth
                            style={{ borderRadius: '10px 10px 10px 10px' }}
                            value={user}
                            onChange={(
                                _: React.SyntheticEvent<Element, Event>,
                                newValue: User | null
                            ) => setUser(newValue)}
                            options={users}
                            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                            renderInput={(params) => (
                                <TextField {...params} label="Employee" placeholder="Employee" />
                            )}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.firstName} {option.lastName}
                                </Box>
                            )}
                        />
                    </FormControl>
                </Header>
                {user?.role === UserRole.Assembler && (
                    <CreateDaysOffTable
                        schedules={scheduleBeforeAllocation}
                        user={user}
                        days={days}
                        setDays={setDays}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    variant={'outlined'}
                    color={'primary'}
                    disabled={disableSubmit}
                    onClick={createDaysOff}
                    style={{
                        borderRadius: '10px',
                        height: '40px',
                        paddingRight: '16px',
                        margin: '15px 23px 15px 0px',
                    }}
                >
                    <AddIcon style={{ marginRight: '4px' }} />
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
