import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { Input, Modal, Select } from 'antd'
import { Box, Typography } from '@mui/material'
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons'
import {
    closeUploadModal,
    isModalValidView,
    setComment,
    setSelectedFile,
    setSelectedTypeId,
    submitModal,
} from '../../../../store/project-documents-slice'
import { ReactNode } from 'react'
import { useDropzone } from 'react-dropzone'
import SmallButton from '../project-info/SmallButton'
import CTRLoader from '../../../ui/loader/CTRloader'

const UploadArea = (props: { onDrop: (file: File) => void }) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (files: File[]) => props.onDrop(files[0]),
        maxFiles: 1,
        minSize: 1,
    })

    return (
        <Box
            {...getRootProps()}
            sx={{
                background: '#F0F2F5',
                height: '150px',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
            }}
        >
            <InboxOutlined style={{ fontSize: 50, color: '#3500A0', paddingBottom: '20px' }} />
            <Typography style={{ textAlign: 'center' }}>
                Click or drag file to this area to upload
            </Typography>

            <input {...getInputProps()} />
        </Box>
    )
}

const FormRow = ({ children }: { children: ReactNode }) => {
    return <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>{children}</Box>
}

export default function DocumentModal() {
    const dispatch = useAppDispatch()
    const documentTypes = useAppSelector((state) => state.projectDocuments.documentTypes)
    const modalState = useAppSelector((state) => state.projectDocuments.modal)
    const isUploadModalValid = useAppSelector(isModalValidView)

    const {
        isVisible,
        isLoading,
        selectedTypeId,
        selectedFile,
        comment,
        newVersionOf,
        isUpdatingComment,
    } = modalState

    return (
        <Modal
            title={
                <Typography sx={{ fontWeight: 'bold' }}>
                    {isUpdatingComment ? 'Update comment' : 'Add document'}
                </Typography>
            }
            visible={isVisible}
            footer={
                <Box>
                    <SmallButton
                        text={'Cancel'}
                        disabled={isLoading}
                        onClick={() => dispatch(closeUploadModal())}
                    />
                    <SmallButton
                        color={'green'}
                        text={'Save'}
                        disabled={isLoading || !isUploadModalValid}
                        onClick={() => dispatch(submitModal())}
                    />
                </Box>
            }
            onCancel={() => dispatch(closeUploadModal())}
        >
            <Box sx={{ position: 'relative' }}>
                {isLoading && (
                    <div
                        style={{
                            zIndex: 10,
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <CTRLoader size="xl" />
                    </div>
                )}

                <Box sx={{ opacity: isLoading ? 0.5 : 1 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                        }}
                    >
                        {!isUpdatingComment && (
                            <UploadArea onDrop={(file) => dispatch(setSelectedFile(file))} />
                        )}
                        {!isUpdatingComment && (
                            <FormRow>
                                <Box sx={{ width: '160px', display: 'flex', alignItems: 'center' }}>
                                    <Box>
                                        <Select
                                            placeholder={'Select document type'}
                                            value={selectedTypeId || ''}
                                            onChange={(id) =>
                                                dispatch(setSelectedTypeId(Number(id) || 0))
                                            }
                                            style={{ width: '200px' }}
                                            disabled={!!newVersionOf}
                                        >
                                            {documentTypes.map((documentType) => (
                                                <Select.Option
                                                    key={documentType.id}
                                                    value={documentType.id}
                                                >
                                                    {documentType.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Box>
                                </Box>

                                {!!selectedFile && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingLeft: '10px',
                                            flexShrink: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#3500A0',
                                                fontSize: '14px',
                                                wordWrap: 'break-word',
                                            }}
                                        >
                                            {selectedFile?.name}
                                        </Typography>
                                    </Box>
                                )}
                                {!!selectedFile && (
                                    <Box sx={{ marginLeft: 'auto' }}>
                                        <DeleteOutlined
                                            style={{
                                                fontSize: 15,
                                                color: '#7E8C80',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => dispatch(setSelectedFile(null))}
                                        />
                                    </Box>
                                )}
                            </FormRow>
                        )}
                        <FormRow>
                            <Input.TextArea
                                style={{ height: '120px' }}
                                placeholder={'Comment'}
                                value={comment || ''}
                                onChange={(e) => dispatch(setComment(e.target.value))}
                            />
                        </FormRow>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}
