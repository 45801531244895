import { Button } from '@mui/material'
import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../store/hooks'
import { ActiveModal, openModal } from '../../../store/warehouse-map-slice'
import { ActionBarContainer } from './ActionBarContainer'

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '20px',
    padding: '10px',
    marginRight: '50px',
}))

export const AssemblyDispatchActionBar = () => {
    const { assemblyToDispatch } = useAppSelector((state) => state.warehouseMap)
    const dispatch = useDispatch()

    if (!assemblyToDispatch) {
        return <>No assembly to dispatch</>
    }

    return (
        <ActionBarContainer>
            <Button
                variant="outlined"
                onClick={() => dispatch(openModal({ modal: ActiveModal.CancelDispatch }))}
            >
                Cancel dispatch
            </Button>
            <Button
                variant="outlined"
                onClick={() => dispatch(openModal({ modal: ActiveModal.ConfirmAssemblyDispatch }))}
            >
                Confirm dispatch
            </Button>
        </ActionBarContainer>
    )
}
