import moment from 'moment'
import { AbsentDay, AbsentDayType, User } from '../../models/models'
import { isKeyOfObject } from '../../util/is-key-of-object'
import { toastFailure } from '../../util/toast'

export const getAbsentDaySubtractedMinutes = (absentDay: AbsentDay) => {
    const currentDay = moment(absentDay.date).isoWeekday().toString()
    if (currentDay === '6' || currentDay === '7') {
        return 0
    }

    if (!absentDay?.daysOff?.user) {
        toastFailure(
            'To calculate the deducted time for an absence, it needs a relation to the user'
        )
        return 0
    }

    const availableMinutes = {
        '1': (user: User) => user.scheduledMinutesMonday,

        '2': (user: User) => user.scheduledMinutesTuesday,

        '3': (user: User) => user.scheduledMinutesWednesday,

        '4': (user: User) => user.scheduledMinutesThursday,

        '5': (user: User) => user.scheduledMinutesFriday,
    }

    if (!isKeyOfObject(currentDay, availableMinutes)) {
        toastFailure('Something went wrong retrieving the minutes to subtract from a absent day')
        return 0
    }

    const userAvailableMinutes = availableMinutes[currentDay](absentDay.daysOff.user)

    //we can assume it should be subtracted because we only consider assembler days off
    const minutesToSubtractFromSchedule = {
        [AbsentDayType.FullDay]: () => userAvailableMinutes,

        [AbsentDayType.HalfDay]: () => Math.round(userAvailableMinutes / 2),

        [AbsentDayType.Minutes]: () => {
            toastFailure('Using unsupportd absent day type')
            return 0
        },
    }[absentDay.type]()

    return minutesToSubtractFromSchedule || 0
}
