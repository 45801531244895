import { Box } from '@mui/material'
import { Button } from 'antd'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getTasksRequest, TaskRelation } from '../../../api/tasks'
import { useAppDispatch } from '../../../store/hooks'
import { RootState } from '../../../store/store'
import { ActiveModal, openModal } from '../../../store/tasks-slice'
import TasksTable, { TasksTableColumns } from '../../tables/TaskTable'
import FilterBar from './FilterBar'

export default function Tasks() {
    const {
        filterWarehouseId,
        filterDateToBeCompletedByDateRangeEnd,
        filterDateToBeCompletedBydDateRangeBegin,
        filterCompletedDateRangeBegin,
        filterCompletedDateRangeEnd,
        updateTableToken,
    } = useSelector((state: RootState) => state.tasks)

    const dispatch = useAppDispatch()

    return (
        <Box>
            <FilterBar />
            <TasksTable
                pagination={true}
                columns={[
                    TasksTableColumns.Id,
                    TasksTableColumns.Type,
                    TasksTableColumns.Project,
                    TasksTableColumns.Description,
                    TasksTableColumns.Minutes,
                    TasksTableColumns.DateToBeCompletedBy,
                    TasksTableColumns.CompletedAt,
                    TasksTableColumns.AssignedTo,
                ]}
                actionComponents={[
                    (taskId: number) => (
                        <Button
                            style={{ marginRight: '10px' }}
                            type="default"
                            onClick={() => {
                                dispatch(
                                    openModal({
                                        activeModal: ActiveModal.TaskInspect,
                                        modalEntityId: taskId,
                                    })
                                )
                            }}
                        >
                            Inspect
                        </Button>
                    ),
                    (taskId: number) => (
                        <Button
                            style={{ marginRight: '10px' }}
                            type="default"
                            onClick={() => {
                                dispatch(
                                    openModal({
                                        activeModal: ActiveModal.TaskUpdate,
                                        modalEntityId: taskId,
                                    })
                                )
                            }}
                        >
                            Update
                        </Button>
                    ),
                    (taskId: number) => (
                        <Button
                            style={{ marginRight: '10px' }}
                            type="default"
                            onClick={() => {
                                dispatch(
                                    openModal({
                                        activeModal: ActiveModal.TaskDelete,
                                        modalEntityId: taskId,
                                    })
                                )
                            }}
                        >
                            Delete
                        </Button>
                    ),
                ]}
                dependencies={[
                    filterWarehouseId,
                    filterDateToBeCompletedBydDateRangeBegin,
                    filterDateToBeCompletedByDateRangeEnd,
                    filterCompletedDateRangeBegin,
                    filterCompletedDateRangeEnd,
                    updateTableToken,
                ]}
                request={(pagination) =>
                    getTasksRequest({
                        dateToBeCompletedByDateRangeEnd:
                            typeof filterDateToBeCompletedByDateRangeEnd === 'number'
                                ? moment.unix(filterDateToBeCompletedByDateRangeEnd).toDate()
                                : undefined,
                        dateToBeCompletedBydDateRangeBegin:
                            typeof filterDateToBeCompletedBydDateRangeBegin === 'number'
                                ? moment.unix(filterDateToBeCompletedBydDateRangeBegin).toDate()
                                : undefined,
                        completedDateRangeBegin:
                            typeof filterCompletedDateRangeBegin === 'number'
                                ? moment.unix(filterCompletedDateRangeBegin).toDate()
                                : undefined,
                        completedDateRangeEnd:
                            typeof filterCompletedDateRangeEnd === 'number'
                                ? moment.unix(filterCompletedDateRangeEnd).toDate()
                                : undefined,
                        warehouseId: filterWarehouseId,
                        relations: [
                            TaskRelation.AssignedTo,
                            TaskRelation.CreatedBy,
                            TaskRelation.Type,
                            TaskRelation.Project,
                        ],
                        skip: pagination?.skip,
                        limit: pagination?.limit,
                        orderField: 'task.id',
                        orderDirection: 'ASC',
                    })
                }
            />
        </Box>
    )
}
