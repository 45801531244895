import Holidays from 'date-holidays'
import moment from 'moment'

const hds = new Holidays('GB', 'ENG')

export function isInPastOrIsBritishHolidayOrIsWeekend(current: moment.Moment): boolean {
    return (
        current < moment().startOf('day') ||
        current.day() % 6 == 0 ||
        hds.isHoliday(current.toString()) != false
    )
}

export const isBritishHoliday = (current: moment.Moment): boolean => {
    return hds.isHoliday(current.toString()) != false
}

export function isBritishHolidayOrIsWeekend(current: moment.Moment): boolean {
    return current.day() % 6 == 0 || hds.isHoliday(current.toString()) != false
}
