import { Dialog, IconButton, Divider, Paper } from '@mui/material'
import { Box, styled } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import { DeliveryAddress } from '../../../api/customers'
import DeliveryAddressTable, { DeliveryAddressColumns } from '../../tables/DeliveryAddressTable'
import { useMemo, useState } from 'react'
import { Button, Input } from 'antd'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
    padding: '20px',
    marginTop: '50px',
})

interface Props {
    isOpen: boolean
    deliveryAddresses: DeliveryAddress[]
    onSelect: (deliveryAddress: DeliveryAddress) => void
    closeModal: () => void
}

export default function SelectDeliveryAddressModal(props: Props) {
    const [postCode, setPostCode] = useState<string>('')
    const { deliveryAddresses, onSelect, closeModal, isOpen } = props

    const filteredDeliveryAddresses = useMemo(() => {
        return deliveryAddresses.filter((deliveryAddress) =>
            deliveryAddress.postcode.toLowerCase().includes(postCode.toLowerCase())
        )
    }, [deliveryAddresses, postCode])

    return (
        <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="md">
            <Paper>
                <Box
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div> Select Delivery Address </div>
                    <IconButton aria-label="close" onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Container>
                    <Input
                        style={{ width: '300px', marginBottom: '15px' }}
                        name="postcode"
                        placeholder="Post code"
                        onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                        ) => {
                            setPostCode(e.target.value)
                        }}
                        value={postCode}
                    />
                    <DeliveryAddressTable
                        deliveryAddresses={filteredDeliveryAddresses}
                        columns={[
                            DeliveryAddressColumns.Address1,
                            DeliveryAddressColumns.Address2,
                            DeliveryAddressColumns.City,
                            DeliveryAddressColumns.Postcode,
                        ]}
                        actionComponents={[
                            (deliveryAddressId: number) => {
                                return (
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            const deliveryAddress = deliveryAddresses.find(
                                                (d) => d.id === deliveryAddressId
                                            )
                                            if (!deliveryAddress) {
                                                return
                                            }
                                            onSelect(deliveryAddress)
                                            closeModal()
                                        }}
                                    >
                                        Select
                                    </Button>
                                )
                            },
                        ]}
                    />
                </Container>
            </Paper>
        </Dialog>
    )
}
