import { Typography } from 'antd'
import { useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import { styled } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import {
    Button,
    Dialog,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Box,
    IconButton,
    Divider,
    Tooltip,
} from '@mui/material'
import {
    activatePurchaseOrderRequest,
    getPurchaseOrderByIdRequest,
    GetPurchaseOrderRelation,
} from '../../../api/purchase-orders'
import { OrderType, PurchaseOrder, WarehouseId } from '../../../models/models'
import { toastFailure, toastSuccess } from '../../../util/toast'
import CTRLoader from '../../ui/loader/CTRloader'
import { useAppSelector } from '../../../store/hooks'
import PurchaseOrderOrderlineTable from '../../tables/PurchaseOrderOrderlineTable'
import DatePicker from '../../ui/calendar/DatePicker'

const Container = styled(Box)({
    margin: '25px',
})
const Label = (props: { text: string; isInvalid?: boolean }) => {
    const { text, isInvalid } = props
    return (
        <Box style={{ width: '270px' }}>
            <Typography style={{ color: '#262626', opacity: '45%' }}>{text}</Typography>
        </Box>
    )
}

const Text = styled(Typography)(() => ({}))

const RowContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
})

interface ModalProps {
    active: boolean
    purchaseOrderId: number
    onOk: () => void
    onClose: () => void
}

enum PurchaseOrderState {
    Unused = 'Unused',
    Loading = 'Loading',
    Found = 'Found',
    NotFound = 'NotFound',
    Error = 'Error',
}

export default function ActivatePurchaseOrderModal({
    active,
    onOk,
    onClose,
    purchaseOrderId,
}: ModalProps) {
    const [dispatchDate, setDispatchDate] = useState<Moment | null>(null)
    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState<Moment | null>(null)
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>()
    const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseId>(WarehouseId.Monument)
    const [orderType, setOrderType] = useState<OrderType>(OrderType.Project)
    const [purchaseOrderState, setPurchaseOrderState] = useState<PurchaseOrderState>(
        PurchaseOrderState.Unused
    )
    const { user: currentUser } = useAppSelector((state) => state.authentication)

    const close = () => {
        setExpectedDeliveryDate(null)
        setDispatchDate(null)
        setPurchaseOrder(undefined)
        onClose()
    }

    if (!currentUser) {
        toastFailure('Current user is not present')
        close()
    }
    const handleWarehouseChange = (e: SelectChangeEvent<WarehouseId>) => {
        if (!Object.values(WarehouseId)?.includes(e.target.value)) {
            return
        }
        setSelectedWarehouse(e.target.value as WarehouseId)
    }

    const disabledDate = (current: Moment) => {
        // Can not select days before today inclusive
        return current && current < moment().endOf('day')
    }

    const ok = async () => {
        if (dispatchDate === null || expectedDeliveryDate === null || !purchaseOrder) {
            return
        }
        const response = await activatePurchaseOrderRequest(purchaseOrderId, {
            expectedDeliveryDate: expectedDeliveryDate.toDate(),
            supplierDispatchDate: dispatchDate.toDate(),
            orderType,
            warehouseId: selectedWarehouse,
        })
        if (response.successful) {
            toastSuccess(
                `Activated Purchase Order ${response.data ? response.data.documentNo : ''}`
            )
            onOk()
        } else {
            toastFailure(response.message)
        }
    }

    const getPurchaseOrder = async () => {
        if (!currentUser) {
            return
        }
        setPurchaseOrderState(PurchaseOrderState.Loading)
        const response = await getPurchaseOrderByIdRequest(purchaseOrderId, {
            relations: [
                GetPurchaseOrderRelation.Deliveries,
                GetPurchaseOrderRelation.OrderConfirmations,
                GetPurchaseOrderRelation.Orderlines,
            ],
            withAmounts: true,
        })
        if (response.successful) {
            if (response.data.activatedDate) {
                setPurchaseOrderState(PurchaseOrderState.Error)
                toastFailure('The purchase order has already been activated')
                return
            }
            setPurchaseOrder(response.data)
            setPurchaseOrderState(PurchaseOrderState.Found)
        } else if (response.status === 404) {
            toastFailure(response.message)
            setPurchaseOrderState(PurchaseOrderState.NotFound)
        } else {
            setPurchaseOrderState(PurchaseOrderState.Error)
            toastFailure(response.message)
        }
    }

    useEffect(() => {
        getPurchaseOrder()
    }, [])

    return (
        <Dialog open={active} onClose={close} fullWidth sx={{ zIndex: '100' }} maxWidth={'lg'}>
            <Paper>
                <Box
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div> Create {orderType} Order </div>
                    <IconButton aria-label="close" onClick={close}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Container>
                    {purchaseOrderState === PurchaseOrderState.Unused && <></>}
                    {purchaseOrderState === PurchaseOrderState.Loading && <></>}
                    {purchaseOrderState === PurchaseOrderState.Error && (
                        <>Something went wrong finding the purchase order</>
                    )}
                    {purchaseOrderState === PurchaseOrderState.NotFound && (
                        <>Could not find the purchase order</>
                    )}
                    {purchaseOrderState === PurchaseOrderState.Loading && (
                        <>
                            <CTRLoader />
                        </>
                    )}
                    {purchaseOrder && purchaseOrderState === PurchaseOrderState.Found && (
                        <Box>
                            <RowContainer>
                                <Label text="Supplier Dispatch Date" />
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format="DD-MM-YYYY"
                                    value={dispatchDate}
                                    disabledDate={disabledDate}
                                    onChange={(value) => setDispatchDate(value)}
                                />
                            </RowContainer>
                            <RowContainer>
                                <Label text="Expected Delivery Date" />
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format="DD-MM-YYYY"
                                    value={expectedDeliveryDate}
                                    disabledDate={disabledDate}
                                    onChange={(value) => setExpectedDeliveryDate(value)}
                                />
                            </RowContainer>
                            <RowContainer>
                                <Label text="Warehouse" />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Warehouse</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedWarehouse}
                                        label="Warehouse"
                                        onChange={handleWarehouseChange}
                                    >
                                        <MenuItem value={WarehouseId.Monument}>Monument</MenuItem>
                                        <MenuItem value={WarehouseId.Scandia}>Scandia</MenuItem>
                                    </Select>
                                </FormControl>
                            </RowContainer>
                            <RowContainer>
                                <Label text={'Purchase order creator'} />
                                <Text>{purchaseOrder.documentCreatedBy}</Text>
                            </RowContainer>
                            <RowContainer>
                                <Label text={'Supplier'} />
                                <Text>{purchaseOrder.supplierName}</Text>
                            </RowContainer>
                            <RowContainer>
                                <Label text={'Created at'} />
                                <Text>
                                    {moment(purchaseOrder.documentDate).format('DD-MM-YYYY')}
                                </Text>
                            </RowContainer>
                            <PurchaseOrderOrderlineTable purchaseOrder={purchaseOrder} />
                            <Button
                                disabled={!dispatchDate || !expectedDeliveryDate}
                                variant="outlined"
                                onClick={ok}
                            >
                                Activate {orderType} PurchaseOrder
                            </Button>
                        </Box>
                    )}
                </Container>
            </Paper>
        </Dialog>
    )
}
