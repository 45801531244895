import { Alert, Button, Modal } from 'antd'
import { CircularProgress, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
    ModalState,
    addAssembly,
    closeStartAssemblyActivityModal,
    updateStartAssemblyActivityModal,
} from '../../../../store/assembly-worksheets-slice'
import { toastFailure } from '../../../../util/toast'
import { useEffect, useState } from 'react'
import {
    getUserCurrentActiveAssemblyActivityRequest,
    startNewActiveAssemblyActivityRequest,
} from '../../../../api/assembly-activites'
import { getAssemblyWorksheetRequest } from '../../../../api/assemblies'
import { Assembly, AssemblyActivity } from '../../../../models/models'
import { ActivityType } from '../../../../models/models'

export function AssemblyActivityStartModal() {
    const dispatch = useAppDispatch()
    const { assemblyId, state } = useAppSelector(
        (state) => state.assemblyWorksheets.startAssemblyActivityModalState
    )
    const authenticatedUser = useAppSelector((state) => state.authentication.user)

    const [assembly, setAssembly] = useState<Assembly>()
    const [currentActivity, setCurrentActivity] = useState<AssemblyActivity>()

    const initiateModal = async () => {
        if (state === ModalState.Closed) {
            return
        }
        if (!assemblyId || !authenticatedUser) {
            toastFailure('Failed to retrieve ressources necessary for modal')
            dispatch(updateStartAssemblyActivityModal({ state: ModalState.Error }))
            return
        }

        const [currentAcivityResponse, worksheetResponse] = await Promise.all([
            getUserCurrentActiveAssemblyActivityRequest(),
            getAssemblyWorksheetRequest(assemblyId),
        ])

        if (!currentAcivityResponse.successful || !worksheetResponse.successful) {
            toastFailure('Failed to retrieve the users activity or the worksheet')
            dispatch(updateStartAssemblyActivityModal({ state: ModalState.Error }))
            return
        }

        dispatch(addAssembly(worksheetResponse.data))

        const currentActivity = currentAcivityResponse.data
        const worksheet = worksheetResponse.data

        if (!worksheet.startedAt) {
            toastFailure('The worksheet has not been started yet')
            dispatch(closeStartAssemblyActivityModal())
            return
        }

        // check if the worksheet has an activity where the user is the owner that has not ended
        const alreadyActive = worksheet.activities.some((activity) => {
            if (activity.userId === authenticatedUser.id && !activity.endedAt) {
                toastFailure('You already have an activity for this worksheet')
                dispatch(closeStartAssemblyActivityModal())
                return
            }
        })

        if (alreadyActive) {
            toastFailure('User already active on the worksheet')
            dispatch(closeStartAssemblyActivityModal())
            return
        }

        setCurrentActivity(currentActivity)
        setAssembly(worksheet)
        dispatch(updateStartAssemblyActivityModal({ state: ModalState.Ready }))
    }

    const startActivity = async () => {
        if (!assemblyId) {
            toastFailure('Failed to start activity')
            dispatch(updateStartAssemblyActivityModal({ state: ModalState.Error }))
            return
        }
        dispatch(updateStartAssemblyActivityModal({ state: ModalState.Loading }))
        const response = await startNewActiveAssemblyActivityRequest({
            assemblyId: assemblyId,
            type: ActivityType.Assembly,
        })

        if (!response.successful) {
            dispatch(updateStartAssemblyActivityModal({ state: ModalState.Error }))
            toastFailure(response.message)
            return
        }

        const worksheetResponse = await getAssemblyWorksheetRequest(assemblyId)

        if (!worksheetResponse.successful) {
            toastFailure('Failed to retrieve the worksheet after updating the activity')
            dispatch(updateStartAssemblyActivityModal({ state: ModalState.Error }))
            return
        }

        dispatch(addAssembly(worksheetResponse.data))
        dispatch(closeStartAssemblyActivityModal())
    }

    useEffect(() => {
        if (state === ModalState.Opened) {
            dispatch(updateStartAssemblyActivityModal({ state: ModalState.Loading }))
            initiateModal()
        }
    }, [state])

    const footer = (
        <div>
            <Button disabled={false} onClick={() => dispatch(closeStartAssemblyActivityModal())}>
                Inspect
            </Button>
            <Button type="primary" disabled={state !== ModalState.Ready} onClick={startActivity}>
                Claim Worksheet
            </Button>
        </div>
    )

    const getModalText = () => {
        if (currentActivity) {
            const activityTypeText =
                currentActivity.type === ActivityType.GeneralAssembly ? (
                    <Typography>
                        You already have an active activity on another general assembly <br />
                        ID: {currentActivity.generalAssemblyId} <br />
                        Item:
                        {currentActivity.generalAssembly.builtItem.bomCode}
                    </Typography>
                ) : (
                    <Typography>
                        You already have an active activity on another assembly <br />
                        ID: {currentActivity.assemblyId} <br />
                        Item:{' '}
                        {
                            currentActivity.assembly.builtItemCallOff.sopBuiltItemOrderline
                                .builtItem.bomCode
                        }
                    </Typography>
                )
            return (
                <>
                    <Alert
                        message={
                            <Typography>
                                {activityTypeText} <br />
                                By claiming this worksheet, you will leave the one you are currently
                                active on
                            </Typography>
                        }
                        type="warning"
                    />
                    <br />
                    <Typography>
                        You can either inspect the activity or claim the worksheet to start a new
                        activity.
                    </Typography>
                </>
            )
        }

        return (
            <div>
                <Typography>
                    By claiming this worksheet, you will be able to start completing units
                </Typography>
            </div>
        )
    }

    return (
        <Modal
            title={<Typography sx={{ fontWeight: 'bold' }}>{'Claim assembly'}</Typography>}
            open={state !== ModalState.Closed}
            width={700}
            onCancel={() => dispatch(closeStartAssemblyActivityModal())}
            footer={footer}
        >
            <div>
                {state === ModalState.Error && <div>Failed to load the modal</div>}
                {state === ModalState.Loading && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                    </div>
                )}
                {state === ModalState.Ready && <div>{getModalText()}</div>}
            </div>
        </Modal>
    )
}
