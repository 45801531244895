import { Dialog, IconButton, Divider, Paper, Button, Tab, Alert } from '@mui/material'
import { Box, styled } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useState } from 'react'
import { OrderConfirmation, PurchaseOrder } from '../../../models/models'
import { toastFailure, toastSuccess } from '../../../util/toast'
import CTRLoader from '../../ui/loader/CTRloader'
import {
    GetOrderConfirmationRelations,
    deleteOrderConfirmationRequest,
    getOrderConfirmationRequest,
} from '../../../api/order-confirmation'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

const Content = styled(Box)({
    width: '100%',
})

interface Props {
    orderConfirmationId: number
    onClose: () => void
    active: boolean
}

enum State {
    Loading = 'Loading',
    Found = 'Found',
    NotFound = 'NotFound',
    Error = 'Error',
}

export default function DeleteOrderConfirmationModal(props: Props) {
    const { orderConfirmationId, onClose, active } = props
    const [orderConfirmation, setOrderConfirmation] = useState<OrderConfirmation>()
    const [state, setState] = useState<State>(State.Loading)

    const deleteOderConfirmation = async () => {
        if (orderConfirmation) {
            const response = await deleteOrderConfirmationRequest(orderConfirmation.id)
            if (!response.successful) {
                toastFailure('Could not delete order confirmation')
                return
            }
            toastSuccess('Order confirmation deleted')
            onClose()
        }
    }

    const getOrderConfirmation = async () => {
        const response = await getOrderConfirmationRequest(orderConfirmationId, {
            relations: [
                GetOrderConfirmationRelations.Orderlines,
                GetOrderConfirmationRelations.Author,
            ],
        })

        if (response.successful) {
            setOrderConfirmation(response.data)
            setState(State.Found)
        } else if (response.status === 404) {
            setState(State.NotFound)
            toastFailure('Could not find sales order')
        } else {
            setState(State.Error)
            toastFailure('Something went wrong retrieving the sales order')
        }
    }
    useEffect(() => {
        getOrderConfirmation()
    }, [])

    return (
        <Dialog open={active} onClose={onClose} fullWidth maxWidth="md">
            <Paper style={{ height: '100%', maxHeight: '800px' }}>
                <Box
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        Delete Order Confirmation
                        {orderConfirmation ? `${orderConfirmation.code}` : ''}
                    </div>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Container>
                    {state === State.Loading && (
                        <Content
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                margin: '20px',
                                width: '100%',
                                height: '400px',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CTRLoader size="xl" />
                        </Content>
                    )}
                    {orderConfirmation && state === State.Found && (
                        <Content sx={{ margin: '20px' }}>
                            <Alert severity="warning">
                                By approving the submit you are confirming that{' '}
                                <b>Order confirmation {orderConfirmation.code}</b> Should be deleted
                                for the system. If the order confirmation has arrived, then related
                                pallets will lose the knowledge of the order confirmation as well as
                                related project/call-off.
                            </Alert>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '15px',
                                    marginTop: '10px',
                                    flexDirection: 'flex-end',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button color="primary" variant="outlined" onClick={onClose}>
                                    Close
                                </Button>
                                <Button
                                    color="error"
                                    variant="outlined"
                                    onClick={deleteOderConfirmation}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </Content>
                    )}
                </Container>
            </Paper>
        </Dialog>
    )
}
