import { useHistory } from 'react-router-dom'
import {
    Autocomplete,
    Box,
    Button,
    Grid,
    Input,
    InputAdornment,
    TextField,
    Typography,
    Tooltip,
} from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
    addOrganizations,
    formHasChangesView,
    isFormInvalidView,
    isOpportunityReferenceInvalidView,
    isProjectNameInvalidView,
    resetFormState,
    showFormValidation,
    updateFormState,
} from '../../../../store/project-info-slice'
import { OpportunityStatus, Organisation, User, UserRole } from '../../../../models/models'
import { PushpinFilled, PushpinOutlined, SaveOutlined } from '@ant-design/icons'
import {
    checkOpportunityReference,
    fetchEntities,
    importProject,
    save,
    setProjectBeingUpdatedIsPinned,
    showCreateOpportunityStatusModal,
    showCustomerModal,
} from '../../../../store/single-project-view-slice'
import { InputAutocompleteSection, InputSection } from '../../../ui/InputFields'
import { TextColors } from '../../../../theme/colors'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { useEffect, useMemo, useRef, useState } from 'react'

import { snakeCaseToTitleCase } from '../../../../util/util'
import { OpportunityStatusText } from '../../../ui/OpportunityStatusText'
import {
    deleteOpportunityStatusRequest,
    getProjectValue,
    pinProject,
    ProjectValueDto,
} from '../../../../api/projects/projects'
import { toastFailure, toastSuccess } from '../../../../util/toast'
import { ConfirmationModal } from '../../../ui/ConfirmationModal'
import { Urls } from '../../../../util/urls'
import AddIcon from '@mui/icons-material/Add'
import _ from 'lodash'
import { getOrganisationsRequest } from '../../../../api/organisations'
import { isValid } from 'postcode'

interface ProjectInfoProps {
    isNewProject: boolean
}

const OrganisationsAutocomplete = (props: {
    disabled?: boolean
    selectedOrganisationsMap: Organisation[]
    onChangeSelectedOrganizations: (organizations: Organisation[]) => void
}) => {
    const [searchText, setSearchText] = useState<string>('')
    const [searchResults, setSearchResults] = useState<Organisation[]>([])

    useEffect(() => {
        async function fetch() {
            const response = await getOrganisationsRequest({
                limit: 10,
                likeName: searchText,
            })
            if (response.successful) {
                setSearchResults(response.data)
            } else {
                toastFailure('An error occurred while fetching organizations.')
            }
        }

        fetch()
    }, [searchText])

    return (
        <Autocomplete
            fullWidth
            multiple
            disabled={!!props.disabled}
            limitTags={2}
            size="small"
            filterSelectedOptions
            value={props.selectedOrganisationsMap}
            onChange={(
                _: React.SyntheticEvent<Element, Event>,
                newValue: Organisation[] | null
            ) => {
                setSearchText('')
                props.onChangeSelectedOrganizations(newValue || [])
            }}
            onInputChange={(event, newInputValue) => {
                setSearchText(newInputValue || '')
            }}
            options={searchResults}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="" placeholder="" />}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option.name}
                </Box>
            )}
        />
    )
}

const ProjectInfo = ({ isNewProject }: ProjectInfoProps) => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const isUpdating = useAppSelector((state) => !!state.singleProjectView.projectBeingUpdated)
    const projectBeingUpdated = useAppSelector(
        (state) => state.singleProjectView.projectBeingUpdated
    )
    const { formState } = useAppSelector((state) => state.projectInfo)
    const { user: currentUser } = useAppSelector((state) => state.authentication)
    const isAdmin = useMemo(
        () => currentUser?.role === UserRole.SavAdmin || currentUser?.role === UserRole.SystemAdmin,
        [currentUser]
    )
    const opportunityStatuses = useAppSelector(
        (state) => state.singleProjectView.opportunityStatuses
    )
    const accountManagers = useAppSelector((state) => state.singleProjectView.accountManagers)
    const applicationsEngineers = useAppSelector(
        (state) => state.singleProjectView.applicationsEngineers
    )
    const projectManagers = useAppSelector((state) => state.singleProjectView.projectManagers)
    const isOpportunityReferenceInvalid = useAppSelector(isOpportunityReferenceInvalidView)
    const isProjectNameInvalid = useAppSelector(isProjectNameInvalidView)
    const isFormInvalid = useAppSelector(isFormInvalidView)
    const formHasChanges = useAppSelector(formHasChangesView)
    const [projectValue, setProjectValue] = useState<ProjectValueDto>()
    const projectMatchingOpportunityReference = useAppSelector(
        (state) => state.singleProjectView.projectMatchingOpportunityReference
    )
    const selectedOrganisationsMap = useAppSelector(
        (state) => state.projectInfo.selectedOrganisationsMap
    )

    const hasBeenImported = !!projectBeingUpdated && !projectBeingUpdated.timeDeletedAt

    useEffect(() => {
        if (!projectBeingUpdated) {
            return
        }
        getProjectValue(projectBeingUpdated.id).then((response) => {
            if (!response.successful) {
                return
            }
            setProjectValue(response.data)
        })
    }, [])
    const onSave = async (closeAfter: boolean) => {
        if (isFormInvalid) {
            dispatch(showFormValidation())
            return
        }

        await dispatch(save())

        if (closeAfter) {
            dispatch(resetFormState())
            history.push(Urls.ProjectMaster)
        }
    }

    const onImport = async (opportunityReference: string | undefined) => {
        if (opportunityReference) {
            const response = await dispatch(importProject(opportunityReference))

            if (response?.status === 404) {
                toastFailure(
                    `Could not find project with opportunity reference ${opportunityReference}`
                )
                return
            }
            if (response?.status === 400) {
                toastFailure(`OppurtunityReference ${opportunityReference} is already in use`)
                return
            }
            if (projectMatchingOpportunityReference) {
                history.push(
                    `${Urls.ProjectMaster}/${projectMatchingOpportunityReference.id}/project-info`
                )
            }
        }
    }

    const onPinProject = async (
        event: React.MouseEvent<HTMLButtonElement>,
        projectId: number,
        isPinned: boolean
    ) => {
        event.preventDefault()
        const isPinnedResponse = await pinProject(projectId, isPinned)
        if (isPinnedResponse.successful) {
            toastSuccess(isPinned ? 'Project pinned' : 'Project unpinned')
            dispatch(setProjectBeingUpdatedIsPinned(isPinned))
        } else {
            toastFailure(isPinned ? 'Pinning project failed' : 'Unpinning project failed')
        }
    }

    const [showDeleteOpportunityStatusModal, setShowDeleteOpportunityStatusModal] = useState<{
        show: boolean
        opportunityStatus: OpportunityStatus | null
    }>({
        show: false,
        opportunityStatus: null,
    })

    async function confirmDeletionOfOpportunityStatus() {
        const res = await deleteOpportunityStatusRequest(
            showDeleteOpportunityStatusModal.opportunityStatus?.id
        )
        if (res.successful) {
            toastSuccess('Opportunity status was deleted successfully')
        } else {
            toastFailure('Opportunity status could not be deleted')
        }
        dispatch(fetchEntities())
    }

    const debouncedOpportunityReferenceCheck = useRef(
        _.debounce((value: string) => {
            dispatch(checkOpportunityReference(value))
        }, 500)
    )

    return (
        <Box>
            <Box sx={{ display: 'flex', marginBottom: '40px', gap: '12px' }}>
                {isNewProject && (
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        disabled={!formState.opportunityReference}
                        onClick={() => onImport(formState.opportunityReference)}
                        startIcon={<AddIcon />}
                    >
                        Import
                    </Button>
                )}
                {!isNewProject && (
                    <>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => onSave(true)}
                            disabled={!formHasChanges || isFormInvalid}
                            startIcon={<SaveOutlined color={'#fff'} />}
                        >
                            Save & Close
                        </Button>
                        <Button
                            variant={'outlined'}
                            color={'secondary'}
                            startIcon={<SaveOutlined />}
                            onClick={() => onSave(false)}
                            disabled={!formHasChanges || isFormInvalid}
                        >
                            Save
                        </Button>
                    </>
                )}
                {projectBeingUpdated && (
                    <Button
                        variant={'outlined'}
                        disabled={!isUpdating}
                        color={'secondary'}
                        startIcon={
                            projectBeingUpdated.isPinned ? <PushpinFilled /> : <PushpinOutlined />
                        }
                        onClick={(e) =>
                            onPinProject(e, projectBeingUpdated.id, !projectBeingUpdated.isPinned)
                        }
                    >
                        Pin
                    </Button>
                )}
            </Box>

            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <InputSection text={'Opportunity reference'} disabled={hasBeenImported}>
                        <Input
                            fullWidth
                            error={isOpportunityReferenceInvalid}
                            value={formState.opportunityReference}
                            disabled={hasBeenImported}
                            onChange={(e) => {
                                if (!isUpdating) {
                                    debouncedOpportunityReferenceCheck.current(e.target.value)
                                }
                                dispatch(
                                    updateFormState({
                                        opportunityReference: e.target.value,
                                    })
                                )
                            }}
                        />
                    </InputSection>

                    <InputSection text={'Project name'} disabled={!hasBeenImported}>
                        <Input
                            fullWidth
                            error={hasBeenImported && isProjectNameInvalid}
                            value={formState.projectName}
                            disabled={!hasBeenImported}
                            onChange={(e) =>
                                dispatch(
                                    updateFormState({
                                        projectName: e.target.value,
                                    })
                                )
                            }
                        />
                    </InputSection>

                    <InputAutocompleteSection
                        disabled={!hasBeenImported}
                        text={'Opportunity status'}
                        button={'+'}
                        onButtonClick={() => dispatch(showCreateOpportunityStatusModal())}
                        isInputValid={true}
                    >
                        <Autocomplete
                            fullWidth
                            disabled={!hasBeenImported}
                            value={opportunityStatuses.find(
                                (status) => status.id === formState.opportunityStatusId
                            )}
                            onChange={(
                                _: React.SyntheticEvent<Element, Event>,
                                newValue: OpportunityStatus | null
                            ) => {
                                dispatch(
                                    updateFormState({
                                        opportunityStatusId: newValue?.id,
                                    })
                                )
                            }}
                            options={opportunityStatuses}
                            getOptionLabel={(option) => snakeCaseToTitleCase(option.name)}
                            renderInput={(params) => (
                                <TextField {...params} label="" placeholder="" />
                            )}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between !important',
                                    }}
                                >
                                    <div style={{ justifyContent: 'flex-start' }}>
                                        {OpportunityStatusText(option)}
                                    </div>
                                    {isAdmin && (
                                        <IconButton
                                            size="small"
                                            onClick={(event) => {
                                                setShowDeleteOpportunityStatusModal({
                                                    show: true,
                                                    opportunityStatus: option,
                                                })
                                                event.stopPropagation()
                                            }}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </Box>
                            )}
                        />
                    </InputAutocompleteSection>

                    <InputSection text={'Project value'} disabled={!hasBeenImported}>
                        <Tooltip title="Displaying the undespatched and total value of a project. The value is based on the products on the sales orders of the project. undespatched / total">
                            <Input
                                fullWidth
                                disabled={true}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Typography fontSize={16} color={TextColors.black}>
                                            £
                                        </Typography>
                                    </InputAdornment>
                                }
                                value={
                                    projectValue &&
                                    `${
                                        Math.round(projectValue?.totalValue) -
                                        Math.round(projectValue?.dispatchedValue)
                                    } / ${Math.round(projectValue?.totalValue)}`
                                }
                            />
                        </Tooltip>
                    </InputSection>

                    <InputSection text={'Postcode'} disabled={!hasBeenImported}>
                        <Input
                            disabled={!hasBeenImported}
                            fullWidth
                            value={formState.postcode}
                            sx={{
                                border:
                                    !formState.postcode || isValid(formState.postcode ?? '')
                                        ? '1px solid #d9d9d9'
                                        : '1px solid red',
                            }}
                            onChange={(e) => {
                                dispatch(
                                    updateFormState({
                                        postcode: e.target.value,
                                    })
                                )
                            }}
                        />
                    </InputSection>

                    <InputSection text={'Street address'} disabled={!hasBeenImported}>
                        <Input
                            disabled={!hasBeenImported}
                            sx={{ height: 'auto', '& .MuiOutlinedInput-root': { height: 'auto' } }}
                            fullWidth
                            multiline
                            minRows={3}
                            value={formState.address}
                            onChange={(e) =>
                                dispatch(
                                    updateFormState({
                                        address: e.target.value,
                                    })
                                )
                            }
                        />
                    </InputSection>
                </Grid>
                <Grid item xs={6}>
                    <InputAutocompleteSection
                        disabled={!hasBeenImported}
                        text={'Account Managers'}
                        isInputValid={
                            !hasBeenImported ||
                            (formState.accountManagerUserIds &&
                                formState.accountManagerUserIds.length > 0)
                        }
                    >
                        <Autocomplete
                            fullWidth
                            multiple
                            limitTags={2}
                            disabled={!hasBeenImported}
                            value={accountManagers.filter((user) =>
                                formState.accountManagerUserIds.includes(user.id)
                            )}
                            onChange={(
                                _: React.SyntheticEvent<Element, Event>,
                                newValue: User[] | null
                            ) => {
                                dispatch(
                                    updateFormState({
                                        accountManagerUserIds: newValue
                                            ? newValue.map((user) => user.id)
                                            : [],
                                    })
                                )
                            }}
                            options={accountManagers}
                            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                            renderInput={(params) => (
                                <TextField {...params} label="" placeholder="" />
                            )}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.firstName} {option.lastName}
                                </Box>
                            )}
                        />
                    </InputAutocompleteSection>

                    <InputAutocompleteSection
                        disabled={!hasBeenImported}
                        text={'Applications Engineers'}
                        isInputValid={
                            !hasBeenImported ||
                            (formState.applicationsEngineerUserIds &&
                                formState.applicationsEngineerUserIds.length > 0)
                        }
                    >
                        <Autocomplete
                            fullWidth
                            multiple
                            limitTags={2}
                            disabled={!hasBeenImported}
                            value={applicationsEngineers.filter((user) =>
                                formState.applicationsEngineerUserIds.includes(user.id)
                            )}
                            onChange={(
                                _: React.SyntheticEvent<Element, Event>,
                                newValue: User[] | null
                            ) => {
                                dispatch(
                                    updateFormState({
                                        applicationsEngineerUserIds: newValue
                                            ? newValue.map((user) => user.id)
                                            : [],
                                    })
                                )
                            }}
                            options={applicationsEngineers}
                            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                            renderInput={(params) => (
                                <TextField {...params} label="" placeholder="" />
                            )}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.firstName} {option.lastName}
                                </Box>
                            )}
                        />
                    </InputAutocompleteSection>

                    <InputAutocompleteSection
                        text={'Project Managers'}
                        isInputValid
                        disabled={!hasBeenImported}
                    >
                        <Autocomplete
                            fullWidth
                            multiple
                            limitTags={2}
                            disabled={!hasBeenImported}
                            value={projectManagers.filter((user) =>
                                formState.projectManagerUserIds.includes(user.id)
                            )}
                            onChange={(
                                _: React.SyntheticEvent<Element, Event>,
                                newValue: User[] | null
                            ) => {
                                dispatch(
                                    updateFormState({
                                        projectManagerUserIds: newValue
                                            ? newValue.map((user) => user.id)
                                            : [],
                                    })
                                )
                            }}
                            options={projectManagers}
                            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                            renderInput={(params) => (
                                <TextField {...params} label="" placeholder="" />
                            )}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.firstName} {option.lastName}
                                </Box>
                            )}
                        />
                    </InputAutocompleteSection>

                    {formState.customer && (
                        <InputSection text={'Credit'} disabled={!hasBeenImported}>
                            <Box sx={{ display: 'flex', gap: '15px' }}>
                                <Tooltip title="limit - balance">
                                    <Box
                                        sx={{
                                            background: '#eee',
                                            padding: '5px 10px',
                                            borderRadius: '10px',
                                        }}
                                    >
                                        <span style={{ marginRight: '10px' }}>Balance</span>
                                        {formState.customer.balance}
                                    </Box>
                                </Tooltip>

                                <Box
                                    sx={{
                                        background: '#eee',
                                        padding: '5px 10px',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <span style={{ marginRight: '10px' }}>Limit</span>
                                    {formState.customer.creditLimit}
                                </Box>
                                <Box
                                    sx={{
                                        background: '#eee',
                                        padding: '5px 10px',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <span style={{ marginRight: '10px' }}>On hold</span>
                                    {formState.customer.accountIsOnHold ? 'Yes' : 'No'}
                                </Box>
                            </Box>
                        </InputSection>
                    )}

                    <InputAutocompleteSection
                        text={'Clients'}
                        isInputValid={true}
                        disabled={!hasBeenImported}
                    >
                        <OrganisationsAutocomplete
                            disabled={!hasBeenImported}
                            selectedOrganisationsMap={formState.clientOrganisationIds.map(
                                (orgId) => selectedOrganisationsMap[orgId]
                            )}
                            onChangeSelectedOrganizations={async (organizations) => {
                                await dispatch(addOrganizations(organizations))
                                dispatch(
                                    updateFormState({
                                        clientOrganisationIds: organizations.map((org) => org.id),
                                    })
                                )
                            }}
                        />
                    </InputAutocompleteSection>

                    <InputAutocompleteSection
                        text={'Consultants'}
                        isInputValid={true}
                        disabled={!hasBeenImported}
                    >
                        <OrganisationsAutocomplete
                            disabled={!hasBeenImported}
                            selectedOrganisationsMap={formState.consultantOrganisationIds.map(
                                (orgId) => selectedOrganisationsMap[orgId]
                            )}
                            onChangeSelectedOrganizations={async (organizations) => {
                                await dispatch(addOrganizations(organizations))
                                dispatch(
                                    updateFormState({
                                        consultantOrganisationIds: organizations.map(
                                            (org) => org.id
                                        ),
                                    })
                                )
                            }}
                        />
                    </InputAutocompleteSection>

                    <InputAutocompleteSection
                        disabled={!hasBeenImported}
                        text={'Contractors'}
                        isInputValid={true}
                    >
                        <OrganisationsAutocomplete
                            disabled={!hasBeenImported}
                            selectedOrganisationsMap={formState.contractorOrganisationIds.map(
                                (orgId) => selectedOrganisationsMap[orgId]
                            )}
                            onChangeSelectedOrganizations={async (organizations) => {
                                await dispatch(addOrganizations(organizations))
                                dispatch(
                                    updateFormState({
                                        contractorOrganisationIds: organizations.map(
                                            (org) => org.id
                                        ),
                                    })
                                )
                            }}
                        />
                    </InputAutocompleteSection>

                    <InputSection
                        disabled={!hasBeenImported}
                        text={'Customer'}
                        button={'+'}
                        onButtonClick={() => dispatch(showCustomerModal())}
                    >
                        <Input
                            className={'without-legend'}
                            fullWidth
                            disabled
                            value={
                                formState.customer
                                    ? `${formState.customer.reference} - ${formState.customer.name}`
                                    : 'None'
                            }
                            onChange={(e) =>
                                dispatch(
                                    updateFormState({
                                        consultantOrganisationIds: e.target.value,
                                    })
                                )
                            }
                        />
                    </InputSection>
                </Grid>
            </Grid>

            {/* MODALS */}
            <ConfirmationModal
                title={'Delete opportunity status'}
                text={`Are you sure you want to delete the opportunity status: ${snakeCaseToTitleCase(
                    showDeleteOpportunityStatusModal.opportunityStatus?.name ?? ' '
                )}?`}
                confirmIcon={<DeleteIcon style={{ marginRight: '4px' }} />}
                confirmText={'Delete'}
                isOpen={showDeleteOpportunityStatusModal.show}
                onClose={() =>
                    setShowDeleteOpportunityStatusModal({ show: false, opportunityStatus: null })
                }
                onConfirm={confirmDeletionOfOpportunityStatus}
            />
        </Box>
    )
}

export default ProjectInfo
