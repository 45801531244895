import { Box, styled } from '@mui/system'
import { Button, Checkbox, Input, Table } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import {
    deleteProjectStockItemRequest,
    getProjectStockItems,
} from '../../../../../../api/projects/stock-items'
import { Project, ProjectStockItem } from '../../../../../../models/models'
import { useAppSelector } from '../../../../../../store/hooks'
import { toastFailure, toastSuccess } from '../../../../../../util/toast'
import DeleteIcon from '@mui/icons-material/Delete'
import { ConfirmationModal } from '../../../../../ui/ConfirmationModal'
import UpdateProjectStockItemModal from '../stock-items/UpdateProjectStockItemModal'
import { ColumnsType } from 'antd/lib/table'

const InputsContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
}))

enum ActiveModal {
    Delete = 'Delete',
    Update = 'Update',
    None = 'None',
}

export default function StockItems() {
    const [stockItems, setStockItems] = useState<ProjectStockItem[]>([])
    const [code, setCode] = useState<string>('')
    const project: Project | null = useAppSelector(
        (state) => state.singleProjectView.projectBeingUpdated
    )
    const projectId = project!.id
    const [modalProjectStockItemId, setModalProjectStockItemId] = useState<number>()
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)
    const [amount, setAmount] = useState<number>(0)
    const [activeModal, setActiveModal] = useState<ActiveModal>(ActiveModal.None)
    const [showNotOnSalesOrders, setShowNotOnSalesOrders] = useState<boolean>(false)

    const filteredStockItems = useMemo(() => {
        return stockItems.filter((si) => {
            if (showNotOnSalesOrders && typeof si.used === 'number') {
                return si.amount > si.used
            } else {
                return true
            }
        })
    }, [stockItems, showNotOnSalesOrders])

    const columns = useMemo(() => {
        const columns: ColumnsType<ProjectStockItem> = [
            {
                title: 'Code',
                key: 'code',
                render: (projectStockItem: ProjectStockItem) => projectStockItem.stockItem.code,
            },
            {
                title: 'Name',
                key: 'name',
                render: (projectStockItem: ProjectStockItem) => projectStockItem.stockItem.name,
            },
            {
                title: 'Description',
                key: 'description',
                render: (projectStockItem: ProjectStockItem) =>
                    projectStockItem.stockItem.description,
            },
        ]

        if (showNotOnSalesOrders) {
            columns.push({
                title: 'Quantity',
                dataIndex: 'amount',
                key: 'amount',
            })
        } else {
            columns.push(
                ...[
                    {
                        title: 'Quantity',
                        dataIndex: 'amount',
                        key: 'amount',
                    },
                    {
                        title: 'Amount on sales-orders',
                        dataIndex: 'used',
                        key: 'used',
                    },
                ]
            )
        }

        columns.push({
            title: 'Actions',
            key: 'actions',
            render: (projectStockItem: ProjectStockItem) => {
                return (
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Button
                            onClick={() => {
                                setActiveModal(ActiveModal.Update)
                                setModalProjectStockItemId(projectStockItem.id)
                            }}
                        >
                            Update
                        </Button>
                        {projectStockItem.used === 0 && (
                            <Button
                                onClick={() => {
                                    setActiveModal(ActiveModal.Delete)
                                    setModalProjectStockItemId(projectStockItem.id)
                                }}
                            >
                                Delete
                            </Button>
                        )}
                    </Box>
                )
            },
        })
        return columns
    }, [showNotOnSalesOrders])

    const deleteProjectStockItem = async (projectStockItemId: number) => {
        const response = await deleteProjectStockItemRequest(projectStockItemId)
        if (response.successful) {
            toastSuccess('Deleted Project stock item')
            retrieveAndSetStockItems()
        } else {
            toastFailure(response.message)
        }
    }
    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const retrieveAndSetStockItems = async () => {
        const response = await getProjectStockItems(projectId, {
            stockItem: true,
            used: true,
            withCount: true,
            //mui tables pages are zero-indexed
            skip: showNotOnSalesOrders ? undefined : page * rowsPerPage - rowsPerPage,
            limit: showNotOnSalesOrders ? undefined : rowsPerPage,
            code: code,
        })
        if (response.successful) {
            setStockItems(response.data.projectStockItems)
            setAmount(response.data.count ?? 0)
        } else {
            toastFailure(response.message)
        }
    }

    useEffect(() => {
        retrieveAndSetStockItems()
    }, [code, activeModal, page, rowsPerPage])

    return (
        <Box sx={{ marginTop: '20px', width: '100% !important' }}>
            <InputsContainer>
                <Input
                    prefix={'Code:'}
                    size="middle"
                    style={{
                        padding: 10,
                        borderRadius: '10px',
                        width: '25%',
                        margin: '10px',
                    }}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: '10px',
                    }}
                >
                    <Checkbox
                        style={{
                            margin: '10px',
                        }}
                        checked={showNotOnSalesOrders}
                        onChange={(e) => setShowNotOnSalesOrders(e.target.checked)}
                    >
                        Show not on sales-orders
                    </Checkbox>
                </div>
            </InputsContainer>
            <Table
                className="product-tables"
                style={{ width: '100%' }}
                dataSource={filteredStockItems}
                columns={columns}
                size="small"
                pagination={
                    showNotOnSalesOrders
                        ? false
                        : {
                              defaultPageSize: 10,
                              current: page,
                              onChange: pageChange,
                              total: amount,
                          }
                }
            />

            {modalProjectStockItemId && activeModal === ActiveModal.Delete && (
                <ConfirmationModal
                    title={'Delete project stock item'}
                    text={`Are you sure you to delete the project stock item with id: ${modalProjectStockItemId}?`}
                    confirmIcon={<DeleteIcon style={{ marginRight: '4px' }} />}
                    confirmText={'Delete'}
                    isOpen={!!modalProjectStockItemId && activeModal === ActiveModal.Delete}
                    onClose={() => {
                        setActiveModal(ActiveModal.None)
                        setModalProjectStockItemId(undefined)
                    }}
                    onConfirm={() => deleteProjectStockItem(modalProjectStockItemId)}
                />
            )}
            {modalProjectStockItemId && activeModal === ActiveModal.Update && (
                <UpdateProjectStockItemModal
                    projectStockItemId={modalProjectStockItemId}
                    projectId={projectId}
                    isOpen={true}
                    closeModal={() => {
                        setActiveModal(ActiveModal.None)
                        setModalProjectStockItemId(undefined)
                    }}
                />
            )}
        </Box>
    )
}
