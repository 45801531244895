import { ErrorSharp, InboxOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    FormControlLabel,
    List,
    Step,
    StepLabel,
    Stepper,
    styled,
    Switch,
    Tab,
    Tabs,
    Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { Input, Space } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { useEffect, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import {
    attachFileToBugReport,
    CreateBugReportDTO,
    createBugReport,
    getUsersBugReport,
    IssueType,
    updateBugReport,
    BugReportDTO,
    getAllBugReports,
} from '../../api/bug-reporting'
import { User, UserRole } from '../../models/models'
import { useAppSelector } from '../../store/hooks'
import CTRLoader from '../ui/loader/CTRloader'
import { toastFailure, toastSuccess } from '../../util/toast'
import { BugReportListItem } from './BugReportListItem'

type Props = {
    isOpen: boolean
    handleClose: () => void
    modalTransition?: any
}

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    )
}

const ButtonContainer = styled(Box)({
    display: 'flex',
    alignContent: 'space-between',
    justifyContent: 'space-between',
    marginTop: '1rem',
    flexDirection: 'row',
})

const NoBugsContainer = styled(Box)({
    textAlign: 'center',
    fontSize: '1rem',

    paddingTop: '25px',
    marginTop: '10px',
    flexDirection: 'column',
    fontStyle: 'italic',
})

//Consider moving this logic to the backenbd
export const generateText = (description?: string, device?: string, createdByUser?: User) => {
    return `${description}
    \n\n
    Device: ${device}
    \n\n
    ${createdByUser && `Created by: ${createdByUser.firstName} ${createdByUser.lastName}`}
    `
}

export interface BugReport {
    summary?: string
    description?: string
    device?: string
}

const UploadArea = (props: { onDrop: (files: File[]) => void; bugReportingFiles: File[] }) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (files: File[]) => props.onDrop(files),
        maxFiles: 5,
        minSize: 1,
    })

    return (
        <Box
            {...getRootProps()}
            sx={{
                background: '#F0F2F5',
                height: '150px',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                padding: '20px',
            }}
        >
            <InboxOutlined style={{ fontSize: 50, color: '#3500A0', paddingBottom: '20px' }} />
            <Typography style={{ textAlign: 'center' }}>
                Click or drag file to this area to upload
            </Typography>

            {props.bugReportingFiles && (
                <Typography sx={{ color: '#3500A0', paddingTop: '10px', textAlign: 'center' }}>
                    {props.bugReportingFiles.map((file) => {
                        return <p key={file.name}>{file.name}</p>
                    })}
                </Typography>
            )}

            <input {...getInputProps()} />
        </Box>
    )
}

const steps = ['Describe the issue', 'Attach videos or screenshots']

export default function BugReporting({ isOpen, handleClose, modalTransition }: Props) {
    const [value, setValue] = React.useState(0)

    const { user } = useAppSelector((state) => state.authentication)

    const getBugReport = async () => {
        setIsLoading(true)

        const bugReportResponse = isDisplayingAllBugs
            ? await getAllBugReports()
            : await getUsersBugReport()

        setIsLoading(false)
        if (bugReportResponse.successful) {
            setBugReports(bugReportResponse.data)
        }
    }

    const clearState = () => {
        setBugReportState({ bugreport: {} })
        setCreatedBugReportId(null)
        setBugReportingFiles([])
    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const [bugReports, setBugReports] = React.useState<BugReportDTO[]>([])
    const [bugReportState, setBugReportState] = React.useState<{
        bugreport: BugReport
    }>({ bugreport: {} })
    const [bugReportingFiles, setBugReportingFiles] = React.useState<Array<File>>([])
    const [activeStep, setActiveStep] = React.useState(0)
    const [createdBugReportId, setCreatedBugReportId] = React.useState<number | null>(null)
    const [isLoading, setIsLoading] = React.useState(false)
    const [isDisplayingAllBugs, setIsDisplayingAllBugs] = React.useState(false)

    useEffect(() => {
        getBugReport()
    }, [isDisplayingAllBugs])

    const onChangeBugReport = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const key = event.target.name
        const value = event.target.value
        setBugReportState({
            ...bugReportState,
            bugreport: { ...bugReportState.bugreport, [key]: value },
        })
    }

    const onSubmitBugReport = async () => {
        const { bugreport } = bugReportState
        const requestBody: CreateBugReportDTO = {
            summary: bugreport.summary ?? '',
            description: generateText(bugreport.description, bugreport.device, user ?? undefined),
            issueType: IssueType.Bug,
        }
        setIsLoading(true)
        const bugReportResponse = !createdBugReportId
            ? await createBugReport(requestBody)
            : await updateBugReport(requestBody, createdBugReportId)
        setIsLoading(false)
        if (bugReportResponse.successful) {
            toastSuccess(
                createdBugReportId
                    ? 'Successfully updated bug report '
                    : 'Successfully created bug report'
            )
            setCreatedBugReportId(bugReportResponse.data.id)
            setActiveStep(1)
        } else {
            toastFailure('Something went wrong ')
        }
    }

    const onSubmitAttachmentForBugReport = async () => {
        const requestFormData = new FormData()

        for (const file in bugReportingFiles) {
            requestFormData.append('files', bugReportingFiles[file])
        }
        setIsLoading(true)
        const bugReportResponse = await attachFileToBugReport(requestFormData, createdBugReportId)
        setIsLoading(false)
        if (bugReportResponse.successful) {
            toastSuccess('Successfully Added images to jira issue')
            getBugReport()
            clearState()
        } else {
            toastFailure('Something went wrong ')
        }
    }

    const isValidBugReportSubmit = useMemo(() => {
        return (
            Object.keys(bugReportState.bugreport).length > 2 &&
            Object.values(bugReportState.bugreport).every((entry) => {
                return entry.length > 4
            })
        )
    }, [bugReportState])

    const isValidUser = user && [UserRole.SavAdmin, UserRole.SystemAdmin].includes(user.role)
    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                TransitionComponent={modalTransition}
                maxWidth="lg"
            >
                <DialogTitle>Bug reporting</DialogTitle>

                <Tabs value={value} onChange={handleChange} style={{ padding: '0 20px' }}>
                    {' '}
                    <Tab label="Create Issue" />
                    <Tab label="See all issues" />
                </Tabs>

                <DialogContent style={{ overflow: 'auto' }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Suppose you experience any bugs while using CTR. You can report them here.
                        All bugs will be brought to the development team and resolved as quickly as
                        possible. Depending on the severity of the bug, you can add a
                        &quot;priority&quot;, but please only use <b>&quot;high &quot; </b> and{' '}
                        <b>&quot;highest&quot;</b> for very critical bugs. All bugs ask you to fill
                        out a particular template, including the following questions
                    </Typography>
                    <TabPanel value={value} index={0}>
                        <br />
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {}
                                const labelProps: {
                                    optional?: React.ReactNode
                                } = {}

                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                )
                            })}
                        </Stepper>
                        <br />
                        {activeStep === 0 ? (
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    <Input
                                        placeholder="Summary"
                                        name="summary"
                                        onChange={(e) => {
                                            onChangeBugReport(e)
                                        }}
                                        value={bugReportState.bugreport.summary}
                                    />
                                    <TextArea
                                        rows={6}
                                        name="description"
                                        placeholder="
                            Describe in steps how the bug occured
                            EG: 
                            1. From the project master screen i clicked the Call Off tab 
                            2. XXX         "
                                        onChange={(e) => {
                                            onChangeBugReport(e)
                                        }}
                                        value={bugReportState.bugreport.description}
                                    />
                                    <Input
                                        name="device"
                                        placeholder="What device and browser did you use? Eg: Windows desktop computer using Chrome"
                                        onChange={(e) => {
                                            onChangeBugReport(e)
                                        }}
                                        value={bugReportState.bugreport.device}
                                    />
                                </Space>
                                <br />
                                <br />
                                <LoadingButton
                                    variant="contained"
                                    onClick={onSubmitBugReport}
                                    disabled={!isValidBugReportSubmit}
                                    loading={isLoading}
                                >
                                    Next step
                                </LoadingButton>
                            </Box>
                        ) : (
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <UploadArea
                                    onDrop={setBugReportingFiles}
                                    bugReportingFiles={bugReportingFiles}
                                />
                                <ButtonContainer>
                                    <Button variant="contained" onClick={() => setActiveStep(0)}>
                                        Go back
                                    </Button>
                                    <LoadingButton
                                        variant="contained"
                                        onClick={onSubmitAttachmentForBugReport}
                                        size="large"
                                        loading={isLoading}
                                    >
                                        Submit
                                    </LoadingButton>
                                </ButtonContainer>
                            </Box>
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {/* Only admin users should be able to see check all*/}
                        {isValidUser && (
                            <FormControlLabel
                                value={isDisplayingAllBugs}
                                onChange={(event, checked) => {
                                    setIsDisplayingAllBugs(checked)
                                }}
                                style={{
                                    padding: '10px',
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    fontWeight: 'bold',
                                }}
                                control={<Switch color="primary" />}
                                label={
                                    isDisplayingAllBugs ? 'Show only my issues' : 'Show all issues'
                                }
                                labelPlacement="end"
                            />
                        )}
                        <List
                            sx={{
                                bgcolor: 'background.paper',
                                overflow: 'auto',
                                height: '55vh',
                                overflowX: 'hidden',
                            }}
                        >
                            {isLoading ? (
                                <CTRLoader />
                            ) : bugReports.length === 0 ? (
                                <NoBugsContainer>
                                    No Reports yet. Click the other tab to start reporting bugs{' '}
                                </NoBugsContainer>
                            ) : (
                                bugReports.map((bugReport, index) => {
                                    return (
                                        <BugReportListItem
                                            bugReport={bugReport}
                                            key={bugReport.id}
                                            lastIndex={index === bugReports.length - 1}
                                        />
                                    )
                                })
                            )}
                        </List>
                    </TabPanel>
                </DialogContent>
            </Dialog>
        </div>
    )
}
