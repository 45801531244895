import Divider from '@mui/material/Divider'
import { styled } from '@mui/styles'
import { ProjectStockItem } from '../../../../../models/models'
import { useState } from 'react'
import { Button, Input, Modal } from 'antd'
import { FieldContainer } from '../../../../ui/FIeldContainer'
import { Label } from '../../../../ui/Label'
import { PlusOutlined } from '@ant-design/icons'

const InputContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    margin: '20px',
})

interface CommentsProps {
    projectStockItem: ProjectStockItem
    closeModal: () => void
    addProjectStockItem: (
        projectStockItem: ProjectStockItem,
        amount: number,
        unitDiscount: number,
        unitPrice: number
    ) => void
}

export default function AddStockItemModal(props: CommentsProps) {
    const { closeModal, projectStockItem, addProjectStockItem } = props
    const [amount, setAmount] = useState<string>('')
    const [unitPrice, setUnitPrice] = useState<string>('')
    const [unitDiscount, setUnitDiscount] = useState<string>('')

    const disableSubmit = () => {
        return !amount || Number(amount) < 0.1
    }
    return (
        <Modal open={true} footer={null} onCancel={() => closeModal()} width={400}>
            <div
                style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: '15px 15px 10px 23px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div> Add stock item </div>
            </div>
            <Divider />
            <InputContainer>
                <FieldContainer>
                    <Label>Amount</Label>
                    <Input
                        placeholder="Amount of units"
                        autoComplete="off"
                        type="number"
                        value={amount}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (
                                projectStockItem.used !== undefined &&
                                Number(e.target.value) >
                                    projectStockItem.amount - projectStockItem.used
                            ) {
                                setAmount(
                                    (projectStockItem.amount - projectStockItem.used).toString()
                                )
                            } else {
                                setAmount(e.target.value)
                            }
                        }}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Label>Price</Label>
                    <Input
                        placeholder="Price per unit"
                        addonAfter="£"
                        type="number"
                        value={unitPrice}
                        onChange={(e) => {
                            setUnitPrice(e.target.value)
                        }}
                    />
                </FieldContainer>
            </InputContainer>
            <div style={{ textAlign: 'right', padding: '15px 23px 15px 0px' }}>
                <Button
                    type="primary"
                    disabled={disableSubmit()}
                    onClick={() => {
                        addProjectStockItem(
                            projectStockItem,
                            Number(amount),
                            Number(unitDiscount) || 0,
                            Number(unitPrice)
                        )
                        closeModal()
                    }}
                    style={{
                        paddingRight: '16px',
                    }}
                    icon={<PlusOutlined style={{ marginRight: '4px' }} />}
                >
                    Add
                </Button>
            </div>
        </Modal>
    )
}
