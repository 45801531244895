import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import {
    Project,
    ProjectDocument,
    ProjectDocumentType,
    ProjectDocumentVersion,
    User,
} from '../models/models'
import {
    archiveDocumentVersionRequest,
    createDocumentRequest,
    createDocumentVersionRequest,
    projectDocumentsRequest,
    projectDocumentTypesRequest,
    submitDocumentVersionRequest,
    updateDocumentVersionRequest,
    uploadDocumentVersionFile,
} from '../api/projects/documents'
import { toastFailure, toastSuccess } from '../util/toast'
import { startLoading, stopLoading } from './single-project-view-slice'
import { RootState } from './store'

export interface ModalState {
    isVisible: boolean
    newVersionOf: ProjectDocument | null
    selectedFile: File | null
    selectedTypeId: number | null
    isLoading: boolean
    comment: string

    isUpdatingComment: boolean
    documentVersion: ProjectDocumentVersion | null
}

const initialModalState: ModalState = {
    isVisible: false,
    newVersionOf: null,
    selectedFile: null,
    selectedTypeId: null,
    comment: '',
    isLoading: false,

    isUpdatingComment: false,
    documentVersion: null,
}

export interface State {
    documentTypes: ProjectDocumentType[]
    documents: ProjectDocument[]
    modal: ModalState
    typeFilter: number[]
    userFilter: number[]
}

const initialState: State = {
    documentTypes: [],
    documents: [],
    modal: initialModalState,
    typeFilter: [],
    userFilter: [],
}

export const projectDocumentSlice = createSlice({
    name: 'projectDocument',
    initialState,
    reducers: {
        setDocumentTypes(state, action: PayloadAction<ProjectDocumentType[]>) {
            state.documentTypes = action.payload
        },
        addDocumentType(state, action: PayloadAction<ProjectDocumentType>) {
            state.documentTypes.push(action.payload)
        },
        setDocuments(state, action: PayloadAction<ProjectDocument[]>) {
            state.documents = action.payload
        },
        updateDocumentState(state, action: PayloadAction<ProjectDocument>) {
            const existingDocument = state.documents.find(
                (document) => document.id === action.payload.id
            )

            if (existingDocument) {
                existingDocument.versions = action.payload.versions
            } else {
                state.documents.push(action.payload)
            }
        },
        setSelectedFile(state, action: PayloadAction<File | null>) {
            state.modal.selectedFile = action.payload
        },
        setSelectedTypeId(state, action: PayloadAction<number>) {
            state.modal.selectedTypeId = action.payload
        },
        setComment(state, action: PayloadAction<string>) {
            state.modal.comment = action.payload
        },
        setModalLoading(state, action: PayloadAction<boolean>) {
            state.modal.isLoading = action.payload
        },
        startUploadingDocument(state, action: PayloadAction<ProjectDocument | null>) {
            const newState = {
                ...initialModalState,
                isVisible: true,
                selectedTypeId: state.documentTypes.length ? state.documentTypes[0].id : null,
            }

            if (action.payload) {
                newState.newVersionOf = action.payload
                newState.selectedTypeId = action.payload.type.id
            }

            state.modal = newState
        },
        closeUploadModal(state) {
            state.modal.isVisible = false
        },
        setUserFilter(state, action: PayloadAction<number[]>) {
            state.userFilter = action.payload
        },
        setTypeFilter(state, action: PayloadAction<number[]>) {
            state.typeFilter = action.payload
        },
        startUpdatingComment(state, action: PayloadAction<ProjectDocumentVersion>) {
            state.modal = {
                ...initialModalState,
                comment: action.payload.comment,
                documentVersion: action.payload,
                isVisible: true,
                isUpdatingComment: true,
            }
        },
    },
})

export const {
    setDocuments,
    setDocumentTypes,
    addDocumentType,
    setSelectedFile,
    setSelectedTypeId,
    setComment,
    startUploadingDocument,
    closeUploadModal,
    updateDocumentState,
    setModalLoading,
    setUserFilter,
    setTypeFilter,
    startUpdatingComment,
} = projectDocumentSlice.actions

export const fetchDocumentTypes = () => async (dispatch: Dispatch) => {
    await dispatch(startLoading())

    const documentsTypesResponse = await projectDocumentTypesRequest()

    if (!documentsTypesResponse.successful) {
        toastFailure('An error occurred while retrieving the project document types.')
        return
    }

    await dispatch(setDocumentTypes(documentsTypesResponse.data))
    await dispatch(stopLoading())
}

export const fetchDocuments = (project: Project) => async (dispatch: Dispatch) => {
    await dispatch(startLoading())
    const documentsResponse = await projectDocumentsRequest(project.id)

    if (!documentsResponse.successful) {
        toastFailure('An error occurred while retrieving the project documents.')
        return
    }

    await dispatch(setDocuments(documentsResponse.data))
    await dispatch(stopLoading())
}

export const isModalValidView = (state: RootState) => {
    const modalState = state.projectDocuments.modal

    if (modalState.isUpdatingComment) {
        return true
    }

    return modalState.selectedFile !== null && modalState.selectedTypeId !== null
}

export const typeFilterOptionsView = (state: RootState) => {
    return [...state.projectDocuments.documentTypes]
}

export const userFilterOptionsView = (state: RootState) => {
    const users: { [key: number]: User } = {}
    state.projectDocuments.documents.forEach((document) => {
        document.versions.forEach((version) => {
            if (version.creatorUser) {
                users[version.creatorUser.id] = version.creatorUser
            }
        })
    })

    return Object.values(users)
}

export const submitModal = () => async (dispatch: Dispatch, getState: () => RootState) => {
    const modalState = getState().projectDocuments.modal
    const project =
        getState().singleProjectView.projectBeingUpdated ||
        getState().singleProjectView.projectMatchingOpportunityReference

    if (modalState.isUpdatingComment && modalState.documentVersion) {
        await dispatch(setModalLoading(true))

        const updateResponse = await updateDocumentVersionRequest(
            modalState.documentVersion.id,
            modalState.comment
        )

        if (!updateResponse.successful) {
            toastFailure('An error occurred while updating the comment.')
            return
        }

        dispatch(updateDocumentState(updateResponse.data))

        toastSuccess('The comment was successfully updated.')

        await dispatch(setModalLoading(false))
        await dispatch(closeUploadModal())

        return
    }

    await dispatch(setModalLoading(true))

    let document = modalState.newVersionOf
    const documentTypeId = modalState.selectedTypeId

    if (!document) {
        const createDocumentResponse = await createDocumentRequest(project?.id, documentTypeId)

        if (!createDocumentResponse.successful) {
            toastFailure('Failed to create new document.')
            await dispatch(setModalLoading(false))
            return
        }

        document = createDocumentResponse.data
    }

    const createDocumentVersionResponse = await createDocumentVersionRequest(
        document,
        modalState.comment
    )

    if (!createDocumentVersionResponse.successful) {
        toastFailure('Failed to create new document version.')
        await dispatch(setModalLoading(false))
        return
    }

    const uploadFileResponse = await uploadDocumentVersionFile(
        createDocumentVersionResponse.data.id,
        modalState.selectedFile!
    )

    if (!uploadFileResponse.successful) {
        toastFailure('Failed to upload file.')
        await dispatch(setModalLoading(false))
        return
    }

    await dispatch(updateDocumentState(uploadFileResponse.data))
    await dispatch(setModalLoading(false))
    await dispatch(closeUploadModal())

    toastSuccess('The document successfully uploaded.')
}

export const submitDocumentVersion =
    (documentVersion: ProjectDocumentVersion) => async (dispatch: Dispatch) => {
        const submitResponse = await submitDocumentVersionRequest(documentVersion.id)
        if (!submitResponse.successful) {
            toastFailure('An error occurred while submitting document.')
            return
        }

        dispatch(updateDocumentState(submitResponse.data))
    }

export const archiveDocumentVersion =
    (documentVersion: ProjectDocumentVersion) => async (dispatch: Dispatch) => {
        const submitResponse = await archiveDocumentVersionRequest(documentVersion.id)
        if (!submitResponse.successful) {
            toastFailure('An error occurred while archiving document.')
            return
        }

        dispatch(updateDocumentState(submitResponse.data))
    }

export const deleteComment =
    (documentVersion: ProjectDocumentVersion) => async (dispatch: Dispatch) => {
        const submitResponse = await updateDocumentVersionRequest(documentVersion.id, null)
        if (!submitResponse.successful) {
            toastFailure('An error occurred while deleting the comment.')
            return
        }

        dispatch(updateDocumentState(submitResponse.data))
    }

export default projectDocumentSlice.reducer
