import { updateGeneralAssemblyLine } from '../../../../store/assembly-worksheets-slice'
import { styled } from '@mui/material/styles'
import { useAppDispatch } from '../../../../store/hooks'
import _ from 'lodash'
import { TableTextareaInput, TableTextInput } from '../active-assembly/TableInputs'
import { GeneralAssembly, GeneralAssemblyLine, User } from '../../../../models/models'
import { Table } from 'antd'
import moment from 'moment'
import ActiveGeneralAssemblyTableHeader from './ActiveGeneralAssemblyTableHeader'

const StatusButton = styled('button')`
    padding: 10px 10px;
    background: #fff;
    border-radius: 10px;
    outline: none;
    border-width: 1px;
`

const CompletedButton = styled(StatusButton)`
    color: #146a5e;
    border-color: #146a5e;
    background: rgba(20, 106, 94, 0.02);
`

const PendingButton = styled(StatusButton)`
    color: #3500a0;
    border-color: #3500a0;
    background: rgba(53, 0, 160, 0.02);
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

const Link = styled('a')`
    color: #3500a0;
    text-decoration: underline;
    font-weight: 500;
`

interface Props {
    generalAssembly: GeneralAssembly
    user: User
}

function ActiveGeneralAssemblyLinesTable({ generalAssembly, user }: Props) {
    const dispatch = useAppDispatch()

    const isSerialEditable = (line: GeneralAssemblyLine) => {
        return !generalAssembly.completedAt && !line.completedAt
    }
    const isNoteEditable = (line: GeneralAssemblyLine) => {
        return !generalAssembly.completedAt && !line.completedAt
    }

    const columns = [
        {
            title: 'BOM',
            key: 'bom',
            render: (line: GeneralAssemblyLine) => {
                return generalAssembly.builtItem.bomCode
            },
        },
        {
            title: 'Serial',
            key: 'serial',
            render: (line: GeneralAssemblyLine) => {
                return (
                    <TableTextInput
                        active={isSerialEditable(line)}
                        disallowEmpty={!!line.completedAt}
                        value={line.serial}
                        onSave={async (value, afterSave) => {
                            await dispatch(updateGeneralAssemblyLine(line, { serial: value }))
                            afterSave()
                        }}
                    />
                )
            },
        },
        {
            title: 'Note',
            key: 'note',
            render: (line: GeneralAssemblyLine) => {
                return (
                    <TableTextareaInput
                        active={isNoteEditable(line)}
                        placeholder="Additional details"
                        value={line.note}
                        maxLength={60}
                        maxLengthError={`The note must not be longer than 60 characters long.`}
                        noNewlines
                        onSave={async (value, afterSave) => {
                            await dispatch(updateGeneralAssemblyLine(line, { note: value }))
                            afterSave()
                        }}
                    />
                )
            },
        },
        {
            title: 'Assembled By',
            key: 'assembled',
            render: (line: GeneralAssemblyLine) => {
                return line.assembledBy
                    ? `${line.assembledBy.firstName} ${line.assembledBy.lastName}`
                    : 'None'
            },
        },
        {
            title: 'Complete',
            key: 'date',
            render: (line: GeneralAssemblyLine) => {
                return line.completedAt ? (
                    <CompletedButton>{moment(line.completedAt).format('LLL')}</CompletedButton>
                ) : (
                    <PendingButton
                        onClick={async () => {
                            await dispatch(
                                updateGeneralAssemblyLine(line, { completedAt: new Date() })
                            )
                        }}
                    >
                        Mark complete
                    </PendingButton>
                )
            },
        },
    ]

    return (
        <>
            <ActiveGeneralAssemblyTableHeader generalAssembly={generalAssembly} />
            <Table
                dataSource={[...generalAssembly.lines].sort((a, b) => a.id - b.id)}
                pagination={false}
                columns={columns}
            />
        </>
    )
}

export default ActiveGeneralAssemblyLinesTable
