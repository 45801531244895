import { styled } from '@mui/material'
import { Checkbox, Select } from 'antd'
import { Project, User, UserRole, Warehouse, WarehouseId } from '../../../models/models'
import moment from 'moment'
import { formatEnumName } from '../../../util/util'
import { useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
    setFilterStartDate,
    setFilterEndDate,
    setFilterWarehouseId,
    setFilterProjectIds,
    setFilterAssemblerId,
    setFilterIsOnHold,
    setFilterStatus,
} from '../../../store/assembly-worksheets-slice'
import DatePicker from '../../ui/calendar/DatePicker'

const { RangePicker } = DatePicker

const Container = styled('div')`
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    user-select: none;
    gap: 10px;

    .right-border-radius {
        .ant-select {
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
        }

        .ant-select-selector {
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
        }
    }

    .left-border-radius {
        .ant-select {
            border-top-left-radius: 5px !important;
            border-bottom-left-radius: 5px !important;
        }

        .ant-select-selector {
            border-top-left-radius: 5px !important;
            border-bottom-left-radius: 5px !important;
        }
    }
`

const Item = styled('div')``

interface Props {
    user: User
    assemblers: User[]
    allWarehouses: Warehouse[]
    allProjects: Project[]
}

export const Filters = (props: Props) => {
    const {
        filterAssemblerId,
        filterStartDate,
        filterEndDate,
        filterIsOnHold,
        filterProjectIds,
        filterStatus,
        filterWarehouseId,
    } = useAppSelector((state) => state.assemblyWorksheets)
    const dispatch = useAppDispatch()
    const selectedAssembler = useMemo(() => {
        const assembler = props.assemblers.find((assembler) => assembler.id === filterAssemblerId)
        return assembler
    }, [filterAssemblerId])

    return (
        <Container>
            <Item>
                <RangePicker
                    size={'large'}
                    allowClear={false}
                    value={[moment.unix(filterStartDate), moment.unix(filterEndDate)]}
                    onChange={(values) => {
                        if (values) {
                            dispatch(setFilterStartDate(values[0]!))
                            dispatch(setFilterEndDate(values[1]!))
                        }
                    }}
                />
            </Item>

            <Checkbox
                checked={filterIsOnHold}
                onChange={(e) => dispatch(setFilterIsOnHold(e.target.checked))}
            >
                On hold only
            </Checkbox>

            <Item style={{ marginLeft: 'auto' }}>
                <Select
                    placeholder="Status"
                    value={filterStatus}
                    onChange={(status) => dispatch(setFilterStatus(status))}
                    size="large"
                    allowClear={true}
                    style={{ width: 250, marginRight: '15px' }}
                    optionFilterProp="children"
                >
                    <Select.Option key={undefined} value={undefined}>
                        Any
                    </Select.Option>
                    <Select.Option key={'NotStarted'} value={'NotStarted'}>
                        Not Started
                    </Select.Option>
                    <Select.Option key={'In Progress'} value={'InProgress'}>
                        In Progress
                    </Select.Option>
                    <Select.Option key={'Completed'} value={'Completed'}>
                        Completed
                    </Select.Option>
                </Select>
            </Item>

            <Item style={{ marginLeft: 'auto' }}>
                <Select
                    placeholder={'Filter by project'}
                    mode={'multiple'}
                    value={filterProjectIds}
                    onChange={(ids) => dispatch(setFilterProjectIds(ids))}
                    size={'large'}
                    allowClear={true}
                    style={{ width: 250, marginRight: '15px' }}
                    optionFilterProp={'children'}
                >
                    {props.allProjects.map((project) => (
                        <Select.Option key={project.id} value={project.id}>
                            {project.name}
                        </Select.Option>
                    ))}
                </Select>
            </Item>

            {props.user.role !== UserRole.Assembler && (
                <Item>
                    <Select
                        size={'large'}
                        style={{ width: 250, marginRight: '15px' }}
                        value={
                            selectedAssembler
                                ? `${selectedAssembler?.firstName} ${selectedAssembler?.lastName}`
                                : undefined
                        }
                        placeholder="Assembler"
                        onChange={(value) => dispatch(setFilterAssemblerId(Number(value)))}
                    >
                        <Select.Option key={undefined} value={undefined}>
                            {`Any`}
                        </Select.Option>
                        {props.assemblers.map((assembler) => (
                            <Select.Option key={assembler.id} value={assembler.id}>
                                {`${assembler?.firstName} ${assembler?.lastName}`}
                            </Select.Option>
                        ))}
                    </Select>
                </Item>
            )}

            <Item>
                <Select
                    size={'large'}
                    style={{ width: 250, marginRight: '15px' }}
                    value={filterWarehouseId}
                    placeholder="Warehouse"
                    onChange={(value) => dispatch(setFilterWarehouseId(value))}
                >
                    <Select.Option value={WarehouseId.Monument}>Monument</Select.Option>
                    <Select.Option value={WarehouseId.Scandia}>Scandia</Select.Option>
                </Select>
            </Item>
        </Container>
    )
}
