import { mainSAVTheme } from '../theme/Theme'
import { useAppSelector } from '../store/hooks'
import { authenticatedUserView } from '../store/authentication-slice'
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import PublicIcon from '@mui/icons-material/Public'
import { snakeCaseToTitleCase } from '../util/util'

export default function Profile() {
    const user = useAppSelector(authenticatedUserView)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} />

            <Grid item xs={2} />
            <Grid item xs={2}>
                <AccountCircleSharpIcon
                    style={{
                        fontSize: '200px',
                        color: `${mainSAVTheme.palette.primary.dark}`,
                    }}
                />
                <Divider />
            </Grid>
            <Grid item xs={8} />

            <Grid item xs={2} />
            <Grid item xs={2}>
                <Grid container direction="row" spacing={2} item>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <p style={{ fontWeight: 'bold', marginBottom: '6px' }}> Profile details </p>
                    </Grid>

                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        Name:
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: 'flex-start' }}>
                        {user?.firstName} {user?.lastName}
                    </Grid>

                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <MailOutlineIcon />
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: 'flex-start' }}>
                        {user?.email}
                    </Grid>

                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <PhoneIphoneIcon />
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: 'flex-start' }}>
                        {user?.phoneNumber}
                    </Grid>

                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <PublicIcon />
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: 'flex-start' }}>
                        {user?.country}
                    </Grid>

                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        Role:
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: 'flex-start' }}>
                        {snakeCaseToTitleCase(user?.role ?? '')}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={8} />
        </Grid>
    )
}
