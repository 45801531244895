import { InboxOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { FileRejection, useDropzone } from 'react-dropzone'

interface FileAreaProps {
    selectedFile?: File
    onDrop: (file: File) => void
    accepts?: string | string[] | undefined
}

export const FileArea = ({
    selectedFile,
    onDrop,
    accepts = ['application/xml', 'text/xml'],
}: FileAreaProps) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            onDrop(acceptedFiles[0])
        },
        maxFiles: 1,
        minSize: 1,
        multiple: false,
        accept: accepts,
    })

    return (
        <div
            {...getRootProps()}
            style={{
                background: '#F0F2F5',
                height: '150px',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                padding: '20px',
            }}
        >
            <InboxOutlined style={{ fontSize: 50, color: '#3500A0', paddingBottom: '20px' }} />
            <Typography style={{ textAlign: 'center' }}>
                Click or drag file to this area to upload
            </Typography>
            <input {...getInputProps()} />
        </div>
    )
}
