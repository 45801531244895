import { Bom } from '../models/models'
import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'

export enum BomRelation {
    Product = 'Product',
    BomGroup = 'BomGroup',
    BomComponents = 'BomComponents',
}

export type GetBuiltItemQueryParams = {
    relations?: BomRelation[]
    stockItems?: boolean
    excludeBomCode?: string
    bomCode?: string
    productName?: string
    bomGroupName?: string
    assemblyMinutes?: number
    instruction?: string
    ids?: number[]
    withCount?: boolean
    skip?: number
    limit?: number
    used?: number
}

export const getBomsRequest = (
    params: GetBuiltItemQueryParams
): Promise<Response<{ entities: Bom[]; count: number }>> =>
    backendConnection.request({
        url: `/boms`,
        method: 'GET',
        params,
    })

export interface GetByIdBuiltItemQueryParams {
    relations?: BomRelation[]
    withDeleted?: boolean
}

export const getBomById = (
    id: number,
    config?: GetByIdBuiltItemQueryParams
): Promise<Response<Bom>> =>
    backendConnection.request({
        url: `/boms/${id}`,
        method: 'GET',
        params: config,
    })

export const getBuiltItemByBomCode = (code: string): Promise<Response<Bom>> =>
    backendConnection.request({
        url: `/boms/code`,
        method: 'GET',
        params: { code },
    })

export const getBuiltItemBybomCode = (bomCode: string): Promise<Response<Bom>> =>
    backendConnection.request({
        url: `/boms/bom/${bomCode}`,
        method: 'GET',
    })

export interface UpdateBomDto {
    instructionLink?: string
    groupId?: number
    assemblyMinutes?: number
}

export const updateBomRequest = (id: number, dto: UpdateBomDto): Promise<Response<Bom>> =>
    backendConnection.request({
        url: `/boms/${id}`,
        method: 'PUT',
        data: dto,
    })

export interface UpdateManyBomsDto {
    excludeIds?: number[]
    filterId?: number
    excludeBomCode?: string
    filterBomCode?: string
    filterProductName?: string
    instructionLink?: string
    bomGroupId?: number
    assemblyMinutes?: number
}

export const updateManyBomsRequst = (
    dto: UpdateManyBomsDto
): Promise<Response<{ entities: Bom[]; count: number }>> =>
    backendConnection.request({
        url: `/boms`,
        method: 'PUT',
        data: dto,
    })
