import { styled, TextareaAutosize } from '@mui/material'
import { Checkbox, Input, InputNumber, Select } from 'antd'
import { useEffect, useState } from 'react'
import _, { trim } from 'lodash'
import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { toastFailure } from '../../../../util/toast'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

interface NumberInputProps {
    active: boolean
    operator?: string | null
    placeholder?: string
    error?: boolean
    value: number | null
    step: number
    onSave: (value: number | null, afterSave: () => void) => void
}
interface DropdownInputProps {
    active: boolean
    value: string | null
    options: string[]
    placeholder?: string
    error?: boolean
    onSave: (value: string | null, afterSave: () => void) => void
}

interface TextInputProps {
    active: boolean
    placeholder?: string
    value: string | null
    disallowEmpty?: boolean
    onSave: (value: string | null, afterSave: () => void) => void
    maxLength?: number
    maxLengthError?: string
}

interface TableTextareaInputProps {
    active: boolean
    placeholder?: string
    value: string | null
    disallowEmpty?: boolean
    onSave: (value: string | null, afterSave: () => void) => void
    maxLength?: number
    maxLengthError?: string
    noNewlines?: boolean
}

interface BooleanInputProps {
    active: boolean
    value: boolean | null
    onSave: (value: boolean | null, afterSave: () => void) => void
}

const Container = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 1px;
    width: 100%;
`

const EditIconContainer = styled('div')`
    pointer-events: none;
`

const StyledInput = styled(Input)`
    height: 34px;
    box-sizing: border-box;
    padding: 12px 5px;
    width: 100%;
    color: #000;
`

const StyledInputNumber = styled(InputNumber)`
    height: 34px;
    box-sizing: border-box;
    margin-right: 2px;
    margin-bottom: 2px;
    width: 100%;
`

function InputIcon(
    { isSaving, color }: { isSaving: boolean; color?: string | undefined } = { isSaving: false }
) {
    return (
        <EditIconContainer>
            {isSaving && <LoadingOutlined style={{ fontSize: 16, color: '#3500a0' }} />}
            {!isSaving && <EditOutlined style={{ fontSize: 16, color: color || '#ccc' }} />}
        </EditIconContainer>
    )
}

export function TableTextInput(props: TextInputProps) {
    const [value, setValue] = useState<string | null>(props.value)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [isInvalid, setIsInvalid] = useState<boolean>(false)

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    const save = () => {
        const nextValue = value === '' ? null : value

        const isInvalidlyEmpty = !value?.length && props.disallowEmpty
        const isInvalidlyTooLong =
            props.maxLength !== undefined && (value?.length || 0) > props.maxLength

        if (isInvalidlyEmpty || isInvalidlyTooLong) {
            setIsInvalid(true)
            if (isInvalidlyTooLong) {
                toastFailure(
                    props.maxLengthError
                        ? props.maxLengthError
                        : `The value must not be longer than ${props.maxLength} characters long.`
                )
            }

            return
        } else {
            setIsInvalid(false)
        }

        if (nextValue === props.value) {
            return
        }

        setIsSaving(true)
        props.onSave(nextValue, () => setIsSaving(false))
    }

    return (
        <Container>
            <StyledInput
                placeholder={'n/a'}
                disabled={!props.active}
                value={value || undefined}
                onChange={(e) => setValue(e.target.value)}
                onBlur={save}
                style={{
                    background: isInvalid ? '#ff7d7d' : 'initial',
                    color: isInvalid ? '#fff' : 'initial',
                    width: '90%',
                }}
            />
            {props.active && (
                <InputIcon color={isInvalid ? '#fff' : undefined} isSaving={isSaving} />
            )}
        </Container>
    )
}

export function TableTextareaInput(props: TableTextareaInputProps) {
    const [value, setValue] = useState<string | null>(props.value)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [isInvalid, setIsInvalid] = useState<boolean>(false)

    const save = () => {
        const nextValue = value === '' ? null : value
        const isInvalidlyEmpty = !value?.length && props.disallowEmpty
        const isInvalidlyTooLong =
            props.maxLength !== undefined && (value?.length || 0) > props.maxLength

        if (isInvalidlyEmpty || isInvalidlyTooLong) {
            setIsInvalid(true)
            if (isInvalidlyTooLong) {
                toastFailure(
                    props.maxLengthError
                        ? props.maxLengthError
                        : `The value must not be longer than ${props.maxLength} characters long.`
                )
            }

            return
        } else {
            setIsInvalid(false)
        }

        if (nextValue === props.value) {
            return
        }

        setIsSaving(true)
        props.onSave(nextValue, () => setIsSaving(false))
    }

    return (
        <Container>
            <TextareaAutosize
                placeholder={props.placeholder}
                disabled={!props.active}
                value={value || undefined}
                onChange={(e) => {
                    const value = !props.noNewlines
                        ? e.target.value
                        : e.target.value.replace(/\n/, '')

                    setValue(value)
                }}
                onBlur={save}
                style={{
                    resize: 'none',
                    boxSizing: 'border-box',
                    border: 'none',
                    width: '90%',
                    outline: 'none',
                    padding: '13px 30px 5px 10px',
                    minWidth: '250px',
                    cursor: !props.active ? 'not-allowed' : 'initial',
                    background: isInvalid ? '#ff7d7d' : '#fafafa',
                    color: isInvalid ? '#fff' : 'initial',
                }}
            />
            {props.active && <InputIcon isSaving={isSaving} />}
        </Container>
    )
}

export function TableNumericInput(props: NumberInputProps) {
    const [value, setValue] = useState<number | null>(props.value)
    const [isSaving, setIsSaving] = useState<boolean>(false)

    const save = () => {
        if (value === props.value) {
            return
        }

        setIsSaving(true)
        props.onSave(value, () => setIsSaving(false))
    }

    return (
        <Container>
            <StyledInputNumber
                type={'number'}
                style={{ width: '100%' }}
                value={props.value ?? undefined}
                step={props.step}
                status={props.error ? 'error' : undefined}
                prefix={props.error ? <ErrorOutlineOutlinedIcon /> : undefined}
                onBlur={save}
                placeholder={'n/a'}
                disabled={!props.active}
                onChange={(value) =>
                    setValue(_.isString(value) ? parseFloat(trim(value, ',.')) : value)
                }
            />
            {props.active && <InputIcon isSaving={isSaving} />}
        </Container>
    )
}

export function TableDropdownInput(props: DropdownInputProps) {
    const [isSaving, setIsSaving] = useState<boolean>(false)

    const save = (value: string | null) => {
        setIsSaving(true)
        props.onSave(value, () => setIsSaving(false))
    }

    return (
        <Container>
            <Select
                placeholder={'n/a'}
                size={'large'}
                style={{ width: '90%' }}
                value={props.value}
                allowClear
                disabled={!props.active}
                onChange={(value) => {
                    save(value)
                }}
            >
                {props.options.map((e) => {
                    return (
                        <Select.Option value={e} key={e}>
                            {e}
                        </Select.Option>
                    )
                })}
            </Select>
            {props.active && <InputIcon isSaving={isSaving} />}
        </Container>
    )
}

export function TableBooleanInput(props: BooleanInputProps) {
    const [isSaving, setIsSaving] = useState<boolean>(false)

    const save = (value: boolean | null) => {
        setIsSaving(true)
        props.onSave(value, () => setIsSaving(false))
    }

    return (
        <Container style={{ minWidth: '5    0px' }}>
            <Checkbox
                disabled={!props.active}
                checked={props.value === null ? undefined : props.value}
                onChange={(e) => save(e.target.checked)}
            />
        </Container>
    )
}
