import { CircularProgress, Dialog, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import { Button } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getPalletRequest } from '../../../api/warehouse-map'
import { Pallet } from '../../../models/models'
import { deletePallet } from '../../../store/warehouse-map-slice'
import { WarehouseMapColors } from '../../../theme/colors'
import { toastFailure } from '../../../util/toast'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
    isOpen: boolean
    onClose: () => void
    palletId: number
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

export const DeletePalletModal = ({ isOpen, onClose, palletId }: Props) => {
    const [pallet, setPallet] = useState<Pallet>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const dispatch = useDispatch()

    const handleDelete = () => {
        if (!pallet) {
            return
        }
        dispatch(deletePallet({ palletId: pallet.id, spotId: pallet.warehouseAreaSpotId }))
        onClose()
    }

    useEffect(() => {
        getPalletRequest(palletId).then((response) => {
            if (response.successful) {
                if (!response.data) {
                    toastFailure('Could not find the pallet')
                    setModalState(ModalState.NotFound)
                    onClose()
                }
                setModalState(ModalState.Success)
                setPallet(response.data)
            } else {
                if (response.status === 404) {
                    toastFailure('Could not find the pallet')
                    setModalState(ModalState.NotFound)
                    onClose()
                } else {
                    toastFailure('Something went wrong retrieving the pallet')
                    setModalState(ModalState.Error)
                    onClose()
                }
            }
        })
    }, [])
    return (
        <Dialog open={isOpen} onClose={onClose} style={{ zIndex: 100 }} fullWidth maxWidth={'md'}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '15px',
                }}
            >
                <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            {modalState === ModalState.Loading && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        margin: '20px',
                        width: '100%',
                        height: '500px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                </Box>
            )}
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.NotFound && (
                <Box>Could not find the pallet with id {palletId}</Box>
            )}
            {modalState === ModalState.Success && (
                <>
                    <Button
                        onClick={handleDelete}
                        style={{
                            color: 'white',
                            textTransform: 'none',
                            margin: '0px 23px',
                            backgroundColor: WarehouseMapColors.deleteButtonColor,
                        }}
                    >
                        <DeleteIcon style={{ marginRight: '4px' }} />
                        Delete pallet
                    </Button>
                </>
            )}
        </Dialog>
    )
}
