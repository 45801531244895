import { ChangeEvent, useEffect, useState } from 'react'
import { Box, styled } from '@mui/system'
import { Table, Input, Button } from 'antd'
import { Assembly } from '../../models/models'
import {
    AssemblyRelation,
    getAssembliesRequest,
    DateSearchType,
    SortBy,
    SortOrder,
} from '../../api/assemblies'
import { WarehouseId } from '../../models/models'
import { RootState } from '../../store/store'
import { toastFailure } from '../../util/toast'
import moment from 'moment'
import InspectAssemblyModal from '../modals/inspect-assembly-modal/InspectAssemblyModal'
import UpdateAssemblyModal from '../production-schedule/UpdateAssemblyModal'
import PageHeader from '../ui/PageHeader'
import { Urls } from '../../util/urls'
import CTRLoader from '../ui/loader/CTRloader'
import useDebounce from '../project-master/single-project-view/hooks'
import DeleteAssemblyModal from '../modals/delete-assembly-modal/DeleteAssemblyModal'
import DatePicker from '../ui/calendar/DatePicker'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
    setActiveModal,
    setAssemblies,
    setAssembliesAmount,
    setFilterAssemblyId,
    setFilterBomCode,
    setFilterCallOffId,
    setFilterDateRangeBegin,
    setFilterDateRangeEnd,
    setFilterDateSearchType,
    setFilterProjectName,
    setFilterSelectedwarehouseId,
    setFilterStatus,
    setIsLoading,
    setModalAssemblerId,
    setSortBy,
    setSortOrder,
} from '../../store/assemblies-slice'
import { FieldContainer } from '../ui/FIeldContainer'
import { Label } from '../ui/Label'
import Select from 'antd/lib/select'

const InputsContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0px 0px 10px 0px',
    gap: '5px',
}))

const Container = styled(Box)(() => ({
    margin: '25px',
}))

enum ActiveModal {
    None = 'None',
    Inspect = 'Inspect',
    Update = 'Update',
    Delete = 'Delete',
}

enum Status {
    Any = 'Any',
    ToBeTransferred = 'To be Transferred',
    ToBeAssembled = 'To be Assembled',
    ToBeDispatched = 'To be Dispatched',
    Completed = 'Completed',
}

export default function Assemblies() {
    const {
        activeModal,
        assemblies,
        assembliesAmount,
        filterBomCode,
        filterDateRangeBegin,
        filterDateRangeEnd,
        filterProjectName,
        filterCallOffId,
        filterAssemblyId,
        filterSelectedWarehouseId,
        filterStatus,
        modalAssemblerId,
        sortOrder,
        sortBy,
        filterDateSearchType,
        isLoading,
    } = useAppSelector((state: RootState) => state.assemblies)

    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)
    const dispatch = useAppDispatch()

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const getStatusProperties = () => {
        return {
            [Status.Any]: {
                hasBeenTransferred: undefined,
                hasBeenAssembled: undefined,
                hasBeenDispatched: undefined,
            },
            [Status.ToBeTransferred]: {
                hasBeenTransferred: false,
                hasBeenAssembled: false,
                hasBeenDispatched: false,
            },
            [Status.ToBeAssembled]: {
                hasBeenTransferred: true,
                hasBeenAssembled: false,
                hasBeenDispatched: false,
            },
            [Status.ToBeDispatched]: {
                hasBeenTransferred: true,
                hasBeenAssembled: true,
                hasBeenDispatched: false,
            },
            [Status.Completed]: {
                hasBeenTransferred: true,
                hasBeenAssembled: true,
                hasBeenDispatched: true,
            },
        }[filterStatus]
    }

    const onDateChange = (dates: [moment.Moment | null, moment.Moment | null] | null) => {
        if (dates === null) {
            dispatch(setFilterDateRangeBegin(null))
            dispatch(setFilterDateRangeEnd(null))
            return
        }

        if (dates[0] !== null) {
            dispatch(setFilterDateRangeBegin(new Date(dates[0]?.format('MM-DD-YYYY') ?? '')))
        } else {
            dispatch(setFilterDateRangeBegin(null))
        }

        if (dates[1] !== null) {
            dispatch(setFilterDateRangeEnd(new Date(dates[1]?.format('MM-DD-YYYY') ?? '')))
        } else {
            dispatch(setFilterDateRangeEnd(null))
        }
    }

    const getAssemblies = async () => {
        if (isLoading === undefined) {
            dispatch(setIsLoading(true))
        }
        const response = await getAssembliesRequest({
            callOffId: filterCallOffId,
            id: filterAssemblyId || undefined,
            bomCode: filterBomCode || undefined,
            relations: [
                AssemblyRelation.CallOff,
                AssemblyRelation.Bom,
                AssemblyRelation.CallOffProject,
            ],
            callOffWarehouseId: filterSelectedWarehouseId,
            projectName: filterProjectName || undefined,

            sortOrder,
            dateSearch: filterDateSearchType
                ? {
                      dateRangeBegin: filterDateRangeBegin || undefined,
                      dateRangeEnd: filterDateRangeEnd || undefined,
                      dateSearchType: filterDateSearchType || undefined,
                  }
                : undefined,
            //antd tables pages are 1-indexed
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
            sortBy: sortBy ? sortBy : undefined,
            ...getStatusProperties(),
        })
        if (response.successful) {
            dispatch(setIsLoading(false))
            dispatch(setAssemblies(response.data.entities))
            dispatch(setAssembliesAmount(response.data.count))
        } else {
            setIsLoading(false)
            toastFailure(response.message)
        }
    }

    const openModal = (assemblyId: number, modal: ActiveModal) => {
        dispatch(setActiveModal(modal))
        dispatch(setModalAssemblerId(assemblyId))
    }

    const closeModal = () => {
        dispatch(setActiveModal(ActiveModal.None))
        dispatch(setModalAssemblerId(undefined))
    }

    const update = useDebounce(
        JSON.stringify({
            sortOrder,
            filterStatus,
            filterProjectName,
            filterAssemblyId,
            filterBomCode,
            filterCallOffId,
            filterSelectedWarehouseId,
            filterDateRangeBegin,
            filterDateRangeEnd,
            filterDateSearchType,
            sortBy,
            page,
            rowsPerPage,
        }),
        200
    )

    useEffect(() => {
        getAssemblies()
    }, [update])

    if (isLoading && assemblies.length === 0) {
        return <CTRLoader />
    }

    const tableColumns = [
        {
            title: 'Assembly ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Call off ID',
            dataIndex: 'callOffId',
            key: 'callOffId',
        },
        {
            title: 'Project name',
            key: 'callOffId',
            render: (assembly: Assembly) => assembly?.callOff?.project?.name || 'No project name',
        },
        {
            title: 'BOM',
            key: 'bom',
            render: (assembly: Assembly) =>
                assembly?.builtItemCallOff?.sopBuiltItemOrderline?.builtItem?.bomCode || 'No BoM',
        },
        {
            key: 'amount',
            title: 'Amount built',
            render: (assembly: Assembly) => {
                const completedAmount = assembly.lines.reduce((completedAmount, cur) => {
                    if (cur.completedAt) {
                        ++completedAmount
                    }
                    return completedAmount
                }, 0)
                return `${completedAmount}/${assembly.amount}`
            },
        },
        {
            title: 'Transferred to assembly',
            key: 'assemblyTransferDate',
            render: (assembly: Assembly) => {
                if (assembly.assemblyTransferDate) {
                    return moment(assembly.assemblyTransferDate).format('DD-MM-YYYY')
                }
                return 'Not transferred yet'
            },
        },
        {
            title: 'Started assembly',
            key: 'assemblyTransferDate',
            render: (assembly: Assembly) => {
                if (assembly?.startedAt) {
                    return moment(assembly.startedAt).format('DD-MM-YYYY HH:mm')
                }
                return 'Not started yet'
            },
        },
        {
            title: 'Completed assembly',
            key: 'completedAssemblyDate',
            render: (assembly: Assembly) => {
                if (assembly.completedAt) {
                    return moment(assembly.completedAt).format('DD-MM-YYYY HH:mm')
                }
                return 'Not done yet'
            },
        },
        {
            title: 'Transferred to dispatch',
            key: 'dispatchTransferDate',
            render: (assembly: Assembly) => {
                if (assembly.dispatchTransferDate) {
                    return moment(assembly.dispatchTransferDate).format('DD-MM-YYYY')
                }
                return 'Not done yet'
            },
        },
        {
            title: 'Date to be completed by',
            key: 'dateToBeCompletedBy',
            render: (assembly: Assembly) => {
                if (assembly.dateToBeCompletedBy) {
                    return moment(assembly.dateToBeCompletedBy).format('DD-MM-YYYY')
                }
                return 'Not done yet'
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (assembly: Assembly) => {
                return (
                    <>
                        <Button
                            onClick={() => {
                                openModal(assembly.id, ActiveModal.Inspect)
                            }}
                        >
                            Inspect
                        </Button>
                        {!assembly.completedAt && (
                            <>
                                <Button
                                    onClick={() => {
                                        openModal(assembly.id, ActiveModal.Update)
                                    }}
                                >
                                    Update
                                </Button>
                            </>
                        )}
                        {!assembly.completedAt && (
                            <>
                                <Button
                                    onClick={() => {
                                        openModal(assembly.id, ActiveModal.Delete)
                                    }}
                                >
                                    Delete
                                </Button>
                            </>
                        )}
                    </>
                )
            },
        },
    ]

    return (
        <>
            <PageHeader
                title="Assemblies"
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        link: Urls.Assemblies,
                        name: 'Assemblies',
                    },
                ]}
            />
            <Container>
                {activeModal === ActiveModal.Inspect && modalAssemblerId && (
                    <InspectAssemblyModal
                        onOk={() => {
                            closeModal()
                            getAssemblies()
                        }}
                        isOpen={true}
                        closeModal={closeModal}
                        assemblyId={modalAssemblerId}
                    />
                )}
                {activeModal === ActiveModal.Delete && modalAssemblerId && (
                    <DeleteAssemblyModal
                        onOk={() => {
                            closeModal()
                            getAssemblies()
                        }}
                        isOpen={true}
                        closeModal={closeModal}
                        assemblyId={modalAssemblerId}
                    />
                )}
                {activeModal === ActiveModal.Update && modalAssemblerId && (
                    <UpdateAssemblyModal
                        onOk={() => {
                            closeModal()
                            getAssemblies()
                        }}
                        visible={true}
                        onClose={closeModal}
                        assemblyId={modalAssemblerId}
                    />
                )}
                <InputsContainer>
                    <FieldContainer>
                        <Label>Assembly Id</Label>
                        <Input
                            style={{ width: '100px' }}
                            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                                if (
                                    isNaN(parseInt(event.target.value)) ||
                                    parseInt(event.target.value) === 0
                                ) {
                                    dispatch(setFilterAssemblyId(undefined))
                                } else {
                                    dispatch(setFilterAssemblyId(Number(event.target.value)))
                                }
                            }}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Call off id</Label>
                        <Input
                            style={{ width: '100px' }}
                            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                                if (
                                    isNaN(parseInt(event.target.value)) ||
                                    parseInt(event.target.value) === 0
                                ) {
                                    dispatch(setFilterCallOffId(undefined))
                                } else {
                                    dispatch(setFilterCallOffId(Number(event.target.value)))
                                }
                            }}
                        />
                    </FieldContainer>

                    <FieldContainer>
                        <Label>Project Name</Label>
                        <Input
                            style={{ width: '100px' }}
                            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                                dispatch(setFilterProjectName(event.target.value))
                            }}
                        />
                    </FieldContainer>

                    <FieldContainer>
                        <Label>Bom Code</Label>
                        <Input
                            style={{ width: '100px' }}
                            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                                dispatch(setFilterBomCode(event.target.value))
                            }}
                        />
                    </FieldContainer>

                    <FieldContainer>
                        <Label>Date</Label>
                        <DatePicker.RangePicker
                            style={{ width: '240px' }}
                            disabled={filterDateSearchType === null ? true : false}
                            value={
                                filterDateRangeBegin === null
                                    ? [null, null]
                                    : [moment(filterDateRangeBegin), moment(filterDateRangeEnd)]
                            }
                            onChange={onDateChange}
                        />
                    </FieldContainer>

                    <FieldContainer>
                        <Label>Date</Label>
                        <Select
                            style={{ width: '170px' }}
                            value={filterDateSearchType}
                            onChange={(dateSearchType): void => {
                                dispatch(setFilterDateSearchType(dateSearchType))
                            }}
                            options={[
                                { value: null, label: 'Any' },
                                {
                                    value: DateSearchType.AssemblyTransfer,
                                    label: 'Assembly Transfer',
                                },
                                {
                                    value: DateSearchType.CompletedAssembly,
                                    label: 'Completed Assembly',
                                },
                                {
                                    value: DateSearchType.DispatchTransfer,
                                    label: 'Dispatch Transfer',
                                },
                                {
                                    value: DateSearchType.DateToBeCompletedBy,
                                    label: 'Date To Be Completed By',
                                },
                            ]}
                        />
                    </FieldContainer>

                    <FieldContainer>
                        <Label>Status</Label>
                        <Select
                            style={{ width: '170px' }}
                            value={filterStatus}
                            onChange={(status): void => {
                                dispatch(setFilterStatus(status))
                            }}
                            options={[
                                { value: null, label: 'Any' },
                                { value: Status.ToBeTransferred, label: 'To Be Transferred' },
                                { value: Status.ToBeAssembled, label: 'To Be Assembled' },
                                { value: Status.ToBeDispatched, label: 'To Be Dispatched' },
                                { value: Status.Completed, label: 'Completed' },
                            ]}
                        />
                    </FieldContainer>

                    <FieldContainer>
                        <Label>Warehouse</Label>
                        <Select
                            style={{ width: '170px' }}
                            value={filterSelectedWarehouseId}
                            onChange={(warehouseId): void => {
                                dispatch(setFilterSelectedwarehouseId(warehouseId))
                            }}
                            options={[
                                { value: WarehouseId.Monument, label: 'Monument' },
                                { value: WarehouseId.Scandia, label: 'Scandia' },
                            ]}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Sort by</Label>
                        <Select
                            style={{ width: '170px' }}
                            value={sortBy}
                            onChange={(sortBy): void => {
                                dispatch(setSortBy(sortBy))
                            }}
                            options={[
                                { value: null, label: 'None' },
                                { value: SortBy.AssemblyId, label: 'Assembly Id' },
                                { value: SortBy.CallOffId, label: 'Call Off Id' },
                                { value: SortBy.ProjectName, label: 'Project Name' },
                                { value: SortBy.BomCode, label: 'Bom Code' },
                                { value: SortBy.AssemblyTransfer, label: 'Assembly Transfer' },
                                { value: SortBy.CompletedAssembly, label: 'Completed Assembly' },
                                { value: SortBy.DispatchTransfer, label: 'Dispatch Transfer' },
                                {
                                    value: SortBy.DateToBeCompletedBy,
                                    label: 'Date To Be Completed By',
                                },
                            ]}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Sort Order</Label>
                        <Select
                            style={{ width: '170px' }}
                            value={sortOrder}
                            onChange={(sortOrder): void => {
                                dispatch(setSortOrder(sortOrder))
                            }}
                            options={[
                                { value: SortOrder.Ascending, label: 'Ascending' },
                                { value: SortOrder.Descending, label: 'Descending' },
                            ]}
                        />
                    </FieldContainer>
                </InputsContainer>
                <Table
                    rowKey="id"
                    columns={tableColumns}
                    size="small"
                    dataSource={assemblies}
                    pagination={{
                        defaultPageSize: 10,
                        current: page,
                        onChange: pageChange,
                        total: assembliesAmount,
                    }}
                />
            </Container>
        </>
    )
}
