import { TextField } from '@mui/material'
import { Box, styled } from '@mui/system'
import { Assembly } from '../../../models/models'
import moment from 'moment'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
})

const InformationContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

const AnalysisCodesContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    margin: '20px',
})

const SalesOrderInformationContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    margin: '20px',
})

interface Props {
    assembly: Assembly
}
export default function AssemblyTab(props: Props) {
    const assembly = props.assembly
    return (
        <Container>
            <InformationContainer>
                <SalesOrderInformationContainer>
                    <TextField
                        key="Created at"
                        id="outlined-read-only-input"
                        label="Created at"
                        defaultValue={moment(assembly.callOff.timeCreatedAt).format('DD/MM/YYYY')}
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        key="Assembly transfer date"
                        id="outlined-read-only-input"
                        label="Assembly transfer date"
                        defaultValue={moment(assembly.assemblyTransferDate).format('DD/MM/YYYY')}
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        key="Date to be completed by"
                        id="outlined-read-only-input"
                        label="Date to be completed by"
                        defaultValue={moment(assembly.dateToBeCompletedBy).format('DD/MM/YYYY')}
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        key="Completed date"
                        id="outlined-read-only-input"
                        label="Completed date"
                        defaultValue={moment(assembly.completedAt).format('DD/MM/YYYY')}
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                </SalesOrderInformationContainer>
                <AnalysisCodesContainer>
                    <TextField
                        key={'id'}
                        id="outlined-basic"
                        variant="outlined"
                        label="Id"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={assembly?.id}
                    />
                    <TextField
                        key={'pallets'}
                        id="outlined-basic"
                        variant="outlined"
                        label="Amount of pallets"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={assembly?.pallets?.length || 0}
                    />
                    <TextField
                        key="code"
                        id="outlined-basic"
                        variant="outlined"
                        label="Amount of products to build"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={
                            assembly?.builtItemCallOff?.sopBuiltItemOrderline?.builtItem?.product
                                ?.code
                        }
                    />
                    <TextField
                        key={assembly.amount}
                        id="outlined-basic"
                        variant="outlined"
                        label="Amount of products to build"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={assembly.amount}
                    />
                </AnalysisCodesContainer>
            </InformationContainer>
        </Container>
    )
}
