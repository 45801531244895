import { ComponentsOverrides } from '@mui/material/styles/overrides'

export const InputBaseTheme: {
    styleOverrides: ComponentsOverrides['MuiInputBase']
} = {
    styleOverrides: {
        root: {
            height: 40,
            borderRadius: 10,
            fontSize: 16,
            lineHeight: '24px',
            textTransform: 'none',
        },
    },
}
