//TODO create union type for all values that can be validated
export type ValidationFieldKey = 'email' | 'firstName' | 'lastName' | 'password' | 'phonenumber'
export interface ValidationError {
    [key: string]: string
}
export const validateFields = (fieldKey: ValidationFieldKey, inputValue: string): string | null => {
    switch (fieldKey) {
        case 'email':
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue) ? null : 'Invalid email address'
        case 'firstName':
            return /^[a-zA-Z]{2,}$/.test(inputValue) ? null : 'Invalid first name'
        case 'lastName':
            return /^[a-zA-Z]{2,}$/.test(inputValue) ? null : 'Invalid last name'
        default:
            return null
    }
}

export const validateEmail = (input: string): RegExpMatchArray | null => {
    return input.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
}
