import { WarehouseId } from '../../../../models/models'
import { ContactDTO, CustomerSopTemplate } from '../../../../api/sales-orders'
import { DeliveryAddress } from '../../../../api/customers'
import {
    isAddedSopAdditionalCharge,
    isAddedSopProjectBuiltItem,
    isAddedSopProjectStockItem,
    Orderline,
} from './CreateSaleOrder'
import moment, { Moment } from 'moment'
import { styled } from '@mui/styles'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { OrderlinesTable } from './OrderlinesTable'
import { useMemo, useState } from 'react'
import { Button, Input, Typography } from 'antd'
import { Label } from '../../../ui/Label'
import { FieldContainer } from '../../../ui/FIeldContainer'

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    marginTop: '50px',
})

const Footer = styled('footer')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
})

const InformationContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
})

const DeliveryAddressContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
})

const BillingAddressContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
})

const DetailsContainer = styled('div')({ display: 'flex', flexDirection: 'column', gap: '10px' })

const AnalysisCodesContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
})

const OrderlinesContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
})

const ContactsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
})

const ChargesContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
})

const CommentsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
})

const totalValueContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
})

interface ConfirmProps {
    template: CustomerSopTemplate
    contacts: ContactDTO[]
    selectedDeliveryAddress: DeliveryAddress
    analysisCode1: string
    analysisCode2: string
    analysisCode3: string
    analysisCode4: string
    analysisCode5: string
    analysisCode6: string
    customerOrderNumber: string
    datePromised: Moment
    warehouseId: WarehouseId
    orderlines: Orderline[]
    setOrderlines: React.Dispatch<React.SetStateAction<Orderline[]>>
    onCreate: () => void
    waiting: boolean
}

export default function Confirm(props: ConfirmProps) {
    const salesOrderTotalValue = useMemo(() => {
        return props.orderlines.reduce((salesOrderValue, orderline) => {
            if (isAddedSopProjectBuiltItem(orderline) || isAddedSopProjectStockItem(orderline)) {
                salesOrderValue += orderline.addedAmount * orderline.unitPrice
            } else if (isAddedSopAdditionalCharge(orderline)) {
                salesOrderValue += orderline.netValue
            }
            return salesOrderValue
        }, 0)
    }, [props.orderlines])

    const {
        template,
        contacts,
        selectedDeliveryAddress,
        analysisCode1,
        analysisCode2,
        analysisCode3,
        analysisCode4,
        analysisCode5,
        analysisCode6,
        orderlines,
        setOrderlines,
        customerOrderNumber,
        datePromised,
        warehouseId,
        onCreate,
        waiting,
    } = props

    return (
        <Container>
            <InformationContainer>
                <OrderlinesContainer>
                    <h3>Orderlines</h3>
                    <OrderlinesTable orderlines={orderlines} setOrderlines={setOrderlines} />

                    <FieldContainer>
                        <Label>Total value</Label>
                        <Input
                            key={analysisCode1}
                            readOnly={true}
                            defaultValue={`${salesOrderTotalValue} £`}
                        />
                    </FieldContainer>
                </OrderlinesContainer>
                <DeliveryAddressContainer>
                    <h3>Delivery Address</h3>

                    <FieldContainer>
                        <Label>Address 1</Label>
                        <Input
                            key={selectedDeliveryAddress?.address1}
                            defaultValue={selectedDeliveryAddress?.address1}
                            readOnly={true}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Address 2</Label>
                        <Input
                            key={selectedDeliveryAddress?.address2}
                            defaultValue={selectedDeliveryAddress?.address2}
                            readOnly={true}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Address 3</Label>
                        <Input
                            key={selectedDeliveryAddress?.address3}
                            defaultValue={selectedDeliveryAddress?.address3}
                            readOnly={true}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Address 4</Label>
                        <Input
                            key={selectedDeliveryAddress?.address4}
                            defaultValue={selectedDeliveryAddress?.address4}
                            readOnly={true}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Postcode</Label>
                        <Input
                            key={selectedDeliveryAddress?.postcode}
                            defaultValue={selectedDeliveryAddress?.postcode}
                            readOnly={true}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>County</Label>
                        <Input
                            key={selectedDeliveryAddress?.county}
                            defaultValue={selectedDeliveryAddress?.county}
                            readOnly={true}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Country</Label>
                        <Input
                            key={selectedDeliveryAddress?.country}
                            defaultValue={selectedDeliveryAddress?.country}
                            readOnly={true}
                        />
                    </FieldContainer>
                </DeliveryAddressContainer>
                <BillingAddressContainer>
                    <h3>Billing Address</h3>

                    <FieldContainer>
                        <Label>Address</Label>
                        <Input value={template.customer.mainAddress?.address1} readOnly={true} />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Address</Label>
                        <Input value={template.customer.mainAddress?.address2} readOnly={true} />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Address</Label>
                        <Input value={template.customer.mainAddress?.address3} readOnly={true} />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Address</Label>
                        <Input value={template.customer.mainAddress?.address4} readOnly={true} />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Postcode</Label>
                        <Input value={template.customer.mainAddress?.postcode} readOnly={true} />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>City</Label>
                        <Input value={template.customer.mainAddress?.city} readOnly={true} />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>County</Label>
                        <Input
                            defaultValue={template.customer.mainAddress?.county}
                            readOnly={true}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Country</Label>
                        <Input value={template.customer.mainAddress?.country} readOnly={true} />
                    </FieldContainer>
                </BillingAddressContainer>
                <AnalysisCodesContainer>
                    <DetailsContainer>
                        <h3>General details</h3>

                        <FieldContainer>
                            <Label>Date promised</Label>
                            <Input
                                defaultValue={datePromised.format('DD/MM/YYYY')}
                                readOnly={true}
                            />
                        </FieldContainer>
                        <FieldContainer>
                            <Label>Customer order number</Label>
                            <Input defaultValue={customerOrderNumber} readOnly={true} />
                        </FieldContainer>
                        <FieldContainer>
                            <Label>Warehouse</Label>
                            <Input
                                defaultValue={
                                    warehouseId === WarehouseId.Scandia ? 'Scandia' : 'Monument'
                                }
                                readOnly={true}
                            />
                        </FieldContainer>
                    </DetailsContainer>
                    <h3>Analysis codes</h3>

                    <FieldContainer>
                        <Label>Project Name</Label>
                        <Input readOnly={true} defaultValue={analysisCode1} />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Project Number</Label>
                        <Input readOnly={true} defaultValue={analysisCode2} />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Project Manager</Label>
                        <Input readOnly={true} defaultValue={analysisCode3} />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Region</Label>
                        <Input readOnly={true} defaultValue={analysisCode4} />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Case Number</Label>
                        <Input readOnly={true} defaultValue={analysisCode5} />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Dispatch from Master Order</Label>
                        <Input readOnly={true} defaultValue={analysisCode6} />
                    </FieldContainer>
                </AnalysisCodesContainer>
                <ContactsContainer>
                    <h3>Contacts</h3>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Phone Number</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contacts.map((contact) => {
                                return (
                                    <TableRow key={contact.description}>
                                        <TableCell>{contact.name}</TableCell>
                                        <TableCell>{contact.description}</TableCell>
                                        <TableCell>{contact.phoneNumber}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </ContactsContainer>
            </InformationContainer>
            <Footer>
                <Button
                    type="primary"
                    style={{ width: '200px' }}
                    disabled={waiting}
                    onClick={onCreate}
                >
                    Create SOP
                </Button>
            </Footer>
        </Container>
    )
}
