import { TextField } from '@mui/material'
import { Box, styled } from '@mui/system'
import { Table } from 'antd'
import { Assembly, CallOff, PurchaseOrder, PurchaseOrderOrderline } from '../../../models/models'
import moment from 'moment'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

interface Props {
    callOff: CallOff
}

const columns = [
    {
        title: 'Document No',
        key: 'code',
        render: (element: PurchaseOrder) => {
            return element.documentNo
        },
    },
    {
        title: 'Expected delivery date',
        key: 'code',
        render: (element: PurchaseOrder) => {
            return element.expectedDeliveryDate
                ? moment(element.expectedDeliveryDate).format('DD/MM/YYYY')
                : 'No expected delivery date'
        },
    },
    {
        title: 'Received date',
        key: 'code',
        render: (element: PurchaseOrder) => {
            return element.receivedDate
                ? moment(element.receivedDate).format('DD/MM/YYYY')
                : 'Not received'
        },
    },
    {
        title: 'Supplier dispatch date',
        key: 'code',
        render: (element: PurchaseOrder) => {
            return element.supplierDispatchDate
                ? moment(element.supplierDispatchDate).format('DD/MM/YYYY')
                : 'Not received'
        },
    },
]

const expandedRowRender = (purchaseOrder: PurchaseOrder) => {
    const columns = [
        {
            title: 'Code',
            key: 'code',
            render: (element: PurchaseOrderOrderline) => {
                return element.product.code
            },
        },
        {
            title: 'Description',
            key: 'price',
            render: (element: PurchaseOrderOrderline) => {
                return element.product.description
            },
        },
        {
            title: 'Amount Ordered',
            key: 'amount',
            render: (element: PurchaseOrderOrderline) => {
                return element.lineQuantity
            },
        },
    ]
    const data = purchaseOrder.orderlines

    return (
        <Table style={{ width: '100%' }} dataSource={data} columns={columns} pagination={false} />
    )
}
export default function PurchaseOrdersTab(props: Props) {
    const callOff = props.callOff
    return (
        <Container>
            <p>should be changed since OC implementation</p>
            <Table
                rowKey="id"
                style={{ width: '100%' }}
                dataSource={[]}
                columns={columns}
                pagination={false}
                expandable={{ expandedRowRender }}
            />
        </Container>
    )
}
