import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { styled } from '@mui/material'
import {
    AssemblyStatusKey,
    completeWorksheet,
    getAssemblyStatus,
    setActiveAssembly,
    setActiveGeneralAssembly,
    showCompleteFlatstationAssemblyWorksheetModal,
    showStartAssemblyModal,
} from '../../../../store/assembly-worksheets-slice'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import LabelsModal from '../modals/LabelsModal'
import { toastWarn } from '../../../../util/toast'
import { isFlatstation } from '../../../../util/util'
import { UserRole, WarehouseId } from '../../../../models/models'

const Container = styled('div')`
    display: flex;
    padding-top: 20px;
    justify-content: space-between;
`

const Button = styled('button')`
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 10px;
    outline: none;
    border: 1px solid;
    cursor: pointer;
`

const BackButton = styled(Button)`
    align-self: flex-start;
    background: #fff;
    border-color: #3500a0;
    color: #3500a0;
`

const CompleteButton = styled(Button)`
    color: #fff;
    background: #146a5e;
    align-self: flex-end;
    border-color: #146a5e;
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

interface Props {
    startAssemblyCallback?: () => void
}

export function ActiveAssemblyContentFooter(props: Props) {
    const dispatch = useAppDispatch()
    const state = useAppSelector((state) => state.assemblyWorksheets)
    const { user } = useAppSelector((state) => state.authentication)

    if (!state.activeAssembly) {
        return null
    }

    const builtItem = state.activeAssembly.builtItemCallOff.sopBuiltItemOrderline.builtItem
    const warehouse = state.activeAssembly.callOff.warehouse
    const assemblyStatus = getAssemblyStatus(state.activeAssembly)

    const onBack = () => {
        dispatch(setActiveAssembly(null))
        dispatch(setActiveGeneralAssembly(null))
    }

    const ButtonFunctions: Record<AssemblyStatusKey, () => void> = {
        NotStarted: () => {
            if (props.startAssemblyCallback) {
                props.startAssemblyCallback()
                return
            }

            if (state.activeAssembly) {
                dispatch(showStartAssemblyModal(state.activeAssembly))
            }
        },
        InProgress: () => {
            if (state.activeAssembly) {
                if (warehouse.id === WarehouseId.Monument && isFlatstation(builtItem)) {
                    dispatch(showCompleteFlatstationAssemblyWorksheetModal(state.activeAssembly.id))
                    return
                }
                dispatch(completeWorksheet(state.activeAssembly))
                dispatch(setActiveAssembly(null))
            }
        },
        Completed: () => {
            toastWarn('not implemented')
        },
    }

    const [showLabels, setShowLabels] = useState<boolean>(false)

    return (
        <Container>
            <BackButton onClick={onBack}>Back</BackButton>
            {isFlatstation(builtItem) && builtItem.instructionLink && (
                <BackButton
                    onClick={() => {
                        if (builtItem.instructionLink) {
                            window.open(builtItem.instructionLink, '_blank', 'noreferrer')
                        }
                    }}
                >
                    {builtItem.instructionLink ? <p> Show instructions</p> : <p>No Instructions</p>}
                </BackButton>
            )}
            {!state.activeAssembly.isOnHold &&
                !state.activeAssembly.callOff.isOnHold &&
                user?.role === UserRole.Assembler && (
                    <CompleteButton
                        onClick={ButtonFunctions[assemblyStatus]}
                        disabled={
                            (assemblyStatus === 'InProgress' &&
                                isFlatstation(builtItem) &&
                                state.activeAssembly.lines.some((item) => !item.completedAt)) ||
                            assemblyStatus === 'Completed'
                        }
                    >
                        {assemblyStatus === 'NotStarted' && 'Start assembly'}
                        {assemblyStatus === 'InProgress' && 'Complete Worksheet'}
                        {assemblyStatus === 'Completed' && 'Worksheet completed'}
                    </CompleteButton>
                )}

            {showLabels && state.activeAssembly && (
                <LabelsModal
                    assemblyId={state.activeAssembly.id}
                    onCancel={() => setShowLabels(false)}
                />
            )}
        </Container>
    )
}
