import { Table, TableColumnType } from 'antd'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { Response } from '../../api/util/with-response-formatter-interceptor'
import { OrderConfirmation } from '../../models/models'
import { toastFailure } from '../../util/toast'
import { getOrderConfirmationStatus, OrderConfirmationStatus } from '../../util/util'
import useDebounce from '../project-master/single-project-view/hooks'
import OrderConfirmationOrderlineTable from './OrderConfirmationOrderlineTable'

interface Props {
    pagination?: boolean
    //antd ColumnType<T> does not have title property
    columns?: Array<TableColumnType<OrderConfirmation>>
    actionComponents?: Array<(orderConfirmationId: number) => JSX.Element>
    dependencies?: any
    expandable?: boolean
    request: (pagination?: {
        skip: number
        limit: number
    }) => Promise<Response<{ count: number; entities: OrderConfirmation[] }>>
}

export const OrderConfirmationsTableColumns = {
    Code: {
        title: 'OC Code',
        key: 'code',
        render: (orderConfirmation: OrderConfirmation) => {
            return orderConfirmation.code
        },
    },
    Status: {
        title: 'Status',
        key: 'status',
        render: (orderConfirmation: OrderConfirmation) => {
            const status = getOrderConfirmationStatus(orderConfirmation)
            if (!status) {
                return 'Incalculatable'
            }
            if (status === OrderConfirmationStatus.Created) {
                return 'Created'
            }
            if (status === OrderConfirmationStatus.PartiallyBookedIn) {
                return 'Partially Booked In'
            }
            if (status === OrderConfirmationStatus.BookedIn) {
                return 'Booked In'
            }
        },
    },
    Author: {
        title: 'Created by',
        key: 'createdBy',
        render: (orderConfirmation: OrderConfirmation) => {
            return `${orderConfirmation?.author?.firstName} ${orderConfirmation?.author?.lastName}`
        },
    },
    ExpectedDeliveryDate: {
        title: 'Expected Delivery Date',
        key: 'activatedAt',
        render: (orderConfirmation: OrderConfirmation) => {
            return moment(orderConfirmation.expectedCompletelyArrivedDate).format('DD/MM/YYYY')
        },
    },
    PurchaseOrderDocumentNumber: {
        title: 'Document NO',
        key: 'documentNo',
        render: (orderConfirmation: OrderConfirmation) => {
            return orderConfirmation?.purchaseOrder?.documentNo
        },
    },
} as const

const DEFAULT_COLUMNS = [
    OrderConfirmationsTableColumns.Code,
    OrderConfirmationsTableColumns.Status,
    OrderConfirmationsTableColumns.Author,
    OrderConfirmationsTableColumns.ExpectedDeliveryDate,
]

export default function OrderConfirmationsTable({
    request,
    columns,
    expandable,
    pagination,
    actionComponents,
    dependencies,
}: Props) {
    const [orderConfirmations, setOrderConfirmations] = useState<OrderConfirmation[]>([])
    const [orderConfirmationsAmount, setOrderConfirmationsAmount] = useState<number>(0)
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)

    const getOrderConfirmationsDeps = useDebounce(JSON.stringify(dependencies), 200)

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const tableColumns = useMemo(() => {
        let cols = columns ? Object.values(columns) : DEFAULT_COLUMNS
        if (actionComponents) {
            cols = [
                ...cols,
                {
                    title: 'Actions',
                    key: 'actions',
                    render: (orderConfirmation: OrderConfirmation) => {
                        return (<>{actionComponents.map((a) => a(orderConfirmation.id))}</>) as any
                    },
                },
            ]
        }
        return cols
    }, [columns])

    const getOrderConfirmations = async (resetPage = false) => {
        const response = await request({
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        })

        if (response.successful) {
            const { count, entities } = response.data
            resetPage && setPage(1)
            setOrderConfirmationsAmount(count)
            setOrderConfirmations(entities)
        } else {
            toastFailure(response.message)
        }
    }

    // this happens when dependencies change outside the table, e.g filters given in with getOrderConfirmationsDeps
    useEffect(() => {
        getOrderConfirmations(true)
    }, [getOrderConfirmationsDeps, rowsPerPage])

    // this happens when dependencies change outside the table, e.g filters given in with getOrderConfirmationsDeps
    useEffect(() => {
        getOrderConfirmations()
    }, [page])

    const expandedRowRender = (orderConfirmation: OrderConfirmation) => {
        return <OrderConfirmationOrderlineTable orderConfirmation={orderConfirmation} />
    }

    return (
        <Table
            rowKey="id"
            columns={tableColumns}
            dataSource={orderConfirmations}
            expandable={expandable ? { expandedRowRender } : undefined}
            size="small"
            pagination={
                pagination
                    ? {
                          defaultPageSize: 10,
                          current: page,
                          onChange: pageChange,
                          total: orderConfirmationsAmount,
                      }
                    : false
            }
        />
    )
}
