import { Schedule } from '../models/models'
import backendConnection from './util/backend-connection'
import { Response } from './util/with-response-formatter-interceptor'

export type ProductionScheduleQueryDTO = {
    from?: Date
    to?: Date
    warehouseId: number
}

export const getProductionSchedules = (
    queryParams: ProductionScheduleQueryDTO
): Promise<Response<Schedule[]>> =>
    backendConnection.request({
        url: `/production-schedule`,
        method: 'GET',
        params: queryParams,
    })

export const getProductionScheduleRequest = (
    warehouseId: number,
    date: Date
): Promise<Response<Schedule>> =>
    backendConnection.request({
        url: `/production-schedule/${warehouseId}`,
        method: 'GET',
        params: { date },
    })
