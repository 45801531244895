import { Box, Typography, Divider } from '@mui/material'
import { styled, SxProps } from '@mui/system'
import { useState } from 'react'
import { TextColors } from '../../../theme/colors'

interface TabNavigationProps<Keys> {
    activeTab: Keys
    setActiveTab: (key: Keys) => void
    tabConfigurations: TabConfiguration<Keys>[]
    tabContainerStyles?: SxProps
    tabStyles?: SxProps
    contentStyles?: SxProps
}

interface TabConfiguration<Keys> {
    text: string
    tabKey: Keys
    component: JSX.Element
    secondaryText?: string
}

interface DividerProps {
    active: boolean
}

interface TabNavigatorProps<Keys> {
    activeTab: Keys
    text: string
    tabKey: Keys
    setActiveTab: (tab: Keys) => void
    secondaryText?: string
}

const TabContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
}))

const Container = styled(Box)(() => ({
    width: '100%',
}))

const ContentContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
}))

const Tab = styled(Box)(() => ({
    height: '36px',
    width: '120px',
    cursor: 'pointer',
}))

const Underline = styled(Divider)<DividerProps>(({ active }) => ({
    'mix-blend-mode': 'darken',
    border: active ? '1px solid #000000' : '1px solid rgba(0, 0, 0, 0.06)',
}))

export default function TabNavigation<Keys>({
    activeTab,
    setActiveTab,
    tabConfigurations,
    tabStyles,
    tabContainerStyles,
    contentStyles,
}: TabNavigationProps<Keys>) {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

    const TabNavigator = ({
        activeTab,
        setActiveTab,
        text,
        tabKey,
        secondaryText,
    }: TabNavigatorProps<Keys>): JSX.Element => {
        const isActive = activeTab === tabKey
        const onChangeTab = (tab: Keys) => {
            if (activeTab === tab) {
                return
            }
            setActiveTab(tab)
        }

        return (
            <Tab
                sx={tabStyles}
                width="100px"
                onClick={(e) => {
                    setAnchorEl(e.currentTarget)
                    onChangeTab(tabKey)
                }}
            >
                <Typography
                    fontWeight={isActive ? '500' : '400'}
                    color={isActive ? TextColors.forest : TextColors.black}
                >
                    {text}
                </Typography>
                {isActive && secondaryText && (
                    <Typography
                        fontSize={'10px'}
                        fontWeight={'100'}
                        color={isActive ? TextColors.forest : TextColors.black}
                    >
                        {secondaryText}
                    </Typography>
                )}
                {(!secondaryText || (!isActive && secondaryText)) && <div style={{ height: 15 }} />}
                <Underline active={activeTab === tabKey} />
            </Tab>
        )
    }

    return (
        <>
            <Container>
                <TabContainer sx={tabContainerStyles} width="100%">
                    {tabConfigurations.map((tc, i) => (
                        <TabNavigator
                            key={i}
                            activeTab={activeTab}
                            text={tc.text}
                            tabKey={tc.tabKey}
                            setActiveTab={setActiveTab}
                            secondaryText={tc.secondaryText}
                        />
                    ))}
                </TabContainer>
                <ContentContainer sx={contentStyles}>
                    {tabConfigurations.find((sc) => sc.tabKey === activeTab)?.component}
                </ContentContainer>
            </Container>
        </>
    )
}
