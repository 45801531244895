import { Button } from '@mui/material'
import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
    closeStartAssemblyModal,
    confirmStartAssembly,
} from '../../../store/assembly-worksheets-slice'
import { useAppSelector } from '../../../store/hooks'
import { setRegularView } from '../../../store/warehouse-map-slice'

const Container = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: '10px',
    flexDirection: 'row',
}))

export const StartAssemblyActionBar = () => {
    const { assemblyWorksheetToStart } = useAppSelector((state) => state.warehouseMap)
    const dispatch = useDispatch()
    const history = useHistory()

    if (
        !assemblyWorksheetToStart ||
        !assemblyWorksheetToStart?.builtItemCallOff.sopBuiltItemOrderline.builtItem.product
    ) {
        return <div>Something went wrong</div>
    }

    return (
        <Container>
            <Button
                variant="outlined"
                onClick={() => {
                    dispatch(closeStartAssemblyModal())
                    dispatch(setRegularView())
                    history.push(`/dashboard/worksheets/assembly/${assemblyWorksheetToStart.id}`)
                }}
                disabled={false}
            >
                Cancel
            </Button>
            <Button
                variant="outlined"
                onClick={() => {
                    dispatch(
                        confirmStartAssembly(() =>
                            history.push(
                                `/dashboard/worksheets/assembly/${assemblyWorksheetToStart.id}`
                            )
                        )
                    )
                }}
                disabled={false}
            >
                Confirm start and movement of products
            </Button>
        </Container>
    )
}
