import { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Input, Modal, Select, Button } from 'antd'
import moment, { Moment } from 'moment'
import { Project, Task, TaskDepartment, UserRole, WarehouseId } from '../../../models/models'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { CircularProgress, styled } from '@mui/material'
import '../../tables/table-styles.css'
import {
    getTaskByIdRequest,
    TaskRelation,
    UpdateTaskDto,
    updateTaskRequest,
} from '../../../api/tasks'
import { OrderedListOutlined } from '@ant-design/icons'
import { getUsersRequest } from '../../../api/users'
import DatePicker from '../../ui/calendar/DatePicker'
import { getProjectsRequest } from '../../../api/projects/projects'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 47%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

interface UpdateTaskModalProps {
    taskId: number
    closeModal: (task?: Task) => void
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

export default function UpdateTaskModal({ taskId, closeModal }: UpdateTaskModalProps) {
    const [description, setDescription] = useState<string>('')
    const [link, setLink] = useState<string>('')
    const [dateToBeCompleted, setDateToBeCompleted] = useState<Moment | null>(null)
    const [completedAt, setCompletedAt] = useState<Moment | null>(null)
    const [warehouseId, setWarehouseId] = useState<WarehouseId>()
    const [userOptions, setUserOptions] = useState<{ value: number; label: string }[]>([])
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
    const [minutes, setMinutes] = useState<number>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const [taskDepartment, setTaskDepartment] = useState<TaskDepartment>(TaskDepartment.ANY)
    const [task, setTask] = useState<Task>()
    const [projects, setProjects] = useState<Project[]>()
    const [selectedProjectId, setSelectedProjectId] = useState<number | null>(null)

    useEffect(() => {
        getTask()
        getProjectsRequest().then((response) => {
            if (response.successful) {
                setProjects(response.data?.projects || [])
            } else {
                setProjects([])
                toastFailure(response.message)
            }
        })
    }, [])

    useEffect(() => {
        if (modalState === ModalState.Success) {
            getUsers()
        }
    }, [taskDepartment, warehouseId, modalState])

    const getUsers = async () => {
        const roles = {
            [TaskDepartment.ASSEMBLY]: [UserRole.Assembler],
            [TaskDepartment.LOGISTICS]: [UserRole.LogisticsEmployee],
            [TaskDepartment.ANY]: [UserRole.Assembler, UserRole.LogisticsEmployee],
        }
        const response = await getUsersRequest({ warehouseId, roles: roles[taskDepartment] })

        if (!response.successful) {
            toastFailure('Could not retrieve available users')
            closeModal()
            return
        }

        const userOptions = response.data.entities.map((u) => ({
            value: u.id,
            label: `${u.firstName} ${u.lastName}`,
        }))

        setUserOptions(userOptions)

        if (!userOptions.find((option) => option.value === selectedUserId)) {
            setSelectedUserId(null)
        }
    }

    const getTask = async () => {
        const response = await getTaskByIdRequest(taskId, {
            relations: [TaskRelation.AssignedTo, TaskRelation.CreatedBy, TaskRelation.Type],
        })

        if (response.successful) {
            const task = response.data
            setTask(task)
            setDescription(task.description)
            setLink(task.link || '')
            setTaskDepartment(task.department)
            setCompletedAt(task.completedAt ? moment(task.completedAt) : null)
            setSelectedUserId(task.assignedUserId || null)
            setMinutes(task.minutes)
            setWarehouseId(task.warehouseId)
            setDateToBeCompleted(moment(task.dateToBeCompletedBy))
            setModalState(ModalState.Success)
            setSelectedProjectId(task.projectId)
        } else {
            if (response.status === 404) {
                setModalState(ModalState.NotFound)
            } else {
                setModalState(ModalState.Error)
            }
            toastFailure(response.message)
        }
    }

    const onOk = async () => {
        if (!task) {
            return
        }
        const body: UpdateTaskDto = {}
        if (selectedUserId !== task.assignedUser?.id) {
            body.assignedUserId = selectedUserId ? selectedUserId : null
        }
        if (description !== task.description) {
            body.description = description
        }
        if (taskDepartment !== task.department) {
            body.department = taskDepartment
        }
        if (selectedProjectId !== task.projectId) {
            body.projectId = selectedProjectId
        }

        body.completedAt = completedAt ? completedAt.toDate() : null

        if (
            dateToBeCompleted &&
            !moment(task.dateToBeCompletedBy).isSame(dateToBeCompleted, 'day')
        ) {
            body.dateToBeCompleted = dateToBeCompleted.toDate()
        }
        if (minutes !== task.minutes) {
            body.minutes = minutes
        }
        if (link !== task.link) {
            body.link = link
        }
        if (warehouseId !== task.warehouseId) {
            body.warehouseId = warehouseId
        }

        if (body.link === '') body.link = undefined

        const response = await updateTaskRequest(taskId, body)
        if (!response.successful) {
            toastFailure(response.message)
            return
        }
        toastSuccess(`Updated task ${taskId}`)
        closeModal(response.data)
    }

    const isSubmitDisabled = () => {
        return false
    }

    const allProjects: any = [
        {
            id: 1,
            name: 'Test',
        },
    ]

    return (
        <Modal open={true} footer={null} onCancel={() => closeModal()}>
            {modalState === ModalState.Loading && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        margin: '20px',
                        width: '100%',
                        height: '500px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                </Box>
            )}
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.NotFound && (
                <Box>Could not find the task with the id of {taskId}</Box>
            )}
            {modalState === ModalState.Success && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Update task {taskId}</Title>
                    </Header>
                    <Divider />
                    <div>
                        <Label>Type </Label>
                        <Input value={task?.type?.title} readOnly={true} />
                    </div>
                    <div>
                        <Label>Description</Label>
                        <Input.TextArea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{ minHeight: 200 }}
                        />
                    </div>
                    <InputRow>
                        <RowElement>
                            <Label>Date to be completed</Label>
                            <DatePicker
                                style={{ width: '100%' }}
                                value={dateToBeCompleted}
                                onChange={setDateToBeCompleted}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Completed Date</Label>
                            <DatePicker
                                style={{ width: '100%' }}
                                value={completedAt}
                                onChange={setCompletedAt}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow>
                        <RowElement>
                            <Label>Warehouse</Label>
                            <Select
                                style={{ width: '100%' }}
                                value={warehouseId}
                                onChange={(value: WarehouseId) => setWarehouseId(value)}
                                options={[
                                    { value: WarehouseId.Monument, label: 'Monument' },
                                    { value: WarehouseId.Scandia, label: 'Scandia' },
                                    { value: null, label: 'None' },
                                ]}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Department</Label>
                            <Select
                                style={{ width: '100%' }}
                                value={taskDepartment}
                                onChange={(value: TaskDepartment) => setTaskDepartment(value)}
                                options={[
                                    { value: TaskDepartment.ASSEMBLY, label: 'Assembly' },
                                    { value: TaskDepartment.LOGISTICS, label: 'Logistics' },
                                    { value: TaskDepartment.ANY, label: 'Any' },
                                ]}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow>
                        <RowElement>
                            <Label>Assigned User</Label>
                            <Select
                                style={{ width: '100%' }}
                                value={selectedUserId}
                                onChange={(value: number | null) => {
                                    setSelectedUserId(value)
                                }}
                                options={[{ value: null, label: 'None' }, ...userOptions]}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Minutes</Label>
                            <Input
                                style={{ width: '100%' }}
                                type="number"
                                value={minutes}
                                onWheel={(e) => e.preventDefault()}
                                onChange={(e) => setMinutes(Number(e.target.value))}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow>
                        <RowElement>
                            <Label>Link</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Project</Label>
                            <Select
                                allowClear={true}
                                style={{ width: '100%' }}
                                optionFilterProp={'children'}
                                value={selectedProjectId}
                                showSearch
                                onChange={(value: number | null) => {
                                    setSelectedProjectId(value)
                                }}
                            >
                                {projects?.map((project: any) => (
                                    <Select.Option key={project.id} value={project.id}>
                                        {project.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </RowElement>
                    </InputRow>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                        <Button type={'primary'} disabled={isSubmitDisabled()} onClick={onOk}>
                            Update
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    )
}
