import backendConnection from './util/backend-connection'
import { Response } from './util/with-response-formatter-interceptor'
import { BomVersion } from '../models/models'

export const getBomsFileRequest = (bomVersionIds: number[]): Promise<any> =>
    backendConnection
        .request({
            url: `/bom-versions/`,
            responseType: 'blob',
            params: { bomVersionIds },
        })
        .then((response) => {
            if (response.successful) {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `sage-bom-download.xml`) //or any other extension
                document.body.appendChild(link)
                link.click()
            }
        })

export const submitBomsRequest = (bomVersionIds: number[]): Promise<Response<BomVersion>> =>
    backendConnection.request({
        url: `/bom-versions/submitted`,
        method: 'PUT',
        data: { bomVersionIds },
    })
