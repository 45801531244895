import { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Input, Modal, Table } from 'antd'
import { styled } from '@mui/material'
import '../../tables/table-styles.css'
import { OrderedListOutlined } from '@ant-design/icons'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../store/hooks'
import { ActiveModal, closeModal } from '../../../store/products-slice'
import { Bom } from '../../../models/models'
import { BomRelation, getBomById } from '../../../api/bom'
import { Response } from '../../../api/util/with-response-formatter-interceptor'
import { BomComponent } from '../../../models/models'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 50%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

enum ModalState {
    Error = 'Error',
    Ready = 'Ready',
}

interface Props {
    bomId?: number
    isVisible?: boolean
    onClose?: () => void
}

export const InspectBomModal = (props: Props) => {
    const { activeModal, modalEntityId } = useSelector((state: RootState) => state.products)
    const [bom, setBom] = useState<Bom>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Ready)
    const dispatch = useAppDispatch()

    const isVisible = activeModal === ActiveModal.BomInspect || props.isVisible
    const bomId = modalEntityId || props.bomId

    const onClose = () => {
        resetState()
        if (props.onClose) {
            props.onClose()
        } else {
            dispatch(closeModal())
        }
    }

    useEffect(() => {
        if (isVisible && bomId) {
            getBomById(bomId, {
                relations: [BomRelation.BomComponents, BomRelation.BomGroup, BomRelation.Product],
            }).then((response: Response<Bom>) => {
                if (!response.successful) {
                    setModalState(ModalState.Error)
                } else {
                    setBom(response.data)
                    setModalState(ModalState.Ready)
                }
            })
        }
    }, [activeModal, modalEntityId])

    const resetState = () => {
        setBom(undefined)
        setModalState(ModalState.Ready)
    }

    return (
        <Modal open={isVisible} footer={null} onCancel={onClose} width={900}>
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.Ready && bom && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Inspect Bom</Title>
                    </Header>
                    <Divider />
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Code</Label>
                            <Input style={{ width: '100%' }} value={bom.bomCode} readOnly={true} />
                        </RowElement>
                        <RowElement>
                            <Label>Name</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={bom.product?.name}
                                readOnly={true}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Product Group</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={bom.product?.productGroupCode}
                                readOnly={true}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Bom Group</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={bom.group?.name}
                                readOnly={true}
                            />
                        </RowElement>
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Assembly Minutes</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={bom.assemblyMinutes}
                                readOnly={true}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Instructions</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={bom.instructionLink ?? 'No instructions'}
                                readOnly={true}
                            />
                        </RowElement>
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Description</Label>
                            <Input.TextArea
                                style={{ width: '100%', minHeight: 120 }}
                                readOnly={true}
                                value={bom.product?.description}
                            />
                        </RowElement>
                        <RowElement />
                    </InputRow>

                    <Label>Components</Label>
                    <Table
                        dataSource={bom.bomComponents}
                        columns={[
                            {
                                title: 'Code',
                                key: 'code',
                                render: (bomComponent: BomComponent) => {
                                    return bomComponent.product?.code
                                },
                            },
                            {
                                title: 'Name',
                                key: 'name',
                                render: (bomComponent: BomComponent) => {
                                    return bomComponent.product?.name
                                },
                            },
                            {
                                title: 'Amount',
                                key: 'amount',
                                render: (bomComponent: BomComponent) => {
                                    return bomComponent.amount
                                },
                            },
                        ]}
                        pagination={false}
                    />
                </Box>
            )}
        </Modal>
    )
}
