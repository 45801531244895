import { useState } from 'react'
import { Button, styled } from '@mui/material'
import { Input, Modal, Select } from 'antd'
import { Moment } from 'moment'
import { OrderedListOutlined } from '@ant-design/icons'
import { TaskDepartment, TaskType, WarehouseId } from '../../../models/models'
import { createTaskTypeRequest } from '../../../api/task-types'
import { toastFailure, toastSuccess } from '../../../util/toast'

export interface TaskTypeCreationState {
    title: string
    description: string
    dateToBeCompleted: Moment
    department: TaskDepartment
}

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 15px;
`

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 6px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 47%;
`

interface Props {
    department?: TaskDepartment
    warehouseId?: WarehouseId
    closeModal: (taskType?: TaskType) => void
}

export default function CreateTaskTypeModal(props: Props) {
    const [title, setTitle] = useState<string>('')
    const [link, setLink] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [warehouseId, setWarehouseId] = useState<WarehouseId>(
        props.warehouseId ?? WarehouseId.Monument
    )
    const [minutes, setMinutes] = useState<number>(0)
    const [department, setDepartment] = useState<TaskDepartment>(
        props.department ?? TaskDepartment.ANY
    )
    const [isCreating, setIsCreating] = useState<boolean>(false)

    const isSubmittable =
        !isCreating &&
        title.length > 0 &&
        typeof description === 'string' &&
        typeof minutes === 'number' &&
        minutes >= 0

    const createTaskType = async () => {
        if (isCreating) {
            return
        }
        try {
            setIsCreating(true)
            const response = await createTaskTypeRequest({
                description,
                title,
                warehouseId,
                minutes,
                link,
                department,
            })
            if (response.successful) {
                toastSuccess('Created template')
                props.closeModal(response.data)
                return
            }
            toastFailure(response.message)
        } catch (e) {
            toastFailure('Something unexpected went wrong')
        } finally {
            setIsCreating(false)
        }
    }

    return (
        <Modal visible={true} footer={null} onCancel={() => props.closeModal()}>
            <Container>
                <Header>
                    <OrderedListOutlined style={{ fontSize: '20px' }} />
                    <Title>Create task template</Title>
                </Header>

                <div>
                    <Label>Title </Label>
                    <Input value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div>
                    <Label>Description</Label>
                    <Input.TextArea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        style={{ minHeight: 200 }}
                    />
                </div>
                <InputRow>
                    <RowElement>
                        <Label>Warehouse</Label>
                        <Select
                            style={{ width: '100%' }}
                            value={warehouseId}
                            onChange={(value: WarehouseId) => setWarehouseId(value)}
                            options={[
                                { value: WarehouseId.Monument, label: 'Monument' },
                                { value: WarehouseId.Scandia, label: 'Scandia' },
                                { value: null, label: 'None' },
                            ]}
                        />
                    </RowElement>
                    <RowElement>
                        <Label>Department</Label>
                        <Select
                            style={{ width: '100%' }}
                            value={department}
                            onChange={(value: TaskDepartment) => setDepartment(value)}
                            options={[
                                { value: TaskDepartment.ASSEMBLY, label: 'Assembly' },
                                { value: TaskDepartment.LOGISTICS, label: 'Logistics' },
                                { value: TaskDepartment.ANY, label: 'Any' },
                            ]}
                        />
                    </RowElement>
                </InputRow>
                <InputRow>
                    <RowElement>
                        <Label>Minutes</Label>
                        <Input
                            style={{ width: '100%' }}
                            type="number"
                            value={minutes}
                            onWheel={(e) => e.preventDefault()}
                            onChange={(e) => setMinutes(Number(e.target.value))}
                        />
                    </RowElement>
                    <RowElement>
                        <Label>Link</Label>
                        <Input
                            style={{ width: '100%' }}
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                        />
                    </RowElement>
                </InputRow>

                <Button
                    variant="contained"
                    disabled={!isSubmittable}
                    onClick={() => {
                        if (isSubmittable) {
                            createTaskType()
                        }
                    }}
                >
                    Create Template
                </Button>
            </Container>
        </Modal>
    )
}
