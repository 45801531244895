import { StockView } from '../models/models'
import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'

interface StockViewParams {
    warehouseId: number
    reach: number
    limit: number
}
export const getStockView = (
    stockItemId: number,
    params: StockViewParams
): Promise<Response<StockView>> =>
    backendConnection.request({
        url: `/stock-manager/${stockItemId}`,
        method: 'GET',
        params,
    })
