import { createContext } from 'react'
import { AddedBuiltItem, AddedStockItem } from '../product-info/ProductInfo'

interface CartItemsContextProps {
    addedStockItems: AddedStockItem[]
    addedBuiltItems: AddedBuiltItem[]
    setAddedStockItems: (value: AddedStockItem[]) => void
    setAddedBuiltItems: (value: AddedBuiltItem[]) => void
}

export const CartItemsContext = createContext<CartItemsContextProps>({
    addedStockItems: [],
    addedBuiltItems: [],
    setAddedStockItems: () => undefined,
    setAddedBuiltItems: () => undefined,
})
