import { Alert, Box, Button, IconButton, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'

interface ConfirmationModalProps {
    title: string
    text: string
    confirmIcon: JSX.Element
    confirmText: string
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
}

export const ConfirmationModal = ({
    title,
    text,
    confirmIcon,
    confirmText,
    isOpen,
    onClose,
    onConfirm,
}: ConfirmationModalProps) => {
    return (
        <Modal open={isOpen} onClose={onClose} style={{ zIndex: 100 }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -100%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '10px 10px 10px 10px',
                    p: 4,
                }}
                style={{ padding: 0 }}
            >
                <Box
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div> {title} </div>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Box sx={{ margin: '20px' }}>
                    <Alert severity="warning">
                        <Typography
                            style={{
                                margin: '15px 25px',
                                fontSize: '16px',
                                whiteSpace: 'pre-line',
                            }}
                        >
                            {text}
                        </Typography>
                    </Alert>
                </Box>

                <Divider />
                <Box sx={{ textAlign: 'right', padding: '15px 23px 15px 0px' }}>
                    <Button
                        onClick={onClose}
                        color="secondary"
                        sx={{ margin: '5px' }}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => {
                            onConfirm()
                            onClose()
                        }}
                        style={{
                            borderRadius: '10px',
                            height: '40px',
                            paddingRight: '16px',
                        }}
                    >
                        {confirmIcon}
                        {confirmText}
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}
