import { Box, TableCell, TableRow } from '@mui/material'
import { DeleteOutlined, DownOutlined, EditOutlined, UpOutlined } from '@ant-design/icons'
import moment from 'moment'
import { downloadDocumentVersionFile } from '../../../../api/projects/documents'
import { Tooltip } from 'antd'
import AddButton from './AddButton'
import {
    archiveDocumentVersion,
    deleteComment,
    startUploadingDocument,
    startUpdatingComment,
    submitDocumentVersion,
} from '../../../../store/project-documents-slice'
import SubmitControl from './SubmitControls'
import { ProjectDocument, ProjectDocumentVersion, User } from '../../../../models/models'
import { useAppDispatch } from '../../../../store/hooks'

interface Props {
    isFirstVersion: boolean
    hasManyVersions: boolean
    isExpanded: boolean
    document: ProjectDocument
    documentVersion: ProjectDocumentVersion
    user: User

    setExpanded: (expanded: boolean) => void
}

function DocumentTableRow(props: Props) {
    const dispatch = useAppDispatch()
    const { document, documentVersion, isFirstVersion, isExpanded, hasManyVersions, setExpanded } =
        props

    return (
        <TableRow
            key={documentVersion.id}
            sx={{
                '&:last-child td, &:last-child th': { border: 0 },
            }}
        >
            <TableCell
                sx={{
                    paddingLeft: hasManyVersions && !isFirstVersion ? '35px' : '0px',
                }}
            >
                {isFirstVersion && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {document.type.name}

                        {hasManyVersions &&
                            isFirstVersion &&
                            (isExpanded ? (
                                <UpOutlined
                                    style={{
                                        marginLeft: 'auto',
                                        paddingLeft: '15px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setExpanded(false)}
                                />
                            ) : (
                                <DownOutlined
                                    style={{
                                        marginLeft: 'auto',
                                        paddingLeft: '15px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setExpanded(true)}
                                />
                            ))}
                    </Box>
                )}
            </TableCell>
            <TableCell>
                {moment(documentVersion.timeSubmittedAt || documentVersion.timeCreatedAt).format(
                    moment(documentVersion.timeSubmittedAt || documentVersion.timeCreatedAt).isSame(
                        moment(),
                        'year'
                    )
                        ? 'MMM Do, HH:mm'
                        : 'Do MMM YYYY, HH:mm'
                )}
            </TableCell>
            <TableCell>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                    }}
                >
                    <a
                        href={'#'}
                        onClick={async () => {
                            await downloadDocumentVersionFile(documentVersion, documentVersion.name)
                        }}
                    >
                        {documentVersion.name}
                    </a>
                    {isFirstVersion && (
                        <Tooltip title={'Upload new document version.'}>
                            <Box sx={{ marginLeft: 'auto' }}>
                                <AddButton
                                    onClick={() => dispatch(startUploadingDocument(document))}
                                />
                            </Box>
                        </Tooltip>
                    )}
                </Box>
            </TableCell>
            <TableCell>
                <SubmitControl
                    documentVersion={documentVersion}
                    user={props.user}
                    onSubmit={(documentVersion) => dispatch(submitDocumentVersion(documentVersion))}
                    onArchive={(documentVersion) =>
                        dispatch(archiveDocumentVersion(documentVersion))
                    }
                />
            </TableCell>
            <TableCell>
                {documentVersion?.creatorUser?.firstName} {documentVersion?.creatorUser?.lastName}
            </TableCell>
            <TableCell>
                <Box sx={{ maxWidth: '300px' }}>
                    <pre
                        style={{
                            fontFamily: 'inherit',
                            whiteSpace: 'pre-line',
                            wordWrap: 'break-word',
                        }}
                    >
                        {documentVersion.comment?.length > 0 ? documentVersion.comment : '-'}
                    </pre>
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                        <EditOutlined
                            onClick={() => dispatch(startUpdatingComment(documentVersion))}
                        />
                        {!!documentVersion.comment && (
                            <DeleteOutlined
                                onClick={() => dispatch(deleteComment(documentVersion))}
                            />
                        )}
                    </Box>
                </Box>
            </TableCell>
        </TableRow>
    )
}

export default DocumentTableRow
