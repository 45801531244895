import { TextField } from '@mui/material'
import { Box, styled } from '@mui/system'
import {
    SopBuiltItemOrderline,
    CallOff,
    SopStockItemOrderline,
    CallOffStatus,
} from '../../../models/models'
import moment from 'moment'
import { snakeCaseToTitleCase } from '../../../util/util'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
})

const InformationContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

const AnalysisCodesContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    margin: '20px',
})

const CallOffInformationContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    margin: '20px',
})

const isBuiltItemSop = (
    itemSop: SopBuiltItemOrderline | SopStockItemOrderline
): itemSop is SopBuiltItemOrderline => {
    return (itemSop as SopBuiltItemOrderline).builtItem !== undefined
}

const isStockItemSop = (
    itemSop: SopBuiltItemOrderline | SopStockItemOrderline
): itemSop is SopStockItemOrderline => {
    return (itemSop as SopStockItemOrderline).stockItemCallOffs !== undefined
}

const columns = [
    {
        title: 'Code',
        key: 'code',
        render: (element: SopBuiltItemOrderline | SopStockItemOrderline) => {
            if (isBuiltItemSop(element)) {
                return `${element.builtItem.bomCode}`
            } else if (isStockItemSop(element)) {
                return `${element.product.code}`
            }
        },
    },
    {
        title: 'Assigned on call offs',
        key: 'assigned',
        render: (element: SopBuiltItemOrderline | SopStockItemOrderline) => {
            if (isBuiltItemSop(element)) {
                return `${element.builtItemCallOffs.reduce((acc, cur) => acc + cur.amount, 0)}/${
                    element.amount
                }`
            } else if (isStockItemSop(element)) {
                return `${element.stockItemCallOffs.reduce((acc, cur) => acc + cur.amount, 0)}/${
                    element.amount
                }`
            }
        },
    },
    {
        title: 'Amount of call offs',
        key: 'name',
        render: (element: SopBuiltItemOrderline | SopStockItemOrderline) => {
            if (isBuiltItemSop(element)) {
                return `${element.builtItemCallOffs.length}`
            } else if (isStockItemSop(element)) {
                return `${element.stockItemCallOffs.length}`
            }
        },
    },
]

interface Props {
    callOff: CallOff
}
export default function CallOffTab(props: Props) {
    const callOff = props.callOff
    const tableData: Array<SopBuiltItemOrderline | SopStockItemOrderline> = [
        ...callOff.salesOrder.builtItemOrderlines,
        ...callOff.salesOrder.stockItemOrderlines,
    ]
    return (
        <Container>
            <InformationContainer>
                <CallOffInformationContainer>
                    <TextField
                        key="Created at"
                        id="outlined-read-only-input"
                        label="Created at"
                        defaultValue={
                            callOff.timeCreatedAt !== null
                                ? moment(callOff.timeCreatedAt).format('DD/MM/YYYY')
                                : 'No date'
                        }
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        key="Customer dispatch date"
                        style={{ marginTop: '10px' }}
                        id="outlined-read-only-input"
                        label="Customer dispatch date"
                        defaultValue={
                            callOff.customerDispatchDate !== null
                                ? moment(callOff.customerDispatchDate).format('DD/MM/YYYY')
                                : 'No date'
                        }
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        key="Delivery date"
                        style={{ marginTop: '10px' }}
                        id="outlined-read-only-input"
                        label="Delivery date"
                        defaultValue={
                            callOff.deliveryDate !== null
                                ? moment(callOff.deliveryDate).format('DD/MM/YYYY')
                                : 'No date'
                        }
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        key="Date to be completed by"
                        style={{ marginTop: '10px' }}
                        id="outlined-read-only-input"
                        label="Date to be completed by"
                        defaultValue={
                            callOff.dateToBeCompletedBy !== null
                                ? moment(callOff.dateToBeCompletedBy).format('DD/MM/YYYY')
                                : 'No date'
                        }
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                    {callOff.status === CallOffStatus.Dispatched && (
                        <>
                            <TextField
                                key="Dispatch Date"
                                style={{ marginTop: '10px' }}
                                id="outlined-read-only-input"
                                label="Dispatched Date"
                                defaultValue={
                                    callOff.dispatchedDate !== null
                                        ? moment(callOff.dispatchedDate).format('DD/MM/YYYY')
                                        : 'No date'
                                }
                                InputProps={{
                                    readOnly: true,
                                }}
                                disabled={true}
                            />
                            <TextField
                                key="Dispatch Comment"
                                style={{ marginTop: '10px' }}
                                id="outlined-read-only-input"
                                label="Dispatch Comment"
                                defaultValue={callOff.dispatchComment}
                                InputProps={{
                                    readOnly: true,
                                }}
                                disabled={true}
                            />
                        </>
                    )}
                </CallOffInformationContainer>
                <AnalysisCodesContainer>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        label="Id"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={callOff.id}
                    />
                    <TextField
                        style={{ marginTop: '10px' }}
                        id="outlined-basic"
                        variant="outlined"
                        label="Status"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={snakeCaseToTitleCase(callOff.status)}
                    />
                    <TextField
                        style={{ marginTop: '10px' }}
                        id="outlined-basic"
                        variant="outlined"
                        label="Is all goods in"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={
                            callOff?.orderConfirmations?.find((o) => !o.deliveredDate)
                                ? 'No'
                                : 'Yes'
                        }
                    />
                </AnalysisCodesContainer>
            </InformationContainer>
        </Container>
    )
}
