import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

export enum ActiveModal {
    CreateBomVersion = 'CreateBomVersion',
    AssemblyFieldCreate = 'AssemblyFieldCreate',
    AssemblyFieldUpdate = 'AssemblyFieldUpdate',
    ProductCreate = 'ProductCreate',
    BomGroupCreate = 'BomGroupCreate',
    BomGroupUpdate = 'BomGroupUpdate',
    BomCreate = 'BomCreate',
    BomUpdate = 'BomUpdate',
    BomUpdateMany = 'BomUpdateMany',
    BomInspect = 'BomInspect',
    None = 'None',
}

export interface ProductState {
    activeView: ProductView
    activeModal: ActiveModal
    modalEntityId?: number
    modalEntityIds?: number[]
    productFilters: ProductFilters
    bomFilters: BomFilters
    bomGroupFilters: BomGroupFilters
    assemblyFieldFilters: AssemblyFieldFilters
    // token that tells to update the table
    updateTableToken?: string
}

export interface ProductFilters {
    productId?: number
    code?: string
    name?: string
    productGroup?: string
}

export interface BomFilters {
    bomId?: number
    excludeCode?: string
    code?: string
    productName?: string
}

export interface BomGroupFilters {
    bomGroupId?: number
    name?: string
}

export interface AssemblyFieldFilters {
    assemblyFieldId?: number
    name?: string
}

export enum ProductView {
    Products = 'Products',
    Boms = 'Boms',
    BomGroups = 'BomGroups',
    AssemblyFields = 'AssemblyFields',
}

const initialState: ProductState = {
    activeView: ProductView.Products,
    productFilters: {},
    bomFilters: {},
    bomGroupFilters: {},
    assemblyFieldFilters: {},
    activeModal: ActiveModal.None,
    modalEntityId: undefined,
    updateTableToken: undefined,
}

export const taskSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        resetState(state) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state = initialState
        },
        openModal(
            state,
            action: PayloadAction<{ activeModal: ActiveModal; modalEntityId?: number }>
        ) {
            state.activeModal = action.payload.activeModal
            state.modalEntityId = action.payload.modalEntityId
        },
        setProductFilters(state, action: PayloadAction<ProductFilters>) {
            state.productFilters = action.payload
        },
        setBomFilters(state, action: PayloadAction<BomFilters>) {
            state.bomFilters = action.payload
        },
        setBomGroupFilters(state, action: PayloadAction<BomGroupFilters>) {
            state.bomGroupFilters = action.payload
        },
        setAssemblyFieldFilters(state, action: PayloadAction<AssemblyFieldFilters>) {
            state.assemblyFieldFilters = action.payload
        },
        setUpdateTableToken(state, action: PayloadAction<string | undefined>) {
            state.updateTableToken = action.payload
        },
        closeModal(state, action: PayloadAction<string | undefined>) {
            state.activeModal = ActiveModal.None
            state.updateTableToken = action.payload
            state.modalEntityId = undefined
            state.modalEntityIds = undefined
        },
        setActiveView(state, action: PayloadAction<ProductView>) {
            state.activeView = action.payload
        },
    },
})

export const selectActiveView = (state: RootState) => state.callOff.activeView

export const {
    resetState,
    setActiveView,
    openModal,
    closeModal,
    setProductFilters,
    setBomFilters,
    setBomGroupFilters,
    setUpdateTableToken,
    setAssemblyFieldFilters,
} = taskSlice.actions

export default taskSlice.reducer
