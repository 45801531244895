import React, { useEffect, useState } from 'react'
import SAV_landing from '../images/SAV_Frame_Landing.svg'
import Grid from '@mui/material/Grid'
import CTR_LOGO from '../images/CTR-LOGO.svg'
import { mainSAVTheme } from '../theme/Theme'
import {
    authenticate,
    AuthenticationStage,
    isAuthenticatedView,
    selectAuthenticationStage,
    setStage,
} from '../store/authentication-slice'
import { useAppSelector } from '../store/hooks'
import { Button, styled, Switch, TextField, Typography } from '@mui/material'
import ForgotPassword from './ForgotPassword'
import { toast, ToastContainer } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { SAGE_AUTH_CB_MESSAGES, isLoggedInMessage } from './SageAuth'
import { SAGE_TOKEN_KEY } from '../api/util/backend-connection'
import { Box } from '@mui/system'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { Urls } from '../util/urls'
import CTRLoader from './ui/loader/CTRloader'

export const ForgotPasswordButton = styled(Button)({
    color: 'white',
    '&:hover': {
        textDecoration: 'underline',
    },
})

interface stateType {
    toastMessage: string
}

export default function Login() {
    const stage = useSelector(selectAuthenticationStage)
    const [isForgottenPassword, setIsForgottenPassword] = useState(false)
    const location = useLocation<stateType>()
    const dispatch = useDispatch()
    const isAuthenticated = useAppSelector(isAuthenticatedView)
    if (isAuthenticated) {
        window.location.href = Urls.Landing
    }
    const BC = new BroadcastChannel('ctr')

    useEffect(() => {
        if (location.state) {
            const { toastMessage } = location.state
            toast.success(toastMessage)
        }
    }, [])

    useEffect(() => {
        BC.addEventListener('message', (message) => {
            if (Object.values(SAGE_AUTH_CB_MESSAGES)?.includes(message.data.type)) {
                if (isLoggedInMessage(message.data)) {
                    localStorage.setItem(SAGE_TOKEN_KEY, message.data.payload)
                }
                if (stage === AuthenticationStage.AwaitingSage) {
                    dispatch(setStage(AuthenticationStage.Initial))
                }
            }
        })
        return () => {
            BC.removeEventListener('message', () => {
                return null
            })
        }
    }, [stage])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        const email = data.get('email')
        const password = data.get('password')
        const remember = data.get('remember')

        if (typeof email === 'string' && typeof password === 'string') {
            dispatch(authenticate({ email, password, remember: !!remember }))
        }
    }

    return (
        <div
            className="Login"
            style={{ backgroundColor: `${mainSAVTheme.palette.primary.main}`, height: '100vh' }}
        >
            <Grid container component="main" sx={{ height: '100vh', padding: '90px 91px' }}>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                    }}
                >
                    <img
                        src={SAV_landing}
                        alt="SAV_landing_img"
                        style={{
                            flexShrink: 0,
                            minWidth: '100%',
                            minHeight: '100%',
                            maxHeight: '80vh',
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={8} md={5} style={{ position: 'relative' }}>
                    {isForgottenPassword ? (
                        <ForgotPassword onCloseHandler={() => setIsForgottenPassword(false)} />
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {stage === AuthenticationStage.AwaitingSage && (
                                <Box sx={{ mt: '25%' }}>
                                    <CTRLoader />
                                    <Typography style={{ color: '#FFF' }}>
                                        Awaiting sage login
                                    </Typography>
                                </Box>
                            )}
                            {stage === AuthenticationStage.Loading && (
                                <Box component="div" sx={{ mt: '25%' }}>
                                    <CTRLoader />
                                </Box>
                            )}

                            <Grid item xs={8}>
                                {stage !== AuthenticationStage.Loading &&
                                    stage !== AuthenticationStage.AwaitingSage && (
                                        <Box
                                            component="form"
                                            onSubmit={handleSubmit}
                                            sx={{ mt: '25%' }}
                                        >
                                            <TextField
                                                color="secondary"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="email"
                                                label="Email address"
                                                name="email"
                                                autoComplete="email"
                                                autoFocus
                                            />
                                            <TextField
                                                color="secondary"
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="password"
                                                label="Password"
                                                type="password"
                                                id="password"
                                                autoComplete="current-password"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color={'secondary'}
                                                        value="remember"
                                                        name="remember"
                                                    />
                                                }
                                                label={
                                                    <Typography style={{ color: '#fff' }}>
                                                        Remember me
                                                    </Typography>
                                                }
                                            />

                                            {stage === AuthenticationStage.IncorrectCredentials && (
                                                <Typography style={{ color: '#ff6060' }}>
                                                    Incorrect email or password provided!
                                                </Typography>
                                            )}

                                            {stage === AuthenticationStage.ServerError && (
                                                <Typography style={{ color: '#ff6060' }}>
                                                    An error occurred. Please try again later!
                                                </Typography>
                                            )}

                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="secondary"
                                                sx={{ mt: 3, mb: 2 }}
                                            >
                                                Log in
                                            </Button>
                                        </Box>
                                    )}
                            </Grid>
                        </Box>
                    )}

                    <img
                        src={CTR_LOGO}
                        alt="SAV_logo"
                        style={{ position: 'absolute', right: 0, bottom: 0 }}
                    />
                </Grid>
            </Grid>
            <ToastContainer />
        </div>
    )
}
