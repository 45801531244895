import { OrderConfirmation } from '../models/models'
import backendConnection from './util/backend-connection'
import { Response } from './util/with-response-formatter-interceptor'

interface CreateOcOrderlineAmount {
    orderlineId: number
    amount: number
}
export interface CreateOrderConfirmationDTO {
    orderlines: CreateOcOrderlineAmount[]
    expectedCompletelyArrivedDate: Date
    callOffId?: number
    purchaseOrderId: number
    code: string
}
export const createOrderConfirmationRequest = (dto: CreateOrderConfirmationDTO) =>
    backendConnection.request({
        url: `/order-confirmations`,
        method: 'POST',
        data: dto,
    })

export enum GetOrderConfirmationRelations {
    Orderlines = 'Orderlines',
    OrderlinePurchaseOrderOrderline = 'OrderlinePurchaseOrderOrderline ',
    PurchaseOrder = 'PurchaseOrder',
    Author = 'Author',
    GoodsDelivered = 'GoodsDelivered',
    Deliveries = 'Deliveries',
    CallOff = 'CallOff',
    CallOffProject = 'CallOffProject',
}

export enum GetOrderConfirmationSortBy {
    ExpectedCompletelyArrivedDate = 'ExpectedCompletelyArrivedDate',
    DeliveredDate = 'DeliveredDate',
}

export enum GetOrderConfirmationDateSearchType {
    ExpectedCompletelyArrivedDate = 'ExpectedCompletelyArrivedDate',
    DeliveredDate = 'DeliveredDate',
}

export interface GetOrderConfirmationDateSearch {
    dateRangeBegin?: Date
    dateRangeEnd?: Date
    dateSearchType?: GetOrderConfirmationDateSearchType
}
export interface GetOrderConfirmationDTO {
    relations?: GetOrderConfirmationRelations[]
    id?: number
    purchaseOrderSupplier?: string
    purchaseOrderCreatedBy?: string
    purchaseOrderIsActivated?: boolean
    purchaseOrderStatus?: string
    hasBeenDelivered?: boolean
    projectId?: number
    purchaseOrderId?: number
    projectName?: string
    purchaseOrderDocumentNo?: string
    code?: string
    warehouseId?: number
    dateSearch?: GetOrderConfirmationDateSearch
    sortBy?: GetOrderConfirmationSortBy
    skip?: number
    limit?: number
}

export interface ProductAmount {
    id: number
    amount: number
}

export interface AddOrderlineDTO {
    orderlineId: number
    amount: number
}

export interface DeleteOrderlineDTO {
    orderlineId: number
}

export interface UpdateOrderConfirmationDTO {
    callOffId?: number
    createOrderlines?: AddOrderlineDTO[]
    updateOrderlines?: ProductAmount[]
    deleteOrderlines?: DeleteOrderlineDTO[]
    expectedDeliveryDate?: Date
}

export const getOrderConfirmationsRequest = (
    params: GetOrderConfirmationDTO
): Promise<Response<{ count: number; entities: OrderConfirmation[] }>> =>
    backendConnection.request({
        url: `/order-confirmations`,
        method: 'GET',
        params,
    })

export const getOrderConfirmationRequest = (
    id: number,
    params: GetOrderConfirmationDTO
): Promise<Response<OrderConfirmation>> =>
    backendConnection.request({
        url: `/order-confirmations/${id}`,
        method: 'GET',
        params,
    })

export const deleteOrderConfirmationRequest = (id: number): Promise<Response<OrderConfirmation>> =>
    backendConnection.request({
        url: `/order-confirmations/${id}`,
        method: 'DELETE',
    })

export const updateOrderConfirmationRequest = (
    id: number,
    dto: UpdateOrderConfirmationDTO
): Promise<Response<OrderConfirmation>> =>
    backendConnection.request({
        url: `/order-confirmations/${id}`,
        method: 'PUT',
        data: dto,
    })
