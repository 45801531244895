import { OpportunityStatus } from '../../models/models'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { ReactElement } from 'react'
import { snakeCaseToTitleCase } from '../../util/util'

export function OpportunityStatusText(status: OpportunityStatus): ReactElement {
    return (
        <>
            <FiberManualRecordIcon
                style={{ fontSize: '12px', marginRight: '5px', color: status.hexColor }}
            />
            {snakeCaseToTitleCase(status.name)}
        </>
    )
}
