import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'
import { AbsentDay, AbsentDayType, DaysOff, DaysOffType, UserRole } from '../models/models'

export const getAbsentDaysByIdRequest = (id: number): Promise<Response<AbsentDay>> => {
    return backendConnection.request({
        url: `days-off/absent-days/${id}`,
        method: 'GET',
    })
}

export interface GetManyAbsentDaysQueryParamsDTO {
    startDate: Date
    endDate: Date
    userIds?: number[]
    roles?: UserRole[]
    dates?: Date[]
    warehouseId?: number
}

export const getAbsentDaysRequest = (
    dto: GetManyAbsentDaysQueryParamsDTO
): Promise<Response<AbsentDay[]>> =>
    backendConnection.request({
        url: `days-off/absent-days`,
        method: 'GET',
        params: dto,
    })

export const getDaysOffByIdRequest = (id: number): Promise<Response<DaysOff>> => {
    return backendConnection.request({
        url: `days-off/${id}`,
        method: 'GET',
    })
}

export interface GetManyDaysOffQueryParamsDTO {
    startDate: Date
    endDate: Date
    userIds?: number[]
    roles?: UserRole[]
    dates?: Date[]
    warehouseId?: number
}

export const getDaysOffRequest = (
    dto: GetManyDaysOffQueryParamsDTO
): Promise<Response<DaysOff[]>> => {
    return backendConnection.request({
        url: `days-off`,
        method: 'GET',
        params: dto,
    })
}

export interface DayDTO {
    date: Date
    type: AbsentDayType
    minutes?: number
}

export interface DaysOffDTO {
    type: DaysOffType
    userId: number
    absentDays: DayDTO[]
}

export const updateDaysOffRequest = (
    daysOffDTO: DaysOffDTO,
    daysOffId: number
): Promise<Response<DaysOff>> =>
    backendConnection.request({
        url: `days-off/${daysOffId}`,
        method: 'PUT',
        data: daysOffDTO,
    })

export const createDaysOffRequest = (daysOffDTO: DaysOffDTO): Promise<Response<void>> =>
    backendConnection.request({
        url: `days-off`,
        method: 'POST',
        data: daysOffDTO,
    })

export const deleteDaysOffRequest = (daysOffId: number): Promise<Response<void>> =>
    backendConnection.request({
        url: `days-off/${daysOffId}`,
        method: 'DELETE',
    })
