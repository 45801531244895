export enum Urls {
    Login = '/login',
    ResetPassword = '/forgot-password/:token',
    SageAuth = '/sage-auth',
    VerifyProfile = '/verify-email/:token',
    Dashboard = '/dashboard',
    Orders = '/dashboard/orders',
    Profile = '/dashboard/profile',
    ProductionSchedule = '/dashboard/production-schedule',
    SingleProjectView = '/dashboard/project-master/:id/:tab?/:deepId?',
    ProjectMaster = '/dashboard/project-master',
    WarehouseMap = '/dashboard/warehouse-map',
    StockManager = '/dashboard/stock-manager',
    ProductAuthorization = '/dashboard/product-authorization',
    Users = '/dashboard/users',
    Products = '/dashboard/products',
    Calendar = '/dashboard/calendar',
    WorksheetsAssembly = '/dashboard/worksheets/assembly',
    WorksheetsAssemblyWithID = '/dashboard/worksheets/assembly/:id?',
    Tasks = '/dashboard/worksheets/tasks',
    WorksheetsLogistics = '/dashboard/worksheets/logistics',
    Notifications = '/dashboard/notifications',
    Landing = '/',
    Assemblies = '/dashboard/assemblies',
    CallOffs = '/dashboard/calloffs',
    GeneralAssemblies = '/dashboard/general-assemblies',
}
