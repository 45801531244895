import { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Input, Modal } from 'antd'
import { TaskType, TaskDepartment, WarehouseId } from '../../../models/models'
import { toastFailure } from '../../../util/toast'
import { CircularProgress, styled } from '@mui/material'
import '../../tables/table-styles.css'
import { getTaskTypeByIdRequest, TaskTypeRelation } from '../../../api/task-types'
import { OrderedListOutlined } from '@ant-design/icons'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 47%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

interface UpdateTaskTypeModalProps {
    taskTypeId: number
    closeModal: () => void
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

export default function InspectTaskTypeModal({ taskTypeId, closeModal }: UpdateTaskTypeModalProps) {
    const [title, setTitle] = useState<string>('')
    const [link, setLink] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [warehouseId, setWarehouseId] = useState<WarehouseId>()
    const [minutes, setMinutes] = useState<number>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const [taskTypeDepartment, setTaskDepartment] = useState<TaskDepartment>(TaskDepartment.ANY)
    const [taskType, setTaskType] = useState<TaskType>()

    useEffect(() => {
        getTaskType()
    }, [])

    const getTaskType = async () => {
        const response = await getTaskTypeByIdRequest(taskTypeId, {
            relations: [TaskTypeRelation.CreatedBy],
        })

        if (response.successful) {
            const taskType = response.data
            setTaskType(taskType)
            setTitle(taskType.title)
            setDescription(taskType.description)
            setTaskDepartment(taskType.department)
            setMinutes(taskType.minutes)
            setLink(taskType.link || 'None')
            setWarehouseId(taskType.warehouseId)
            setModalState(ModalState.Success)
        } else {
            if (response.status === 404) {
                setModalState(ModalState.NotFound)
            } else {
                setModalState(ModalState.Error)
            }
            toastFailure(response.message)
        }
    }

    return (
        <Modal open={true} footer={null} onCancel={closeModal}>
            {modalState === ModalState.Loading && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        margin: '20px',
                        width: '100%',
                        height: '500px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                </Box>
            )}
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.NotFound && (
                <Box>Could not find the taskType with the id of {taskTypeId}</Box>
            )}
            {modalState === ModalState.Success && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Update taskType {taskTypeId}</Title>
                    </Header>
                    <Divider />
                    <div>
                        <Label>Title </Label>
                        <Input
                            value={title}
                            readOnly={true}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div>
                        <Label>Description</Label>
                        <Input.TextArea
                            value={description}
                            readOnly={true}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{ minHeight: 200 }}
                        />
                    </div>
                    <InputRow>
                        <RowElement>
                            <Label>Warehouse</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                value={
                                    [
                                        { value: WarehouseId.Monument, label: 'Monument' },
                                        { value: WarehouseId.Scandia, label: 'Scandia' },
                                        { value: null, label: 'None' },
                                    ].find((option) => option.value === warehouseId)?.label ||
                                    'None'
                                }
                                onWheel={(e) => e.preventDefault()}
                                onChange={(e) => setMinutes(Number(e.target.value))}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Department</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                value={
                                    [
                                        { value: TaskDepartment.ASSEMBLY, label: 'Assembly' },
                                        { value: TaskDepartment.LOGISTICS, label: 'Logistics' },
                                        { value: TaskDepartment.ANY, label: 'Any' },
                                    ].find((option) => option.value === taskTypeDepartment)
                                        ?.label || 'None'
                                }
                                onWheel={(e) => e.preventDefault()}
                                onChange={(e) => setMinutes(Number(e.target.value))}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow>
                        <RowElement>
                            <Label>Minutes</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                type="number"
                                value={minutes}
                                onWheel={(e) => e.preventDefault()}
                                onChange={(e) => setMinutes(Number(e.target.value))}
                            />
                        </RowElement>

                        <RowElement>
                            <Label>Link</Label>
                            <Input style={{ width: '100%' }} readOnly={true} value={link} />
                        </RowElement>
                    </InputRow>
                </Box>
            )}
        </Modal>
    )
}
