import { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/system'
import { Button, Divider, Input, Modal } from 'antd'
import moment, { Moment } from 'moment'
import { GeneralAssembly, GeneralAssemblyLine, WarehouseId } from '../../../models/models'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { CircularProgress, styled } from '@mui/material'
import '../../tables/table-styles.css'
import {
    getGeneralAssemblyByIdRequest,
    GeneralAssemblyRelation,
    updateGeneralAssemblyRequest,
    UpdateGeneralAssemblyDto,
} from '../../../api/general-assemblies'
import { DeleteOutlined, OrderedListOutlined, UndoOutlined } from '@ant-design/icons'
import { calculateGeneralAssemblyMinutes, GeneralAssemblyMinutes } from '../../../util/util'
import GeneralAssemblyLinesTable, {
    GeneralAssemblyLinesTableColumns,
    TableLine,
} from '../../tables/GeneralAssemblyLinesTable'
import DatePicker from '../../ui/calendar/DatePicker'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 47%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

enum UpdateGeneralAssemblyLineState {
    Deleted,
    Added,
    Active,
}

interface UpdateGeneralAssemblyLine extends TableLine {
    state: UpdateGeneralAssemblyLineState
}

const generateLineId = () => {
    return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
}

const isLineDeleted = (generalAssemblyLine: UpdateGeneralAssemblyLine) => {
    return generalAssemblyLine.state === UpdateGeneralAssemblyLineState.Deleted
}

const isLineActive = (generalAssemblyLine: UpdateGeneralAssemblyLine) => {
    return generalAssemblyLine.state === UpdateGeneralAssemblyLineState.Active
}

const isLineAdded = (generalAssemblyLine: UpdateGeneralAssemblyLine) => {
    return generalAssemblyLine.state === UpdateGeneralAssemblyLineState.Added
}

interface UpdateGeneralAssemblyModalProps {
    generalAssemblyId: number
    closeModal: (generalAssembly?: GeneralAssembly) => void
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

export default function UpdateGeneralAssemblyModal({
    generalAssemblyId,
    closeModal,
}: UpdateGeneralAssemblyModalProps) {
    const [description, setDescription] = useState<string>('')
    const [dateToBeCompleted, setDateToBeCompleted] = useState<Moment | null>(null)
    const [completedAt, setCompletedAt] = useState<Moment | null>(null)
    const [warehouseId, setWarehouseId] = useState<WarehouseId>()
    const [minutes, setMinutes] = useState<GeneralAssemblyMinutes>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const [generalAssembly, setGeneralAssembly] = useState<GeneralAssembly>()
    const [generalAssemblyLines, setGeneralAssemblyLines] = useState<UpdateGeneralAssemblyLine[]>(
        []
    )

    const sortedGeneralAssemblyLines = useMemo(() => {
        return generalAssemblyLines.sort((a, b) => {
            return a.state - b.state
        })
    }, [generalAssemblyLines])

    useEffect(() => {
        if (!generalAssembly?.lines) {
            return
        }
        const updateLines = generalAssembly?.lines.map((line) => {
            const updateLine: UpdateGeneralAssemblyLine = {
                ...line,
                state: UpdateGeneralAssemblyLineState.Active,
            }
            return updateLine
        })
        setGeneralAssemblyLines(updateLines)
    }, [generalAssembly])

    useEffect(() => {
        getGeneralAssembly()
    }, [])

    const getAssemblyLineById = (id: number): UpdateGeneralAssemblyLine | undefined => {
        return generalAssemblyLines.find((l) => l.id === id)
    }

    const createAssemblyLine = () => {
        if (!generalAssembly) {
            return
        }
        const line: UpdateGeneralAssemblyLine = {
            generalAssemblyId: generalAssembly.id,
            id: generateLineId(),
            generalAssembly: generalAssembly,
            completedAt: undefined,
            assembledById: undefined,
            assembledBy: undefined,
            note: '',
            serial: '',
            state: UpdateGeneralAssemblyLineState.Added,
        }

        setGeneralAssemblyLines([...generalAssemblyLines, line])
    }

    const deleteAssemblyLine = (id: number) => {
        setGeneralAssemblyLines(
            [...generalAssemblyLines]
                //remove lines if they are added
                .filter((line) => {
                    return !(line.id === id && line.state === UpdateGeneralAssemblyLineState.Added)
                })
                //if they are active set to deleted
                .map((line) => {
                    if (line.id === id) {
                        line.state = UpdateGeneralAssemblyLineState.Deleted
                    }
                    return line
                })
        )
    }

    const unDeleteAssemblyLine = (id: number) => {
        setGeneralAssemblyLines(
            [...generalAssemblyLines].map((line) => {
                if (line.id === id && line.state === UpdateGeneralAssemblyLineState.Deleted) {
                    line.state = UpdateGeneralAssemblyLineState.Active
                }
                return line
            })
        )
    }

    const onOk = async () => {
        if (!generalAssembly) {
            return
        }
        const linesToCreate = generalAssemblyLines
            .filter((line) => line.state === UpdateGeneralAssemblyLineState.Added)
            .map((l) => ({}))
        const linesToDelete = generalAssemblyLines
            .filter((line) => line.state === UpdateGeneralAssemblyLineState.Deleted)
            .map((l) => l.id)

        const dto: UpdateGeneralAssemblyDto = {
            createLines: linesToCreate,
            deleteLines: linesToDelete,
        }
        if (
            dateToBeCompleted &&
            !dateToBeCompleted.isSame(generalAssembly?.dateToBeCompletedBy, 'day')
        ) {
            dto.dateToBeCompletedBy = dateToBeCompleted.toDate()
        }

        if (generalAssembly.description !== description) {
            dto.description = description
        }
        const response = await updateGeneralAssemblyRequest(generalAssemblyId, dto)

        if (!response.successful) {
            toastFailure(response.message)
            return
        }

        toastSuccess('Updated General Assembly')
        closeModal(response.data)
    }

    const getGeneralAssembly = async () => {
        const response = await getGeneralAssemblyByIdRequest(generalAssemblyId, {
            joins: [
                GeneralAssemblyRelation.CreatedBy,
                GeneralAssemblyRelation.Bom,
                GeneralAssemblyRelation.Lines,
            ],
        })

        if (response.successful) {
            const generalAssembly = response.data
            setGeneralAssembly(generalAssembly)
            setDescription(generalAssembly.description)
            setCompletedAt(generalAssembly.completedAt ? moment(generalAssembly.completedAt) : null)
            setWarehouseId(generalAssembly.warehouseId)
            setDateToBeCompleted(
                generalAssembly.dateToBeCompletedBy
                    ? moment(generalAssembly.dateToBeCompletedBy)
                    : null
            )
            setMinutes(calculateGeneralAssemblyMinutes(generalAssembly))
            setModalState(ModalState.Success)
        } else {
            if (response.status === 404) {
                setModalState(ModalState.NotFound)
            } else {
                setModalState(ModalState.Error)
            }
            toastFailure(response.message)
        }
    }

    return (
        <Modal open={true} footer={null} onCancel={() => closeModal()} width={700}>
            {modalState === ModalState.Loading && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        margin: '20px',
                        width: '100%',
                        height: '500px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                </Box>
            )}
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.NotFound && (
                <Box>Could not find the general assembly with the id of {generalAssemblyId}</Box>
            )}
            {modalState === ModalState.Success && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Update General Assembly {generalAssemblyId}</Title>
                    </Header>
                    <Divider />
                    <div>
                        <Label>Description</Label>
                        <Input.TextArea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{ minHeight: 100 }}
                        />
                    </div>
                    <InputRow>
                        <RowElement>
                            <Label>Date to be completed</Label>
                            <DatePicker
                                style={{ width: '100%' }}
                                value={dateToBeCompleted}
                                onChange={setDateToBeCompleted}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Completed Date</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                disabled={true}
                                value={
                                    completedAt
                                        ? moment(completedAt).format('DD/MM/YYYY')
                                        : 'Not completed'
                                }
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow>
                        <RowElement>
                            <Label>Warehouse</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                disabled={true}
                                value={
                                    [
                                        { value: WarehouseId.Monument, label: 'Monument' },
                                        { value: WarehouseId.Scandia, label: 'Scandia' },
                                        { value: null, label: 'None' },
                                    ].find((option) => option.value === warehouseId)?.label ||
                                    'None'
                                }
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow>
                        <RowElement>
                            <Label>BOM</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                disabled={true}
                                value={generalAssembly?.builtItem?.bomCode || 'Could not determine'}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Minutes completed</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                disabled={true}
                                value={`${minutes?.completed}/${minutes?.total}`}
                            />
                        </RowElement>
                    </InputRow>
                    {generalAssembly?.lines && (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    margin: '10px 10px 5px 10px',
                                }}
                            >
                                <Label>Lines</Label>
                                <Button
                                    type="default"
                                    disabled={false}
                                    onClick={createAssemblyLine}
                                >
                                    Create line
                                </Button>
                            </Box>

                            <GeneralAssemblyLinesTable
                                rowClassName={(line) => {
                                    const updateGeneralAssemblyLine =
                                        line as UpdateGeneralAssemblyLine

                                    if (
                                        updateGeneralAssemblyLine.state ===
                                        UpdateGeneralAssemblyLineState.Added
                                    ) {
                                        return 'green-row'
                                    }

                                    if (
                                        updateGeneralAssemblyLine.state ===
                                        UpdateGeneralAssemblyLineState.Deleted
                                    ) {
                                        return 'red-row'
                                    }
                                    return ''
                                }}
                                actionComponents={[
                                    (id: number) => {
                                        const assemblyLine = getAssemblyLineById(id)
                                        if (!assemblyLine) {
                                            return <></>
                                        }
                                        if (isLineAdded(assemblyLine)) {
                                            return (
                                                <Button
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => deleteAssemblyLine(id)}
                                                />
                                            )
                                        }
                                        if (isLineDeleted(assemblyLine)) {
                                            return (
                                                <Button
                                                    icon={<UndoOutlined />}
                                                    onClick={() => unDeleteAssemblyLine(id)}
                                                />
                                            )
                                        }
                                        if (isLineActive(assemblyLine)) {
                                            return (
                                                <Button
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => deleteAssemblyLine(id)}
                                                />
                                            )
                                        }
                                        return <></>
                                    },
                                ]}
                                columns={[
                                    GeneralAssemblyLinesTableColumns.AssembledBy,
                                    GeneralAssemblyLinesTableColumns.CompletedAt,
                                    GeneralAssemblyLinesTableColumns.Note,
                                ]}
                                datasource={sortedGeneralAssemblyLines}
                            />
                        </>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                        <Button type="primary" disabled={false} onClick={onOk}>
                            Update
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    )
}
