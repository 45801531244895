import { Table, TableColumnType, Tag } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Response } from '../../api/util/with-response-formatter-interceptor'
import { User } from '../../models/models'
import { toastFailure } from '../../util/toast'
import useDebounce from '../project-master/single-project-view/hooks'
import '../tables/table-styles.css'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { formatEnumName } from '../../util/util'
import moment from 'moment'

interface Props {
    pagination?: boolean
    //antd ColumnType<T> does not have title property
    columns?: Array<TableColumnType<User>>
    actionComponents?: Array<(user: User) => JSX.Element>
    dependencies?: any
    request: (pagination?: {
        skip: number
        limit: number
    }) => Promise<Response<{ count: number; entities: User[] }>>
}

export const UserTableColumns: { [key in string]: TableColumnType<User> } = {
    Id: {
        title: 'Id',
        key: 'id',
        render: (user: User) => {
            return user.id
        },
    },

    Activated: {
        title: 'Activated',
        key: 'Activated',
        render: (user: User) => {
            return user.verifiedAt ? (
                <Tag icon={<CheckCircleOutlined />} color="success">
                    Activated
                </Tag>
            ) : (
                <Tag icon={<ExclamationCircleOutlined />} color="warning">
                    Not Activated
                </Tag>
            )
        },
    },
    CreatedAt: {
        title: 'Created At',
        key: 'CreatedAt',
        render: (user: User) => {
            return moment(user.timeCreatedAt).format('DD/MM/YYYY HH:mm')
        },
    },
    Warehouse: {
        title: 'Warehouse',
        key: 'Waerhouse',
        render: (user: User) => {
            return user.warehouse ? user.warehouse.name : '-'
        },
    },
    Name: {
        title: 'Name',
        key: 'Name',
        render: (user: User) => {
            return `${user.firstName} ${user.lastName}`
        },
    },
    Email: {
        title: 'Email',
        key: 'Email',
        render: (user: User) => {
            return user.email
        },
    },
    PhoneNumber: {
        title: 'Phone Number',
        key: 'PhoneNumber',
        render: (user: User) => {
            return user?.phoneNumber ? (
                <a href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</a>
            ) : (
                '-'
            )
        },
    },
    Role: {
        title: 'Role',
        key: 'role',
        render: (user: User) => {
            return formatEnumName(user.role)
        },
    },
} as const

const DEFAULT_COLUMNS = [
    UserTableColumns.Activated,
    UserTableColumns.CreatedAt,
    UserTableColumns.Name,
    UserTableColumns.Email,
    UserTableColumns.PhoneNumber,
    UserTableColumns.Role,
    UserTableColumns.Warehouse,
]

export default function UsersTable({
    request,
    columns,
    pagination,
    actionComponents,
    dependencies,
}: Props) {
    const [users, setUsers] = useState<User[]>([])
    const [usersAmount, setUsersAmount] = useState<number>(0)
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)

    const getUsersDeps = useDebounce(JSON.stringify(dependencies), 200)

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const actionComponentsRenderer = useCallback(
        (user) => {
            return <>{actionComponents?.map((a) => a(user))}</>
        },
        [actionComponents]
    )

    const tableColumns = useMemo(() => {
        let cols = columns ? Object.values(columns) : DEFAULT_COLUMNS

        if (actionComponents) {
            cols = [
                ...cols,
                {
                    title: 'Actions',
                    key: 'actions',
                    render: actionComponentsRenderer,
                },
            ]
        }
        return cols
    }, [columns, actionComponentsRenderer])

    const getUsers = async (resetPage = false) => {
        const response = await request({
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        })

        if (response.successful) {
            const { count, entities } = response.data
            resetPage && setPage(1)
            setUsersAmount(count)
            setUsers(entities)
        } else {
            toastFailure(response.message)
        }
    }

    // this happens when dependencies change outside the table, e.g filters given in with getUsersDeps
    useEffect(() => {
        getUsers(true)
    }, [getUsersDeps, rowsPerPage])

    // this happens when dependencies change outside the table, e.g filters given in with getUsersDeps
    useEffect(() => {
        getUsers()
    }, [page])

    return (
        <Table
            rowKey="id"
            columns={tableColumns}
            dataSource={users}
            expandable={undefined}
            size="small"
            pagination={
                pagination
                    ? {
                          defaultPageSize: 10,
                          current: page,
                          onChange: pageChange,
                          total: usersAmount,
                      }
                    : false
            }
        />
    )
}
