import { Box } from '@mui/system'
import { useState } from 'react'
import StockItems from './tabs/stock-items/StockItems'
import AddedItems from './tabs/added-items/AddedItems'
import { Product } from '../../../../models/models'
import { CartItemsContext } from '../project-info/CartItems'
import { TabContext, TabList } from '@mui/lab'
import { Tab } from '@mui/material'
import ExistingBuiltItems from './tabs/built-items/BuiltItems'

enum TabKeys {
    BuiltItems = 'BUILT_ITEMS',
    StockItems = 'STOCK_ITEMS',
    View = 'View',
}

export type AddedBuiltItem = {
    id: number
    assemblyMinutes: number
    amount: number
    bomCode: string
}

export interface AddedStockItem extends Product {
    amount: number
}

export default function ProductInfo() {
    const [activeTab, setActiveTab] = useState<TabKeys>(TabKeys.View)
    const [addedStockItems, setAddedStockItems] = useState<AddedStockItem[]>([])
    const [addedBuiltItems, setAddedBuiltItems] = useState<AddedBuiltItem[]>([])
    return (
        <TabContext value={activeTab}>
            <CartItemsContext.Provider
                value={{
                    addedStockItems,
                    setAddedStockItems,
                    addedBuiltItems,
                    setAddedBuiltItems,
                }}
            >
                <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ width: '100%' }}>
                            <TabList
                                onChange={(event, value) => {
                                    setActiveTab(value)
                                }}
                            >
                                <Tab
                                    label={'Added Items'}
                                    value={TabKeys.View}
                                    style={{ textTransform: 'none' }}
                                />
                                <Tab
                                    label={'Add Built items'}
                                    value={TabKeys.BuiltItems}
                                    style={{ textTransform: 'none' }}
                                />
                                <Tab
                                    label={'Add Stock items'}
                                    value={TabKeys.StockItems}
                                    style={{ textTransform: 'none' }}
                                />
                            </TabList>
                            <Box
                                sx={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {activeTab === TabKeys.BuiltItems && <ExistingBuiltItems />}
                                {activeTab === TabKeys.StockItems && <StockItems />}
                                {activeTab === TabKeys.View && <AddedItems />}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </CartItemsContext.Provider>
        </TabContext>
    )
}
