import Box from '@mui/material/Box'
import { Button, Dialog, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { useDispatch } from 'react-redux'
import { setRegularView } from '../../../store/warehouse-map-slice'

const ModalFooter = (props: { onConfirm: () => void; onClose: () => void }) => {
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
    }))

    const ModalButton = styled(Button)(() => ({
        padding: '0px',
        minWidth: '0px',
        margin: '10px 10px 5px 0px',
    }))

    return (
        <Row>
            <ModalButton variant="outlined" size="small" onClick={props.onClose}>
                Take me back
            </ModalButton>
            <ModalButton
                variant="outlined"
                size="small"
                onClick={() => {
                    props.onConfirm()
                    props.onClose()
                }}
            >
                Cancel dispatch
            </ModalButton>
        </Row>
    )
}

interface AssemblyTransferModalProps {
    isOpen: boolean
    onClose: () => void
}

export const CancelDispatchModal = ({ isOpen, onClose }: AssemblyTransferModalProps) => {
    const dispatch = useDispatch()

    return (
        <Dialog open={isOpen} onClose={onClose} style={{ zIndex: 100 }} fullWidth maxWidth={'sm'}>
            <Box style={{ width: '100%', padding: '20px' }}>
                <Typography color="red" fontSize={20}>
                    Cancel
                </Typography>
                <Typography>Are you sure that you want to cancel the dispatch?</Typography>
                <ModalFooter
                    onConfirm={() => {
                        dispatch(setRegularView())
                    }}
                    onClose={onClose}
                />
            </Box>
        </Dialog>
    )
}
