import { BugReportOutlined, CheckCircleOutline } from '@mui/icons-material'
import { ListItemAvatar, ListItemText, Typography, ListItem, Divider } from '@mui/material'
import { BugReportDTO, IssueType } from '../../api/bug-reporting'
import moment from 'moment'
export const BugReportListItem = ({
    lastIndex,
    bugReport: { issueType, summary, description, owner, timeCreatedAt, jiraTicketUrl },
}: {
    bugReport: BugReportDTO
    lastIndex?: boolean
}) => {
    const BugReportAvatar =
        issueType === IssueType.Bug ? (
            <BugReportOutlined fontSize={'large'} />
        ) : (
            <CheckCircleOutline />
        )
    return (
        <>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start" style={{ margin: '5px' }}>
                <ListItemAvatar>{BugReportAvatar}</ListItemAvatar>
                <ListItemText
                    style={{ fontSize: '3rem', maxWidth: 800 }}
                    primary={
                        <Typography variant="body1" fontSize={18} color="textPrimary">
                            {summary}
                        </Typography>
                    }
                    secondary={
                        <>
                            <Typography
                                variant="body2"
                                fontSize={15}
                                color="textPrimary"
                                style={{
                                    overflowWrap: 'break-word',
                                    width: '90%',
                                }}
                            >
                                {description}
                            </Typography>
                            <a href={jiraTicketUrl} target="_blank" rel="noopener noreferrer">
                                Issue on Jira
                            </a>
                        </>
                    }
                />
                <div style={{ width: '20%' }}>
                    Created by {owner.firstName} {owner.lastName}
                    <br />
                    {moment(timeCreatedAt).format('MMMM Do YYYY')}
                </div>
            </ListItem>
            {lastIndex && <Divider variant="inset" component="li" />}
        </>
    )
}
