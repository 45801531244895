import { Box, styled } from '@mui/system'
import { Table } from 'antd'
import { Assembly, CallOff } from '../../../models/models'
import moment from 'moment'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

interface Props {
    callOff: CallOff
}

const columns = [
    {
        title: 'Id',
        key: 'Id',
        render: (element: Assembly) => {
            return element.id
        },
    },
    {
        title: 'Assembly transfer',
        key: 'code',
        render: (element: Assembly) => {
            return element.assemblyTransferDate
                ? moment(element.assemblyTransferDate).format('DD/MM/YYYY')
                : 'Not completed'
        },
    },
    {
        title: 'Completed assembly',
        key: 'code',
        render: (element: Assembly) => {
            return element.completedAt
                ? moment(element.completedAt).format('DD/MM/YYYY')
                : 'Not completed'
        },
    },
    {
        title: 'Dispatch transfer',
        key: 'code',
        render: (element: Assembly) => {
            return element.dispatchTransferDate
                ? moment(element.dispatchTransferDate).format('DD/MM/YYYY')
                : 'Not completed'
        },
    },
    {
        title: 'Date to be completed by',
        key: 'code',
        render: (element: Assembly) => {
            return element.dateToBeCompletedBy
                ? moment(element.dateToBeCompletedBy).format('DD/MM/YYYY')
                : 'Not completed'
        },
    },
    {
        title: 'Amount to build',
        key: 'code',
        render: (element: Assembly) => {
            return element.amount
        },
    },
]
export default function AssembliesTab(props: Props) {
    const callOff = props.callOff
    return (
        <Container>
            <Table dataSource={callOff.assemblies} columns={columns} pagination={false} />
        </Container>
    )
}
