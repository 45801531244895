import { Grid } from '@mui/material'
import { Box, styled } from '@mui/system'
import { Button, Input } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { CartItemsContext } from '../../../project-info/CartItems'
import { Bom } from '../../../../../../models/models'
import AddBuiltItemModal from './AddBuiltItemModal'
import { BomRelation, getBomsRequest } from '../../../../../../api/bom'
import { toastFailure } from '../../../../../../util/toast'
import BomsTable from '../../../../../tables/BomTable'
import { BomsTableColumns } from '../../../../../tables/BomTable'
import { InspectBomModal } from '../../../../../products/modals/InspectBomModal'
import AddedItemsOverview, { AddedItemsTabKeys } from '../../AddedItemsOverview'

const InputsContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
}))

enum ActiveModal {
    None = 'None',
    Inspect = 'Inspect',
    Add = 'Add',
}

export default function ExistingBuiltItems() {
    const [builtItems, setBuiltItems] = useState<Bom[]>([])
    const [builtItemsAmount, setBuiltItemsAmount] = useState<number>()
    const [bomCode, setBomCode] = useState<string>('')
    const [modalBuiltItemId, setModalBuiltItemId] = useState<number>()
    const [activeModal, setActiveModal] = useState<ActiveModal>(ActiveModal.None)
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)
    const { addedBuiltItems, setAddedBuiltItems } = useContext(CartItemsContext)

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const addBuiltItem = (builtItem: Bom, amount: number) => {
        setAddedBuiltItems([
            ...addedBuiltItems,
            {
                amount,
                assemblyMinutes: builtItem.assemblyMinutes,
                bomCode: builtItem.bomCode,
                id: builtItem.id,
            },
        ])
    }

    const getBuiltItems = async () => {
        const response = await getBomsRequest({
            withCount: true,
            bomCode,
            //antd tables pages are 1-indexed
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        })
        if (response.successful) {
            setBuiltItems(response.data.entities)
            setBuiltItemsAmount(response.data.count)
        } else {
            toastFailure(response.message)
        }
    }

    const openModal = (activeModal: ActiveModal, builtItemId: number) => {
        setActiveModal(activeModal)
        setModalBuiltItemId(builtItemId)
    }

    const closeModal = () => {
        setActiveModal(ActiveModal.None)
        setModalBuiltItemId(undefined)
    }

    useEffect(() => {
        getBuiltItems()
    }, [bomCode, page])

    return (
        <Grid container justifyContent="space-around" spacing={1} sx={{ paddingTop: '26px' }}>
            <Grid item>
                <InputsContainer>
                    <Input
                        prefix={'BOM Code'}
                        size="middle"
                        style={{
                            padding: 10,
                            borderRadius: '10px',
                            width: '100%',
                        }}
                        value={bomCode}
                        onChange={(e) => {
                            setBomCode(e.target.value)
                            pageChange(1, rowsPerPage)
                        }}
                    />
                </InputsContainer>
                <BomsTable
                    style={{ width: '1000px' }}
                    pagination={true}
                    actionComponents={[
                        (bom: Bom) => {
                            return (
                                <Button
                                    style={{ marginRight: '10px' }}
                                    onClick={() => openModal(ActiveModal.Inspect, bom.id)}
                                >
                                    Inspect
                                </Button>
                            )
                        },
                        (bom: Bom) => {
                            return (
                                <Button
                                    type="primary"
                                    onClick={() => openModal(ActiveModal.Add, bom.id)}
                                >
                                    Add
                                </Button>
                            )
                        },
                    ]}
                    columns={[
                        BomsTableColumns.Id,
                        BomsTableColumns.Code,
                        BomsTableColumns.ProductName,
                        BomsTableColumns.BomGroupName,
                        BomsTableColumns.AssemblyTime,
                    ]}
                    dependencies={{ bomCode }}
                    request={(pagination) =>
                        getBomsRequest({
                            relations: [BomRelation.BomGroup, BomRelation.Product],
                            withCount: true,
                            bomCode,
                            skip: pagination?.skip,
                            limit: pagination?.limit,
                        })
                    }
                />
            </Grid>
            <Grid item>
                <AddedItemsOverview tab={AddedItemsTabKeys.BuiltItems} />
            </Grid>

            <InspectBomModal
                bomId={modalBuiltItemId}
                isVisible={activeModal === ActiveModal.Inspect && modalBuiltItemId !== undefined}
                onClose={closeModal}
            />

            {activeModal === ActiveModal.Add && modalBuiltItemId && (
                <AddBuiltItemModal
                    builtItemId={modalBuiltItemId}
                    isOpen={true}
                    onClose={closeModal}
                    onConfirm={addBuiltItem}
                />
            )}
        </Grid>
    )
}
