import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
    closeCompleteFlatstationAssemblyWorksheetModal,
    completeFlatstationWorksheet,
    completeWorksheet,
} from '../../../store/assembly-worksheets-slice'
import { useAppSelector } from '../../../store/hooks'
import { setRegularView, updateCompleteAssemblyState } from '../../../store/warehouse-map-slice'
import { toastFailure } from '../../../util/toast'
import { ActionBarContainer } from './ActionBarContainer'
import { Button } from 'antd'
import { CSSProperties, useMemo } from 'react'
import { WarehouseMapColors } from '../../../theme/colors'
import { CompleteAssemblyFlatstationPalletDto } from '../../../api/assemblies'

export const CompleteAssemblyActionBar = () => {
    const { assemblyWorksheetToComplete, completeAssemblyState } = useAppSelector(
        (state) => state.warehouseMap
    )
    const dispatch = useDispatch()
    const history = useHistory()

    if (!assemblyWorksheetToComplete) {
        toastFailure('Could not find worksheet to complete')
        return <div>Could not find worksheet to complete</div>
    }

    return (
        <ActionBarContainer>
            <Button
                onClick={() => {
                    history.push(`/dashboard/worksheets/assembly/${assemblyWorksheetToComplete.id}`)
                    dispatch(setRegularView())
                }}
                disabled={false}
            >
                Cancel
            </Button>
            <Button
                type="primary"
                onClick={() => {
                    if (!completeAssemblyState?.pallets) {
                        toastFailure('No pallets to complete')
                        return
                    }
                    const palletDtos: CompleteAssemblyFlatstationPalletDto[] = []
                    for (const pallet of completeAssemblyState.pallets) {
                        if (!pallet.spot) {
                            toastFailure('Not all pallets have been placed')
                            return
                        }
                        palletDtos.push({
                            amount: pallet.amount,
                            warehouseAreaSpotId: pallet.spot.id,
                        })
                    }
                    history.push(`/dashboard/worksheets/assembly/${assemblyWorksheetToComplete.id}`)
                    dispatch(completeFlatstationWorksheet(assemblyWorksheetToComplete, palletDtos))
                    dispatch(closeCompleteFlatstationAssemblyWorksheetModal())
                    dispatch(setRegularView())
                }}
                disabled={false}
            >
                Confirm
            </Button>
            <div style={{ width: '200px' }}></div>
            {completeAssemblyState?.pallets.map((pallet) => {
                const style: CSSProperties = {
                    height: '70px',
                    width: '70px',
                }
                if (completeAssemblyState.selectedPallet === pallet.key) {
                    style.borderColor = 'blue'
                    style.borderWidth = '1.5px'
                }
                if (pallet.spot) {
                    style.backgroundColor = '#E5F6DF'
                }

                return (
                    <Button
                        key={pallet.key}
                        style={style}
                        onClick={() =>
                            dispatch(
                                updateCompleteAssemblyState({
                                    ...completeAssemblyState,
                                    pallets: completeAssemblyState.pallets.map((p) => {
                                        if (p.key === pallet.key) {
                                            return {
                                                ...p,
                                                spot: null,
                                            }
                                        }
                                        return p
                                    }),
                                    selectedPallet: pallet.key,
                                })
                            )
                        }
                    >
                        <span>x</span>
                        <span style={{ fontSize: 30 }}> {pallet.amount}</span>
                    </Button>
                )
            })}
        </ActionBarContainer>
    )
}
