import { PlusOutlined } from '@ant-design/icons'
import { Box } from '@mui/material'

interface Props {
    onClick: () => void
    height?: number
    width?: number
    withoutBorder?: boolean
}

function AddButton(props: Props) {
    return (
        <Box>
            <Box
                onClick={props.onClick}
                sx={{
                    background: '#fff',
                    display: 'flex',
                    cursor: 'pointer',
                    height: props.height || 32,
                    width: props.width || 32,
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: props.withoutBorder ? null : '1px solid #d9d9d9',
                    borderRadius: '5px',
                }}
            >
                <PlusOutlined />
            </Box>
        </Box>
    )
}

export default AddButton
