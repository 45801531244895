import { Table } from 'antd'
import { OrderConfirmation, OrderConfirmationOrderline } from '../../models/models'

export default function OrderConfirmationOrderlineTable({
    orderConfirmation,
}: {
    orderConfirmation: OrderConfirmation
}) {
    //whether or not the purchase order orderline amounts
    const hasAmounts = !!orderConfirmation?.orderlines.find(
        (orderline: OrderConfirmationOrderline) => {
            return orderline.deliveredAmount !== undefined
        }
    )
    const columns = [
        {
            title: 'Product Code',
            key: 'code',
            render: (orderline: OrderConfirmationOrderline) => {
                return orderline?.purchaseOrderOrderline?.product?.code
            },
        },
        {
            title: 'Name',
            key: 'name',
            render: (orderline: OrderConfirmationOrderline) => {
                return orderline?.purchaseOrderOrderline?.product?.name
            },
        },
        {
            title: 'description',
            key: 'description',
            render: (orderline: OrderConfirmationOrderline) => {
                return orderline?.purchaseOrderOrderline?.product?.description
            },
        },
        {
            title: 'Amount',
            key: 'Amount',
            dataIndex: 'amount',
        },
    ]

    if (hasAmounts) {
        columns.push({
            title: 'Delivered',
            key: 'Delivered',
            render: (orderline: OrderConfirmationOrderline) => {
                return orderline.deliveredAmount ?? 'Indecisive'
            },
        } as any)
    }

    return (
        <Table
            size={'small'}
            dataSource={orderConfirmation.orderlines}
            columns={columns}
            pagination={false}
        />
    )
}
