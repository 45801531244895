import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'
import { SageWarehouse } from '../models/models'

const URL_PREFIX = '/sage-warehouse'

export const getManySageWarehouses = (): Promise<Response<SageWarehouse[]>> =>
    backendConnection.request({
        url: `${URL_PREFIX}`,
        method: 'GET',
    })
