import { Input, Radio, Select } from 'antd'
import { useSelector } from 'react-redux'
import { WarehouseId } from '../../../models/models'
import { useAppDispatch } from '../../../store/hooks'
import {
    setFilterAssemblyId,
    setFilterCallOffId,
    setFilterDispatchDate,
    setFilterDocumentNo,
    setFilterIsPurchaseOrderActivated,
    setFilterProjectName,
    setFilterPurchaseOrderCreator,
    setFilterPurchaseOrderStatus,
    setFilterPurchaseOrderSupplier,
    setFilterSavNumber,
    setFilterTransfer,
    setFilterWarehouseId,
    setPurchaseOrderCreators,
    setPurchaseOrderSuppliers,
    TransferFilter,
} from '../../../store/logistics-worksheet-slice'
import { RootState } from '../../../store/store'
import { Label } from '../../ui/Label'
import { styled } from '@mui/system'
import { useEffect } from 'react'
import {
    getPurchaseOrderCreatorsRequest,
    getPurchaseOrderSuppliersRequest,
    PurchaseOrderDocumentStatus,
} from '../../../api/purchase-orders'
import { toastFailure } from '../../../util/toast'

export const Container = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 15px;
`

type FilterDispatchDate = 'Today' | 'Upcoming'

const dispatchDateFilterOptions: Array<{ label: string; value: FilterDispatchDate }> = [
    { label: 'Today', value: 'Today' },
    { label: 'Upcoming', value: 'Upcoming' },
]

export const FilterBar = () => {
    const { user } = useSelector((state: RootState) => state.authentication)
    const dispatch = useAppDispatch()
    const {
        activeView,
        purchaseOrderCreators,
        purchaseOrderSuppliers,
        filterCallOffId,
        filterProjectName,
        filterSavNumber,
        filterTransfer,
        filterAssemblyId,
        filterDocumentNo,
        filterIsPurchaseOrderActivated,
        filterPurchaseOrderCreator,
        filterPurchaseOrderStatus,
        filterPurchaseOrderSupplier,
        filterDispatchDate,
        filterWarehouseId,
    } = useSelector((state: RootState) => state.logisticsWorksheets)

    const getPurchaseOrderCreators = async () => {
        const response = await getPurchaseOrderCreatorsRequest()
        if (response.successful) {
            dispatch(setPurchaseOrderCreators(response.data))
        } else {
            dispatch(setPurchaseOrderCreators([]))
            toastFailure(response.message)
        }
    }

    const getPurchaseOrderSuppliers = async () => {
        const response = await getPurchaseOrderSuppliersRequest()
        if (response.successful) {
            dispatch(setPurchaseOrderSuppliers(response.data))
        } else {
            dispatch(setPurchaseOrderSuppliers([]))
            toastFailure(response.message)
        }
    }

    useEffect(() => {
        if (purchaseOrderSuppliers.length === 0) {
            getPurchaseOrderSuppliers()
        }
    }, [])

    useEffect(() => {
        if (purchaseOrderCreators.length === 0) {
            getPurchaseOrderCreators()
        }
    }, [])

    return (
        <Container>
            <div style={{ width: '150px' }}>
                <Label>Warehouse </Label>
                <Select
                    style={{ width: '150px' }}
                    value={filterWarehouseId}
                    onChange={(value: WarehouseId) => dispatch(setFilterWarehouseId(value))}
                    options={[
                        { value: WarehouseId.Monument, label: 'Monument' },
                        { value: WarehouseId.Scandia, label: 'Scandia' },
                    ]}
                />
            </div>
            {(activeView === 'Dispatch' || activeView === 'Transfer') && (
                <>
                    <div style={{ width: '150px' }}>
                        <Label>Call off </Label>
                        <Input
                            placeholder="Call Off Id"
                            autoComplete="off"
                            type="number"
                            value={filterCallOffId}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                dispatch(setFilterCallOffId(e.target.value))
                            }}
                        />
                    </div>
                    <div style={{ width: '150px' }}>
                        <Label>Project </Label>
                        <Input
                            placeholder="Project Name"
                            autoComplete="off"
                            value={filterProjectName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                dispatch(setFilterProjectName(e.target.value))
                            }}
                        />
                    </div>
                    <div style={{ width: '150px' }}>
                        <Label>Sav number </Label>
                        <Input
                            id="outlined-basic"
                            placeholder="Project Sav Number"
                            autoComplete="off"
                            value={filterSavNumber}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                dispatch(setFilterSavNumber(e.target.value))
                            }}
                        />
                    </div>
                </>
            )}
            {activeView === 'Dispatch' && (
                <div style={{ width: '180px' }}>
                    <Label>Dispatch date </Label>
                    <Radio.Group
                        optionType="button"
                        options={dispatchDateFilterOptions}
                        onChange={(event) => dispatch(setFilterDispatchDate(event.target.value))}
                        value={filterDispatchDate}
                    />
                </div>
            )}
            {activeView === 'Transfer' && (
                <>
                    <div style={{ width: '150px' }}>
                        <Label>Assembly Id</Label>
                        <Input
                            placeholder="Assembly Id"
                            autoComplete="off"
                            type="number"
                            value={filterAssemblyId}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                dispatch(setFilterAssemblyId(e.target.value))
                            }}
                        />
                    </div>
                    <div style={{ width: '150px' }}>
                        <Label>Transfer Type</Label>
                        <Select
                            style={{ width: '150px' }}
                            value={filterTransfer}
                            onChange={(value: TransferFilter) => dispatch(setFilterTransfer(value))}
                            options={[
                                { value: TransferFilter.None, label: 'All' },
                                { value: TransferFilter.Dispatch, label: 'Dispatch' },
                                { value: TransferFilter.Assembly, label: 'Assembly' },
                            ]}
                        />
                    </div>
                </>
            )}
            {activeView === 'Booking' && (
                <>
                    <div style={{ width: '150px' }}>
                        <Label>Document number</Label>
                        <Input
                            placeholder="Document number"
                            value={filterDocumentNo}
                            onChange={(e) => {
                                dispatch(setFilterDocumentNo(e.target.value))
                            }}
                        />
                    </div>

                    <div style={{ width: '150px' }}>
                        <Label>PO Creator</Label>
                        <Select
                            style={{ width: '150px' }}
                            placeholder="Creator"
                            showSearch
                            value={filterPurchaseOrderCreator}
                            onChange={(purchaseOrderCreator: string) =>
                                dispatch(setFilterPurchaseOrderCreator(purchaseOrderCreator || ''))
                            }
                        >
                            <Select.Option key={undefined} value={undefined}>
                                None
                            </Select.Option>
                            {purchaseOrderCreators.map((purchaseOrderCreator) => (
                                <Select.Option
                                    key={purchaseOrderCreator}
                                    value={purchaseOrderCreator}
                                >
                                    {purchaseOrderCreator}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>

                    <div style={{ width: '150px' }}>
                        <Label>PO Supplier</Label>
                        <Select
                            style={{ width: '150px' }}
                            placeholder="Supplier"
                            showSearch
                            value={filterPurchaseOrderSupplier}
                            onChange={(purchaseOrderSupplier: string | undefined) =>
                                dispatch(
                                    setFilterPurchaseOrderSupplier(purchaseOrderSupplier || '')
                                )
                            }
                        >
                            <Select.Option key={undefined} value={undefined}>
                                None
                            </Select.Option>
                            {purchaseOrderSuppliers.map((purchaseOrderSupplier) => (
                                <Select.Option
                                    key={purchaseOrderSupplier}
                                    value={purchaseOrderSupplier}
                                >
                                    {purchaseOrderSupplier}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>

                    <div style={{ width: '150px' }}>
                        <Label>PO activated</Label>
                        <Select
                            style={{ width: '150px' }}
                            placeholder="Activated"
                            showSearch
                            value={filterIsPurchaseOrderActivated}
                            onChange={(isActivated: boolean | undefined) =>
                                dispatch(setFilterIsPurchaseOrderActivated(isActivated))
                            }
                        >
                            <Select.Option key={undefined} value={undefined}>
                                None
                            </Select.Option>
                            <Select.Option key={'true'} value={true}>
                                Activated
                            </Select.Option>
                            <Select.Option key={'false'} value={false}>
                                Not Activated
                            </Select.Option>
                        </Select>
                    </div>

                    <div style={{ width: '150px' }}>
                        <Label>PO Status</Label>
                        <Select
                            style={{ width: '150px' }}
                            placeholder="Document status"
                            showSearch
                            value={filterPurchaseOrderStatus}
                            onChange={(documentStatus: PurchaseOrderDocumentStatus | undefined) =>
                                dispatch(setFilterPurchaseOrderStatus(documentStatus))
                            }
                        >
                            <Select.Option key={undefined} value={undefined}>
                                None
                            </Select.Option>
                            <Select.Option
                                key={PurchaseOrderDocumentStatus.EnumDocumentStatusOnHold}
                                value={PurchaseOrderDocumentStatus.EnumDocumentStatusOnHold}
                            >
                                On hold
                            </Select.Option>
                            <Select.Option
                                key={PurchaseOrderDocumentStatus.EnumDocumentStatusLive}
                                value={PurchaseOrderDocumentStatus.EnumDocumentStatusLive}
                            >
                                Live
                            </Select.Option>
                            <Select.Option
                                key={PurchaseOrderDocumentStatus.EnumDocumentStatusComplete}
                                value={PurchaseOrderDocumentStatus.EnumDocumentStatusComplete}
                            >
                                Complete
                            </Select.Option>
                        </Select>
                    </div>
                </>
            )}
        </Container>
    )
}
