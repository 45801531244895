import * as Sentry from '@sentry/react'
import { createAsyncThunk, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { loginRequest } from '../api/auth'
import { getCurrentUser } from '../api/users'
import { User } from '../models/models'
import { UserRole } from '../models/models'
import { AUTH_REDIRECT_URI, CLIENT_ID } from '../configurations/sage-configuration'
import { STORAGE_TOKEN_KEY } from '../api/util/backend-connection'

export const STORAGE_TOKEN_EXPIRY_KEY = 'authentication_token_expiry'

export enum AuthenticationStage {
    Initial,
    Loading,
    IncorrectCredentials,
    ServerError,
    AwaitingSage,
}

export interface AuthenticationStoreState {
    stage: AuthenticationStage
    token: string | null
    tokenExpiry: number | null
    user: User | null
}

export interface AuthenticationOptions {
    email: string
    password: string
    remember: boolean
}

const initialToken =
    localStorage.getItem(STORAGE_TOKEN_KEY) || sessionStorage.getItem(STORAGE_TOKEN_KEY)
const initialTokenExpiry =
    localStorage.getItem(STORAGE_TOKEN_EXPIRY_KEY) ||
    sessionStorage.getItem(STORAGE_TOKEN_EXPIRY_KEY)

const initialState: AuthenticationStoreState = {
    stage: AuthenticationStage.Initial,
    token: initialToken,
    tokenExpiry: initialTokenExpiry ? parseInt(initialTokenExpiry) : null,
    user: null,
}

export const skipSageAuthenticationTimer = createAsyncThunk(
    'skip-sage',
    async (timeout: number) => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve()
            }, timeout)
        })
    }
)

export const authenticate = createAsyncThunk(
    'authenticate',
    async (authenticationOptions: AuthenticationOptions, { dispatch }) => {
        const response = await loginRequest(
            authenticationOptions.email,
            authenticationOptions.password
        )

        if (
            response.successful &&
            (response.data.user.role === UserRole.SystemAdmin ||
                response.data.user.role === UserRole.SavAdmin ||
                response.data.user.role === UserRole.ProjectManager)
        ) {
            dispatch(skipSageAuthenticationTimer(500))
        }

        return { ...response, remember: authenticationOptions.remember }
    }
)

export const getUser = () => async (dispatch: Dispatch) => {
    const response = await getCurrentUser()
    if (response.successful) {
        Sentry.configureScope((scope) => scope.setUser({ id: String(response.data.id) }))
        dispatch(setUser(response.data))
    }
}

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setToken(state, action: PayloadAction<string>) {
            state.token = action.payload
        },

        setTokenExpiration(state, action: PayloadAction<number>) {
            state.tokenExpiry = action.payload
        },

        setUser(state, action: PayloadAction<User>) {
            state.user = action.payload
        },

        setStage(state, action: PayloadAction<AuthenticationStage>) {
            state.stage = action.payload
        },

        logout(state) {
            state.token = null
            state.tokenExpiry = null
            localStorage.removeItem(STORAGE_TOKEN_KEY)
            localStorage.removeItem(STORAGE_TOKEN_EXPIRY_KEY)
            sessionStorage.removeItem(STORAGE_TOKEN_KEY)
            sessionStorage.removeItem(STORAGE_TOKEN_EXPIRY_KEY)
            state.user = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(skipSageAuthenticationTimer.fulfilled, (state) => {
            if (state.stage === AuthenticationStage.AwaitingSage) {
                state.stage = AuthenticationStage.Initial
            }
        })
        builder.addCase(authenticate.fulfilled, (state, action) => {
            const { remember, is4xx, successful } = action.payload

            if (successful) {
                const { data } = action.payload
                if (remember) {
                    localStorage.setItem(STORAGE_TOKEN_KEY, data.accessToken)
                    localStorage.setItem(
                        STORAGE_TOKEN_EXPIRY_KEY,
                        String(data.accessTokenExpiresAt)
                    )
                } else {
                    sessionStorage.setItem(STORAGE_TOKEN_KEY, data.accessToken)
                    sessionStorage.setItem(
                        STORAGE_TOKEN_EXPIRY_KEY,
                        String(data.accessTokenExpiresAt)
                    )
                }

                state.token = data.accessToken
                state.tokenExpiry = data.accessTokenExpiresAt
                state.user = data.user

                if (
                    data.user.role === UserRole.ProjectManager ||
                    data.user.role === UserRole.SystemAdmin ||
                    data.user.role === UserRole.SavAdmin
                ) {
                    state.stage = AuthenticationStage.AwaitingSage
                    window.open(
                        `https://id.sage.com/authorize?audience=s200ukipd/sage200&client_id=${CLIENT_ID}&response_type=code&redirect_uri=${AUTH_REDIRECT_URI}&scope=openid profile email offline_access`,
                        'popUpWindow',
                        'height=800,width=700,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                    )
                } else {
                    state.stage = AuthenticationStage.Initial
                }
            } else if (is4xx) {
                state.stage = AuthenticationStage.IncorrectCredentials
            } else {
                state.stage = AuthenticationStage.ServerError
            }
        })

        builder.addCase(authenticate.rejected, () => {
            setStage(AuthenticationStage.ServerError)
        })
    },
})

export const { logout, setStage, setToken, setTokenExpiration, setUser } =
    authenticationSlice.actions

export const selectAuthenticationStage = (state: RootState) => state.authentication.stage

export const isAuthenticatedView = (state: RootState) => {
    if (
        !state.authentication.token ||
        !state.authentication.tokenExpiry ||
        state.authentication.stage === AuthenticationStage.AwaitingSage
    ) {
        return false
    }
    return state.authentication.tokenExpiry > Date.now()
}

export const authenticatedUserView = (state: RootState) => state.authentication.user

export default authenticationSlice.reducer
