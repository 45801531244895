import { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Input, Modal, Select, Button } from 'antd'
import moment, { Moment } from 'moment'
import {
    Project,
    Task,
    TaskDepartment,
    TaskType,
    User,
    UserRole,
    WarehouseId,
} from '../../../models/models'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { CircularProgress, styled } from '@mui/material'
import '../../tables/table-styles.css'
import { CreateTaskDto, createTaskRequest } from '../../../api/tasks'
import { OrderedListOutlined } from '@ant-design/icons'
import { getUsersRequest } from '../../../api/users'
import DatePicker from '../../ui/calendar/DatePicker'
import { getTaskTypesRequest } from '../../../api/task-types'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import { getProjectsRequest } from '../../../api/projects/projects'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 47%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

interface CreateTaskModalProps {
    warehouseId: number
    department?: TaskDepartment
    closeModal: (task?: Task) => void
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

const roles = {
    [TaskDepartment.ASSEMBLY]: [UserRole.Assembler],
    [TaskDepartment.LOGISTICS]: [UserRole.LogisticsEmployee],
    [TaskDepartment.ANY]: [UserRole.Assembler, UserRole.LogisticsEmployee],
}

const isSelfAssigning = (user: User | null) => {
    if (!user) {
        return false
    }
    const selfAssigningRoles = [UserRole.Assembler, UserRole.LogisticsEmployee]

    return selfAssigningRoles.includes(user?.role)
}

export default function CreateTaskModal({
    warehouseId,
    department,
    closeModal,
}: CreateTaskModalProps) {
    const { user } = useSelector((state: RootState) => state.authentication)
    const [types, setTypes] = useState<TaskType[]>([])
    const [selectedTypeId, setSelectedTypeId] = useState<number>()
    const [description, setDescription] = useState<string>('')
    const [link, setLink] = useState<string>('')
    const [dateToBeCompleted, setDateToBeCompleted] = useState<Moment | null>(null)
    const [completedAt, setCompletedAt] = useState<Moment | null>(null)
    const [userOptions, setUserOptions] = useState<{ value: number; label: string }[]>([])
    const [selectedUserId, setSelectedUserId] = useState<number>()
    const [minutes, setMinutes] = useState<number>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const [taskDepartment, setTaskDepartment] = useState<TaskDepartment>(TaskDepartment.ANY)
    const [projects, setProjects] = useState<Project[]>()
    const [selectedProjectId, setSelectedProjectId] = useState<number>()

    useEffect(() => {
        getTypes()
        getProjectsRequest().then((response) => {
            if (response.successful) {
                setProjects(response.data?.projects || [])
            } else {
                setProjects([])
                toastFailure(response.message)
            }
        })
    }, [])

    useEffect(() => {
        if (!user) {
            return
        }
        if (isSelfAssigning(user)) {
            setDateToBeCompleted(moment())
            setCompletedAt(moment())
        }
    }, [user])

    const typeOptions = useMemo(() => {
        return types.map((t) => ({
            value: t.id,
            label: t.title,
        }))
    }, [types])

    const selectedType = useMemo(() => {
        return types.find((t) => t.id === selectedTypeId)
    }, [types, selectedTypeId])

    const selectTypeOption = (typeId: number) => {
        const type = types.find((t) => t.id === typeId)
        if (!type) {
            toastFailure('Could not select type')
            return
        }
        setMinutes(type.minutes)
        setLink(type.link || '')
        setTaskDepartment(type.department)
        setDescription(type.description)
        setSelectedTypeId(typeId)
    }

    const getTypes = async () => {
        const response = await getTaskTypesRequest({ warehouseId, department })

        if (response.successful) {
            setTypes(response.data.entities)
            setModalState(ModalState.Success)
        } else {
            if (response.status === 404) {
                setModalState(ModalState.NotFound)
            } else {
                setModalState(ModalState.Error)
            }
            toastFailure(response.message)
        }
    }

    useEffect(() => {
        if (modalState === ModalState.Success) {
            getUsersOptions()
        }
    }, [taskDepartment, warehouseId, modalState])

    const getUsersOptions = async () => {
        if (!user) {
            toastFailure('Could not find current authenticated user')
            return
        }
        const selfAssigningRoles = [UserRole.LogisticsEmployee, UserRole.Assembler]
        if (selfAssigningRoles.includes(user.role)) {
            setUserOptions([
                {
                    value: user.id,
                    label: `${user.firstName} ${user.lastName}`,
                },
            ])
            setSelectedUserId(user.id)
            return
        }

        const response = await getUsersRequest({ warehouseId, roles: roles[taskDepartment] })

        if (!response.successful) {
            toastFailure('Could not retrieve available users')
            closeModal()
            return
        }

        const userOptions = response.data.entities.map((u) => ({
            value: u.id,
            label: `${u.firstName} ${u.lastName}`,
        }))

        setUserOptions(userOptions)

        if (!userOptions.find((option) => option.value === selectedUserId)) {
            setSelectedUserId(undefined)
        }
    }

    const disableSubmit =
        !taskDepartment ||
        typeof minutes !== 'number' ||
        typeof description !== 'string' ||
        minutes < 0 ||
        !warehouseId ||
        !selectedTypeId ||
        !dateToBeCompleted

    const onOk = async () => {
        if (typeof minutes !== 'number' || minutes < 0 || !selectedTypeId || !dateToBeCompleted) {
            return
        }

        if (isSelfAssigning(user) && !completedAt) {
            return
        }

        const body: CreateTaskDto = {
            description,
            department: taskDepartment,
            minutes: minutes,
            warehouseId: warehouseId,
            link,
            typeId: selectedTypeId,
            dateToBeCompleted: dateToBeCompleted.toDate(),
            projectId: selectedProjectId,
        }
        if (selectedUserId) {
            body.assignedUserId = selectedUserId
        }

        if (completedAt) {
            body.completedAt = completedAt.toDate()
        }

        const response = await createTaskRequest(body)
        if (!response.successful) {
            toastFailure(response.message)
            return
        }
        toastSuccess(`Created task ${response.data.id}`)
        closeModal(response.data)
    }

    return (
        <Modal open={true} footer={null} onCancel={() => closeModal()}>
            {modalState === ModalState.Loading && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        margin: '20px',
                        width: '100%',
                        height: '500px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                </Box>
            )}
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.NotFound && <Box>Could not find the task types</Box>}
            {modalState === ModalState.Success && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Create task</Title>
                    </Header>
                    <Divider />
                    <div>
                        <RowElement>
                            <Label>Type</Label>
                            <Select
                                style={{ width: '100%' }}
                                value={selectedTypeId}
                                onChange={selectTypeOption}
                                options={typeOptions}
                            />
                        </RowElement>
                    </div>

                    {selectedType && (
                        <>
                            <div>
                                <Label>Description</Label>
                                <Input.TextArea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    style={{ minHeight: 200 }}
                                />
                            </div>
                            <InputRow>
                                <RowElement>
                                    <Label>Date to be completed</Label>
                                    {isSelfAssigning(user) && (
                                        <Input
                                            style={{ width: '100%' }}
                                            readOnly={true}
                                            value={moment(dateToBeCompleted).format('DD/MM/YYYY')}
                                        />
                                    )}
                                    {!isSelfAssigning(user) && (
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            value={dateToBeCompleted}
                                            onChange={setDateToBeCompleted}
                                        />
                                    )}
                                </RowElement>
                                <RowElement>
                                    <Label>Completed Date</Label>
                                    {isSelfAssigning(user) && (
                                        <Input
                                            style={{ width: '100%' }}
                                            readOnly={true}
                                            value={moment(completedAt).format('DD/MM/YYYY')}
                                        />
                                    )}
                                    {!isSelfAssigning(user) && (
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            value={completedAt}
                                            onChange={setCompletedAt}
                                        />
                                    )}
                                </RowElement>
                            </InputRow>
                            <InputRow>
                                <RowElement>
                                    <Label>Warehouse</Label>
                                    <Input
                                        style={{ width: '100%' }}
                                        readOnly={true}
                                        value={
                                            warehouseId
                                                ? {
                                                      [WarehouseId.Monument]: 'Monument',
                                                      [WarehouseId.Scandia]: 'Scandia',
                                                  }[warehouseId]
                                                : 'None'
                                        }
                                    />
                                </RowElement>
                                <RowElement>
                                    <Label>Department</Label>
                                    {isSelfAssigning(user) && (
                                        <Input
                                            style={{ width: '100%' }}
                                            readOnly={true}
                                            value={
                                                department
                                                    ? {
                                                          [TaskDepartment.ASSEMBLY]: 'Assembly',
                                                          [TaskDepartment.LOGISTICS]: 'Logistics',
                                                          [TaskDepartment.ANY]: 'Any',
                                                      }[department]
                                                    : 'Error'
                                            }
                                        />
                                    )}
                                    {!isSelfAssigning(user) && (
                                        <Select
                                            style={{ width: '100%' }}
                                            value={taskDepartment}
                                            onChange={(value: TaskDepartment) =>
                                                setTaskDepartment(value)
                                            }
                                            options={[
                                                {
                                                    value: TaskDepartment.ASSEMBLY,
                                                    label: 'Assembly',
                                                },
                                                {
                                                    value: TaskDepartment.LOGISTICS,
                                                    label: 'Logistics',
                                                },
                                                { value: TaskDepartment.ANY, label: 'Any' },
                                            ]}
                                        />
                                    )}
                                </RowElement>
                            </InputRow>
                            <InputRow>
                                <RowElement>
                                    <Label>Assigned User</Label>
                                    <Select
                                        style={{ width: '100%' }}
                                        value={selectedUserId}
                                        onChange={(value: number) => setSelectedUserId(value)}
                                        options={userOptions}
                                    />
                                </RowElement>
                                <RowElement>
                                    <Label>Minutes</Label>
                                    <Input
                                        style={{ width: '100%' }}
                                        type="number"
                                        value={minutes}
                                        onWheel={(e) => e.preventDefault()}
                                        onChange={(e) => setMinutes(Number(e.target.value))}
                                    />
                                </RowElement>
                            </InputRow>
                            <InputRow>
                                <RowElement>
                                    <Label>Link</Label>
                                    <Input
                                        style={{ width: '100%' }}
                                        value={link}
                                        onChange={(e) => setLink(e.target.value)}
                                    />
                                </RowElement>
                                <RowElement>
                                    <Label>Project</Label>
                                    <Select
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        optionFilterProp={'children'}
                                        showSearch
                                        onChange={(value: number) => setSelectedProjectId(value)}
                                    >
                                        {projects?.map((project: Project) => (
                                            <Select.Option key={project.id} value={project.id}>
                                                {project.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </RowElement>
                            </InputRow>
                        </>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                        <Button type={'primary'} disabled={disableSubmit} onClick={onOk}>
                            Create
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    )
}
