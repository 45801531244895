import { getDeliveriesRequest, GetDeliveryRelations } from '../../../api/deliveries'
import { OrderConfirmation } from '../../../models/models'
import DeliveriesTable, { DeliveriesTableColumns } from '../../tables/DeliveriesTable'
interface Props {
    orderConfirmation: OrderConfirmation
}

export default function DeliveriesTab({ orderConfirmation }: Props) {
    return (
        <DeliveriesTable
            columns={[DeliveriesTableColumns.ReceivedDate, DeliveriesTableColumns.AmountDelivered]}
            pagination={true}
            expandable={true}
            dependencies={{}}
            request={(pagination) =>
                getDeliveriesRequest({
                    relations: [
                        GetDeliveryRelations.GoodsDelivered,
                        GetDeliveryRelations.GoodsDeliveredOrderlines,
                        GetDeliveryRelations.GoodsDeliveredOrderlinesProduct,
                        GetDeliveryRelations.OrderConfirmation,
                    ],
                    orderConfirmationId: orderConfirmation.id,
                    skip: pagination?.skip,
                    limit: pagination?.limit,
                })
            }
        />
    )
}
