import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from '../../../store/hooks'
import {
    ActiveModal,
    openModal,
    selectRemandingAmountOfProductsToTransfer,
} from '../../../store/warehouse-map-slice'
import { ActionBarContainer } from './ActionBarContainer'
import { InfoColumn } from './InfoColumn'
import { findHiu } from '../../../util/util'

export const TransferActionBar = () => {
    const remaindingAmountProducts = useSelector(selectRemandingAmountOfProductsToTransfer)
    const { assemblyToTransfer } = useAppSelector((state) => state.warehouseMap)
    const dispatch = useDispatch()

    if (!assemblyToTransfer) {
        return <></>
    }

    return (
        <ActionBarContainer>
            <InfoColumn
                description="Product to transfer"
                content={`${
                    findHiu(assemblyToTransfer.builtItemCallOff.sopBuiltItemOrderline.builtItem)
                        ?.code
                }`}
            />
            <InfoColumn
                description="Total amount needed"
                content={`${(
                    assemblyToTransfer.amount - remaindingAmountProducts
                ).toString()}/${assemblyToTransfer.amount.toString()}`}
            />
            <Button
                onClick={() =>
                    dispatch(
                        openModal({
                            modal: ActiveModal.InspectBinHoldings,
                            productId: findHiu(
                                assemblyToTransfer.builtItemCallOff.sopBuiltItemOrderline.builtItem
                            )?.id,
                        })
                    )
                }
            >
                Inspect Bin holdings
            </Button>
            <Button
                onClick={() => dispatch(openModal({ modal: ActiveModal.ConfirmCancelTransfer }))}
            >
                Cancel transfer
            </Button>
            <Button
                onClick={() => dispatch(openModal({ modal: ActiveModal.ConfirmTransfer }))}
                disabled={remaindingAmountProducts !== 0}
            >
                Confirm transfer
            </Button>
        </ActionBarContainer>
    )
}
