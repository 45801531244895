import { Assembly, User } from '../../../../models/models'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextareaAutosize,
    Typography,
} from '@mui/material'
import { useAppDispatch } from '../../../../store/hooks'
import { useState } from 'react'
import { styled } from '@mui/system'
import { ActiveAssemblyContentFooter } from './ActiveAssemblyContentFooter'
import { saveWorksheetNote, startAssembly } from '../../../../store/assembly-worksheets-slice'

interface Props {
    user: User
    assembly: Assembly
}

const Container = styled('div')`
    display: flex;
    gap: 30px;

    tbody tr:last-of-type td {
        border-bottom: none;
    }
`

const Aside = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 15px;
`

export function ActiveAssemblyCollectiveComplete(props: Props) {
    const assembly = props.assembly
    const builtItem = assembly.builtItemCallOff.sopBuiltItemOrderline.builtItem
    const dispatch = useAppDispatch()
    const [note, setNote] = useState<string | null>(assembly?.note)

    const fields = builtItem.group?.bomGroupAssemblyFields

    const componentSpecs = builtItem.bomComponents.map((bomComponent) => ({
        name: bomComponent.product.name,
        code: bomComponent.product.code,
        quantity: bomComponent.amount,
    }))

    return (
        <Container>
            <Aside style={{ width: '40%' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '20px',
                        borderRadius: '15px',
                    }}
                >
                    <Typography
                        component={'h2'}
                        sx={{
                            padding: '5px 10px',
                            fontSize: '18px',
                            background: '#3500A0',
                            borderRadius: '10px',
                            color: '#fff',
                        }}
                    >
                        {assembly.amount}x
                    </Typography>
                    <Typography component={'h2'} sx={{ fontSize: '18px', fontWeight: 500 }}>
                        {builtItem.bomCode}
                    </Typography>
                </div>
                <TextareaAutosize
                    placeholder={'Note'}
                    value={note || ''}
                    onChange={(e) => setNote(e.target.value)}
                    disabled={!assembly.startedAt}
                    onBlur={async () => {
                        const newValue = note || ''
                        if ((assembly?.note || '') !== newValue) {
                            await dispatch(saveWorksheetNote(assembly, newValue))
                        }
                    }}
                    style={{
                        border: '1px solid #ddd',
                        borderRadius: '15px',
                        padding: '10px',
                        minHeight: '200px',
                        outline: 'none',
                    }}
                />
            </Aside>

            <Aside style={{ width: '60%' }}>
                <div style={{ background: '#eee', padding: '15px', borderRadius: '15px' }}>
                    <Typography component={'h2'} sx={{ fontSize: 18 }}>
                        Components
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell>Quantity</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {componentSpecs.map((spec, i) => (
                                <TableRow key={i}>
                                    <TableCell>{spec.name}</TableCell>
                                    <TableCell>{spec.code}</TableCell>
                                    <TableCell>{spec.quantity}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </Aside>
        </Container>
    )
}
