import {
    AssemblyStatusBackgrounds,
    getGeneralAssemblyStatus,
    setActiveGeneralAssemblyView,
} from '../../../store/assembly-worksheets-slice'
import { Box, Typography } from '@mui/material'
import moment, { Moment } from 'moment'
import { ReactNode } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
    GeneralAssembly,
    Schedule,
    UserRole,
    AssemblyActivity,
    GeneralAssemblyLine,
} from '../../../models/models'
import { camelCaseToWords, uniqueBy } from '../../../util/util'
import { useHistory } from 'react-router-dom'
import {
    getGeneralAssembliesCompletedMinutes,
    getGeneralAssembliesMinutes,
} from '../../../util/production-schedule'
import { Button } from 'antd'
import { ActiveModal, openModal } from '../../../store/products-slice'

interface Props {
    schedule?: Schedule
    generalAssembly: GeneralAssembly
    onClick: null | (() => void)
}

function AttributeName({ text }: { text: string }) {
    return (
        <Typography
            sx={{
                fontSize: 14,
                color: '#26262690',
            }}
        >
            {text}
        </Typography>
    )
}

function AttributeValue({ text }: { text: ReactNode }) {
    return (
        <Typography
            sx={{
                fontSize: 14,
            }}
        >
            {text}
        </Typography>
    )
}

function SplitRow({ left, right }: { left: ReactNode; right: ReactNode }) {
    return (
        <Box
            sx={{
                borderTop: '1px solid #ddd',
                display: 'flex',
            }}
        >
            <Box
                sx={{
                    width: '130px',
                    padding: '20px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {left}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {right}
            </Box>
        </Box>
    )
}

function Assemblers({ activities }: { activities: AssemblyActivity[] }) {
    return (
        <>
            {uniqueBy(activities, (a1, a2) => a1.userId === a2.userId).map((activity) => (
                <Box
                    key={activity.id}
                    sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                        }}
                    >
                        {activity?.user
                            ? `${activity?.user.firstName} ${activity?.user.lastName}`
                            : 'No assembler'}{' '}
                    </Typography>
                </Box>
            ))}
        </>
    )
}

function Progress({ generalAssembly }: { generalAssembly: GeneralAssembly }) {
    const generalAssemblyStatus = getGeneralAssemblyStatus(generalAssembly)
    const isBehindSchedule =
        moment(generalAssembly.dateToBeCompletedBy) < moment().startOf('day') &&
        generalAssemblyStatus !== 'Completed'
    const activities = generalAssembly.activities

    const linesWithAssemblers = generalAssembly.lines.filter((l) => l.assembledBy)

    return (
        <Box
            sx={{
                borderTop: '1px solid #ddd',
                background: isBehindSchedule
                    ? 'red'
                    : AssemblyStatusBackgrounds[generalAssemblyStatus],
                color: '#fff',
                padding: '15px 20px',
            }}
        >
            <Typography sx={{ fontWeight: 'bold' }}>{`${camelCaseToWords(generalAssemblyStatus)} ${
                isBehindSchedule ? '- Delayed!' : ''
            }`}</Typography>
            {activities.length > 0 && <Assemblers activities={activities} />}
        </Box>
    )
}

function GeneralAssemblyWorksheetCard(props: Props) {
    const dispatch = useAppDispatch()
    const authenticatedUser = useAppSelector((state) => state.authentication.user)
    const { generalAssembly } = props
    const builtItem = generalAssembly.builtItem
    const history = useHistory()

    const minutes = getGeneralAssembliesMinutes([generalAssembly])
    const completedMinutes = getGeneralAssembliesCompletedMinutes([generalAssembly])

    if (!authenticatedUser) {
        return null
    }

    //find the startedAt date based on the first completed lines completedAt date, return Moment or null
    const startedAt = generalAssembly.lines.reduce<Moment | null>(
        (acc: Moment | null, line: GeneralAssemblyLine) => {
            if (line.completedAt) {
                const completedAt = moment(line.completedAt)
                if (!acc || completedAt.isBefore(acc)) {
                    return completedAt
                }
            }
            return acc
        },
        null
    )

    const authenticatedUserRole = authenticatedUser.role
    const canOpen = [
        UserRole.Assembler,
        UserRole.SystemAdmin,
        UserRole.Scheduler,
        UserRole.SavAdmin,
        UserRole.ProjectManager,
    ].includes(authenticatedUserRole)

    const onClick =
        props.onClick ||
        (canOpen
            ? () => {
                  dispatch(setActiveGeneralAssemblyView(generalAssembly))
              }
            : null)

    return (
        <Box
            onClick={() => canOpen && onClick && onClick()}
            sx={{
                width: '370px',
                background: '#fff',
                cursor: onClick ? 'pointer' : 'initial',
            }}
        >
            <Box>
                <Typography
                    sx={{
                        fontSize: 16,
                        padding: '20px',
                        fontWeight: 500,
                    }}
                >
                    General Assembly
                </Typography>
            </Box>
            <Progress generalAssembly={generalAssembly} />
            <SplitRow
                left={<AttributeName text={'Product'} />}
                right={
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            dispatch(
                                openModal({
                                    activeModal: ActiveModal.BomInspect,
                                    modalEntityId: builtItem.id,
                                })
                            )
                        }}
                    >
                        {builtItem.bomCode}
                    </Button>
                }
            />
            <SplitRow
                left={<AttributeName text={'Quantity'} />}
                right={<AttributeValue text={generalAssembly.lines.length} />}
            />
            <SplitRow
                left={<AttributeName text={'Description'} />}
                right={<AttributeValue text={generalAssembly?.description} />}
            />
            <SplitRow
                left={<AttributeName text={'Minutes completed'} />}
                right={<AttributeValue text={`${completedMinutes}/${minutes}`} />}
            />
            <SplitRow
                left={<AttributeName text={'General Assembly Id'} />}
                right={<AttributeValue text={generalAssembly.id} />}
            />
            <SplitRow
                left={<AttributeName text={'Date to be completed'} />}
                right={
                    <AttributeValue
                        text={moment(generalAssembly.dateToBeCompletedBy).format('DD-MM-YYYY')}
                    />
                }
            />
            <SplitRow
                left={<AttributeName text={'Started at'} />}
                right={
                    <AttributeValue
                        text={startedAt ? startedAt.format('DD-MM-YYYY HH:mm') : 'Not started'}
                    />
                }
            />
            <SplitRow
                left={<AttributeName text={'Completed at'} />}
                right={
                    <AttributeValue
                        text={
                            generalAssembly.completedAt
                                ? moment(generalAssembly.completedAt).format('DD-MM-YYYY HH:mm')
                                : 'Not completed'
                        }
                    />
                }
            />
        </Box>
    )
}

export default GeneralAssemblyWorksheetCard
