import {
    CallOff,
    Delivery,
    PurchaseOrder,
    OrderType,
    Assembly,
    WarehouseAreaSpot,
} from '../models/models'
import backendConnection from './util/backend-connection'
import { Response } from './util/with-response-formatter-interceptor'

export enum TransferType {
    DispatchTransfer = 'DispatchTransfer',
    AssemblyTransfer = 'AssemblyTransfer',
}

export interface TransferWorksheet {
    dueDate: Date
    type: TransferType
    item: Assembly
}

export interface TransferWorksheetsResponseDTO {
    transfers: TransferWorksheet[]
}

export const getTransferWorksheetsRequest = async (
    warehouseId: number
): Promise<Response<TransferWorksheetsResponseDTO>> => {
    return backendConnection.request({
        url: `logistics-worksheets/transfer/worksheets/${warehouseId}`,
        method: 'GET',
    })
}

export interface AssemblyTransferMonumentHiuDTO {
    amountToTransfer: number
    palletProductId: number
    palletId: number
}

export const assemblyTransferMonumentHiuRequest = async (
    productionId: number,
    transfers: AssemblyTransferMonumentHiuDTO[]
): Promise<Response<WarehouseAreaSpot[]>> =>
    backendConnection.request({
        url: `logistics-worksheets/transfer/assembly/monument/hiu/${productionId}`,
        method: 'PUT',
        data: { transfers },
    })

export interface DispatchTransferMonumentHIUDTO {
    assemblyId: number
}

export const dispatchTransferMonumentHiuRequest = (
    dto: DispatchTransferMonumentHIUDTO
): Promise<Response<void>> =>
    backendConnection.request({
        url: `logistics-worksheets/transfer/dispatch/monument/hiu/${dto.assemblyId}`,
        method: 'PUT',
    })

export const assemblyTransferRequest = (assemblyId: number) =>
    backendConnection.request({
        url: `logistics-worksheets/transfer/assembly/${assemblyId}`,
        method: 'PUT',
    })

export const dispatchTransferStockItemRequest = (projectStockItemId: number) =>
    backendConnection.request({
        url: `logistics-worksheets/transfer/dispatch/stock/${projectStockItemId}`,
        method: 'PUT',
    })

export const dispatchTransferAssemblyRequest = (assemblyId: number) =>
    backendConnection.request({
        url: `logistics-worksheets/transfer/dispatch/assembly/${assemblyId}`,
        method: 'PUT',
    })

export const callOffDispatchRequest = (callOffId: number, dto: { toSpotId: number }) =>
    backendConnection.request({
        url: `logistics-worksheets/dispatch/${callOffId}`,
        method: 'PUT',
        data: { toSpotId: dto.toSpotId },
    })

export interface OrderhasArrivedDto {
    receivedDate?: Date
}

export const setOrderHasArrivedRequest = (
    orderId: number,
    body: OrderhasArrivedDto
): Promise<Response<PurchaseOrder[]>> =>
    backendConnection.request({
        url: `logistics-worksheets/booking/${orderId}`,
        method: 'PUT',
        data: body,
    })

export interface CreateBulkOrderBody {
    orderConfirmationNumber: string
    expectedDeliveryDate: Date
    supplierName: string
    orderedStockItemId: number
    amountOrdered: number
    warehouseId: number
    type: OrderType
}

export interface UpdateOrderBody {
    receivedDate?: Date
    returnedDate?: Date
    supplierDispatchDate?: Date
    expectedDeliveryDate?: Date
}

export const updateOrderRequest = (
    orderId: number,
    body: UpdateOrderBody
): Promise<Response<PurchaseOrder>> =>
    backendConnection.request({
        url: `logistics-worksheets/booking/${orderId}`,
        method: 'PUT',
        data: body,
    })

export const deleteOrderRequest = (orderId: number): Promise<Response<void>> =>
    backendConnection.request({
        url: `logistics-worksheets/booking/${orderId}`,
        method: 'DELETE',
    })
export enum StorageStrategy {
    Pallet = 'Pallet',
    Shelf = 'Shelf',
}

export interface ProductDTO {
    amount: number
    orderlineId: number
}

export interface PalletProductDTO {
    amount: number
    id: number
}

export interface DeliveryPalletDto {
    projectId?: number
    products: PalletProductDTO[]
}

export interface CreateDeliveryDTO {
    products: ProductDTO[]
    pallets: DeliveryPalletDto[]
}

export const createDeliveryRequest = (
    orderConfirmationId: number,
    body: CreateDeliveryDTO
): Promise<Response<Delivery>> =>
    backendConnection.request({
        url: `logistics-worksheets/booking/delivery/${orderConfirmationId}`,
        method: 'POST',
        data: body,
    })

export interface CreateOcAndDeliveryDTO {
    products: ProductDTO[]
    pallets: DeliveryPalletDto[]
    code: string
}

export const createOcAndDeliveryRequest = (
    orderConfirmationId: number,
    body: CreateOcAndDeliveryDTO
): Promise<Response<Delivery>> =>
    backendConnection.request({
        url: `logistics-worksheets/booking/delivery/purchaseOrder/${orderConfirmationId}`,
        method: 'POST',
        data: body,
    })

export interface GetBookingWorksheetsDTO {
    skip?: number
    limit?: number
    likeDocumentNo?: number
    warehouseId?: number
}

export const getBookingWorksheetsRequest = (
    queryParams: GetBookingWorksheetsDTO
): Promise<Response<PurchaseOrder[]>> =>
    backendConnection.request({
        url: `logistics-worksheets/booking/worksheets`,
        method: 'GET',
        params: queryParams,
    })

export interface GetDispatchWorksheetsDTO {
    warehouseId?: number
}

export const getDispatchWorksheetsRequest = (
    queryParams: GetDispatchWorksheetsDTO
): Promise<Response<CallOff[]>> =>
    backendConnection.request({
        url: `logistics-worksheets/dispatch/worksheets`,
        method: 'GET',
        params: queryParams,
    })

export interface DispatchBuiltItemCallOff {
    builtItemCallOffId: number
    amount: number
    assemblyLineIds?: number[]
}

export interface DispatchStockItemCallOff {
    stockItemCallOffId: number
    amount: number
}

export const dispatchCallOffRequest = (
    callOffId: number,
    body: {
        stockItemCallOffs: DispatchStockItemCallOff[]
        builtItemCallOffs: DispatchBuiltItemCallOff[]
        dispatchCourier: string
        consignmentNumber: string
        dispatchNote: string
    }
): Promise<Response<CallOff>> =>
    backendConnection.request({
        url: `logistics-worksheets/dispatch/${callOffId}`,
        method: 'PUT',
        data: body,
    })
