import { Accordion, AccordionDetails, AccordionSummary, TextField, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import {
    SopBuiltItemOrderline,
    CallOff,
    SopStockItemOrderline,
    SopContact,
} from '../../../models/models'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Table } from 'antd'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
})

const InformationContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

const AnalysisCodesContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    margin: '20px',
})

const SalesOrderInformationContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    margin: '20px',
})

const isBuiltItemSop = (
    itemSop: SopBuiltItemOrderline | SopStockItemOrderline
): itemSop is SopBuiltItemOrderline => {
    return (itemSop as SopBuiltItemOrderline).builtItem !== undefined
}

const isStockItemSop = (
    itemSop: SopBuiltItemOrderline | SopStockItemOrderline
): itemSop is SopStockItemOrderline => {
    return (itemSop as SopStockItemOrderline).stockItemCallOffs !== undefined
}

const productsTableColumns = [
    {
        title: 'Code',
        key: 'code',
        render: (element: SopBuiltItemOrderline | SopStockItemOrderline) => {
            if (isBuiltItemSop(element)) {
                return `${element.builtItem.bomCode}`
            } else if (isStockItemSop(element)) {
                return `${element.product.code}`
            }
        },
    },
    {
        title: 'Assigned on call offs',
        key: 'assigned',
        render: (element: SopBuiltItemOrderline | SopStockItemOrderline) => {
            if (isBuiltItemSop(element)) {
                return `${element.builtItemCallOffs.reduce((acc, cur) => acc + cur.amount, 0)}/${
                    element.amount
                }`
            } else if (isStockItemSop(element)) {
                return `${element.stockItemCallOffs.reduce((acc, cur) => acc + cur.amount, 0)}/${
                    element.amount
                }`
            }
        },
    },
    {
        title: 'Amount of call offs',
        key: 'name',
        render: (element: SopBuiltItemOrderline | SopStockItemOrderline) => {
            if (isBuiltItemSop(element)) {
                return `${element.builtItemCallOffs.length}`
            } else if (isStockItemSop(element)) {
                return `${element.stockItemCallOffs.length}`
            }
        },
    },
]

const contactTableColumns = [
    {
        title: 'Name',
        key: 'name',
        render: (element: SopContact) => {
            return element.name
        },
    },
    {
        title: 'Phone number',
        key: 'phone',
        render: (element: SopContact) => {
            return element.phoneNumber
        },
    },
    {
        title: 'Description',
        key: 'description',
        render: (element: SopContact) => {
            return element.description
        },
    },
]

interface Props {
    callOff: CallOff
}
export default function SalesOrderTab(props: Props) {
    const callOff = props.callOff
    const productsTableData: Array<SopBuiltItemOrderline | SopStockItemOrderline> = [
        ...callOff.salesOrder.builtItemOrderlines,
        ...callOff.salesOrder.stockItemOrderlines,
    ]
    const contactTableData = callOff.salesOrder.contacts
    return (
        <Container>
            <InformationContainer>
                <SalesOrderInformationContainer>
                    <TextField
                        key={callOff.salesOrder.documentNo}
                        id="outlined-read-only-input"
                        label="Document No"
                        defaultValue={callOff.salesOrder.documentNo}
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        style={{ marginTop: '10px' }}
                        key={callOff.salesOrder?.customer?.name}
                        id="outlined-read-only-input"
                        label="Name"
                        defaultValue={callOff.salesOrder?.customer?.name}
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        style={{ marginTop: '10px' }}
                        key={callOff.salesOrder?.customer?.balance}
                        id="outlined-read-only-input"
                        label="Customer Balance"
                        defaultValue={callOff.salesOrder?.customer?.balance}
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        style={{ marginTop: '10px' }}
                        key={callOff.salesOrder?.customer?.creditLimit}
                        id="outlined-read-only-input"
                        label="Customer Credit Limit"
                        defaultValue={callOff.salesOrder?.customer?.creditLimit}
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        style={{ marginTop: '10px' }}
                        key={callOff.salesOrder?.customer?.customerLocationAddressLine1}
                        id="outlined-read-only-input"
                        label="Customer Address"
                        defaultValue={callOff.salesOrder?.customer?.customerLocationAddressLine1}
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                </SalesOrderInformationContainer>
                <AnalysisCodesContainer>
                    <TextField
                        key={callOff.salesOrder.analysisCode1}
                        id="outlined-basic"
                        variant="outlined"
                        label="Project Name"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={callOff.salesOrder.analysisCode1}
                    />
                    <TextField
                        style={{ marginTop: '10px' }}
                        key={callOff.salesOrder.analysisCode2}
                        id="outlined-basic"
                        variant="outlined"
                        label="Project Number"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={callOff.salesOrder.analysisCode2}
                    />
                    <TextField
                        style={{ marginTop: '10px' }}
                        key={callOff.salesOrder.analysisCode3}
                        id="outlined-basic"
                        variant="outlined"
                        label="Project Manager"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={callOff.salesOrder.analysisCode3}
                    />
                    <TextField
                        style={{ marginTop: '10px' }}
                        key={callOff.salesOrder.analysisCode4}
                        id="outlined-basic"
                        variant="outlined"
                        label="Region"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={callOff.salesOrder.analysisCode4}
                    />
                    <TextField
                        style={{ marginTop: '10px' }}
                        key={callOff.salesOrder.analysisCode5}
                        id="outlined-basic"
                        variant="outlined"
                        label="Case Number"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={callOff.salesOrder.analysisCode5}
                    />
                    <TextField
                        style={{ marginTop: '10px' }}
                        key={callOff.salesOrder.analysisCode6}
                        id="outlined-basic"
                        variant="outlined"
                        label="Dispatch from Master Order"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={callOff.salesOrder.analysisCode6}
                    />
                </AnalysisCodesContainer>
            </InformationContainer>
            <div style={{ boxShadow: '0px 0px 30px -10px rgba(150,170,180,0.5)' }}>
                <Accordion
                    disabled={
                        !callOff.salesOrder.builtItemOrderlines &&
                        !callOff.salesOrder.stockItemOrderlines
                    }
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Products</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table
                            dataSource={productsTableData}
                            columns={productsTableColumns}
                            pagination={false}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>
            <div style={{ boxShadow: '0px 0px 30px -10px rgba(150,170,180,0.5)' }}>
                <Accordion
                    disabled={
                        !callOff.salesOrder.builtItemOrderlines &&
                        !callOff.salesOrder.stockItemOrderlines
                    }
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Contacts</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table
                            dataSource={contactTableData}
                            columns={contactTableColumns}
                            pagination={false}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>
        </Container>
    )
}
