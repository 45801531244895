import { Box, styled } from '@mui/system'
import { useEffect, useState } from 'react'
import { Button, DatePicker, Select, Switch, Table, Tag, Typography } from 'antd'
import PageHeader from '../ui/PageHeader'
import { Urls } from '../../util/urls'
import {
    getNotificationsRequest,
    setReactedNotificationsRequest,
    setSeenNotificationsRequest,
} from '../../api/notifications'
import { Notification, NotificationType } from '../../models/models'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import moment from 'moment'
import { CheckCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { fetchUnreactedAmount, fetchUnseenAmount } from '../../store/notifications-slice'
import { useHistory } from 'react-router-dom'
import {
    LogisticsWorksheetView,
    resetFilters as resetAssemblyWorksheetsFilters,
    setFilterAssemblyId as setFilterAssemblyIdLogistics,
    setActiveView,
} from '../../store/logistics-worksheet-slice'
import { getAssemblyByIdRequest } from '../../api/assemblies'
import { toastFailure } from '../../util/toast'
import {
    resetFilters as resetAssembliesFilters,
    setFilterAssemblyId as setFilterAssemblyIdAssemblies,
} from '../../store/assemblies-slice'

const Container = styled(Box)(() => ({
    margin: '50px',
}))

const Header = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    justifyContent: 'flex-start',
    margin: '10px',
}))

const DEFAULT_PAGE = 1

const referenceColumn: {
    [key in NotificationType]: (notification: Notification) => string | undefined
} = {
    [NotificationType.CompletedAssembly]: (notification: Notification) => {
        return `assembly id: ${notification.assemblyId}`
    },
    [NotificationType.CreatedAssembly]: (notification: Notification) => {
        return `assembly id: ${notification.assemblyId}`
    },
}

export default function Notifications() {
    const [notifications, setNotifications] = useState<Notification[]>([])
    const [typeFilter, setTypeFilter] = useState<NotificationType | 'All'>('All')
    const [withSeenNotifications, setWithSeenNotifications] = useState<boolean>(true)
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(DEFAULT_PAGE)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)
    const [amount, setAmount] = useState<number>(0)
    const { user } = useSelector((state: RootState) => state.authentication)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (!user?.id) {
            return
        }
        dispatch(fetchUnreactedAmount(user.id))
    }, [user])

    const notificationReaction: {
        [key in NotificationType]: (notification: Notification) => void
    } = {
        [NotificationType.CompletedAssembly]: (notification: Notification) => {
            if (!user?.id) return
            setReactedNotificationsRequest({ notificationIds: [notification.id] }).then(
                (response) => {
                    if (!response.successful) {
                        toastFailure(response.message)
                        return
                    }
                    if (notification.assemblyId === undefined) {
                        toastFailure(
                            'The notification did not include the assembly id to complete the action'
                        )
                        return
                    }

                    getAssemblyByIdRequest(notification.assemblyId).then((response) => {
                        if (!response.successful) {
                            toastFailure(response.message)
                            return
                        }
                        dispatch(resetAssembliesFilters())
                        dispatch(setFilterAssemblyIdAssemblies(notification.assemblyId))
                        dispatch(fetchUnreactedAmount(user.id))
                        history.push(Urls.Assemblies)
                    })
                }
            )
        },
        [NotificationType.CreatedAssembly]: (notification: Notification) => {
            if (!user?.id) return
            setReactedNotificationsRequest({ notificationIds: [notification.id] }).then(
                (response) => {
                    if (!response.successful) {
                        toastFailure(response.message)
                        return
                    }
                    if (notification.assemblyId === undefined) {
                        toastFailure(
                            'The notification did not include the assembly id to complete the action'
                        )
                        return
                    }

                    getAssemblyByIdRequest(notification.assemblyId).then((response) => {
                        if (!response.successful) {
                            toastFailure(response.message)
                            return
                        }
                        dispatch(resetAssemblyWorksheetsFilters())
                        dispatch(setActiveView(LogisticsWorksheetView.Transfer))
                        dispatch(setFilterAssemblyIdLogistics(notification.assemblyId.toString()))
                        dispatch(fetchUnreactedAmount(user.id))
                        history.push(Urls.WorksheetsLogistics)
                    })
                }
            )
        },
    }

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const getNotifications = async () => {
        if (!user) return

        const response = await getNotificationsRequest({
            userId: user.id,
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
            type: typeFilter !== 'All' ? typeFilter : undefined,
            onlyUnseen: !withSeenNotifications,
        })
        if (response.successful) {
            setNotifications(response.data.notifications)
            setAmount(response.data.count)

            const unseenNotifications = response.data.notifications.filter((notification) => {
                return !notification.seenAt
            })

            if (unseenNotifications.length > 0) {
                await setSeenNotificationsRequest({
                    notificationIds: unseenNotifications.map((n) => n.id),
                })
                dispatch(fetchUnseenAmount(user.id))
            }
        }
    }
    useEffect(() => {
        getNotifications()
    }, [typeFilter, page, rowsPerPage, withSeenNotifications])

    const onTypeFilterChange = (value: NotificationType | 'All') => {
        setTypeFilter(value)
        setPage(DEFAULT_PAGE)
    }

    const columns = [
        {
            title: 'Type',
            key: 'Type',
            render: (notification: Notification) =>
                notification.notificationType.replace(/([A-Z])/g, ' $1'),
        },
        {
            title: 'Description',
            key: 'Description',
            render: (notification: Notification) => notification.comment,
        },
        {
            title: 'Reference',
            key: 'Reference',
            render: (notification: Notification) =>
                referenceColumn[notification.notificationType](notification),
        },
        {
            title: 'Actions',
            key: 'Actions',
            render: (notification: Notification) => {
                return (
                    <Box>
                        <Button
                            onClick={() => {
                                notificationReaction[notification.notificationType](notification)
                            }}
                        >
                            React
                        </Button>
                    </Box>
                )
            },
        },
        {
            title: 'Labels',
            key: 'labels',
            render: (notification: Notification) => {
                return (
                    <Box>
                        {notification.reactedAt && (
                            <Tag icon={<CheckCircleOutlined />} color="success">
                                Reacted
                            </Tag>
                        )}
                        {notification.seenAt && (
                            <Tag icon={<CheckCircleOutlined />} color="success">
                                Seen
                            </Tag>
                        )}
                    </Box>
                )
            },
        },
        {
            title: 'Received at',
            key: 'Received at',
            render: (notification: Notification) => {
                return moment(notification.timeCreatedAt).format('DD/MM/YYYY')
            },
        },
    ]

    return (
        <>
            <PageHeader
                title="Notifications"
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        link: Urls.Notifications,
                        name: 'Notifications',
                    },
                ]}
            />
            <Container>
                <Box>
                    <Header>
                        <Select
                            value={typeFilter}
                            onChange={onTypeFilterChange}
                            style={{ width: '280px' }}
                        >
                            <Select.Option value={'All'} key={'All'}>
                                {'All'}
                            </Select.Option>
                            {Object.values(NotificationType).map((type) => {
                                return (
                                    <Select.Option value={type} key={type}>
                                        {/** Make space after each capitalized letter */}
                                        {type.replace(/([A-Z])/g, ' $1')}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '10px',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography>Include old notifications</Typography>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={withSeenNotifications}
                                onClick={() => {
                                    setWithSeenNotifications(!withSeenNotifications)
                                    setPage(DEFAULT_PAGE)
                                }}
                            />
                        </Box>
                    </Header>

                    <Table
                        columns={columns}
                        dataSource={notifications}
                        bordered
                        size="middle"
                        pagination={{
                            defaultPageSize: 10,
                            current: page,
                            onChange: pageChange,
                            total: amount,
                        }}
                    />
                </Box>
            </Container>
        </>
    )
}
