import { styled } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { useAppDispatch } from '../../store/hooks'
import {
    ActiveModal,
    closeModal,
    setActiveView,
    GeneralAssemblyView,
} from '../../store/general-assemblies-slice'
import PageHeader from '../ui/PageHeader'
import { Urls } from '../../util/urls'
import GeneralAssemblies from './GeneralAssemblies'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import UpdateGeneralAssemblyModal from '../modals/update-general-assembly-modal/UpdateGeneralAssemblyModal'
import DeleteGeneralAssemblyModal from '../modals/delete-general-assembly-modal/DeleteGeneralAssemblyModal'
import InspectGeneralAssemblyModal from '../modals/inspect-general-assembly-modal/InspectGeneralAssemblyModal'
import CreateGeneralAssemblyModal from '../modals/create-general-assembly-modal/CreateGeneralAssemblyModal'

const Container = styled('div')``

const ContentContainer = styled('div')`
    padding: 25px;
`

export default function GeneralAssembliesRoute() {
    const { activeView, modalEntityId, activeModal, filterWarehouseId } = useSelector(
        (state: RootState) => state.generalAssemblies
    )
    const dispatch = useAppDispatch()

    const tabsOptions: TabsProps['items'] = [
        {
            key: GeneralAssemblyView.GeneralAssemblies,
            label: `General Assemblies`,
            children: <GeneralAssemblies />,
        },
    ]

    return (
        <Container>
            <PageHeader
                title="General Assemblies"
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        link: Urls.GeneralAssemblies,
                        name: 'General Assemblies',
                    },
                ]}
            />
            <ContentContainer>
                <Tabs
                    type="card"
                    activeKey={activeView}
                    onChange={(view) => dispatch(setActiveView(view as GeneralAssemblyView))}
                    defaultActiveKey={GeneralAssemblyView.GeneralAssemblies}
                    items={tabsOptions}
                />

                {activeModal === ActiveModal.GeneralAssemblyCreate && (
                    <CreateGeneralAssemblyModal
                        warehouseId={filterWarehouseId}
                        closeModal={(generalAssembly) => {
                            dispatch(closeModal(JSON.stringify(generalAssembly)))
                        }}
                    />
                )}

                {modalEntityId && activeModal === ActiveModal.GeneralAssemblyDelete && (
                    <DeleteGeneralAssemblyModal
                        generalAssemblyId={modalEntityId}
                        closeModal={(generalAssembly) =>
                            dispatch(closeModal(JSON.stringify(generalAssembly)))
                        }
                    />
                )}
                {modalEntityId && activeModal === ActiveModal.GeneralAssemblyUpdate && (
                    <UpdateGeneralAssemblyModal
                        generalAssemblyId={modalEntityId}
                        closeModal={(generalAssembly) =>
                            dispatch(closeModal(JSON.stringify(generalAssembly)))
                        }
                    />
                )}
                {modalEntityId && activeModal === ActiveModal.GeneralAssemblyInspect && (
                    <InspectGeneralAssemblyModal
                        generalAssemblyId={modalEntityId}
                        closeModal={() => dispatch(closeModal())}
                    />
                )}
            </ContentContainer>
        </Container>
    )
}
