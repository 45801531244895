import {
    CircularProgress,
    Dialog,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    IconButton,
} from '@mui/material'
import { Box } from '@mui/system'
import { Divider } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getPalletRequest, ProductDTO } from '../../../api/warehouse-map'
import { Pallet } from '../../../models/models'
import { toastFailure } from '../../../util/toast'
import DoneIcon from '@mui/icons-material/Done'
import { splitPallet } from '../../../store/warehouse-map-slice'
import { InputSection as InputSectionComponent, TextInput } from '../../ui/InputFields'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
    isOpen: boolean
    onClose: () => void
    palletId: number
}

interface SplitProductsFormState {
    [productId: number]: string
}

const InputSection = (props: { children: React.ReactNode; text: string }) => {
    return (
        <InputSectionComponent width={120} text={props.text}>
            {props.children}
        </InputSectionComponent>
    )
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

export const SplitPalletModal = ({ isOpen, onClose, palletId }: Props) => {
    const [pallet, setPallet] = useState<Pallet>()
    const [splitProductQuantitiesForm, setSplitProductQuantitiesForm] =
        useState<SplitProductsFormState>({})
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const dispatch = useDispatch()

    const setSplitProductQuantity = (productId: number, quantity: string) => {
        const clone = { ...splitProductQuantitiesForm }
        clone[productId] = quantity
        setSplitProductQuantitiesForm(clone)
    }

    useEffect(() => {
        getPalletRequest(palletId).then((response) => {
            if (response.successful) {
                if (!response.data) {
                    toastFailure('Could not find the pallet')
                    setModalState(ModalState.NotFound)
                    onClose()
                }
                setSplitProductQuantitiesForm(
                    response.data.palletProducts.reduce<SplitProductsFormState>((acc, cur) => {
                        acc[cur.productId] = ''
                        return acc
                    }, {})
                )
                setModalState(ModalState.Success)
                setPallet(response.data)
            } else {
                if (response.status === 404) {
                    toastFailure('Could not find the pallet')
                    setModalState(ModalState.NotFound)
                    onClose()
                } else {
                    toastFailure('Something went wrong retrieving the pallet')
                    setModalState(ModalState.Error)
                    onClose()
                }
            }
        })
    }, [])

    const isSplitButtonEnabled = () => {
        let hasRemainingStockAfterSplit = false
        let hasSplitProduct = false
        for (const productQuantity of Object.entries(splitProductQuantitiesForm)) {
            const [id, quantity] = productQuantity
            const palletProduct = getPalletProductById(Number(id))
            if (!palletProduct) {
                return false
            }
            if (Number(quantity) > 0) {
                hasSplitProduct = true
            }
            if (palletProduct.amount > Number(quantity)) {
                hasRemainingStockAfterSplit = true
            }
        }
        return hasRemainingStockAfterSplit && hasSplitProduct
    }

    const getPalletProductById = (id: number) => {
        if (!pallet) {
            return
        }
        const palletProduct = pallet.palletProducts.find((pp) => pp.productId === Number(id))
        if (!palletProduct) {
            return
        }
        return { amount: palletProduct.amount, ...palletProduct.product }
    }

    const handleSplit = () => {
        if (!pallet) {
            return
        }
        const productsBefore = pallet.palletProducts.map((pp) => ({
            amount: pp.amount,
            id: pp.productId,
        }))
        const productsToSplit = Object.entries(splitProductQuantitiesForm)
            //get products where quantity has been provided in form
            .filter((entry): ProductDTO | undefined => {
                const [_, quantity] = entry
                return quantity
            })
            .map((entry) => {
                const [id, quantity] = entry
                return {
                    amount: Number(quantity),
                    id: Number(id),
                }
            })
        dispatch(splitPallet({ palletId: pallet.id, productsBefore, productsToSplit }))
        onClose()
    }

    return (
        <Dialog open={isOpen} onClose={onClose} style={{ zIndex: 100 }} fullWidth maxWidth={'md'}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '15px',
                }}
            >
                <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            {modalState === ModalState.Loading && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        margin: '20px',
                        width: '100%',
                        height: '500px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                </Box>
            )}
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.NotFound && (
                <Box>Could not find the pallet with id {palletId}</Box>
            )}
            {modalState === ModalState.Success && (
                <Box>
                    <Box>
                        <InputSection text="Quantities">
                            <Table size="medium">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Quantity</TableCell>
                                        <TableCell>Amount to split</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(splitProductQuantitiesForm).map(
                                        (productQuantity) => {
                                            const [id, amount] = productQuantity
                                            const product = getPalletProductById(Number(id))
                                            return (
                                                <TableRow key={id}>
                                                    <TableCell>{product?.code}</TableCell>
                                                    <TableCell>{product?.amount}</TableCell>
                                                    <TableCell>
                                                        <TextInput
                                                            type="number"
                                                            value={amount}
                                                            onChange={(value) => {
                                                                if (
                                                                    product &&
                                                                    Number(value) >
                                                                        Number(product?.amount)
                                                                ) {
                                                                    value =
                                                                        product?.amount.toString()
                                                                }
                                                                setSplitProductQuantity(
                                                                    Number(id),
                                                                    value
                                                                )
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    )}
                                </TableBody>
                            </Table>
                        </InputSection>
                    </Box>
                    <Divider />
                    <Box sx={{ textAlign: 'right', padding: '15px 23px 15px 0px' }}>
                        <Button
                            onClick={handleSplit}
                            variant="contained"
                            color="primary"
                            style={{
                                textTransform: 'none',
                                margin: '0px 23px',
                            }}
                            disabled={!isSplitButtonEnabled()}
                        >
                            <DoneIcon style={{ marginRight: '4px' }} />
                            Split
                        </Button>
                    </Box>
                </Box>
            )}
        </Dialog>
    )
}
