import { IconButton } from '@mui/material'
import { ReactElement, useMemo } from 'react'
import PageHeader from '../ui/PageHeader'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ActiveModal, closeModal, openModal, setUpdateTableToken } from '../../store/users-slice'
import { Urls } from '../../util/urls'
import { UpdateUserModal } from '../modals/update-user-modal/UpdateUserModal'
import { UpdateUserPasswordModal } from '../modals/update-user-password-modal/UpdateUserPasswordModal'
import UsersTable from '../tables/UserTable'
import { UserRelations, getUsersRequest } from '../../api/users'
import { Tooltip } from 'antd'
import { User } from '../../models/models'
import { DeleteOutlined, EditOutlined, LockOutlined } from '@ant-design/icons'
import { UsersTopBar } from './UsersTopBar'
import { CreateUserModal } from '../modals/create-user-modal/CreateUserModal'
import { DeleteUserModal } from '../modals/delete-user-modal/DeleteUserModal'

export default function Users(): ReactElement {
    const dispatch = useAppDispatch()

    const { modalState, updateTableToken, userFilters } = useAppSelector((state) => state.users)

    const actionComponents = useMemo(
        () => [
            (user: User) => {
                return (
                    <Tooltip title="Change user details">
                        <IconButton
                            onClick={() => {
                                dispatch(
                                    openModal({
                                        activeModal: ActiveModal.UpdateUser,
                                        modalEntityId: user.id,
                                    })
                                )
                            }}
                        >
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>
                )
            },
            (user: User) => {
                return (
                    <Tooltip title="Reset the user's password">
                        <IconButton
                            onClick={() => {
                                dispatch(
                                    openModal({
                                        activeModal: ActiveModal.UpdatePasswordUser,
                                        modalEntityId: user.id,
                                    })
                                )
                            }}
                        >
                            <LockOutlined />
                        </IconButton>
                    </Tooltip>
                )
            },
            (user: User) => {
                return (
                    <Tooltip title="Delete user">
                        <IconButton
                            onClick={() => {
                                dispatch(
                                    openModal({
                                        activeModal: ActiveModal.DeleteUser,
                                        modalEntityId: user.id,
                                    })
                                )
                            }}
                        >
                            <DeleteOutlined />
                        </IconButton>
                    </Tooltip>
                )
            },
        ],
        []
    ) // Empty array means this only runs once

    return (
        <>
            <PageHeader
                title="Users"
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        link: Urls.Users,
                        name: 'Users',
                    },
                ]}
            />

            <div style={{ padding: '30px' }}>
                <UsersTopBar />
                <UsersTable
                    request={() =>
                        getUsersRequest({
                            relations: [UserRelations.Warehouse],
                            email: userFilters?.email,
                            name: userFilters?.name,
                            role: userFilters?.role,
                        }).then((e) => {
                            return e
                        })
                    }
                    actionComponents={actionComponents}
                    dependencies={{ updateTableToken, userFilters }}
                />
            </div>

            {/* MODALS */}
            <DeleteUserModal
                userId={modalState.modalEntityId}
                isVisible={
                    modalState.activeModal === ActiveModal.DeleteUser && !!modalState.modalEntityId
                }
                onDeleted={(user) => dispatch(setUpdateTableToken(`delete-user-${user.id}`))}
                closeModal={() => dispatch(closeModal())}
            />
            <UpdateUserPasswordModal
                userId={modalState.modalEntityId}
                isVisible={
                    modalState.activeModal === ActiveModal.UpdatePasswordUser &&
                    !!modalState.modalEntityId
                }
                onCancel={() => dispatch(closeModal())}
                onSubmit={(user) => dispatch(setUpdateTableToken(`updated-pass-user-${user.id}`))}
            />
            <UpdateUserModal
                userId={modalState.modalEntityId}
                isVisible={
                    modalState.activeModal === ActiveModal.UpdateUser && !!modalState.modalEntityId
                }
                close={() => dispatch(closeModal())}
                onSubmit={(user) => dispatch(setUpdateTableToken(`updated-user-${user.id}`))}
            />

            <CreateUserModal
                userId={modalState.modalEntityId}
                isVisible={modalState.activeModal === ActiveModal.CreateUser}
                close={() => dispatch(closeModal())}
                onSubmit={(user) => dispatch(setUpdateTableToken(`created-user-${user.id}`))}
            />
        </>
    )
}
