import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { SalesOrder } from '../../../models/models'
import SalesOrderOrderlineTable from '../../project-master/sales-orders/SalesOrderOrderlineTable'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    gap: '30px',
    width: '100%',
})

interface Props {
    salesOrder: SalesOrder
}
export default function OrderlinesTab({ salesOrder }: Props) {
    return (
        <Container>
            <SalesOrderOrderlineTable salesOrder={salesOrder} />
        </Container>
    )
}
