import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { useEffect, useMemo, useState } from 'react'
import { Project, ProjectBuiltItem, ProjectStockItem, WarehouseId } from '../../../../models/models'
import { useAppSelector } from '../../../../store/hooks'
import {
    AddedProjectBuiltItem,
    AddedProjectStockItem,
    getBuiltItemOrderlines,
    getStockItemOrderlines,
    isAddedSopProjectBuiltItem,
    isAddedSopProjectStockItem,
    Orderline,
} from './CreateSaleOrder'
import AddBuiltItemModal from './modals/AddBuiltItemModal'
import AddStockItemModal from './modals/AddStockItemModal'
import { getProjectBuiltItems } from '../../../../api/projects/built-items'
import { getProjectStockItems } from '../../../../api/projects/stock-items'
import { Button, Input, Table } from 'antd'

const RowContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

const Container = styled(Box)({
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
})

const ItemContainer = styled(Box)({ display: 'flex', flexDirection: 'column', padding: '20px' })
const InputsContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
}))

export const OrderlineTabContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
})

const AddedItemsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '100px',
})

enum ActiveModal {
    None = 'None',
    AddBuiltItem = 'AddBuiltItem',
    AddStockItem = 'AddStockItem',
}

interface ProductsProps {
    orderlines: Orderline[]
    setOrderlines: React.Dispatch<React.SetStateAction<Orderline[]>>
    warehouseId: WarehouseId
    onNextStep: () => void
}
export default function Products(props: ProductsProps) {
    const { orderlines, setOrderlines, warehouseId, onNextStep } = props

    const [projectStockItems, setProjectStockItems] = useState<ProjectStockItem[]>([])
    const [projectBuiltItems, setProjectBuiltItems] = useState<ProjectBuiltItem[]>([])
    const [buildItemCodeSearch, setBuildItemCodeSearch] = useState<string>('')
    const [stockItemCodeSearch, setStockItemCodeSearch] = useState<string>('')
    const [activeModal, setActiveModal] = useState<ActiveModal>(ActiveModal.None)
    const [modalProjectStockItem, setModalProjectStockItem] = useState<ProjectStockItem>()
    const [modalProjectBuiltItem, setModalProjectBuiltItem] = useState<ProjectBuiltItem>()
    const project: Project | null = useAppSelector(
        (state) => state.singleProjectView.projectBeingUpdated
    )

    const addedBuiltItems = useMemo(() => {
        return getBuiltItemOrderlines(orderlines)
    }, [orderlines])

    const addedStockItems = useMemo(() => {
        return getStockItemOrderlines(orderlines)
    }, [orderlines])

    const closeModal = () => {
        setActiveModal(ActiveModal.None)
        setModalProjectStockItem(undefined)
        setModalProjectBuiltItem(undefined)
    }

    const openAddBuiltItemModal = (projectBuiltItem: ProjectBuiltItem) => {
        setActiveModal(ActiveModal.AddBuiltItem)
        setModalProjectBuiltItem(projectBuiltItem)
    }

    const getConsumedAmountBuiltItem = (builtItemId: number): number => {
        const addedAmount = addedBuiltItems
            ?.filter((addedBuiltItem) => builtItemId === addedBuiltItem.id)
            .reduce((acc, cur) => {
                return acc + cur.addedAmount || 0
            }, 0)
        const usedAmount =
            projectBuiltItems?.find((projectBuiltItem) => builtItemId === projectBuiltItem.id)
                ?.used || 0
        return addedAmount + usedAmount
    }

    const getAmountProjectBuiltItem = (builtItemId: number): number => {
        return projectBuiltItems?.find((builtItem) => builtItemId === builtItem.id)?.amount || 0
    }

    const getAvailableAmountBuiltItem = (builtItemId: number): number => {
        return getAmountProjectBuiltItem(builtItemId) - getConsumedAmountBuiltItem(builtItemId)
    }

    const getConsumedAmountStockItem = (stockItemId: number): number => {
        const addedAmount = addedStockItems
            ?.filter((addedStockItem) => stockItemId === addedStockItem.id)
            .reduce((acc, cur) => {
                return acc + cur.addedAmount || 0
            }, 0)
        const usedAmount =
            projectStockItems?.find((projectStockItem) => stockItemId === projectStockItem.id)
                ?.used || 0
        return addedAmount + usedAmount
    }

    const getAmountProjectStockItem = (stockItemId: number): number => {
        return projectStockItems?.find((stockItem) => stockItemId === stockItem.id)?.amount || 0
    }

    const getAvailableAmountStockItem = (stockItemId: number): number => {
        return getAmountProjectStockItem(stockItemId) - getConsumedAmountStockItem(stockItemId)
    }

    const openAddStockItemModal = (projectStockItem: ProjectStockItem) => {
        setActiveModal(ActiveModal.AddStockItem)
        setModalProjectStockItem(projectStockItem)
    }

    useEffect(() => {
        getProjectBuiltItems(project!.id, {
            builtItem: true,
            skip: 0,
            used: true,
            limit: 10,
            bomCode: buildItemCodeSearch,
        }).then((response) => {
            if (response.successful) {
                setProjectBuiltItems(response.data.projectBuiltItems)
            }
        })
    }, [buildItemCodeSearch])

    useEffect(() => {
        getProjectStockItems(project!.id, {
            stockItem: true,
            skip: 0,
            limit: 10,
            used: true,
            code: stockItemCodeSearch,
        }).then((response) => {
            if (response.successful) {
                setProjectStockItems(response.data.projectStockItems)
            }
        })
    }, [stockItemCodeSearch])

    const itemInputHandler = (input: string, remainding: number | undefined) => {
        if (remainding === undefined) {
            return ''
        }
        if (parseInt(input, 10) < 0) {
            return '0'
        }
        if (input.length > 1 && input[0] === '0') {
            input = input.slice(1, input.length)
        }
        if (parseInt(input, 10) > remainding) {
            input = remainding.toString()
        }
        return input
    }

    const pushAddedProjectBuiltItem = (
        builtItem: ProjectBuiltItem,
        amount: number,
        unitDiscount: number,
        unitPrice: number
    ) => {
        setOrderlines([
            ...orderlines,
            { ...builtItem, addedAmount: amount, unitPrice, unitDiscount },
        ])
    }

    const removeAddedProjectBuiltItem = (addedBuiltItemId: number) => {
        setOrderlines(
            orderlines.filter(
                (orderline) =>
                    !isAddedSopProjectBuiltItem(orderline) || orderline.id !== addedBuiltItemId
            )
        )
    }

    const pushAddedProjectStockItem = (
        stockItem: ProjectStockItem,
        amount: number,
        unitDiscount: number,
        unitPrice: number
    ) => {
        setOrderlines([
            ...orderlines,
            { ...stockItem, addedAmount: amount, unitPrice, unitDiscount },
        ])
    }

    const removeAddedProjectStockItem = (addedStockItemId: number) => {
        setOrderlines(
            orderlines.filter(
                (orderline) =>
                    !isAddedSopProjectStockItem(orderline) || orderline.id !== addedStockItemId
            )
        )
    }

    const builtItemHasBeenAdded = (id: number) =>
        addedBuiltItems.find((bi) => bi.id === id) !== undefined

    const stockItemHasBeenAdded = (id: number) =>
        addedStockItems.find((bi) => bi.id === id) !== undefined

    const builtItemColumns = [
        {
            key: 'code',
            title: 'BoM code',
            render: (projectBuiltItem: ProjectBuiltItem) => {
                return projectBuiltItem?.builtItem?.bomCode
            },
        },
        {
            key: 'status',
            title: 'Project Status',
            render: (projectBuiltItem: ProjectBuiltItem) => {
                return `assigned: 
                ${getConsumedAmountBuiltItem(projectBuiltItem.id)}/${projectBuiltItem.amount}`
            },
        },
        {
            key: 'add',
            title: 'Add',
            render: (projectBuiltItem: ProjectBuiltItem) => {
                return (
                    <Button
                        type="primary"
                        disabled={getAvailableAmountBuiltItem(projectBuiltItem.id) <= 0}
                        onClick={() => openAddBuiltItemModal(projectBuiltItem)}
                    >
                        Add
                    </Button>
                )
            },
        },
    ]

    const stockItemColumns = [
        {
            key: 'code',
            title: 'Code',
            render: (projectStockItem: ProjectStockItem) => {
                return projectStockItem?.stockItem?.code
            },
        },
        {
            key: 'status',
            title: 'Project Status',
            render: (projectStockItem: ProjectStockItem) => {
                return `assigned: 
                ${getConsumedAmountStockItem(projectStockItem.id)}/${projectStockItem.amount}`
            },
        },
        {
            key: 'add',
            title: 'Add',
            render: (projectStockItem: ProjectStockItem) => {
                return (
                    <Button
                        disabled={getAvailableAmountStockItem(projectStockItem.id) <= 0}
                        onClick={() => openAddStockItemModal(projectStockItem)}
                    >
                        {stockItemHasBeenAdded(projectStockItem.id) ? 'Added' : 'Add'}
                    </Button>
                )
            },
        },
    ]

    const addedProductsColumns = [
        {
            key: 'code',
            title: 'Code',
            render: (addedItem: AddedProjectBuiltItem | AddedProjectStockItem) => {
                if (isAddedSopProjectBuiltItem(addedItem)) {
                    return addedItem?.builtItem?.bomCode
                }
                return addedItem.stockItem.code
            },
        },
        {
            key: 'price',
            title: 'Price',
            render: (addedItem: AddedProjectBuiltItem | AddedProjectStockItem) => {
                return addedItem.unitPrice
            },
        },
        {
            key: 'discount',
            title: 'Discount',
            render: (addedItem: AddedProjectBuiltItem | AddedProjectStockItem) => {
                return addedItem.unitDiscount
            },
        },
        {
            key: 'amount',
            title: 'Amount',
            render: (addedItem: AddedProjectBuiltItem | AddedProjectStockItem) => {
                return addedItem.addedAmount
            },
        },
        {
            key: 'remove',
            title: '',
            render: (addedItem: AddedProjectBuiltItem | AddedProjectStockItem) => {
                if (isAddedSopProjectBuiltItem(addedItem)) {
                    return (
                        <Button
                            aria-label="close"
                            onClick={() => removeAddedProjectBuiltItem(addedItem.id)}
                            icon={<CloseIcon />}
                        />
                    )
                }
                return (
                    <Button
                        aria-label="close"
                        onClick={() => removeAddedProjectStockItem(addedItem.id)}
                        icon={<CloseIcon />}
                    />
                )
            },
        },
    ]

    return (
        <Container>
            {activeModal === ActiveModal.AddBuiltItem && modalProjectBuiltItem && (
                <AddBuiltItemModal
                    projectBuiltItem={modalProjectBuiltItem}
                    closeModal={closeModal}
                    addProjectBuiltItem={pushAddedProjectBuiltItem}
                />
            )}
            {activeModal === ActiveModal.AddStockItem && modalProjectStockItem && (
                <AddStockItemModal
                    projectStockItem={modalProjectStockItem}
                    closeModal={closeModal}
                    addProjectStockItem={pushAddedProjectStockItem}
                />
            )}
            <RowContainer>
                <OrderlineTabContainer>
                    <ItemContainer>
                        <h3>Built items</h3>
                        <InputsContainer>
                            <Input
                                addonBefore={'bom code'}
                                style={{
                                    width: '100%',
                                    marginBottom: '10px',
                                }}
                                value={buildItemCodeSearch}
                                onChange={(e) => setBuildItemCodeSearch(e.target.value)}
                            />
                        </InputsContainer>
                        <Table
                            dataSource={projectBuiltItems}
                            columns={builtItemColumns}
                            pagination={false}
                        />
                    </ItemContainer>

                    <ItemContainer>
                        <h3>Stock Items</h3>
                        <InputsContainer>
                            <Input
                                addonBefore={'stock code'}
                                style={{
                                    width: '100%',
                                    marginBottom: '10px',
                                }}
                                value={stockItemCodeSearch}
                                onChange={(e) => setStockItemCodeSearch(e.target.value)}
                            />
                        </InputsContainer>
                        <Table
                            dataSource={projectStockItems.filter(
                                (projectStockItem) =>
                                    (projectStockItem.used || 0) <= projectStockItem.amount
                            )}
                            columns={stockItemColumns}
                            pagination={false}
                        />
                    </ItemContainer>
                </OrderlineTabContainer>

                <AddedItemsContainer>
                    <Table
                        dataSource={[...addedBuiltItems, ...addedStockItems]}
                        columns={addedProductsColumns}
                        pagination={false}
                    />
                    {[...addedStockItems, ...addedBuiltItems].length > 0 && (
                        <Button type="primary" onClick={onNextStep}>
                            Continue
                        </Button>
                    )}
                </AddedItemsContainer>
            </RowContainer>
        </Container>
    )
}
