import { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Input, Modal, Select, Button } from 'antd'
import { TaskType, TaskDepartment, WarehouseId } from '../../../models/models'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { CircularProgress, styled } from '@mui/material'
import '../../tables/table-styles.css'
import {
    getTaskTypeByIdRequest,
    TaskTypeRelation,
    UpdateTaskTypeDto,
    updateTaskTypeRequest,
} from '../../../api/task-types'
import { OrderedListOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 47%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

interface UpdateTaskTypeModalProps {
    taskTypeId: number
    closeModal: (taskType?: TaskType) => void
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

export default function UpdateTaskTypeModal({ taskTypeId, closeModal }: UpdateTaskTypeModalProps) {
    const [title, setTitle] = useState<string>('')
    const [link, setLink] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [warehouseId, setWarehouseId] = useState<WarehouseId>()
    const [minutes, setMinutes] = useState<number>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const [taskTypeDepartment, setTaskDepartment] = useState<TaskDepartment>(TaskDepartment.ANY)
    const [taskType, setTaskType] = useState<TaskType>()
    const dispatch = useDispatch()

    useEffect(() => {
        getTaskType()
    }, [])

    const getTaskType = async () => {
        const response = await getTaskTypeByIdRequest(taskTypeId, {
            relations: [TaskTypeRelation.CreatedBy],
        })

        if (response.successful) {
            const taskType = response.data
            setTaskType(taskType)
            setTitle(taskType.title)
            setLink(taskType.link || '')
            setDescription(taskType.description)
            setTaskDepartment(taskType.department)
            setMinutes(taskType.minutes)
            setWarehouseId(taskType.warehouseId)
            setModalState(ModalState.Success)
        } else {
            if (response.status === 404) {
                setModalState(ModalState.NotFound)
            } else {
                setModalState(ModalState.Error)
            }
            toastFailure(response.message)
        }
    }

    const onOk = async () => {
        if (!taskType) {
            return
        }
        const body: UpdateTaskTypeDto = {}
        if (title !== taskType.title) {
            body.title = title
        }
        if (description !== taskType.description) {
            body.description = description
        }
        if (taskTypeDepartment !== taskType.department) {
            body.department = taskTypeDepartment
        }
        if (minutes !== taskType.minutes) {
            body.minutes = minutes
        }
        if (link !== taskType.link) {
            body.link = link
        }
        if (warehouseId !== taskType.warehouseId) {
            body.warehouseId = warehouseId
        }

        const response = await updateTaskTypeRequest(taskTypeId, body)
        if (!response.successful) {
            toastFailure(response.message)
            return
        }
        toastSuccess(`Updated task template ${taskTypeId}`)
        dispatch(closeModal(response.data))
    }

    const isSubmitDisabled = () => {
        return false
    }

    return (
        <Modal open={true} footer={null} onCancel={() => closeModal()}>
            {modalState === ModalState.Loading && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        margin: '20px',
                        width: '100%',
                        height: '500px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                </Box>
            )}
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.NotFound && (
                <Box>Could not find the taskType with the id of {taskTypeId}</Box>
            )}
            {modalState === ModalState.Success && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Update Template {taskTypeId}</Title>
                    </Header>
                    <Divider />
                    <div>
                        <Label>Title </Label>
                        <Input value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div>
                        <Label>Description</Label>
                        <Input.TextArea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{ minHeight: 200 }}
                        />
                    </div>
                    <InputRow>
                        <RowElement>
                            <Label>Warehouse</Label>
                            <Select
                                style={{ width: '100%' }}
                                value={warehouseId}
                                onChange={(value: WarehouseId) => setWarehouseId(value)}
                                options={[
                                    { value: WarehouseId.Monument, label: 'Monument' },
                                    { value: WarehouseId.Scandia, label: 'Scandia' },
                                    { value: null, label: 'None' },
                                ]}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Department</Label>
                            <Select
                                style={{ width: '100%' }}
                                value={taskTypeDepartment}
                                onChange={(value: TaskDepartment) => setTaskDepartment(value)}
                                options={[
                                    { value: TaskDepartment.ASSEMBLY, label: 'Assembly' },
                                    { value: TaskDepartment.LOGISTICS, label: 'Logistics' },
                                    { value: TaskDepartment.ANY, label: 'Any' },
                                ]}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow>
                        <RowElement>
                            <Label>Minutes</Label>
                            <Input
                                style={{ width: '100%' }}
                                type="number"
                                value={minutes}
                                onWheel={(e) => e.preventDefault()}
                                onChange={(e) => setMinutes(Number(e.target.value))}
                            />
                        </RowElement>

                        <RowElement>
                            <Label>Link</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                            />
                        </RowElement>
                    </InputRow>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                        <Button type={'primary'} disabled={isSubmitDisabled()} onClick={onOk}>
                            Update
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    )
}
