import Box from '@mui/material/Box'
import { Button, CircularProgress, Divider, Typography } from '@mui/material'
import { Pallet, WarehouseAreaSpot } from '../../../models/models'
import { useAppSelector } from '../../../store/hooks'
import { useEffect, useState } from 'react'
import { styled } from '@mui/styles'
import { useDispatch } from 'react-redux'
import { movePallet, unSelect } from '../../../store/warehouse-map-slice'
import { getPalletRequest, getSpotRequest } from '../../../api/warehouse-map'
import { toastFailure } from '../../../util/toast'
import { Drawer } from 'antd'
import { RootState } from '../../../store/store'

const InfoRow = (props: { description: string; content: string; disableDivider?: boolean }) => {
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '10px',
    }))

    const RowContent = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: '6px',
    }))

    const RowDescriptionContainer = styled(Box)(() => ({
        paddingLeft: '10px',
        width: '30%',
    }))

    const RowContentContainer = styled(Box)(() => ({
        width: '70%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }))

    const { description, content, disableDivider } = props
    return (
        <Row>
            <RowContent>
                <RowDescriptionContainer>
                    <Typography sx={{ color: '#26262690' }}>{description}</Typography>
                </RowDescriptionContainer>
                <RowContentContainer>
                    <Typography>{content}</Typography>
                </RowContentContainer>
            </RowContent>
            {!disableDivider && <Divider />}
        </Row>
    )
}

const RowContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
})

const ColumnContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
})

const Column = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
})

const Row = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
    height: '50px',
})

const ModalFooter = (props: { onClick: () => void }) => {
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
    }))

    const MoveButton = styled(Button)(() => ({
        padding: '0px',
        minWidth: '0px',
        margin: '10px 10px 5px 0px',
    }))

    return (
        <Row>
            <MoveButton variant="outlined" size="small" onClick={props.onClick}>
                Move
            </MoveButton>
        </Row>
    )
}

interface PalletMovementModal {
    isOpen: boolean
    onClose: () => void
    spotId: number
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

export const ConfirmPalletMovementModal = ({ isOpen, onClose, spotId }: PalletMovementModal) => {
    const { selected } = useAppSelector((state: RootState) => state.warehouseMap)
    const [toSpot, setToSpot] = useState<WarehouseAreaSpot>()
    const [pallet, setPallet] = useState<Pallet>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const dispatch = useDispatch()

    const getProject = (pallet: Pallet) => {
        return pallet?.project || pallet.assembly?.callOff?.project
    }

    const filterProjectPallets = (pallets: Pallet[]) => {
        return pallets.filter((p) => !!getProject(p))
    }

    const getAssembly = (pallet: Pallet) => {
        return pallet?.assembly
    }

    const filterAssemblyPallets = (pallets: Pallet[]) => {
        return pallets.filter((p) => !!getAssembly(p))
    }

    const getData = async () => {
        if (!selected.selectedId) {
            toastFailure('Could not retrieve the selected pallet in CTR')
            onClose()
            return null
        }
        try {
            const [palletResponse, spotResponse] = await Promise.all([
                getPalletRequest(selected.selectedId),
                getSpotRequest(spotId),
            ])

            if (!spotResponse.successful) {
                if (spotResponse.status === 404) {
                    toastFailure('Could not find the spot to move the pallet to')
                    setModalState(ModalState.Error)
                    onClose()
                } else {
                    toastFailure('Could not find the pallet')
                    setModalState(ModalState.Error)
                    onClose()
                }
                return
            }
            if (!palletResponse.successful) {
                if (palletResponse.status === 404) {
                    toastFailure('Could not find the spot to move the pallet to')
                    setModalState(ModalState.Error)
                    onClose()
                } else {
                    toastFailure('Could not find the pallet')
                    setModalState(ModalState.Error)
                    onClose()
                }
                return
            }

            setPallet(palletResponse.data)
            setToSpot(spotResponse.data)
            setModalState(ModalState.Success)
        } catch (e) {
            setModalState(ModalState.Error)
            onClose()
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Drawer
            visible={isOpen}
            onClose={onClose}
            style={{ zIndex: 100 }}
            contentWrapperStyle={{ height: '140px' }}
            bodyStyle={{ padding: '8px 20px' }}
            closable={false}
            placement={'bottom'}
            maskStyle={{ opacity: 0 }}
        >
            <Box style={{ width: '100%', padding: '0px 0px' }}>
                {modalState === ModalState.Loading && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            margin: '20px 0px 0px 0px',
                            width: '100%',
                            height: '500px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                    </Box>
                )}
                {modalState === ModalState.Error && <Box>Something went wrong</Box>}
                {modalState === ModalState.NotFound && (
                    <Box>Could not find the spot to move the pallet to {spotId}</Box>
                )}
                {toSpot && pallet && modalState === ModalState.Success && (
                    <>
                        <ColumnContainer>
                            <Column>
                                <RowContainer>
                                    <ColumnContainer>
                                        <Box style={{ width: '480px' }}>
                                            <h1>Moving Pallet:</h1>
                                        </Box>

                                        <InfoRow
                                            //Regret making it one-indexed in map UI and zero indexed in db
                                            content={`  x: ${pallet.warehouseAreaSpot.x + 1}   y: ${
                                                pallet.warehouseAreaSpot.y + 1
                                            }`}
                                            description="Coords"
                                        />
                                        <InfoRow
                                            content={
                                                pallet.warehouseAreaSpot.warehouseAreaId === 1
                                                    ? 'Logistics'
                                                    : 'Assembly'
                                            }
                                            description="Floor"
                                        />
                                    </ColumnContainer>
                                    {/**
                                     *                                     {pallet.assemblyId && (
                                        <InfoRow
                                            content={`${pallet.assemblyId}`}
                                            description="Assembly"
                                        />
                                    )}
                                    {pallet.comment && (
                                        <InfoRow
                                            content={`${pallet.comment}`}
                                            description="Comment"
                                        />
                                    )}
                                    {getProject(pallet) && (
                                        <InfoRow
                                            content={`${getProject(pallet).name}`}
                                            description="Project"
                                        />
                                    )}
                                     */}
                                </RowContainer>
                            </Column>
                            <Column>
                                <RowContainer>
                                    <ColumnContainer>
                                        <Box sx={{ width: '100px' }}>
                                            <h1>To</h1>
                                        </Box>
                                        <InfoRow
                                            //Regret making it one-indexed in map UI and zero indexed in db
                                            content={`x:${toSpot.x + 1} y:${toSpot.y + 1}`}
                                            description="Coords"
                                        />
                                        <InfoRow
                                            content={
                                                toSpot.warehouseAreaId === 1
                                                    ? 'Logistics'
                                                    : 'Assembly'
                                            }
                                            description="Floor"
                                        />
                                        {toSpot.pallets.length !== 0 && (
                                            <InfoRow
                                                content={`${toSpot.pallets.length}`}
                                                description="Pallets"
                                            />
                                        )}
                                    </ColumnContainer>
                                </RowContainer>
                            </Column>
                        </ColumnContainer>
                        <ModalFooter
                            onClick={() => {
                                dispatch(
                                    movePallet({
                                        fromSpotId: pallet.warehouseAreaSpotId,
                                        toSpotId: toSpot.id,
                                        palletId: pallet.id,
                                    })
                                )
                                dispatch(unSelect())
                                onClose()
                            }}
                        />
                    </>
                )}
            </Box>
        </Drawer>
    )
}
