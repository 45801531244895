import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import { Box, styled } from '@mui/system'
import { useState } from 'react'
import PageHeader from '../ui/PageHeader'
import { Urls } from '../../util/urls'
import OrderConfirmations from './order-confirmations/OrderConfirmations'
import PurchaseOrders from './purchase-orders/PurchaseOrders'
import SalesOrders from './sales-orders/SalesOrders'

export type Tabs = 'SalesOrder' | 'PurchaseOrders' | 'OrderConfirmations'

const Container = styled(Box)(``)

export default function Orders() {
    const [activeTab, setActiveTab] = useState<Tabs>('PurchaseOrders')

    const handleTabChange = (event: React.SyntheticEvent, tabKey: Tabs) => {
        event.preventDefault()
        setActiveTab(tabKey)
    }

    return (
        <>
            <PageHeader
                title="Orders"
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        link: Urls.Orders,
                        name: 'Orders',
                    },
                ]}
            />
            <Container>
                <TabContext value={activeTab}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: '20px 50px 0px 50px',
                        }}
                    >
                        <TabList onChange={handleTabChange}>
                            <Tab
                                label="Purchase Orders"
                                value="PurchaseOrders"
                                style={{ textTransform: 'none' }}
                            />
                            <Tab
                                label="Order Confirmations"
                                value="OrderConfirmations"
                                style={{ textTransform: 'none' }}
                            />
                            <Tab
                                label="Sales Orders"
                                value="SalesOrders"
                                style={{ textTransform: 'none' }}
                            />
                        </TabList>
                    </Box>

                    <TabPanel value="PurchaseOrders">
                        <PurchaseOrders />
                    </TabPanel>
                    <TabPanel value="OrderConfirmations">
                        <OrderConfirmations />
                    </TabPanel>
                    <TabPanel value="SalesOrders">
                        <SalesOrders />
                    </TabPanel>
                </TabContext>
            </Container>
        </>
    )
}
