import { styled } from '@mui/material'
import { Assembly, Bom, Project, User } from '../../../../models/models'
import { toastFailure } from '../../../../util/toast'
import { useAppDispatch } from '../../../../store/hooks'
import { ActiveModal, openModal } from '../../../../store/products-slice'

interface Props {
    assembly: Assembly
}

const Container = styled('div')`
    width: 100%;
    display: flex;
    gap: 15px;
    padding-bottom: 15px;
`

const HeaderItem = styled('div')`
    display: flex;
    flex: 1;
    font-size: 16px;
    align-items: center;
    padding: 0 15px;

    :not(:last-of-type) {
        border-right: 1px solid #ddd;
    }
`

const Bold = styled('span')`
    font-weight: 500;
    margin-right: 10px;
`

export function ActiveAssemblyHeader({ assembly }: Props) {
    const assemblyProject = assembly.callOff.project
    const dispatch = useAppDispatch()
    const assemblyBuiltItem = assembly.builtItemCallOff.sopBuiltItemOrderline.builtItem

    return (
        <Container>
            <HeaderItem>
                <Bold>Assembly ID:</Bold>
                {assembly.id}
            </HeaderItem>

            <HeaderItem>
                <Bold>Project name:</Bold>
                {assemblyProject.name}
            </HeaderItem>

            <HeaderItem>
                <Bold>Project manager:</Bold>
                {assemblyProject.projectManagers
                    .map((user) => `${user.firstName} ${user.lastName}`)
                    .join(', ')}
            </HeaderItem>

            <HeaderItem
                sx={{ cursor: 'pointer', color: '#3500A0', textDecoration: 'underline' }}
                onClick={(e) => {
                    e.stopPropagation()
                    dispatch(
                        openModal({
                            activeModal: ActiveModal.BomInspect,
                            modalEntityId: assemblyBuiltItem.id,
                        })
                    )
                }}
            >
                <Bold>Show Bom</Bold>
            </HeaderItem>

            <HeaderItem
                onClick={() => {
                    if (assemblyBuiltItem.instructionLink) {
                        window.open(assemblyBuiltItem.instructionLink, '_blank', 'noreferrer')
                    }
                }}
            >
                {assemblyBuiltItem.instructionLink ? (
                    <Bold sx={{ cursor: 'pointer', color: '#3500A0', textDecoration: 'underline' }}>
                        Show instructions
                    </Bold>
                ) : (
                    <p>No Instructions</p>
                )}
            </HeaderItem>
        </Container>
    )
}
