import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'
import { Organisation } from '../models/models'

export const getOrganisationByIdRequest = (id: number): Promise<Response<Organisation>> =>
    backendConnection.request({
        url: `/organisations/id/${id}`,
        method: 'GET',
    })

interface GetOrganisationsQueryParamsDTO {
    skip?: number
    limit?: number
    likeName?: string
    ids?: number[]
}

export const getOrganisationsRequest = (
    params?: GetOrganisationsQueryParamsDTO
): Promise<Response<Organisation[]>> => {
    return backendConnection.request({
        url: '/organisations',
        method: 'GET',
        params,
    })
}
