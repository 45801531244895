import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import userReducer from './users-slice'
import fetchCallOffReducer from './call-off-slice'
import callOffReducer from './call-off-slice'
import assemblyWorksheetSlice from './assembly-worksheets-slice'
import authenticationReducer from './authentication-slice'
import singleProjectViewReducer from './single-project-view-slice'
import projectInfoReducer from './project-info-slice'
import serialLogReducer from './serial-log-slice'
import forgotPasswordReducer from './forgot-password-slice'
import projectHistoryReducer from './project-history-slice'
import projectDocumentReducer from './project-documents-slice'
import warehouseMapSlice from './warehouse-map-slice'
import returnsAndReworksReducer from './returns-and-reworks-slice'
import notificationsSlice from './notifications-slice'
import logisticsWorksheetSlice from './logistics-worksheet-slice'
import projectMasterSlice from './project-master-slice'
import tasksReducer from './tasks-slice'
import generalAssembliesSlice from './general-assemblies-slice'
import assembliesSlice from './assemblies-slice'
import productsSlice from './products-slice'
import productAuthorizationSlice from './product-authorization-slice'
import projectCallOffSlice from './project-call-off-slice'

export const store = configureStore({
    reducer: {
        projectCallOffs: projectCallOffSlice,
        productAuthorization: productAuthorizationSlice,
        products: productsSlice,
        singleProjectView: singleProjectViewReducer,
        projectInfo: projectInfoReducer,
        serialLog: serialLogReducer,
        returnsAndReworks: returnsAndReworksReducer,
        projectHistory: projectHistoryReducer,
        projectDocuments: projectDocumentReducer,
        projectMaster: projectMasterSlice,
        callOff: callOffReducer,
        authentication: authenticationReducer,
        forgotPassword: forgotPasswordReducer,
        fetchCallOffs: fetchCallOffReducer,
        users: userReducer,
        assemblies: assembliesSlice,
        callOffView: callOffReducer,
        assemblyWorksheets: assemblyWorksheetSlice,
        logisticsWorksheets: logisticsWorksheetSlice,
        notifications: notificationsSlice,
        warehouseMap: warehouseMapSlice,
        tasks: tasksReducer,
        generalAssemblies: generalAssembliesSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
