import { Table, TableColumnType } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { Response } from '../../api/util/with-response-formatter-interceptor'
import { Delivery } from '../../models/models'
import { toastFailure } from '../../util/toast'
import useDebounce from '../project-master/single-project-view/hooks'
import GoodsDeliveredTable from './GoodsDeliveredTable'
import moment from 'moment'

interface Props {
    pagination?: boolean
    //antd ColumnType<T> does not have title property
    columns?: Array<TableColumnType<Delivery>>
    actionComponents?: Array<(orderConfirmationId: number) => JSX.Element>
    dependencies?: any
    expandable?: boolean
    request: (pagination?: {
        skip: number
        limit: number
    }) => Promise<Response<{ count: number; entities: Delivery[] }>>
}

export const DeliveriesTableColumns = {
    ReceivedDate: {
        title: 'Received Date',
        key: 'code',
        render: (delivery: Delivery) => {
            if (!delivery.receivedDate) {
                return 'Unknown'
            }
            return moment(delivery.receivedDate).format('DD/MM/YYYY')
        },
    },
    AmountDelivered: {
        title: 'Amount products delivered',
        key: 'code',
        render: (delivery: Delivery) => {
            return delivery?.goodsDelivered.reduce((a, c) => a + c.amountReceived, 0)
        },
    },
} as const

const DEFAULT_COLUMNS = [
    DeliveriesTableColumns.ReceivedDate,
    DeliveriesTableColumns.AmountDelivered,
]

export default function DeliveriesTable({
    request,
    columns,
    expandable,
    pagination,
    actionComponents,
    dependencies,
}: Props) {
    const [deliveries, setDeliveries] = useState<Delivery[]>([])
    const [deliveriesAmount, setDeliveriesAmount] = useState<number>(0)
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)

    const getDeliveriesDeps = useDebounce(JSON.stringify(dependencies), 200)

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const getDeliveries = async () => {
        const response = await request({
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        })
        if (response.successful) {
            const { count, entities } = response.data
            setPage(1)
            setDeliveriesAmount(count)
            setDeliveries(entities)
        } else {
            toastFailure(response.message)
        }
    }

    const tableColumns = useMemo(() => {
        let cols = columns ? Object.values(columns) : DEFAULT_COLUMNS
        if (actionComponents) {
            cols = [
                ...cols,
                {
                    title: 'Actions',
                    key: 'actions',
                    render: (delivery: Delivery) => {
                        return (<>{actionComponents.map((a) => a(delivery.id))}</>) as any
                    },
                },
            ]
        }
        return cols
    }, [columns])

    useEffect(() => {
        getDeliveries()
    }, [getDeliveriesDeps])

    const expandedRowRender = (ocDelivery: Delivery) => {
        return <GoodsDeliveredTable goodsDelivered={ocDelivery.goodsDelivered} />
    }

    return (
        <Table
            rowKey="id"
            columns={tableColumns}
            dataSource={deliveries}
            expandable={expandable ? { expandedRowRender } : undefined}
            size="small"
            pagination={
                pagination
                    ? {
                          defaultPageSize: 10,
                          current: page,
                          onChange: pageChange,
                          total: deliveriesAmount,
                      }
                    : false
            }
        />
    )
}
