import { useEffect, useMemo, useState } from 'react'
import moment, { Moment } from 'moment'
import { Warehouse, WarehouseId } from '../../models/models'
import ProductionCalendar from './ProductionCalendar'
import { ProductionScheduleView } from './single-date-view/ProductionScheduleView'
import { BackgroundColors } from '../../theme/colors'
import Dialog from '@mui/material/Dialog'
import { RootState } from '../../store/store'
import { useSelector } from 'react-redux'
import { getWarehouses } from '../../api/warehouse'
import { toastFailure } from '../../util/toast'

export default function ProductionSchedule() {
    const { user } = useSelector((state: RootState) => state.authentication)
    const [currentDate, setCurrentDate] = useState<Moment>(moment())
    const [isScheduleViewActive, setScheduleViewActive] = useState<boolean>(false)
    const [selectedDate, setSelectedDate] = useState<Moment | null>()
    const [selectedWarehouseId, setSelectedWarehouseId] = useState<WarehouseId>(
        (user?.warehouseId || WarehouseId.Monument) as WarehouseId
    )
    const [warehouses, setWarehouses] = useState<Warehouse[]>([])

    const selectedWarehouse = useMemo(() => {
        return warehouses.find((warehouse) => warehouse.id === selectedWarehouseId)
    }, [warehouses, selectedWarehouseId])

    useEffect(() => {
        getWarehouses().then((response) => {
            if (!response.successful) {
                toastFailure('Could not load warehouses')
                return
            }
            setWarehouses(response.data)
        })
    }, [])
    return (
        <>
            {selectedWarehouse && (
                <ProductionCalendar
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    setSelectedDate={(date) => {
                        setSelectedDate(date)
                        setScheduleViewActive(true)
                    }}
                    selectedWarehouse={selectedWarehouse}
                    selectedWarehouseId={selectedWarehouseId}
                    setSelectedWarehouseId={setSelectedWarehouseId}
                />
            )}
            {selectedDate && selectedWarehouse && (
                <Dialog
                    fullScreen
                    open={isScheduleViewActive}
                    PaperProps={{
                        style: {
                            backgroundColor: BackgroundColors.lighterGrey,
                        },
                    }}
                    sx={{ zIndex: '99' }}
                >
                    <ProductionScheduleView
                        selectedDate={selectedDate}
                        selectedWarehouse={selectedWarehouse}
                        setSelectedDate={(date) => {
                            if (date && currentDate.month() !== date.month()) {
                                setCurrentDate(date)
                            }
                            setSelectedDate(date)
                        }}
                        onClose={() => setScheduleViewActive(false)}
                    />
                </Dialog>
            )}
        </>
    )
}
