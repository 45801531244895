import { AssemblyField, FieldType } from '../models/models'
import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'

export enum AssemblyFieldRelations {
    CreatedBy = 'CreatedBy',
    AssignedTo = 'AssignedTo',
    Type = 'Type',
}

export interface GetAssemblyFieldsDto {
    relations?: AssemblyFieldRelations[]
    ids?: number[]
    excludeIds?: number[]
    id?: number
    name?: string
    withDeleted?: boolean
    skip?: number
    limit?: number
}

export const getAssemblyFieldByIdRequest = (
    id: number,
    dto: GetAssemblyFieldsDto
): Promise<Response<AssemblyField>> =>
    backendConnection.request({
        url: `/assembly-fields/${id}`,
        params: dto,
        method: 'GET',
    })

export const getAssemblyFieldsRequest = (
    dto: GetAssemblyFieldsDto
): Promise<Response<{ entities: AssemblyField[]; count: number }>> =>
    backendConnection.request({
        url: `/assembly-fields`,
        params: dto,
        method: 'GET',
    })

export interface UpdateAssemblyFieldDto {
    name?: string
    dropdownOptions?: string[]
    isRequired?: boolean
    isUnique?: boolean
    isSerial?: boolean
    isScannable?: boolean
    numericLowerLimit?: number | null
    numericUpperLimit?: number | null
}

export const updateAssemblyFieldRequest = (
    id: number,
    dto: UpdateAssemblyFieldDto
): Promise<Response<AssemblyField>> =>
    backendConnection.request({
        url: `/assembly-fields/${id}`,
        data: dto,
        method: 'PUT',
    })

export const deleteAssemblyFieldsRequest = (
    assemblyFieldId: number
): Promise<Response<AssemblyField>> =>
    backendConnection.request({
        url: `/assembly-fields/${assemblyFieldId}`,
        method: 'DELETE',
    })

export interface CreateAssemblyFieldDto {
    type: FieldType
    name: string
    isRequired: boolean
    isUnique: boolean
    isSerial: boolean
    dropdownOptions?: string[]
    isScannable?: boolean
    numericLowerLimit?: number
    numericUpperLimit?: number
}

export const createAssemblyFieldRequest = (
    assemblyField: CreateAssemblyFieldDto
): Promise<Response<AssemblyField>> =>
    backendConnection.request({
        url: `/assembly-fields`,
        method: 'POST',
        data: assemblyField,
    })
