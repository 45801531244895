import { Table, TableColumnType } from 'antd'
import { useMemo, useState } from 'react'
import { DeliveryAddress } from '../../api/customers'
import './table-styles.css'

interface Props {
    deliveryAddresses: DeliveryAddress[]
    columns?: Array<TableColumnType<DeliveryAddress>>
    actionComponents?: Array<(deliveryAddressId: number) => JSX.Element>
}

export const DeliveryAddressColumns = {
    Address1: {
        title: 'address1',
        key: 'address1',
        render: (deliveryAddress: DeliveryAddress) => {
            return deliveryAddress.address1
        },
    },
    Address2: {
        title: 'address2',
        key: 'address2',
        render: (deliveryAddress: DeliveryAddress) => {
            return deliveryAddress.address2
        },
    },
    Address3: {
        title: 'address3',
        key: 'address3',
        render: (deliveryAddress: DeliveryAddress) => {
            return deliveryAddress.address3
        },
    },
    Address4: {
        title: 'address4',
        key: 'address4',
        render: (deliveryAddress: DeliveryAddress) => {
            return deliveryAddress.address4
        },
    },
    City: {
        title: 'City',
        key: 'city',
        render: (deliveryAddress: DeliveryAddress) => {
            return deliveryAddress.city
        },
    },
    Description: {
        title: 'description',
        key: 'description',
        render: (deliveryAddress: DeliveryAddress) => {
            return deliveryAddress.description
        },
    },
    Email: {
        title: 'email',
        key: 'email',
        render: (deliveryAddress: DeliveryAddress) => {
            return deliveryAddress.email
        },
    },
    County: {
        title: 'county',
        key: 'county',
        render: (deliveryAddress: DeliveryAddress) => {
            return deliveryAddress.county
        },
    },
    Postcode: {
        title: 'postcode',
        key: 'postcode',
        render: (deliveryAddress: DeliveryAddress) => {
            return deliveryAddress.postcode
        },
    },
    Country: {
        title: 'country',
        key: 'country',
        render: (deliveryAddress: DeliveryAddress) => {
            return deliveryAddress.country
        },
    },
} as const

const DEFAULT_COLUMNS = [
    DeliveryAddressColumns.Address1,
    DeliveryAddressColumns.City,
    DeliveryAddressColumns.Postcode,
    DeliveryAddressColumns.Description,
]
const ROWS_PER_PAGE = 10

export default function DeliveryAddressTable({
    deliveryAddresses,
    actionComponents,
    columns,
}: Props) {
    const [page, setPage] = useState<number>(1)

    const pageChange = (page: number) => {
        setPage(page)
    }

    const _columns = useMemo(() => {
        const cols = [...(columns ?? DEFAULT_COLUMNS)]
        if (actionComponents) {
            cols.push({
                title: 'Actions',
                key: 'actions',
                render: (callOff: DeliveryAddress) => {
                    return <>{actionComponents.map((fComponent) => fComponent(callOff.id))}</>
                },
            })
        }
        return cols
    }, [columns, deliveryAddresses])

    return (
        <Table
            rowKey="id"
            columns={_columns}
            dataSource={deliveryAddresses}
            className="no-hover"
            size="small"
            pagination={{
                defaultPageSize: ROWS_PER_PAGE,
                current: page,
                onChange: pageChange,
                total: deliveryAddresses.length,
            }}
        />
    )
}
