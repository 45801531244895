import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { Button, Table } from 'antd'
import { useEffect, useState } from 'react'
import {
    getSalesOrdersRequest,
    GetManySalesOrderRelations,
    updateSalesOrderNoteRequest,
} from '../../../../api/sales-orders'
import { Project, SalesOrder, WarehouseId, Bom } from '../../../../models/models'
import { useAppSelector } from '../../../../store/hooks'
import { toastFailure, toastSuccess } from '../../../../util/toast'
import moment from 'moment'
import CreateCallOff from '../create/modals/CreateCallOff'
import SalesOrderOrderlineTable from '../SalesOrderOrderlineTable'
import InspectSalesOrderModal from '../../../modals/inspect-sales-order-modal/InspectSalesOrderModal'
import { deleteSopOrderlineRequest, SopOrderlineType } from '../../../../api/sop-orderlines'
import { InspectBomModal } from '../../../products/modals/InspectBomModal'
import { SalesOrdersTable, SalesOrdersTableColumns } from '../../../tables/SalesOrdersTable'
import { TableTextareaInput } from '../../../worksheets/assembly/active-assembly/TableInputs'

const Container = styled(Box)({})

enum ActiveModal {
    None = 'None',
    CreateCallOff = 'CreateCallOff',
    InspectBuiltItem = 'InspectBuiltItem',
    InspectSalesOrder = 'InspectSalesOrder',
}

export default function ViewSalesOrders() {
    const [salesOrders, setSalesOrders] = useState<SalesOrder[]>([])
    const [activeModal, setActiveModal] = useState<ActiveModal>(ActiveModal.None)
    const [modalSalesOrder, setModalSalesOrder] = useState<SalesOrder>()
    const [modalBuiltItem, setModalBuiltItem] = useState<Bom>()
    const project: Project | null = useAppSelector(
        (state) => state.singleProjectView.projectBeingUpdated
    )
    const [updateTableToken, setUpdateTableToken] = useState<string>('')

    const deleteSopOrderline = async (sopOrderlineId: number, type: SopOrderlineType) => {
        const response = await deleteSopOrderlineRequest(sopOrderlineId, type)
        if (response.successful) {
            getSalesOrders()
            toastSuccess('Deleted the orderline')
        } else {
            toastFailure(response.message)
        }
    }

    const expandedRowRender = (salesOrder: SalesOrder) => {
        return (
            <SalesOrderOrderlineTable
                salesOrder={salesOrder}
                actionComponents={[
                    (sopOrderlineId: number, type: SopOrderlineType) => (
                        <Button onClick={() => deleteSopOrderline(sopOrderlineId, type)}>
                            Delete
                        </Button>
                    ),
                ]}
            />
        )
    }

    if (!project) {
        throw new Error('Something went wrong')
    }

    const getSalesOrders = async () => {
        const response = await getSalesOrdersRequest({
            relations: [
                GetManySalesOrderRelations.Projects,
                GetManySalesOrderRelations.Orderlines,
                GetManySalesOrderRelations.CallOffs,
            ],
            withReservedOrderlineAmounts: true,
            projectId: project.id,
            withCount: false,
        })
        if (response.successful) {
            setSalesOrders(response.data.entities)
        } else {
            toastFailure('Could not get sales orders')
        }
    }

    useEffect(() => {
        if (activeModal === ActiveModal.None) {
            getSalesOrders()
        }
    }, [activeModal])

    /*
                        {
                        title: 'Note',
                        render: (unauthorizedBom: UnauthorizedBom) => {
                            return (
                                <TableTextareaInput
                                    active
                                    placeholder="No note"
                                    value={unauthorizedBom.note}
                                    maxLength={60}
                                    maxLengthError={`The note must not be longer than 60 characters long.`}
                                    noNewlines
                                    onSave={async (value, afterSave) => {
                                        if (value !== null) {
                                            await updateUnauthorizedBomNoteRequest(
                                                unauthorizedBom.id,
                                                value
                                            )
                                            dispatch(
                                                setUpdateTableToken(
                                                    `deny-${unauthorizedBom.id}${unauthorizedBom.note}`
                                                )
                                            )
                                        }
                                        afterSave()
                                    }}
                                />
                            )
                        },
                    },
                    */
    return (
        <Container>
            <SalesOrdersTable
                pagination={true}
                dependencies={updateTableToken}
                columns={[
                    SalesOrdersTableColumns.Id,
                    SalesOrdersTableColumns.DocumentNo,
                    SalesOrdersTableColumns.Warehouse,
                    SalesOrdersTableColumns.PromisedDeliveryDate,
                    SalesOrdersTableColumns.OrderlinesAmount,
                    SalesOrdersTableColumns.Status,
                    {
                        title: 'Note',
                        render: (salesOrder: SalesOrder) => {
                            return (
                                <TableTextareaInput
                                    active
                                    placeholder="No note"
                                    value={salesOrder.note}
                                    maxLength={60}
                                    maxLengthError={`The note must not be longer than 60 characters long.`}
                                    noNewlines
                                    onSave={async (value, afterSave) => {
                                        if (value !== null) {
                                            await updateSalesOrderNoteRequest(salesOrder.id, value)

                                            setUpdateTableToken(
                                                `upate-note-${salesOrder.id}${salesOrder.note}`
                                            )
                                        }
                                        afterSave()
                                    }}
                                />
                            )
                        },
                    },
                ]}
                request={(pagination) => {
                    return getSalesOrdersRequest({
                        relations: [
                            GetManySalesOrderRelations.Projects,
                            GetManySalesOrderRelations.Orderlines,
                            GetManySalesOrderRelations.CallOffs,
                        ],
                        limit: pagination?.limit,
                        skip: pagination?.skip,
                        withReservedOrderlineAmounts: true,
                        projectId: project.id,
                        withCount: true,
                    })
                }}
                actionComponents={[
                    (salesOrder: SalesOrder) => {
                        return (
                            <>
                                <Button
                                    onClick={() => {
                                        setModalSalesOrder(salesOrder)
                                        setActiveModal(ActiveModal.InspectSalesOrder)
                                    }}
                                >
                                    Inspect
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setModalSalesOrder(salesOrder)
                                        setActiveModal(ActiveModal.CreateCallOff)
                                    }}
                                >
                                    Create Call Off
                                </Button>
                            </>
                        )
                    },
                ]}
                expandable={{ expandedRowRender }}
            />
            {activeModal === ActiveModal.CreateCallOff && modalSalesOrder && (
                <CreateCallOff
                    onClose={() => {
                        setActiveModal(ActiveModal.None)
                        setModalSalesOrder(undefined)
                    }}
                    salesOrder={modalSalesOrder}
                />
            )}

            <InspectBomModal
                bomId={modalBuiltItem?.id}
                isVisible={
                    activeModal === ActiveModal.InspectBuiltItem && modalBuiltItem !== undefined
                }
                onClose={() => {
                    setActiveModal(ActiveModal.None)
                    setModalBuiltItem(undefined)
                }}
            />

            {activeModal === ActiveModal.InspectSalesOrder && modalSalesOrder && (
                <InspectSalesOrderModal
                    salesOrderId={modalSalesOrder.id}
                    isOpen={true}
                    closeModal={() => {
                        setActiveModal(ActiveModal.None)
                        setModalBuiltItem(undefined)
                    }}
                />
            )}
        </Container>
    )
}
