import { useEffect, useState } from 'react'
import { getCustomersRequest } from '../../../api/customers'
import { Customer } from '../../../models/models'

export const useCustomers = (skip: number, limit: number, search: string): [Customer[], number] => {
    const [customers, setCustomers] = useState<Customer[]>([])
    const [customersCount, setCustomersCount] = useState<number>(0)

    useEffect(() => {
        const fetchCustomers = async () => {
            const response = await getCustomersRequest({
                skip,
                limit,
                like: search,
                //onHold: false,
                withCount: true,
            })

            if (response.successful) {
                setCustomers(response.data.customers)
                setCustomersCount(response.data.count || 0)
            }
        }
        fetchCustomers()
    }, [skip, limit, search])

    return [customers, customersCount]
}

/**
 * Put every value which should be debounced as the value, and provide it to the useEffect dependency to debounce
 * @param value
 * @param delay
 * @returns
 */
export default function useDebounce(value: any, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)
        return () => {
            clearTimeout(handler)
        }
    }, [value])

    return debouncedValue
}
