import { styled } from '@mui/styles'
import { useAppDispatch } from '../../store/hooks'
import { RootState } from '../../store/store'
import { useSelector } from 'react-redux'
import { FieldContainer } from '../ui/FIeldContainer'
import { Label } from '../ui/Label'
import { Button, Input } from 'antd'
import { ChangeEvent } from 'react'
import {
    ActiveModal,
    BomGroupFilters,
    openModal,
    setBomGroupFilters,
} from '../../store/products-slice'

const Container = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}))

const InputsContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0px 0px 10px 0px',
    gap: '5px',
}))

const ActionsContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0px 0px 10px 0px',
    gap: '5px',
}))

export const BomGroupsTopBar = () => {
    const { bomGroupFilters } = useSelector((state: RootState) => state.products)
    const dispatch = useAppDispatch()

    const mutateBomGroupsFilters = (
        key: keyof BomGroupFilters,
        value: string | number | undefined
    ) => {
        dispatch(setBomGroupFilters({ ...bomGroupFilters, [key]: value }))
    }

    return (
        <Container>
            <InputsContainer>
                <FieldContainer>
                    <Label>Id</Label>
                    <Input
                        style={{ width: '150px' }}
                        value={bomGroupFilters.bomGroupId}
                        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                            if (
                                isNaN(parseInt(event.target.value)) ||
                                parseInt(event.target.value) === 0
                            ) {
                                mutateBomGroupsFilters('bomGroupId', undefined)
                            } else {
                                mutateBomGroupsFilters('bomGroupId', Number(event.target.value))
                            }
                        }}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Label>Name</Label>
                    <Input
                        style={{ width: '200px' }}
                        value={bomGroupFilters.name}
                        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                            mutateBomGroupsFilters('name', event.target.value)
                        }}
                    />
                </FieldContainer>
            </InputsContainer>
            <ActionsContainer>
                <Button
                    type="primary"
                    onClick={() =>
                        dispatch(
                            openModal({
                                activeModal: ActiveModal.BomGroupCreate,
                            })
                        )
                    }
                >
                    Create Bom Group
                </Button>
            </ActionsContainer>
        </Container>
    )
}
