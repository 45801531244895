import { Table, TableColumnType } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { Response } from '../../api/util/with-response-formatter-interceptor'
import { AssemblyField } from '../../models/models'
import { toastFailure } from '../../util/toast'
import useDebounce from '../project-master/single-project-view/hooks'

interface Props {
    pagination?: boolean
    //antd ColumnType<T> does not have title property
    columns?: Array<TableColumnType<AssemblyField>>
    actionComponents?: Array<(assemblyField: AssemblyField) => JSX.Element>
    dependencies?: any
    request: (pagination?: {
        skip: number
        limit: number
    }) => Promise<Response<{ count: number; entities: AssemblyField[] }>>
}

export const AssemblyFieldsTableColumns: { [key in string]: TableColumnType<AssemblyField> } = {
    Id: {
        title: 'Id',
        key: 'id',
        render: (assemblyField: AssemblyField) => {
            return assemblyField.id
        },
    },
    Type: {
        title: 'Type',
        key: 'type',
        render: (assemblyField: AssemblyField) => {
            return assemblyField?.type
        },
    },
    Name: {
        title: 'name',
        key: 'Name',
        width: '350px',
        render: (assemblyField: AssemblyField) => {
            return assemblyField.name
        },
    },
    Required: {
        title: 'Required',
        key: 'requried',
        render: (assemblyField: AssemblyField) => {
            return assemblyField?.isRequired.toString()
        },
    },
    Scannable: {
        title: 'Scannable',
        key: 'scannable',
        render: (assemblyField: AssemblyField) => {
            return assemblyField?.isScannable.toString()
        },
    },
    Serial: {
        title: 'Serial',
        key: 'serial',
        render: (assemblyField: AssemblyField) => {
            return assemblyField?.isSerial.toString()
        },
    },
    DropdownOptions: {
        title: 'Dropdown Options',
        key: 'dropdownOptions',
        render: (assemblyField: AssemblyField) => {
            return assemblyField?.dropdownOptions
        },
    },
    NumericUpperLimit: {
        title: 'Numeric Upper Limit',
        key: 'numericUpperLimit',
        render: (assemblyField: AssemblyField) => {
            return assemblyField?.numericUpperLimit
        },
    },
    NumericLowerLimit: {
        title: 'Numeric Lower Limit',
        key: 'numericLowerLimit',
        render: (assemblyField: AssemblyField) => {
            return assemblyField?.numericLowerLimit
        },
    },
} as const

const DEFAULT_COLUMNS = [
    AssemblyFieldsTableColumns.Id,
    AssemblyFieldsTableColumns.Name,
    AssemblyFieldsTableColumns.Type,
]

export default function AssemblyFieldsTable({
    request,
    columns,
    pagination,
    actionComponents,
    dependencies,
}: Props) {
    const [assemblyFields, setAssemblyFields] = useState<AssemblyField[]>([])
    const [assemblyFieldsAmount, setAssemblyFieldsAmount] = useState<number>(0)
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)

    const getAssemblyFieldsDeps = useDebounce(JSON.stringify(dependencies), 200)

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const tableColumns = useMemo(() => {
        let cols = columns ? Object.values(columns) : DEFAULT_COLUMNS
        if (actionComponents) {
            cols = [
                ...cols,
                {
                    title: 'Actions',
                    key: 'actions',
                    render: (assemblyField: AssemblyField) => {
                        return (<>{actionComponents.map((a) => a(assemblyField))}</>) as any
                    },
                },
            ]
        }
        return cols
    }, [columns])

    const getAssemblyFields = async (resetPage = false) => {
        const response = await request({
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        })

        if (response.successful) {
            const { count, entities } = response.data
            resetPage && setPage(1)
            setAssemblyFieldsAmount(count)
            setAssemblyFields(entities)
        } else {
            toastFailure(response.message)
        }
    }

    // this happens when dependencies change outside the table, e.g filters given in with getAssemblyFieldsDeps
    useEffect(() => {
        getAssemblyFields(true)
    }, [getAssemblyFieldsDeps, rowsPerPage])

    // this happens when dependencies change outside the table, e.g filters given in with getAssemblyFieldsDeps
    useEffect(() => {
        getAssemblyFields()
    }, [page])

    return (
        <Table
            rowKey="id"
            columns={tableColumns}
            dataSource={assemblyFields}
            expandable={undefined}
            size="small"
            pagination={
                pagination
                    ? {
                          defaultPageSize: 10,
                          current: page,
                          onChange: pageChange,
                          total: assemblyFieldsAmount,
                      }
                    : false
            }
        />
    )
}
