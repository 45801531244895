import { styled } from '@mui/styles'
import { useAppDispatch } from '../../store/hooks'
import { RootState } from '../../store/store'
import { useSelector } from 'react-redux'
import { FieldContainer } from '../ui/FIeldContainer'
import { Label } from '../ui/Label'
import { Button, Input } from 'antd'
import { ChangeEvent } from 'react'
import { ActiveModal, BomFilters, openModal, setBomFilters } from '../../store/products-slice'

const Container = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}))

const InputsContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0px 0px 10px 0px',
    gap: '5px',
}))

const ActionsContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0px 0px 10px 0px',
    gap: '5px',
}))

export const BomsTopBar = () => {
    const { bomFilters } = useSelector((state: RootState) => state.products)
    const dispatch = useAppDispatch()

    const mutateBomFilters = (key: keyof BomFilters, value: string | number | undefined) => {
        dispatch(setBomFilters({ ...bomFilters, [key]: value }))
    }

    return (
        <Container>
            <InputsContainer>
                <FieldContainer>
                    <Label>Id</Label>
                    <Input
                        style={{ width: '150px' }}
                        value={bomFilters.bomId}
                        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                            if (
                                isNaN(parseInt(event.target.value)) ||
                                parseInt(event.target.value) === 0
                            ) {
                                mutateBomFilters('bomId', undefined)
                            } else {
                                mutateBomFilters('bomId', Number(event.target.value))
                            }
                        }}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Label>Code</Label>
                    <Input
                        style={{ width: '200px' }}
                        value={bomFilters.code}
                        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                            mutateBomFilters('code', event.target.value)
                        }}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Label>Product Name</Label>
                    <Input
                        style={{ width: '200px' }}
                        value={bomFilters.productName}
                        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                            mutateBomFilters('productName', event.target.value)
                        }}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Label>Exclude Code</Label>
                    <Input
                        style={{ width: '200px' }}
                        value={bomFilters.excludeCode}
                        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                            mutateBomFilters('excludeCode', event.target.value)
                        }}
                    />
                </FieldContainer>
            </InputsContainer>
            <ActionsContainer>
                <Button
                    type="primary"
                    onClick={() =>
                        dispatch(
                            openModal({
                                activeModal: ActiveModal.BomUpdateMany,
                            })
                        )
                    }
                >
                    Update Boms
                </Button>
                <Button
                    type="primary"
                    onClick={() =>
                        dispatch(
                            openModal({
                                activeModal: ActiveModal.BomCreate,
                            })
                        )
                    }
                >
                    Create Bom
                </Button>
            </ActionsContainer>
        </Container>
    )
}
