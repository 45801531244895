import { toast, ToastContent, ToastOptions } from 'react-toastify'

const defaultArguments: ToastOptions = {
    position: 'top-right',
    autoClose: 5000,
    theme: 'dark',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const toastInfo = (content: ToastContent): void => {
    toast.info(content, defaultArguments)
}

export const toastWarn = (content: ToastContent): void => {
    toast.warn(content, defaultArguments)
}

export const toastSuccess = (content: ToastContent): void => {
    toast.success(content, defaultArguments)
}

export const toastFailure = (content: ToastContent): void => {
    toast.error(content, defaultArguments)
}
