import { FilterOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import { Box } from '@mui/material'
import { useState } from 'react'

interface Option {
    id: number
    label: string
}

interface Props {
    options: Option[]
    selected: number[]
    onChange: (selected: number[]) => void
}

function Dropdown(props: Props) {
    return (
        <Box
            sx={{
                width: '200px',
                position: 'absolute',
                top: 25,
                left: -10,
                background: '#fff',
                border: '1px solid rgba(224, 224, 224, 1)',
                padding: '5px',
                borderRadius: '5px',

                '& .ant-select-selection-item': {
                    display: 'none',
                },
            }}
        >
            <Select
                mode={'multiple'}
                placeholder={'Search'}
                value={props.selected}
                showSearch
                allowClear
                open
                filterOption={(input, option) =>
                    !!option?.label &&
                    option.label.toString().toLowerCase().includes(input.toLowerCase())
                }
                onChange={props.onChange}
                style={{ width: '100%', display: 'block' }}
                options={props.options.map((option) => ({
                    value: option.id,
                    label: option.label,
                }))}
            />
        </Box>
    )
}

function HoverFilter(props: Props) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Box
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            sx={{ position: 'relative' }}
        >
            <FilterOutlined style={{ fontSize: 14 }} />
            {isOpen && <Dropdown {...props} />}
        </Box>
    )
}

export default HoverFilter
