import { useMemo, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Input, Modal, Button, Table, InputNumber, Select, Radio, Alert } from 'antd'
import { styled } from '@mui/material'
import '../../tables/table-styles.css'
import { OrderedListOutlined, DeleteOutlined } from '@ant-design/icons'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../store/hooks'
import { BomGroup, Product, Project, WarehouseId } from '../../../models/models'
import { SearchInput } from '../../ui/SearchInput'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DraggableBodyRow } from '../../project-master/sales-orders/create/OrderlinesTable'
import {
    GetStockItemQueryParams,
    ProductGroup,
    getByCode,
    getProductGroupByIdRequest,
    getProductGroupsRequest,
    getStockItemByIdRequest,
    getStockItemsRequest,
} from '../../../api/stock-item'
import { createUnauthorizedBomWithProductRequest } from '../../../api/unauthorized-product'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { getBomGroupByIdRequest, getBomGroupsRequest } from '../../../api/bom-groups'
import { FileArea } from '../../ui/FileArea'
import { convertCsvToJson } from '../../../util/util'
import { ActiveModal, closeModal } from '../../../store/product-authorization-slice'
import Loading from '../../project-master/sales-orders/create/modals/createCustomerModal/Loading'
import { getProjectByIdRequest, getProjectsRequest } from '../../../api/projects/projects'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 50%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

interface ProductWithAmount extends Product {
    amount: number
}

enum ComponentSearchOptions {
    Code = 'Code',
    Name = 'Name',
}
const componentSearchOptions: Array<{ label: string; value: ComponentSearchOptions }> = [
    { label: 'Code', value: ComponentSearchOptions.Code },
    { label: 'Name', value: ComponentSearchOptions.Name },
]

enum ModalStep {
    Import = 'Import',
    Edit = 'Edit',
}

interface BomCsvRow {
    componentCode: string
    quantity: number
    bomCode: string
}

export const ImportInternalBomModal = () => {
    const { activeModal } = useSelector((state: RootState) => state.productAuthorization)
    const [bomGroup, setBomGroup] = useState<BomGroup>()
    const [code, setCode] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [selectedProject, setSelectedProject] = useState<Project>()
    const [note, setNote] = useState<string>('')

    const [warehouseId, setWarehouseId] = useState<WarehouseId>(WarehouseId.Monument)
    const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.Import)
    const [selectedProductGroup, setSelectedProductGroup] = useState<ProductGroup>()
    const [componentSearchOption, setComponentSearchOption] = useState<ComponentSearchOptions>(
        ComponentSearchOptions.Code
    )
    const [assemblyMinutes, setAssemblyMinutes] = useState<number>()
    const [instructionLink, setInstructionLink] = useState<string>()
    const [components, setComponents] = useState<ProductWithAmount[]>([])
    const dispatch = useAppDispatch()

    const onImport = async (file: File) => {
        const rows = await convertCsvToJson<BomCsvRow>(file)
        if (rows.length === 0) {
            toastFailure('No valid rows in the csv file')
            return
        }

        const bomCode = rows[0].bomCode

        if (!bomCode) {
            toastFailure('No bom code found')
            throw new Error('No bom code found')
        }

        const getComponentPromises: Array<Promise<ProductWithAmount>> = []

        for (const row of rows) {
            if (!row.componentCode || !row.quantity) {
                continue
            }

            const getComponentPromise = getByCode(encodeURIComponent(row.componentCode)).then(
                (response) => {
                    if (!response.successful || !response.data) {
                        toastFailure(`Component with code ${row.componentCode} not found`)
                        throw new Error('Component not found')
                    }

                    return { ...response.data, amount: row.quantity }
                }
            )

            getComponentPromises.push(getComponentPromise)
        }

        if (getComponentPromises.length === 0) {
            toastFailure('No components found')
            throw new Error('No components found')
        }
        try {
            const components = await Promise.all(getComponentPromises)

            setModalStep(ModalStep.Edit)
            setComponents(components)
            setCode(bomCode)
        } catch {}
    }

    const resetState = () => {
        setCode('')
        setDescription('')
        setName('')
        setNote('')
        setSelectedProject(undefined)
        setComponents([])
        setAssemblyMinutes(undefined)
        setInstructionLink(undefined)
        setSelectedProductGroup(undefined)
        setComponentSearchOption(ComponentSearchOptions.Code)
    }

    const onClose = () => {
        resetState()
        dispatch(closeModal())
    }

    const onMove = (fromIndex: number, toIndex: number) => {
        const temp = [...components]
        const element = components[fromIndex]
        temp.splice(fromIndex, 1)
        temp.splice(toIndex, 0, element)
        setComponents(temp)
    }

    const isSubmitDisabled = useMemo(() => {
        return (
            !code ||
            !name ||
            !selectedProductGroup ||
            (components.length > 0 && components.some((c) => c.amount <= 0))
        )
    }, [code, name, components, selectedProductGroup])

    const onOk = async () => {
        if (!selectedProductGroup || !bomGroup) {
            return
        }
        createUnauthorizedBomWithProductRequest({
            code,
            description,
            bomGroupId: bomGroup.id,
            name,
            requestedForProjectId: selectedProject?.id,
            note,
            assemblyMinutes,
            instructionLink,
            components: components.map((c) => ({ id: c.id, amount: Number(c.amount) })),
            productGroupId: selectedProductGroup.id,
            warehouseId,
        }).then((response) => {
            if (!response.successful) {
                toastFailure(response.message)
                return
            }
            if (response.successful) {
                toastSuccess(`Made Bom ${response.data.bomCode} eligible for authorization`)
                dispatch(closeModal(JSON.stringify(response.data)))
                resetState()
            }
        })
    }

    return (
        <Modal
            open={activeModal === ActiveModal.ImportInternalBom}
            footer={null}
            onCancel={onClose}
            width={900}
        >
            <Box>
                <Header>
                    <OrderedListOutlined style={{ fontSize: '20px' }} />
                    <Title>Import Bom</Title>
                </Header>
                <Divider />
                <Alert
                    style={{ marginBottom: '20px' }}
                    message={`
                    In this modal you can import Boms created in internal CTR systems. Doing so will create a unauthorized bom.`}
                    type="info"
                    showIcon
                />

                {isLoading && (
                    <div>
                        <Loading text={'Creating the product in Sage, might take a while'} />
                    </div>
                )}
                {modalStep === ModalStep.Import && !isLoading && (
                    <>
                        <FileArea
                            onDrop={onImport}
                            accepts={['application/csv', 'text/csv', '.csv']}
                        />
                    </>
                )}

                {modalStep === ModalStep.Edit && !isLoading && (
                    <>
                        <InputRow style={{ marginBottom: '20px' }}>
                            <RowElement>
                                <Label>Code</Label>
                                <Input
                                    style={{ width: '100%' }}
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </RowElement>
                            <RowElement>
                                <Label>Name</Label>
                                <Input
                                    style={{ width: '100%' }}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </RowElement>
                        </InputRow>

                        <InputRow style={{ marginBottom: '20px' }}>
                            <RowElement>
                                <Label>Assembly Minutes</Label>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0.1}
                                    value={assemblyMinutes}
                                    status={!assemblyMinutes || assemblyMinutes > 0 ? '' : 'error'}
                                    onChange={(value: number | null) => {
                                        if (!value) {
                                            return
                                        }
                                        setAssemblyMinutes(value)
                                    }}
                                />
                            </RowElement>
                            <RowElement>
                                <Label>Instructions</Label>
                                <Input
                                    style={{ width: '100%' }}
                                    value={instructionLink}
                                    onChange={(e) => setInstructionLink(e.target.value)}
                                />
                            </RowElement>
                        </InputRow>

                        <InputRow style={{ marginBottom: '20px' }}>
                            <RowElement>
                                <Label>Product Group</Label>
                                <SearchInput
                                    style={{ width: '100%' }}
                                    placeholder="Product Groups"
                                    selectedValue={selectedProductGroup?.id}
                                    excludeValues={components.map((af) => af.id)}
                                    setSelectedValue={(id) => {
                                        if (!id) {
                                            return
                                        }
                                        getProductGroupByIdRequest(id).then((r) => {
                                            if (r.successful) {
                                                setSelectedProductGroup(r.data)
                                            }
                                        })
                                    }}
                                    request={(search: string) =>
                                        getProductGroupsRequest(search).then((r) =>
                                            r.successful
                                                ? r.data.map((productGroup) => ({
                                                      label: productGroup.code,
                                                      value: productGroup.id,
                                                  }))
                                                : [{ label: 'None', value: 2 }]
                                        )
                                    }
                                />
                            </RowElement>
                            <RowElement>
                                <Label>Warehouse</Label>
                                <Select
                                    style={{ width: '100%' }}
                                    value={warehouseId}
                                    onChange={(value: WarehouseId) => setWarehouseId(value)}
                                    options={[
                                        { value: WarehouseId.Monument, label: 'Monument' },
                                        { value: WarehouseId.Scandia, label: 'Scandia' },
                                        { value: null, label: 'None' },
                                    ]}
                                />
                            </RowElement>
                        </InputRow>

                        <InputRow style={{ marginBottom: '20px' }}>
                            <RowElement>
                                <Label>Description</Label>
                                <Input.TextArea
                                    style={{ width: '100%', minHeight: 120 }}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </RowElement>
                            <RowElement>
                                <Label>Bom Group</Label>
                                <SearchInput
                                    style={{ width: '100%' }}
                                    placeholder="Bom Group"
                                    selectedValue={bomGroup?.id}
                                    setSelectedValue={(id) => {
                                        if (!id) {
                                            return
                                        }
                                        getBomGroupByIdRequest(id, {}).then((r) => {
                                            if (r.successful) {
                                                setBomGroup(r.data)
                                            }
                                        })
                                    }}
                                    request={(search: string) =>
                                        getBomGroupsRequest({ name: search }).then((r) =>
                                            r.successful
                                                ? r.data.entities.map((bomGroup) => ({
                                                      label: bomGroup.name,
                                                      value: bomGroup.id,
                                                  }))
                                                : [{ label: 'None', value: 2 }]
                                        )
                                    }
                                />
                            </RowElement>
                        </InputRow>
                        <InputRow style={{ marginBottom: '20px' }}>
                            <RowElement>
                                <Label>Note</Label>
                                <Input.TextArea
                                    style={{ width: '100%', minHeight: 120 }}
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </RowElement>
                            <RowElement>
                                <Label>Project</Label>
                                <SearchInput
                                    style={{ width: '100%' }}
                                    placeholder="Project"
                                    selectedValue={selectedProject?.id}
                                    setSelectedValue={(id) => {
                                        if (!id) {
                                            return
                                        }
                                        getProjectByIdRequest(id).then((r) => {
                                            if (r.successful) {
                                                setSelectedProject(r.data)
                                            }
                                        })
                                    }}
                                    request={(search: string) =>
                                        getProjectsRequest({ like: search }).then((r) =>
                                            r.successful
                                                ? r.data.projects.map((project) => ({
                                                      label: project.name,
                                                      value: project.id,
                                                  }))
                                                : [{ label: 'None', value: 2 }]
                                        )
                                    }
                                />
                            </RowElement>
                        </InputRow>
                        <InputRow style={{ marginBottom: '20px', gap: '' }}>
                            <RowElement>
                                <Label>Components</Label>
                                <SearchInput
                                    style={{ width: '100%' }}
                                    placeholder="Components"
                                    selectedValue={undefined}
                                    excludeValues={components.map((af) => af.id)}
                                    setSelectedValue={(id) => {
                                        if (!id) {
                                            return
                                        }
                                        getStockItemByIdRequest(id).then((r) => {
                                            if (r.successful) {
                                                setComponents([
                                                    ...components,
                                                    { ...r.data, amount: 1 },
                                                ])
                                            }
                                        })
                                    }}
                                    request={(search: string) => {
                                        const requestConfig: GetStockItemQueryParams = {
                                            excludeIds: components.map((af) => af.id),
                                            limit: 100,
                                        }
                                        if (componentSearchOption === ComponentSearchOptions.Code) {
                                            requestConfig.likeCode = search
                                        }
                                        if (componentSearchOption === ComponentSearchOptions.Name) {
                                            requestConfig.likeName = search
                                        }

                                        return getStockItemsRequest(requestConfig).then(
                                            (r): Array<{ label: string; value: number }> => {
                                                if (!r.successful) {
                                                    return [{ label: 'None', value: 2 }]
                                                }

                                                return r.data.entities.map((component) => {
                                                    if (
                                                        componentSearchOption ===
                                                        ComponentSearchOptions.Code
                                                    ) {
                                                        return {
                                                            label: component.code,
                                                            value: component.id,
                                                        }
                                                    } else {
                                                        return {
                                                            label: component.name,
                                                            value: component.id,
                                                        }
                                                    }
                                                })
                                            }
                                        )
                                    }}
                                />
                            </RowElement>
                            <RowElement style={{ marginTop: 'auto' }}>
                                <Radio.Group
                                    options={componentSearchOptions}
                                    onChange={(e) => setComponentSearchOption(e.target.value)}
                                    value={componentSearchOption}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </RowElement>
                        </InputRow>
                        <DndProvider backend={HTML5Backend}>
                            <Table
                                dataSource={components}
                                components={{
                                    body: {
                                        row: DraggableBodyRow,
                                    },
                                }}
                                onRow={(_, index) => {
                                    const attr = {
                                        index,
                                        onMove,
                                    }
                                    return attr as React.HTMLAttributes<any>
                                }}
                                columns={[
                                    {
                                        title: 'Code',
                                        dataIndex: 'code',
                                        key: 'code',
                                    },
                                    {
                                        title: 'Name',
                                        dataIndex: 'name',
                                        key: 'name',
                                    },
                                    {
                                        title: 'Amount',

                                        key: 'amount',
                                        render: (product: ProductWithAmount) => {
                                            const component = components.find(
                                                (c) => c.id === product.id
                                            )
                                            if (!component) {
                                                return
                                            }
                                            return (
                                                <InputNumber
                                                    min={0.01}
                                                    value={component.amount}
                                                    status={component.amount > 0 ? '' : 'error'}
                                                    onChange={(value) => {
                                                        if (!value) {
                                                            return
                                                        }
                                                        setComponents(
                                                            components.map((c) =>
                                                                c.id === product.id
                                                                    ? { ...c, amount: value }
                                                                    : c
                                                            )
                                                        )
                                                    }}
                                                />
                                            )
                                        },
                                    },
                                    {
                                        title: 'Delete',
                                        dataIndex: 'id',
                                        key: 'id',
                                        render: (product: ProductWithAmount) => (
                                            <Button
                                                shape="circle"
                                                onClick={() => {
                                                    setComponents(
                                                        components.filter(
                                                            (component) =>
                                                                component.id !== product.id
                                                        )
                                                    )
                                                }}
                                                icon={<DeleteOutlined />}
                                            />
                                        ),
                                    },
                                ]}
                                pagination={false}
                            />
                        </DndProvider>
                    </>
                )}
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                    <Button type={'primary'} disabled={isSubmitDisabled} onClick={onOk}>
                        Create
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}
