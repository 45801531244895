import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { toastFailure } from '../util/toast'
import { Assembly, AssemblyLine, Bom, Project } from '../models/models'
import { returnedAndReworkedLinesRequest } from '../api/assembly-line'

export interface ReturnedOrReworkedLine extends AssemblyLine {
    builtItem: Bom
    worksheet: Assembly
}

interface State {
    items: ReturnedOrReworkedLine[]
}

const initialState: State = {
    items: [],
}

export const returnsAndReworksSlice = createSlice({
    name: 'returnsAndReworks',
    initialState,
    reducers: {
        setItems(state, action: PayloadAction<ReturnedOrReworkedLine[]>) {
            state.items = action.payload
        },
    },
})

export const { setItems } = returnsAndReworksSlice.actions

export const fetchReturnedAndReworkedItems = (project: Project) => async (dispatch: Dispatch) => {
    const response = await returnedAndReworkedLinesRequest(project.id)

    if (!response.successful) {
        toastFailure('An error occurred while fetching returned and reworked items.')
        return
    }

    await dispatch(setItems(response.data))
}

export default returnsAndReworksSlice.reducer
