import PageHeader from '../ui/PageHeader'
import { Urls } from '../../util/urls'
import ProductAuthorizationProductPage from './products/ProductAuthorizationProducts'
import ProductAuthorizationBomPage from './boms/ProductAuthorizationBoms'
import { Tabs, TabsProps } from 'antd'
import { styled } from '@mui/material'
import { RootState } from '../../store/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../store/hooks'
import {
    ActiveModal,
    ProductAuthorizationView,
    closeModal,
    setActiveView,
    setUpdateTableToken,
} from '../../store/product-authorization-slice'
import ProductAuthorizationExportBoms from './export-boms/ProductAuthorizationExportBoms'
import { AuthorizeBomModal } from './modals/AuthorizeBomModal'
import { ExportBomsModal } from './modals/ExportBomsModal'
import { InspectBomModal } from '../products/modals/InspectBomModal'
import { InspectUnauthorizedBomModal } from './modals/InspectUnauthorizedBomModal'
import { ImportSageBomVersionModal } from './modals/ImportSageBomVersionModal'
import { ImportInternalBomModal } from './modals/ImportInternalBomModal'

const Container = styled('div')``

const ContentContainer = styled('div')`
    padding: 25px;
`

export default function ProductAuthorization() {
    const { activeView, activeModal, modalEntityId } = useSelector(
        (state: RootState) => state.productAuthorization
    )
    const dispatch = useAppDispatch()

    const tabsOptions: TabsProps['items'] = [
        {
            key: ProductAuthorizationView.Products,
            label: `Stock Items`,
            children: <ProductAuthorizationProductPage />,
        },
        {
            key: ProductAuthorizationView.Boms,
            label: `Built Items`,
            children: <ProductAuthorizationBomPage />,
        },
        {
            key: ProductAuthorizationView.ExportBoms,
            label: `Export to Sage`,
            children: <ProductAuthorizationExportBoms />,
        },
    ]

    return (
        <Container>
            <PageHeader
                title="Product Authorization"
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        link: Urls.ProductAuthorization,
                        name: 'Product Authorization',
                    },
                ]}
            />
            {activeModal === ActiveModal.ImportSageBoms && (
                <ImportSageBomVersionModal
                    isVisible={activeModal === ActiveModal.ImportSageBoms}
                    onClose={() => dispatch(closeModal())}
                />
            )}

            {activeModal === ActiveModal.ImportInternalBom && <ImportInternalBomModal />}

            <InspectBomModal
                bomId={modalEntityId}
                isVisible={activeModal === ActiveModal.InspectBom && modalEntityId !== undefined}
                onClose={closeModal}
            />

            <InspectUnauthorizedBomModal
                unauthorizedBomId={modalEntityId}
                isVisible={
                    activeModal === ActiveModal.InspectUnauthorizedBom &&
                    modalEntityId !== undefined
                }
                onClose={() => dispatch(closeModal())}
            />

            {modalEntityId && (
                <AuthorizeBomModal
                    unauthorizedBomId={modalEntityId}
                    isVisible={
                        activeModal === ActiveModal.AuthorizeBomModal && modalEntityId !== undefined
                    }
                    onAuthorized={() => {
                        dispatch(setUpdateTableToken(`authorized-${modalEntityId}`))
                    }}
                    onClose={() => dispatch(closeModal())}
                />
            )}

            {activeModal === ActiveModal.ExportBoms && (
                <ExportBomsModal closeModal={() => dispatch(closeModal())} isOpen={true} />
            )}
            <ContentContainer>
                <Tabs
                    type="card"
                    activeKey={activeView}
                    onChange={(view) => dispatch(setActiveView(view as ProductAuthorizationView))}
                    defaultActiveKey={activeView}
                    items={tabsOptions}
                />
            </ContentContainer>
        </Container>
    )
}
