import { Box, Typography } from '@mui/material'
import AssemblyWorksheetCard from './AssemblyWorksheetCard'
import moment from 'moment'
import _ from 'lodash'
import { Assembly, GeneralAssembly, Schedule } from '../../../models/models'
import GeneralAssemblyWorksheetCard from './GeneralAssemblyCard'
import { useAppDispatch } from '../../../store/hooks'

interface Props {
    schedules: Schedule[]
    assemblies: Assembly[]
    assemblyCardOnClick?: (a: Assembly) => void
    generalAssemblies: GeneralAssembly[]
    generalAssemblyCardOnClick?: (ga: GeneralAssembly) => void
}

export const isAssembly = (assembly: Assembly | GeneralAssembly): assembly is Assembly => {
    return (assembly as Assembly).callOffId !== undefined
}

function AssembliesGrid(props: Props) {
    const dispatch = useAppDispatch()

    const sortedAsssemblies = [...props.assemblies, ...props.generalAssemblies].sort((a, b) => {
        const aDate = moment(a.dateToBeCompletedBy)
        const bDate = moment(b.dateToBeCompletedBy)

        return aDate.unix() - bDate.unix() || a.id - b.id
    })

    const dateGroupedAssemblies = _.groupBy(sortedAsssemblies, (worksheet) =>
        moment(worksheet.dateToBeCompletedBy).format('YYYY-MM-DD')
    )

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '20px',
                flexDirection: 'column',
            }}
        >
            {_.entries(dateGroupedAssemblies).map(([date, assemblies]) => (
                <Box key={date}>
                    <Typography sx={{ fontSize: 20, fontWeight: 'normal' }}>{date}</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '20px',
                            paddingTop: '20px',
                            flexWrap: 'wrap',
                        }}
                    >
                        {assemblies.map((assembly: Assembly | GeneralAssembly) =>
                            isAssembly(assembly) ? (
                                <AssemblyWorksheetCard
                                    key={assembly.id}
                                    schedule={props.schedules.find((s) => {
                                        return moment(s.day).isSame(
                                            assembly.dateToBeCompletedBy,
                                            'day'
                                        )
                                    })}
                                    assembly={assembly}
                                    onClick={() => {
                                        props.assemblyCardOnClick &&
                                            props.assemblyCardOnClick(assembly)
                                    }}
                                />
                            ) : (
                                <GeneralAssemblyWorksheetCard
                                    generalAssembly={assembly}
                                    schedule={props.schedules.find((s) => {
                                        return moment(s.day).isSame(
                                            assembly.dateToBeCompletedBy,
                                            'day'
                                        )
                                    })}
                                    onClick={() => {
                                        props.generalAssemblyCardOnClick &&
                                            props.generalAssemblyCardOnClick(assembly)
                                    }}
                                />
                            )
                        )}
                    </Box>
                </Box>
            ))}
        </Box>
    )
}

export default AssembliesGrid
