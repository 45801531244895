import styled from '@emotion/styled'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { Box } from '@mui/system'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Table } from 'antd'
import { Product } from '../../../models/models'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
    margin: '25px',
    height: '100%',
    gap: '20px',
})

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Quantity in Stock',
        dataIndex: 'quantityInStock',
        key: 'quantityInStock',
    },
    {
        title: 'Free Stock Available',
        dataIndex: 'freeStockAvailable',
        key: 'freeStockAvailable',
    },
]

interface Pt40ContentProps {
    product: Product
}

export default function StockItemContent(props: Pt40ContentProps) {
    const product = props.product
    return (
        <Container>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Product code - {product.code}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Table dataSource={product.binHoldings || []} columns={columns} />
                </AccordionDetails>
            </Accordion>
        </Container>
    )
}
