import { Dialog, IconButton, Divider, CircularProgress, Paper, Button } from '@mui/material'
import { Box, styled } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import { Product } from '../../../models/models'
import withBinHoldings, { WithBinHoldingsState } from '../../../hooks/withBinHoldings'
import StockItemContent from './StockItemContent'
import { useState } from 'react'
import { BomComponentsChecklist } from './BomComponentsCheckList'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: '50px',
})

const FooterContainer = styled(Box)(() => ({
    margin: '10px 20px 10px 20px',
}))

const FooterButton = styled(Button)(() => ({
    margin: '10px',
}))

function isStockItem(obj: Product | any): obj is Product {
    return (obj as Product)?.code !== undefined
}

interface ProductBinHoldingsModalProps {
    productId: number
    warehouseId: number
    closeModal: () => void
    onOk?: () => void
    isOpen: boolean
}

export default function ProductBinHoldingsModal(props: ProductBinHoldingsModalProps) {
    const { productId, closeModal, isOpen, onOk, warehouseId } = props
    const { product, state } = withBinHoldings(productId, warehouseId)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false)

    return (
        <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="md">
            <Paper style={{ height: '100%', maxHeight: '800px' }}>
                <Box
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div> {product && `${product.code} bin holdings`} </div>
                    <IconButton aria-label="close" onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Container>
                    {state === WithBinHoldingsState.Loading && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                margin: '20px',
                                width: '100%',
                                height: '400px',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                        </Box>
                    )}
                    {state === WithBinHoldingsState.Error && <Box>Something went wrong</Box>}
                    {state === WithBinHoldingsState.NotFound && (
                        <Box>Could not find the built item with the id of {productId}</Box>
                    )}
                    {state === WithBinHoldingsState.Success && product && (
                        <>
                            {!product.bom && <StockItemContent product={product} />}

                            <BomComponentsChecklist
                                product={product}
                                setIsSubmitDisabled={setIsSubmitDisabled}
                            />
                        </>
                    )}
                </Container>
                <FooterContainer>
                    <FooterButton variant="outlined" onClick={closeModal}>
                        Back
                    </FooterButton>
                    {onOk && (
                        <FooterButton
                            variant="outlined"
                            onClick={() => onOk()}
                            disabled={isSubmitDisabled}
                        >
                            Done
                        </FooterButton>
                    )}
                </FooterContainer>
            </Paper>
        </Dialog>
    )
}
