import { Box } from '@mui/system'
import { WarehouseAreaSpot } from '../../models/models'
import Pallet from './Pallet'
import _ from 'lodash'
import { styled } from '@mui/material'
import { SPOT_SIZE_HEIGHT_PX, SPOT_SIZE_WIDTH_PX } from './style'
import { RefObject, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { closeStackPopOver } from '../../store/warehouse-map-slice'

interface PalletStackProps {
    spot: WarehouseAreaSpot
    parentRef: RefObject<HTMLDivElement>
}
const PopOver = styled(Box)<{ spot: WarehouseAreaSpot }>((props) => ({
    position: 'absolute',
    display: 'flex',
    gap: '2px',
    padding: '6px',
    top: `-${(SPOT_SIZE_HEIGHT_PX / 4) * props.spot.pallets.length + 7}px`,
    right: `-${SPOT_SIZE_WIDTH_PX * 1.4}px`,
    height: `${SPOT_SIZE_HEIGHT_PX * props.spot.pallets.length + 14}px`,
    width: `${SPOT_SIZE_WIDTH_PX * 1.3}px`,
    zIndex: 2342323,
    backgroundColor: '#fff',
    flexDirection: 'column',
    boxShadow: '0 3px 7px rgba(0, 0, 0, 0.3)',
}))
const PopOverStackedPalletContainer = styled(Box)(() => ({}))

/**
 * This component is a child to Spot, and is rendered when a spot have multiple pallets as opposed
 * to PalletModel when not.
 * @param param0
 * @returns
 */
export const StackPopOver = ({ spot, parentRef }: PalletStackProps) => {
    const dispatch = useDispatch()
    const popOverRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
            //check if parent contains it. The parent components containers onClick function checks whether
            //or not it has a active popover for whether or not it should select the stack. But addEventListeners
            //gets called first, so without it this just removes the popover, and parent tries to open it again.
            if (parentRef.current && parentRef.current.contains(event.target as Node)) {
                return
            }
            if (popOverRef.current && !popOverRef.current.contains(event.target as Node)) {
                dispatch(closeStackPopOver())
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    })
    return (
        <PopOver spot={spot} ref={popOverRef}>
            {[...spot.pallets]
                .sort((firstEl, secondEl) => secondEl.stackLevel - firstEl.stackLevel)
                .map((pallet, index) => (
                    <PopOverStackedPalletContainer key={index}>
                        <Pallet spot={spot} pallet={pallet} key={index} />
                    </PopOverStackedPalletContainer>
                ))}
        </PopOver>
    )
}
