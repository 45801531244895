import { Button, FormControl, TextField } from '@mui/material'
import { Box, styled } from '@mui/system'
import { useEffect, useState } from 'react'
import OrderConfirmationsTable, {
    OrderConfirmationsTableColumns,
} from '../../tables/OrderConfirmationsTable'
import {
    GetOrderConfirmationRelations,
    getOrderConfirmationsRequest,
} from '../../../api/order-confirmation'
import InspectOrderConfirmationModal from '../../modals/inspect-order-confirmation-modal/InspectOrderConfirmationModal'
import DeleteOrderConfirmationModal from '../../modals/delete-order-confirmation-modal/DeleteOrderConfirmationModal'
import UpdateOrderConfirmationModal from '../../modals/update-order-confirmation-modal/UpdateOrderConfirmationModal'
import { Select } from 'antd'
import {
    getPurchaseOrderCreatorsRequest,
    getPurchaseOrderSuppliersRequest,
    PurchaseOrderDocumentStatus,
} from '../../../api/purchase-orders'
import { toastFailure } from '../../../util/toast'

enum ActiveModal {
    None = 'None',
    Inspect = 'Inspect',
    Update = 'Update',
    Delete = 'Delete',
}

const Container = styled(Box)(``)

const FilterContainer = styled(Box)(`
    display: flex;
`)

export default function OrderConfirmations() {
    const [activeModal, setActiveModal] = useState<ActiveModal>(ActiveModal.None)
    const [modalOrderConfirmationId, setModalOrderConfirmationId] = useState<number>()
    const [orderConfirmationCode, setOrderConfirmationCode] = useState<string>('')
    const [purchaseOrderCreators, setPurchaseOrderCreators] = useState<string[]>([])
    const [selectedPurchaseOrderCreator, setSelectedPurchaseOrderCreator] = useState<string>()
    const [purchaseOrderSuppliers, setPurchaseOrderSuppliers] = useState<string[]>([])
    const [selectedPurchaseOrderSupplier, setSelectedPurchaseOrderSupplier] = useState<string>()
    const [selectedIsActivated, setSelectedIsActivated] = useState<boolean>()
    const [selectedDocumentStatus, setSelectedDocumentStatus] =
        useState<PurchaseOrderDocumentStatus>()

    const getPurchaseOrderCreators = async () => {
        const response = await getPurchaseOrderCreatorsRequest()
        if (response.successful) {
            setPurchaseOrderCreators(response.data)
        } else {
            setPurchaseOrderCreators([])
            toastFailure(response.message)
        }
    }

    const getPurchaseOrderSuppliers = async () => {
        const response = await getPurchaseOrderSuppliersRequest()
        if (response.successful) {
            setPurchaseOrderSuppliers(response.data)
        } else {
            setPurchaseOrderSuppliers([])
            toastFailure(response.message)
        }
    }

    useEffect(() => {
        getPurchaseOrderSuppliers()
    }, [])

    useEffect(() => {
        getPurchaseOrderCreators()
    }, [])

    const openModal = (orderConfirmationId: number, modal: ActiveModal) => {
        setActiveModal(modal)
        setModalOrderConfirmationId(orderConfirmationId)
    }

    const closeModal = () => {
        setActiveModal(ActiveModal.None)
        setModalOrderConfirmationId(undefined)
    }

    return (
        <Container>
            {activeModal === ActiveModal.Inspect && modalOrderConfirmationId && (
                <InspectOrderConfirmationModal
                    orderConfirmationId={modalOrderConfirmationId}
                    active={activeModal === ActiveModal.Inspect && !!modalOrderConfirmationId}
                    onClose={closeModal}
                />
            )}

            {activeModal === ActiveModal.Update && modalOrderConfirmationId && (
                <UpdateOrderConfirmationModal
                    orderConfirmationId={modalOrderConfirmationId}
                    active={activeModal === ActiveModal.Update && !!modalOrderConfirmationId}
                    onClose={closeModal}
                />
            )}

            {activeModal === ActiveModal.Delete && modalOrderConfirmationId && (
                <DeleteOrderConfirmationModal
                    orderConfirmationId={modalOrderConfirmationId}
                    active={activeModal === ActiveModal.Delete && !!modalOrderConfirmationId}
                    onClose={closeModal}
                />
            )}

            <FilterContainer>
                <FormControl>
                    <TextField
                        sx={{
                            '& fieldset': { borderRadius: '10px' },
                            paddingRight: 1,
                        }}
                        label="Order confirmation code"
                        color={'primary'}
                        variant={'outlined'}
                        onChange={(e) => {
                            setOrderConfirmationCode(e.target.value)
                        }}
                    />
                </FormControl>

                <Select
                    placeholder={'Creator'}
                    size={'large'}
                    showSearch
                    style={{ width: '18%' }}
                    optionFilterProp={'name'}
                    value={selectedPurchaseOrderCreator}
                    onChange={(purchaseOrderCreator: string | undefined) =>
                        setSelectedPurchaseOrderCreator(purchaseOrderCreator)
                    }
                >
                    <Select.Option key={undefined} value={undefined}>
                        None
                    </Select.Option>
                    {purchaseOrderCreators.map((purchaseOrderCreator) => (
                        <Select.Option key={purchaseOrderCreator} value={purchaseOrderCreator}>
                            {purchaseOrderCreator}
                        </Select.Option>
                    ))}
                </Select>

                <Select
                    placeholder={'Supplier'}
                    size={'large'}
                    showSearch
                    style={{ width: '18%' }}
                    value={selectedPurchaseOrderSupplier}
                    onChange={(purchaseOrderSupplier: string | undefined) =>
                        setSelectedPurchaseOrderSupplier(purchaseOrderSupplier)
                    }
                >
                    <Select.Option key={undefined} value={undefined}>
                        None
                    </Select.Option>
                    {purchaseOrderSuppliers.map((purchaseOrderSupplier) => (
                        <Select.Option key={purchaseOrderSupplier} value={purchaseOrderSupplier}>
                            {purchaseOrderSupplier}
                        </Select.Option>
                    ))}
                </Select>

                <Select
                    placeholder={'Activated'}
                    size={'large'}
                    showSearch
                    style={{ width: '18%' }}
                    optionFilterProp={'name'}
                    value={selectedIsActivated}
                    onChange={(isActivated: boolean | undefined) =>
                        setSelectedIsActivated(isActivated)
                    }
                >
                    <Select.Option key={undefined} value={undefined}>
                        None
                    </Select.Option>
                    <Select.Option key={'true'} value={true}>
                        Activated
                    </Select.Option>
                    <Select.Option key={'false'} value={false}>
                        Not Activated
                    </Select.Option>
                </Select>

                <Select
                    placeholder={'Document status'}
                    size={'large'}
                    showSearch
                    style={{ width: '18%' }}
                    optionFilterProp={'name'}
                    value={selectedDocumentStatus}
                    onChange={(documentStatus: PurchaseOrderDocumentStatus | undefined) =>
                        setSelectedDocumentStatus(documentStatus)
                    }
                >
                    <Select.Option key={undefined} value={undefined}>
                        None
                    </Select.Option>
                    <Select.Option
                        key={PurchaseOrderDocumentStatus.EnumDocumentStatusOnHold}
                        value={PurchaseOrderDocumentStatus.EnumDocumentStatusOnHold}
                    >
                        On hold
                    </Select.Option>
                    <Select.Option
                        key={PurchaseOrderDocumentStatus.EnumDocumentStatusLive}
                        value={PurchaseOrderDocumentStatus.EnumDocumentStatusLive}
                    >
                        Live
                    </Select.Option>
                    <Select.Option
                        key={PurchaseOrderDocumentStatus.EnumDocumentStatusComplete}
                        value={PurchaseOrderDocumentStatus.EnumDocumentStatusComplete}
                    >
                        Complete
                    </Select.Option>
                </Select>
            </FilterContainer>

            <OrderConfirmationsTable
                columns={[
                    OrderConfirmationsTableColumns.PurchaseOrderDocumentNumber,
                    OrderConfirmationsTableColumns.Code,
                    OrderConfirmationsTableColumns.Status,
                    OrderConfirmationsTableColumns.Author,
                    OrderConfirmationsTableColumns.ExpectedDeliveryDate,
                ]}
                actionComponents={[
                    (orderConfirmationId: number) => {
                        return (
                            <Button
                                variant={'outlined'}
                                onClick={() => {
                                    openModal(orderConfirmationId, ActiveModal.Inspect)
                                }}
                            >
                                Inspect
                            </Button>
                        )
                    },
                    (orderConfirmationId: number) => {
                        return (
                            <Button
                                variant={'outlined'}
                                onClick={() => {
                                    openModal(orderConfirmationId, ActiveModal.Update)
                                }}
                            >
                                Update
                            </Button>
                        )
                    },
                    (orderConfirmationId: number) => {
                        return (
                            <Button
                                variant={'outlined'}
                                onClick={() => {
                                    openModal(orderConfirmationId, ActiveModal.Delete)
                                }}
                            >
                                Delete
                            </Button>
                        )
                    },
                ]}
                pagination={true}
                expandable={true}
                dependencies={{
                    orderConfirmationCode,
                    selectedPurchaseOrderCreator,
                    selectedPurchaseOrderSupplier,
                    selectedIsActivated,
                    selectedDocumentStatus,
                }}
                request={(pagination) => {
                    return getOrderConfirmationsRequest({
                        code: orderConfirmationCode,
                        purchaseOrderSupplier: selectedPurchaseOrderSupplier,
                        purchaseOrderCreatedBy: selectedPurchaseOrderCreator,
                        purchaseOrderIsActivated: selectedIsActivated,
                        purchaseOrderStatus: selectedDocumentStatus,
                        relations: [
                            GetOrderConfirmationRelations.Author,
                            GetOrderConfirmationRelations.PurchaseOrder,
                            GetOrderConfirmationRelations.Orderlines,
                            GetOrderConfirmationRelations.Deliveries,
                            GetOrderConfirmationRelations.OrderlinePurchaseOrderOrderline,
                            GetOrderConfirmationRelations.GoodsDelivered,
                        ],
                        skip: pagination?.skip,
                        limit: pagination?.limit,
                    })
                }}
            />
        </Container>
    )
}
