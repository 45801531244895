import { Tab } from '@mui/material'
import { BackgroundColors, TextColors } from '../../../theme/colors'
import { styled } from '@mui/material/styles'

export const FormTab = styled(Tab)({
    textTransform: 'none',
    fontWeight: 400,
    color: TextColors.black,
    '&.Mui-selected': {
        backgroundColor: BackgroundColors.white,
        color: TextColors.purple,
        fontWeight: 500,
        opacity: 1,
        border: 'none',
        borderRadius: '8px 8px 0 0',
    },
})
