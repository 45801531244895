import { Dialog, IconButton, Divider, Paper, Button, TextField } from '@mui/material'
import { Typography } from 'antd'
import { Box, styled } from '@mui/system'
import { deleteCallOffRequest, getCallOffById, SingleCallOffRelations } from '../../../api/call-off'
import { CallOff } from '../../../models/models'
import { toastFailure, toastSuccess } from '../../../util/toast'
import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useState } from 'react'
import CTRLoader from '../../ui/loader/CTRloader'

interface DeleteCallOffModalProps {
    callOffId: number
    closeModal: () => void
    isOpen: boolean
}

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

const Content = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 50px 20px 50px',
})

enum State {
    Loading = 'Loading',
    Found = 'Found',
    NotFound = 'NotFound',
    Error = 'Error',
}

export default function DeleteCallOffModal(props: DeleteCallOffModalProps) {
    const { callOffId, closeModal, isOpen } = props
    const [callOff, setCallOff] = useState<CallOff>()
    const [state, setState] = useState<State>(State.Loading)

    const getCallOff = async () => {
        const response = await getCallOffById(callOffId, {
            relations: [
                SingleCallOffRelations.Assemblies,
                SingleCallOffRelations.AssembliesPallets,
                SingleCallOffRelations.CallOffProducts,
                SingleCallOffRelations.SalesOrder,
                SingleCallOffRelations.SalesOrderCustomer,
                SingleCallOffRelations.SalesOrderProducts,
                SingleCallOffRelations.OrderConfirmations,
            ],
        })

        if (response.successful) {
            setCallOff(response.data)
            setState(State.Found)
        } else if (response.status === 404) {
            toastFailure('Could not find call off')
            setState(State.NotFound)
            closeModal()
        } else {
            toastFailure(response.message)
            setState(State.Error)
            closeModal()
        }
    }

    const deleteCallOff = async () => {
        const response = await deleteCallOffRequest(callOffId)
        if (response.successful) {
            toastSuccess('Call off has been deleted')
            closeModal()
        } else {
            toastFailure(response.message)
        }
    }

    useEffect(() => {
        getCallOff()
    }, [])

    return (
        <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="md">
            <Paper style={{ height: '100%', maxHeight: '800px' }}>
                <Box
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div> Delete Call off </div>
                    <IconButton aria-label="close" onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Container>
                    {state === State.Loading && (
                        <Content
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                margin: '20px',
                                width: '100%',
                                height: '400px',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CTRLoader size="xl" />
                        </Content>
                    )}
                    {callOff && state === State.Found && (
                        <Content>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly',
                                    padding: '20px 50px 10px 50px',
                                }}
                            >
                                <TextField
                                    key="Call Off ID"
                                    id="outlined-read-only-input"
                                    label="Call Off ID"
                                    defaultValue={callOff.id}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={true}
                                />
                                <TextField
                                    key="Project"
                                    id="outlined-read-only-input"
                                    label="Project Name"
                                    defaultValue={callOff.salesOrder.analysisCodes.analysisCode1}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={true}
                                />
                            </Box>
                            <Typography.Text type="danger">
                                Are you sure you want to delete this call-off? Your customer&apos;s
                                request may be forgotten if you have not created a new one.
                            </Typography.Text>

                            <Button
                                style={{ margin: '10px' }}
                                variant="contained"
                                color="error"
                                onClick={deleteCallOff}
                            >
                                Confirm
                            </Button>
                        </Content>
                    )}
                </Container>
            </Paper>
        </Dialog>
    )
}
