import { Button, Chip, Divider, styled, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { CallOff, WarehouseId } from '../../../../models/models'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCallOffDispatchView } from '../../../../store/warehouse-map-slice'
import { Urls } from '../../../../util/urls'
import { getCallOffById, SingleCallOffRelations } from '../../../../api/call-off'
import { toastFailure, toastWarn } from '../../../../util/toast'
import { isFlatstation } from '../../../../util/util'

interface TransferCardProps {
    callOff: CallOff
    onClick: () => void
    openDetailsModal: (callOff: CallOff) => void
    openDispatchModal: (callOff: CallOff) => void
}
const CardRow = (props: { description: string; content: string; disableDivider?: boolean }) => {
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '10px',
    }))

    const RowContent = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: '6px',
    }))

    const RowDescriptionContainer = styled(Box)(() => ({
        paddingLeft: '10px',
        width: '40%',
    }))

    const RowContentContainer = styled(Box)(() => ({
        width: '60%',
    }))

    const { description, content, disableDivider } = props
    return (
        <Row>
            <RowContent>
                <RowDescriptionContainer>
                    <Typography sx={{ color: '#26262690' }}>{description}</Typography>
                </RowDescriptionContainer>
                <RowContentContainer>
                    <Typography>{content}</Typography>
                </RowContentContainer>
            </RowContent>
            {!disableDivider && <Divider />}
        </Row>
    )
}

const CardHeader = (props: { callOff: CallOff; openDetailsModal: (callOff: CallOff) => void }) => {
    const { callOff, openDetailsModal } = props
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        padding: '5px',
    }))

    const ProjectName = styled(Box)(() => ({
        justifyContent: 'center',
        alignSelf: 'center',
        paddingLeft: '10px',
    }))

    const DetailsButton = styled(Button)(() => ({
        padding: '0px',
        minWidth: '0px',
    }))

    return (
        <div>
            <Row>
                <ProjectName>{callOff.project.name}</ProjectName>
                <DetailsButton
                    variant="text"
                    size="small"
                    onClick={() => openDetailsModal(callOff)}
                >
                    <Typography>Call-off Details</Typography>
                </DetailsButton>
            </Row>
            <Row>
                {callOff.isOnHold && (
                    <Chip
                        sx={{
                            marginTop: '10px',
                            color: '#ff1a1a',
                            whiteSpace: 'normal',
                            flexWrap: 'wrap',
                            borderColor: '#ff1a1a',
                            backgroundColor: '#ffcccc',
                        }}
                        label="Is on hold"
                        variant="outlined"
                    />
                )}
            </Row>
        </div>
    )
}

const CardFooter = (props: { onClick: () => void }) => {
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
    }))

    const TransferButton = styled(Button)(() => ({
        padding: '0px',
        minWidth: '0px',
        margin: '10px 10px 5px 0px',
    }))

    return (
        <Row>
            <TransferButton variant="outlined" size="small" onClick={props.onClick}>
                Dispatch call off to customer
            </TransferButton>
        </Row>
    )
}

const CardContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    margin: '20px',
    paddingBottom: '10px',
    backgroundColor: '#FFF',
    boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
}))

export default function DispatchCard(props: TransferCardProps) {
    const { callOff, openDetailsModal, openDispatchModal } = props
    const dispatch = useDispatch()
    const history = useHistory()

    const doesCallOffHaveHIU = (callOff: CallOff) => {
        if (callOff.warehouseId !== WarehouseId.Monument) {
            return false
        }
        const foundHIU = callOff.builtItemCallOffs.find((builtItemCallOff) => {
            return isFlatstation(builtItemCallOff.sopBuiltItemOrderline.builtItem)
        })
        return foundHIU !== undefined
    }
    return (
        <CardContainer>
            <CardHeader callOff={callOff} openDetailsModal={openDetailsModal} />
            <CardRow description="Call off id" content={callOff.id.toString()} />
            <CardRow
                description="Call off customer dispatch"
                content={moment(callOff.customerDispatchDate).format('DD/MM')}
            />

            <CardRow
                description="Call off customer delivery"
                content={moment(callOff.deliveryDate).format('DD/MM')}
            />
            <CardRow description="Dispatch comment" content={callOff.dispatchComment} />
            <CardRow
                description="Address 1"
                content={callOff.salesOrder?.deliveryAddress?.address1}
            />
            <CardRow
                description="Post code"
                content={callOff.salesOrder?.deliveryAddress?.postcode}
            />
            <CardRow
                description="Contacts"
                content={callOff.salesOrder?.contacts
                    .map((c) => `name: ${c.name} phone: ${c.phoneNumber}`)
                    .join(', \n \n \n')}
            />
            <CardRow
                description="Customer Document No"
                content={callOff.salesOrder?.customerDocumentNo}
            />
            <CardFooter
                onClick={async () => {
                    if (callOff.isOnHold) {
                        toastWarn('Cannot dispatch the call off because it is on hold')
                        return
                    }
                    if (!doesCallOffHaveHIU(callOff)) {
                        openDispatchModal(callOff)
                        return
                    }

                    const response = await getCallOffById(callOff.id, {
                        relations: [
                            SingleCallOffRelations.Assemblies,
                            SingleCallOffRelations.AssembliesPallets,
                        ],
                    })
                    if (response.successful) {
                        dispatch(setCallOffDispatchView(response.data))
                        history.push(Urls.WarehouseMap)
                    } else {
                        toastFailure(response.message)
                        return
                    }
                }}
            ></CardFooter>
        </CardContainer>
    )
}
