import { Redirect, Route, RouteProps } from 'react-router-dom'

interface ConditionalRouteProps extends RouteProps {
    condition: boolean
    elseRender?: (props: any) => JSX.Element
}

export const ConditionalRoute = (props: ConditionalRouteProps): JSX.Element | null => {
    const { render: IfComponent, elseRender: ElseComponent, condition } = props
    const RedirectComponent = () => <Redirect to="/" />
    return (
        <Route {...props} render={condition ? IfComponent : ElseComponent || RedirectComponent} />
    )
}
