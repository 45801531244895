import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip, Box } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
    getWarehouseMapSpotKey,
    selectAssemblyWarehouseMapSpots,
    setAssemblers,
} from '../../store/warehouse-map-slice'
import Spot from './Spot'

//This map consists of 5 tables.
//1. Green area / finished
//2. Padding Table 1 column
//3. Assembler table
//4. Another padding table with 2 columns
//5. Weekday table
export const AssemblyFloor = () => {
    const dispatch = useAppDispatch()
    const { assemblers } = useAppSelector((state) => state.warehouseMap)
    const spots = useSelector(selectAssemblyWarehouseMapSpots)
    const getSpot = (x: number, y: number) => {
        return spots[getWarehouseMapSpotKey(x, y)]
    }

    useEffect(() => {
        //takes warehouse id
        dispatch(setAssemblers(1))
    }, [])

    const assemblersWithSpots = assemblers
        .filter((a) => a.assemblerSpots)
        .filter((a) => a.assemblerSpots.length > 0)
        .sort(
            (a, b) =>
                (a.timeCreatedAt ? new Date(a.timeCreatedAt).getTime() : 0) -
                (b.timeCreatedAt ? new Date(b.timeCreatedAt).getTime() : 0)
        )

    const TABLE_HEIGHT = 14

    const FINISHED_TABLE_X_START = 0
    const FINISHED_TABLE_WIDTH = 9
    const FINISHED_TABLE_Y_START = 0
    const FINISHED_TABLE_HEIGHT = 14

    const PADDING_TABLE_1_WIDTH = 1
    const PADDING_TABLE_2_WIDTH = 2

    const WEEK_TABLE_X_START = 21

    const MAX_AMOUNT_ASSEMBLER_ROWS = 11

    const PADDING_TABLES_WIDTH = PADDING_TABLE_1_WIDTH + PADDING_TABLE_2_WIDTH

    const WEEK_TABLE_WIDTH = 15

    const TOTAL_NUMBER_OF_COLUMNS =
        FINISHED_TABLE_WIDTH + PADDING_TABLES_WIDTH + WEEK_TABLE_WIDTH + assemblersWithSpots.length

    const ASSEMBLERS_AMOUNT = assemblersWithSpots.length

    return (
        <Box
            style={{
                width: 'calc(100% - 2 * 27px)',
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            {/**FINISHED TABLE START */}
            <Box
                style={{
                    width: `calc(100% * (${FINISHED_TABLE_WIDTH} / ${TOTAL_NUMBER_OF_COLUMNS}))`,
                    minWidth: 'calc(8*36px + 36px)',
                }}
            >
                {Array.from(Array(FINISHED_TABLE_HEIGHT)).map((_, i) => {
                    const y = i + FINISHED_TABLE_Y_START
                    return (
                        <Box key={y} style={{ display: 'flex', flexDirection: 'row' }}>
                            <Box
                                style={{
                                    position: 'relative',
                                    minWidth: '36px',
                                    minHeight: '40px',
                                    width: `calc(100% / ${FINISHED_TABLE_WIDTH})`,
                                    paddingTop: `calc(100% / ${FINISHED_TABLE_WIDTH})`,
                                }}
                            >
                                <Box
                                    style={{
                                        position: 'absolute',
                                        top: '25%',
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        textAlign: 'center',
                                    }}
                                >
                                    {String.fromCharCode(97 + y)}
                                </Box>
                            </Box>
                            {Array.from(Array(FINISHED_TABLE_WIDTH)).map((_, i) => {
                                const x = i + FINISHED_TABLE_X_START
                                const spot = getSpot(x, y)
                                return (
                                    <Box
                                        key={x}
                                        style={{ width: `calc(100% / ${FINISHED_TABLE_WIDTH})` }}
                                    >
                                        <Spot key={i} spot={spot} />
                                    </Box>
                                )
                            })}
                        </Box>
                    )
                })}
            </Box>
            {/**FINISHED TABLE END */}
            {/**PADDING TABLE START */}
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: `calc(100% * (1 / ${TOTAL_NUMBER_OF_COLUMNS}))`,
                    minWidth: '36px',
                }}
            >
                {Array.from(Array(TABLE_HEIGHT)).map((_, i) => {
                    return (
                        <Box
                            key={i}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            {Array.from(Array(1)).map((_, j) => {
                                //NaN args makes it unable to have or get pallets
                                return <Spot key={j} spot={getSpot(NaN, NaN)} />
                            })}
                        </Box>
                    )
                })}
            </Box>
            {/**PADDING TABLE END */}
            {/**ASSEMBLER TABLE START */}
            <Box
                style={{
                    width: `calc(100% * (${ASSEMBLERS_AMOUNT} / ${TOTAL_NUMBER_OF_COLUMNS}))`,
                    display: 'flex',
                    flexDirection: 'row',
                    minWidth: `calc(${ASSEMBLERS_AMOUNT} * 36px)`,
                }}
            >
                {assemblersWithSpots.map((assembler) => {
                    const sortedSpots = [...assembler.assemblerSpots].sort((s1, s2) => s1.y - s2.y)
                    const assemblerX = sortedSpots[0].x

                    //zero indexed, so 0 = 1
                    let amountOfSpotRowsForAssember = 0
                    sortedSpots.forEach((spot) => {
                        const assemblerSpot = getSpot(assemblerX, spot.y)
                        if (assemblerSpot && assemblerSpot.pallets.length > 0) {
                            amountOfSpotRowsForAssember = spot.y + 1
                        }
                    })
                    //maximum amount is 10
                    if (amountOfSpotRowsForAssember > MAX_AMOUNT_ASSEMBLER_ROWS) {
                        amountOfSpotRowsForAssember = MAX_AMOUNT_ASSEMBLER_ROWS
                    }

                    //we can assume assembler only has same x values for all spots due to back end schema

                    return (
                        <Box
                            key={assemblerX}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: `calc(100% / ${ASSEMBLERS_AMOUNT})`,
                            }}
                        >
                            {/*NaN args makes it unable to have or get pallets*/}
                            {Array.from(Array(0)).map((_, y) => (
                                <Spot key={y} spot={getSpot(NaN, NaN)} />
                            ))}

                            {/** ASSEMBLER NAMES */}
                            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                <Box
                                    style={{
                                        position: 'relative',
                                        textAlign: 'center',
                                        height: '20px',
                                        width: '100%',
                                    }}
                                >
                                    <p>
                                        {assembler.firstName.slice(0, 1)}
                                        {assembler.lastName.slice(0, 1)}
                                    </p>
                                </Box>
                            </Box>
                            {Array.from(Array(amountOfSpotRowsForAssember + 1)).map((_, j) => {
                                const y = j

                                const isTabletDevice =
                                    localStorage.getItem('IS_TABLET') === 'true' ? true : false

                                return (
                                    <>
                                        {isTabletDevice ? (
                                            <Box>
                                                {y === 0 && (
                                                    <p
                                                        style={{
                                                            textAlign: 'center',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {assembler.firstName.slice(0, 1)}
                                                        {assembler.lastName.slice(0, 1)}
                                                    </p>
                                                )}
                                                <Spot spot={spots[`${assemblerX}-${y}`]} />
                                            </Box>
                                        ) : (
                                            <Tooltip
                                                title={assembler.firstName}
                                                key={`${assemblerX}-${y}`}
                                                placement="bottom"
                                            >
                                                <Box>
                                                    <Spot spot={spots[`${assemblerX}-${y}`]} />
                                                </Box>
                                            </Tooltip>
                                        )}
                                    </>
                                )
                            })}
                            {/** ROWS BELOW ASSEMBLER */}
                            {Array.from(
                                Array(MAX_AMOUNT_ASSEMBLER_ROWS - amountOfSpotRowsForAssember)
                            ).map((_, j) => {
                                /*NaN args makes it unable to have or get pallets*/
                                return <Spot key={j} spot={getSpot(NaN, NaN)} />
                            })}
                        </Box>
                    )
                })}
            </Box>
            {/**ASSEMBLER TABLE END */}
            {/**PADDING TABLE START */}
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: `calc(100% * (${PADDING_TABLE_2_WIDTH} / ${TOTAL_NUMBER_OF_COLUMNS}))`,
                    minWidth: `calc(${PADDING_TABLE_2_WIDTH} * 36px)`,
                }}
            >
                {Array.from(Array(TABLE_HEIGHT)).map((_, i) => {
                    return (
                        <Box
                            key={i}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            {Array.from(Array(PADDING_TABLE_2_WIDTH)).map((_, j) => {
                                /*NaN args makes it unable to have or get pallets*/
                                return <Spot key={j} spot={getSpot(NaN, NaN)} />
                            })}
                        </Box>
                    )
                })}
            </Box>
            {/**PADDING TABLE END */}
            {/**WEEK TABLE START */}
            <Box
                style={{
                    width: `calc(100% * (${WEEK_TABLE_WIDTH} / ${TOTAL_NUMBER_OF_COLUMNS}))`,
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: `calc(${WEEK_TABLE_WIDTH} * 36px)`,
                }}
            >
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    {/** PADDING ABOVE WEEK TABLE */}
                    {Array.from(Array(WEEK_TABLE_WIDTH)).map((_, x) => (
                        <Box
                            key={x}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: `calc(100% / ${ASSEMBLERS_AMOUNT})`,
                            }}
                        >
                            {x + 1}
                        </Box>
                    ))}
                </Box>

                {/**THE ACTUAL WEEK TABLE */}
                {Array.from(Array(13)).map((_, y) => {
                    return (
                        <Box
                            key={y}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            {Array.from(Array(WEEK_TABLE_WIDTH)).map((_, x) => {
                                const xStart = WEEK_TABLE_X_START
                                x = xStart + x
                                const spot = getSpot(x, y)
                                return (
                                    <Box
                                        key={x}
                                        style={{ width: `calc(100% / ${WEEK_TABLE_WIDTH})` }}
                                    >
                                        <Spot spot={spot} />
                                    </Box>
                                )
                            })}
                        </Box>
                    )
                })}
            </Box>
            {/**WEEK TABLE END */}
        </Box>
    )
}
