import { Delivery } from '../models/models'
import backendConnection from './util/backend-connection'
import { Response } from './util/with-response-formatter-interceptor'

export enum GetDeliveryRelations {
    OrderConfirmation = 'OrderConfirmation',
    GoodsDelivered = 'Orderlines',
    GoodsDeliveredOrderlines = 'GoodsDeliveredOrderlines ',
    GoodsDeliveredOrderlinesProduct = 'GoodsDeliveredOrderlinesProduct',
}

export enum GetDeliverySortBy {
    ReceivedDate = 'ReceivedDate',
}

export enum GetDeliveryDateSearchType {
    ExpectedCompletelyArrivedDate = 'ExpectedCompletelyArrivedDate',
    ReceivedDate = 'ReceivedDate',
}

interface GetDeliveryDateSearch {
    dateRangeBegin?: Date
    dateRangeEnd?: Date
    dateSearchType: GetDeliveryDateSearchType
}

export interface GetDeliveryDTO {
    relations?: GetDeliveryRelations[]
    id?: number
    orderConfirmationId?: number
    dateSearch?: GetDeliveryDateSearch
    sortBy?: GetDeliverySortBy
    skip?: number
    limit?: number
}

export const getDeliveriesRequest = (
    params: GetDeliveryDTO
): Promise<Response<{ count: number; entities: Delivery[] }>> =>
    backendConnection.request({
        url: `/deliveries`,
        method: 'GET',
        params,
    })
