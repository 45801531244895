import { Assembly, AssemblyLine } from '../models/models'
import { ValidationSuiteAttributes } from '../store/assembly-worksheets-slice'
import { ReturnedOrReworkedLine } from '../store/returns-and-reworks-slice'
import backendConnection from './util/backend-connection'
import { Response } from './util/with-response-formatter-interceptor'

export const updateWorksheetLineRequest = (
    itemId: number,
    data: {
        energyMeterSerialNumber?: string | null
        hiuSerialNumber?: string | null
        isPPV?: boolean | null
        note?: string | null
    } = {}
): Promise<Response<Assembly>> =>
    backendConnection.request({
        url: `assembly-lines/${itemId}`,
        method: 'PATCH',
        data,
    })

export const validateWorksheetLineRequest = (
    itemId: number,
    key: keyof ValidationSuiteAttributes,
    value: string | boolean | number | null
): Promise<Response<Assembly>> =>
    backendConnection.request({
        url: `assembly-lines/validation/${itemId}`,
        method: 'PATCH',
        data: {
            [key]: value,
        },
    })

export const completeWorksheetLineRequest = (itemId: number): Promise<Response<Assembly>> =>
    backendConnection.request({
        url: `assembly-lines/complete/${itemId}`,
        method: 'POST',
    })

export const returnWorksheetLineRequest = (
    item: AssemblyLine,
    comment: string
): Promise<Response<Assembly>> =>
    backendConnection.request({
        url: `assembly-lines/return/${item.id}`,
        method: 'POST',
        data: {
            comment,
        },
    })

export const reworkWorksheetLineRequest = (item: AssemblyLine): Promise<Response<Assembly>> =>
    backendConnection.request({
        url: `assembly-lines/${item.id}/rework`,
        method: 'POST',
    })

export const returnedAndReworkedLinesRequest = (
    projectId: number
): Promise<Response<ReturnedOrReworkedLine[]>> =>
    backendConnection.request({
        url: `assembly-lines/returns-and-reworks/${projectId}`,
        method: 'GET',
    })

export const getAssemblyLineByIdRequest = (id: number): Promise<Response<AssemblyLine>> =>
    backendConnection.request({
        url: `assembly-lines/${id}`,
        method: 'GET',
    })
