import { RootState } from '../../store/store'
import { useSelector } from 'react-redux'
import AssemblyFieldsTable, { AssemblyFieldsTableColumns } from '../tables/AssemblyFieldsTable'
import { getAssemblyFieldsRequest } from '../../api/assembly-fields'
import { AssemblyFieldsTopBar } from './AssemblyFieldsTopBar'
import { AssemblyField } from '../../models/models'
import { Button } from 'antd'
import { useAppDispatch } from '../../store/hooks'
import { ActiveModal, openModal } from '../../store/products-slice'

export const AssemblyFields = () => {
    const { assemblyFieldFilters, updateTableToken } = useSelector(
        (state: RootState) => state.products
    )
    const dispatch = useAppDispatch()
    return (
        <div>
            <AssemblyFieldsTopBar />
            <AssemblyFieldsTable
                pagination={true}
                actionComponents={[
                    (assemblyField: AssemblyField) => {
                        return (
                            <Button
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            activeModal: ActiveModal.AssemblyFieldUpdate,
                                            modalEntityId: assemblyField.id,
                                        })
                                    )
                                }
                            >
                                Update
                            </Button>
                        )
                    },
                ]}
                dependencies={{ assemblyFieldFilters, updateTableToken }}
                columns={[
                    AssemblyFieldsTableColumns.Id,
                    AssemblyFieldsTableColumns.Name,
                    AssemblyFieldsTableColumns.Type,
                    AssemblyFieldsTableColumns.Required,
                    AssemblyFieldsTableColumns.Scannable,
                    AssemblyFieldsTableColumns.Serial,
                    AssemblyFieldsTableColumns.NumericLowerLimit,
                    AssemblyFieldsTableColumns.NumericUpperLimit,
                    AssemblyFieldsTableColumns.DropdownOptions,
                ]}
                request={(pagination) =>
                    getAssemblyFieldsRequest({
                        ids: assemblyFieldFilters?.assemblyFieldId
                            ? [assemblyFieldFilters.assemblyFieldId]
                            : undefined,
                        name: assemblyFieldFilters?.name,
                        skip: pagination?.skip,
                        limit: pagination?.limit,
                    })
                }
            />
        </div>
    )
}
