import { Response } from '../util/with-response-formatter-interceptor'
import { ProjectBuiltItem } from '../../models/models'
import backendConnection from '../util/backend-connection'

export type GetProjectBuiltItemsQueryParams = {
    builtItem?: boolean
    used?: boolean
    skip?: number
    bomCode?: string
    withWarehouseHoldings?: boolean
    limit?: number
    withCount?: boolean
}

export const getProjectBuiltItems = (
    projectId: number,
    queryParams: GetProjectBuiltItemsQueryParams
): Promise<Response<{ count?: number; projectBuiltItems: ProjectBuiltItem[] }>> =>
    backendConnection.request({
        url: `/projects/built-items/${projectId}`,
        method: 'GET',
        params: queryParams,
    })

export const getByProjectAndBuiltItemIdRequest = (
    projectId: number,
    projectBuiltItemId: number
): Promise<Response<ProjectBuiltItem>> =>
    backendConnection.request({
        url: `/projects/built-items/${projectId}/${projectBuiltItemId}`,
        method: 'GET',
    })

export interface UpdateProjectBuiltItemDTO {
    projectId: number
    projectBuiltItemId: number
    amount: number
}

export const updateProjectBuiltItemRequest = (
    dto: UpdateProjectBuiltItemDTO
): Promise<Response<ProjectBuiltItem>> =>
    backendConnection.request({
        url: `/projects/built-items`,
        method: 'PATCH',
        data: dto,
    })

export const deleteProjectBuiltItemRequest = (
    projectBuiltItemId: number
): Promise<Response<void>> =>
    backendConnection.request({
        url: `/projects/built-items/${projectBuiltItemId}`,
        method: 'DELETE',
    })

interface CreateProjectBuiltItemsDTO {
    builtItems: { id: number; amount: number }[]
}

export const createProjectBuiltItems = (
    projectId: number,
    body: CreateProjectBuiltItemsDTO
): Promise<Response<ProjectBuiltItem[]>> =>
    backendConnection.request({
        url: `/projects/built-items/${projectId}`,
        method: 'POST',
        data: body,
    })
