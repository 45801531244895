import { BomTypes, LineType, SalesOrder } from '../models/models'
import backendConnection from './util/backend-connection'
import { Response } from './util/with-response-formatter-interceptor'

export interface CustomerSopTemplate {
    id: number
    isCreditLimitExceeded: boolean
    paymentWithOrder: boolean
    paymentValue: number
    paymentMethodId: number
    paymentMethod: {
        name: string
        description: string
        bankAccountId: number
        currencyId: number
        cardProcessingMethod: boolean
        id: number
    }
    documentNo: string
    documentDate: Date
    documentStatus: string
    customerType: string
    customerId: number
    currencyId: number
    isDraft: boolean
    exchangeRate: number
    subtotalGoodsValue: number
    subtotalChargeNetValue: number
    subtotalChargeTaxValue: number
    subtotalDiscountValue: number
    totalNetValue: number
    totalTaxValue: number
    totalGrossValue: number
    customerDocumentNo: string
    useInvoiceAddress: boolean
    settlementDiscountDays: number
    settlementDiscountPercent: number
    documentDiscountPercent: number
    documentCreatedBy: string
    requestedDeliveryDate: Date
    promisedDeliveryDate: Date
    orderPriority: string
    analysisCode1: string
    analysisCode2: string
    analysisCode3: string
    analysisCode4: string
    analysisCode5: string
    analysisCode6: string
    analysisCode7: string
    analysisCode8: string
    analysisCode9: string
    analysisCode10: string
    analysisCode11: string
    analysisCode12: string
    analysisCode13: string
    analysisCode14: string
    analysisCode15: string
    analysisCode16: string
    analysisCode17: string
    analysisCode18: string
    analysisCode19: string
    analysisCode20: string
    externalReference: string
    customer: {
        reference: string
        name: string
        shortName: string
        balance: number
        onHold: boolean
        accountStatusType: string
        statusReason: string
        currencyId: number
        exchangeRateType: string
        telephoneCountryCode: string
        telephoneAreaCode: string
        telephoneSubscriberNumber: string
        faxCountryCode: string
        faxAreaCode: string
        faxSubscriberNumber: string
        website: string
        creditLimit: number
        countryCodeId: number
        defaultTaxCodeId: number
        vatNumber: string
        accountType: string
        earlySettlementDiscountPercent: number
        earlySettlementDiscountDays: number
        paymentTermsDays: number
        paymentTermsBasis: string
        creditBureauId: number
        creditPositionId: number
        tradingTerms: string
        creditReference: string
        accountOpened: Date
        lastCreditReview: Date
        nextCreditReview: Date
        termsAgreed: boolean
        orderPriority: string
        monthsToKeepTransactions: number
        defaultNominalCodeReference: string
        defaultNominalCodeCostCentre: string
        defaultNominalCodeDepartment: string
        analysisCode1: string
        analysisCode2: string
        analysisCode3: string
        analysisCode4: string
        analysisCode5: string
        analysisCode6: string
        analysisCode7: string
        analysisCode8: string
        analysisCode9: string
        analysisCode10: string
        analysisCode11: string
        analysisCode12: string
        analysisCode13: string
        analysisCode14: string
        analysisCode15: string
        analysisCode16: string
        analysisCode17: string
        analysisCode18: string
        analysisCode19: string
        analysisCode20: string
        valueOfCurrentOrdersInSop: number
        dunsCode: string
        averageTimeToPay: number
        financeChargeId: number
        officeType: string
        associatedHeadOfficeId: number
        produceStatementsForCustomer: boolean
        useConsolidatedBilling: boolean
        useTaxCodeAsDefault: boolean
        invoiceDiscountPercent: number
        invoiceLineDiscountPercent: number
        customerDiscountGroupId: number
        orderValueDiscountId: number
        priceBandId: number
        mainAddress?: {
            customerId: number
            country: string
            address1: string
            address2: string
            address3: string
            address4: string
            city: string
            county: string
            postcode: string
            addressCountryCodeId: number
            id: number
            dateTimeCreated: Date
            dateTimeUpdated: Date
        }
        currency?: {
            symbol: string
            name: string
            coreCurrencyRate: number
            euroCurrencyRate: number
            currencyIsoCodeId: number
            isBaseCurrency: boolean
            isEuroCurrency: boolean
            useForNewAccounts: boolean
            exchangeRateType: string
            exchangeRateAmendabilityType: string
            id: number
            dateTimeCreated: Date
            dateTimeUpdated: Date
        }
        alerts: any[]
        id: number
        dateTimeCreated: Date
        dateTimeUpdated: Date
    }
    currency: {
        symbol: string
        name: string
        coreCurrencyRate: number
        euroCurrencyRate: number
        currencyIsoCodeId: number
        isBaseCurrency: boolean
        isEuroCurrency: boolean
        useForNewAccounts: boolean
        exchangeRateType: string
        exchangeRateAmendabilityType: string
        id: number
        dateTimeCreated: Date
        dateTimeUpdated: Date
    }
    lines: any[]
}

export const getSopTemplate = (projectId: number): Promise<Response<CustomerSopTemplate>> =>
    backendConnection.request({
        url: `/sales-orders/template/${projectId}`,
        method: 'GET',
    })

export interface AdditionalCharge {
    code: string
    name: string
    chargeValue: number
    notionalCostValue: number
    nominalCodeId: number
    taxCodeId: number
    id: number
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export const getAdditionalChargeOptions = (): Promise<Response<AdditionalCharge[]>> =>
    backendConnection.request({
        url: `/sales-orders/additional-charges`,
        method: 'GET',
    })

export interface ChargeOrderline {
    code: string
    description: string
    price: number
}

export interface ProductOrderline {
    builtItemId?: number
    productId: number
    amount: number
    price: number
}

export interface CommentOrderline {
    description: string
}

export interface ContactDTO {
    name: string
    description: string
    phoneNumber: string
}

export interface CreateSalesOrderDTO {
    projectId: number
    deliveryAddressId: number
    promisedDeliveryDate: Date
    customerId: number
    contacts?: ContactDTO[]
    analysisCode1: string
    analysisCode2: string
    analysisCode3: string
    analysisCode4: string
    analysisCode5: string
    analysisCode6: string
    orderlines: Array<ProductOrderline | CommentOrderline | ChargeOrderline>
    warehouseId: number
    customerOrderNumber: string
}

export const createSalesOrder = (dto: CreateSalesOrderDTO): Promise<Response<SalesOrder[]>> =>
    backendConnection.request({
        url: `/sales-orders`,
        method: 'POST',
        data: dto,
    })

export enum SingleSalesOrderRelations {
    Orderlines = 'Orderlines',
    CallOffs = 'CallOffs',
    Projects = 'Projects',
    Contacts = 'Contacts',
    Customer = 'Customer',
}
export class GetSalesOrderQueryParams {
    withReservedOrderlineAmounts?: boolean
    relations?: SingleSalesOrderRelations[]
}

export const getSalesOrderByIdRequest = (
    id: number,
    dto: GetSalesOrderQueryParams
): Promise<Response<SalesOrder>> =>
    backendConnection.request({
        url: `/sales-orders/${id}`,
        method: 'GET',
        params: dto,
    })

export enum GetManySalesOrderRelations {
    Orderlines = 'Orderlines',
    CallOffs = 'CallOffs',
    Projects = 'Projects',
}

export class GetSalesOrdersQueryParams {
    skip?: number
    relations?: GetManySalesOrderRelations[]
    limit?: number
    withCount?: boolean
    projectId?: number
    withReservedOrderlineAmounts?: boolean
}

export const getSalesOrdersRequest = (
    dto: GetSalesOrdersQueryParams
): Promise<Response<{ entities: SalesOrder[]; count: number }>> =>
    backendConnection.request({
        url: `/sales-orders`,
        method: 'GET',
        params: dto,
    })

export const updateSalesOrderNoteRequest = (
    id: number,
    note: string
): Promise<Response<SalesOrder>> =>
    backendConnection.request({
        url: `sales-orders/note/${id}`,
        method: 'PUT',
        params: { note },
    })

export interface AnalysisCodeValueDTO {
    analysisCodeId: number
    value: string
    isDefault: boolean
    id: number
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export interface AnalysisCodeDTO {
    name: string
    allowOvertype: boolean
    allowNewOnEntry: boolean
    values: AnalysisCodeValueDTO[]
    id: number
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export interface AnalysisCodeMappings {
    className: string
    friendlyClassName: string
    propertyName: string
    friendlyPropertyName: string
    isMandatory: boolean
    slot: number
    analysisCodeId: number
    analysisCode: AnalysisCodeDTO
    id: number
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export const getAnalysisCodeDetailsRequest = (): Promise<Response<AnalysisCodeMappings[]>> =>
    backendConnection.request({
        url: `/sales-orders/analysis-code-options`,
        method: 'GET',
    })

export interface AdditionalChargeDTO {
    id: number
    code: string
    name: string
    chargeValue: number
    notionalCostValue: number
    nominalCodeId: number
    taxCodeId: number
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export interface ProductDTO {
    id: number
    code: string
    name: string
    description: string
    useDescriptionOnDocuments: boolean
    barcode: string
    allowSalesOrder: boolean
    freeStockQuantity: number
    productGroupId: number
    taxCodeId: number
    productStatusType: string
    fulfilmentMethodType: string
    fulfilmentSequenceType: string
    inactivationDate: Date
    manufacturer: string
    partNumber: string
    labelPrintingOptionType: string
    traceableType: string
    saleFromSingleBatch: boolean
    allowDuplicateNumbers: boolean
    usesAlternativeRef: boolean
    usesSellByDate: boolean
    usesUseByDate: boolean
    shelfLife: number
    shelfLifeType: string
    recordNosOnGoodsReceived: boolean
    includeNosOnCountSheets: boolean
    autoGenerateOptionType: string
    autoGenerateSeparatorId: number
    autoGeneratePrefix: string
    autoGenerateNext_number: number
    autoGeneratePadding: number
    stocktakeCyclePeriod: number
    standardCost: number
    averageBuyingPrice: number
    defaultPickingListComment: string
    defaultDespatchNoteComment: string
    commodityCode: string
    countryOfOriginId: number
    weight: number
    suppressMassOnDeclaration: boolean
    isWeeeItem: boolean
    useSupplementaryUnits: boolean
    supplementaryUnitConversionRatio: number
    useReverseChargeVatRules: boolean
    analysisCode1: string
    analysisCode2: string
    analysisCode3: string
    analysisCode4: string
    analysisCode5: string
    analysisCode6: string
    analysisCode7: string
    analysisCode8: string
    analysisCode9: string
    analysisCode10: string
    analysisCode11: string
    analysisCode12: string
    analysisCode13: string
    analysisCode14: string
    analysisCode15: string
    analysisCode16: string
    analysisCode17: string
    analysisCode18: string
    analysisCode19: string
    analysisCode20: string
    landedCostsType: string
    landedCostsPercentage: number
    landedCostsPerItem: number
    bomItemType: BomTypes
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export interface OrderlineDTO {
    id: number
    sopOrderId: number
    lineNumber: number
    lineType: LineType
    code: string
    description: string
    useDescription: boolean
    taxCodeId: number
    nominalReference: string
    nominalCostCentre: string
    nominalDepartment: string
    lineQuantity: number
    allocatedQuantity: number
    despatchReceiptQuantity: number
    invoiceCreditQuantity: number
    postedInvoiceCreditQuantity: number
    availableForDespatch: number
    stockUnitLineQuantity: number
    stockUnitAllocatedQuantity: number
    stockUnitDespatchReceiptQuantity: number
    stockUnitInvoiceCreditQuantity: number
    stockUnitPostedInvoiceCreditQuantity: number
    stockUnitAvailableForDespatch: number
    allocationStatus: string
    despatchReceiptStatus: string
    invoiceCreditStatus: string
    sellingUnitDescription: string
    pricingUnitDescription: string
    sellingUnitPrice: number
    unitDiscountPercent: number
    unitDiscountValue: number
    costPrice: number
    discountPercentSpecified: boolean
    sellingUnitPriceOverridden: boolean
    unitDiscountOverridden: boolean
    pickingListComment: string
    despatchNoteComment: string
    showOnCustomerDocs: boolean
    showOnPickingListType: string
    confirmationIntentType: string
    hasPopOrder: boolean
    isComplete: boolean
    lineTotalValue: number
    lineTaxValue: number
    requestedDeliveryDate: Date
    promisedDeliveryDate: Date
    analysisCode1: string
    analysisCode2: string
    analysisCode3: string
    analysisCode4: string
    analysisCode5: string
    analysisCode6: string
    analysisCode7: string
    analysisCode8: string
    analysisCode9: string
    analysisCode10: string
    analysisCode11: string
    analysisCode12: string
    analysisCode13: string
    analysisCode14: string
    analysisCode15: string
    analysisCode16: string
    analysisCode17: string
    analysisCode18: string
    analysisCode19: string
    analysisCode20: string
    additionalCharge?: AdditionalChargeDTO
    toAllocateQuantity?: number
    stockUnitToAllocateQuantity?: number
    sellingUnitId?: number
    pricingUnitId?: number
    discountedUnitPrice?: number
    retainManualPrices?: boolean
    fulfilmentMethod: string
    markAsPreferred?: boolean
    product?: ProductDTO
    productId?: number
    warehouseId?: number
}
export interface CustomerDTO {
    reference: string
    name: string
    shortName: string
    balance: number
    onHold: boolean
    accountStatusType: string
    statusReason: string
    currencyId: number
    exchangeRateType: string
    telephoneCountryCode: string
    telephoneAreaCode: string
    telephoneSubscriberNumber: string
    faxCountryCode: string
    faxAreaCode: string
    faxSubscriberNumber: string
    website: string
    creditLimit: number
    countryCodeId: number
    defaultTaxCodeId: number
    vatNumber: string
    accountType: string
    earlySettlementDiscountPercent: number
    earlySettlementDiscountDays: number
    paymentTermsDays: number
    paymentTermsBasis: string
    creditBureauId: number
    creditPositionId: number
    tradingTerms: string
    creditReference: string
    accountOpened: Date
    nextCreditReview: Date
    termsAgreed: boolean
    orderPriority: string
    monthsToKeepTransactions: number
    defaultNominalCodeReference: string
    defaultNominalCodeCost_centre: string
    defaultNominalCodeDepartment: string
    analysisCode1: string
    analysisCode2: string
    analysisCode3: string
    analysisCode4: string
    analysisCode5: string
    analysisCode6: string
    analysisCode7: string
    analysisCode8: string
    analysisCode9: string
    analysisCode10: string
    analysisCode11: string
    analysisCode12: string
    analysisCode13: string
    analysisCode14: string
    analysisCode15: string
    analysisCode16: string
    analysisCode17: string
    analysisCode18: string
    analysisCode19: string
    analysisCode20: string
    valueOfCurrentOrdersInSop: number
    dunsCode: string
    averageTimeToPay: number
    financeChargeId: number
    officeType: string
    associatedHeadOfficeId: number
    produceStatementsForCustomer: boolean
    useConsolidatedBilling: boolean
    useTaxCodeAsDefault: boolean
    invoiceDiscountPercent: number
    invoiceLineDiscountPercent: number
    customerDiscountGroupId: number
    orderValueDiscountId: number
    priceBandId: number
    alerts: any[]
    id: number
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export interface SalesOrderDTO {
    id: number
    isCreditLimitExceeded: boolean
    paymentWithOrder: boolean
    paymentValue: number
    paymentMethodId: number
    documentNo: string
    documentDate: Date
    documentStatus: string
    customerType: string
    customerId: number
    currencyId: number
    isDraft: boolean
    exchangeRate: number
    subtotalGoodsValue: number
    subtotalChargeNetValue: number
    subtotalChargeTaxValue: number
    subtotalDiscountValue: number
    totalNetValue: number
    totalTaxValue: number
    totalGrossValue: number
    customerDocumentNo: string
    useInvoiceAddress: boolean
    settlementDiscountDays: number
    settlementDiscountPercent: number
    documentDiscountPercent: number
    documentCreatedBy: string
    requestedDeliveryDate: Date
    promisedDeliveryDate: Date
    orderPriority: string
    analysisCode1: string
    analysisCode2: string
    analysisCode3: string
    analysisCode4: string
    analysisCode5: string
    analysisCode6: string
    analysisCode7: string
    analysisCode8: string
    analysisCode9: string
    analysisCode10: string
    analysisCode11: string
    analysisCode12: string
    analysisCode13: string
    analysisCode14: string
    analysisCode15: string
    analysisCode16: string
    analysisCode17: string
    analysisCode18: string
    analysisCode19: string
    analysisCode20: string
    externalReference: string
    lines: OrderlineDTO[]
    customer?: CustomerDTO
}

export const getSageSalesOrderByDocumentNoRequest = (
    documentNo: string
): Promise<Response<SalesOrderDTO>> =>
    backendConnection.request({
        url: `/sales-orders/sage/${documentNo}`,
        method: 'GET',
    })

export interface ImportSageSalesOrderDTO {
    projectId: number
    warehouseId: number
    documentNo: string
}

export const importSageSalesOrderRequest = (
    body: ImportSageSalesOrderDTO
): Promise<Response<SalesOrderDTO>> =>
    backendConnection.request({
        url: `/sales-orders/import`,
        method: 'POST',
        data: body,
    })
