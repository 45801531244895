import { PurchaseOrder } from '../../../models/models'
import PurchaseOrderOrderlineTable from '../../tables/PurchaseOrderOrderlineTable'

interface Props {
    purchaseOrder: PurchaseOrder
}

export default function OrderlinesTab({ purchaseOrder }: Props) {
    return <PurchaseOrderOrderlineTable purchaseOrder={purchaseOrder} />
}
