import { Dialog, IconButton, Divider, Typography, Paper, Button, TextField } from '@mui/material'
import { Box, styled } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useState } from 'react'
import CTRLoader from '../../../../../ui/loader/CTRloader'
import { Bom } from '../../../../../../models/models'
import { toastFailure } from '../../../../../../util/toast'
import { BomRelation, getBomById } from '../../../../../../api/bom'

interface AddStockItemModalProps {
    isOpen: boolean
    builtItemId: number
    onClose: () => void
    onConfirm: (stockItem: Bom, amount: number) => void
}
const ModalRow = (props: { description: string; component: any; disableDivider?: boolean }) => {
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '15px',
    }))

    const RowContent = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: '10px',
    }))

    const RowDescriptionContainer = styled(Box)(() => ({
        paddingLeft: '10px',
        width: '30%',
    }))

    const RowContentContainer = styled(Box)(() => ({
        width: '70%',
    }))

    const { description, component, disableDivider } = props
    return (
        <Row>
            <RowContent>
                <RowDescriptionContainer>
                    <Typography sx={{ color: '#26262690' }}>{description}</Typography>
                </RowDescriptionContainer>
                <RowContentContainer>{component}</RowContentContainer>
            </RowContent>
            {!disableDivider && <Divider />}
        </Row>
    )
}

enum ModalState {
    Loading = 'Loading',
    Ready = 'Ready',
    Error = 'Error',
}

export default function AddBuiltItemModal({
    isOpen,
    onClose,
    builtItemId,
    onConfirm,
}: AddStockItemModalProps) {
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const [amount, setAmount] = useState<string>('')
    const [builtItem, setBuiltItemtProduct] = useState<Bom>()

    useEffect(() => {
        getBomById(builtItemId, { relations: [BomRelation.Product] }).then((response) => {
            if (response.successful) {
                setBuiltItemtProduct(response.data)
                setModalState(ModalState.Ready)
            } else {
                toastFailure(response.message)
                setModalState(ModalState.Error)
            }
        })
    }, [])
    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
            <Paper style={{ height: '100%', maxHeight: '800px' }}>
                <Box
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        <Typography fontSize={18}>Add Built Item</Typography>
                    </Box>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />

                {modalState === ModalState.Loading && (
                    <Box sx={{ height: '400px' }}>
                        <CTRLoader />
                    </Box>
                )}
                {modalState === ModalState.Error && (
                    <Box>
                        <Typography>Something went wrong.</Typography>
                    </Box>
                )}
                {modalState === ModalState.Ready && builtItem && (
                    <Box style={{ width: '100%', padding: '20px 0px 20px 0px' }}>
                        <ModalRow description="Code" component={builtItem.bomCode} />
                        <ModalRow description="Name" component={builtItem.product.name} />
                        <ModalRow
                            description="Description"
                            component={builtItem.product.description}
                        />
                        <ModalRow
                            description="Amount"
                            component={
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    type="number"
                                    sx={{ width: '90%' }}
                                    label="Amount"
                                    autoFocus
                                    autoComplete="off"
                                    value={amount}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setAmount(e.target.value)
                                    }}
                                />
                            }
                        />

                        <Divider />
                        <Box sx={{ textAlign: 'right', padding: '15px 23px 15px 0px' }}>
                            <Button
                                variant="outlined"
                                disabled={!amount || Number(amount) <= 0}
                                onClick={() => {
                                    onConfirm(builtItem, Number(amount))
                                    onClose()
                                }}
                            >
                                Add to Project
                            </Button>
                        </Box>
                    </Box>
                )}
            </Paper>
        </Dialog>
    )
}
