import { useEffect, useState } from 'react'
import {
    getBinHoldingsRequest,
    getStockItemByIdRequest,
    StockItemRelation,
} from '../api/stock-item'
import { Response } from '../api/util/with-response-formatter-interceptor'
import { BinHolding, Product } from '../models/models'
import { toastFailure } from '../util/toast'

export enum WithBinHoldingsState {
    Loading = 'Loading',
    NotFound = 'NotFound',
    Success = 'Success',
    Error = 'Error',
}

export default function withBinHoldings(
    productId?: number,
    warehouseId?: number
): { product?: Product; state: WithBinHoldingsState } {
    const [state, setState] = useState<WithBinHoldingsState>(WithBinHoldingsState.Loading)
    const [product, setProduct] = useState<Product>()

    const fetchData = async () => {
        if (!productId) {
            return
        }
        const response = await getStockItemByIdRequest(productId, [
            StockItemRelation.Bom,
            StockItemRelation.BuilItemComponents,
        ])

        if (response.successful) {
            const foundProduct = response.data
            const binHoldingPromises = []
            if (foundProduct?.bom) {
                const other = foundProduct.bom
                if (other.bomComponents) {
                    other.bomComponents.forEach((bomComponent, i) => {
                        const otherBuiltItemBinHoldingsPromise = getBinHoldingsRequest(
                            bomComponent.productId,
                            {
                                warehouseId,
                            }
                        ).then((response: Response<BinHolding[]>) => {
                            if (response.successful) {
                                other.bomComponents[i].product.binHoldings = response.data || []
                            }
                        })
                        binHoldingPromises.push(otherBuiltItemBinHoldingsPromise)
                    })
                }
            }
            const productBinHoldingPromise = getBinHoldingsRequest(foundProduct.id, {
                warehouseId,
            }).then((response: Response<BinHolding[]>) => {
                if (response.successful) {
                    foundProduct.binHoldings = response.data || []
                }
            })
            binHoldingPromises.push(productBinHoldingPromise)
            await Promise.all(binHoldingPromises)

            setProduct(foundProduct)
            setState(WithBinHoldingsState.Success)
        } else if (response.status === 404) {
            toastFailure('Could not find the product')
            setState(WithBinHoldingsState.NotFound)
        } else if (response.status === 500) {
            toastFailure('Something went wrong getting the product')
            setState(WithBinHoldingsState.Error)
        } else {
            toastFailure('Something went wrong getting the product')
            setState(WithBinHoldingsState.Error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [productId, warehouseId])

    return { product, state }
}
