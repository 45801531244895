import { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/system'
import { Alert, Button, Divider, Input, InputNumber, Modal, Radio, Table, Tooltip } from 'antd'
import { styled } from '@mui/material'
import { EditOutlined, OrderedListOutlined } from '@ant-design/icons'
import { BomGroup, Product, Project, UnauthorizedBom } from '../../../models/models'
import { UnauthorizedBomComponent } from '../../../models/models'
import {
    UnauthorizedBomRelation,
    getUnauthorizedBomByIdRequest,
} from '../../../api/unauthorized-bom'
import '../../tables/table-styles.css'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { AuthorizeBomDTO, acceptBomAuthorizationRequest } from '../../../api/product-authorization'
import { SearchInput } from '../../ui/SearchInput'
import { getBomGroupByIdRequest, getBomGroupsRequest } from '../../../api/bom-groups'
import {
    GetStockItemQueryParams,
    getProductGroupByIdRequest,
    getStockItemByIdRequest,
    getStockItemsRequest,
} from '../../../api/stock-item'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 50%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

interface Props {
    unauthorizedBomId: number
    isVisible: boolean
    onClose: () => void
    onAuthorized: () => void
}

enum AuthorizationAction {
    NewVersion = 'NewVersion',
    NewProduct = 'NewProduct',
    None = 'None',
}

const INFO_TEXT = {
    [AuthorizationAction.NewVersion]:
        'You are about to authorize a new version of an existing built item. Authorizing will update the bom in CTR, and will then require for you to export the created bom entry into Sage',
    [AuthorizationAction.NewProduct]:
        'You are about to authorize a new built item. Authorizing will create the built item in Sage, and will then require for you to export the created bom entry into Sage',
    [AuthorizationAction.None]: '',
}

interface ProductWithAmount extends Product {
    amount: number
}

enum ComponentSearchOptions {
    Code = 'Code',
    Name = 'Name',
}

const componentSearchOptions: Array<{ label: string; value: ComponentSearchOptions }> = [
    { label: 'Code', value: ComponentSearchOptions.Code },
    { label: 'Name', value: ComponentSearchOptions.Name },
]

export const AuthorizeBomModal = ({
    unauthorizedBomId,
    isVisible,
    onClose,
    onAuthorized,
}: Props) => {
    const [unauthorizedBom, setUnauthorizedBom] = useState<UnauthorizedBom>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const [assemblyMinutes, setAssemblyMinutes] = useState<number>()
    const [description, setDescription] = useState<string>('')
    const [note, setNote] = useState<string>('')
    const [componentSearchOption, setComponentSearchOption] = useState<ComponentSearchOptions>(
        ComponentSearchOptions.Code
    )
    const [components, setComponents] = useState<ProductWithAmount[]>([])
    const [productGroupCode, setProductGroupCode] = useState<string>()
    const [requestedForProject, setRequestedForProject] = useState<Project>()
    const [instructionLink, setInstructionLink] = useState<string>('')
    const [bomGroup, setBomGroup] = useState<BomGroup>()
    const [name, setName] = useState<string>('')

    const product = useMemo(() => {
        return unauthorizedBom?.product || unauthorizedBom?.unauthorizedProduct
    }, [unauthorizedBom])

    const action: AuthorizationAction = useMemo(() => {
        if (!unauthorizedBom) {
            return AuthorizationAction.None
        }
        if (unauthorizedBom.productId) {
            return AuthorizationAction.NewVersion
        }
        return AuthorizationAction.NewProduct
    }, [unauthorizedBom])

    const disableSubmit = () => {
        if (!unauthorizedBom) {
            return true
        }
        if (unauthorizedBom.productId) {
            return !assemblyMinutes
        }
        return !assemblyMinutes || description === '' || name === ''
    }

    const authorize = async () => {
        if (
            !unauthorizedBom ||
            (unauthorizedBom.productId && !assemblyMinutes) ||
            (!unauthorizedBom.productId && (!assemblyMinutes || name === ''))
        ) {
            return
        }

        setModalState(ModalState.Loading)
        if (!unauthorizedBom) {
            setModalState(ModalState.Error)
            toastFailure("Can't authorize when the product has not been retrieved")
            return
        }
        const body: AuthorizeBomDTO = {
            assemblyMinutes: Number(assemblyMinutes),
            bomGroupId: bomGroup?.id,
            components: components,
            instructionLink,
            description,
            name,
        }

        const response = await acceptBomAuthorizationRequest(unauthorizedBom.id, body)
        if (response.successful) {
            onClose()
            toastSuccess('Successfully authorized the built item')
            onAuthorized()
        } else {
            setModalState(ModalState.Error)
            toastFailure(response.message)
        }
    }

    const fetchBuiltItem = () => {
        if (!unauthorizedBomId) {
            return
        }
        return getUnauthorizedBomByIdRequest(unauthorizedBomId, {
            relations: [
                UnauthorizedBomRelation.Components,
                UnauthorizedBomRelation.Product,
                UnauthorizedBomRelation.RequestedForProject,
                UnauthorizedBomRelation.BomGroup,
            ],
        }).then((response) => {
            if (response.successful) {
                setDescription(response.data.unauthorizedProduct?.description || '')
                setName(response.data.unauthorizedProduct?.name || '')
                setAssemblyMinutes(response.data.assemblyMinutes)
                setRequestedForProject(response.data.requestedForProject || undefined)
                setNote(response.data.note || '')
                setInstructionLink(response.data.instructionLink || '')
                setBomGroup(response.data.group)
                setUnauthorizedBom(response.data)
                setModalState(ModalState.Success)
                setComponents(
                    response.data.bomComponents.map((c) => ({ ...c.product, amount: c.amount }))
                )
            } else {
                if (response.status === 404) {
                    toastFailure('The built item does not exist')
                    setModalState(ModalState.NotFound)
                } else {
                    toastFailure('Something went wrong finding the built item')
                    setModalState(ModalState.Error)
                }
            }
        })
    }
    // unauthorized products have a reference to a sage product group, while products have a product group code on the entity
    const getProductGroupCode = async () => {
        if (!product) {
            return
        }

        if ('productGroupCode' in product) {
            return product.productGroupCode
        }

        if (!product.productGroupId) {
            return
        }

        const response = await getProductGroupByIdRequest(product.productGroupId)

        if (!response.successful) {
            toastFailure('Something went wrong finding the product group')
            return
        }

        setProductGroupCode(response.data.code)
    }

    useEffect(() => {
        if (isVisible && unauthorizedBomId) {
            getProductGroupCode()
        }
    }, [product])

    useEffect(() => {
        if (isVisible && unauthorizedBomId) {
            fetchBuiltItem()
        }
    }, [isVisible, unauthorizedBomId])

    return (
        <Modal open={isVisible} footer={null} onCancel={onClose} width={900}>
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.Success && unauthorizedBom && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Authorize Bom</Title>
                    </Header>
                    <Divider />
                    <Alert
                        style={{ marginBottom: '20px' }}
                        message={INFO_TEXT[action]}
                        type="info"
                    />
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Code</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={unauthorizedBom.bomCode}
                                readOnly={true}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Name</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={product?.name}
                                readOnly={true}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Product Group Code</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={productGroupCode}
                                readOnly={true}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Bom Group</Label>
                            <SearchInput
                                style={{ width: '100%' }}
                                placeholder="Bom Group"
                                selectedValue={bomGroup?.id}
                                addonAfter={<EditOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
                                setSelectedValue={(id) => {
                                    if (!id) {
                                        return
                                    }
                                    getBomGroupByIdRequest(id, {}).then((r) => {
                                        if (r.successful) {
                                            setBomGroup(r.data)
                                        }
                                    })
                                }}
                                request={(search: string) =>
                                    getBomGroupsRequest({ name: search }).then((r) =>
                                        r.successful
                                            ? r.data.entities.map((bomGroup) => ({
                                                  label: bomGroup.name,
                                                  value: bomGroup.id,
                                              }))
                                            : [{ label: 'None', value: 2 }]
                                    )
                                }
                            />
                        </RowElement>
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Assembly Minutes</Label>
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0.1}
                                value={assemblyMinutes}
                                addonAfter={
                                    <Tooltip title="The assembly minutes is editable">
                                        <EditOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                                status={!assemblyMinutes || assemblyMinutes > 0 ? '' : 'error'}
                                onChange={(value: number | null) => {
                                    if (!value) {
                                        return
                                    }
                                    setAssemblyMinutes(value)
                                }}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Instructions</Label>
                            <Input
                                style={{ width: '100%' }}
                                suffix={
                                    <Tooltip title="The instruction link is editable">
                                        <EditOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                                value={instructionLink}
                                onChange={(e) => setInstructionLink(e.target.value)}
                            />
                        </RowElement>
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Description</Label>
                            <Input.TextArea
                                style={{ width: '100%', minHeight: 120 }}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </RowElement>
                        <RowElement />
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Note</Label>
                            <Input.TextArea
                                style={{ width: '100%', minHeight: 120 }}
                                value={note}
                                readOnly={true}
                                onChange={(e) => setNote(e.target.value)}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Requested for Project</Label>
                            <Input
                                style={{ width: '100%' }}
                                placeholder="Project"
                                value={requestedForProject?.name ?? 'None'}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px', gap: '' }}>
                        <RowElement>
                            <Label>Components</Label>
                            <SearchInput
                                style={{ width: '100%' }}
                                placeholder="Components"
                                selectedValue={undefined}
                                excludeValues={components.map((af) => af.id)}
                                setSelectedValue={(id) => {
                                    if (!id) {
                                        return
                                    }
                                    getStockItemByIdRequest(id).then((r) => {
                                        if (r.successful) {
                                            setComponents([...components, { ...r.data, amount: 1 }])
                                        }
                                    })
                                }}
                                request={(search: string) => {
                                    const requestConfig: GetStockItemQueryParams = {
                                        excludeIds: components.map((af) => af.id),
                                        limit: 100,
                                    }
                                    if (componentSearchOption === ComponentSearchOptions.Code) {
                                        requestConfig.likeCode = search
                                    }
                                    if (componentSearchOption === ComponentSearchOptions.Name) {
                                        requestConfig.likeName = search
                                    }

                                    return getStockItemsRequest(requestConfig).then(
                                        (r): Array<{ label: string; value: number }> => {
                                            if (!r.successful) {
                                                return [{ label: 'None', value: 2 }]
                                            }

                                            return r.data.entities.map((component) => {
                                                if (
                                                    componentSearchOption ===
                                                    ComponentSearchOptions.Code
                                                ) {
                                                    return {
                                                        label: component.code,
                                                        value: component.id,
                                                    }
                                                } else {
                                                    return {
                                                        label: component.name,
                                                        value: component.id,
                                                    }
                                                }
                                            })
                                        }
                                    )
                                }}
                            />
                        </RowElement>
                        <RowElement style={{ marginTop: 'auto' }}>
                            <Radio.Group
                                options={componentSearchOptions}
                                onChange={(e) => setComponentSearchOption(e.target.value)}
                                value={componentSearchOption}
                                optionType="button"
                                buttonStyle="solid"
                            />
                        </RowElement>
                    </InputRow>
                    <Label>Components</Label>
                    <Table
                        dataSource={components}
                        columns={[
                            {
                                title: 'Code',
                                key: 'code',
                                render: (product: ProductWithAmount) => {
                                    return product?.code
                                },
                            },
                            {
                                title: 'Name',
                                key: 'name',
                                render: (product: ProductWithAmount) => {
                                    return product?.name
                                },
                            },
                            {
                                title: 'Amount',

                                key: 'amount',
                                render: (product: ProductWithAmount) => {
                                    const component = components.find((c) => c.id === product.id)
                                    if (!component) {
                                        return
                                    }
                                    return (
                                        <InputNumber
                                            min={0.01}
                                            value={component.amount}
                                            status={component.amount > 0 ? '' : 'error'}
                                            onChange={(value) => {
                                                if (!value) {
                                                    return
                                                }
                                                setComponents(
                                                    components.map((c) =>
                                                        c.id === product.id
                                                            ? { ...c, amount: value }
                                                            : c
                                                    )
                                                )
                                            }}
                                        />
                                    )
                                },
                            },
                        ]}
                        pagination={false}
                    />
                    <Button disabled={disableSubmit()} onClick={authorize}>
                        Authorize
                    </Button>
                </Box>
            )}
        </Modal>
    )
}
