import { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Input, Modal, Select, Button, Checkbox, InputNumber, Table } from 'antd'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { styled } from '@mui/material'
import '../../tables/table-styles.css'
import { OrderedListOutlined, DeleteOutlined } from '@ant-design/icons'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../store/hooks'
import { ActiveModal, closeModal } from '../../../store/products-slice'
import { CreateAssemblyFieldDto, createAssemblyFieldRequest } from '../../../api/assembly-fields'
import { FieldType } from '../../../models/models'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 47%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

enum ModalState {
    Error = 'Error',
    Ready = 'Ready',
}

export const CreateAssemblyFieldModal = () => {
    const { activeModal, modalEntityId } = useSelector((state: RootState) => state.products)
    const [fieldType, setFieldType] = useState<FieldType>(FieldType.Text)
    const [name, setName] = useState<string>('')
    const [isRequired, setIsRequired] = useState<boolean>(false)
    const [isSerial, setIsSerial] = useState<boolean>(false)
    const [hasNumericValueLimits, setHasNumericValueLimits] = useState<boolean>(false)
    const [numericUpperLimit, setNumericUpperLimit] = useState<number | null>(null)
    const [numericLowerLimit, setNumericLowerLimit] = useState<number | null>(null)
    const [isUnique, setIsUnique] = useState<boolean>(false)
    const [isScannable, setIsScannable] = useState<boolean>(false)
    const [dropdownOptions, setDropdownOptions] = useState<string[]>([])
    const [dropdownOptionInput, setDropdonwOptionInput] = useState<string>('')
    const [modalState, setModalState] = useState<ModalState>(ModalState.Ready)
    const dispatch = useAppDispatch()

    const resetState = () => {
        setFieldType(FieldType.Text)
        setName('')
        setIsRequired(false)
        setHasNumericValueLimits(false)
        setNumericUpperLimit(null)
        setNumericLowerLimit(null)
        setIsUnique(false)
        setIsScannable(false)
        setDropdownOptions([])
        setDropdonwOptionInput('')
        setModalState(ModalState.Ready)
    }

    useEffect(() => {
        if (fieldType !== FieldType.Dropdown) {
            setDropdownOptions([])
            setDropdonwOptionInput('')
        }
        if (fieldType !== FieldType.Numeric) {
            setHasNumericValueLimits(false)
            setNumericUpperLimit(null)
            setNumericLowerLimit(null)
        }
        if (fieldType !== FieldType.Text) {
            setIsScannable(false)
        }
    }, [fieldType])

    const isLimitsValid = () => {
        if (!hasNumericValueLimits) {
            return true
        }
        if (numericUpperLimit === null || numericLowerLimit === null) {
            return false
        }
        return numericUpperLimit > numericLowerLimit
    }

    const showNumericLimitsError = useMemo(() => {
        if (!hasNumericValueLimits || numericUpperLimit === null || numericLowerLimit === null) {
            return false
        }
        return numericUpperLimit <= numericLowerLimit
    }, [hasNumericValueLimits, numericUpperLimit, numericLowerLimit])

    const close = () => {
        setModalState(ModalState.Ready)
        resetState()
        dispatch(closeModal())
    }

    const disableSubmit = !name

    const onOk = async () => {
        const body: CreateAssemblyFieldDto = {
            name,
            isSerial,
            isRequired,
            isUnique,
            isScannable,
            type: fieldType,
        }

        if (fieldType === FieldType.Dropdown) {
            body.dropdownOptions = dropdownOptions
        }

        if (
            fieldType === FieldType.Numeric &&
            numericUpperLimit !== null &&
            numericUpperLimit > 0 &&
            numericLowerLimit !== null &&
            numericLowerLimit >= 0
        ) {
            body.numericUpperLimit = numericUpperLimit
            body.numericLowerLimit = numericLowerLimit
        }

        if (fieldType === FieldType.Text) {
            body.isScannable = isScannable
            body.isSerial = isSerial
        }

        const response = await createAssemblyFieldRequest(body)
        if (!response.successful) {
            toastFailure(response.message)
            return
        }
        toastSuccess(`Created assembly field ${response.data.id}`)
        dispatch(closeModal(JSON.stringify(response.data)))
    }

    return (
        <Modal
            open={activeModal === ActiveModal.AssemblyFieldCreate}
            footer={null}
            onCancel={close}
        >
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.Ready && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Create Assembly Field</Title>
                    </Header>
                    <Divider />
                    <InputRow>
                        <RowElement>
                            <Label>Name</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </RowElement>
                        <RowElement></RowElement>
                    </InputRow>

                    <InputRow style={{ margin: '25px 0px 15px 0px', justifyContent: 'flex-start' }}>
                        <Checkbox
                            checked={isRequired}
                            onChange={(e) => setIsRequired(e.target.checked)}
                        >
                            Required
                        </Checkbox>
                        <Checkbox
                            checked={isUnique}
                            onChange={(e) => setIsUnique(e.target.checked)}
                        >
                            Unique
                        </Checkbox>
                        {fieldType === FieldType.Text && (
                            <Checkbox
                                checked={isSerial}
                                onChange={(e) => setIsSerial(e.target.checked)}
                            >
                                Serial
                            </Checkbox>
                        )}
                        {fieldType === FieldType.Text && (
                            <Checkbox
                                checked={isScannable}
                                onChange={(e) => setIsScannable(e.target.checked)}
                            >
                                Scannable
                            </Checkbox>
                        )}
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Type</Label>
                            <Select
                                style={{ width: '100%' }}
                                value={fieldType}
                                onChange={(value: FieldType) => setFieldType(value)}
                                options={[
                                    { value: FieldType.Text, label: 'Text' },
                                    { value: FieldType.Numeric, label: 'Numeric' },
                                    { value: FieldType.Boolean, label: 'Checkbox' },
                                    { value: FieldType.Dropdown, label: 'Dropdown' },
                                ]}
                            />
                        </RowElement>
                        <RowElement></RowElement>
                    </InputRow>
                    {fieldType === FieldType.Dropdown && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <RowElement>
                                    <Label>Option</Label>
                                    <Input
                                        style={{ width: '100%' }}
                                        value={dropdownOptionInput}
                                        onChange={(e) => setDropdonwOptionInput(e.target.value)}
                                    />
                                </RowElement>
                                <RowElement style={{ marginTop: 'auto' }}>
                                    <Button
                                        onClick={() => {
                                            if (!dropdownOptionInput) {
                                                return
                                            }
                                            setDropdownOptions([
                                                ...dropdownOptions,
                                                dropdownOptionInput,
                                            ])
                                            setDropdonwOptionInput('')
                                        }}
                                    >
                                        Add
                                    </Button>
                                </RowElement>
                            </div>
                            <Table
                                style={{ marginBottom: '20px' }}
                                dataSource={dropdownOptions.map((option, index) => ({
                                    key: index,
                                    option,
                                }))}
                                pagination={false}
                                columns={[
                                    {
                                        title: 'Option',
                                        dataIndex: 'option',
                                        key: 'option',
                                    },
                                    {
                                        title: 'Delete',
                                        key: 'action',
                                        width: 50,
                                        render: (_: any, record: any) => (
                                            <Button
                                                onClick={() => {
                                                    setDropdownOptions(
                                                        dropdownOptions.filter(
                                                            (option) => option !== record.option
                                                        )
                                                    )
                                                }}
                                                type="dashed"
                                                shape="circle"
                                                icon={<DeleteOutlined />}
                                            />
                                        ),
                                    },
                                ]}
                            />
                        </>
                    )}
                    {fieldType === FieldType.Numeric && (
                        <>
                            <InputRow
                                style={{
                                    margin: '25px 0px 15px 0px',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <Checkbox
                                    checked={hasNumericValueLimits}
                                    onChange={(e) => setHasNumericValueLimits(e.target.checked)}
                                >
                                    Has limits
                                </Checkbox>
                            </InputRow>
                            <InputRow>
                                <RowElement>
                                    <Label>Lower limit</Label>
                                    <InputNumber
                                        disabled={!hasNumericValueLimits}
                                        style={{ width: '100%' }}
                                        status={showNumericLimitsError ? 'warning' : undefined}
                                        value={numericLowerLimit}
                                        onChange={(value) => setNumericLowerLimit(value)}
                                    />
                                </RowElement>
                                <RowElement>
                                    <Label>Upper limit</Label>
                                    <InputNumber
                                        disabled={!hasNumericValueLimits}
                                        style={{ width: '100%' }}
                                        status={showNumericLimitsError ? 'warning' : undefined}
                                        value={numericUpperLimit}
                                        onChange={(value) => setNumericUpperLimit(value)}
                                    />
                                </RowElement>
                            </InputRow>
                        </>
                    )}
                    {fieldType === FieldType.Text && <InputRow></InputRow>}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                        <Button type={'primary'} disabled={disableSubmit} onClick={onOk}>
                            Create
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    )
}
