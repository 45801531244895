import { Button, Input, Modal, Table, Typography, Divider } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/styles'
import { toastFailure } from '../../../../util/toast'
import CTRLoader from '../../../ui/loader/CTRloader'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { Assembly } from '../../../../models/models'
import { AssemblyRelation, getAssemblyByIdRequest } from '../../../../api/assemblies'
import { ColumnsType } from 'antd/es/table'
import { v4 as uuidv4 } from 'uuid'
import {
    ModalState,
    closeCompleteFlatstationAssemblyWorksheetModal,
    setActiveAssembly,
} from '../../../../store/assembly-worksheets-slice'
import { useHistory } from 'react-router-dom'
import {
    CompleteAssemblyPallet,
    setCompleteAssemblyView,
} from '../../../../store/warehouse-map-slice'
import { Urls } from '../../../../util/urls'
import { CloseCircleOutlined } from '@ant-design/icons'

const Container = styled('div')({
    margin: '25px',
})

enum AssemblyState {
    Loading = 'Loading',
    Found = 'Found',
    NotFound = 'NotFound',
    Error = 'Error',
}

export enum ModalStep {
    Pallets,
    Confirm,
}

type Pallets = Record<string, string>
type DatasourceObj = { key: string; amount: string }
type Datasource = Array<DatasourceObj>

export function CompleteFlatstationAssemblyModal() {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { assemblyId, state } = useAppSelector(
        (state) => state.assemblyWorksheets.completeFlatstationAssemblyWorksheetModalState
    )
    const { user: currentUser } = useAppSelector((state) => state.authentication)
    const [assemblyState, setAssemblyState] = useState<AssemblyState>(AssemblyState.Loading)
    const [assembly, setAssembly] = useState<Assembly>()
    const [pallets, setPallets] = useState<Pallets>({})

    const addPallet = () => {
        const newPallets = { ...pallets }

        const key = uuidv4()
        newPallets[key] = ''

        setPallets(newPallets)
    }

    const removePallet = (key: string) => {
        const newPallets = { ...pallets }
        delete newPallets[key]
        setPallets(newPallets)
    }

    // make sure that the total amount on all pallets are valid number strings, and that their total value equals the amount on the assembly
    const canConfirm = useMemo(() => {
        if (!assembly) {
            return false
        }
        const palletAmounts = Object.values(pallets)
        if (palletAmounts.length === 0) {
            return false
        }

        const totalAmount = palletAmounts.reduce((acc, curr) => acc + Number(curr), 0)
        if (totalAmount !== assembly.amount) {
            return false
        }
        return palletAmounts.every((amount) => {
            return !Number.isNaN(Number(amount)) && Number(amount) > 0
        })
    }, [pallets, assembly])

    const updatePallet = (key: string, value: string) => {
        if (Number.isNaN(Number(value))) {
            return
        }
        const newPallets = { ...pallets }
        newPallets[key] = value
        setPallets(newPallets)
    }

    const amountOnPallets = useMemo(() => {
        return Object.values(pallets).reduce((acc, curr) => acc + Number(curr), 0)
    }, [pallets])

    const availableAmount = useMemo(() => {
        if (!assembly) {
            return 0
        }
        return assembly.amount - amountOnPallets
    }, [amountOnPallets, assembly])

    const close = () => {
        setPallets({})
        dispatch(closeCompleteFlatstationAssemblyWorksheetModal())
    }

    if (!currentUser) {
        toastFailure('Current user is not present')
        close()
    }

    const confirm = async () => {
        if (!assembly) {
            return
        }
        history.push(Urls.WarehouseMap)
        const completeAssemblyPallets: Array<CompleteAssemblyPallet> = Object.entries(pallets).map(
            ([key, value]): CompleteAssemblyPallet => ({
                key,
                amount: Number(value),
                spot: null,
            })
        )
        dispatch(
            setCompleteAssemblyView({
                assembly,
                pallets: completeAssemblyPallets,
                selectedPallet: null,
            })
        )
        dispatch(setActiveAssembly(null))
    }

    const getAssembly = async () => {
        if (!currentUser || !assemblyId) {
            return
        }
        setAssemblyState(AssemblyState.Loading)
        const response = await getAssemblyByIdRequest(assemblyId, {
            relations: [AssemblyRelation.Bom],
        })
        if (response.successful) {
            if (!response.data) {
                setAssemblyState(AssemblyState.Error)
                toastFailure('The purchase order has not been activated')
                return
            }
            setAssembly(response.data)
            setAssemblyState(AssemblyState.Found)
        } else if (response.status === 404) {
            toastFailure(response.message)
            setAssemblyState(AssemblyState.NotFound)
        } else {
            setAssemblyState(AssemblyState.Error)
            toastFailure(response.message)
        }
    }

    useEffect(() => {
        if (!assemblyId) {
            setAssembly(undefined)
        } else {
            getAssembly()
        }
    }, [assemblyId])

    const columns: ColumnsType<object> = [
        {
            title: 'Flatstation',
            key: 'flatstation',
            render: () => {
                return assembly?.builtItemCallOff.sopBuiltItemOrderline.builtItem.bomCode
            },
        },
        {
            title: 'Amount on Pallet',
            key: 'Amount',
            render: ({ key, amount }: DatasourceObj) => {
                const maxAvailableValue = availableAmount + Number(amount)
                return (
                    <Input
                        type="number"
                        value={amount}
                        disabled={
                            maxAvailableValue <= 0 && (!amount || amount === '' || amount === '0')
                        }
                        onChange={(e) => {
                            const newValue = e.target.value

                            if (newValue === '') {
                                updatePallet(key, newValue)
                                return
                            }
                            if (Number(newValue) > maxAvailableValue) {
                                updatePallet(key, maxAvailableValue.toString())
                            } else {
                                updatePallet(key, newValue)
                            }
                        }}
                    />
                )
            },
        },
        {
            title: '',
            key: 'Remove',
            render: ({ key, amount }: DatasourceObj) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            onClick={() => removePallet(key)}
                            type="dashed"
                            shape="circle"
                            icon={<CloseCircleOutlined />}
                        />
                    </div>
                )
            },
        },
    ]

    const footer = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button disabled={!AssemblyState.Found} onClick={addPallet}>
                Add pallet
            </Button>
            <Button disabled={!canConfirm} onClick={confirm} type="primary">
                Continue
            </Button>
        </div>
    )

    return (
        <Modal
            title={<Typography style={{ fontWeight: 'bold' }}>{'Complete assembly'}</Typography>}
            open={state !== ModalState.Closed}
            width={700}
            onCancel={close}
            footer={footer}
        >
            <Divider />
            <Container>
                {assemblyState === AssemblyState.Loading && <></>}
                {assemblyState === AssemblyState.Error && (
                    <>Something went wrong finding the purchase order</>
                )}
                {assemblyState === AssemblyState.NotFound && <>Could not find the purchase order</>}
                {assemblyState === AssemblyState.Loading && (
                    <>
                        <CTRLoader />
                    </>
                )}
                {assembly && assemblyState === AssemblyState.Found && (
                    <div>
                        <div style={{ display: 'flex', gap: '15px', flexDirection: 'column' }}>
                            <div>
                                <Typography style={{ fontWeight: 'bold' }}>
                                    {'Flatstation'}
                                </Typography>
                                <Typography>
                                    {
                                        assembly.builtItemCallOff.sopBuiltItemOrderline.builtItem
                                            .bomCode
                                    }
                                </Typography>
                            </div>
                            <div>
                                <Typography style={{ fontWeight: 'bold' }}>
                                    {'Amount to build'}
                                </Typography>
                                <Typography>{assembly.amount}</Typography>
                            </div>
                            <div>
                                <Typography style={{ fontWeight: 'bold' }}>
                                    {'Amount on pallets'}
                                </Typography>
                                <Typography>{amountOnPallets}</Typography>
                            </div>
                            <Table
                                size={'small'}
                                dataSource={Object.entries(pallets).map((entry): DatasourceObj => {
                                    const key = entry[0]
                                    const amount = entry[1]
                                    return { key, amount }
                                })}
                                columns={columns}
                                pagination={{ pageSize: 100 }}
                            />
                        </div>
                    </div>
                )}
            </Container>
        </Modal>
    )
}
