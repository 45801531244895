import { ActivityType, AssemblyActivity, Task } from '../models/models'
import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'

const URL_SUFFIX = 'assembly-activities'

export const getUserCurrentActiveAssemblyActivityRequest = (): Promise<
    Response<AssemblyActivity>
> =>
    backendConnection.request({
        url: `/${URL_SUFFIX}/current`,
        method: 'GET',
    })

export interface StartNewActivityDto {
    readonly assemblyId: number
    readonly type: ActivityType
}

export const startNewActiveAssemblyActivityRequest = (
    data: StartNewActivityDto
): Promise<Response<AssemblyActivity>> =>
    backendConnection.request({
        url: `/${URL_SUFFIX}/start`,
        data,
        method: 'POST',
    })
