import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { Input } from 'antd'
import { useState } from 'react'
import {
    getSageSalesOrderByDocumentNoRequest,
    importSageSalesOrderRequest,
    SalesOrderDTO,
} from '../../../../api/sales-orders'
import { Project, WarehouseId } from '../../../../models/models'
import { useAppSelector } from '../../../../store/hooks'
import { ConfirmationModal } from '../../../ui/ConfirmationModal'
import CTRLoader from '../../../ui/loader/CTRloader'
import { toastFailure, toastSuccess } from '../../../../util/toast'
import SalesOrderOrderlineTable from '../SalesOrderOrderlineTable'
import CreateIcon from '@mui/icons-material/Create'

const Container = styled(Box)({})
const RowContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
    justifyContent: 'center',
})
const Label = (props: { text: string; isInvalid?: boolean }) => {
    const { text, isInvalid } = props
    return (
        <Box style={{ width: '270px' }}>
            <Typography style={{ color: '#262626', opacity: '45%' }}>{text}</Typography>
        </Box>
    )
}

enum State {
    Found = 'Found',
    Loading = 'Loading',
    Ready = 'Ready',
}
export default function AssignSalesOrders() {
    const [documentNo, setDocumentNo] = useState<string>('')
    const [state, setState] = useState<State>(State.Ready)
    const [salesOrder, setSalesOrder] = useState<SalesOrderDTO>()
    const [warehouseId, setWarehouseId] = useState<WarehouseId>(WarehouseId.Monument)
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
    const project: Project | null = useAppSelector(
        (state) => state.singleProjectView.projectBeingUpdated
    )
    const projectId = project!.id

    const checkSalesOrder = async () => {
        setState(State.Loading)
        const response = await getSageSalesOrderByDocumentNoRequest(documentNo)
        if (response.successful) {
            setState(State.Found)
            setSalesOrder(response.data)
        } else {
            setState(State.Ready)
            toastFailure(response.message)
        }
    }

    const openModal = () => {
        if (!salesOrder || !warehouseId || !projectId) {
            toastFailure('Not able to send the import request due')
            return
        }
        setIsConfirmationModalOpen(true)
    }

    const closeModal = () => {
        setIsConfirmationModalOpen(false)
    }

    const importSageSalesOrder = async () => {
        if (!salesOrder || !warehouseId || !projectId) {
            toastFailure('Not able to send the import request due')
            return
        }
        setState(State.Loading)
        const response = await importSageSalesOrderRequest({
            documentNo: salesOrder.documentNo,
            warehouseId,
            projectId,
        })
        if (response.successful) {
            toastSuccess(`Completed adding sales order: ${documentNo} to :${project!.name}`)
            setDocumentNo('')
            setState(State.Ready)
        } else {
            setState(State.Ready)
            toastFailure(response.message)
        }
    }

    return (
        <Container>
            {state === State.Loading && (
                <Box sx={{ height: '500px', display: 'flex', justifyContent: 'center' }}>
                    <CTRLoader size="lg" />
                </Box>
            )}
            {state === State.Ready && (
                <RowContainer>
                    <Box
                        sx={{
                            width: '35%',
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '100px',
                            marginBottom: '100px',
                        }}
                    >
                        <Label text="Document No" />
                        <Input
                            autoComplete="off"
                            size="large"
                            type="text"
                            name="name"
                            value={documentNo}
                            onChange={(e) => setDocumentNo(e.target.value)}
                        />
                        <Tooltip title="Find the sales order in sage through the document number.">
                            <Button
                                variant="outlined"
                                onClick={checkSalesOrder}
                                disabled={documentNo.length < 6}
                            >
                                Check
                            </Button>
                        </Tooltip>
                    </Box>
                </RowContainer>
            )}
            {state === State.Found && salesOrder && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: '50px',
                            gap: '10px',
                            marginBottom: '30px',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button variant="outlined" onClick={() => setState(State.Ready)}>
                            Cancel import
                        </Button>
                        <Button variant="outlined" onClick={openModal}>
                            Import to project
                        </Button>
                    </Box>
                    <Box>
                        <h3>Sales Order</h3>
                        <FormControl>
                            <InputLabel id="warehouse-label">Warehouse</InputLabel>
                            <Select
                                label="warehouse"
                                labelId="warehouse-label"
                                id="demo-simple-select"
                                value={warehouseId}
                                onChange={(
                                    event: SelectChangeEvent<WarehouseId>,
                                    child: React.ReactNode
                                ) => setWarehouseId(event.target.value as WarehouseId)}
                            >
                                <MenuItem value={WarehouseId.Monument}>Monument</MenuItem>
                                <MenuItem value={WarehouseId.Scandia}>Scandia</MenuItem>
                            </Select>
                        </FormControl>
                        <Box>
                            <TextField
                                key={'Document Number'}
                                id="outlined-basic"
                                variant="outlined"
                                label="Document Number"
                                InputProps={{
                                    readOnly: true,
                                }}
                                defaultValue={
                                    salesOrder?.documentNo || 'Could not find document number'
                                }
                            />
                            <TextField
                                key={'Total net value'}
                                id="outlined-basic"
                                variant="outlined"
                                label="Total net value"
                                InputProps={{
                                    readOnly: true,
                                }}
                                defaultValue={
                                    salesOrder?.totalNetValue || 'Could not find total net value'
                                }
                            />
                            <TextField
                                key="Total Tax Value"
                                id="outlined-basic"
                                variant="outlined"
                                label="Total Tax Value"
                                InputProps={{
                                    readOnly: true,
                                }}
                                defaultValue={
                                    salesOrder?.totalTaxValue || 'Could not find total tax value'
                                }
                            />
                            <TextField
                                key={'Customer document Number'}
                                id="outlined-basic"
                                variant="outlined"
                                label="Customer document Number"
                                InputProps={{
                                    readOnly: true,
                                }}
                                defaultValue={
                                    salesOrder?.customerDocumentNo ||
                                    'Could not find customer document number'
                                }
                            />
                            <TextField
                                key={'Document Status'}
                                id="outlined-basic"
                                variant="outlined"
                                label="Document Status"
                                InputProps={{
                                    readOnly: true,
                                }}
                                defaultValue={
                                    salesOrder?.documentStatus ||
                                    'Could not find the document status'
                                }
                            />
                            <TextField
                                key={'Document Status'}
                                id="outlined-basic"
                                variant="outlined"
                                label="Document Status"
                                InputProps={{
                                    readOnly: true,
                                }}
                                defaultValue={
                                    salesOrder?.documentStatus ||
                                    'Could not find the document status'
                                }
                            />
                            <TextField
                                key={'Customer'}
                                id="outlined-basic"
                                variant="outlined"
                                label="Customer"
                                InputProps={{
                                    readOnly: true,
                                }}
                                defaultValue={
                                    salesOrder?.customer?.name || 'Could not find customer name'
                                }
                            />
                            <TextField
                                key={'On Hold'}
                                id="outlined-basic"
                                variant="outlined"
                                label="On Hold status"
                                InputProps={{
                                    readOnly: true,
                                }}
                                defaultValue={
                                    salesOrder.customer?.onHold === undefined
                                        ? 'Could not find whether or not customer was on hold'
                                        : salesOrder.customer.onHold
                                        ? 'Yes'
                                        : 'No'
                                }
                            />
                        </Box>
                        <h3>Orderlines</h3>
                        <Box>
                            <SalesOrderOrderlineTable salesOrder={salesOrder} />
                        </Box>
                    </Box>
                </Box>
            )}
            <ConfirmationModal
                title={'Import sales order'}
                text={`Are you sure you want to import the sales order, considering following details? \n
                Document Number: ${salesOrder?.documentNo}\n
                Project: ${project?.name} \n
                Warehouse: ${warehouseId === WarehouseId.Monument ? 'Monument' : 'Scandia'}`}
                confirmIcon={<CreateIcon style={{ marginRight: '4px' }} />}
                confirmText={'Import'}
                isOpen={isConfirmationModalOpen}
                onClose={closeModal}
                onConfirm={importSageSalesOrder}
            />
        </Container>
    )
}
