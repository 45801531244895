import { Input, Modal, Select, Typography } from 'antd'
import { Box, Button } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
    closeEntityModal,
    EntityModalAttribute,
    EntityModalMode,
    invalidEntityModalModalFieldsView,
    saveEntityModal,
    showEntityModalValidation,
    TableType,
    updateEntityModalState,
} from '../../../store/single-project-view-slice'
import CustomersTable from './CustomersTable'
import MuiPhoneNumber from 'material-ui-phone-number'

interface LabelProps {
    text: string
    isInvalid: boolean
}

const Label = ({ text, isInvalid }: LabelProps) => {
    return <Typography style={{ color: isInvalid ? 'red' : 'inherit' }}>{text}</Typography>
}

interface InputSectionProps {
    label: string
    showInvalid: boolean
    children: JSX.Element
}

const InputSection = ({ label, showInvalid, children }: InputSectionProps) => {
    return (
        <Box sx={{ display: 'flex', marginBottom: '15px' }}>
            <Box sx={{ width: '150px', display: 'flex', alignItems: 'center' }}>
                <Label text={label} isInvalid={showInvalid} />
            </Box>
            <Box sx={{ flexGrow: 1 }}>{children}</Box>
        </Box>
    )
}

export default function CreateNewEntityModal() {
    const dispatch = useAppDispatch()

    const {
        showValidation,
        title,
        visible,
        fields,
        name,
        selectedCustomer,
        type,
        typeOptions,
        hexColor,
        mode,
        email,
        phoneNumber,
        search,
        tableOptions,
        address,
    } = useAppSelector((state) => state.singleProjectView.entityModal)

    const invalidFields = useAppSelector(invalidEntityModalModalFieldsView)

    const onConfirm = async () => {
        if (invalidFields.size) {
            dispatch(showEntityModalValidation())
            return
        }

        await dispatch(saveEntityModal())
    }

    return (
        <Modal
            title={title}
            visible={visible}
            onCancel={() => dispatch(closeEntityModal())}
            width={mode === EntityModalMode.Customer ? '800px' : '500px'}
            footer={
                <Box>
                    <Button
                        onClick={() => dispatch(closeEntityModal())}
                        color="secondary"
                        sx={{ margin: '5px' }}
                        variant="outlined"
                    >
                        Back
                    </Button>
                    <Button
                        onClick={onConfirm}
                        disabled={showValidation && invalidFields.size > 0}
                        sx={{ margin: '5px' }}
                        variant="outlined"
                    >
                        Confirm
                    </Button>
                </Box>
            }
        >
            {fields.includes(EntityModalAttribute.Name) && (
                <InputSection
                    label={'Name'}
                    showInvalid={showValidation && invalidFields.has(EntityModalAttribute.Name)}
                >
                    <Input
                        size={'large'}
                        type={'text'}
                        name={'name'}
                        value={name}
                        onChange={(e) =>
                            dispatch(
                                updateEntityModalState({
                                    name: e.target.value,
                                })
                            )
                        }
                    />
                </InputSection>
            )}

            {fields.includes(EntityModalAttribute.HexColor) && (
                <InputSection
                    label={'Color'}
                    showInvalid={showValidation && invalidFields.has(EntityModalAttribute.HexColor)}
                >
                    <Input
                        size={'large'}
                        type={'color'}
                        name={'hexColor'}
                        value={hexColor}
                        onChange={(e) =>
                            dispatch(
                                updateEntityModalState({
                                    hexColor: e.target.value,
                                })
                            )
                        }
                    />
                </InputSection>
            )}

            {fields.includes(EntityModalAttribute.Email) && (
                <InputSection
                    label={'Email'}
                    showInvalid={showValidation && invalidFields.has(EntityModalAttribute.Email)}
                >
                    <Input
                        size={'large'}
                        type={'email'}
                        name={'email'}
                        value={email}
                        onChange={(e) =>
                            dispatch(
                                updateEntityModalState({
                                    email: e.target.value,
                                })
                            )
                        }
                    />
                </InputSection>
            )}

            {fields.includes(EntityModalAttribute.PhoneNumber) && (
                <InputSection
                    label={'Phone number'}
                    showInvalid={
                        showValidation && invalidFields.has(EntityModalAttribute.PhoneNumber)
                    }
                >
                    <MuiPhoneNumber
                        defaultCountry={'gb'}
                        value={String(
                            phoneNumber?.charAt(0) === '+' ? phoneNumber : `+44 ${phoneNumber}`
                        )}
                        onChange={(e) =>
                            dispatch(
                                updateEntityModalState({
                                    phoneNumber: e.toString(),
                                })
                            )
                        }
                    />
                </InputSection>
            )}

            {fields.includes(EntityModalAttribute.Address) && (
                <InputSection
                    label={'Address'}
                    showInvalid={showValidation && invalidFields.has(EntityModalAttribute.Address)}
                >
                    <Input.TextArea
                        style={{ height: '110px' }}
                        size={'large'}
                        name={'address'}
                        value={address}
                        onChange={(e) =>
                            dispatch(
                                updateEntityModalState({
                                    address: e.target.value,
                                })
                            )
                        }
                    />
                </InputSection>
            )}

            {fields.includes(EntityModalAttribute.Type) && (
                <InputSection
                    label={'Type'}
                    showInvalid={showValidation && invalidFields.has(EntityModalAttribute.Type)}
                >
                    <Select
                        size={'large'}
                        style={{ width: '100%' }}
                        value={type || ''}
                        onChange={(value) =>
                            dispatch(
                                updateEntityModalState({
                                    type: value,
                                })
                            )
                        }
                    >
                        {Object.entries(typeOptions).map(([value, label]) => (
                            <Select.Option key={value} value={value}>
                                {label}
                            </Select.Option>
                        ))}
                    </Select>
                </InputSection>
            )}

            {fields.includes(EntityModalAttribute.Search) && (
                <InputSection
                    label={'Name/Reference search'}
                    showInvalid={showValidation && invalidFields.has(EntityModalAttribute.Type)}
                >
                    <Input
                        size={'large'}
                        type={'tel'}
                        name={'Name/Reference search'}
                        disabled={selectedCustomer !== null}
                        value={search}
                        onChange={(e) =>
                            dispatch(
                                updateEntityModalState({
                                    search: e.target.value,
                                    tableOptions: {
                                        ...tableOptions,
                                        page: 0,
                                    },
                                })
                            )
                        }
                    />
                </InputSection>
            )}

            {tableOptions?.type === TableType.AssignCustomer && <CustomersTable />}
        </Modal>
    )
}
