import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { Customer, Organisation, Project, User } from '../models/models'
import _ from 'lodash'
import { isValid } from 'postcode'

interface FormState {
    opportunityReference?: string
    projectName?: string
    opportunityStatusId?: number
    valueInGBP?: number
    postcode?: string
    address?: string
    accountManagerUserIds: number[]
    applicationsEngineerUserIds: number[]
    projectManagerUserIds: number[]
    creditStatusId?: number
    clientOrganisationIds: number[]
    consultantOrganisationIds: number[]
    contractorOrganisationIds: number[]
    customer?: Customer
}

interface State {
    showValidation: boolean
    selectedOrganisationsMap: { [key: number]: Organisation }
    formState: FormState
}

const initialFormState: FormState = {
    opportunityReference: undefined,
    projectName: undefined,
    opportunityStatusId: undefined,
    valueInGBP: undefined,
    postcode: undefined,
    address: undefined,
    applicationsEngineerUserIds: [],
    accountManagerUserIds: [],
    projectManagerUserIds: [],
    creditStatusId: undefined,
    customer: undefined,
    clientOrganisationIds: [],
    consultantOrganisationIds: [],
    contractorOrganisationIds: [],
}

const initialState: State = {
    showValidation: false,
    formState: { ...initialFormState },
    selectedOrganisationsMap: {},
}

export const projectInfoSlice = createSlice({
    name: 'projectInfo',
    initialState,
    reducers: {
        showFormValidation(state) {
            state.showValidation = true
        },

        addOrganizations(state, action: PayloadAction<Organisation[]>) {
            const newValue = { ...state.selectedOrganisationsMap }
            action.payload.forEach((organisation) => {
                newValue[organisation.id] = organisation
            })

            state.selectedOrganisationsMap = { ...newValue }
        },

        resetFormState(state) {
            state.formState = { ...initialFormState }
        },

        updateFormState(state, action) {
            Object.entries(action.payload).forEach(([key, value]) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // we normalize empty values to make it easier to detect when the form has changes
                state.formState[key] = value === undefined ? null : value
            })
        },

        updateFormStateFromProject(state, action) {
            const project: Project = action.payload

            state.formState = createAttributesFromProject(project)

            const newValue: { [key: number]: Organisation } = { ...state.selectedOrganisationsMap }
            project.clients.forEach((organisation) => {
                newValue[organisation.id] = organisation
            })

            project.consultants.forEach((organisation) => {
                newValue[organisation.id] = organisation
            })

            project.contractors.forEach((organisation) => {
                newValue[organisation.id] = organisation
            })

            state.selectedOrganisationsMap = { ...newValue }
        },

        resetForm(state) {
            state.formState = { ...initialFormState }
            state.selectedOrganisationsMap = {}
        },
    },
})

export const createAttributesFromProject = (project: Project): FormState => {
    return {
        opportunityReference: project.opportunityReference,
        projectName: project.name,
        opportunityStatusId: project.opportunityStatus?.id,
        valueInGBP: project.valueInGBP,
        postcode: project.postcode,
        address: project.address,
        accountManagerUserIds: project.accountManagers.map((user: User) => user.id),
        applicationsEngineerUserIds: project.applicationsEngineers.map((user: User) => user.id),
        projectManagerUserIds: project.projectManagers.map((user: User) => user.id),
        customer: project.customer,

        creditStatusId: project.creditStatus?.id,
        clientOrganisationIds: project?.clients?.map((client) => client.id),

        consultantOrganisationIds: project?.consultants?.map((consultant) => consultant.id),

        contractorOrganisationIds: project?.contractors?.map((contractor) => contractor.id),
    }
}

export const isOpportunityReferenceInvalidView = (state: RootState) => {
    const value = state.projectInfo.formState.opportunityReference
    return !value || value.trim().length < 1
}

export const isProjectNameInvalidView = (state: RootState) => {
    const value = state.projectInfo.formState.projectName
    return !value || value.trim().length < 1
}

export const isFormInvalidView = (state: RootState): boolean => {
    const opportunityReference = state.projectInfo.formState.opportunityReference
    const projectName = state.projectInfo.formState.projectName
    const postcode = state.projectInfo.formState.postcode

    return (
        !opportunityReference ||
        opportunityReference.trim().length < 1 ||
        !projectName ||
        projectName.trim().length < 1 ||
        state.projectInfo.formState.accountManagerUserIds.length < 1 ||
        state.projectInfo.formState.applicationsEngineerUserIds.length < 1 ||
        (!!postcode && !isValid(postcode ?? ''))
    )
}

export const formHasChangesView = (state: RootState) => {
    const currentState = state.projectInfo.formState
    const initialState = state.singleProjectView.projectBeingUpdated
        ? createAttributesFromProject(state.singleProjectView.projectBeingUpdated)
        : { ...initialFormState }

    return !_.isEqual(initialState, currentState)
}

export const {
    updateFormState,
    updateFormStateFromProject,
    resetFormState,
    showFormValidation,
    addOrganizations,
    resetForm,
} = projectInfoSlice.actions

export default projectInfoSlice.reducer
