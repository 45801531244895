import { Table } from 'antd'
import { PurchaseOrder, PurchaseOrderOrderline } from '../../models/models'

export default function PurchaseOrderOrderlineTable({
    purchaseOrder,
}: {
    purchaseOrder: PurchaseOrder
}) {
    //whether or not the purchase order orderline amounts
    const hasAmounts = !!purchaseOrder?.orderlines.find((orderline: PurchaseOrderOrderline) => {
        return orderline.allocatedAmount !== undefined
    })
    const columns = [
        {
            title: 'Orderline Type',
            dataIndex: 'lineType',
            key: 'lineType',
        },
        {
            title: 'code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Amount',
            key: 'Amount',
            render: (orderline: PurchaseOrderOrderline) => {
                if (orderline?.lineQuantity !== undefined) {
                    return orderline.lineQuantity
                }
                return 'Indecisive'
            },
        },
    ]

    if (hasAmounts) {
        columns.push({
            title: 'Allocated',
            key: 'Allocated',
            render: (orderline: PurchaseOrderOrderline) => {
                if (orderline?.lineQuantity !== undefined) {
                    return `${orderline.allocatedAmount}/${orderline.lineQuantity}`
                }
                return 'Indecisive'
            },
        } as any)
        columns.push({
            title: 'Delivered',
            key: 'Delivered',
            render: (orderline: PurchaseOrderOrderline) => {
                if (orderline?.lineQuantity !== undefined) {
                    return `${orderline.deliveredAmount}/${orderline.lineQuantity}`
                }
                return 'Indecisive'
            },
        } as any)
    }

    return (
        <Table
            size={'small'}
            dataSource={
                purchaseOrder?.orderlines?.sort((a, b) => a.lineNumber - b.lineNumber) || []
            }
            columns={columns}
            pagination={{ pageSize: 100 }}
        />
    )
}
