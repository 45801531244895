import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { DeliverySchedule } from './DeliverySchedule'
import { Tabs, TabsProps } from 'antd'
import ViewCallOffs from './ViewCallOffs'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
    ActiveModal,
    ActiveView,
    closeModal,
    fetchEntitites,
    resetState,
    setActiveView,
} from '../../../../store/project-call-off-slice'
import { RootState } from '../../../../store/store'
import { useEffect } from 'react'
import { Project } from '../../../../models/models'
import UpdateCallOffModal from '../../../modals/update-call-off-modal/UpdateCallOff'
import InspectCallOffModal from '../../../modals/inspect-call-off-modal/InspectCallOffModal'
import CreateAssemblyModal from './view-call-off/modals/CreateAssembly'

const ContentContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
}))

export default function CallOff() {
    const dispatch = useAppDispatch()
    const { activeModal, activeView, modalEntityId } = useAppSelector(
        (state: RootState) => state.projectCallOffs
    )
    const project: Project | null = useAppSelector(
        (state) => state.singleProjectView.projectBeingUpdated
    )
    const tabsOptions: TabsProps['items'] = [
        {
            key: ActiveView.DeliverySchedule,
            label: `Delivery Schedule`,
            children: <DeliverySchedule />,
        },
        {
            key: ActiveView.List,
            label: `List`,
            children: <ViewCallOffs />,
        },
    ]

    useEffect(() => {
        dispatch(resetState())
        if (project) {
            dispatch(fetchEntitites(project))
        }
    }, [project])

    if (!project) return null

    return (
        <Box>
            <ContentContainer>
                <Tabs
                    style={{ width: '100%' }}
                    type="card"
                    activeKey={activeView}
                    onChange={(view) => {
                        dispatch(setActiveView(view as ActiveView))
                    }}
                    defaultActiveKey={ActiveView.DeliverySchedule}
                    items={tabsOptions}
                />
                {modalEntityId && activeModal === ActiveModal.Inspect && (
                    <InspectCallOffModal
                        callOffId={modalEntityId}
                        isOpen={activeModal === ActiveModal.Inspect}
                        closeModal={() => {
                            dispatch(closeModal())
                        }}
                    />
                )}
                {modalEntityId && activeModal === ActiveModal.Update && (
                    <UpdateCallOffModal
                        onUpdate={() => dispatch(fetchEntitites(project))}
                        callOffId={modalEntityId}
                        onClose={() => {
                            dispatch(closeModal())
                        }}
                    />
                )}
                {modalEntityId && activeModal === ActiveModal.CreateAssembly && (
                    <CreateAssemblyModal
                        callOffId={modalEntityId}
                        onClose={() => {
                            dispatch(closeModal())
                        }}
                    />
                )}
            </ContentContainer>
        </Box>
    )
}
