import React, { useState } from 'react'
import { Tooltip } from 'antd'

interface Props {
    text: string
    condition: boolean
    children: React.ReactNode
}

export const ConditionalToolTip: React.FC<Props> = ({ text, condition, children }) => {
    const [visible, setVisible] = useState(false)

    const handleMouseOver = () => {
        if (condition) {
            setVisible(true)
        }
    }

    const handleMouseOut = () => {
        setVisible(false)
    }

    return (
        <Tooltip
            getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
            title={text}
            visible={visible}
        >
            <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                {children}
            </div>
        </Tooltip>
    )
}
