import { Button } from 'antd'
import {
    UnauthorizedBomRelation,
    getManyUnauthorizedBomsRequest,
    updateUnauthorizedBomNoteRequest,
} from '../../../api/unauthorized-bom'
import { UnauthorizedBom } from '../../../models/models'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import UnauthorizedBomsTable, {
    UnauthorizedBomsTableColumns,
} from '../../tables/UnauthorizedBomsTable'
import '../antd-tables.css'
import { BomsTopBar } from './BomsTopBar'
import { denyAuthorizationBomRequest } from '../../../api/product-authorization'
import { toastFailure, toastSuccess } from '../../../util/toast'
import {
    ActiveModal,
    openModal,
    setUpdateTableToken,
} from '../../../store/product-authorization-slice'
import { TableTextareaInput } from '../../worksheets/assembly/active-assembly/TableInputs'
import { useState } from 'react'
import { ConfimrationModal } from '../../modals/genral-common-modal/confirmationModal'

export default function ProductAuthorizationBoms() {
    const { bomFilters, updateTableToken } = useAppSelector((state) => state.productAuthorization)
    const dispatch = useAppDispatch()

    const [checkConfirm, SetCheckConfirm] = useState<boolean>(false)
    const [prodId, SetProdId] = useState<number | undefined>()
    const [confirmAction, SetConfirmAction] = useState<'accept' | 'deny' | 'none'>()

    const confirm = (prodId: number, confirmAction: 'accept' | 'deny' | 'none') => {
        SetProdId(prodId)
        SetConfirmAction(confirmAction)
        SetCheckConfirm(true)
    }

    const performConfirmAction = () => {
        if (confirmAction === 'deny') {
            prodId ? deny(prodId) : null
        }
    }

    const confirmReset = () => {
        SetProdId(undefined)
        SetConfirmAction('none')
        SetCheckConfirm(false)
    }

    const deny = async (unauthorizedBomId: number) => {
        const response = await denyAuthorizationBomRequest(unauthorizedBomId)
        confirmReset()
        if (response.successful) {
            toastSuccess('Successfully removed the unauthorized bom')
            dispatch(setUpdateTableToken(`deny-${unauthorizedBomId}`))
        } else {
            toastFailure(response.message)
        }
    }

    return (
        <div>
            <ConfimrationModal
                isVisible={checkConfirm}
                action={performConfirmAction}
                onClose={confirmReset}
            ></ConfimrationModal>
            <BomsTopBar />
            <UnauthorizedBomsTable
                pagination={true}
                actionComponents={[
                    (unauthorizedBom: UnauthorizedBom) => {
                        return (
                            <Button
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            activeModal: ActiveModal.InspectUnauthorizedBom,
                                            modalEntityId: unauthorizedBom.id,
                                        })
                                    )
                                }
                            >
                                Inspect
                            </Button>
                        )
                    },
                    (unauthorizedBom: UnauthorizedBom) => {
                        return (
                            <Button
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            activeModal: ActiveModal.AuthorizeBomModal,
                                            modalEntityId: unauthorizedBom.id,
                                        })
                                    )
                                }
                            >
                                Authorize
                            </Button>
                        )
                    },
                    (unauthorizedBom: UnauthorizedBom) => {
                        return (
                            <Button onClick={() => confirm(unauthorizedBom.id, 'deny')}>
                                Deny
                            </Button>
                        )
                    },
                ]}
                columns={[
                    UnauthorizedBomsTableColumns.Code,
                    UnauthorizedBomsTableColumns.Description,
                    UnauthorizedBomsTableColumns.Name,
                    UnauthorizedBomsTableColumns.RequestedBy,
                    UnauthorizedBomsTableColumns.RequestedFor,
                    UnauthorizedBomsTableColumns.RequestedAt,
                    {
                        title: 'Note',
                        render: (unauthorizedBom: UnauthorizedBom) => {
                            return (
                                <TableTextareaInput
                                    active
                                    placeholder="No note"
                                    value={unauthorizedBom.note}
                                    maxLength={60}
                                    maxLengthError={`The note must not be longer than 60 characters long.`}
                                    noNewlines
                                    onSave={async (value, afterSave) => {
                                        if (value !== null) {
                                            await updateUnauthorizedBomNoteRequest(
                                                unauthorizedBom.id,
                                                value
                                            )
                                            dispatch(
                                                setUpdateTableToken(
                                                    `deny-${unauthorizedBom.id}${unauthorizedBom.note}`
                                                )
                                            )
                                        }
                                        afterSave()
                                    }}
                                />
                            )
                        },
                    },
                ]}
                dependencies={{ bomFilters, updateTableToken }}
                request={(pagination) => {
                    return getManyUnauthorizedBomsRequest({
                        withCount: true,
                        relations: [
                            UnauthorizedBomRelation.Components,
                            UnauthorizedBomRelation.Product,
                            UnauthorizedBomRelation.RequestedByUser,
                            UnauthorizedBomRelation.RequestedForProject,
                        ],
                        onlyUnauthorized: true,
                        code: bomFilters.code,
                        //antd tables pages are 1-indexed
                        skip: pagination?.skip,
                        limit: pagination?.limit,
                    })
                }}
            />
        </div>
    )
}
