import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

export interface CallOffState {
    activeView: CallOffView
    callOffs: any
}

export type CallOffView = 'CREATE' | 'VIEW'

const initialState: CallOffState = {
    activeView: 'VIEW',
    callOffs: undefined,
}

export const callOffSlice = createSlice({
    name: 'callOff',
    initialState,
    reducers: {
        resetState(state) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state = initialState
        },
        setActiveView(state, action: PayloadAction<CallOffView>) {
            state.activeView = action.payload
        },
    },
})

export const selectActiveView = (state: RootState) => state.callOff.activeView

export const { resetState, setActiveView } = callOffSlice.actions

export default callOffSlice.reducer
