import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import {
    Assembly,
    AssemblyLine,
    GeneralAssembly,
    GeneralAssemblyLine,
    InsulationTestValue,
    User,
    UserRole,
    WarehouseId,
} from '../models/models'
import { getUsersRequest } from '../api/users'
import { toastFailure, toastSuccess } from '../util/toast'
import moment, { Moment } from 'moment'
import { RootState } from './store'
import { setRegularView } from './warehouse-map-slice'
import { FailedResponse, SuccessfulResponse } from '../api/util/with-response-formatter-interceptor'
import {
    completeGeneralAssemblyRequest,
    GeneralAssemblyRelation,
    GetGeneralAssembliesDto,
    getGeneralAssembliesRequest,
    getGeneralAssemblyByIdRequest,
} from '../api/general-assemblies'
import {
    UpdateGeneralAssemblyLineDto,
    updateGeneralAssemblyLineRequest,
} from '../api/general-assembly-lines'
import {
    CompleteAssemblyFlatstationPalletDto,
    completeFlatstationWorksheetRequest,
    completeWorksheetRequest,
    getAssemblyWorksheetRequest,
    getAssemblyWorksheets,
    GetAssemblyWorksheetsDTO,
    startAssemblyWorksheetRequest,
    updateWorksheetRequest,
} from '../api/assemblies'
import { completeWorksheetLineRequest, validateWorksheetLineRequest } from '../api/assembly-line'
import { updateAssemblyLineFieldValueRequest } from '../api/assembly-line-field'

export enum ModalState {
    Closed,
    Opened,
    Loading,
    Ready,
    Error,
}

/**
 * Poor fix to make sure that the assembly worksheet id is refreshed when the user refreshes the page due to QR scanner bug
 */
export const LOCAL_STORAGE_ASSEMBLY_WORKSHEET_ID_REFRESH_KEY = 'AssemblyWorksheetIdKey'

export type AssemblyStatusKey = 'NotStarted' | 'InProgress' | 'Completed'

export const getGeneralAssemblyStatus = (generalAssembly: GeneralAssembly): AssemblyStatusKey => {
    if (!generalAssembly.lines) {
        toastFailure('Cannot get general assembly status when lines are undefined')
        return 'NotStarted'
    }
    if (generalAssembly.completedAt) {
        return 'Completed'
    }
    return generalAssembly.lines.find((l) => l.completedAt) ? 'InProgress' : 'NotStarted'
}

export const getAssemblyStatus = (assembly: Assembly): AssemblyStatusKey => {
    if (assembly.completedAt) {
        return 'Completed'
    } else if (assembly.startedAt) {
        return 'InProgress'
    } else {
        return 'NotStarted'
    }
}
export const AssemblyStatusBackgrounds: Record<AssemblyStatusKey, string> = {
    NotStarted: '#3500a0',
    InProgress: '#939393',
    Completed: '#00c246',
}

export const GeneralAssemblyStatusBackground: Record<AssemblyStatusKey, string> = {
    NotStarted: '#3500a0',
    InProgress: '#939393',
    Completed: '#00c246',
}

export interface StartAssemblyModalState {
    isVisible: boolean
    isLoading: boolean
    assembly: Assembly | null
}

const initialStartAssemblyModalState: StartAssemblyModalState = {
    isVisible: false,
    isLoading: false,
    assembly: null,
}

export interface StartAssemblyActivityModalState {
    state: ModalState
    assemblyId: number | null
}

const initialStartAssemblyActivityModalState: StartAssemblyActivityModalState = {
    state: ModalState.Closed,
    assemblyId: null,
}

export interface CompleteAssemblyLineModalState {
    state: ModalState
    assemblyLineId: number | null
}

const initialCompleteAssemblyLineModalState: CompleteAssemblyLineModalState = {
    state: ModalState.Closed,
    assemblyLineId: null,
}

export interface CompleteFlatstationAssemblyWorksheetModalState {
    state: ModalState
    assemblyId: number | null
}

const initialCompleteFlatstationAssemblyWorksheetModalState: CompleteFlatstationAssemblyWorksheetModalState =
    {
        state: ModalState.Closed,
        assemblyId: null,
    }

export interface StartGeneralAssemblyActivityModalState {
    state: ModalState
    generalAssemblyId: number | null
}

const initialStartGeneralAssemblyActivityModalState: StartGeneralAssemblyActivityModalState = {
    state: ModalState.Closed,
    generalAssemblyId: null,
}

export interface State {
    isLoading: boolean
    assemblies: Assembly[]
    generalAssemblies: GeneralAssembly[]
    startAssemblyModal: StartAssemblyModalState
    completeFlatstationAssemblyWorksheetModalState: CompleteFlatstationAssemblyWorksheetModalState
    startGeneralAssemblyActivityModalState: StartGeneralAssemblyActivityModalState
    startAssemblyActivityModalState: StartAssemblyActivityModalState
    completeAssemblyLineModalState: CompleteAssemblyLineModalState
    filterStartDate: number
    filterEndDate: number
    filterWarehouseId: WarehouseId
    filterProjectIds: number[]
    filterAssemblerId: number | undefined
    filterIsOnHold: boolean
    filterStatus: AssemblyStatusKey | undefined
    assemblers: User[]
    activeAssembly: Assembly | null
    activeGeneralAssembly: GeneralAssembly | null
}

const initialState: State = {
    filterStartDate: moment().startOf('day').unix(),
    filterEndDate: moment().startOf('day').add(7, 'days').unix(),
    filterWarehouseId: WarehouseId.Monument,
    completeAssemblyLineModalState: initialCompleteAssemblyLineModalState,
    completeFlatstationAssemblyWorksheetModalState:
        initialCompleteFlatstationAssemblyWorksheetModalState,
    startGeneralAssemblyActivityModalState: initialStartGeneralAssemblyActivityModalState,
    startAssemblyActivityModalState: initialStartAssemblyActivityModalState,
    filterProjectIds: [],
    filterAssemblerId: undefined,
    filterIsOnHold: false,
    filterStatus: undefined,
    isLoading: true,
    assemblies: [],
    generalAssemblies: [],
    assemblers: [],
    startAssemblyModal: { ...initialStartAssemblyModalState },
    activeAssembly: null,
    activeGeneralAssembly: null,
}

//get array to use for dropdown
export const ALL_MEGGER_VALUES = [
    '101586440',
    '102047199',
    '101961925',
    '102047197',
    '101766161',
    '101961924',
] as const

//get it as tuple so can get union type
export type MeggerTuple = typeof ALL_MEGGER_VALUES

//union type for dropdown
export type MeggerValues = MeggerTuple[number]

export interface ValidationSuiteAttributes {
    note?: string | null
    visualCheck?: boolean | null
    flyingLeadsCheck?: boolean | null
    meggerMIT3000SN?: MeggerValues
    continuityTestEarthPumpBody?: number | null
    continuityTestEarthGuruEarth?: number | null
    continuityTestEarthFrame?: number | null
    insulationTest?: InsulationTestValue | null
    functionalTest?: boolean | null
}

export interface ValidationSuite extends ValidationSuiteAttributes {
    testedBy: User | null
    testedOn: Date | null
}

export const assemblyWorksheetsSlice = createSlice({
    name: 'assembly-worksheets',
    initialState,
    reducers: {
        setAssemblies(state, action: PayloadAction<Assembly[]>) {
            state.assemblies = action.payload
        },
        setGeneralAssemblies(state, action: PayloadAction<GeneralAssembly[]>) {
            state.generalAssemblies = action.payload
        },
        setActiveAssembly(state, action: PayloadAction<Assembly | null>) {
            state.activeAssembly = action.payload
        },
        setActiveGeneralAssembly(state, action: PayloadAction<GeneralAssembly | null>) {
            state.activeGeneralAssembly = action.payload
        },
        addAssembly(state, action: PayloadAction<Assembly>) {
            if (state.activeAssembly?.id === action.payload.id) {
                state.activeAssembly = action.payload
            }

            state.assemblies = [
                ...state.assemblies.filter((worksheet) => worksheet.id !== action.payload.id),
                action.payload,
            ]
        },
        addGeneralAssembly(state, action: PayloadAction<GeneralAssembly>) {
            if (state.activeGeneralAssembly?.id === action.payload.id) {
                state.activeGeneralAssembly = action.payload
            }

            state.generalAssemblies = [
                ...state.generalAssemblies.filter(
                    (generalAssembly) => generalAssembly.id !== action.payload.id
                ),
                action.payload,
            ]
        },
        resetFilters(state) {
            state.filterStartDate = moment().startOf('day').unix()
            state.filterEndDate = moment().startOf('day').add(7, 'days').unix()
            state.filterWarehouseId = WarehouseId.Monument
            state.filterProjectIds = []
            state.filterAssemblerId = undefined
            state.filterIsOnHold = false
            state.filterStatus = undefined
        },
        setFilterStartDate(state, action: PayloadAction<Moment>) {
            state.filterStartDate = action.payload.unix()
        },
        setFilterEndDate(state, action: PayloadAction<Moment>) {
            state.filterEndDate = action.payload.unix()
        },
        setFilterWarehouseId(state, action: PayloadAction<WarehouseId>) {
            state.filterWarehouseId = action.payload
        },
        setFilterProjectIds(state, action: PayloadAction<number[]>) {
            state.filterProjectIds = action.payload
        },
        setFilterAssemblerId(state, action: PayloadAction<number | undefined>) {
            state.filterAssemblerId = action.payload
        },
        setFilterIsOnHold(state, action: PayloadAction<boolean>) {
            state.filterIsOnHold = action.payload
        },
        setFilterStatus(state, action: PayloadAction<AssemblyStatusKey | undefined>) {
            state.filterStatus = action.payload
        },
        setAssemblers(state, action: PayloadAction<User[]>) {
            state.assemblers = action.payload
        },
        startLoading(state) {
            state.isLoading = true
        },
        stopLoading(state) {
            state.isLoading = false
        },
        showStartAssemblyModal(state, action: PayloadAction<Assembly>) {
            state.startAssemblyModal = {
                isVisible: true,
                isLoading: true,
                assembly: action.payload,
            }
        },
        closeStartAssemblyModal(state) {
            state.startAssemblyModal = { ...initialStartAssemblyModalState }
        },
        showCompleteFlatstationAssemblyWorksheetModal(state, action: PayloadAction<number>) {
            state.completeFlatstationAssemblyWorksheetModalState = {
                ...state.completeFlatstationAssemblyWorksheetModalState,
                state: ModalState.Opened,
                assemblyId: action.payload,
            }
        },
        showCompleteAssemblyLineModal(state, action: PayloadAction<number>) {
            state.completeAssemblyLineModalState = {
                state: ModalState.Opened,
                assemblyLineId: action.payload,
            }
        },
        updateCompleteAssemblyLineModal(
            state,
            action: PayloadAction<Partial<CompleteAssemblyLineModalState>>
        ) {
            state.completeAssemblyLineModalState = {
                ...state.completeAssemblyLineModalState,
                ...action.payload,
            }
        },
        closeCompleteAssemblyLineModal(state) {
            state.completeAssemblyLineModalState = {
                state: ModalState.Closed,
                assemblyLineId: null,
            }
        },
        updateCompleteFlatstationAssemblyWorksheetModal(
            state,
            action: PayloadAction<Partial<CompleteFlatstationAssemblyWorksheetModalState>>
        ) {
            state.completeFlatstationAssemblyWorksheetModalState = {
                ...state.completeFlatstationAssemblyWorksheetModalState,
                ...action.payload,
            }
        },
        closeCompleteFlatstationAssemblyWorksheetModal(state) {
            state.completeFlatstationAssemblyWorksheetModalState = {
                ...initialCompleteFlatstationAssemblyWorksheetModalState,
            }
        },
        showCompleteAssemblyModal(state, action: PayloadAction<number>) {
            state.completeFlatstationAssemblyWorksheetModalState = {
                state: ModalState.Opened,
                assemblyId: action.payload,
            }
        },
        showStartGeneralAssemblyActivityModal(state, action: PayloadAction<number>) {
            state.startGeneralAssemblyActivityModalState = {
                ...state.startGeneralAssemblyActivityModalState,
                state: ModalState.Opened,
                generalAssemblyId: action.payload,
            }
        },
        updateStartGeneralAssemblyActivityModal(
            state,
            action: PayloadAction<Partial<StartGeneralAssemblyActivityModalState>>
        ) {
            state.startGeneralAssemblyActivityModalState = {
                ...state.startGeneralAssemblyActivityModalState,
                ...action.payload,
            }
        },
        closeStartGeneralAssemblyActivityModal(state) {
            state.startGeneralAssemblyActivityModalState = {
                ...initialStartGeneralAssemblyActivityModalState,
            }
        },
        updateStartAssemblyActivityModal(
            state,
            action: PayloadAction<Partial<StartAssemblyActivityModalState>>
        ) {
            state.startAssemblyActivityModalState = {
                ...state.startAssemblyActivityModalState,
                ...action.payload,
            }
        },
        showStartAssemblyActivityModal(state, action: PayloadAction<number>) {
            state.startAssemblyActivityModalState = {
                ...state.startAssemblyActivityModalState,
                state: ModalState.Opened,
                assemblyId: action.payload,
            }
        },
        closeStartAssemblyActivityModal(state) {
            state.startAssemblyActivityModalState = {
                ...initialStartAssemblyActivityModalState,
            }
        },
    },
})

export const {
    showCompleteFlatstationAssemblyWorksheetModal,
    updateCompleteFlatstationAssemblyWorksheetModal,
    closeCompleteFlatstationAssemblyWorksheetModal,
    updateStartAssemblyActivityModal,
    showCompleteAssemblyLineModal,
    updateCompleteAssemblyLineModal,
    closeCompleteAssemblyLineModal,
    updateStartGeneralAssemblyActivityModal,
    showStartGeneralAssemblyActivityModal,
    closeStartGeneralAssemblyActivityModal,
    showStartAssemblyActivityModal,
    closeStartAssemblyActivityModal,
    setFilterStartDate,
    setFilterEndDate,
    setFilterWarehouseId,
    setFilterProjectIds,
    setFilterAssemblerId,
    setFilterIsOnHold,
    setFilterStatus,
    setAssemblies,
    setActiveGeneralAssembly,
    setGeneralAssemblies,
    addAssembly,
    setActiveAssembly,
    setAssemblers,
    startLoading,
    stopLoading,
    showStartAssemblyModal,
    closeStartAssemblyModal,
    addGeneralAssembly,
    resetFilters,
} = assemblyWorksheetsSlice.actions

export const initializeAssembly =
    (activeAssemblyId: number | null) => async (dispatch: Dispatch, getRoot: () => RootState) => {
        const state = getRoot()
        const activeAssembly = state.assemblyWorksheets.assemblies.find(
            (assembly) => assembly.id === activeAssemblyId
        )

        dispatch(setActiveAssembly(activeAssembly || null))
    }

export const initializeGeneralAssembly =
    (generalAssemblyId: number | null) => async (dispatch: Dispatch, getRoot: () => RootState) => {
        const state = getRoot()
        const activeGeneralAssembly = state.assemblyWorksheets.generalAssemblies.find(
            (assembly) => assembly.id === generalAssemblyId
        )

        dispatch(setActiveGeneralAssembly(activeGeneralAssembly || null))
    }

export const fetchAssemblers = () => async (dispatch: Dispatch) => {
    const assemblersResponse = await getUsersRequest({ roles: [UserRole.Assembler] })

    if (!assemblersResponse.successful) {
        toastFailure(assemblersResponse.message)
        return
    }

    dispatch(setAssemblers(assemblersResponse.data.entities))
}

export const fetchWorksheets =
    (filters: GetAssemblyWorksheetsDTO & GetGeneralAssembliesDto = {}) =>
    async (dispatch: Dispatch) => {
        await dispatch(startLoading())

        const worksheetsResponse = await getAssemblyWorksheets(filters)
        if (!worksheetsResponse.successful) {
            await dispatch(stopLoading())
            toastFailure(worksheetsResponse.message)
            return
        }

        const response = await getGeneralAssembliesRequest({
            dateToBeCompletedByDateRangeEnd: filters.dateToBeCompletedByDateRangeEnd,
            isCompleted: false,
            warehouseId: filters.warehouseId,
            joins: [
                GeneralAssemblyRelation.Bom,
                GeneralAssemblyRelation.CreatedBy,
                GeneralAssemblyRelation.Lines,
                GeneralAssemblyRelation.LinesAssembledBy,
                GeneralAssemblyRelation.Activities,
                GeneralAssemblyRelation.ActivitiesUser,
            ],
        })
        if (!response.successful) {
            await dispatch(stopLoading())
            toastFailure(response.message)
            return
        }

        await dispatch(setAssemblies(worksheetsResponse.data))
        await dispatch(setGeneralAssemblies(response.data.entities))
        await dispatch(stopLoading())
    }

export const fetchGeneralAssemblies =
    (filters: GetGeneralAssembliesDto = {}) =>
    async (dispatch: Dispatch) => {
        await dispatch(startLoading())

        console.log('dto', {
            dateToBeCompletedByDateRangeEnd: filters.dateToBeCompletedByDateRangeEnd,
            isCompleted: false,
            warehouseId: filters.warehouseId,
            joins: [
                GeneralAssemblyRelation.Bom,
                GeneralAssemblyRelation.CreatedBy,
                GeneralAssemblyRelation.Lines,
                GeneralAssemblyRelation.LinesAssembledBy,
                GeneralAssemblyRelation.Activities,
                GeneralAssemblyRelation.ActivitiesUser,
            ],
        })
        const response = await getGeneralAssembliesRequest({
            dateToBeCompletedByDateRangeEnd: filters.dateToBeCompletedByDateRangeEnd,
            isCompleted: false,
            warehouseId: filters.warehouseId,
            joins: [
                GeneralAssemblyRelation.Bom,
                GeneralAssemblyRelation.CreatedBy,
                GeneralAssemblyRelation.Lines,
                GeneralAssemblyRelation.LinesAssembledBy,
                GeneralAssemblyRelation.Activities,
                GeneralAssemblyRelation.ActivitiesUser,
            ],
        })

        //console.log('response', response)

        if (!response.successful) {
            await dispatch(stopLoading())
            toastFailure('An error occurred while retrieving the assembly worksheets.')
            return
        }

        await dispatch(setGeneralAssemblies(response.data.entities))
        await dispatch(stopLoading())
    }

export const completeGeneralAssembly = (id: number) => async (dispatch: Dispatch) => {
    await dispatch(startLoading())

    const response = await completeGeneralAssemblyRequest(id)

    if (!response.successful) {
        await dispatch(stopLoading())
        toastFailure(response.message)
        return
    }

    toastSuccess('Completed general assembly')
    await dispatch(addGeneralAssembly(response.data))
    await dispatch(stopLoading())
}

export const setActiveWorksheetView =
    (worksheet: Assembly) => async (dispatch: Dispatch, getRoot: () => RootState) => {
        const state = getRoot()

        if (!state.authentication.user) {
            return
        }

        const getWorksheetResponse = await getAssemblyWorksheetRequest(worksheet.id)
        if (!getWorksheetResponse.successful) {
            toastFailure('Something went wrong while retrieving the worksheet.')
            return
        }

        dispatch(setActiveAssembly(worksheet))
        await dispatch(stopLoading())
    }

export const setActiveGeneralAssemblyView =
    (generalAssembly: GeneralAssembly) => async (dispatch: Dispatch, getRoot: () => RootState) => {
        const state = getRoot()

        if (!state.authentication.user) {
            return
        }

        const response = await getGeneralAssemblyByIdRequest(generalAssembly.id, {
            joins: [
                GeneralAssemblyRelation.Bom,
                GeneralAssemblyRelation.CreatedBy,
                GeneralAssemblyRelation.Lines,
                GeneralAssemblyRelation.LinesAssembledBy,
                GeneralAssemblyRelation.ActivitiesUser,
            ],
        })

        if (!response.successful) {
            dispatch(setActiveAssembly(null))
            toastFailure('Something went wrong while retrieving the worksheet.')
            await dispatch(stopLoading())
            return
        }

        setActiveGeneralAssembly(response.data)
        await dispatch(stopLoading())
    }

export const startAssembly =
    (assembly: Assembly, after: () => void) =>
    async (dispatch: Dispatch, getRoot: () => RootState) => {
        const authenticatedUser = getRoot().authentication.user

        const worksheetsResponse = await startAssemblyWorksheetRequest({
            assemblyId: assembly.id,
        })

        await handleStartAssemblyResponse(dispatch, after, worksheetsResponse)
    }

export const confirmStartAssembly =
    (after: () => void) => async (dispatch: Dispatch, getRoot: () => RootState) => {
        const modalState = getRoot().assemblyWorksheets.startAssemblyModal

        const worksheetsResponse = await startAssemblyWorksheetRequest({
            assemblyId: modalState.assembly!.id,
        })

        await handleStartAssemblyResponse(dispatch, after, worksheetsResponse)
    }

const handleStartAssemblyResponse = async (
    dispatch: Dispatch,
    after: () => void,
    worksheetsResponse: SuccessfulResponse<Assembly> | FailedResponse
) => {
    if (!worksheetsResponse.successful) {
        await dispatch(stopLoading())
        await dispatch(closeStartAssemblyModal())
        await dispatch(setRegularView())
        toastFailure(worksheetsResponse.message)
        return
    }
    await dispatch(addAssembly(worksheetsResponse.data))
    await dispatch(setActiveAssembly(worksheetsResponse.data))
    after()
    toastSuccess('Assemblies has been distributed')
    await dispatch(closeStartAssemblyModal())
    await dispatch(setRegularView())
}

export const completeLine = (item: AssemblyLine) => async (dispatch: Dispatch) => {
    const completeResponse = await completeWorksheetLineRequest(item.id)
    if (!completeResponse.successful) {
        toastFailure(completeResponse.message)
        return
    }

    await dispatch(addAssembly(completeResponse.data))
    await dispatch(closeCompleteAssemblyLineModal())
    toastSuccess('Successfully marked the item complete.')
}

export const completeWorksheet = (assembly: Assembly) => async (dispatch: Dispatch) => {
    const completeResponse = await completeWorksheetRequest(assembly.id)
    if (!completeResponse.successful) {
        toastFailure(completeResponse.message)
        return
    }

    await dispatch(addAssembly(completeResponse.data))
    toastSuccess('Successfully marked the worksheet completed.')
}

export const completeFlatstationWorksheet =
    (assembly: Assembly, pallets: CompleteAssemblyFlatstationPalletDto[]) =>
    async (dispatch: Dispatch) => {
        const completeResponse = await completeFlatstationWorksheetRequest(assembly.id, pallets)
        if (!completeResponse.successful) {
            toastFailure(completeResponse.message)
            return
        }

        await dispatch(addAssembly(completeResponse.data))
        toastSuccess('Successfully marked the worksheet completed.')
    }

export const saveWorksheetNote =
    (assembly: Assembly, note: string | null) => async (dispatch: Dispatch) => {
        const updateResponse = await updateWorksheetRequest(assembly.id, { note })
        if (!updateResponse.successful) {
            toastFailure(updateResponse.message)
            return
        }

        await dispatch(addAssembly(updateResponse.data))
        toastSuccess('Successfully updated the note.')
    }

export const fetchAssembly = (assemblyId: number) => async (dispatch: Dispatch) => {
    const assemblyResponse = await getAssemblyWorksheetRequest(assemblyId)

    if (!assemblyResponse.successful) {
        toastFailure(assemblyResponse.message)
        return
    }

    await dispatch(addAssembly(assemblyResponse.data))
}

export const saveFieldValue = async (
    assemblyLineFieldId: number,
    value: string | null,
    onUpdated: () => void
) => {
    const updateResponse = await updateAssemblyLineFieldValueRequest(assemblyLineFieldId, {
        value,
    })

    if (!updateResponse.successful) {
        toastFailure(updateResponse.message ?? 'An error occurred while updating the field value.')
        return
    }

    onUpdated()
}

export const updateAssemblyLineField =
    (
        item: AssemblyLine,
        key: keyof ValidationSuiteAttributes,
        value: string | boolean | number | null
    ) =>
    async (dispatch: Dispatch) => {
        const validationUpdateResponse = await validateWorksheetLineRequest(item.id, key, value)
        if (!validationUpdateResponse.successful) {
            toastFailure(validationUpdateResponse.message)
            return
        }

        await dispatch(addAssembly(validationUpdateResponse.data))
    }

export const updateGeneralAssemblyLine =
    (line: GeneralAssemblyLine, dto: UpdateGeneralAssemblyLineDto) =>
    async (dispatch: Dispatch) => {
        const updateLineResponse = await updateGeneralAssemblyLineRequest(line.id, dto)
        if (!updateLineResponse.successful) {
            toastFailure(updateLineResponse.message)
            return
        }

        const generalAssemblyResponse = await getGeneralAssemblyByIdRequest(
            line.generalAssemblyId,
            {
                joins: [
                    GeneralAssemblyRelation.CreatedBy,
                    GeneralAssemblyRelation.Bom,
                    GeneralAssemblyRelation.Lines,
                    GeneralAssemblyRelation.LinesAssembledBy,
                    GeneralAssemblyRelation.Activities,
                    GeneralAssemblyRelation.ActivitiesUser,
                ],
            }
        )

        if (!generalAssemblyResponse.successful) {
            toastFailure(generalAssemblyResponse.message)
            return
        }

        await dispatch(addGeneralAssembly(generalAssemblyResponse.data))
    }

export default assemblyWorksheetsSlice.reducer
