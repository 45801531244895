import { useSelector } from 'react-redux'
import { getBomGroupsRequest } from '../../api/bom-groups'
import BomGroupsTable from '../tables/BomGroupTable'
import { RootState } from '../../store/store'
import { BomGroupsTopBar } from './BomGroupsTopBar'
import { BomGroup } from '../../models/models'
import { Button } from 'antd'
import { ActiveModal, openModal } from '../../store/products-slice'
import { useAppDispatch } from '../../store/hooks'

export const BomGroups = () => {
    const { bomGroupFilters, updateTableToken } = useSelector((state: RootState) => state.products)
    const dispatch = useAppDispatch()

    return (
        <div>
            <BomGroupsTopBar />
            <BomGroupsTable
                pagination={true}
                actionComponents={[
                    (bomGroup: BomGroup) => {
                        return (
                            <Button
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            activeModal: ActiveModal.BomGroupUpdate,
                                            modalEntityId: bomGroup.id,
                                        })
                                    )
                                }
                            >
                                Update
                            </Button>
                        )
                    },
                ]}
                dependencies={{ bomGroupFilters, updateTableToken }}
                request={(pagination) =>
                    getBomGroupsRequest({
                        ids: bomGroupFilters?.bomGroupId ? [bomGroupFilters.bomGroupId] : undefined,
                        name: bomGroupFilters?.name,
                        skip: pagination?.skip,
                        limit: pagination?.limit,
                    })
                }
            />
        </div>
    )
}
