import React from 'react'
import { Box, Typography } from '@mui/material'
import { Input } from 'antd'
import { LiteralUnion } from 'antd/lib/_util/type'
import { BorderColors, TextColors } from '../../theme/colors'
import { CSSProperties } from '@mui/styled-engine'
import { numberInputOnWheelPreventChange } from '../../util/util'

interface TextInputProps {
    type?: LiteralUnion<string>
    name?: string | undefined
    prefix?: React.ReactNode
    showValidation?: boolean
    invalid?: boolean
    value: string
    onChange: (value: string) => void
    disabled?: boolean
}

export const TextInput = (props: TextInputProps) => {
    return (
        <Input
            disabled={props.disabled}
            type={props.type}
            name={props.name}
            prefix={props.prefix}
            size="middle"
            style={{
                padding: 10,
                borderRadius: '10px',
                width: '100%',
                border:
                    props.showValidation && props.invalid ? '1px solid red' : '1px solid #d9d9d9',
            }}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            onWheel={numberInputOnWheelPreventChange}
        />
    )
}

interface InputSectionProps {
    text: string
    children: React.ReactNode
    width?: number
    height?: string
    button?: React.ReactNode
    onButtonClick?: () => void
    disabled?: boolean
    style?: CSSProperties
}

export const InputSection = (props: InputSectionProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: '15px',
                marginBottom: '15px',
                padding: '5px 0px 5px 0px',
                opacity: props.disabled ? 0.5 : 1,
                alignItems: 'center',
                ...(props.button && {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 0,
                    },
                    '& fieldset': { borderRadius: '10px 0 0 10px' },
                }),
                ...props?.style,
            }}
        >
            <Box sx={{ minWidth: props.width || 170 }}>
                <Typography fontSize={16} color={TextColors.lightGrey} align={'right'}>
                    {props.text}
                </Typography>
            </Box>

            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'flex-end',
                    overflow: 'hidden',
                }}
            >
                {props.children}
                {props.button && (
                    <Box
                        onClick={() => {
                            if (!props.disabled && props.onButtonClick) {
                                props.onButtonClick()
                            }
                        }}
                        sx={{
                            minWidth: '40px',
                            height: '45px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: props.disabled ? 'not-allowed' : 'pointer',
                            border: '1px solid #d9d9d9',
                            borderLeft: 'none',
                        }}
                    >
                        {props.button}
                    </Box>
                )}
            </Box>
        </Box>
    )
}

interface InputAutocompleteSectionProps {
    text: string
    children: React.ReactNode
    width?: number
    button?: React.ReactNode
    onButtonClick?: () => void
    isInputValid: boolean
    disabled?: boolean
}

export const InputAutocompleteSection = (props: InputAutocompleteSectionProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: '15px',
                marginBottom: '15px',
                alignItems: 'center',
                opacity: props.disabled ? 0.5 : 1,
                ...{
                    '& .MuiAutocomplete-root': {
                        overflow: 'hidden',
                        height: 45,
                        border: `1px solid ${
                            props.isInputValid ? BorderColors.lighterGrey : BorderColors.red
                        }`,
                        ...(props.button
                            ? {
                                  borderRadius: '10px 0 0 10px',
                              }
                            : { borderRadius: '10px' }),
                    },
                    '& .MuiAutocomplete-root:active': {
                        border: `2px solid ${BorderColors.purple}`,
                    },
                    '& .MuiAutocomplete-root:hover': {
                        border: `1px solid ${BorderColors.black}`,
                    },
                    '& .MuiAutocomplete-root.Mui-error': {
                        border: `1px solid ${BorderColors.red}`,
                    },
                    '& .MuiAutocomplete-root.Mui-focused': {
                        border: `2px solid ${BorderColors.purple}`,
                    },
                    '& .MuiAutocomplete-root.MuiInputBase-multiline': {
                        padding: 14,
                    },
                    '& .MuiAutocomplete-root :before': {
                        display: 'none',
                    },
                    '& .MuiAutocomplete-root :after': {
                        display: 'none',
                    },
                    '& .MuiAutocomplete-root fieldset': {
                        display: 'none',
                    },
                    '& .MuiAutocomplete-root fieldset legend': {
                        width: 0,
                        display: 'none',
                    },
                    '& .MuiAutocomplete-root .MuiAutocomplete-endAdornment': {
                        top: 8,
                    },
                    '& .MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
                        height: '45px',
                        padding: 0,
                    },
                    '& .MuiAutocomplete-root .MuiFormControl-root': {
                        height: '45px',
                    },
                    '& .MuiAutocomplete-root .MuiOutlinedInput-input': {
                        height: '45px',
                        padding: '0px 0px 0px 14px !important',
                    },
                },
            }}
        >
            <Box sx={{ minWidth: props.width || 170 }}>
                <Typography fontSize={16} color={TextColors.lightGrey} align={'right'}>
                    {props.text}
                </Typography>
            </Box>

            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'flex-end',
                    overflow: 'hidden',
                }}
            >
                {props.children}
                {props.button && (
                    <Box
                        onClick={() => {
                            if (!props.disabled && props.onButtonClick) {
                                props.onButtonClick()
                            }
                        }}
                        sx={{
                            minWidth: '40px',
                            height: '45px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '1px solid #d9d9d9',
                            borderLeft: 'none',
                            cursor: props.disabled ? 'not-allowed' : 'pointer',
                        }}
                    >
                        {props.button}
                    </Box>
                )}
            </Box>
        </Box>
    )
}
