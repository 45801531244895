import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { ComponentsVariants } from '@mui/material/styles/variants'

export const TablePaginationThemeStyles: {
    styleOverrides: ComponentsOverrides['MuiTablePagination']
    variants: ComponentsVariants['MuiTablePagination']
} = {
    styleOverrides: {
        toolbar: {
            padding: '0 !important',
            justifyContent: 'center',
        },
        spacer: {
            display: 'contents',
        },
        selectLabel: {
            margin: 0,
        },
        displayedRows: {
            margin: 0,
        },
    },
    variants: [],
}
