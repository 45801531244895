import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import CommentIcon from '@mui/icons-material/Comment'
import { useMemo, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import TextArea from 'antd/lib/input/TextArea'
import { AddedComment, getCommentOrderlines, Orderline, isAddedComment } from './CreateSaleOrder'
import { Button } from 'antd'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: '30px',
})

const AddCommentsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
})

const AddedCommentsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
})

interface CommentsProps {
    onNextStep: () => void
    orderlines: Orderline[]
    setOrderlines: React.Dispatch<React.SetStateAction<Orderline[]>>
}

export default function Comments(props: CommentsProps) {
    const { onNextStep, orderlines, setOrderlines } = props
    const [comment, setComment] = useState<string>('')

    const commentOrderlines = useMemo(() => {
        return getCommentOrderlines(orderlines)
    }, [orderlines])

    return (
        <Container>
            <AddCommentsContainer>
                <h3>New Comment</h3>
                <TextArea
                    value={comment}
                    onChange={(e: any) => setComment(e.target.value)}
                    placeholder="New Comment"
                    style={{ width: '500px' }}
                    autoSize={{ minRows: 3, maxRows: 5 }}
                />
                <Button
                    disabled={comment === ''}
                    onClick={() => {
                        setOrderlines([...orderlines, { description: comment }])
                        setComment('')
                    }}
                >
                    Add
                </Button>
            </AddCommentsContainer>
            <AddedCommentsContainer>
                <h3>Added Comments</h3>
                <List sx={{ marginTop: '20px' }}>
                    {commentOrderlines.map((comment: AddedComment, i) => {
                        return (
                            <ListItem key={i}>
                                <ListItemIcon>
                                    <CommentIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={comment.description}
                                    primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                                />
                                <ListItemIcon>
                                    <Button
                                        aria-label="close"
                                        onClick={() => {
                                            setOrderlines(
                                                orderlines.filter(
                                                    (orderline) =>
                                                        !isAddedComment(orderline) ||
                                                        orderline.description !==
                                                            comment.description
                                                )
                                            )
                                        }}
                                        icon={<CloseIcon />}
                                    />
                                </ListItemIcon>
                            </ListItem>
                        )
                    })}
                </List>
                <Button
                    type="primary"
                    onClick={() => {
                        onNextStep()
                    }}
                >
                    Continue
                </Button>
            </AddedCommentsContainer>
        </Container>
    )
}
