import { Box } from '@mui/system'
import CTRLoader from '../../../../../ui/loader/CTRloader'

export default function Loading({ text }: { text?: string }) {
    return (
        <Box
            sx={{
                zIndex: 10,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <CTRLoader size="xl" />
            {text && (
                <Box
                    sx={{
                        textAlign: 'center',
                        marginTop: '220px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                    }}
                >
                    <p>{text}</p>
                </Box>
            )}
        </Box>
    )
}
