import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { UserRole } from '../models/models'

export enum StoreStage {
    Initial,
    Loading,
    ServerError,
}

export enum ActiveModal {
    CreateUser = 'CreateUser',
    UpdateUser = 'UpdateUser',
    UpdatePasswordUser = 'UpdatePasswordUser',
    DeleteUser = 'DeleteUser',
    None = 'None',
}

interface ModalState {
    activeModal: ActiveModal
    modalEntityId: number | null
}

export interface UsersState {
    modalState: ModalState
    userFilters: UsersFilters
    // token that tells to update the table
    updateTableToken?: string
}

export interface UsersFilters {
    email?: string
    name?: string
    role?: UserRole
}
const initialState: UsersState = {
    userFilters: {},
    modalState: {
        activeModal: ActiveModal.None,
        modalEntityId: null,
    },
    updateTableToken: undefined,
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUserFilters(state, action: PayloadAction<UsersFilters>) {
            state.userFilters = action.payload
        },
        setUpdateTableToken(state, action: PayloadAction<string | undefined>) {
            state.updateTableToken = action.payload
        },
        openModal: (state, action: PayloadAction<ModalState>) => {
            state.modalState = action.payload
        },
        closeModal: (state) => {
            state.modalState = {
                activeModal: ActiveModal.None,
                modalEntityId: null,
            }
        },
    },
})

export const { setUserFilters, setUpdateTableToken, openModal, closeModal } = usersSlice.actions

export default usersSlice.reducer
