import { Box } from '@mui/material'
import { Button } from 'antd'
import { useSelector } from 'react-redux'
import { getTaskTypesRequest } from '../../../api/task-types'
import { useAppDispatch } from '../../../store/hooks'
import { RootState } from '../../../store/store'
import { ActiveModal, openModal } from '../../../store/tasks-slice'
import TaskTypeTable, { TaskTypeTableColumns } from '../../tables/TaskTypeTable'
import FilterBar from './FilterBar'

export default function TaskTypes() {
    const { filterWarehouseId, updateTableToken } = useSelector((state: RootState) => state.tasks)
    const dispatch = useAppDispatch()
    return (
        <Box>
            <FilterBar />
            <TaskTypeTable
                pagination={true}
                columns={[
                    TaskTypeTableColumns.Id,
                    TaskTypeTableColumns.Title,
                    TaskTypeTableColumns.Minutes,
                    TaskTypeTableColumns.Description,
                ]}
                actionComponents={[
                    (taskTypeId: number) => (
                        <Button
                            style={{ marginRight: '10px' }}
                            type="default"
                            onClick={() => {
                                dispatch(
                                    openModal({
                                        activeModal: ActiveModal.TaskTypeInspect,
                                        modalEntityId: taskTypeId,
                                    })
                                )
                            }}
                        >
                            Inspect
                        </Button>
                    ),
                    (taskTypeId: number) => (
                        <Button
                            style={{ marginRight: '10px' }}
                            type="default"
                            onClick={() => {
                                dispatch(
                                    openModal({
                                        activeModal: ActiveModal.TaskTypeUpdate,
                                        modalEntityId: taskTypeId,
                                    })
                                )
                            }}
                        >
                            Update
                        </Button>
                    ),
                    (taskTypeId: number) => (
                        <Button
                            style={{ marginRight: '10px' }}
                            type="default"
                            onClick={() => {
                                dispatch(
                                    openModal({
                                        activeModal: ActiveModal.TaskTypeDelete,
                                        modalEntityId: taskTypeId,
                                    })
                                )
                            }}
                        >
                            Delete
                        </Button>
                    ),
                ]}
                dependencies={[filterWarehouseId, updateTableToken]}
                request={(pagination) =>
                    getTaskTypesRequest({
                        warehouseId: filterWarehouseId,
                        skip: pagination?.skip,
                        limit: pagination?.limit,
                    })
                }
            />
        </Box>
    )
}
