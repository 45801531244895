import moment, { Moment } from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { AssemblyRelation, getAssembliesRequest } from '../api/assemblies'
import { GeneralAssemblyRelation, getGeneralAssembliesRequest } from '../api/general-assemblies'
import { getProductionScheduleRequest } from '../api/production-schedule'
import { getTasksRequest } from '../api/tasks'
import {
    AbsentDay,
    AbsentDayType,
    Assembly,
    GeneralAssembly,
    Schedule,
    Task,
    User,
} from '../models/models'
import { toastFailure } from './toast'
import { isBritishHolidayOrIsWeekend } from './british-holidays'

type MomentWeekday = '1' | '2' | '3' | '4' | '5'

/**
 * totalMinutes - absenceMinutes - allocatedMinutes
 * @param schedule
 * @returns
 */
export const getScheduleAvailableMinutes = (schedule: Schedule) => {
    const absenceMinutes = getAbsentDayMinutes(schedule.absentDays)

    const assembliesMinutes = getAssembliesMinutes(schedule.assemblies)

    const assembliesCompletedMinutes = getAssembliesCompletedMinutes(schedule.assemblies)

    const assemblyRemainingMinutes = assembliesMinutes - assembliesCompletedMinutes

    const generalAssembliesMinutes = getGeneralAssembliesMinutes(schedule.generalAssemblies)
    const generalAssembliesCompletedMinutes = getGeneralAssembliesCompletedMinutes(
        schedule.generalAssemblies
    )

    const generalAssembliesRemainingMinutes =
        generalAssembliesMinutes - generalAssembliesCompletedMinutes

    const taskMinutes = getTaskMinutes(schedule.tasks)
    const taskCompletedMinutes = getTasksCompletedMinutes(schedule.tasks)
    const taskRemainingMinutes = taskMinutes - taskCompletedMinutes

    const totalMinutes = getTotalMinutes(moment(schedule.day), schedule.assemblers)

    return (
        totalMinutes -
        assemblyRemainingMinutes -
        taskRemainingMinutes -
        generalAssembliesRemainingMinutes -
        absenceMinutes
    )
}

/**
 * totalMinutes - absenceMinutes - allocatedMinutes
 * @param schedule
 * @returns
 */
export const getScheduleRemainingMinutes = (schedule: Schedule) => {
    const absenceMinutes = getAbsentDayMinutes(schedule.absentDays)

    const assembliesMinutes = getAssembliesMinutes(schedule.assemblies)

    const assembliesCompletedMinutes = getAssembliesCompletedMinutes(schedule.assemblies)

    const assemblyRemainingMinutes = assembliesMinutes - assembliesCompletedMinutes

    const totalMinutes = getTotalMinutes(moment(schedule.day), schedule.assemblers)

    return totalMinutes - assemblyRemainingMinutes - absenceMinutes
}

export const getAssembliesMinutes = (assemblies: Assembly[]) => {
    return assemblies.reduce((minutes, assembly) => {
        return (
            minutes +
            assembly.amount *
                assembly.builtItemCallOff.sopBuiltItemOrderline.builtItem.assemblyMinutes
        )
    }, 0)
}

export const getAssembliesCompletedMinutes = (assemblies: Assembly[]) => {
    return assemblies.reduce((minutes, assembly) => {
        const completedAmount = assembly.lines.reduce((total, item) => {
            if (item.completedAt) {
                total++
            }
            return total
        }, 0)
        return (
            minutes +
            completedAmount *
                assembly.builtItemCallOff.sopBuiltItemOrderline.builtItem.assemblyMinutes
        )
    }, 0)
}

export const getGeneralAssembliesMinutes = (generalAssemblies: GeneralAssembly[]) => {
    return generalAssemblies.reduce((minutes, generalAssembly) => {
        return minutes + generalAssembly.lines.length * generalAssembly.builtItem.assemblyMinutes
    }, 0)
}

export const getGeneralAssembliesCompletedMinutes = (generalAssemblies: GeneralAssembly[]) => {
    return generalAssemblies.reduce((minutes, generalAssembly) => {
        const completedAmount = generalAssembly.lines.reduce((total, line) => {
            if (line.completedAt) {
                total++
            }
            return total
        }, 0)
        return minutes + completedAmount * generalAssembly.builtItem.assemblyMinutes
    }, 0)
}

const getAssemblerDayMinutes = (day: Moment, user: User): number => {
    const currentDay = day.isoWeekday().toString()
    if (currentDay === '6' || currentDay === '7') {
        return 0
    }
    return {
        '1': user?.scheduledMinutesMonday || 0,
        '2': user?.scheduledMinutesTuesday || 0,
        '3': user?.scheduledMinutesWednesday || 0,
        '4': user?.scheduledMinutesThursday || 0,
        '5': user?.scheduledMinutesFriday || 0,
    }[currentDay as MomentWeekday]
}

export const getTotalMinutes = (day: Moment, assemblers: User[]) => {
    if (isBritishHolidayOrIsWeekend(day)) {
        return 0
    }

    return assemblers.reduce((total, user) => total + getAssemblerDayMinutes(day, user), 0)
}

export const getTaskMinutes = (tasks: Task[]) => {
    let minutes = 0
    for (const task of tasks) {
        minutes += task.minutes
    }
    return minutes
}

export const getTasksCompletedMinutes = (tasks: Task[]) => {
    let minutes = 0
    for (const task of tasks) {
        if (task.completedAt) {
            minutes += task.minutes
        }
    }
    return minutes
}

export const getAbsentDayMinutes = (absentDays: AbsentDay[]) => {
    return absentDays.reduce((minutes, absentDay) => {
        absentDay.date
        const currentDay = moment(absentDay.date).isoWeekday().toString()

        if (currentDay === '6' || currentDay === '7') {
            return 0
        }

        absentDay.date

        const userAvailableMinutes = {
            '1': absentDay.daysOff?.user?.scheduledMinutesMonday || 0,
            '2': absentDay.daysOff?.user?.scheduledMinutesTuesday || 0,
            '3': absentDay.daysOff?.user?.scheduledMinutesWednesday || 0,
            '4': absentDay.daysOff?.user?.scheduledMinutesThursday || 0,
            '5': absentDay.daysOff?.user?.scheduledMinutesFriday || 0,
        }[currentDay as MomentWeekday]

        //we can assume it should be subtracted because we only consider assembler days off
        const minutesToSubtractFromSchedule = {
            [AbsentDayType.FullDay]: () => userAvailableMinutes,

            [AbsentDayType.HalfDay]: () => Math.round(userAvailableMinutes / 2),

            [AbsentDayType.Minutes]: () => {
                throw new Error('Not implemented yet')
            },
        }[absentDay.type]()

        return minutes + (minutesToSubtractFromSchedule || 0)
    }, 0)
}

interface UseScheduleProps {
    date: Moment
    warehouseId: number
    withDelayed?: boolean
}

export const useSchedule = ({ date, warehouseId, withDelayed }: UseScheduleProps) => {
    const [absenceMinutes, setAbsenceMinutes] = useState<number>(0)
    const [assembliesMinutes, setAssembliesMinutes] = useState<number>(0)
    const [assembliesCompletedMinutes, setAssembliesCompletedMinutes] = useState<number>(0)
    const [assembliesDelayedMinutes, setAssembliesDelayedMinutes] = useState<number>(0)
    const [generalAssembliesMinutes, setGeneralAssembliesMinutes] = useState<number>(0)
    const [generalAssembliesCompletedMinutes, setGeneralAssembliesCompletedMinutes] =
        useState<number>(0)
    const [generalAssembliesDelayedMinutes, setGeneralAssembliesDelayedMinutes] =
        useState<number>(0)
    const [taskMinutes, setTaskMinutes] = useState<number>(0)
    const [taskCompletedMinutes, setTaskCompletedMinutes] = useState<number>(0)
    const [taskDelayedMinutes, setTaskDelayedMinutes] = useState<number>(0)
    const [totalMinutes, setTotalMinutes] = useState<number>(0)
    const [schedule, setSchedule] = useState<Schedule>()
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<boolean>(false)

    const assemblyIncompleteMinutes = useMemo(() => {
        return assembliesMinutes - assembliesCompletedMinutes
    }, [assembliesMinutes, assembliesCompletedMinutes])

    const taskIncompleteMinutes = useMemo(() => {
        return taskMinutes - taskCompletedMinutes
    }, [taskMinutes, taskCompletedMinutes])

    const generalAssembliesIncompleteMinutes = useMemo(() => {
        return generalAssembliesMinutes - generalAssembliesCompletedMinutes
    }, [generalAssembliesMinutes, generalAssembliesCompletedMinutes])

    const incompleteMinutes = useMemo(() => {
        return (
            assemblyIncompleteMinutes + taskIncompleteMinutes + generalAssembliesIncompleteMinutes
        )
    }, [taskIncompleteMinutes, assemblyIncompleteMinutes, generalAssembliesIncompleteMinutes])

    const delayedMinutes = useMemo(() => {
        return taskDelayedMinutes + assembliesDelayedMinutes + generalAssembliesDelayedMinutes
    }, [taskDelayedMinutes, assembliesDelayedMinutes, generalAssembliesDelayedMinutes])

    const allocatedMinutes = useMemo(() => {
        return taskMinutes + assembliesMinutes + generalAssembliesMinutes
    }, [taskMinutes, assembliesMinutes, generalAssembliesMinutes])

    const completedMinutes = useMemo(() => {
        return assembliesCompletedMinutes + taskCompletedMinutes + generalAssembliesCompletedMinutes
    }, [assembliesCompletedMinutes, taskCompletedMinutes, generalAssembliesCompletedMinutes])

    const remainingMinutes = useMemo(() => {
        return incompleteMinutes
    }, [incompleteMinutes])

    const availableMinutes = useMemo(() => {
        return totalMinutes - absenceMinutes - allocatedMinutes
    }, [absenceMinutes, remainingMinutes, totalMinutes])

    const getDelayedMinutes = async () => {
        const [assembliesResponse, tasksResponse, generalAssembliesResponse] = await Promise.all([
            getAssembliesRequest({
                isDelayed: true,
                hasBeenAssembled: false,
                relations: [AssemblyRelation.CallOff, AssemblyRelation.Bom],
            }),

            getTasksRequest({ dateToBeCompletedByDateRangeEnd: new Date(), isCompleted: false }),

            getGeneralAssembliesRequest({
                dateToBeCompletedByDateRangeEnd: new Date(),
                isCompleted: false,
                joins: [GeneralAssemblyRelation.Bom, GeneralAssemblyRelation.Lines],
            }),
        ])

        if (
            !tasksResponse.successful ||
            !assembliesResponse.successful ||
            !generalAssembliesResponse.successful
        ) {
            toastFailure('Failed to get the amount of delayed minutes')
            return
        }

        const assemblies = assembliesResponse.data.entities
        const tasks = tasksResponse.data.entities
        const generalAssemblies = generalAssembliesResponse.data.entities

        const generalAssembliesMinutes = getGeneralAssembliesMinutes(generalAssemblies)
        const generalAssembliesCompletedMinutes =
            getGeneralAssembliesCompletedMinutes(generalAssemblies)
        const assembliesMinutes = getAssembliesMinutes(assemblies)
        const assembliesCompletedMinutes = getAssembliesCompletedMinutes(assemblies)
        const taskMinutes = getTaskMinutes(tasks)
        const taskCompletedMinutes = getTasksCompletedMinutes(tasks)

        setAssembliesDelayedMinutes(assembliesMinutes - assembliesCompletedMinutes)
        setTaskDelayedMinutes(taskMinutes - taskCompletedMinutes)
        setGeneralAssembliesDelayedMinutes(
            generalAssembliesMinutes - generalAssembliesCompletedMinutes
        )
    }

    const onUpdate = async () => {
        setLoading(true)
        const day = date.isoWeekday().toString()
        if (day === '6' || day === '7') {
            setLoading(false)
            return
        }

        if (withDelayed) {
            await getDelayedMinutes()
        }

        const response = await getProductionScheduleRequest(warehouseId, date.toDate())
        if (response.successful) {
            const schedule = response.data
            setSchedule(schedule)

            const absenceMinutes = getAbsentDayMinutes(schedule.absentDays)
            setAbsenceMinutes(absenceMinutes)

            const assembliesMinutes = getAssembliesMinutes(schedule.assemblies)
            setAssembliesMinutes(assembliesMinutes)

            const assembliesCompletedMinutes = getAssembliesCompletedMinutes(schedule.assemblies)
            setAssembliesCompletedMinutes(assembliesCompletedMinutes)

            const generalAssembliesMinutes = getGeneralAssembliesMinutes(schedule.generalAssemblies)
            setGeneralAssembliesMinutes(generalAssembliesMinutes)
            const generalAssembliesCompletedMinutes = getGeneralAssembliesCompletedMinutes(
                schedule.generalAssemblies
            )
            setGeneralAssembliesCompletedMinutes(generalAssembliesCompletedMinutes)

            const taskMinutes = getTaskMinutes(schedule.tasks)
            setTaskMinutes(taskMinutes)

            const taskCompletedMinutes = getTasksCompletedMinutes(schedule.tasks)
            setTaskCompletedMinutes(taskCompletedMinutes)

            const totalMinutes = getTotalMinutes(moment(schedule.day), schedule.assemblers)
            setTotalMinutes(totalMinutes)

            setError(false)
        } else {
            toastFailure(response.message)
            setError(true)
        }
        setLoading(false)
    }

    const dependencies = JSON.stringify({ warehouseId, date })

    useEffect(() => {
        onUpdate()
    }, [dependencies])

    return {
        assembliesMinutes,
        assembliesCompletedMinutes,
        assemblyIncompleteMinutes,
        assembliesDelayedMinutes,

        generalAssembliesMinutes,
        generalAssembliesCompletedMinutes,
        generalAssembliesIncompleteMinutes,
        generalAssembliesDelayedMinutes,

        taskMinutes,
        taskCompletedMinutes,
        taskIncompleteMinutes,
        taskDelayedMinutes,

        allocatedMinutes,
        totalMinutes,
        absenceMinutes,
        schedule,
        remainingMinutes,
        completedMinutes,
        incompleteMinutes,
        delayedMinutes,
        availableMinutes,
        loading,
        error,
    }
}
