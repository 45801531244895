import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'
import {
    UnauthorizedBom,
    UnauthorizedProduct,
    ProductType,
    AuthorizationState,
} from '../models/models'

const URL_PREFIX = '/unauthorized-product'

export enum ManyUnauthorizedProductRelation {
    Bom = 'Bom',
    RequestedByUser = 'RequestedByUser',
    RequestedForProject = 'RequestedForProject',
}

export interface GetManyQueryParamDTO {
    relations?: ManyUnauthorizedProductRelation[]
    withCount?: boolean
    productType?: ProductType
    authorizationState?: AuthorizationState
    skip?: number
    limit?: number
}

export const getManyUnauthorizedProductsRequest = (
    params?: GetManyQueryParamDTO
): Promise<Response<{ entities: UnauthorizedProduct[]; count: number }>> =>
    backendConnection.request({
        url: `${URL_PREFIX}`,
        method: 'GET',
        params: params,
    })

export enum UnauthorizedProductRelation {
    Bom = 'Bom',
}

export interface GetByIdQueryParamDTO {
    relations?: UnauthorizedProductRelation[]
}

export const getUnauthorizedProductByIdRequest = (
    id: number,
    params: GetByIdQueryParamDTO
): Promise<Response<UnauthorizedProduct>> =>
    backendConnection.request({
        url: `${URL_PREFIX}/${id}`,
        method: 'GET',
        params,
    })

export interface StockItemDTO {
    id: number
    amount: number
}
export interface CreateUnauthorizedBomDto {
    code: string
    components: StockItemDTO[]
    description: string
    bomGroupId?: number
    instructionLink?: string
    assemblyMinutes?: number
    name: string
    warehouseId: number
    productGroupId: number
    projectId?: number
    requestedForProjectId?: number
    note?: string
}

export const createUnauthorizedBomWithProductRequest = (
    body: CreateUnauthorizedBomDto
): Promise<Response<UnauthorizedBom>> =>
    backendConnection.request({
        url: `${URL_PREFIX}/product-and-bom`,
        method: 'POST',
        data: body,
    })

export const updateUnauthorizedProductNoteRequest = (
    id: number,
    note: string
): Promise<Response<UnauthorizedProduct>> =>
    backendConnection.request({
        url: `${URL_PREFIX}/note/${id}`,
        method: 'PUT',
        params: { note },
    })

export interface CreateUnauthorizedStockItemDTO {
    code: string
    name: string
    description: string
    supplierId: number
    warehouseId: number
    productGroupId: number
    requestedByUserId?: number
    requestedForProjectId?: number
    note?: string
}

export const createUnauthorizedStockItemRequest = (
    body: CreateUnauthorizedStockItemDTO
): Promise<Response<UnauthorizedProduct>> =>
    backendConnection.request({
        url: `${URL_PREFIX}/product`,
        method: 'POST',
        data: body,
    })
