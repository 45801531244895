import { styled } from '@mui/styles'
import { useAppDispatch } from '../../store/hooks'
import { RootState } from '../../store/store'
import { useSelector } from 'react-redux'
import { FieldContainer } from '../ui/FIeldContainer'
import { Label } from '../ui/Label'
import { Button, Input, Select } from 'antd'
import { ChangeEvent } from 'react'
import { ActiveModal, openModal, setUserFilters } from '../../store/users-slice'
import { UsersFilters } from '../../store/users-slice'
import { UserRole } from '../../models/models'

const Container = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}))

const InputsContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0px 0px 10px 0px',
    gap: '5px',
}))

const ActionsContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0px 0px 10px 0px',
    gap: '5px',
}))

export const UsersTopBar = () => {
    const { userFilters } = useSelector((state: RootState) => state.users)
    const dispatch = useAppDispatch()

    const mutateUserFilters = (key: keyof UsersFilters, value: string | number | undefined) => {
        dispatch(setUserFilters({ ...userFilters, [key]: value }))
    }

    return (
        <Container>
            <InputsContainer>
                <FieldContainer>
                    <Label>Email</Label>
                    <Input
                        placeholder="Email"
                        style={{ width: '200px' }}
                        value={userFilters.email}
                        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                            mutateUserFilters('email', event.target.value)
                        }}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Label>Name</Label>
                    <Input
                        placeholder="Name"
                        style={{ width: '200px' }}
                        value={userFilters.name}
                        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                            mutateUserFilters('name', event.target.value)
                        }}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Label>Role</Label>
                    <Select
                        style={{ width: '200px' }}
                        value={userFilters.role}
                        onChange={(role): void => {
                            mutateUserFilters('role', role)
                        }}
                        placeholder="Select Role"
                        options={[
                            { label: 'Assembler', value: UserRole.Assembler },
                            { label: 'Project Manager', value: UserRole.ProjectManager },
                            { label: 'Sav Admin', value: UserRole.SavAdmin },
                            { label: 'System Admin', value: UserRole.SystemAdmin },
                            { label: 'Account Manager', value: UserRole.AccountManager },
                            {
                                label: 'Logistics Employee',
                                value: UserRole.LogisticsEmployee,
                            },
                            {
                                label: 'Application Engineer',
                                value: UserRole.ApplicationEngineer,
                            },
                            { label: 'Scheduler', value: UserRole.Scheduler },
                            { label: 'None', value: undefined },
                        ]}
                    />
                </FieldContainer>
            </InputsContainer>
            <ActionsContainer>
                <Button
                    type="primary"
                    onClick={() =>
                        dispatch(
                            openModal({
                                activeModal: ActiveModal.CreateUser,
                                modalEntityId: null,
                            })
                        )
                    }
                >
                    Create User
                </Button>
            </ActionsContainer>
        </Container>
    )
}
