import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import { AbsentDay } from '../../../models/models'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Typography } from 'antd'
import { snakeCaseToTitleCase } from '../../../util/util'
import moment from 'moment'

interface OverviewOfDayModalProps {
    isOpen: boolean
    onClose: () => void
    absentDays: AbsentDay[]
    date: Date | undefined
    changeDayOff: (dayOffId: number) => void
}

export const InspectDaysOffModal = ({
    isOpen,
    onClose,
    absentDays,
    date,
    changeDayOff,
}: OverviewOfDayModalProps) => {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{date?.toDateString()}</DialogTitle>
            <DialogContent style={{ padding: '0px 0px 10px 0px' }}>
                <Table aria-label="simple table" style={{ minWidth: '400px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <Typography style={{ fontSize: 16 }}>Employee</Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography style={{ fontSize: 16 }}>Holiday Type</Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography style={{ fontSize: 16 }}>Actions</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {absentDays
                            .filter((absentDay) => {
                                return (
                                    moment(absentDay.date).format('YYYY-MM-DD') <=
                                        moment(date).format('YYYY-MM-DD') &&
                                    moment(absentDay.date).format('YYYY-MM-DD') >=
                                        moment(date).format('YYYY-MM-DD')
                                )
                            })
                            .map((absentDay) => (
                                <TableRow
                                    key={absentDay.daysOff?.user?.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>
                                        {absentDay.daysOff?.user?.firstName}{' '}
                                        {absentDay.daysOff?.user?.lastName}
                                    </TableCell>
                                    <TableCell>
                                        {snakeCaseToTitleCase(absentDay.daysOff.type)}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            onClick={() => changeDayOff(absentDay.daysOffId)}
                                        >
                                            Change day off
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    )
}
