import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateSearchType, SortBy, SortOrder } from '../api/assemblies'
import { Assembly, WarehouseId } from '../models/models'
import { RootState } from './store'

export enum ActiveModal {
    None = 'None',
    Inspect = 'Inspect',
    Update = 'Update',
    Delete = 'Delete',
}

export enum Status {
    Any = 'Any',
    ToBeTransfferred = 'To be Transferred',
    ToBeAssembled = 'To be Assembled',
    ToBeDispatched = 'To be Dispatched',
    Completed = 'Completed',
}

export interface AssembliesState {
    activeModal: ActiveModal
    isLoading?: boolean
    sortBy: SortBy | null
    sortOrder: SortOrder
    assemblies: Assembly[]
    assembliesAmount: number
    modalAssemblerId?: number
    filterCallOffId?: number
    filterAssemblyId?: number
    filterProjectName: string
    filterBomCode: string
    filterStatus: Status
    filterSelectedWarehouseId: WarehouseId
    filterDateRangeBegin: Date | null
    filterDateRangeEnd: Date | null
    filterDateSearchType: DateSearchType | null
}

const initialState: AssembliesState = {
    activeModal: ActiveModal.None,
    isLoading: undefined,
    sortBy: null,
    sortOrder: SortOrder.Ascending,
    assemblies: [],
    assembliesAmount: 0,
    modalAssemblerId: undefined,
    filterCallOffId: undefined,
    filterAssemblyId: undefined,
    filterProjectName: '',
    filterBomCode: '',
    filterStatus: Status.Any,
    filterSelectedWarehouseId: WarehouseId.Monument,
    filterDateRangeBegin: null,
    filterDateRangeEnd: null,
    filterDateSearchType: null,
}

export const callOffSlice = createSlice({
    name: 'assemblies',
    initialState,
    reducers: {
        resetState(state) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state = initialState
        },
        setActiveModal(state, action: PayloadAction<ActiveModal>) {
            state.activeModal = action.payload
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setSortBy(state, action: PayloadAction<SortBy | null>) {
            state.sortBy = action.payload
        },
        setSortOrder(state, action: PayloadAction<SortOrder>) {
            state.sortOrder = action.payload
        },
        setAssemblies(state, action: PayloadAction<Assembly[]>) {
            state.assemblies = action.payload
        },
        setAssembliesAmount(state, action: PayloadAction<number>) {
            state.assembliesAmount = action.payload
        },
        setModalAssemblerId(state, action: PayloadAction<number | undefined>) {
            state.modalAssemblerId = action.payload
        },
        resetFilters(state) {
            state.filterCallOffId = undefined
            state.filterAssemblyId = undefined
            state.filterProjectName = ''
            state.filterBomCode = ''
            state.filterStatus = Status.Any
            state.filterSelectedWarehouseId = WarehouseId.Monument
            state.filterDateRangeBegin = null
            state.filterDateRangeEnd = null
            state.filterDateSearchType = null
        },
        setFilterCallOffId(state, action: PayloadAction<number | undefined>) {
            state.filterCallOffId = action.payload
        },
        setFilterAssemblyId(state, action: PayloadAction<number | undefined>) {
            state.filterAssemblyId = action.payload
        },
        setFilterProjectName(state, action: PayloadAction<string>) {
            state.filterProjectName = action.payload
        },
        setFilterBomCode(state, action: PayloadAction<string>) {
            state.filterBomCode = action.payload
        },
        setFilterStatus(state, action: PayloadAction<Status>) {
            state.filterStatus = action.payload
        },
        setFilterSelectedwarehouseId(state, action: PayloadAction<WarehouseId>) {
            state.filterSelectedWarehouseId = action.payload
        },
        setFilterDateRangeBegin(state, action: PayloadAction<Date | null>) {
            state.filterDateRangeBegin = action.payload
        },
        setFilterDateRangeEnd(state, action: PayloadAction<Date | null>) {
            state.filterDateRangeEnd = action.payload
        },
        setFilterDateSearchType(state, action: PayloadAction<DateSearchType | null>) {
            state.filterDateSearchType = action.payload
            state.filterDateRangeBegin = null
            state.filterDateRangeEnd = null
        },
    },
})

export const selectActiveView = (state: RootState) => state.callOff.activeView

export const {
    resetState,
    setActiveModal,
    setAssemblies,
    setAssembliesAmount,
    setFilterAssemblyId,
    setFilterBomCode,
    setFilterCallOffId,
    setFilterDateRangeBegin,
    setFilterDateRangeEnd,
    setFilterDateSearchType,
    setFilterProjectName,
    setFilterSelectedwarehouseId,
    setFilterStatus,
    setIsLoading,
    setModalAssemblerId,
    setSortBy,
    setSortOrder,
    resetFilters,
} = callOffSlice.actions

export default callOffSlice.reducer
