import { IconButton, styled } from '@mui/material'
import { Assembly, User, UserRole, WorksheetCompletionType } from '../../../../models/models'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../store/hooks'
import {
    addAssembly,
    fetchAssembly,
    saveFieldValue,
    setActiveWorksheetView,
    showStartAssemblyActivityModal,
} from '../../../../store/assembly-worksheets-slice'
import { AssemblyActivityStartModal } from '../modals/AssemblyActivityStartModal'
import { CompleteFlatstationAssemblyModal } from '../modals/CompleteFlatstationAssemblyModal'
import { ActiveAssemblyHeader } from './ActiveAssemblyHeader'
import { ActiveAssemblyCollectiveComplete } from './ActiveAssemblyCollectiveComplete'
import { ActiveAssemblyIndividualComplete } from './ActiveAssemblyIndividualComplete'
import { ActiveAssemblyContentFooter } from './ActiveAssemblyContentFooter'
import ScannerModal from '../modals/ScannerModal'
import { toastFailure } from '../../../../util/toast'
import { RefreshOutlined, RefreshRounded } from '@mui/icons-material'
import { getAssemblyWorksheetRequest } from '../../../../api/assemblies'
import { Dispatch } from '@reduxjs/toolkit'
import { CompleteAssemblyLineModal } from '../modals/CompleteAssemblyLineModal'

interface Props {
    user: User
    activeAssembly: Assembly
}

const WhiteContainer = styled('div')`
    padding-bottom: 20px;
    background: #fafafa;
    overflow-x: auto;
`

const CommentContainer = styled(WhiteContainer)`
    margin-bottom: 15px;
    padding: 10px !important;
    display: flex;
    gap: 10px;
`

const CommentPre = styled('pre')`
    overflow-y: auto;
    font-family: inherit;
    margin: 0;
    white-space: pre-wrap;
`

const CommentHeader = styled('p')`
    font-weight: bold;
    margin: 0;
`

export interface ScannerModalState {
    isVisible: boolean
    hasManualMode: boolean
    assemblyLineFieldId?: number
}

export const initialScannerModalState: ScannerModalState = {
    isVisible: false,
    assemblyLineFieldId: undefined,
    hasManualMode: false,
}

export function ActiveAssembly({ user, activeAssembly }: Props) {
    const dispatch = useAppDispatch()
    const [scannerModalState, setScannerModalState] =
        useState<ScannerModalState>(initialScannerModalState)
    const bom = activeAssembly?.builtItemCallOff.sopBuiltItemOrderline.builtItem

    useEffect(() => {
        if (user.role !== UserRole.Assembler) {
            return
        }

        if (!activeAssembly.startedAt || activeAssembly.completedAt) {
            return
        }

        const hasActivity = activeAssembly.activities.some((activity) => {
            return activity.userId === user.id && !activity.endedAt
        })

        if (!hasActivity) {
            dispatch(showStartAssemblyActivityModal(activeAssembly.id))
        }
    }, [])

    if (!bom.group) {
        return <div>No bom group</div>
    }

    const fetchAssembly = (assemblyId: number) => async (dispatch: Dispatch) => {
        const assemblyResponse = await getAssemblyWorksheetRequest(assemblyId)

        if (!assemblyResponse.successful) {
            toastFailure(assemblyResponse.message)
            return
        }

        await dispatch(addAssembly(assemblyResponse.data))
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <IconButton
                    style={{ marginBottom: '15px' }}
                    onClick={() => {
                        dispatch(fetchAssembly(activeAssembly.id))
                    }}
                >
                    <RefreshRounded />
                </IconButton>
                {activeAssembly.assemblyComment && (
                    <CommentContainer>
                        <CommentHeader>Comment:</CommentHeader>
                        <CommentPre>{activeAssembly.assemblyComment}</CommentPre>
                    </CommentContainer>
                )}
            </div>

            <WhiteContainer style={{ padding: '15px' }}>
                <ActiveAssemblyHeader assembly={activeAssembly} />
                {bom.group.worksheetCompletionType ===
                    WorksheetCompletionType.CollectiveComplete && (
                    <ActiveAssemblyCollectiveComplete user={user} assembly={activeAssembly} />
                )}
                {bom.group.worksheetCompletionType ===
                    WorksheetCompletionType.IndividualComplete && (
                    <ActiveAssemblyIndividualComplete
                        user={user}
                        assembly={activeAssembly}
                        setScannerModalState={setScannerModalState}
                        scannerModalState={scannerModalState}
                    />
                )}
                <ActiveAssemblyContentFooter />
            </WhiteContainer>
            <CompleteAssemblyLineModal />
            <AssemblyActivityStartModal />
            <CompleteFlatstationAssemblyModal />
            <ScannerModal
                hasManualMode={scannerModalState.hasManualMode}
                isVisible={scannerModalState.isVisible}
                onClose={() => setScannerModalState(initialScannerModalState)}
                onSubmit={(value: string | null) => {
                    setScannerModalState(initialScannerModalState)
                    if (!scannerModalState.isVisible || !scannerModalState.assemblyLineFieldId) {
                        toastFailure('Something went wrong')
                        return
                    }

                    if (value !== null) {
                        saveFieldValue(scannerModalState.assemblyLineFieldId, value, () => {
                            dispatch(fetchAssembly(activeAssembly.id))
                        })
                    }
                }}
            />
        </>
    )
}
