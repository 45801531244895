import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    splitPalletRequest,
    getSpotsRequest,
    createPalletRequest,
    deletePalletRequest,
    deleteStackRequest,
    movePalletRequest,
    moveStackRequest,
    updatePalletRequest,
    CreatePalletDTO,
    MovePalletDTO,
    MoveStackDTO,
    DeleteStackDTO,
    DeletePalletDTO,
    UpdatePalletDTO,
    SplitPalletDTO,
} from '../api/warehouse-map'
import { getUsersRequest, UserRelations } from '../api/users'
import {
    Pallet,
    PalletProduct,
    Assembly,
    Product,
    User,
    WarehouseAreaSpot,
    CallOff,
    PalletEvent,
    UserRole,
} from '../models/models'
import { toastFailure, toastSuccess } from '../util/toast'
import { RootState } from './store'
import {
    AssemblyTransferMonumentHiuDTO,
    assemblyTransferMonumentHiuRequest,
    DispatchTransferMonumentHIUDTO,
    dispatchTransferMonumentHiuRequest,
} from '../api/logistics-worksheet'
import { escapeRegex, findHiu } from '../util/util'

export enum ViewType {
    Transfer = 'Transfer',
    Regular = 'Regular',
    AssemblyDispatch = 'AssemblyDispatch',
    CallOffDispatch = 'CallOffDispatch',
    AssemblyStart = 'AssemblyStart',
    AssemblyComplete = 'AssemblyComplete',
}

export enum Area {
    FirstFloor = '1',
    SecondFloor = '2',
    EventLog = '3',
}

export enum SearchType {
    ProjectName = 'ProjectName',
    PalletId = 'PalletId',
    CallOffId = 'CallOffId',
    ProductCode = 'ProductCode',
    AssemblyId = 'AssemblyId',
    OcCode = 'OcCode',
}

export enum SearchedEffect {
    Glow = 'Glow',
    None = 'None',
}

export interface Transfer {
    amountToTransfer: number
    //pallet product is the relation mapping a pallet to a product
    palletProductId: number
}
export interface Transfers {
    [palletId: number]: Transfer
}

export interface EventsByPallet {
    events?: PalletEvent[]
    count?: number
}
export interface StackPopOver {
    spotId?: number
}

export enum SelectedType {
    Pallet = 'Pallet',
    Stack = 'Stack',
    None = 'None',
}

export interface Selected {
    //a spot id if stack, pallet id if pallet.
    selectedId?: number
    type: SelectedType
}

export interface CompleteAssemblyPallet {
    key: string
    amount: number
    spot: WarehouseAreaSpot | null
}

export interface CompleteAssemblyState {
    assembly: Assembly
    // the key of the pallet
    selectedPallet: string | null
    pallets: Array<CompleteAssemblyPallet>
}
export interface WarehouseMapState {
    spots: WarehouseAreaSpot[]
    selected: Selected
    isTablet: boolean
    searchText: string
    searchType: SearchType
    searchedEffect: SearchedEffect
    viewType: ViewType
    transfers: Transfers
    hiuToTransfer: Product | undefined
    completeAssemblyState: CompleteAssemblyState | null
    excludeUnsearchedPallets: boolean
    excludePalletsWithOrders: boolean
    activeModal: ActiveModal
    modalPalletId: number | undefined
    modalProductId: number | undefined
    modalCallOffId: number | undefined
    modalSpotId: number | undefined
    assemblyToTransfer: Assembly | undefined
    assemblyWorksheetToStart: Assembly | undefined
    assemblyWorksheetToComplete: Assembly | undefined
    assemblyToDispatch: Assembly | undefined
    callOffToDispatch: CallOff | undefined
    excludePalletsWithAssemblies: boolean
    disableSearch: boolean
    area: Area
    stackPopOver: StackPopOver
    isLoading: boolean
    assemblers: User[]
    eventsByPallet: EventsByPallet
}

export enum ActiveModal {
    InspectPallet = 'InspectPallet',
    UpdatePallet = 'UpdatePallet',
    SplitPallet = 'SplitPallet',
    DeletePallet = 'DeletePallet',
    AddPallet = 'AddPallet',
    ConfirmPalletMovement = 'ConfirmPalletMovement',
    ConfirmStackMovement = 'ConfirmStackMovement',
    ConfirmAssemblyDispatch = 'ConfirmAssemblyDispatch',
    ConfirmCallOffDispatch = 'ConfirmCallOffDispatch',
    AssemblyTransfer = 'AssemblyTransfer',
    ConfirmCancelTransfer = 'ConfirmCancelTransfer',
    ConfirmTransfer = 'ConfirmTransfer',
    CancelDispatch = 'CancelDispatch',
    InspectBinHoldings = 'InspectBinHoldings',
    None = 'None',
}

const initialState: WarehouseMapState = {
    spots: [],
    selected: { selectedId: undefined, type: SelectedType.None },
    //initial page render should be loading
    isLoading: true,
    searchText: '',
    isTablet: JSON.parse(localStorage.getItem('IS_TABLET') || 'false') || false,
    searchedEffect: SearchedEffect.Glow,
    assemblyToTransfer: undefined,
    assemblyWorksheetToStart: undefined,
    transfers: {},
    hiuToTransfer: undefined,
    assemblyToDispatch: undefined,
    callOffToDispatch: undefined,
    assemblyWorksheetToComplete: undefined,
    viewType: ViewType.Regular,
    activeModal: ActiveModal.None,
    modalPalletId: undefined,
    modalCallOffId: undefined,
    modalSpotId: undefined,
    modalProductId: undefined,
    completeAssemblyState: null,
    excludeUnsearchedPallets: false,
    excludePalletsWithOrders: false,
    excludePalletsWithAssemblies: false,
    disableSearch: false,
    searchType: SearchType.ProjectName,
    area: Area.FirstFloor,
    stackPopOver: { spotId: undefined },
    assemblers: [],
    eventsByPallet: {},
}

export const getSpots = createAsyncThunk('pallets/fetch', async () => {
    return { response: await getSpotsRequest() }
})

export const transferPallets = createAsyncThunk(
    'transfer/pallets',
    async (args: { assemblyId: number; transfers: AssemblyTransferMonumentHiuDTO[] }) => {
        return {
            response: await assemblyTransferMonumentHiuRequest(args.assemblyId, args.transfers),
            assemblyId: args.assemblyId,
        }
    }
)

export const dispatchPallets = createAsyncThunk(
    'dispatch/pallets',
    async (args: DispatchTransferMonumentHIUDTO) => {
        return { response: await dispatchTransferMonumentHiuRequest(args) }
    }
)

export const setAssemblers = createAsyncThunk('assemblers/fetch', async (warehouseId: number) => {
    return {
        response: await getUsersRequest({
            warehouseId,
            roles: [UserRole.Assembler],
            relations: [UserRelations.AssemblerSpots],
        }),
    }
})

export const createPallet = createAsyncThunk('pallets/add', async (body: CreatePalletDTO) => {
    return { response: await createPalletRequest(body) }
})

export const deletePallet = createAsyncThunk('pallets/delete', async (body: DeletePalletDTO) => {
    return { response: await deletePalletRequest(body) }
})

export const deleteStack = createAsyncThunk('stacks/delete', async (body: DeleteStackDTO) => {
    return { response: await deleteStackRequest(body) }
})

export const movePallet = createAsyncThunk('pallets/move', async (body: MovePalletDTO) => {
    return { response: await movePalletRequest(body) }
})

export const moveStack = createAsyncThunk('stack/move', async (body: MoveStackDTO) => {
    return { response: await moveStackRequest(body) }
})

export const updatePallet = createAsyncThunk('palletUpdate/move', async (body: UpdatePalletDTO) => {
    return { response: await updatePalletRequest(body) }
})

export const splitPallet = createAsyncThunk('pallets/split', async (body: SplitPalletDTO) => {
    return { response: await splitPalletRequest(body) }
})

export const warehouseMapSlice = createSlice({
    name: 'warehouseMap',
    initialState,
    reducers: {
        setSearchText(state, action: PayloadAction<string>) {
            state.searchText = action.payload
        },
        setSearchType(state, action: PayloadAction<SearchType>) {
            state.searchType = action.payload
            state.searchText = ''
        },
        setIsTablet(state, action: PayloadAction<boolean>) {
            state.isTablet = action.payload
        },
        setArea(state, action: PayloadAction<Area>) {
            state.area = action.payload
        },
        addTransfer(state, action: PayloadAction<{ palletId: number; transfer: Transfer }>) {
            if (action.payload.transfer.amountToTransfer > 0) {
                state.transfers[action.payload.palletId] = action.payload.transfer
            } else {
                delete state.transfers[action.payload.palletId]
            }
        },
        openModal(
            state,
            action: PayloadAction<{
                modal: ActiveModal
                spotId?: number
                productId?: number
                palletId?: number
                callOffId?: number
            }>
        ) {
            const { modal, palletId, productId, callOffId, spotId } = action.payload
            state.activeModal = modal
            state.modalPalletId = palletId
            state.modalSpotId = spotId
            state.modalCallOffId = callOffId
            state.modalProductId = productId
        },
        updateSpots(state, action: PayloadAction<WarehouseAreaSpot[]>) {
            for (const spot of action.payload) {
                const spotIndex = state.spots.findIndex((s) => s.id === spot.id)
                state.spots[spotIndex] = spot
            }
        },
        updatePallets(state, action: PayloadAction<Pallet[]>) {
            const pallets = state.spots.map((s) => s.pallets).flat()
            for (const pallet of action.payload) {
                const spotIndex = state.spots.findIndex((s) => s.id === pallet.warehouseAreaSpotId)
                const spot = state.spots[spotIndex]
                const foundPallet = pallets.find((p) => p.id === pallet.id)
                //update pallet
                if (foundPallet) {
                    spot.pallets = spot.pallets.map((p) => (p.id === pallet.id ? pallet : p))
                    continue
                }
                spot.pallets = [...state.spots[spotIndex].pallets, pallet]
            }
        },
        updateCompleteAssemblyState(state, action: PayloadAction<CompleteAssemblyState>) {
            state.completeAssemblyState = action.payload
        },
        select(state, action: PayloadAction<Selected>) {
            state.selected.selectedId = action.payload.selectedId
            state.selected.type = action.payload.type
        },
        unSelect(state) {
            state.selected.selectedId = undefined
            state.selected.type = SelectedType.None
        },
        closeModal(state) {
            state.activeModal = ActiveModal.None
            state.modalCallOffId = undefined
            state.modalSpotId = undefined
            state.modalProductId = undefined
            state.modalPalletId = undefined
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        openStackPopOver(state, action: PayloadAction<number>) {
            state.stackPopOver = { spotId: action.payload }
        },
        closeStackPopOver(state) {
            state.stackPopOver = { spotId: undefined }
        },
        setTransferView(state, action: PayloadAction<Assembly>) {
            state.assemblyToTransfer = action.payload
            state.assemblyToDispatch = undefined
            state.disableSearch = true
            state.searchedEffect = SearchedEffect.None
            state.searchType = SearchType.ProductCode
            state.area = Area.FirstFloor
            state.excludePalletsWithOrders = true
            state.searchText =
                findHiu(action.payload.builtItemCallOff.sopBuiltItemOrderline.builtItem)?.code ?? ''
            state.hiuToTransfer = findHiu(
                action.payload.builtItemCallOff.sopBuiltItemOrderline.builtItem
            )
            state.excludeUnsearchedPallets = true
            state.excludePalletsWithAssemblies = true
            state.viewType = ViewType.Transfer
        },
        setAssemblyDispatchView(state, action: PayloadAction<Assembly>) {
            state.assemblyToDispatch = action.payload
            state.disableSearch = true
            state.searchedEffect = SearchedEffect.Glow
            state.searchType = SearchType.AssemblyId
            state.searchText = action.payload.id.toString()
            state.area = Area.SecondFloor
            state.hiuToTransfer = undefined
            state.excludeUnsearchedPallets = false
            state.excludePalletsWithOrders = false
            state.excludePalletsWithAssemblies = false
            state.viewType = ViewType.AssemblyDispatch
        },
        setCallOffDispatchView(state, action: PayloadAction<CallOff>) {
            state.callOffToDispatch = action.payload
            state.disableSearch = false
            state.searchedEffect = SearchedEffect.Glow
            state.searchType = SearchType.CallOffId
            state.area = Area.FirstFloor
            state.searchText = action.payload.id.toString()
            state.excludePalletsWithOrders = false
            state.hiuToTransfer = undefined
            state.excludeUnsearchedPallets = false
            state.transfers = {}
            state.excludePalletsWithAssemblies = false
            state.viewType = ViewType.CallOffDispatch
        },
        setStartAssemblyView(state, action: PayloadAction<Assembly>) {
            state.disableSearch = false
            state.searchedEffect = SearchedEffect.Glow
            state.searchType = SearchType.AssemblyId
            state.searchText = action.payload.id.toString()
            state.disableSearch = true
            state.area = Area.SecondFloor
            state.assemblyWorksheetToStart = action.payload
            state.excludePalletsWithOrders = false
            state.hiuToTransfer = undefined
            state.excludeUnsearchedPallets = false
            state.transfers = {}
            state.excludePalletsWithAssemblies = false
            state.viewType = ViewType.AssemblyStart
        },
        setCompleteAssemblyView(state, action: PayloadAction<CompleteAssemblyState>) {
            state.disableSearch = false
            state.searchedEffect = SearchedEffect.Glow
            state.searchType = SearchType.AssemblyId
            state.searchText = action.payload.assembly.id.toString()
            state.disableSearch = true
            state.area = Area.SecondFloor
            state.assemblyWorksheetToComplete = action.payload.assembly
            state.completeAssemblyState = action.payload
            state.assemblyWorksheetToStart = undefined
            state.excludePalletsWithOrders = false
            state.hiuToTransfer = undefined
            state.excludeUnsearchedPallets = false
            state.transfers = {}
            state.excludePalletsWithAssemblies = false
            state.viewType = ViewType.AssemblyComplete
        },
        setRegularView(state) {
            state.searchText = ''
            state.searchedEffect = SearchedEffect.Glow
            state.assemblyToTransfer = undefined
            state.assemblyWorksheetToStart = undefined
            state.transfers = {}
            state.hiuToTransfer = undefined
            state.assemblyToDispatch = undefined
            state.callOffToDispatch = undefined
            state.assemblyWorksheetToComplete = undefined
            state.assemblyWorksheetToComplete
            state.viewType = ViewType.Regular
            state.completeAssemblyState = null
            state.activeModal = ActiveModal.None
            state.modalPalletId = undefined
            state.modalCallOffId = undefined
            state.modalProductId = undefined
            state.excludeUnsearchedPallets = false
            state.excludePalletsWithOrders = false
            state.excludePalletsWithAssemblies = false
            state.disableSearch = false
            state.searchType = SearchType.ProjectName
            state.area = Area.FirstFloor
        },
    },
    extraReducers: (builder) => {
        builder.addCase(movePallet.fulfilled, (state, action) => {
            if (action.payload.response.successful) {
            } else {
                toastFailure(action.payload.response.message)
            }
        })
        builder.addCase(transferPallets.fulfilled, (state, action) => {
            if (action.payload.response.successful) {
                state.assemblyToTransfer = undefined
                state.assemblyToDispatch = undefined
                state.disableSearch = false
                state.searchedEffect = SearchedEffect.Glow
                state.searchType = SearchType.AssemblyId
                state.searchText = action.payload.assemblyId.toString()
                state.excludePalletsWithOrders = false
                state.hiuToTransfer = undefined
                state.excludeUnsearchedPallets = false
                state.transfers = {}
                state.excludePalletsWithAssemblies = false
                state.viewType = ViewType.Regular
                state.area = Area.SecondFloor
                toastSuccess('Transfer has been performed')
            } else {
                toastFailure(action.payload.response.message)
            }
        })

        builder.addCase(dispatchPallets.fulfilled, (state, action) => {
            if (action.payload.response.successful) {
                state.assemblyToTransfer = undefined
                state.disableSearch = false
                state.searchType = SearchType.ProjectName
                state.searchText = ''
                state.excludePalletsWithOrders = false
                state.hiuToTransfer = undefined
                state.excludeUnsearchedPallets = false
                state.transfers = {}
                state.excludePalletsWithAssemblies = false
                state.viewType = ViewType.Regular
                state.area = Area.FirstFloor
                toastSuccess('Transfer has been performed')
            } else {
                toastFailure(action.payload.response.message)
            }
        })
        builder.addCase(getSpots.fulfilled, (state, action) => {
            if (action.payload.response.successful) {
                state.spots = action.payload.response.data
                state.isLoading = false
            }
        })

        builder.addCase(moveStack.fulfilled, (state, action) => {
            if (action.payload.response.successful) {
                toastSuccess('Stack moved')
            } else {
                toastFailure(action.payload.response.message)
            }
        })

        builder.addCase(createPallet.fulfilled, (state, action) => {
            if (action.payload.response.successful) {
                toastSuccess('Pallet added.')
            } else {
                toastFailure(action.payload.response.message)
            }
        })
        builder.addCase(deleteStack.fulfilled, (state, action) => {
            if (action.payload.response.successful) {
            } else {
                toastFailure(action.payload.response.message)
            }
        })
        builder.addCase(splitPallet.fulfilled, (state, action) => {
            if (action.payload.response.successful) {
                toastSuccess('Pallet has been split')
            } else {
                toastFailure(action.payload.response.message)
            }
        })
        builder.addCase(updatePallet.fulfilled, (state, action) => {
            if (action.payload.response.successful) {
                toastSuccess('Pallet updated')
            } else {
                toastFailure(action.payload.response.message)
            }
        })
        builder.addCase(deletePallet.fulfilled, (state, action) => {
            if (action.payload.response.successful) {
                toastSuccess('Stack deleted')
            } else {
                toastFailure(action.payload.response.message)
            }
        })
        builder.addCase(setAssemblers.fulfilled, (state, action) => {
            if (action.payload.response.successful) {
                state.assemblers = action.payload.response.data.entities
            } else {
                toastFailure(action.payload.response.message)
            }
        })
    },
})

interface WarehouseMapSpots {
    [key: string]: WarehouseAreaSpot
}

export const getWarehouseMapSpotKey = (x: number, y: number) => {
    return `${x}-${y}`
}

export const isPalletSearchedFor = (
    searchType: SearchType,
    searchText: string,
    pallet: Pallet
): boolean => {
    if (searchText === '') {
        return false
    }
    if (searchType === SearchType.PalletId) {
        return pallet.id === Number(searchText)
    } else if (searchType === SearchType.CallOffId) {
        return pallet?.assembly?.callOffId === Number(searchText)
    } else if (searchType === SearchType.OcCode) {
        return pallet?.delivery?.orderConfirmation?.code === searchText
    } else if (searchType === SearchType.ProductCode) {
        return !!pallet.palletProducts.find((pp) => {
            return pp.product.code.match(new RegExp(escapeRegex(searchText), 'i'))
        })
    } else if (searchType === SearchType.ProjectName) {
        return (
            !!pallet?.project?.name.match(new RegExp(escapeRegex(searchText), 'i')) ||
            !!pallet?.assembly?.callOff?.project?.name.match(
                new RegExp(escapeRegex(searchText), 'i')
            )
        )
    } else if (searchType === SearchType.AssemblyId) {
        return pallet.assemblyId === Number(searchText)
    }
    return false
}

export const isSpotSearchedFor = (
    searchType: SearchType,
    searchText: string,
    spot: WarehouseAreaSpot
): boolean => {
    if (searchText === '') {
        return false
    }
    return !!spot.pallets.find((p) => isPalletSearchedFor(searchType, searchText, p))
}

export const selectLogisticsWarehouseMapSpots = (state: RootState): WarehouseMapSpots => {
    const warehouseMapSpots: WarehouseMapSpots = {}

    const {
        spots,
        excludeUnsearchedPallets,
        excludePalletsWithAssemblies,
        excludePalletsWithOrders,
        searchType,
        searchText,
    } = state.warehouseMap
    spots
        .filter((s) => s.warehouseAreaId === 1)
        .forEach((spot) => {
            if (
                excludeUnsearchedPallets ||
                excludePalletsWithAssemblies ||
                excludePalletsWithOrders
            ) {
                spot = { ...spot }
                if (excludeUnsearchedPallets) {
                    spot.pallets = spot.pallets.filter((pallet) =>
                        isPalletSearchedFor(searchType, searchText, pallet)
                    )
                }
                if (excludePalletsWithAssemblies) {
                    spot.pallets = spot.pallets.filter((pallet) => !pallet.assembly)
                }
            }
            warehouseMapSpots[`${spot.x}-${spot.y}`] = spot
        })
    return warehouseMapSpots
}

export const selectAssemblyWarehouseMapSpots = (state: RootState): WarehouseMapSpots => {
    const warehouseMapSpots: WarehouseMapSpots = {}

    const {
        spots,
        excludeUnsearchedPallets,
        excludePalletsWithAssemblies,
        excludePalletsWithOrders,
        searchType,
        searchText,
    } = state.warehouseMap
    spots
        .filter((s) => s.warehouseAreaId === 2)
        .forEach((spot) => {
            if (
                excludeUnsearchedPallets ||
                excludePalletsWithAssemblies ||
                excludePalletsWithOrders
            ) {
                spot = { ...spot }
                if (excludeUnsearchedPallets) {
                    spot.pallets = spot.pallets.filter((pallet) =>
                        isPalletSearchedFor(searchType, searchText, pallet)
                    )
                }
                if (excludePalletsWithAssemblies) {
                    spot.pallets = spot.pallets.filter((pallet) => !pallet.assembly)
                }
            }
            warehouseMapSpots[`${spot.x}-${spot.y}`] = spot
        })
    return warehouseMapSpots
}

export const selectRemandingAmountOfProductsToTransfer = (state: RootState): number => {
    const { assemblyToTransfer, transfers } = state.warehouseMap
    if (!assemblyToTransfer) {
        return NaN
    }
    let remainderAmountOfProductsToTransfer = assemblyToTransfer.amount
    Object.keys(transfers).forEach((palletId) => {
        const transfer = transfers[Number(palletId)]
        remainderAmountOfProductsToTransfer -= transfer.amountToTransfer
    })
    return remainderAmountOfProductsToTransfer
}

interface PalletTransferEntity extends Pallet {
    amountToTransfer: number
    x: number
    y: number
    documentNo: string | undefined
    palletProduct: PalletProduct
}

export const selectPalletTransfers = (state: RootState): PalletTransferEntity[] => {
    const { assemblyToTransfer, transfers, spots } = state.warehouseMap
    if (!transfers || !assemblyToTransfer) {
        return []
    }
    const palletsToTransferFrom: PalletTransferEntity[] = []
    Object.keys(transfers).forEach((palletId) => {
        const transfer = transfers[Number(palletId)]
        const spotWithPallet = spots.find((spot) =>
            spot.pallets.find((pallet) => pallet.id === Number(palletId))
        )
        if (!spotWithPallet) {
            return
        }

        const pallet = spotWithPallet?.pallets.find((p) => p.id === Number(palletId))
        if (!pallet) {
            return
        }
        const palletProduct = pallet?.palletProducts.find(
            (pp) =>
                pp.product.code ===
                findHiu(assemblyToTransfer.builtItemCallOff.sopBuiltItemOrderline.builtItem)?.code
        )

        if (!palletProduct) {
            return
        }

        palletsToTransferFrom.push({
            ...pallet,
            x: spotWithPallet.x,
            y: spotWithPallet.y,
            documentNo: pallet?.delivery?.orderConfirmation?.purchaseOrder?.documentNo,
            warehouseAreaSpot: pallet.warehouseAreaSpot,
            amountToTransfer: transfer.amountToTransfer,
            palletProduct,
        })
    })
    return palletsToTransferFrom
}

export const {
    select,
    updatePallets,
    updateCompleteAssemblyState,
    unSelect,
    openStackPopOver,
    closeStackPopOver,
    setSearchText,
    setSearchType,
    setArea,
    setIsLoading,
    setTransferView,
    setRegularView,
    setCompleteAssemblyView,
    setStartAssemblyView,
    setAssemblyDispatchView,
    setCallOffDispatchView,
    addTransfer,
    setIsTablet,
    openModal,
    closeModal,
    updateSpots,
} = warehouseMapSlice.actions

export default warehouseMapSlice.reducer
