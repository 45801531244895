import { useAppSelector } from '../../../store/hooks'
import { Box } from '@mui/material'
import CTRLoader from '../../ui/loader/CTRloader'

function LoadingOverlay() {
    const isLoading = useAppSelector((state) => state.singleProjectView.isLoading)

    if (!isLoading) {
        return null
    }

    return (
        <Box
            sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                background: '#ffffffbb',
            }}
        >
            <Box
                component="div"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <CTRLoader size="xl" />
            </Box>
        </Box>
    )
}

export default LoadingOverlay
