import PageHeader from '../ui/PageHeader'
import { Warehouse, WarehouseId } from '../../models/models'
import { Box } from '@mui/system'
import moment, { Moment } from 'moment'
import LoadingOverlay from '../project-master/single-project-view/LoadingOverlay'
import ProductionCalendarItem from './ProductionCalendarItem'
import { Urls } from '../../util/urls'
import styled from '@emotion/styled'
import { Select } from 'antd'

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

const FilterElement = styled('div')``

interface Props {
    currentDate: Moment
    setCurrentDate: (date: Moment) => void
    setSelectedDate: (date: Moment) => void
    selectedWarehouse: Warehouse
    selectedWarehouseId: WarehouseId
    setSelectedWarehouseId: (warehouse: WarehouseId) => void
}

export default function ProductionCalendar({
    currentDate,
    setCurrentDate,
    setSelectedDate,
    selectedWarehouse,
    selectedWarehouseId,
    setSelectedWarehouseId,
}: Props) {
    const breadcrumbs = [
        { link: Urls.Landing, name: 'Main Page' },
        {
            link: Urls.ProductionSchedule,
            name: 'Production Schedule',
        },
    ]

    if (!selectedWarehouse) {
        return <LoadingOverlay />
    }

    const yearOptions: { value: number; label: string }[] = Array.from(
        { length: 20 },
        (v, i) => currentDate.year() - 10 + i + 1
    ).map((year) => ({ value: year, label: year.toString() }))

    return (
        <>
            <PageHeader title="Production Schedule" breadcrumbs={breadcrumbs}>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        padding: '40px 0 8px 0',
                    }}
                >
                    <Box sx={{ flexGrow: 1 }} />
                    <FilterElement>
                        <Label>Warehouse</Label>
                        <Select
                            style={{ width: '200px' }}
                            value={selectedWarehouseId}
                            onChange={(value: WarehouseId) => setSelectedWarehouseId(value)}
                            options={[
                                { value: WarehouseId.Monument, label: 'Monument' },
                                { value: WarehouseId.Scandia, label: 'Scandia' },
                            ]}
                        />
                    </FilterElement>
                    <FilterElement>
                        <Label>Month</Label>
                        <Select
                            value={currentDate.format('MMM')}
                            onChange={(value) => setCurrentDate(currentDate.clone().month(value))}
                            options={moment
                                .monthsShort()
                                .map((mon) => ({ value: mon, label: mon }))}
                        />
                    </FilterElement>
                    <FilterElement>
                        <Label>Year</Label>
                        <Select
                            value={currentDate.year()}
                            onChange={(value) => {
                                setCurrentDate(currentDate.clone().year(value))
                            }}
                            options={yearOptions}
                        />
                    </FilterElement>
                </Box>
            </PageHeader>
            <ProductionCalendarItem
                selectedWarehouse={selectedWarehouse}
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                setSelectedDate={setSelectedDate}
            />
        </>
    )
}
