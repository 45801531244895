/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useMemo, useRef } from 'react'
import { Box } from '@mui/system'
import { Pallet as PalletModel, PalletProduct, WarehouseAreaSpot } from '../../models/models'
import { useAppSelector } from '../../store/hooks'
import {
    closeStackPopOver,
    isSpotSearchedFor,
    openStackPopOver,
    SearchedEffect,
    select,
    SelectedType,
    unSelect,
    ViewType,
} from '../../store/warehouse-map-slice'
import _ from 'lodash'
import { styled } from '@mui/material'
import { StackPopOver } from './StackPopOver'
import { useDispatch } from 'react-redux'

interface PalletStackProps {
    spot: WarehouseAreaSpot
}

const BASE_FONT_SIZE = 4 // in px

const Container = styled(Box)(() => ({
    position: 'relative',
}))

type ContentContainerProps = {
    isSearchedFor: boolean
    searchedEffect: SearchedEffect
    isSelected: boolean
    isPalletsWithinAssemblyTransferred: boolean
    isPalletWithinSelected: boolean
    isPalletsWithinOnAssemblyToStart: boolean
}
const ContentContainer = styled(Box, {
    shouldForwardProp: (propName) =>
        propName !== 'isSearchedFor' &&
        propName !== 'searchedEffect' &&
        propName !== 'isSelected' &&
        propName !== 'isPalletsWithinAssemblyTransferred' &&
        propName !== 'isPalletWithinSelected' &&
        propName !== 'isPalletsWithinOnAssemblyToStart',
})<ContentContainerProps>((props) => {
    const {
        isSearchedFor,
        searchedEffect,
        isPalletsWithinAssemblyTransferred,
        isSelected,
        isPalletWithinSelected,
        isPalletsWithinOnAssemblyToStart,
    } = props

    const GREEN_OUTLINE = '1px solid green'
    const YELLOW_OUTLINE = '1px solid #663d7d'

    const border = () => {
        if (isPalletsWithinAssemblyTransferred) {
            return GREEN_OUTLINE
        }
        if (isSelected) {
            return GREEN_OUTLINE
        }
        if (isPalletWithinSelected) {
            return YELLOW_OUTLINE
        }
        return 'none'
    }

    const backgroundColor = () => {
        if (
            isPalletsWithinOnAssemblyToStart ||
            (isSearchedFor && searchedEffect === SearchedEffect.Glow)
        ) {
            return 'orange'
        }
        return 'transparent'
    }

    return {
        backgroundColor: backgroundColor(),
        border: border(),
        color: 'inherit',
        outline: 'none',
        fontSize: '4px',
        fontWeight: 'bold',
        cursor: 'pointer',
        zIndex: 0,
        width: '100%',
        top: '0',
        left: '0',
        height: 0,
        paddingBottom: '100%',
        lineHeight: 'normal',
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        textAlign: 'left',
    }
})
const MainTextContainer = styled(Box)(() => ({ padding: '3px', overflow: 'hidden' }))
const StackSizeContainer = styled(Box)(() => ({
    position: 'absolute',
    right: '2px',
    bottom: '3px',
    fontSize: `${BASE_FONT_SIZE + 3}px`,
}))
const ProjectNameContainer = styled(Box)(() => ({}))
const ProductCodesContainer = styled(Box)(() => ({ fontSize: '3px' }))

/**
 * This component is a child to Spot, and is rendered when a spot have multiple pallets as opposed
 * to PalletModel when not.
 * @param param0
 * @returns
 */
export default function Stack({ spot }: PalletStackProps) {
    const {
        searchText,
        searchType,
        viewType,
        searchedEffect,
        transfers,
        stackPopOver,
        selected,
        assemblyWorksheetToStart,
    } = useAppSelector((state) => state.warehouseMap)
    const { pallets } = spot
    const containerRef = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()

    // If all pallets have the same project, display it
    const labelProjectName = useMemo(() => {
        const project = pallets[0]?.project
        if (!project || !pallets.every((p) => p?.project?.id === project.id)) {
            return
        }
        return project.name
    }, [pallets])

    const labelProductCodes = useMemo(() => {
        return pallets.reduce((stackProductAmounts, pallet) => {
            //create an object with the product code as key and amount as value for single pallet

            const palletProductAmounts = pallet.palletProducts.reduce(
                (productAmounts, palletProduct) => {
                    if (productAmounts[palletProduct.product.code]) {
                        productAmounts[palletProduct.product.code] += palletProduct.amount
                    } else {
                        productAmounts[palletProduct.product.code] = palletProduct.amount
                    }
                    return productAmounts
                },
                {} as { [productCode: string]: number }
            )
            //add the amounts to the total for all pallets
            Object.keys(palletProductAmounts).forEach((productCode) => {
                if (stackProductAmounts[productCode]) {
                    stackProductAmounts[productCode] += palletProductAmounts[productCode]
                } else {
                    stackProductAmounts[productCode] = palletProductAmounts[productCode]
                }
            })
            return stackProductAmounts
        }, {} as { [productCode: string]: number })
    }, [pallets])

    const isSelected = useMemo(() => {
        return selected.selectedId === spot.id && selected.type === SelectedType.Stack
    }, [selected, spot])

    const isPalletWithinSelected = useMemo(() => {
        return (
            !!selected.selectedId &&
            selected.selectedId === spot.id &&
            !isSelected &&
            !!spot.pallets.find((p) => p.id === selected.selectedId)
        )
    }, [selected, spot])

    const hasOpenPopOver = useMemo(() => {
        return stackPopOver.spotId === spot.id
    }, [stackPopOver, spot])

    const palletProductsSum = useMemo(() => {
        let palletsAddProductsAmountSum = 0
        pallets.forEach((pallet) => {
            pallet.palletProducts.forEach((palletProduct) => {
                palletsAddProductsAmountSum += palletProduct.amount
            })
        })
        return palletsAddProductsAmountSum
    }, [pallets])

    const isSearchedFor = useMemo(() => {
        return isSpotSearchedFor(searchType, searchText, spot)
    }, [searchType, searchText, spot])

    const isPalletsWithinOnAssemblyToStart = useMemo(() => {
        if (viewType !== ViewType.AssemblyStart || !assemblyWorksheetToStart) {
            return false
        }
        const hasPalletsWithAssemblyWithin = pallets.find((pallet) => {
            pallet.assemblyId === assemblyWorksheetToStart.id
        })
        return hasPalletsWithAssemblyWithin !== undefined
    }, [pallets, viewType, assemblyWorksheetToStart])

    const isPalletsWithinAssemblyTransferred = useMemo(() => {
        if (viewType !== ViewType.Transfer) {
            return false
        }
        const transferredProduct = pallets.find((pallet) => {
            return pallet?.palletProducts.find((palletProduct: PalletProduct) => {
                return Object.values(transfers).find(
                    (transfer) => transfer.palletProductId === palletProduct.id
                )
            })
        })
        return transferredProduct !== undefined
    }, [pallets, viewType])

    const onClicks: { [key in ViewType]: (e: React.MouseEvent<HTMLElement>) => void } = {
        [ViewType.AssemblyComplete]: (e: React.MouseEvent<HTMLElement>) => {},
        [ViewType.AssemblyDispatch]: (e: React.MouseEvent<HTMLElement>) => {
            //if pallet within is selected
            if (isPalletWithinSelected) {
                e.stopPropagation()
                dispatch(unSelect())
                return
            }
            //if other selected and click, do nothing. The spot opens transfer modal
            if (!isSelected && selected.selectedId) {
                return
            }
            e.stopPropagation()
            //if it is currently selected, unselect
            if (isSelected) {
                dispatch(unSelect())
                return
            }
            //if nothing is currently selected, open the popover to select something
            if (!hasOpenPopOver) {
                dispatch(openStackPopOver(spot.id))
                return
            }
            //if the popover is already open and the stack is clicked, select it
            dispatch(
                select({
                    selectedId: spot.id,
                    type: SelectedType.Stack,
                })
            )
            dispatch(closeStackPopOver())
        },
        [ViewType.AssemblyStart]: () => {
            if (!hasOpenPopOver) {
                dispatch(openStackPopOver(spot.id))
                return
            } else {
                dispatch(closeStackPopOver())
            }
        },
        [ViewType.CallOffDispatch]: (e: React.MouseEvent<HTMLElement>) => {
            //if pallet within is selected
            if (isPalletWithinSelected) {
                e.stopPropagation()
                dispatch(unSelect())
                return
            }
            //if other selected and click, do nothing. The spot opens transfer modal
            if (!isSelected && selected.selectedId) {
                return
            }
            e.stopPropagation()
            //if it is currently selected, unselect
            if (isSelected) {
                dispatch(unSelect())
                return
            }
            //if nothing is currently selected, open the popover to select something
            if (!hasOpenPopOver) {
                dispatch(openStackPopOver(spot.id))
                return
            }
            //if the popover is already open and the stack is clicked, select it
            dispatch(
                select({
                    selectedId: spot.id,
                    type: SelectedType.Stack,
                })
            )
            dispatch(closeStackPopOver())
        },
        [ViewType.Regular]: (e: React.MouseEvent<HTMLElement>) => {
            //if pallet within is selected
            if (isPalletWithinSelected) {
                e.stopPropagation()
                dispatch(unSelect())
                return
            }
            //if other selected and click, do nothing. The spot opens transfer modal
            if (!isSelected && selected.selectedId) {
                return
            }
            e.stopPropagation()
            //if it is currently selected, unselect
            if (isSelected) {
                dispatch(unSelect())
                return
            }
            //if nothing is currently selected, open the popover to select something
            if (!hasOpenPopOver) {
                dispatch(openStackPopOver(spot.id))
                return
            }
            //if the popover is already open and the stack is clicked, select it
            dispatch(
                select({
                    selectedId: spot.id,
                    type: SelectedType.Stack,
                })
            )
            dispatch(closeStackPopOver())
        },
        [ViewType.Transfer]: (e: React.MouseEvent<HTMLElement>) => {
            //if pallet within is selected
            if (isPalletWithinSelected) {
                e.stopPropagation()
                dispatch(unSelect())
                return
            }
            //if other selected and click, do nothing. The spot opens transfer modal
            if (!isSelected && selected.selectedId) {
                return
            }
            e.stopPropagation()
            //if it is currently selected, unselect
            if (isSelected) {
                dispatch(unSelect())
                return
            }
            //if nothing is currently selected, open the popover to select something
            if (!hasOpenPopOver) {
                dispatch(openStackPopOver(spot.id))
                return
            }
            dispatch(closeStackPopOver())
        },
    }

    return (
        <Container
            onClick={(e: React.MouseEvent<HTMLElement>) => onClicks[viewType](e)}
            ref={containerRef}
        >
            <ContentContainer
                id={String(spot.id)}
                isSearchedFor={isSearchedFor}
                isPalletsWithinAssemblyTransferred={isPalletsWithinAssemblyTransferred}
                searchedEffect={searchedEffect}
                isSelected={isSelected}
                isPalletWithinSelected={isPalletWithinSelected}
                isPalletsWithinOnAssemblyToStart={isPalletsWithinOnAssemblyToStart}
            >
                {hasOpenPopOver && <StackPopOver spot={spot} parentRef={containerRef} />}
                <MainTextContainer>
                    <ProjectNameContainer>{labelProjectName}</ProjectNameContainer>
                    {palletProductsSum && (
                        <ProductCodesContainer>
                            <>
                                {Object.entries(labelProductCodes).map(([code, amount], index) => (
                                    <span key={code + amount + index}>
                                        {amount} x {code} <br />
                                    </span>
                                ))}
                            </>
                        </ProductCodesContainer>
                    )}
                </MainTextContainer>
                <StackSizeContainer>{pallets.length}</StackSizeContainer>
            </ContentContainer>
        </Container>
    )
}
