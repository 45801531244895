import { ComponentsOverrides } from '@mui/material/styles/overrides'

export const InputLabelTheme: {
    styleOverrides: ComponentsOverrides['MuiInputLabel']
} = {
    styleOverrides: {
        root: {
            fontSize: 16,
            lineHeight: '24px',
        },
        shrink: {
            transform: 'translate(14px, -9px) scale(0.75) !important',
        },
        outlined: {
            transform: 'translate(14px, 8px) scale(1)',
        },
    },
}
