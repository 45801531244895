import { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Input, Modal, Select, Button, Checkbox, InputNumber, Table } from 'antd'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { styled } from '@mui/material'
import '../../tables/table-styles.css'
import { OrderedListOutlined, DeleteOutlined } from '@ant-design/icons'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../store/hooks'
import { ActiveModal, closeModal } from '../../../store/products-slice'
import { CreateBomGroupDto, createBomGroupRequest } from '../../../api/bom-groups'
import { AssemblyField, WorksheetCompletionType } from '../../../models/models'
import { SearchInput } from '../../ui/SearchInput'
import { getAssemblyFieldByIdRequest, getAssemblyFieldsRequest } from '../../../api/assembly-fields'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DraggableBodyRow } from '../../project-master/sales-orders/create/OrderlinesTable'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 47%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

enum ModalState {
    Error = 'Error',
    Ready = 'Ready',
}

export const CreateBomGroupModal = () => {
    const { activeModal } = useSelector((state: RootState) => state.products)
    const [worksheetCompletionType, setWorksheetCompletionType] = useState<WorksheetCompletionType>(
        WorksheetCompletionType.IndividualComplete
    )
    const [name, setName] = useState<string>('')
    const [modalState, setModalState] = useState<ModalState>(ModalState.Ready)
    const [assemblyFields, setAssemblyFields] = useState<AssemblyField[]>([])
    const dispatch = useAppDispatch()

    const resetState = () => {
        setWorksheetCompletionType(WorksheetCompletionType.IndividualComplete)
        setName('')
        setAssemblyFields([])
        setModalState(ModalState.Ready)
    }

    useEffect(() => {
        if (worksheetCompletionType === WorksheetCompletionType.CollectiveComplete) {
            setAssemblyFields([])
        }
    }, [worksheetCompletionType])

    const disableSubmit = useMemo(() => {
        if (!name) {
            return true
        }
        if (worksheetCompletionType === WorksheetCompletionType.IndividualComplete) {
            return assemblyFields.length === 0
        }
        return false
    }, [name, worksheetCompletionType, assemblyFields])

    const onClose = () => {
        resetState()
        dispatch(closeModal())
    }

    const onMove = (fromIndex: number, toIndex: number) => {
        const temp = [...assemblyFields]
        const element = assemblyFields[fromIndex]
        temp.splice(fromIndex, 1)
        temp.splice(toIndex, 0, element)
        setAssemblyFields(temp)
    }

    const onOk = async () => {
        const body: CreateBomGroupDto = {
            name,
            worksheetCompletionType,
        }

        if (worksheetCompletionType === WorksheetCompletionType.IndividualComplete) {
            body.lines = assemblyFields.map((af) => af.id)
        }

        const response = await createBomGroupRequest(body)
        if (!response.successful) {
            toastFailure(response.message)
            return
        }
        toastSuccess(`Created Bom Group ${response.data.id}`)
        dispatch(closeModal(JSON.stringify(response.data)))
        resetState()
    }

    return (
        <Modal open={activeModal === ActiveModal.BomGroupCreate} footer={null} onCancel={onClose}>
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.Ready && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Create Bom Group</Title>
                    </Header>
                    <Divider />
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Name</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </RowElement>
                        <RowElement></RowElement>
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Type</Label>
                            <Select
                                style={{ width: '100%' }}
                                value={worksheetCompletionType}
                                onChange={(value: WorksheetCompletionType) =>
                                    setWorksheetCompletionType(value)
                                }
                                options={[
                                    {
                                        value: WorksheetCompletionType.IndividualComplete,
                                        label: 'Individual Complete',
                                    },
                                    {
                                        value: WorksheetCompletionType.CollectiveComplete,
                                        label: 'Collective Complete',
                                    },
                                ]}
                            />
                        </RowElement>
                        <RowElement></RowElement>
                    </InputRow>

                    {worksheetCompletionType === WorksheetCompletionType.IndividualComplete && (
                        <>
                            <InputRow style={{ marginBottom: '20px' }}>
                                <RowElement>
                                    <Label>Assembly Lines</Label>
                                    <SearchInput
                                        style={{ width: '100%' }}
                                        placeholder="Assembly Fields"
                                        selectedValue={undefined}
                                        excludeValues={assemblyFields.map((af) => af.id)}
                                        setSelectedValue={(id) => {
                                            if (!id) {
                                                return
                                            }
                                            getAssemblyFieldByIdRequest(id, {}).then((r) => {
                                                if (r.successful) {
                                                    setAssemblyFields([...assemblyFields, r.data])
                                                }
                                            })
                                        }}
                                        request={(search: string) =>
                                            getAssemblyFieldsRequest({
                                                excludeIds: assemblyFields.map((af) => af.id),
                                                name: search,
                                                limit: 100,
                                            }).then((r) =>
                                                r.successful
                                                    ? r.data.entities.map((assemblyField) => ({
                                                          label: assemblyField.name,
                                                          value: assemblyField.id,
                                                      }))
                                                    : [{ label: 'None', value: 2 }]
                                            )
                                        }
                                    />
                                </RowElement>
                                <RowElement />
                            </InputRow>
                            <DndProvider backend={HTML5Backend}>
                                <Table
                                    dataSource={assemblyFields}
                                    components={{
                                        body: {
                                            row: DraggableBodyRow,
                                        },
                                    }}
                                    onRow={(_, index) => {
                                        const attr = {
                                            index,
                                            onMove,
                                        }
                                        return attr as React.HTMLAttributes<any>
                                    }}
                                    columns={[
                                        {
                                            title: 'Name',
                                            dataIndex: 'name',
                                            key: 'name',
                                        },
                                        {
                                            title: 'Delete',
                                            dataIndex: 'id',
                                            key: 'id',
                                            render: (id: number) => (
                                                <Button
                                                    shape="circle"
                                                    onClick={() => {
                                                        setAssemblyFields(
                                                            assemblyFields.filter(
                                                                (assemblyField) =>
                                                                    assemblyField.id !== id
                                                            )
                                                        )
                                                    }}
                                                    icon={<DeleteOutlined />}
                                                />
                                            ),
                                        },
                                    ]}
                                    pagination={false}
                                />
                            </DndProvider>
                        </>
                    )}
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                        <Button type={'primary'} disabled={disableSubmit} onClick={onOk}>
                            Create
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    )
}
