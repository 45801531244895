import { styled } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { useAppDispatch } from '../../../store/hooks'
import { ActiveModal, closeModal, setActiveView, TaskView } from '../../../store/tasks-slice'
import PageHeader from '../../ui/PageHeader'
import { Urls } from '../../../util/urls'
import Tasks from './Tasks'
import TaskTypes from './TaskTypes'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import UpdateTaskModal from '../../modals/update-task-modal/UpdateTaskModal'
import DeleteTaskModal from '../../modals/delete-task-modal/DeleteTaskModal'
import InspectTaskModal from '../../modals/inspect-task-modal/InspectTaskModal'
import DeleteTaskTypeModal from '../../modals/delete-task-type-modal/DeleteTaskTypeModal'
import CreateTaskTypeModal from '../../modals/create-task-type-modal/CreateTaskTypeModal'
import UpdateTaskTypeModal from '../../modals/update-task-type-modal/UpdateTaskTemplateModal'
import InspectTaskTypeModal from '../../modals/inspect-task-type-modal/InspectTaskTemplateModal'
import CreateTaskModal from '../../modals/create-task-modal/CreateTaskModal'

const Container = styled('div')``

const ContentContainer = styled('div')`
    padding: 25px;
`

export default function TasksRoute() {
    const { activeView, modalEntityId, activeModal, filterWarehouseId } = useSelector(
        (state: RootState) => state.tasks
    )
    const dispatch = useAppDispatch()

    const tabsOptions: TabsProps['items'] = [
        {
            key: TaskView.Tasks,
            label: `Tasks`,
            children: <Tasks />,
        },
        {
            key: TaskView.Types,
            label: `Types`,
            children: <TaskTypes />,
        },
    ]

    return (
        <Container>
            <PageHeader
                title="Tasks"
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        link: Urls.Tasks,
                        name: 'Tasks',
                    },
                ]}
            />
            <ContentContainer>
                <Tabs
                    type="card"
                    activeKey={activeView}
                    onChange={(view) => dispatch(setActiveView(view as TaskView))}
                    defaultActiveKey={TaskView.Tasks}
                    items={tabsOptions}
                />

                {activeModal === ActiveModal.TaskCreate && (
                    <CreateTaskModal
                        warehouseId={filterWarehouseId}
                        closeModal={(task) => {
                            dispatch(closeModal(JSON.stringify(task)))
                        }}
                    />
                )}

                {modalEntityId && activeModal === ActiveModal.TaskDelete && (
                    <DeleteTaskModal
                        taskId={modalEntityId}
                        closeModal={(task) => dispatch(closeModal(JSON.stringify(task)))}
                    />
                )}
                {modalEntityId && activeModal === ActiveModal.TaskUpdate && (
                    <UpdateTaskModal
                        taskId={modalEntityId}
                        closeModal={(task) => dispatch(closeModal(JSON.stringify(task)))}
                    />
                )}
                {modalEntityId && activeModal === ActiveModal.TaskInspect && (
                    <InspectTaskModal
                        taskId={modalEntityId}
                        closeModal={() => dispatch(closeModal())}
                    />
                )}

                {activeModal === ActiveModal.TaskTypeCreate && (
                    <CreateTaskTypeModal
                        closeModal={(taskType) => dispatch(closeModal(JSON.stringify(taskType)))}
                    />
                )}

                {modalEntityId && activeModal === ActiveModal.TaskTypeDelete && (
                    <DeleteTaskTypeModal
                        taskTypeId={modalEntityId}
                        closeModal={(taskType) => {
                            dispatch(closeModal(JSON.stringify(taskType)))
                        }}
                    />
                )}
                {modalEntityId && activeModal === ActiveModal.TaskTypeUpdate && (
                    <UpdateTaskTypeModal
                        taskTypeId={modalEntityId}
                        closeModal={(taskType) => dispatch(closeModal(JSON.stringify(taskType)))}
                    />
                )}
                {modalEntityId && activeModal === ActiveModal.TaskTypeInspect && (
                    <InspectTaskTypeModal
                        taskTypeId={modalEntityId}
                        closeModal={() => dispatch(closeModal())}
                    />
                )}
            </ContentContainer>
        </Container>
    )
}
