import { Chip } from '@mui/material'
import { Box, styled } from '@mui/system'
import { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import moment from 'moment'
import { PurchaseOrder, WarehouseId } from '../../../../models/models'
import {
    GetManyPurchaseOrderRelation,
    getPurchaseOrdersRequest,
} from '../../../../api/purchase-orders'
import { toastFailure } from '../../../../util/toast'
import InspectPurchaseOrderModal from '../../../modals/inspect-purchase-order-modal/InspectPurchaseOrderModal'
import BookInModal from '../modals/BookInModal'
import OrderConfirmationsTable from '../../../tables/OrderConfirmationsTable'
import {
    GetOrderConfirmationRelations,
    getOrderConfirmationsRequest,
} from '../../../../api/order-confirmation'
import InspectOrderConfirmationModal from '../../../modals/inspect-order-confirmation-modal/InspectOrderConfirmationModal'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { RootState } from '../../../../store/store'
import { FilterBar } from '../Filterbar'

interface Props {
    warehouseId: WarehouseId
}

enum ActiveModal {
    None = 'None',
    InspectPurchaseOrder = 'InspectPurchaseOrder',
    InspectOrderConfirmation = 'InspectOrderConfirmation',
    BookIn = 'BookIn',
}

const Container = styled(Box)(`

`)

const TableContainer = styled(Box)(`
    margin-top: 20px;
`)

export default function PurchaseOrders({ warehouseId }: Props) {
    const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([])
    const [purchaseOrdersCount, setPurchaseOrdersCount] = useState<number>()
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)

    const [activeModal, setActiveModal] = useState<ActiveModal>(ActiveModal.None)
    const [modalPurchaseOrderId, setModalPurchaseOrderId] = useState<number>()
    const [modalOrderConfirmationId, setModalOrderConfirmationId] = useState<number>()
    const [purchaseOrderCreators, setPurchaseOrderCreators] = useState<string[]>([])
    const [purchaseOrderSuppliers, setPurchaseOrderSuppliers] = useState<string[]>([])
    const {
        filterDocumentNo,
        filterPurchaseOrderCreator,
        filterPurchaseOrderSupplier,
        filterIsPurchaseOrderActivated,
        filterPurchaseOrderStatus,
    } = useAppSelector((state: RootState) => state.logisticsWorksheets)
    const dispatch = useAppDispatch()

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const getPurchaseOrders = async () => {
        const response = await getPurchaseOrdersRequest({
            relations: [
                GetManyPurchaseOrderRelation.Orderlines,
                GetManyPurchaseOrderRelation.OrderConfirmations,
                GetManyPurchaseOrderRelation.Deliveries,
            ],
            supplier: filterPurchaseOrderSupplier,
            createdBy: filterPurchaseOrderCreator,
            isActivated: filterIsPurchaseOrderActivated,
            status: filterPurchaseOrderStatus,
            withCount: true,
            completed: false,
            activated: true,
            likeDocumentNo: filterDocumentNo,
            warehouseId,
            //antd tables pages are 1-indexed
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        })
        if (response.successful) {
            setPurchaseOrders(response.data.purchaseOrders)
            setPurchaseOrdersCount(response.data.count)
        } else {
            toastFailure(response.message)
        }
    }

    const openModal = (conf: {
        purchaseOrderId?: number
        orderConfirmationId?: number
        modal: ActiveModal
    }) => {
        setActiveModal(conf.modal)
        setModalPurchaseOrderId(conf.purchaseOrderId)
        setModalOrderConfirmationId(conf.orderConfirmationId)
    }

    const closeModal = () => {
        setActiveModal(ActiveModal.None)
        setModalPurchaseOrderId(undefined)
    }

    useEffect(() => {
        getPurchaseOrders()
    }, [
        rowsPerPage,
        page,
        warehouseId,
        filterDocumentNo,
        filterPurchaseOrderSupplier,
        filterPurchaseOrderCreator,
        filterIsPurchaseOrderActivated,
        filterPurchaseOrderStatus,
    ])

    const tableColumns = [
        {
            title: 'Document number',
            dataIndex: 'documentNo',
            key: 'documentNo',
        },
        {
            title: 'Document created by',
            dataIndex: 'documentCreatedBy',
            key: 'documentCreatedBy',
        },
        {
            title: 'Supplier name',
            key: 'supplierName',
            dataIndex: 'supplierName',
        },
        {
            title: 'activated at',
            key: 'activatedAt',
            render: (purchaseOrder: PurchaseOrder) => {
                if (purchaseOrder.activatedDate) {
                    return moment(purchaseOrder.activatedDate).format('DD-MM-YYYY')
                }
                return 'Not activated'
            },
        },
        {
            title: 'Actions',
            key: 'stock item',
            render: (purchaseOrder: PurchaseOrder) => {
                return (
                    <>
                        <Button
                            style={{ marginRight: '10px' }}
                            onClick={() =>
                                openModal({
                                    purchaseOrderId: purchaseOrder.id,
                                    modal: ActiveModal.InspectPurchaseOrder,
                                })
                            }
                        >
                            Inspect
                        </Button>
                        <Button
                            onClick={() =>
                                openModal({
                                    purchaseOrderId: purchaseOrder.id,
                                    modal: ActiveModal.BookIn,
                                })
                            }
                        >
                            Book In
                        </Button>
                    </>
                )
            },
        },
        {
            title: 'On hold',
            key: 'isOnHold',
            render: (purchaseOrder: PurchaseOrder) => {
                return purchaseOrder.isOnHold ? (
                    <Chip
                        sx={{
                            marginTop: '10px',
                            color: '#ff1a1a',
                            whiteSpace: 'normal',
                            flexWrap: 'wrap',
                            borderColor: '#ff1a1a',
                            backgroundColor: '#ffcccc',
                        }}
                        label="Is on hold"
                        variant="outlined"
                    />
                ) : null
            },
        },
    ]

    const expandedRowRender = (purchaseOrder: PurchaseOrder) => {
        return (
            <OrderConfirmationsTable
                actionComponents={[
                    (orderConfirmationId: number) => (
                        <Button
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                                openModal({
                                    orderConfirmationId: orderConfirmationId,
                                    modal: ActiveModal.InspectOrderConfirmation,
                                })
                            }}
                        >
                            Inspect
                        </Button>
                    ),
                ]}
                pagination={true}
                request={() =>
                    getOrderConfirmationsRequest({
                        purchaseOrderId: purchaseOrder.id,
                        relations: [
                            GetOrderConfirmationRelations.Author,
                            GetOrderConfirmationRelations.Deliveries,
                            GetOrderConfirmationRelations.GoodsDelivered,
                            GetOrderConfirmationRelations.Orderlines,
                        ],
                    })
                }
            />
        )
    }

    return (
        <Container>
            <FilterBar />
            <TableContainer>
                <Table
                    rowKey="id"
                    columns={tableColumns}
                    dataSource={purchaseOrders}
                    expandable={{ expandedRowRender }}
                    size="small"
                    pagination={{
                        defaultPageSize: 10,
                        current: page,
                        onChange: pageChange,
                        total: purchaseOrdersCount,
                    }}
                />
            </TableContainer>

            {activeModal === ActiveModal.InspectPurchaseOrder && modalPurchaseOrderId && (
                <InspectPurchaseOrderModal
                    active={true}
                    onOk={() => {
                        closeModal()
                        getPurchaseOrders()
                    }}
                    purchaseOrderId={modalPurchaseOrderId}
                    onClose={closeModal}
                />
            )}
            {activeModal === ActiveModal.InspectOrderConfirmation && modalOrderConfirmationId && (
                <InspectOrderConfirmationModal
                    active={true}
                    orderConfirmationId={modalOrderConfirmationId}
                    onClose={closeModal}
                />
            )}
            {activeModal === ActiveModal.BookIn && modalPurchaseOrderId && (
                <BookInModal
                    active={true}
                    onOk={() => {
                        closeModal()
                        getPurchaseOrders()
                    }}
                    purchaseOrderId={modalPurchaseOrderId}
                    onClose={closeModal}
                />
            )}
        </Container>
    )
}
