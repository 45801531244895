import { Modal } from 'antd'
import { styled } from '@mui/material'
import backendConnection from '../../../../api/util/backend-connection'
import { useEffect, useState } from 'react'

interface Props {
    onCancel: () => void
    assemblyId: number
}

const StyledModal = styled(Modal)`
    height: 90vh;
    top: 50%;
    transform: translateY(-50%);

    * {
        background: #3d3d3d !important;
    }

    .ant-modal-content {
        overflow: hidden;
        height: 90vh;
    }

    .ant-modal-close {
        display: none;
    }
`

const Container = styled('div')`
    position: relative;
    overflow: hidden;
    width: 90vw;
    height: 90vh;
    margin: -24px;
`

function LabelsModal(props: Props) {
    const [url, setUrl] = useState<string | null>(null)

    useEffect(() => {
        backendConnection
            .request({
                url: `/assembly-worksheets/${props.assemblyId}/labels/download`,
                responseType: 'blob',
            })
            .then((response) => {
                if (response.successful) {
                    const url = URL.createObjectURL(response.data)
                    setUrl(url)
                }
            })
    }, [])

    if (!url) {
        return null
    }

    return (
        <StyledModal
            visible={true}
            footer={null}
            title={null}
            width={'90vw'}
            onCancel={props.onCancel}
        >
            <Container>
                <embed src={url} width="100%" height="100%" type="application/pdf" />
            </Container>
        </StyledModal>
    )
}

export default LabelsModal
