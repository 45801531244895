import { AssemblyLineField } from '../models/models'
import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'

export enum AssemblyFieldRelations {
    AssemblyLine = 'assemblyLine',
}

export interface GetAssemblyFieldsDto {
    relations?: AssemblyFieldRelations[]
    ids?: number[]
    excludeIds?: number[]
    id?: number
    withDeleted?: boolean
    skip?: number
    limit?: number
}

export const getAssemblyFieldByIdRequest = (
    id: number,
    dto: GetAssemblyFieldsDto
): Promise<Response<AssemblyLineField>> =>
    backendConnection.request({
        url: `/assembly-fields/${id}`,
        params: dto,
        method: 'GET',
    })

export const getAssemblyFieldsRequest = (
    dto: GetAssemblyFieldsDto
): Promise<Response<{ entities: AssemblyLineField[]; count: number }>> =>
    backendConnection.request({
        url: `/assembly-fields`,
        params: dto,
        method: 'GET',
    })

export interface UpdateValueDto {
    value: string | null
}

export const updateAssemblyLineFieldValueRequest = (
    id: number,
    dto: UpdateValueDto
): Promise<Response<AssemblyLineField>> =>
    backendConnection.request({
        url: `/assembly-line-fields/value/${id}`,
        data: dto,
        method: 'PUT',
    })
