import { createTheme } from '@mui/material/styles'
import { InputLabelTheme } from './InputLabelTheme'
import { InputBaseTheme } from './InputBaseTheme'
import { ButtonThemeStyles } from './button-theme-styles'
import { BackgroundColors, TextColors } from './colors'
import { TextFieldThemeStyles } from './text-field-theme-styles'
import { SelectThemeStyles } from './select-theme-styles'
import { OutlinedInputThemeStyles } from './outlined-input-theme-styles'
import { InputThemeStyles } from './input-theme-styles'
import { TablePaginationThemeStyles } from './table-pagination-theme-styles'

export const mainSAVTheme = createTheme({
    typography: {
        h2: {
            fontSize: 20,
            fontWeight: 500,
            color: TextColors.black,
        },
        body1: {
            fontSize: 14,
        },
    },
    palette: {
        primary: {
            main: TextColors.purple,
            light: TextColors.lightPurple,
            dark: TextColors.darkPurple,
            contrastText: TextColors.white,
        },
        secondary: {
            main: TextColors.white,
            dark: '#D3D3D3', // Darker gray
            contrastText: TextColors.black,
        },
        text: {
            primary: TextColors.black,
            secondary: TextColors.lightGrey,
        },
    },
    components: {
        MuiButton: ButtonThemeStyles,
        MuiTextField: TextFieldThemeStyles,
        MuiSelect: SelectThemeStyles,
        MuiOutlinedInput: OutlinedInputThemeStyles,
        MuiInputBase: InputBaseTheme,
        MuiInputLabel: InputLabelTheme,
        MuiInput: InputThemeStyles,
        MuiCheckbox: {
            variants: [
                {
                    props: { color: 'secondary' },
                    style: {
                        '& svg': {
                            color: 'white',
                        },
                    },
                },
            ],
        },
        MuiLink: { styleOverrides: { root: { textDecoration: 'none' } } },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    zIndex: 100,
                    backgroundColor: BackgroundColors.purple,
                },
            },
        },
        MuiTablePagination: TablePaginationThemeStyles,
    },
})
