import { Table, TableColumnType, Typography } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { Response } from '../../api/util/with-response-formatter-interceptor'
import { Bom } from '../../models/models'
import { toastFailure } from '../../util/toast'
import useDebounce from '../project-master/single-project-view/hooks'
import '../tables/table-styles.css'

interface Props {
    style?: React.CSSProperties
    pagination?: boolean
    //antd ColumnType<T> does not have title property
    columns?: Array<TableColumnType<Bom>>
    actionComponents?: Array<(bom: Bom) => JSX.Element>
    dependencies?: any
    request: (pagination?: {
        skip: number
        limit: number
    }) => Promise<Response<{ count: number; entities: Bom[] }>>
}

export const BomsTableColumns: { [key in string]: TableColumnType<Bom> } = {
    Id: {
        title: 'Id',
        key: 'id',
        render: (bom: Bom) => {
            return bom.id
        },
    },
    Code: {
        title: 'code',
        key: 'Code',
        render: (bom: Bom) => {
            return bom.bomCode
        },
    },
    ProductName: {
        title: 'Product Name',
        key: 'ProductName',
        render: (bom: Bom) => {
            return bom?.product?.name ?? 'Could not find product'
        },
    },
    Description: {
        title: 'Description',
        key: 'Description',
        render: (bom: Bom) => {
            return bom.product.description
        },
    },
    AssemblyTime: {
        title: 'Assembly Time',
        key: 'AssemblyTime',
        render: (bom: Bom) => {
            return `${bom?.assemblyMinutes} minutes`
        },
    },
    BomGroupName: {
        title: 'Bom Group Name',
        key: 'bomGroupName',
        render: (bom: Bom) => {
            return bom?.group?.name
        },
    },
    Instruction: {
        title: 'Instruction',
        key: 'instruction',
        render: (bom: Bom) => {
            return bom?.instructionLink ?? 'None'
        },
    },
    InstructionEllipsis: {
        title: 'Instruction',
        key: 'instructionEllipsis',
        width: '100px',
        render: (bom: Bom) => {
            return (
                <Typography.Text ellipsis style={{ width: '350px' }}>
                    {bom?.instructionLink ?? 'None'}
                </Typography.Text>
            )
        },
    },
} as const

const DEFAULT_COLUMNS = [
    BomsTableColumns.Id,
    BomsTableColumns.Code,
    BomsTableColumns.ProductName,
    BomsTableColumns.BomGroupName,
    BomsTableColumns.AssemblyTime,
    BomsTableColumns.Instruction,
]

export default function BomsTable({
    style,
    request,
    columns,
    pagination,
    actionComponents,
    dependencies,
}: Props) {
    const [boms, setBoms] = useState<Bom[]>([])
    const [bomsAmount, setBomsAmount] = useState<number>(0)
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)

    const getBomsDeps = useDebounce(JSON.stringify(dependencies), 200)

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const tableColumns = useMemo(() => {
        let cols = columns ? Object.values(columns) : DEFAULT_COLUMNS
        if (actionComponents) {
            cols = [
                ...cols,
                {
                    title: 'Actions',
                    key: 'actions',
                    render: (bom: Bom) => {
                        return (<>{actionComponents.map((a) => a(bom))}</>) as any
                    },
                },
            ]
        }
        return cols
    }, [columns])

    const getBoms = async (resetPage = false) => {
        const response = await request({
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        })

        if (response.successful) {
            const { count, entities } = response.data
            resetPage && setPage(1)
            setBomsAmount(count)
            setBoms(entities)
        } else {
            toastFailure(response.message)
        }
    }

    // this happens when dependencies change outside the table, e.g filters given in with getBomsDeps
    useEffect(() => {
        getBoms(true)
    }, [getBomsDeps, rowsPerPage])

    // this happens when dependencies change outside the table, e.g filters given in with getBomsDeps
    useEffect(() => {
        getBoms()
    }, [page])

    return (
        <Table
            style={style}
            rowKey="id"
            columns={tableColumns}
            dataSource={boms}
            expandable={undefined}
            size="small"
            pagination={
                pagination
                    ? {
                          defaultPageSize: 10,
                          current: page,
                          onChange: pageChange,
                          total: bomsAmount,
                      }
                    : false
            }
        />
    )
}
