import { Box, Dialog, Divider, IconButton, Paper, Typography } from '@mui/material'
import { useState } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import { DeleteOutlined } from '@mui/icons-material'
import { uploadOtherBoms } from '../../../api/unauthorized-bom'
import CloseIcon from '@mui/icons-material/Close'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { Alert, Button } from 'antd'
import { FileArea } from '../../ui/FileArea'

interface UploadOtherBomVersionModalProps {
    isVisible: boolean
    onSubmitted?: () => void
    onClose: () => void
}

export const ImportSageBomVersionModal = ({
    isVisible,
    onSubmitted,
    onClose,
}: UploadOtherBomVersionModalProps) => {
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [file, setFile] = useState<File>()

    const onUpload = async () => {
        if (!file) {
            return
        }
        try {
            setIsUploading(true)
            const response = await uploadOtherBoms(file)
            if (!response.successful) {
                toastFailure(response.message)
                return
            }

            toastSuccess('Created and/or updated BoMs')

            if (onSubmitted) {
                onSubmitted()
            }
            onClose()
        } catch (e) {
        } finally {
            setIsUploading(false)
        }
    }

    return (
        <Dialog open={isVisible} onClose={onClose} sx={{ zIndex: '100' }} fullWidth maxWidth="sm">
            <Paper sx={{ padding: '20px' }}>
                <Box
                    sx={{
                        fontsize: 16,
                        fontWeight: 'bold',
                        padding: '15px 0px 10px 0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div> Upload sage bom versions </div>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Alert
                    style={{ marginBottom: '20px' }}
                    message={`
                    In this modal you can import an exported Sage .xml file with boms for existing built items. 
                    By importing the file, it will either create the Bom if none exists, or update create a Bom Version if a Bom already exists.`}
                    type="info"
                    showIcon
                />
                <Alert
                    style={{ marginBottom: '20px' }}
                    message={`
                    Be mindful that exported BOM codes from sage should only include versions with the status "active". The exported file does not differentiate between obsolote and active versions.`}
                    type="warning"
                    showIcon
                />
                <FileArea
                    onDrop={(file: File) => {
                        setFile(file)
                    }}
                    selectedFile={file}
                />
                {file && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <Typography sx={{ color: '#3500A0' }}>{file.name}</Typography>

                        <DeleteOutlined
                            style={{
                                fontSize: 20,
                                marginLeft: '15px',
                                color: 'red',
                                cursor: 'pointer',
                            }}
                            onClick={() => setFile(undefined)}
                        />
                    </Box>
                )}
                <Button
                    style={{ marginTop: '20px' }}
                    icon={<SaveOutlined />}
                    disabled={!file || isUploading}
                    onClick={onUpload}
                >
                    Upload Sage Boms
                </Button>
            </Paper>
        </Dialog>
    )
}
