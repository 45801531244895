/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box, styled } from '@mui/system'
import { createProjectStockItems } from '../../../../api/projects/stock-items'
import { toastFailure, toastSuccess } from '../../../../util/toast'
import { Project } from '../../../../models/models'
import { useAppSelector } from '../../../../store/hooks'
import { RoundBorderContainer } from '../../../ui/container/RoundBorderContainer'
import { useContext, useState } from 'react'
import TabNavigation from '../../../ui/navigation/TabNavigation'
import { AddedBuiltItem, AddedStockItem } from './ProductInfo'
import { HighlightOff } from '@mui/icons-material/'
import IconButton from '@mui/material/IconButton'
import { CartItemsContext } from '../project-info/CartItems'
import { createProjectBuiltItems } from '../../../../api/projects/built-items'
import { Button } from 'antd'

const Container = styled(Box)({ display: 'flex', flexDirection: 'column' })

export enum AddedItemsTabKeys {
    BuiltItems = 'BUILT_ITEMS',
    StockItems = 'STOCK_ITEMS',
}

interface Props {
    tab: AddedItemsTabKeys
}

export default function AddedItemsOverview({ tab }: Props) {
    const [activeTab, setActiveTab] = useState<AddedItemsTabKeys>(tab)
    const { addedStockItems, addedBuiltItems, setAddedStockItems, setAddedBuiltItems } =
        useContext(CartItemsContext)
    const project: Project | null = useAppSelector(
        (state) => state.singleProjectView.projectBeingUpdated
    )

    const addToProject = async () => {
        let createProjectBuiltItemsPromise
        if (addedBuiltItems.length > 0) {
            createProjectBuiltItemsPromise = createProjectBuiltItems(project!.id, {
                builtItems: addedBuiltItems.map((bi): { id: number; amount: number } => ({
                    id: bi.id,
                    amount: bi.amount,
                })),
            })
        }
        let createProjectStockItemsPromise
        if (addedStockItems.length > 0) {
            createProjectStockItemsPromise = createProjectStockItems(project!.id, {
                stockItems: addedStockItems.map((si): { id: number; amount: number } => ({
                    id: si.id,
                    amount: si.amount,
                })),
            })
        }

        const [builtItemResponse, stockItemResponse] = await Promise.all([
            createProjectBuiltItemsPromise,
            createProjectStockItemsPromise,
        ])

        let success = true
        if (builtItemResponse) {
            if (builtItemResponse.successful) {
                setAddedBuiltItems([])
            } else {
                success = false
                toastFailure('Could not add the stock items to the project')
            }
        }
        if (stockItemResponse) {
            if (stockItemResponse.successful) {
                setAddedStockItems([])
            } else {
                success = false
                toastFailure('Could not add the stock items to the project')
            }
        }
        if (success) {
            toastSuccess('Added items to the project!')
        }
    }

    return (
        <RoundBorderContainer>
            <Container>
                <TabNavigation
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    tabStyles={{ textAlign: 'center' }}
                    tabConfigurations={[
                        {
                            tabKey: AddedItemsTabKeys.BuiltItems,
                            text: 'Built items',
                            component: (
                                <CartItems
                                    items={addedBuiltItems}
                                    removeItems={(id) =>
                                        setAddedBuiltItems(
                                            addedBuiltItems.filter((x) => x.id !== id)
                                        )
                                    }
                                />
                            ),
                        },
                        {
                            tabKey: AddedItemsTabKeys.StockItems,
                            text: 'Stock items',
                            component: (
                                <CartItems
                                    items={addedStockItems}
                                    removeItems={(id) =>
                                        setAddedStockItems(
                                            addedStockItems.filter((x) => x.id !== id)
                                        )
                                    }
                                />
                            ),
                        },
                    ]}
                />
                {[...addedStockItems, ...addedBuiltItems].length > 0 && (
                    <Button onClick={addToProject}>Confirm</Button>
                )}
            </Container>
        </RoundBorderContainer>
    )
}

interface CartItemsProps {
    items: AddedStockItem[] | AddedBuiltItem[]
    removeItems: (id: number) => void
}

const CartItems = ({ items, removeItems }: CartItemsProps) => {
    return (
        <Table size="small" style={{ marginTop: '20px' }}>
            <TableHead>
                <TableRow>
                    <TableCell>BOM Code</TableCell>
                    <TableCell>Quantity</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map((row) => (
                    <TableRow key={JSON.stringify(row)}>
                        <TableCell>{'code' in row ? row.code : row.bomCode}</TableCell>
                        <TableCell>
                            {row.amount}
                            <IconButton aria-label="close" onClick={() => removeItems(row.id)}>
                                <HighlightOff fontSize={'small'} />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
