import { Table, TableColumnType } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { Response } from '../../api/util/with-response-formatter-interceptor'
import { TaskType } from '../../models/models'
import { toastFailure } from '../../util/toast'
import useDebounce from '../project-master/single-project-view/hooks'

interface Props {
    pagination?: boolean
    //antd ColumnType<T> does not have title property
    columns?: Array<TableColumnType<TaskType>>
    actionComponents?: Array<(taskTypeId: number) => JSX.Element>
    dependencies?: any
    request: (pagination?: {
        skip: number
        limit: number
    }) => Promise<Response<{ count: number; entities: TaskType[] }>>
}

export const TaskTypeTableColumns = {
    Id: {
        title: 'Id',
        key: 'id',
        render: (taskType: TaskType) => {
            return taskType.id
        },
    },
    Title: {
        title: 'Title',
        key: 'title',
        render: (taskType: TaskType) => {
            return taskType.title
        },
    },
    Description: {
        title: 'Description',
        key: 'description',
        render: (taskType: TaskType) => {
            return taskType.description
        },
    },
    Author: {
        title: 'Created by',
        key: 'createdBy',
        render: (taskType: TaskType) => {
            return taskType?.createdByUser
                ? `${taskType?.createdByUser?.firstName} ${taskType?.createdByUser?.lastName}`
                : 'None'
        },
    },
    Minutes: {
        title: 'Minutes',
        key: 'minutes',
        render: (taskType: TaskType) => {
            return taskType.minutes
        },
    },
} as const

const DEFAULT_COLUMNS = [
    TaskTypeTableColumns.Title,
    TaskTypeTableColumns.Author,
    TaskTypeTableColumns.Minutes,
    TaskTypeTableColumns.Description,
]

export default function TaskTypeTable({
    request,
    columns,
    pagination,
    actionComponents,
    dependencies,
}: Props) {
    const [taskTypes, setTaskType] = useState<TaskType[]>([])
    const [taskTypesAmount, setTaskTypeAmount] = useState<number>(0)
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)

    const getTaskTypeDeps = useDebounce(JSON.stringify(dependencies), 200)

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const tableColumns = useMemo(() => {
        let cols = columns ? Object.values(columns) : DEFAULT_COLUMNS
        if (actionComponents) {
            cols = [
                ...cols,
                {
                    title: 'Actions',
                    key: 'actions',
                    render: (taskType: TaskType) => {
                        return (<>{actionComponents.map((a) => a(taskType.id))}</>) as any
                    },
                },
            ]
        }
        return cols
    }, [columns])

    const getTaskType = async (resetPage = false) => {
        const response = await request({
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        })

        if (response.successful) {
            const { count, entities } = response.data
            resetPage && setPage(1)
            setTaskTypeAmount(count)
            setTaskType(entities)
        } else {
            toastFailure(response.message)
        }
    }

    // this happens when dependencies change outside the table, e.g filters given in with getTaskTypeDeps
    useEffect(() => {
        getTaskType(true)
    }, [getTaskTypeDeps, rowsPerPage])

    // this happens when dependencies change outside the table, e.g filters given in with getTaskTypeDeps
    useEffect(() => {
        getTaskType()
    }, [page])

    return (
        <Table
            rowKey="id"
            columns={tableColumns}
            dataSource={taskTypes}
            expandable={undefined}
            size="small"
            pagination={
                pagination
                    ? {
                          defaultPageSize: 10,
                          current: page,
                          onChange: pageChange,
                          total: taskTypesAmount,
                      }
                    : false
            }
        />
    )
}
