import { Table, TableColumnType } from 'antd'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { Response } from '../../api/util/with-response-formatter-interceptor'
import { GeneralAssemblyLine } from '../../models/models'
import { toastFailure } from '../../util/toast'
import useDebounce from '../project-master/single-project-view/hooks'

export type TableLine = Partial<GeneralAssemblyLine> & Pick<GeneralAssemblyLine, 'id'>

type Request = (pagination?: {
    skip: number
    limit: number
}) => Promise<Response<{ count: number; entities: TableLine[] }>>

interface Props {
    rowClassName?: (line: TableLine) => string
    pagination?: boolean
    //antd ColumnType<T> does not have title property
    columns?: Array<TableColumnType<TableLine>>
    actionComponents?: Array<(generalAssemblyLineId: number) => JSX.Element>
    dependencies?: any
    datasource?: TableLine[]
    request?: Request
}

export const GeneralAssemblyLinesTableColumns = {
    Id: {
        title: 'Id',
        key: 'id',
        render: (generalAssemblyLine: TableLine) => {
            return generalAssemblyLine?.id
        },
    },
    Note: {
        title: 'Note',
        key: 'note',
        width: '350px',
        render: (generalAssemblyLine: TableLine) => {
            return generalAssemblyLine?.note
        },
    },
    AssembledBy: {
        title: 'Assembled By',
        key: 'assembledBy',
        render: (generalAssemblyLine: TableLine) => {
            return generalAssemblyLine?.assembledBy
                ? `${generalAssemblyLine?.assembledBy?.firstName} ${generalAssemblyLine?.assembledBy?.lastName}`
                : 'None'
        },
    },
    CompletedAt: {
        title: 'Completed at',
        key: 'completedAt',
        render: (generalAssemblyLine: TableLine) => {
            return generalAssemblyLine?.completedAt
                ? moment(generalAssemblyLine?.completedAt).format('DD/MM/YYYY')
                : 'Not completed'
        },
    },
} as const

const DEFAULT_COLUMNS = [
    GeneralAssemblyLinesTableColumns.Id,
    GeneralAssemblyLinesTableColumns.AssembledBy,
    GeneralAssemblyLinesTableColumns.CompletedAt,
]

export default function GeneralAssemblyLinesTable({
    request,
    columns,
    pagination,
    rowClassName,
    actionComponents,
    datasource,
    dependencies,
}: Props) {
    const [generalAssemblies, setGeneralAssemblyLines] = useState<TableLine[]>([])
    const [generalAssembliesAmount, setGeneralAssemblyLinesAmount] = useState<number>(0)
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)

    const getGeneralAssemblyLinesDeps = useDebounce(JSON.stringify(dependencies), 200)

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const tableColumns = useMemo(() => {
        let cols = columns ? Object.values(columns) : DEFAULT_COLUMNS
        if (actionComponents) {
            cols = [
                ...cols,
                {
                    title: 'Actions',
                    key: 'actions',
                    render: (generalAssemblyLine: TableLine) => {
                        return (
                            <>{actionComponents.map((a) => a(generalAssemblyLine.id))}</>
                        ) as any
                    },
                },
            ]
        }
        return cols
    }, [columns])

    const getGeneralAssemblyLinesFromRequest = async ({
        resetPage,
        request,
    }: {
        resetPage: boolean
        request: Request
    }) => {
        const response = await request({
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        })

        if (response.successful) {
            const { count, entities } = response.data
            resetPage && setPage(1)
            setGeneralAssemblyLinesAmount(count)
            setGeneralAssemblyLines(entities)
        } else {
            toastFailure(response.message)
        }
    }

    const getGeneralAssemblyLinesFromDatasource = async ({ resetPage }: { resetPage: boolean }) => {
        if (!datasource) {
            return
        }
        resetPage && setPage(1)
        setGeneralAssemblyLinesAmount(datasource.length)
        if (pagination) {
            const start = page * rowsPerPage - rowsPerPage
            const end = start + rowsPerPage
            setGeneralAssemblyLines(datasource?.slice(start, end))
        } else {
            setGeneralAssemblyLines(datasource)
        }
    }

    const getGeneralAssemblyLines = async ({ resetPage }: { resetPage: boolean }) => {
        if (datasource) {
            getGeneralAssemblyLinesFromDatasource({ resetPage })
            return
        }
        if (request) {
            getGeneralAssemblyLinesFromRequest({ resetPage, request })
            return
        }
    }

    // this happens when dependencies change outside the table, e.g filters given in with getGeneralAssemblyLinesDeps
    useEffect(() => {
        getGeneralAssemblyLines({ resetPage: true })
    }, [getGeneralAssemblyLinesDeps, rowsPerPage, datasource])

    // this happens when dependencies change outside the table, e.g filters given in with getGeneralAssemblyLinesDeps
    useEffect(() => {
        getGeneralAssemblyLines({ resetPage: false })
    }, [page])

    return (
        <Table
            rowClassName={rowClassName}
            rowKey="id"
            columns={tableColumns}
            dataSource={generalAssemblies}
            expandable={undefined}
            size="small"
            pagination={
                pagination
                    ? {
                          defaultPageSize: 10,
                          current: page,
                          onChange: pageChange,
                          total: generalAssembliesAmount,
                      }
                    : false
            }
        />
    )
}
