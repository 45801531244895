import { Button, Divider, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from '../../../store/hooks'
import { ActiveModal, openModal, setRegularView } from '../../../store/warehouse-map-slice'
import { Urls } from '../../../util/urls'
import { ActionBarContainer } from './ActionBarContainer'
import { InfoColumn } from './InfoColumn'

const InfoColumnsContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
}))

const ButtonColumnsContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
}))

export const CallOffDispatchActionBar = () => {
    const { callOffToDispatch, spots } = useAppSelector((state) => state.warehouseMap)
    const history = useHistory()
    const dispatch = useDispatch()

    if (!callOffToDispatch) {
        return null
    }
    const amountOfPalletsOnCallOff = callOffToDispatch?.assemblies.reduce((acc, cur) => {
        return acc + cur.pallets.length
    }, 0)

    //for performance
    const creationAreaSpots = spots.filter((spot) => spot.isCreationSpot)

    const amountOfCallOffPalletsInCreationArea = creationAreaSpots.reduce((acc, cur) => {
        return (
            acc +
                cur?.pallets.filter((p) =>
                    callOffToDispatch.assemblies.find((assembly) => assembly.id === p.assemblyId)
                )?.length || 0
        )
    }, 0)

    return (
        <ActionBarContainer>
            <InfoColumnsContainer>
                <InfoColumn
                    description="Pallets to transfer"
                    content={`${amountOfCallOffPalletsInCreationArea} / ${amountOfPalletsOnCallOff}`}
                    disableDivider
                />
            </InfoColumnsContainer>
            <ButtonColumnsContainer>
                <Button
                    onClick={() => {
                        history.push(Urls.WorksheetsLogistics)
                        dispatch(setRegularView())
                    }}
                >
                    Cancel dispatch
                </Button>
                <Button
                    onClick={() =>
                        dispatch(
                            openModal({
                                modal: ActiveModal.ConfirmCallOffDispatch,
                                callOffId: callOffToDispatch.id,
                            })
                        )
                    }
                >
                    Confirm dispatch
                </Button>
            </ButtonColumnsContainer>
        </ActionBarContainer>
    )
}
