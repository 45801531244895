import { Alert, Button, Modal } from 'antd'
import { CircularProgress, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
    ModalState,
    closeCompleteAssemblyLineModal,
    completeLine,
    updateCompleteAssemblyLineModal,
} from '../../../../store/assembly-worksheets-slice'
import { toastFailure } from '../../../../util/toast'
import { useEffect, useState } from 'react'
import { getUserCurrentActiveAssemblyActivityRequest } from '../../../../api/assembly-activites'
import { AssemblyActivity, AssemblyLine } from '../../../../models/models'
import { getAssemblyLineByIdRequest } from '../../../../api/assembly-line'

export const CompleteAssemblyLineModal = () => {
    const dispatch = useAppDispatch()
    const { assemblyLineId, state } = useAppSelector(
        (state) => state.assemblyWorksheets.completeAssemblyLineModalState
    )
    const authenticatedUser = useAppSelector((state) => state.authentication.user)

    const [assemblyLine, setAssemblyLine] = useState<AssemblyLine>()
    const [currentActivity, setCurrentActivity] = useState<AssemblyActivity>()

    const initiateModal = async () => {
        if (state === ModalState.Closed) {
            return
        }
        if (!assemblyLineId || !authenticatedUser) {
            toastFailure('Failed to retrieve ressources necessary for modal')
            dispatch(updateCompleteAssemblyLineModal({ state: ModalState.Error }))
            return
        }

        const [currentAcivityResponse, assemblyLineResponse] = await Promise.all([
            getUserCurrentActiveAssemblyActivityRequest(),
            getAssemblyLineByIdRequest(assemblyLineId),
        ])

        if (!currentAcivityResponse.successful || !assemblyLineResponse.successful) {
            toastFailure('Failed to retrieve the users activity or the worksheet')
            dispatch(updateCompleteAssemblyLineModal({ state: ModalState.Error }))
            return
        }

        const currentActivity = currentAcivityResponse.data
        const assemblyLine = assemblyLineResponse.data

        if (!currentActivity) {
            toastFailure('Failed to retrieve the users activity')
            dispatch(updateCompleteAssemblyLineModal({ state: ModalState.Error }))
            return
        }

        setCurrentActivity(currentActivity)
        setAssemblyLine(assemblyLine)
        dispatch(updateCompleteAssemblyLineModal({ state: ModalState.Ready }))
    }

    const completeAssemblyLine = async () => {
        if (!assemblyLine) {
            return
        }
        dispatch(completeLine(assemblyLine))
    }

    useEffect(() => {
        if (state === ModalState.Opened) {
            dispatch(updateCompleteAssemblyLineModal({ state: ModalState.Loading }))
            initiateModal()
        }
    }, [state])

    const footer = (
        <div>
            <Button disabled={false} onClick={() => dispatch(closeCompleteAssemblyLineModal())}>
                Close
            </Button>
            <Button
                type="primary"
                disabled={state !== ModalState.Ready}
                onClick={completeAssemblyLine}
            >
                Complete Assembly Line
            </Button>
        </div>
    )

    const getModalText = () => {
        if (!assemblyLine) {
            return <Typography>Failed to find the assembly line</Typography>
        }
        if (!currentActivity) {
            return (
                <Typography>
                    In order to complete the assembly line, you need an activity for this assembly
                </Typography>
            )
        }
        return (
            <>
                <Alert
                    message={
                        <Typography>
                            Completing this assembly line will mark the unit as being completed by
                            you at this time
                        </Typography>
                    }
                    type="warning"
                />
                <br />
                <Typography></Typography>
            </>
        )
    }

    return (
        <Modal
            title={<Typography sx={{ fontWeight: 'bold' }}>{'Complete assembly line'}</Typography>}
            open={state !== ModalState.Closed}
            width={700}
            onCancel={() => dispatch(closeCompleteAssemblyLineModal())}
            footer={footer}
        >
            <div>
                {state === ModalState.Error && <div>Failed to load the modal</div>}
                {state === ModalState.Loading && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                    </div>
                )}
                {state === ModalState.Ready && <div>{getModalText()}</div>}
            </div>
        </Modal>
    )
}
