import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getNotificationsRequest } from '../api/notifications'

export enum StoreStage {
    Initial,
    Loading,
    ServerError,
}

export interface NotificationState {
    unseenAmount: number
    unreactedAmount: number
}

const initialState: NotificationState = {
    unseenAmount: 0,
    unreactedAmount: 0,
}

export const fetchUnseenAmount = createAsyncThunk(
    'notifications/fetch-unseen',
    async (userId: number) => {
        return {
            response: await getNotificationsRequest({
                limit: 0,
                userId,
                onlyUnseen: true,
            }),
        }
    }
)

export const fetchUnreactedAmount = createAsyncThunk(
    'notifications/fetch-unreacted',
    async (userId: number) => {
        return {
            response: await getNotificationsRequest({
                limit: 0,
                userId,
                onlyUnreacted: true,
            }),
        }
    }
)

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setUnseenAmount: (state, action: PayloadAction<number>) => {
            state.unseenAmount = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUnseenAmount.fulfilled, (state, action) => {
            if (action.payload.response.successful) {
                state.unseenAmount = action.payload.response.data.count
            }
        })

        builder.addCase(fetchUnreactedAmount.fulfilled, (state, action) => {
            if (action.payload.response.successful) {
                state.unreactedAmount = action.payload.response.data.count
            }
        })
    },
})

export const { setUnseenAmount } = notificationSlice.actions

export default notificationSlice.reducer
