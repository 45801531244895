import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { ComponentsVariants } from '@mui/material/styles/variants'
import { BorderColors, TextColors } from './colors'

export const TextFieldThemeStyles: {
    styleOverrides: ComponentsOverrides['MuiTextField']
    variants: ComponentsVariants['MuiTextField']
} = {
    styleOverrides: {
        root: {
            height: 40,
            borderRadius: 10,
            fontSize: 16,
            lineHeight: '24px',
            textTransform: 'none',
        },
    },
    variants: [
        {
            props: { color: 'secondary' },
            style: {
                '& label.Mui-focused': {
                    color: TextColors.white,
                },
                '& label': {
                    color: TextColors.white,
                },
                '& input': {
                    color: TextColors.white,
                },
                '& input:-webkit-autofill': {
                    transition: 'all 0s 50000s',
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: BorderColors.white,
                    },
                    '&:hover fieldset': {
                        borderColor: BorderColors.darkGrey,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: BorderColors.white,
                    },
                },
            },
        },
        {
            props: { variant: 'outlined', color: 'primary' },
            style: {
                '& .MuiOutlinedInput-root': {
                    borderRadius: 10,
                },
            },
        },
    ],
}
