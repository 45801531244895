import { styled } from '@mui/styles'
import { Box } from '@mui/system'

const Footer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: '10px',
    flexWrap: 'wrap',
})

interface CardFooterProps {
    children: React.ReactNode[] | React.ReactNode
}
export default function CardFooter({ children }: CardFooterProps) {
    return <Footer sx={{ paddingBottom: '0px' }}>{children}</Footer>
}
