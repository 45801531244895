import { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Modal, Button, Typography } from 'antd'
import { User } from '../../../models/models'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { CircularProgress, styled } from '@mui/material'
import '../../tables/table-styles.css'
import { deleteUserRequest, getUserRequest } from '../../../api/users'
import { OrderedListOutlined } from '@ant-design/icons'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

interface UpdateUserModalProps {
    isVisible: boolean
    userId: number | null
    onDeleted: (user: User) => void
    closeModal: (user?: User) => void
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

export const DeleteUserModal = ({
    userId,
    closeModal,
    isVisible,
    onDeleted,
}: UpdateUserModalProps) => {
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const [user, setUser] = useState<User>()

    useEffect(() => {
        getUser()
    }, [isVisible])

    const getUser = async () => {
        if (!userId) {
            setModalState(ModalState.NotFound)
            return
        }
        const response = await getUserRequest(userId, {
            relations: [],
        })

        if (response.successful) {
            const user = response.data
            setUser(user)
            setModalState(ModalState.Success)
        } else {
            if (response.status === 404) {
                setModalState(ModalState.NotFound)
            } else {
                setModalState(ModalState.Error)
            }
            toastFailure(response.message)
        }
    }

    const deleteUser = async () => {
        if (!userId || !user) {
            toastFailure('Something went wrong deleting user')
            return
        }
        const response = await deleteUserRequest(userId)
        if (response.successful) {
            toastSuccess(
                `Successfully deleted ${user ? `${user.firstName} ${user.lastName}` : 'user'}`
            )
            onDeleted(user)
            closeModal(response.data)
            return
        }
        toastFailure(`Something went wrong deleting user ${userId}`)
    }

    return (
        <Modal open={isVisible} footer={null} onCancel={() => closeModal()}>
            {modalState === ModalState.Loading && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        margin: '20px',
                        width: '100%',
                        height: '500px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                </Box>
            )}
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.NotFound && (
                <Box>Could not find the user with the id of {userId}</Box>
            )}
            {modalState === ModalState.Success && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Delete {user ? `${user.firstName} ${user.lastName}` : 'User'}</Title>
                    </Header>
                    <Divider />

                    <Typography.Text type="danger">
                        Are you sure you want to delete{' '}
                        {user ? `${user.firstName} ${user.lastName}` : 'this user'}? It will make
                        them uninteractable in the system.
                    </Typography.Text>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                        <Button type="primary" danger onClick={deleteUser}>
                            Delete
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    )
}
