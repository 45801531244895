import {
    FormControl,
    InputLabel,
    Select,
    TextField,
    Typography,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material'
import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { TaxCode } from '../../../../../../models/models'

const Row = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '15px',
}))

const RowContent = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: '10px',
}))

const RowDescriptionContainer = styled(Box)(() => ({
    display: 'flex',
    paddingLeft: '10px',
    width: '30%',
}))

const RowContentContainer = styled(Box)(() => ({
    width: '70%',
}))

interface ReadyProps {
    address1: string
    setAddress1: (value: string) => void
    address2: string
    setAddress2: (value: string) => void
    address3: string
    setAddress3: (value: string) => void
    address4: string
    setAddress4: (value: string) => void
    postcode: string
    setPostcode: (value: string) => void
    county: string
    setCounty: (value: string) => void
    description: string
    setDescription: (value: string) => void
    taxCodeOptions: TaxCode[]
    selectedTaxCode: TaxCode | undefined
    setSelectedTaxCode: (value: TaxCode | undefined) => void
}
export default function Ready(props: ReadyProps) {
    const {
        address1,
        address2,
        address3,
        address4,
        county,
        postcode,
        description,
        setAddress1,
        setAddress2,
        setAddress3,
        setAddress4,
        setCounty,
        setPostcode,
        setDescription,
        taxCodeOptions,
        selectedTaxCode,
        setSelectedTaxCode,
    } = props

    const findOptionById = (id: number) => {
        return taxCodeOptions.find((taxCodeOption) => taxCodeOption.id === id)
    }

    const handleSelectTaxCodeChange = (e: SelectChangeEvent<number>) => {
        setSelectedTaxCode(findOptionById(Number(e.target.value)))
    }

    return (
        <Box>
            <Row>
                <RowContent>
                    <RowDescriptionContainer>
                        <Typography sx={{ color: '#26262690' }}>Description</Typography>
                    </RowDescriptionContainer>
                    <RowContentContainer>
                        <TextField
                            key={'Description'}
                            label={'Description'}
                            sx={{ width: 350 }}
                            defaultValue={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </RowContentContainer>
                </RowContent>
            </Row>
            <Row>
                <RowContent>
                    <RowDescriptionContainer>
                        <Typography sx={{ color: '#26262690' }}>Tax code</Typography>
                    </RowDescriptionContainer>
                    <RowContentContainer>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Tax code</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                sx={{ width: 350 }}
                                value={selectedTaxCode?.id}
                                label="Tax code"
                                onChange={handleSelectTaxCodeChange}
                            >
                                {taxCodeOptions.map((taxCodeOption) => (
                                    <MenuItem key={taxCodeOption.id} value={taxCodeOption.id}>
                                        {taxCodeOption.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </RowContentContainer>
                </RowContent>
            </Row>
            <Row>
                <RowContent>
                    <RowDescriptionContainer>
                        <Typography sx={{ color: '#26262690' }}>Address 1</Typography>
                    </RowDescriptionContainer>
                    <RowContentContainer>
                        <TextField
                            key={'address1'}
                            label={'Address1'}
                            sx={{ width: 350 }}
                            defaultValue={address1}
                            onChange={(e) => setAddress1(e.target.value)}
                        />
                    </RowContentContainer>
                </RowContent>
            </Row>
            <Row>
                <RowContent>
                    <RowDescriptionContainer>
                        <Typography sx={{ color: '#26262690' }}>Address 2</Typography>
                    </RowDescriptionContainer>
                    <RowContentContainer>
                        <TextField
                            key={'address2'}
                            label="Address2"
                            sx={{ width: 350 }}
                            defaultValue={address2}
                            onChange={(e) => setAddress2(e.target.value)}
                        />
                    </RowContentContainer>
                </RowContent>
            </Row>
            <Row>
                <RowContent>
                    <RowDescriptionContainer>
                        <Typography sx={{ color: '#26262690' }}>Address 3</Typography>
                    </RowDescriptionContainer>
                    <RowContentContainer>
                        <TextField
                            key={'address3'}
                            label={'Address3'}
                            sx={{ width: 350 }}
                            defaultValue={address3}
                            onChange={(e) => setAddress3(e.target.value)}
                        />
                    </RowContentContainer>
                </RowContent>
            </Row>
            <Row>
                <RowContent>
                    <RowDescriptionContainer>
                        <Typography sx={{ color: '#26262690' }}>Address 4</Typography>
                    </RowDescriptionContainer>
                    <RowContentContainer>
                        <TextField
                            key={'address4'}
                            label={'Address4'}
                            sx={{ width: 350 }}
                            defaultValue={address4}
                            onChange={(e) => setAddress4(e.target.value)}
                        />
                    </RowContentContainer>
                </RowContent>
            </Row>
            <Row>
                <RowContent>
                    <RowDescriptionContainer>
                        <Typography sx={{ color: '#26262690' }}>Postcode</Typography>
                    </RowDescriptionContainer>
                    <RowContentContainer>
                        <TextField
                            key={'postcode'}
                            label="Postcode"
                            sx={{ width: 350 }}
                            defaultValue={postcode}
                            onChange={(e) => setPostcode(e.target.value)}
                        />
                    </RowContentContainer>
                </RowContent>
            </Row>
            <Row>
                <RowContent>
                    <RowDescriptionContainer>
                        <Typography sx={{ color: '#26262690' }}>County</Typography>
                    </RowDescriptionContainer>
                    <RowContentContainer>
                        <TextField
                            key={'county'}
                            label="County"
                            sx={{ width: 350 }}
                            defaultValue={county}
                            onChange={(e) => setCounty(e.target.value)}
                        />
                    </RowContentContainer>
                </RowContent>
            </Row>
        </Box>
    )
}
