import { Product, Warehouse } from '../../models/models'
import { Changes } from './AddStockTrackingModal'

/**
 *
 * @param stockItem the stockitem to check if warehouse exists on
 * @param currentWarehouse the warehouse to check against the stock item
 * @param changes in case there has been made changes to the stock item, this will be prioritized instead of the stockitem
 * @returns boolean
 */
export const isTrackedByWarehouse = (
    stockItem: Product,
    currentWarehouse: Warehouse,
    changes?: Changes
): boolean => {
    if (changes && changes[stockItem.id]) {
        return changes[stockItem.id].find((wid) => currentWarehouse.id === wid) !== undefined
    }
    return (
        stockItem.stockTrackingWarehouses?.find((w) => currentWarehouse.id === w.id) !== undefined
    )
}
