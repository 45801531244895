import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { Breadcrumb } from '../PageHeader'
import { TextColors } from '../../../theme/colors'

interface Props {
    breadcrumbs: Breadcrumb[]
}

export const Breadcrumbs = (props: Props) => {
    return (
        <MUIBreadcrumbs aria-label="breadcrumb">
            {props.breadcrumbs.map((step, index) => {
                return (
                    <div key={String(step.name + index)}>
                        {index === props.breadcrumbs.length - 1 || step.link === undefined ? (
                            <Typography color="textPrimary">{step.name}</Typography>
                        ) : (
                            <Link
                                key={index}
                                component={RouterLink}
                                to={step.link}
                                onClick={step?.onClickAction ? step.onClickAction : undefined}
                                underline="hover"
                                color={TextColors.lightGrey}
                                sx={{
                                    '&:hover': {
                                        color: TextColors.lightGrey,
                                        textDecorationColor: TextColors.lightGrey,
                                    },
                                }}
                            >
                                {step.name}
                            </Link>
                        )}
                    </div>
                )
            })}
        </MUIBreadcrumbs>
    )
}
