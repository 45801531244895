import { useMemo, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Input, Modal, Button, Table, InputNumber, Select, Radio } from 'antd'
import { Tooltip, styled } from '@mui/material'
import '../../tables/table-styles.css'
import { OrderedListOutlined, DeleteOutlined } from '@ant-design/icons'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../store/hooks'
import { ActiveModal, closeModal } from '../../../store/products-slice'
import { BomGroup, Product, Project, WarehouseId } from '../../../models/models'
import { SearchInput } from '../../ui/SearchInput'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DraggableBodyRow } from '../../project-master/sales-orders/create/OrderlinesTable'
import {
    GetStockItemQueryParams,
    ProductGroup,
    getProductGroupByIdRequest,
    getProductGroupsRequest,
    getStockItemByIdRequest,
    getStockItemsRequest,
} from '../../../api/stock-item'
import { createUnauthorizedBomWithProductRequest } from '../../../api/unauthorized-product'
import { toastSuccess } from '../../../util/toast'
import { getBomGroupByIdRequest, getBomGroupsRequest } from '../../../api/bom-groups'
import { getProjectByIdRequest, getProjectsRequest } from '../../../api/projects/projects'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 50%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

enum ModalState {
    Error = 'Error',
    Ready = 'Ready',
}

interface ProductWithAmount extends Product {
    amount: number
}

enum ComponentSearchOptions {
    Code = 'Code',
    Name = 'Name',
}
const componentSearchOptions: Array<{ label: string; value: ComponentSearchOptions }> = [
    { label: 'Code', value: ComponentSearchOptions.Code },
    { label: 'Name', value: ComponentSearchOptions.Name },
]

export const CreateBomModal = () => {
    const { activeModal } = useSelector((state: RootState) => state.products)
    const [bomGroup, setBomGroup] = useState<BomGroup>()
    const [code, setCode] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [note, setNote] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [warehouseId, setWarehouseId] = useState<WarehouseId>(WarehouseId.Monument)
    const [modalState, setModalState] = useState<ModalState>(ModalState.Ready)
    const [selectedProductGroup, setSelectedProductGroup] = useState<ProductGroup>()
    const [componentSearchOption, setComponentSearchOption] = useState<ComponentSearchOptions>(
        ComponentSearchOptions.Code
    )
    const [assemblyMinutes, setAssemblyMinutes] = useState<number>()
    const [instructionLink, setInstructionLink] = useState<string>()
    const [components, setComponents] = useState<ProductWithAmount[]>([])
    const [selectedProject, setSelectedProject] = useState<Project>()
    const dispatch = useAppDispatch()

    const resetState = () => {
        setAssemblyMinutes(undefined)
        setInstructionLink(undefined)
        setBomGroup(undefined)
        setNote('')
        setCode('')
        setName('')
        setWarehouseId(WarehouseId.Monument)
        setDescription('')
        setModalState(ModalState.Ready)
        setSelectedProductGroup(undefined)
        setComponents([])
    }

    const onClose = () => {
        resetState()
        dispatch(closeModal())
    }

    const onMove = (fromIndex: number, toIndex: number) => {
        const temp = [...components]
        const element = components[fromIndex]
        temp.splice(fromIndex, 1)
        temp.splice(toIndex, 0, element)
        setComponents(temp)
    }

    const isSubmitDisabled = useMemo(() => {
        return (
            !code ||
            !name ||
            !selectedProductGroup ||
            !bomGroup ||
            (components.length > 0 && components.some((c) => c.amount <= 0))
        )
    }, [code, name, components, selectedProductGroup, bomGroup])

    const onOk = async () => {
        if (!selectedProductGroup || !bomGroup) {
            return
        }
        createUnauthorizedBomWithProductRequest({
            code,
            description,
            bomGroupId: bomGroup.id,
            name,
            requestedForProjectId: selectedProject?.id,
            note,
            assemblyMinutes,
            instructionLink,
            components: components.map((c) => ({ id: c.id, amount: Number(c.amount) })),
            productGroupId: selectedProductGroup.id,
            warehouseId,
        }).then((response) => {
            if (response.successful) {
                toastSuccess(`Made Bom ${response.data.bomCode} eligible for authorization`)
                dispatch(closeModal(JSON.stringify(response.data)))
                resetState()
            }
        })
    }

    return (
        <Modal
            open={activeModal === ActiveModal.BomCreate}
            footer={null}
            onCancel={onClose}
            width={900}
        >
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.Ready && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Create Bom</Title>
                    </Header>
                    <Divider />
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Code</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Name</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </RowElement>
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Assembly Minutes</Label>
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0.001}
                                value={assemblyMinutes}
                                status={!assemblyMinutes || assemblyMinutes > 0 ? '' : 'error'}
                                onChange={(value: number | null) => {
                                    if (!value) {
                                        return
                                    }
                                    setAssemblyMinutes(value)
                                }}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Instructions</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={instructionLink}
                                onChange={(e) => setInstructionLink(e.target.value)}
                            />
                        </RowElement>
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Product Group</Label>
                            <SearchInput
                                style={{ width: '100%' }}
                                placeholder="Product Groups"
                                selectedValue={selectedProductGroup?.id}
                                excludeValues={components.map((af) => af.id)}
                                setSelectedValue={(id) => {
                                    if (!id) {
                                        return
                                    }
                                    getProductGroupByIdRequest(id).then((r) => {
                                        if (r.successful) {
                                            setSelectedProductGroup(r.data)
                                        }
                                    })
                                }}
                                request={(search: string) =>
                                    getProductGroupsRequest(search).then((r) =>
                                        r.successful
                                            ? r.data.map((productGroup) => ({
                                                  label: productGroup.code,
                                                  value: productGroup.id,
                                              }))
                                            : [{ label: 'None', value: 2 }]
                                    )
                                }
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Warehouse</Label>
                            <Select
                                style={{ width: '100%' }}
                                value={warehouseId}
                                onChange={(value: WarehouseId) => setWarehouseId(value)}
                                options={[
                                    { value: WarehouseId.Monument, label: 'Monument' },
                                    { value: WarehouseId.Scandia, label: 'Scandia' },
                                    { value: null, label: 'None' },
                                ]}
                            />
                        </RowElement>
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Description</Label>
                            <Input.TextArea
                                style={{ width: '100%', minHeight: 120 }}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Bom Group</Label>
                            <SearchInput
                                style={{ width: '100%' }}
                                placeholder="Bom Group"
                                selectedValue={bomGroup?.id}
                                setSelectedValue={(id) => {
                                    if (!id) {
                                        return
                                    }
                                    getBomGroupByIdRequest(id, {}).then((r) => {
                                        if (r.successful) {
                                            setBomGroup(r.data)
                                        }
                                    })
                                }}
                                request={(search: string) =>
                                    getBomGroupsRequest({ name: search }).then((r) =>
                                        r.successful
                                            ? r.data.entities.map((bomGroup) => ({
                                                  label: bomGroup.name,
                                                  value: bomGroup.id,
                                              }))
                                            : [{ label: 'None', value: 2 }]
                                    )
                                }
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Note</Label>
                            <Input.TextArea
                                style={{ width: '100%', minHeight: 120 }}
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Project</Label>
                            <SearchInput
                                style={{ width: '100%' }}
                                placeholder="Project"
                                selectedValue={selectedProject?.id}
                                setSelectedValue={(id) => {
                                    if (!id) {
                                        return
                                    }
                                    getProjectByIdRequest(id).then((r) => {
                                        if (r.successful) {
                                            setSelectedProject(r.data)
                                        }
                                    })
                                }}
                                request={(search: string) =>
                                    getProjectsRequest({ like: search }).then((r) =>
                                        r.successful
                                            ? r.data.projects.map((project) => ({
                                                  label: project.name,
                                                  value: project.id,
                                              }))
                                            : [{ label: 'None', value: 2 }]
                                    )
                                }
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px', gap: '' }}>
                        <RowElement>
                            <Label>Components</Label>
                            <SearchInput
                                style={{ width: '100%' }}
                                placeholder="Components"
                                selectedValue={undefined}
                                excludeValues={components.map((af) => af.id)}
                                setSelectedValue={(id) => {
                                    if (!id) {
                                        return
                                    }
                                    getStockItemByIdRequest(id).then((r) => {
                                        if (r.successful) {
                                            setComponents([...components, { ...r.data, amount: 1 }])
                                        }
                                    })
                                }}
                                request={(search: string) => {
                                    const requestConfig: GetStockItemQueryParams = {
                                        excludeIds: components.map((af) => af.id),
                                        limit: 100,
                                    }
                                    if (componentSearchOption === ComponentSearchOptions.Code) {
                                        requestConfig.likeCode = search
                                    }
                                    if (componentSearchOption === ComponentSearchOptions.Name) {
                                        requestConfig.likeName = search
                                    }

                                    return getStockItemsRequest(requestConfig).then(
                                        (r): Array<{ label: string; value: number }> => {
                                            if (!r.successful) {
                                                return [{ label: 'None', value: 2 }]
                                            }

                                            return r.data.entities.map((component) => {
                                                if (
                                                    componentSearchOption ===
                                                    ComponentSearchOptions.Code
                                                ) {
                                                    return {
                                                        label: component.code,
                                                        value: component.id,
                                                    }
                                                } else {
                                                    return {
                                                        label: component.name,
                                                        value: component.id,
                                                    }
                                                }
                                            })
                                        }
                                    )
                                }}
                            />
                        </RowElement>
                        <RowElement style={{ marginTop: 'auto' }}>
                            <Radio.Group
                                options={componentSearchOptions}
                                onChange={(e) => setComponentSearchOption(e.target.value)}
                                value={componentSearchOption}
                                optionType="button"
                                buttonStyle="solid"
                            />
                        </RowElement>
                    </InputRow>
                    <DndProvider backend={HTML5Backend}>
                        <Table
                            dataSource={components}
                            components={{
                                body: {
                                    row: DraggableBodyRow,
                                },
                            }}
                            onRow={(_, index) => {
                                const attr = {
                                    index,
                                    onMove,
                                }
                                return attr as React.HTMLAttributes<any>
                            }}
                            columns={[
                                {
                                    title: 'Code',
                                    dataIndex: 'code',
                                    key: 'code',
                                },
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    key: 'name',
                                },
                                {
                                    title: 'Amount',

                                    key: 'amount',
                                    render: (product: ProductWithAmount) => {
                                        const component = components.find(
                                            (c) => c.id === product.id
                                        )
                                        if (!component) {
                                            return
                                        }
                                        return (
                                            <InputNumber
                                                min={0.01}
                                                value={component.amount}
                                                status={component.amount > 0 ? '' : 'error'}
                                                onChange={(value) => {
                                                    if (!value) {
                                                        return
                                                    }
                                                    setComponents(
                                                        components.map((c) =>
                                                            c.id === product.id
                                                                ? { ...c, amount: value }
                                                                : c
                                                        )
                                                    )
                                                }}
                                            />
                                        )
                                    },
                                },
                                {
                                    title: 'Delete',
                                    dataIndex: 'id',
                                    key: 'id',
                                    render: (product: ProductWithAmount) => (
                                        <Button
                                            shape="circle"
                                            onClick={() => {
                                                setComponents(
                                                    components.filter(
                                                        (component) => component.id !== product.id
                                                    )
                                                )
                                            }}
                                            icon={<DeleteOutlined />}
                                        />
                                    ),
                                },
                            ]}
                            pagination={false}
                        />
                    </DndProvider>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                        <Button type={'primary'} disabled={isSubmitDisabled} onClick={onOk}>
                            Create
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    )
}
