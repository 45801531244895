import PageHeader from '../ui/PageHeader'
import Paper from '@mui/material/Paper'
import { mainSAVTheme } from '../../theme/Theme'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import { Autocomplete, Button, TextField } from '@mui/material'
import { useState } from 'react'
import moment from 'moment'
import { getUsersRequest } from '../../api/users'
import { useEffect } from 'react'
import { AbsentDay, User, UserRole } from '../../models/models'
import { getAbsentDaysRequest } from '../../api/days-off'
import { snakeCaseToTitleCase } from '../../util/util'
import { CreateDaysOffModal } from './modals/CreateDaysOffModal'
import { InspectDaysOffModal } from './modals/InspectDaysOffModal'
import { UpdateDaysOffModal } from './modals/UpdateDaysOffModal'
import { BackgroundColors } from '../../theme/colors'
import { Urls } from '../../util/urls'
import Calendar from '../ui/calendar/Calendar'

export default function AvailabilityCalendar() {
    /* Related to calendar */
    const [users, setUsers] = useState<User[]>([])
    const [roleFilter, setRoleFilter] = useState<UserRole[]>([])
    const [userFilter, setUserFilter] = useState<number[]>([])
    const [firstDayOfMonth, setFirstDayOfMonth] = useState<Date>(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    )
    const [lastDayOfMonth, setLastDayOfMonth] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)
    )
    const [absentDays, setAbsentDays] = useState<AbsentDay[]>([])

    async function fetchUsers() {
        const response = await getUsersRequest({
            roles: roleFilter.length > 0 ? roleFilter : undefined,
        })

        if (response.successful) {
            setUsers(response.data.entities)
        }
    }

    useEffect(() => {
        fetchUsers()
    }, [roleFilter])

    async function fetchAbsentDays(startDate: Date, endDate: Date) {
        let response
        if (userFilter.length != 0) {
            response = await getAbsentDaysRequest({ userIds: userFilter, startDate, endDate })
        } else if (roleFilter.length != 0) {
            response = await getAbsentDaysRequest({ roles: roleFilter, startDate, endDate })
        } else {
            response = await getAbsentDaysRequest({ startDate, endDate })
        }

        if (response.successful) {
            setAbsentDays(response.data)
        }
    }
    useEffect(() => {
        fetchAbsentDays(firstDayOfMonth, lastDayOfMonth)
    }, [firstDayOfMonth, userFilter, roleFilter])

    function dateCellRender(date: moment.Moment) {
        return (
            <div className="days-off">
                {absentDays
                    .filter((absentDay) => moment(absentDay.date).isSame(date, 'day'))
                    .map((absentDay) => (
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            key={absentDay.daysOff?.user?.id}
                            onClick={(event) => handleDaysOffClick(event, absentDay)}
                            style={{ padding: '2px 8px', marginBottom: '5px' }}
                        >
                            <span style={{ fontSize: '10px' }}>
                                {absentDay.daysOff?.user?.firstName || 'Unknown'}
                                {absentDay.daysOff?.user?.lastName}
                            </span>
                        </Button>
                    ))}
            </div>
        )
    }

    function handlePanelChange(date: moment.Moment) {
        setFirstDayOfMonth(new Date(date.year(), date.month(), 1))
        setLastDayOfMonth(new Date(date.year(), date.month() + 1, 1))
    }

    const roleFilterOptions: { label: string; value: UserRole }[] = Object.values(UserRole).map(
        (role) => ({
            label: snakeCaseToTitleCase(role),
            value: role,
        })
    )

    const userFilterOptions: { label: string; id: number }[] = users.map((user) => ({
        label: user.firstName + ' ' + user.lastName,
        id: user.id,
    }))

    /* Related to AddDaysOffModal */
    const [showAddDaysOffModal, setShowAddDaysOffModal] = useState(false)

    /* Related to ChangeDaysOffModal [shouldShow, daysOffId]*/
    const [showChangeDaysOffModal, setShowChangeDaysOffModal] = useState<
        [boolean, number | undefined]
    >([false, undefined])
    function handleDaysOffClick(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
        absentDay: AbsentDay
    ) {
        if (event != undefined) {
            setShowChangeDaysOffModal([true, absentDay.daysOffId])
            event.stopPropagation()
        }
    }

    /* Related to OverviewOfDayModal */
    const [showOverviewOfDayModal, setShowOverviewOfDayModal] = useState<
        [boolean, Date | undefined]
    >([false, undefined])
    function handleSelectDate(date: moment.Moment) {
        const events = absentDays.filter(() => {
            return handleDaysOffClick
        })

        if (events.length != 0) {
            setShowOverviewOfDayModal([true, date.toDate()])
        }
    }

    return (
        <>
            <PageHeader
                title="Assembly & Logistics Calendar"
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        link: Urls.Calendar,
                        name: 'Calendar',
                    },
                ]}
            />
            <Paper
                elevation={0}
                square
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    height: 86,
                    padding: '23px 24px 23px 24px',
                }}
            >
                <div style={{ display: 'flex' }}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => setShowAddDaysOffModal(true)}
                        style={{
                            borderRadius: '10px',
                            height: '40px',
                            paddingRight: '16px',
                        }}
                    >
                        <AddIcon style={{ marginRight: '4px' }} />
                        Add off-days
                    </Button>
                </div>
                <div>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <style>
                            {`.MuiFormControl-root {
                                margin: 0px
                              }


                              #roles-selector .MuiOutlinedInput-root {
                                border-radius: 10px 0px 0px 10px
                              }
                              
                              #employee-selector .MuiOutlinedInput-root {
                                border-radius: 0px 10px 10px 0px
                              }                 
                          `}
                        </style>
                        <FormControl id="roles-selector" sx={{ minWidth: 160 }} size="small">
                            <Autocomplete
                                multiple
                                size="small"
                                limitTags={1}
                                renderInput={(params) => (
                                    <TextField {...params} label="Roles" margin="normal" />
                                )}
                                onChange={(
                                    event: React.SyntheticEvent<Element, Event>,
                                    newValues: { label: string; value: UserRole }[]
                                ) => {
                                    setRoleFilter(newValues.map((newValue) => newValue.value))
                                }}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                options={roleFilterOptions}
                            />
                        </FormControl>
                        <FormControl id="employee-selector" sx={{ minWidth: 160 }} size="small">
                            <Autocomplete
                                multiple
                                size="small"
                                limitTags={1}
                                renderInput={(params) => (
                                    <TextField {...params} label="Employees" margin="normal" />
                                )}
                                onChange={(
                                    _event: React.SyntheticEvent<Element, Event>,
                                    newValues: { label: string; id: number }[]
                                ) => {
                                    setUserFilter(newValues.map((newValue) => newValue.id))
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                options={userFilterOptions}
                            />
                        </FormControl>
                    </Box>
                </div>
            </Paper>
            <div style={{ position: 'relative', left: '2.5%', width: '95%', marginTop: '30px' }}>
                <style>
                    {`.ant-picker-calendar-header {
                        background-color: ${BackgroundColors.lighterGrey};
                      }

                      .ant-picker-body {
                        background-color: ${BackgroundColors.lighterGrey};
                      }

                      tbody td div.ant-picker-cell-inner.ant-picker-calendar-date {
                        background-color: white;
                      }

                      div.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date { 
                        margin: 3.5px 4.5px;
                        border-top: 2px solid white;
                      }
                      div.ant-radio-group.ant-radio-group-outline.ant-picker-calendar-mode-switch {
                        display: none;
                      }

                      td.ant-picker-cell:not(.ant-picker-cell-in-view) div.ant-picker-cell-inner.ant-picker-calendar-date {
                        background-color: ${mainSAVTheme.palette.secondary.dark};
                        border-color: ${mainSAVTheme.palette.secondary.dark};
                      }
                    `}
                </style>
                <Calendar
                    dateCellRender={dateCellRender}
                    mode={'month'}
                    onPanelChange={handlePanelChange}
                    onSelect={handleSelectDate}
                />
            </div>

            {/* ***** Modals ***** */}

            <CreateDaysOffModal
                isOpen={showAddDaysOffModal}
                onClose={() => {
                    setShowAddDaysOffModal(false)
                    fetchAbsentDays(firstDayOfMonth, lastDayOfMonth)
                }}
                users={users}
            />

            <InspectDaysOffModal
                isOpen={showOverviewOfDayModal[0]}
                onClose={() => setShowOverviewOfDayModal([false, undefined])}
                absentDays={absentDays}
                date={showOverviewOfDayModal[1]}
                changeDayOff={(dayOffId: number) => {
                    setShowOverviewOfDayModal([false, undefined])
                    setShowChangeDaysOffModal([true, dayOffId])
                }}
            />

            {showChangeDaysOffModal[1] != undefined && (
                <UpdateDaysOffModal
                    isOpen={showChangeDaysOffModal[0]}
                    onClose={() => {
                        setShowChangeDaysOffModal([false, undefined])
                        fetchAbsentDays(firstDayOfMonth, lastDayOfMonth)
                    }}
                    daysOffId={showChangeDaysOffModal[1]}
                />
            )}
        </>
    )
}
