import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material'
import { MouseEvent } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../store/hooks'
import { updateEntityModalState } from '../../../store/single-project-view-slice'
import { useCustomers } from './hooks'

export default function CustomersTable() {
    const { tableOptions, search, selectedCustomer } = useAppSelector(
        (state) => state.singleProjectView.entityModal
    )
    const dispatch = useDispatch()

    const skip =
        tableOptions?.page && tableOptions?.amountPerPage
            ? tableOptions.page * tableOptions.amountPerPage
            : 0
    const limit = tableOptions?.amountPerPage || 0

    const [customers, count] = useCustomers(skip, limit, search)

    const pageChange = (
        event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
        page: number
    ) => {
        dispatch(
            updateEntityModalState({
                tableOptions: { ...tableOptions, page },
            })
        )
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Reference</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Balance</TableCell>
                    <TableCell>Credit limit</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {customers.map((customer) => (
                    <TableRow
                        key={customer.id}
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            cursor: 'pointer',
                            backgroundColor:
                                selectedCustomer?.id === customer.id ? '#dcf7e9' : 'auto',
                        }}
                        hover
                        onClick={() => {
                            dispatch(
                                updateEntityModalState({
                                    selectedCustomer:
                                        customer.id === selectedCustomer?.id ? null : customer,
                                })
                            )
                        }}
                    >
                        <TableCell component="th" scope="row" width="35%">
                            {customer.name}
                        </TableCell>
                        <TableCell component="th" scope="row">{`${customer.reference}`}</TableCell>
                        <TableCell
                            component="th"
                            scope="row"
                        >{`${customer.analysisCode1}`}</TableCell>
                        <TableCell component="th" scope="row">
                            {customer.balance}
                        </TableCell>
                        <TableCell
                            component="th"
                            scope="row"
                        >{`${customer.creditLimit}`}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
            <TableFooter>
                <TablePagination
                    count={count}
                    page={tableOptions?.page || 0}
                    labelRowsPerPage=""
                    rowsPerPageOptions={[]}
                    rowsPerPage={tableOptions?.amountPerPage || 0}
                    onPageChange={(
                        event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
                        page: number
                    ) => {
                        pageChange(event, page)
                        dispatch(
                            updateEntityModalState({
                                selectedCustomer: null,
                            })
                        )
                    }}
                />
            </TableFooter>
        </Table>
    )
}
