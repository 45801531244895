import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/system'
import { Button, Checkbox, Divider, Input, InputNumber, Modal, Typography } from 'antd'
import { Tooltip, styled } from '@mui/material'
import '../../tables/table-styles.css'
import {
    CloseCircleOutlined,
    EditOutlined,
    InfoCircleOutlined,
    LeftOutlined,
    OrderedListOutlined,
    RightOutlined,
} from '@ant-design/icons'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../store/hooks'
import { ActiveModal, closeModal } from '../../../store/products-slice'
import { Bom, BomGroup } from '../../../models/models'
import { BomRelation, getBomsRequest, updateManyBomsRequst } from '../../../api/bom'
import { SearchInput } from '../../ui/SearchInput'
import { getBomGroupByIdRequest, getBomGroupsRequest } from '../../../api/bom-groups'
import BomsTable, { BomsTableColumns } from '../../tables/BomTable'
import { toastSuccess } from '../../../util/toast'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 50%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

enum ModalState {
    Error = 'Error',
    Ready = 'Ready',
}

export const UpdateManyBomsModal = () => {
    const { bomFilters, updateTableToken, activeModal } = useSelector(
        (state: RootState) => state.products
    )
    const dispatch = useAppDispatch()
    // setState for all the property fields
    const [excludeCode, setExcludeCode] = useState<string>(bomFilters.excludeCode ?? '')
    const [code, setCode] = useState<string>(bomFilters.code ?? '')
    const [productName, setProductName] = useState<string>(bomFilters.productName ?? '')
    const [id, setId] = useState<number | undefined>(bomFilters.bomId ?? undefined)
    const [assemblyMinutes, setAssemblyMinutes] = useState<number>()
    const [instructionLink, setInstructionLink] = useState<string>()
    const [bomGroup, setBomGroup] = useState<BomGroup>()
    const [uncheckedIds, setUncheckedIds] = useState<number[]>([])
    const [modalState, setModalState] = useState<ModalState>(ModalState.Ready)
    const [isInstructionEllipsis, setIsInstructionEllipsis] = useState<boolean>(true)

    const resetState = () => {
        setCode('')
        setId(undefined)
        setProductName('')
        setAssemblyMinutes(undefined)
        setInstructionLink(undefined)
        setBomGroup(undefined)
        setModalState(ModalState.Ready)
    }

    const onClose = () => {
        resetState()
        dispatch(closeModal())
    }

    useEffect(() => {
        if (activeModal === ActiveModal.BomUpdateMany) {
            resetState()
            setCode(bomFilters.code ?? '')
            setProductName(bomFilters.productName ?? '')
            setId(bomFilters.bomId ?? undefined)
        }
    }, [activeModal])

    useEffect(() => {
        setUncheckedIds([])
    }, [productName, code, excludeCode, id])

    const columns = useMemo(() => {
        const columns = [BomsTableColumns.Id, BomsTableColumns.Code, BomsTableColumns.ProductName]

        if (assemblyMinutes) {
            columns.push({
                title: (
                    <span
                        onClick={() => setAssemblyMinutes(undefined)}
                        style={{ cursor: 'pointer' }}
                    >
                        Assembly Minutes <CloseCircleOutlined />
                    </span>
                ),
                className: 'antd-updated-cell',
                key: 'updatedAssemblyMinutes',
                render: () => {
                    return `${assemblyMinutes} minutes`
                },
            })
        }
        if (!assemblyMinutes) {
            columns.push(BomsTableColumns.AssemblyTime)
        }
        if (instructionLink) {
            columns.push({
                title: (
                    <span
                        onClick={() => setInstructionLink(undefined)}
                        style={{ cursor: 'pointer' }}
                    >
                        Instruction Link <CloseCircleOutlined />
                    </span>
                ),
                className: 'antd-updated-cell',
                key: 'updatedInstructionLink',
                render: () => {
                    return instructionLink
                },
            })
        }
        if (!instructionLink && !isInstructionEllipsis) {
            columns.push({
                title: () => (
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                        Instruction
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '24px',
                                width: '24px',
                                borderRadius: '50%',
                                border: '1px solid #d9d9d9',
                                cursor: 'pointer',
                            }}
                            onClick={() => setIsInstructionEllipsis(true)}
                        >
                            <LeftOutlined style={{ fontSize: '8px' }} />{' '}
                        </div>
                    </div>
                ),
                key: 'instruction',
                render: (bom: Bom) => {
                    return (
                        <Typography.Text style={{ width: '300px' }}>
                            {bom?.instructionLink ?? 'None'}
                        </Typography.Text>
                    )
                },
            })
        }
        if (!instructionLink && isInstructionEllipsis) {
            columns.push({
                title: () => (
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                        Instruction
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '24px',
                                width: '24px',
                                borderRadius: '50%',
                                border: '1px solid #d9d9d9',
                                cursor: 'pointer',
                            }}
                            onClick={() => setIsInstructionEllipsis(false)}
                        >
                            <RightOutlined style={{ fontSize: '8px' }} />{' '}
                        </div>
                    </div>
                ),
                key: 'instructionEllipsis',
                width: '100px',
                render: (bom: Bom) => {
                    return (
                        <Typography.Text ellipsis style={{ width: '300px' }}>
                            {bom?.instructionLink ?? 'None'}
                        </Typography.Text>
                    )
                },
            })
        }
        if (bomGroup) {
            columns.push({
                title: (
                    <span onClick={() => setBomGroup(undefined)} style={{ cursor: 'pointer' }}>
                        BomGroup <CloseCircleOutlined />
                    </span>
                ),
                className: 'antd-updated-cell',
                key: 'updatedBomGroup',
                render: () => {
                    return bomGroup.name
                },
            })
        }
        if (!bomGroup) {
            columns.push(BomsTableColumns.BomGroupName)
        }

        columns.push({
            title: 'Check',
            key: 'check',
            render: (bom: Bom) => {
                return (
                    <Checkbox
                        checked={!uncheckedIds.includes(bom.id)}
                        onChange={(e: CheckboxChangeEvent) => {
                            if (e.target.checked) {
                                setUncheckedIds((prev) => prev.filter((id) => id !== bom.id))
                            } else {
                                setUncheckedIds((prev) => [...prev, bom.id])
                            }
                        }}
                    />
                )
            },
        })

        return columns
    }, [assemblyMinutes, bomGroup, instructionLink, isInstructionEllipsis, uncheckedIds])

    const isSubmitDisabled = useMemo(() => {
        if (!assemblyMinutes && !instructionLink && !bomGroup) {
            return true
        }
        if (!code && !productName && !id) {
            return true
        }
        return false
    }, [assemblyMinutes, instructionLink, bomGroup, code, productName, id])

    const onOk = async () => {
        const response = await updateManyBomsRequst({
            excludeIds: uncheckedIds,
            assemblyMinutes,
            instructionLink,
            bomGroupId: bomGroup?.id,
            filterId: id,
            filterBomCode: code,
            filterProductName: productName,
        })

        if (!response.successful) {
            setModalState(ModalState.Error)
            return
        }

        toastSuccess(`Updated Boms `)
        dispatch(closeModal(JSON.stringify(response.data)))
        resetState()
    }

    return (
        <Modal
            open={activeModal === ActiveModal.BomUpdateMany}
            footer={null}
            onCancel={onClose}
            width={1200}
        >
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.Ready && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Update Many Boms</Title>
                    </Header>
                    <Divider />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <h2>Fields to update</h2>
                        <Tooltip
                            title=" This modal only updates fields that are left not empty. Meaning
                            if bom group, assembly minutes or instruction fields are left
                            empty, they will not override the existing values."
                        >
                            <InfoCircleOutlined
                                style={{ fontSize: '20px', margin: 'auto 0px 18px 8px' }}
                            />
                        </Tooltip>
                    </div>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Assembly Minutes</Label>
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0.1}
                                value={assemblyMinutes}
                                addonAfter={
                                    <Tooltip title="The assembly minutes is editable">
                                        <EditOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                                status={!assemblyMinutes || assemblyMinutes > 0 ? '' : 'error'}
                                onChange={(value: number | null) => {
                                    if (!value) {
                                        setAssemblyMinutes(undefined)
                                        return
                                    }
                                    setAssemblyMinutes(value)
                                }}
                            />
                        </RowElement>
                        <RowElement />
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Bom Group</Label>
                            <SearchInput
                                style={{ width: '100%' }}
                                placeholder="Bom Group"
                                selectedValue={bomGroup?.id}
                                addonAfter={<EditOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
                                setSelectedValue={(id) => {
                                    if (!id) {
                                        return
                                    }
                                    getBomGroupByIdRequest(id, {}).then((r) => {
                                        if (r.successful) {
                                            setBomGroup(r.data)
                                        }
                                    })
                                }}
                                request={(search: string) =>
                                    getBomGroupsRequest({ name: search }).then((r) =>
                                        r.successful
                                            ? r.data.entities.map((bomGroup) => ({
                                                  label: bomGroup.name,
                                                  value: bomGroup.id,
                                              }))
                                            : [{ label: 'None', value: 2 }]
                                    )
                                }
                            />
                        </RowElement>
                        <RowElement />
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Instructions</Label>
                            <Input
                                style={{ width: '100%' }}
                                suffix={
                                    <Tooltip title="The instruction link is editable">
                                        <EditOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                                value={instructionLink}
                                onChange={(e) => setInstructionLink(e.target.value)}
                            />
                        </RowElement>
                        <RowElement />
                    </InputRow>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <h2>Boms that updates</h2>
                        <Tooltip
                            title="This modal will update all the boms present in the table. Be
                                    aware that the table can have multiple pages. Targetting
                                    specific Boms are done by adjusting the filters"
                        >
                            <InfoCircleOutlined
                                style={{ fontSize: '20px', margin: 'auto 0px 18px 8px' }}
                            />
                        </Tooltip>
                    </div>

                    <InputRow style={{ width: '100%' }}>
                        <div
                            style={{
                                width: '70%',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                            }}
                        >
                            <RowElement>
                                <Label>Id</Label>
                                <Input
                                    style={{ width: '100%' }}
                                    value={bomFilters.bomId}
                                    onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                                        if (
                                            isNaN(parseInt(event.target.value)) ||
                                            parseInt(event.target.value) === 0
                                        ) {
                                            setId(undefined)
                                        } else {
                                            setId(Number(event.target.value))
                                        }
                                    }}
                                />
                            </RowElement>
                            <RowElement>
                                <Label>Code</Label>
                                <Input
                                    style={{ width: '100%' }}
                                    type="text"
                                    value={code}
                                    onChange={(event): void => {
                                        setCode(event.target.value)
                                    }}
                                />
                            </RowElement>
                            <RowElement>
                                <Label>Product Name</Label>
                                <Input
                                    style={{ width: '100%' }}
                                    value={productName}
                                    onChange={(event): void => {
                                        setProductName(event.target.value)
                                    }}
                                />
                            </RowElement>
                            <RowElement>
                                <Label>Exclude Code</Label>
                                <Input
                                    style={{ width: '100%' }}
                                    type="text"
                                    value={excludeCode}
                                    onChange={(event): void => {
                                        setExcludeCode(event.target.value)
                                    }}
                                />
                            </RowElement>
                        </div>
                        <div
                            style={{
                                width: '30%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'end',
                                justifyContent: 'end',
                                gap: '10px',
                            }}
                        >
                            {/*
                            <Button
                                type="default"
                                onClick={() => {
                                    // check all
                                }}
                            >{`Check Page`}</Button>
                            <Button
                                type="default"
                                onClick={() => {
                                    // uncheck all
                                }}
                            >{`Uncheck ${100}`}</Button>
                            */}
                        </div>
                    </InputRow>
                    {columns && (
                        <BomsTable
                            pagination={true}
                            columns={columns}
                            dependencies={{ productName, code, updateTableToken, excludeCode, id }}
                            request={(pagination) =>
                                getBomsRequest({
                                    excludeBomCode: excludeCode ?? undefined,
                                    ids: id ? [id] : undefined,
                                    bomCode: code ?? undefined,
                                    productName: productName ?? undefined,
                                    relations: [BomRelation.BomGroup, BomRelation.Product],
                                    withCount: true,
                                    skip: pagination?.skip,
                                    limit: pagination?.limit,
                                })
                            }
                        />
                    )}
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                        <Button type={'primary'} disabled={isSubmitDisabled} onClick={onOk}>
                            Update
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    )
}
