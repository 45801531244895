import { Box } from '@mui/material'
import { Empty } from 'antd'

function SingleProjectViewNoData() {
    return (
        <Box
            sx={{
                paddingTop: '40px',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Empty />
        </Box>
    )
}

export default SingleProjectViewNoData
