import { Dialog, IconButton, Divider, Paper, Button, TextField } from '@mui/material'
import { Typography } from 'antd'
import { Box, styled } from '@mui/system'
import { Assembly } from '../../../models/models'
import { toastFailure, toastSuccess } from '../../../util/toast'
import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useState } from 'react'
import CTRLoader from '../../ui/loader/CTRloader'
import {
    AssemblyRelation,
    deleteAssemblyRequest,
    getAssemblyByIdRequest,
} from '../../../api/assemblies'

interface DeleteAssemblyModalProps {
    assemblyId: number
    closeModal: () => void
    onOk: () => void
    isOpen: boolean
}

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

const Content = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 50px 20px 50px',
})

enum State {
    Loading = 'Loading',
    Found = 'Found',
    NotFound = 'NotFound',
    Error = 'Error',
}

export default function DeleteAssemblyModal(props: DeleteAssemblyModalProps) {
    const { assemblyId, closeModal, isOpen, onOk } = props
    const [assembly, setAssembly] = useState<Assembly>()
    const [state, setState] = useState<State>(State.Loading)

    const getAssembly = async () => {
        const response = await getAssemblyByIdRequest(assemblyId, {
            relations: [
                AssemblyRelation.CallOff,
                AssemblyRelation.Bom,
                AssemblyRelation.CallOffProject,
                AssemblyRelation.CallOffSalesOrder,
            ],
        })

        if (response.successful) {
            setAssembly(response.data)
            setState(State.Found)
        } else if (response.status === 404) {
            toastFailure('Could not find assembly')
            setState(State.NotFound)
            closeModal()
        } else {
            toastFailure(response.message)
            setState(State.Error)
            closeModal()
        }
    }

    const deleteAssembly = async () => {
        const response = await deleteAssemblyRequest(assemblyId)
        if (response.successful) {
            toastSuccess('Assembly has been deleted')
            onOk()
            closeModal()
        } else {
            toastFailure(response.message)
        }
    }

    useEffect(() => {
        getAssembly()
    }, [])

    return (
        <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="md">
            <Paper style={{ height: '100%', maxHeight: '800px' }}>
                <Box
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div> Delete Assembly </div>
                    <IconButton aria-label="close" onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Container>
                    {state === State.Loading && (
                        <Content
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                margin: '20px',
                                width: '100%',
                                height: '400px',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CTRLoader size="xl" />
                        </Content>
                    )}
                    {assembly && state === State.Found && (
                        <Content>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly',
                                    padding: '20px 50px 10px 50px',
                                }}
                            >
                                <TextField
                                    key="Built item"
                                    id="outlined-read-only-input"
                                    label="Built item"
                                    defaultValue={
                                        assembly.builtItemCallOff?.sopBuiltItemOrderline?.builtItem
                                            ?.bomCode || 'No built item'
                                    }
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={true}
                                />
                                <TextField
                                    key="Call Off ID"
                                    id="outlined-read-only-input"
                                    label="Call Off ID"
                                    defaultValue={assembly.id}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={true}
                                />
                                <TextField
                                    key="Project"
                                    id="outlined-read-only-input"
                                    label="Project Name"
                                    defaultValue={assembly.callOff.project.name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={true}
                                />
                                <TextField
                                    key="Project"
                                    id="outlined-read-only-input"
                                    label="Sales order"
                                    defaultValue={assembly.callOff.salesOrder.documentNo}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={true}
                                />
                            </Box>
                            <Typography.Text type="danger">
                                Are you sure you want to delete this assembly? Pallets associated
                                will remain but without being attached to the call off.
                            </Typography.Text>

                            <Button
                                style={{ margin: '10px' }}
                                variant="contained"
                                color="error"
                                onClick={deleteAssembly}
                            >
                                Confirm
                            </Button>
                        </Content>
                    )}
                </Container>
            </Paper>
        </Dialog>
    )
}
