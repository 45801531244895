import { styled } from '@mui/material'
import { GeneralAssembly, Task, TaskDepartment, WarehouseId } from '../../../models/models'
import { useEffect, useState } from 'react'
import { completeTasksRequest, getTasksRequest, TaskRelation } from '../../../api/tasks'
import { toastFailure, toastSuccess } from '../../../util/toast'
import moment from 'moment'
import {
    CheckCircleOutlined,
    DeleteOutlined,
    OrderedListOutlined,
    PlusOutlined,
} from '@ant-design/icons'
import { Modal } from 'antd'
import { useAppSelector } from '../../../store/hooks'
import CreateTaskModal from '../../modals/create-task-modal/CreateTaskModal'
import {
    getAssembliesCompletedMinutes,
    getGeneralAssembliesCompletedMinutes,
    getGeneralAssembliesMinutes,
} from '../../../util/production-schedule'

const { confirm } = Modal

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Container = styled('div')`
    border: 1px solid #ededed;
    margin-top: 15px;
    margin-bottom: 15px;
    background: #fff;
    padding: 15px;
`

const ListContainer = styled('div')`
    display: flex;
    gap: 15px;
    overflow-x: auto;
    flex-grow: 1;
    margin-top: 15px;
`

interface Props {
    department: TaskDepartment
    warehouseId: WarehouseId
}

const GeneralAssemblyCardContainer = styled('div')`
    background: #ededed;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    height: 210px;
    min-width: 250px;
    max-width: 250px;
    flex-direction: column;
    display: flex;
    gap: 10px;
`

const GeneralAssemblyCardTitle = styled('p')`
    font-size: 14px;
    font-weight: bold;
    margin: 0;
`

const GeneralAssemblySingleLineField = styled('p')`
    font-size: 14px;
    font-weight: bold;
    margin: 0;
`

const GeneralAssemblyMultiLineField = styled('pre')`
    flex-grow: 1;
    overflow-y: auto;
    font-family: inherit;
    margin: 0;
    white-space: pre-wrap;
`

const Footer = styled('div')`
    display: flex;
    align-items: center;
    gap: 10px;
`

const CompletedText = styled('p')`
    margin: 0;
    font-weight: bold;
    color: #40bd40;
`

interface GeneralAssemblyCardProps {
    generalAssembly: GeneralAssembly
}

export const GeneralAssemblyCard = ({ generalAssembly }: GeneralAssemblyCardProps) => {
    const completedMinutes = getGeneralAssembliesCompletedMinutes([generalAssembly])
    const totalMinutes = getGeneralAssembliesMinutes([generalAssembly])
    return (
        <GeneralAssemblyCardContainer>
            <GeneralAssemblyCardTitle>{`General Assembly Id: ${generalAssembly.id}`}</GeneralAssemblyCardTitle>
            <GeneralAssemblySingleLineField>{`BOM: ${generalAssembly.builtItem.bomCode}`}</GeneralAssemblySingleLineField>
            <GeneralAssemblySingleLineField>{`Minutes: ${completedMinutes}/${totalMinutes}`}</GeneralAssemblySingleLineField>
            <GeneralAssemblyMultiLineField>
                {`Description: ${generalAssembly.description}`}
            </GeneralAssemblyMultiLineField>
            <Footer>
                {generalAssembly.completedAt && <CompletedText>Completed</CompletedText>}
            </Footer>
        </GeneralAssemblyCardContainer>
    )
}

export default GeneralAssemblyCard
