import { Button } from '@mui/material'
import { Box, styled } from '@mui/system'
import { useEffect, useState } from 'react'
import { SalesOrder, WarehouseId } from '../../../models/models'
import { getSalesOrdersRequest, GetManySalesOrderRelations } from '../../../api/sales-orders'
import SalesOrderOrderlineTable from '../../project-master/sales-orders/SalesOrderOrderlineTable'
import { toastFailure, toastWarn } from '../../../util/toast'
import moment from 'moment'
import { Table } from 'antd'
import InspectSalesOrderModal from '../../modals/inspect-sales-order-modal/InspectSalesOrderModal'

enum ActiveModal {
    None = 'None',
    CreateCallOff = 'CreateCallOff',
    InspectBuiltItem = 'InspectBuiltItem',
    InspectSalesOrder = 'InspectSalesOrder',
}

const Container = styled(Box)(``)

export default function SalesOrders() {
    const [salesOrders, setSalesOrders] = useState<SalesOrder[]>([])
    const [salesOrdersCount, setSalesordersCount] = useState<number>()
    const [activeModal, setActiveModal] = useState<ActiveModal>(ActiveModal.None)
    const [modalSalesOrder, setModalSalesOrder] = useState<SalesOrder>()
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    useEffect(() => {
        const getSalesOrders = async () => {
            const response = await getSalesOrdersRequest({
                relations: [
                    GetManySalesOrderRelations.Projects,
                    GetManySalesOrderRelations.Orderlines,
                    GetManySalesOrderRelations.CallOffs,
                ],
                withReservedOrderlineAmounts: true,
                withCount: true,
                //antd tables pages are 1-indexed
                skip: page * rowsPerPage - rowsPerPage,
                limit: rowsPerPage,
            })
            if (response.successful) {
                setSalesOrders(response.data.entities)
                setSalesordersCount(response.data.count)
            } else {
                toastFailure('Could not get sales orders')
            }
        }
        getSalesOrders()
    }, [rowsPerPage, page])

    const tableColumns = [
        {
            title: 'Document Number',
            dataIndex: 'documentNo',
            key: 'documentNo',
        },
        {
            title: 'Warehouse',
            dataIndex: 'warehouseId',
            key: 'warehouse',
            render: (warehouseId: number) => {
                if (warehouseId === WarehouseId.Monument) {
                    return 'Monument'
                } else if (warehouseId === WarehouseId.Scandia) {
                    return 'Scandia'
                }
            },
        },
        {
            title: 'Promised delivery',
            key: 'promisedDeliveryDate',
            render: (salesOrder: SalesOrder) => {
                return moment(salesOrder.promisedDeliveryDate).format('DD/MM/YYYY')
            },
        },
        {
            title: 'Orderlines amount',
            key: 'stock item',
            render: (salesOrder: SalesOrder) => {
                return salesOrder.stockItemOrderlines.length + salesOrder.builtItemOrderlines.length
            },
        },
        {
            title: 'Actions',
            key: 'stock item',
            render: (salesOrder: SalesOrder) => {
                return (
                    <>
                        <Button
                            variant={'outlined'}
                            onClick={() => {
                                setModalSalesOrder(salesOrder)
                                setActiveModal(ActiveModal.InspectSalesOrder)
                            }}
                        >
                            Inspect Sales Order
                        </Button>
                    </>
                )
            },
        },
    ]

    const expandedRowRender = (salesOrder: SalesOrder) => {
        return <SalesOrderOrderlineTable salesOrder={salesOrder} />
    }
    return (
        <Container>
            {activeModal === ActiveModal.InspectSalesOrder && modalSalesOrder && (
                <InspectSalesOrderModal
                    salesOrderId={modalSalesOrder.id}
                    isOpen={true}
                    closeModal={() => {
                        setActiveModal(ActiveModal.None)
                        setModalSalesOrder(undefined)
                    }}
                />
            )}
            <Table
                rowKey="id"
                columns={tableColumns}
                dataSource={salesOrders}
                expandable={{ expandedRowRender }}
                size="small"
                pagination={{
                    defaultPageSize: 10,
                    current: page,
                    onChange: pageChange,
                    total: salesOrdersCount,
                }}
            />
        </Container>
    )
}
