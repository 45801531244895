import { styled } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { useAppDispatch } from '../../store/hooks'
import PageHeader from '../ui/PageHeader'
import { Urls } from '../../util/urls'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { ActiveModal, ProductView, closeModal, setActiveView } from '../../store/products-slice'
import { Products } from './Products'
import { AssemblyFields } from './AssemblyFields'
import { BomGroups } from './BomGroups'
import { Boms } from './Boms'
import { CreateProductModal } from './modals/CreateProductModal'
import { CreateAssemblyFieldModal } from './modals/CreateAssemblyFieldModal'
import { UpdateAssemblyFieldModal } from './modals/UpdateAssemblyFieldModal'
import { CreateBomGroupModal } from './modals/CreateBomGroupModal'
import { UpdateBomGroupModal } from './modals/UpdateBomGroupModal'
import { CreateBomModal } from './modals/CreateBomModal'
import { InspectBomModal } from './modals/InspectBomModal'
import { UpdateBomModal } from './modals/UpdateBomModal'
import { UpdateManyBomsModal } from './modals/UpdateManyBomsModal'
import { CreateBomVersionModal } from './modals/CreateBomVersion'

const Container = styled('div')``

const ContentContainer = styled('div')`
    padding: 25px;
`

export const ProductsRoute = () => {
    const { activeView, activeModal } = useSelector((state: RootState) => state.products)
    const dispatch = useAppDispatch()

    const tabsOptions: TabsProps['items'] = [
        {
            key: ProductView.Products,
            label: `Products`,
            children: <Products />,
        },
        { key: ProductView.Boms, label: `Boms`, children: <Boms /> },
        { key: ProductView.BomGroups, label: `Bom Groups`, children: <BomGroups /> },
        {
            key: ProductView.AssemblyFields,
            label: `Assembly Fields`,
            children: <AssemblyFields />,
        },
    ]

    return (
        <Container>
            <CreateProductModal
                isOpen={activeModal === ActiveModal.ProductCreate}
                onClose={() => {
                    dispatch(closeModal())
                }}
            />
            <CreateAssemblyFieldModal />
            <UpdateAssemblyFieldModal />
            <CreateBomGroupModal />
            <UpdateBomGroupModal />
            <CreateBomModal />
            <InspectBomModal />
            <UpdateBomModal />
            <UpdateManyBomsModal />
            <CreateBomVersionModal />
            <PageHeader
                title="Products"
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        link: Urls.Products,
                        name: 'Products',
                    },
                ]}
            />
            <ContentContainer>
                <Tabs
                    type="card"
                    activeKey={activeView}
                    onChange={(view) => dispatch(setActiveView(view as ProductView))}
                    defaultActiveKey={ProductView.Products}
                    items={tabsOptions}
                />
            </ContentContainer>
        </Container>
    )
}
