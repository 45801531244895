import { GeneralAssembly } from '../models/models'
import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'

export enum GeneralAssemblyRelation {
    CreatedBy = 'createdByUser',
    Bom = 'builtItem',
    Lines = 'lines',
    LinesAssembledBy = 'linesAssembledBy',
    StartedBy = 'startedBy',
    Activities = 'activities',
    ActivitiesUser = 'activitiesUser',
}
export interface GetGeneralAssembliesDto {
    joins?: GeneralAssemblyRelation[]
    ids?: number[]
    warehouseId?: number
    withDeleted?: boolean
    dateToBeCompletedBydDateRangeBegin?: Date
    dateToBeCompletedByDateRangeEnd?: Date
    completedDateRangeBegin?: Date
    completedDateRangeEnd?: Date
    isCompleted?: boolean
    skip?: number
    limit?: number
}

export const getGeneralAssemblyByIdRequest = (
    id: number,
    dto: GetGeneralAssembliesDto
): Promise<Response<GeneralAssembly>> =>
    backendConnection.request({
        url: `general-assemblies/${id}`,
        params: dto,
        method: 'GET',
    })
export interface CreateLineDto {
    note?: string
    completedAt?: Date
    assembledBy?: Date
}

export interface UpdateGeneralAssemblyDto {
    createLines?: CreateLineDto[]
    description?: string
    deleteLines?: number[]
    dateToBeCompletedBy?: Date
}

export const updateGeneralAssemblyRequest = (
    id: number,
    dto: UpdateGeneralAssemblyDto
): Promise<Response<GeneralAssembly>> =>
    backendConnection.request({
        url: `general-assemblies/${id}`,
        data: dto,
        method: 'PATCH',
    })

export const getGeneralAssembliesRequest = (
    dto: GetGeneralAssembliesDto
): Promise<Response<{ entities: GeneralAssembly[]; count: number }>> =>
    backendConnection.request({
        url: `general-assemblies`,
        params: dto,
        method: 'GET',
    })

export const completeGeneralAssemblyRequest = (id: number): Promise<Response<GeneralAssembly>> =>
    backendConnection.request({
        url: `general-assemblies/complete/${id}`,
        method: 'PUT',
    })

export const deleteGeneralAssembliesRequest = (
    taskId: number
): Promise<Response<GeneralAssembly>> =>
    backendConnection.request({
        url: `general-assemblies/${taskId}`,
        method: 'DELETE',
    })

export interface CreateGeneralAssemblyDto {
    builtItemId: number
    description: string
    dateToBeCompleted: Date
    amount: number
    warehouseId: number
}

export const createGeneralAssemblyRequest = (
    dto: CreateGeneralAssemblyDto
): Promise<Response<GeneralAssembly>> =>
    backendConnection.request({
        url: `general-assemblies`,
        method: 'POST',
        data: dto,
    })
