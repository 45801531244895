import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { Project } from '../../../../models/models'
import SmallButton from '../project-info/SmallButton'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
    deleteContact,
    showCreateContactModal,
    showUpdateContactModal,
} from '../../../../store/single-project-view-slice'
import _ from 'lodash'
import SingleProjectViewNoData from '../SingleProjectViewNoData'

const ActionButton = ({ children, onClick }: any) => {
    return (
        <Box onClick={onClick} sx={{ cursor: 'pointer' }}>
            {children}
        </Box>
    )
}

function Contacts() {
    const dispatch = useAppDispatch()
    const project: Project | null = useAppSelector(
        (state) => state.singleProjectView.projectBeingUpdated
    )
    if (!project) {
        return null
    }

    return (
        <Box>
            <Box sx={{ paddingBottom: '10px', display: 'flex', gap: '15px' }}>
                <SmallButton
                    text={'Add new contact'}
                    onClick={() => dispatch(showCreateContactModal())}
                    icon={<PlusOutlined />}
                />
            </Box>
            <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                <Table sx={{ width: '100%', border: 'none' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Phone</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Address</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.sortBy(project.contacts, (contact) => contact.id).map((contact) => (
                            <TableRow
                                key={contact.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{contact.name}</TableCell>
                                <TableCell>
                                    <a href={`mailto:${contact.email}`}>{contact.email}</a>
                                </TableCell>
                                <TableCell>
                                    <a href={`tel:${contact.phoneNumber}`}>{contact.phoneNumber}</a>
                                </TableCell>
                                <TableCell sx={{ whiteSpace: 'pre' }}>{contact.address}</TableCell>
                                <TableCell>
                                    <Box sx={{ gap: '15px', display: 'flex' }}>
                                        <ActionButton
                                            onClick={() =>
                                                dispatch(showUpdateContactModal(contact))
                                            }
                                        >
                                            <EditOutlined style={{ fontSize: 20 }} />
                                        </ActionButton>
                                        <ActionButton
                                            onClick={() => dispatch(deleteContact(contact))}
                                        >
                                            <DeleteOutlined
                                                style={{
                                                    fontSize: 20,
                                                    color: 'red',
                                                }}
                                            />
                                        </ActionButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {!project.contacts.length && <SingleProjectViewNoData />}
            </TableContainer>
        </Box>
    )
}

export default Contacts
