import { styled } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { getDispatchWorksheetsRequest } from '../../../../api/logistics-worksheet'
import { CallOff, WarehouseId } from '../../../../models/models'
import DispatchCard from './DispatchCard'
import { DispatchCallOffModal } from './DispatchCallOffModal'
import InspectCallOffModal from '../../../modals/inspect-call-off-modal/InspectCallOffModal'
import { FilterBar } from '../Filterbar'

interface Props {
    fetchCallOffs: () => void
    callOffs: CallOff[]
}

const Container = styled(Box)(() => ({}))
const HeaderContainer = styled(Box)(() => ({}))
const ContentContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
}))

enum ActiveModal {
    None = 'None',
    Details = 'Details',
    Dispatch = 'Dispatch',
}
export default function Dispatch({ callOffs, fetchCallOffs }: Props) {
    const [activeModal, setActiveModal] = useState<ActiveModal>(ActiveModal.None)
    const [modalCallOff, setModalCallOff] = useState<CallOff>()

    const openModal = (callOff: CallOff, activeModal: ActiveModal) => {
        setModalCallOff(callOff)
        setActiveModal(activeModal)
    }

    const closeModal = () => {
        setModalCallOff(undefined)
        setActiveModal(ActiveModal.None)
    }

    return (
        <Container>
            <FilterBar />
            {activeModal === ActiveModal.Dispatch && modalCallOff && (
                <DispatchCallOffModal
                    isOpen={activeModal === ActiveModal.Dispatch}
                    closeModal={closeModal}
                    onOk={() => {
                        closeModal()
                        fetchCallOffs()
                    }}
                    callOffId={modalCallOff.id}
                />
            )}

            {activeModal === ActiveModal.Details && modalCallOff && (
                <InspectCallOffModal
                    callOffId={modalCallOff.id}
                    isOpen={activeModal === ActiveModal.Details}
                    closeModal={() => {
                        closeModal()
                    }}
                />
            )}
            <ContentContainer>
                {callOffs.map((callOff) => {
                    return (
                        <DispatchCard
                            openDetailsModal={(callOff: CallOff) =>
                                openModal(callOff, ActiveModal.Details)
                            }
                            openDispatchModal={(callOff: CallOff) =>
                                openModal(callOff, ActiveModal.Dispatch)
                            }
                            key={callOff.id}
                            onClick={fetchCallOffs}
                            callOff={callOff}
                        />
                    )
                })}
            </ContentContainer>
        </Container>
    )
}
