import { Response } from './util/with-response-formatter-interceptor'
import { User } from '../models/models'
import backendConnection from './util/backend-connection'

interface LoginResponse {
    user: User
    accessToken: string
    accessTokenExpiresAt: number
}

export const loginRequest = (email: string, password: string): Promise<Response<LoginResponse>> =>
    backendConnection.request({
        url: '/auth/login',
        method: 'POST',
        data: { email, password },
    })

export const forgotPasswordRequest = (email: string): Promise<Response<LoginResponse>> =>
    backendConnection.request({
        url: `/auth/forgot-password/${email}`,
        method: 'GET',
    })

export const changeForgottenPasswordRequest = (
    token: string,
    newPassword: string,
    confirmPassword: string
): Promise<Response<void>> =>
    backendConnection.request({
        url: `/auth/forgot-password/change/${token}`,
        method: 'POST',
        data: { newPassword, confirmPassword },
    })

export const validateResetPasswordTokenRequest = (token: string): Promise<Response<User>> =>
    backendConnection.request({
        url: `/auth/forgot-password/validate/${token}`,
        method: 'GET',
    })

export const validateVerifyEmailTokenRequest = (token: string): Promise<Response<User>> =>
    backendConnection.request({ url: `/auth/verify-user/validate/${token}`, method: 'GET' })

export const verifyUser = (
    token: string,
    password: string,
    confirmPassword: string
): Promise<Response<User>> =>
    backendConnection.request({
        url: `/auth/verify-user/${token}`,
        method: 'POST',
        data: { password, confirmPassword },
    })
