import { Modal } from 'antd'
import QrReader from 'react-qr-reader'
import { Button, Input, styled } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

const StyledModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 15px;
        overflow: hidden;
    }

    .ant-modal-header {
        border-bottom: none;
    }

    .ant-modal-body {
        padding-top: 0;
    }

    section div {
        box-shadow: rgb(255 255 255 / 50%) 0 0 0 5px inset !important;
        border: 5px dashed rgb(255 255 255 / 50%);
    }
`

const Title = styled('h1')`
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: rgba(38, 38, 38, 0.85);
    text-align: center;
    margin: 0;
`

const ManualText = styled('p')`
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: rgba(38, 38, 38, 0.85);
    text-align: center;
    cursor: pointer;
    margin: 0;
`

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
`

const QrReaderContainer = styled('div')`
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
`

const ManualModeContainer = styled('div')`
    width: 500px;
    height: 250px;
    background: #f0f1f3;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
`

const SerialInput = styled(Input)`
    padding: 15px;
    font-size: 18px;
    text-align: center;
`

const SubmitButton = styled(Button)``

interface Props {
    isVisible: boolean
    hasManualMode: boolean
    onClose: () => void
    onSubmit: (str: string | null) => void
}

function ScannerModal({ isVisible, hasManualMode, onClose, onSubmit }: Props) {
    const qrReaderRef = useRef<any>(null)
    const [value, setValue] = useState<string>('')
    const [isScanning, setIsScanning] = useState<boolean>(false)
    const [isManualMode, setIsManualMode] = useState<boolean>(false)

    function isKamstrupUrl(urlString: string): boolean {
        try {
            const url = new URL(urlString)
            return url.hostname === 'qr.kamstrup.com'
        } catch (error) {
            return false
        }
    }

    function getKamstrupSerialNumberQueryParam(urlString: string): string | null {
        try {
            const url = new URL(urlString)
            const hashParams = new URLSearchParams(url.hash.substr(1))
            return hashParams.has('s') ? hashParams.get('s') : null
        } catch (error) {
            console.error('Invalid URL', error)
            return null
        }
    }

    const forceStopCamera = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices()

            devices.forEach((device) => {
                if (device.kind === 'videoinput') {
                    navigator.mediaDevices
                        .getUserMedia({
                            video: { deviceId: device.deviceId },
                        })
                        .then((stream) => {
                            stream.getTracks().forEach((track) => {
                                track.stop()
                            })
                        })
                        .catch((err) => {
                            console.error('Error stopping the camera:', err)
                        })
                }
            })
        } catch (err) {
            console.error('Error enumerating devices:', err)
        }
    }

    useEffect(() => {
        if (isVisible && qrReaderRef.current && qrReaderRef.current.stopCamera) {
            qrReaderRef.current.clearComponent()
            qrReaderRef.current.stopCamera()
            forceStopCamera()
        }
        if (isVisible && qrReaderRef.current && qrReaderRef.current.initiate) {
            qrReaderRef.current.initiate()
        }
        if (!isVisible && qrReaderRef.current && qrReaderRef.current.stopCamera) {
            qrReaderRef.current.clearComponent()
            qrReaderRef.current.stopCamera()
            forceStopCamera()
        }
    }, [isVisible])

    const resetState = () => {
        setValue('')
        setIsManualMode(false)
    }

    const closeModal = () => {
        resetState()
        onClose()
    }

    return (
        <StyledModal
            visible={isVisible}
            width={550}
            title={<Title>Scan QR code</Title>}
            footer={null}
            onCancel={closeModal}
        >
            <Container>
                {!isManualMode && (
                    <QrReaderContainer>
                        <QrReader
                            ref={qrReaderRef}
                            delay={300}
                            onError={console.error}
                            onScan={(v: string | null) => {
                                if (!v || isScanning) {
                                    return
                                }
                                setIsScanning(true)
                                if (isKamstrupUrl(v)) {
                                    const serialNumber = getKamstrupSerialNumberQueryParam(v)
                                    if (serialNumber) {
                                        onSubmit(serialNumber)
                                        setIsScanning(false)
                                        return
                                    }
                                }
                                onSubmit(v)
                                setIsScanning(false)
                            }}
                            style={{
                                width: 500,
                                height: 500,
                            }}
                        />
                    </QrReaderContainer>
                )}
                {isManualMode && (
                    <ManualModeContainer>
                        <SerialInput value={value} onChange={(e) => setValue(e.target.value)} />
                        <SubmitButton
                            disabled={value.length < 1}
                            onClick={() => {
                                if (value) {
                                    onSubmit(value)
                                }
                            }}
                        >
                            Submit
                        </SubmitButton>
                    </ManualModeContainer>
                )}
                {hasManualMode && (
                    <ManualText onClick={() => setIsManualMode(!isManualMode)}>
                        {isManualMode ? 'Use QR Scanner' : 'Manually enter value'}
                    </ManualText>
                )}
            </Container>
        </StyledModal>
    )
}

export default ScannerModal
