import { Button } from '@mui/material'
import { Box, styled } from '@mui/system'
import { useEffect, useState } from 'react'
import { PurchaseOrder } from '../../../models/models'
import { Input, Select, Table } from 'antd'
import {
    GetManyPurchaseOrderRelation,
    getPurchaseOrderCreatorsRequest,
    getPurchaseOrdersRequest,
    getPurchaseOrderSuppliersRequest,
    PurchaseOrderDocumentStatus,
} from '../../../api/purchase-orders'
import { toastFailure } from '../../../util/toast'
import ActivatePurchaseOrderModal from './ActivatePurchaseOrderModal'
import moment from 'moment'
import InspectPurchaseOrderModal from '../../modals/inspect-purchase-order-modal/InspectPurchaseOrderModal'
import PurchaseOrderOrderlineTable from '../../tables/PurchaseOrderOrderlineTable'
import CreateOrderConfirmationModal from './CreateOrderConfirmationModal'

const InputsContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: '10px',
}))

enum ActiveModal {
    None = 'None',
    Inspect = 'Inspect',
    Activate = 'Activate',
    CreateOc = 'CreateOc',
}

const Container = styled(Box)(``)

export default function PurchaseOrders() {
    const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([])
    const [purchaseOrdersCount, setPurchaseOrdersCount] = useState<number>()
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [purchaseOrderCreators, setPurchaseOrderCreators] = useState<string[]>([])
    const [selectedPurchaseOrderCreator, setSelectedPurchaseOrderCreator] = useState<string>()
    const [purchaseOrderSuppliers, setPurchaseOrderSuppliers] = useState<string[]>([])
    const [selectedPurchaseOrderSupplier, setSelectedPurchaseOrderSupplier] = useState<string>()
    const [selectedIsActivated, setSelectedIsActivated] = useState<boolean>()
    const [selectedDocumentStatus, setSelectedDocumentStatus] =
        useState<PurchaseOrderDocumentStatus>()
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)
    const [documentNo, setDocumentNo] = useState<string>('')
    const [activeModal, setActiveModal] = useState<ActiveModal>(ActiveModal.None)
    const [modalPurchaseOrderId, setModalPurchaseOrderId] = useState<number>()

    const getPurchaseOrderCreators = async () => {
        const response = await getPurchaseOrderCreatorsRequest()
        if (response.successful) {
            setPurchaseOrderCreators(response.data)
        } else {
            setPurchaseOrderCreators([])
            toastFailure(response.message)
        }
    }

    const getPurchaseOrderSuppliers = async () => {
        const response = await getPurchaseOrderSuppliersRequest()
        if (response.successful) {
            setPurchaseOrderSuppliers(response.data)
        } else {
            setPurchaseOrderSuppliers([])
            toastFailure(response.message)
        }
    }

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const getPurchaseOrders = async () => {
        const response = await getPurchaseOrdersRequest({
            relations: [GetManyPurchaseOrderRelation.Orderlines],
            withCount: true,
            likeDocumentNo: documentNo,
            supplier: selectedPurchaseOrderSupplier,
            createdBy: selectedPurchaseOrderCreator,
            isActivated: selectedIsActivated,
            status: selectedDocumentStatus,
            //antd tables pages are 1-indexed
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        })
        if (response.successful) {
            setPurchaseOrders(response.data.purchaseOrders)
            setPurchaseOrdersCount(response.data.count)
        } else {
            toastFailure(response.message)
        }
    }

    const openModal = (purchaseOrderId: number, modal: ActiveModal) => {
        setActiveModal(modal)
        setModalPurchaseOrderId(purchaseOrderId)
    }

    const closeModal = () => {
        setActiveModal(ActiveModal.None)
        setModalPurchaseOrderId(undefined)
    }

    useEffect(() => {
        getPurchaseOrderSuppliers()
    }, [])

    useEffect(() => {
        getPurchaseOrderCreators()
    }, [])

    useEffect(() => {
        getPurchaseOrders()
    }, [
        rowsPerPage,
        page,
        documentNo,
        selectedPurchaseOrderCreator,
        selectedPurchaseOrderSupplier,
        selectedIsActivated,
        selectedDocumentStatus,
    ])

    const tableColumns = [
        {
            title: 'Document number',
            dataIndex: 'documentNo',
            key: 'documentNo',
        },
        {
            title: 'Document created by',
            dataIndex: 'documentCreatedBy',
            key: 'documentCreatedBy',
        },
        {
            title: 'Supplier name',
            key: 'supplierName',
            dataIndex: 'supplierName',
        },
        {
            title: 'Document status',
            key: 'documentStatus',
            dataIndex: 'documentStatus',
        },
        {
            title: 'activated at',
            key: 'activatedAt',
            render: (purchaseOrder: PurchaseOrder) => {
                if (purchaseOrder.activatedDate) {
                    return moment(purchaseOrder.activatedDate).format('DD-MM-YYYY')
                }
                return 'Not activated'
            },
        },
        {
            title: 'Actions',
            key: 'stock item',
            render: (purchaseOrder: PurchaseOrder) => {
                return (
                    <>
                        {purchaseOrder.documentStatus !== 'EnumDocumentStatusComplete' && (
                            <>
                                <Button
                                    variant={'outlined'}
                                    onClick={() => {
                                        openModal(purchaseOrder.id, ActiveModal.Inspect)
                                    }}
                                >
                                    Inspect
                                </Button>
                                {purchaseOrder.activatedDate ? (
                                    <Button
                                        variant={'outlined'}
                                        onClick={() => {
                                            openModal(purchaseOrder.id, ActiveModal.CreateOc)
                                        }}
                                    >
                                        Create OC
                                    </Button>
                                ) : (
                                    <Button
                                        variant={'outlined'}
                                        onClick={() => {
                                            openModal(purchaseOrder.id, ActiveModal.Activate)
                                        }}
                                    >
                                        Activate
                                    </Button>
                                )}
                            </>
                        )}
                    </>
                )
            },
        },
    ]

    const expandedRowRender = (purchaseOrder: PurchaseOrder) => {
        return <PurchaseOrderOrderlineTable purchaseOrder={purchaseOrder} />
    }

    return (
        <Container>
            {activeModal === ActiveModal.Inspect && modalPurchaseOrderId && (
                <InspectPurchaseOrderModal
                    active={true}
                    onOk={() => {
                        closeModal()
                        getPurchaseOrders()
                    }}
                    purchaseOrderId={modalPurchaseOrderId}
                    onClose={closeModal}
                />
            )}

            {activeModal === ActiveModal.Activate && modalPurchaseOrderId && (
                <ActivatePurchaseOrderModal
                    active={true}
                    onOk={() => {
                        closeModal()
                        getPurchaseOrders()
                    }}
                    purchaseOrderId={modalPurchaseOrderId}
                    onClose={closeModal}
                />
            )}

            {activeModal === ActiveModal.CreateOc && modalPurchaseOrderId && (
                <CreateOrderConfirmationModal
                    active={true}
                    onOk={() => {
                        closeModal()
                        getPurchaseOrders()
                    }}
                    purchaseOrderId={modalPurchaseOrderId}
                    onClose={closeModal}
                />
            )}

            <InputsContainer>
                <Input
                    prefix={'Document Number:'}
                    size="middle"
                    style={{
                        padding: 10,
                        borderRadius: '10px',
                        width: '18%',
                    }}
                    value={documentNo}
                    onChange={(e) => setDocumentNo(e.target.value)}
                />

                <Select
                    placeholder={'Creator'}
                    size={'large'}
                    showSearch
                    style={{ width: '18%' }}
                    optionFilterProp={'name'}
                    value={selectedPurchaseOrderCreator}
                    onChange={(purchaseOrderCreator: string | undefined) =>
                        setSelectedPurchaseOrderCreator(purchaseOrderCreator)
                    }
                >
                    <Select.Option key={undefined} value={undefined}>
                        None
                    </Select.Option>
                    {purchaseOrderCreators.map((purchaseOrderCreator) => (
                        <Select.Option key={purchaseOrderCreator} value={purchaseOrderCreator}>
                            {purchaseOrderCreator}
                        </Select.Option>
                    ))}
                </Select>

                <Select
                    placeholder={'Supplier'}
                    size={'large'}
                    showSearch
                    style={{ width: '18%' }}
                    value={selectedPurchaseOrderSupplier}
                    onChange={(purchaseOrderSupplier: string | undefined) =>
                        setSelectedPurchaseOrderSupplier(purchaseOrderSupplier)
                    }
                >
                    <Select.Option key={undefined} value={undefined}>
                        None
                    </Select.Option>
                    {purchaseOrderSuppliers.map((purchaseOrderSupplier) => (
                        <Select.Option key={purchaseOrderSupplier} value={purchaseOrderSupplier}>
                            {purchaseOrderSupplier}
                        </Select.Option>
                    ))}
                </Select>

                <Select
                    placeholder={'Activated'}
                    size={'large'}
                    showSearch
                    style={{ width: '18%' }}
                    optionFilterProp={'name'}
                    value={selectedIsActivated}
                    onChange={(isActivated: boolean | undefined) =>
                        setSelectedIsActivated(isActivated)
                    }
                >
                    <Select.Option key={undefined} value={undefined}>
                        None
                    </Select.Option>
                    <Select.Option key={'true'} value={true}>
                        Activated
                    </Select.Option>
                    <Select.Option key={'false'} value={false}>
                        Not Activated
                    </Select.Option>
                </Select>

                <Select
                    placeholder={'Document status'}
                    size={'large'}
                    showSearch
                    style={{ width: '18%' }}
                    optionFilterProp={'name'}
                    value={selectedDocumentStatus}
                    onChange={(documentStatus: PurchaseOrderDocumentStatus | undefined) =>
                        setSelectedDocumentStatus(documentStatus)
                    }
                >
                    <Select.Option key={undefined} value={undefined}>
                        None
                    </Select.Option>
                    <Select.Option
                        key={PurchaseOrderDocumentStatus.EnumDocumentStatusOnHold}
                        value={PurchaseOrderDocumentStatus.EnumDocumentStatusOnHold}
                    >
                        On hold
                    </Select.Option>
                    <Select.Option
                        key={PurchaseOrderDocumentStatus.EnumDocumentStatusLive}
                        value={PurchaseOrderDocumentStatus.EnumDocumentStatusLive}
                    >
                        Live
                    </Select.Option>
                    <Select.Option
                        key={PurchaseOrderDocumentStatus.EnumDocumentStatusComplete}
                        value={PurchaseOrderDocumentStatus.EnumDocumentStatusComplete}
                    >
                        Complete
                    </Select.Option>
                </Select>
            </InputsContainer>

            <Table
                rowKey="id"
                columns={tableColumns}
                dataSource={purchaseOrders}
                expandable={{ expandedRowRender }}
                size="small"
                pagination={{
                    defaultPageSize: 10,
                    current: page,
                    onChange: pageChange,
                    total: purchaseOrdersCount,
                }}
            />
        </Container>
    )
}
