import '../antd-tables.css'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { UnauthorizedBom } from '../../../models/models'
import { ActiveModal, openModal } from '../../../store/product-authorization-slice'
import { ExportBomsTopBar } from './ExportBomsTopBar'
import {
    UnauthorizedBomRelation,
    getManyUnauthorizedBomsRequest,
} from '../../../api/unauthorized-bom'
import UnauthorizedBomsTable from '../../tables/UnauthorizedBomsTable'
import { Button } from 'antd'

export default function ProductAuthorizationExportBoms() {
    const { user } = useAppSelector((state) => state.authentication)
    const { exportBomFilters, updateTableToken } = useAppSelector(
        (state) => state.productAuthorization
    )
    const dispatch = useAppDispatch()
    return (
        <div>
            <ExportBomsTopBar />
            <UnauthorizedBomsTable
                pagination={true}
                actionComponents={[
                    (unauthorizedBom: UnauthorizedBom) => {
                        return (
                            <Button
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            activeModal: ActiveModal.InspectUnauthorizedBom,
                                            modalEntityId: unauthorizedBom.id,
                                        })
                                    )
                                }
                            >
                                Inspect
                            </Button>
                        )
                    },
                ]}
                dependencies={{ exportBomFilters, updateTableToken }}
                request={(pagination) => {
                    return getManyUnauthorizedBomsRequest({
                        withCount: true,
                        relations: [
                            UnauthorizedBomRelation.Components,
                            UnauthorizedBomRelation.Product,
                            UnauthorizedBomRelation.RequestedByUser,
                            UnauthorizedBomRelation.RequestedForProject,
                        ],
                        onlySubmittable: true,
                        //antd tables pages are 1-indexed
                        skip: pagination?.skip,
                        limit: pagination?.limit,
                    })
                }}
            />
        </div>
    )
}
