import { Box, Dialog, IconButton, Paper, Alert } from '@mui/material'
import { useEffect, useState } from 'react'
import {
    getManyUnauthorizedBomsRequest,
    UnauthorizedBomRelation,
} from '../../../api/unauthorized-bom'
import { UnauthorizedBom } from '../../../models/models'
import CTRLoader from '../../ui/loader/CTRloader'
import { toastFailure, toastSuccess } from '../../../util/toast'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Checkbox, Divider, Steps, Table } from 'antd'
import { styled } from '@mui/styles'
import { getBomsFileRequest, submitBomsRequest } from '../../../api/bom-version'

const Footer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
})
interface DownloadBomsModalProps {
    isOpen: boolean
    closeModal: () => void
}

enum ModalState {
    Error = 'Error',
    Ready = 'Ready',
    Loading = 'Loading',
}
interface UnauthorizedBomMap {
    [id: number]: UnauthorizedBom
}

interface UnauthorizedBomToDownloadMap {
    [id: number]: boolean
}

export enum DownloadBomStep {
    Select,
    Confirm,
}
export const ExportBomsModal = ({ isOpen, closeModal }: DownloadBomsModalProps) => {
    //takes too long to render list if it has to iterate through a lot of unauthorized boms in an array
    const [unauthorizedBoms, setUnauthorizedBoms] = useState<UnauthorizedBomMap>({})
    const [bomsToDownload, setBomsToDownload] = useState<UnauthorizedBomToDownloadMap>({})
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const [activeStep, setActiveStep] = useState<DownloadBomStep>(DownloadBomStep.Select)
    const [downloadedBomIds, setDownloadedBomIds] = useState<number[]>([])

    const fetchUnauthorizedBoms = () => {
        return getManyUnauthorizedBomsRequest({
            relations: [
                UnauthorizedBomRelation.BomVersion,
                UnauthorizedBomRelation.Components,
                UnauthorizedBomRelation.Product,
                UnauthorizedBomRelation.RequestedByUser,
                UnauthorizedBomRelation.RequestedForProject,
            ],
            onlySubmittable: true,
        }).then((response) => {
            if (response.successful) {
                const unauthorizedBoms: UnauthorizedBomMap = {}
                response.data.entities.forEach((unauthorizedBom) => {
                    unauthorizedBoms[unauthorizedBom.id] = unauthorizedBom
                })
                setUnauthorizedBoms(unauthorizedBoms)
                const unauthorizedBomsToDownload: UnauthorizedBomToDownloadMap = {}
                response.data.entities.forEach((unauthorizedBom) => {
                    unauthorizedBomsToDownload[unauthorizedBom.id] = true
                })
                setBomsToDownload(unauthorizedBomsToDownload)
                setModalState(ModalState.Ready)
            } else {
                toastFailure('Something went wrong finding the built item')
                setModalState(ModalState.Error)
            }
        })
    }

    useEffect(() => {
        fetchUnauthorizedBoms()
    }, [])

    const downloadBomTableColumns = [
        {
            title: 'Code',
            render: (bom: UnauthorizedBom) => {
                return <Box>{bom?.bomCode || 'Could not get code'}</Box>
            },
        },
        {
            title: 'Description',
            render: (bom: UnauthorizedBom) => {
                return <Box>{bom?.product?.description || 'Could not get description'}</Box>
            },
        },
        {
            title: 'Bom version',
            render: (bom: UnauthorizedBom) => {
                return <Box>{bom?.bomVersion?.version || 'Could not get version'}</Box>
            },
        },
        {
            title: 'Selected',
            render: (bom: UnauthorizedBom) => {
                return (
                    <Box>
                        <Checkbox
                            onChange={() => {
                                const clone = { ...bomsToDownload }
                                clone[bom.id] = !clone[bom.id]
                                setBomsToDownload(clone)
                            }}
                            checked={bomsToDownload[bom.id]}
                        ></Checkbox>
                    </Box>
                )
            },
        },
    ]

    const stepperItems = [
        {
            title: 'Select Boms',
            content: 'First-content',
        },
        {
            title: 'Confirm Sage Import',
            content: 'Second-content',
        },
    ]

    return (
        <Dialog open={isOpen} onClose={closeModal} style={{ zIndex: 100 }} fullWidth maxWidth="lg">
            <Paper style={{ width: '100%', padding: '20px' }}>
                <Box
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box> Import BOMs to Sage</Box>
                    <IconButton aria-label="close" onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                {modalState === ModalState.Loading && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            margin: '20px',
                            width: '100%',
                            height: '200px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CTRLoader />
                    </Box>
                )}
                {modalState === ModalState.Error && <Box>Something went wrong</Box>}
                {modalState === ModalState.Ready && unauthorizedBoms && (
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '10px 200px 20px 200px',
                            }}
                        >
                            <Steps current={activeStep} items={stepperItems} />
                        </Box>

                        {activeStep === DownloadBomStep.Select && (
                            <Box sx={{ display: 'flex', gap: '50px', flexDirection: 'column' }}>
                                <Table
                                    className="product-tables"
                                    dataSource={Object.values(unauthorizedBoms)}
                                    columns={downloadBomTableColumns}
                                    size="small"
                                />
                            </Box>
                        )}
                        {activeStep === DownloadBomStep.Confirm && (
                            <Box sx={{ display: 'flex', gap: '50px', flexDirection: 'column' }}>
                                <Alert severity="info">
                                    Make sure that the downloaded BOMs are correct before inserting
                                    into Sage. When importing first: <br />
                                    1. Validate and note down which BOMs did not pass if any.
                                    <br />
                                    2. When importing remember to check off the box saying
                                    <b> Make stock item active after import</b>
                                </Alert>
                                <Alert severity="warning">
                                    By approving the submit you are confirming that <b>ALL</b> the
                                    items have been imported into sage. If any did not pass
                                    validation, go back to
                                    <b> Select Boms to Import</b> and uncheck the unpassing BOMs
                                    before confirming the submit.
                                </Alert>
                            </Box>
                        )}
                    </Box>
                )}
                <Divider />
                <Footer>
                    <Button style={{ margin: '10px' }} onClick={closeModal}>
                        Cancel
                    </Button>
                    {activeStep === DownloadBomStep.Select && (
                        <Button
                            type="primary"
                            style={{ margin: '10px' }}
                            onClick={() => {
                                const amountOfBomsToDownload = Object.values(bomsToDownload).filter(
                                    (value) => value
                                )
                                if (amountOfBomsToDownload.length === 0) {
                                    toastFailure('Cannot download zero BOMS')
                                    return
                                }
                                const entries = Object.entries(bomsToDownload)
                                const entriesToDownload = entries.filter((entry) => entry[1])
                                const unauthorizedBomIds = entriesToDownload.map(
                                    (entryToDownload) => Number(entryToDownload[0])
                                )
                                const bomVersionIds: number[] = []
                                unauthorizedBomIds.forEach((unauthorizedBomId) => {
                                    bomVersionIds.push(
                                        unauthorizedBoms[unauthorizedBomId].bomVersionId
                                    )
                                })
                                setDownloadedBomIds(bomVersionIds)
                                getBomsFileRequest(bomVersionIds)
                                setActiveStep(DownloadBomStep.Confirm)
                            }}
                        >
                            Download Boms
                        </Button>
                    )}
                    {activeStep === DownloadBomStep.Confirm && (
                        <Button
                            type="primary"
                            style={{ margin: '10px' }}
                            onClick={async () => {
                                const response = await submitBomsRequest(downloadedBomIds)
                                if (response.successful) {
                                    toastSuccess('Succesfully submitted the BOMs')
                                    closeModal()
                                } else {
                                    toastFailure(response.message)
                                }
                            }}
                        >
                            Confirm submit
                        </Button>
                    )}
                </Footer>
            </Paper>
        </Dialog>
    )
}
