import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { BACKEND_URL } from '../../configurations/backend-configuration'
import withResponseFormatterInterceptor from './with-response-formatter-interceptor'

export const STORAGE_TOKEN_KEY = 'authentication_token'
export const SAGE_TOKEN_KEY = 'sage_token'

const backendConnection = axios.create({
    baseURL: BACKEND_URL,
})

/*
	Since your token is dynamic, you can't define it in your axios instance factory headers setup. 
	Best way to handle this globally is to use request interceptors
	https://stackoverflow.com/questions/57624855/how-to-use-dynamic-auth-header-in-axiosvue-js
*/
backendConnection.interceptors.request.use((requestConfig: AxiosRequestConfig<AxiosInstance>) => {
    requestConfig.headers = {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${
            localStorage.getItem(STORAGE_TOKEN_KEY) || sessionStorage.getItem(STORAGE_TOKEN_KEY)
        }`,
        'Cache-Control': 'no-cache',
        sage: `${localStorage.getItem(SAGE_TOKEN_KEY)}`,
    }
    return requestConfig
})

export default withResponseFormatterInterceptor(backendConnection)
