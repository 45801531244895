import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { ComponentsVariants } from '@mui/material/styles/variants'
import { BorderColors } from './colors'

export const OutlinedInputThemeStyles: {
    styleOverrides: ComponentsOverrides['MuiOutlinedInput']
    variants: ComponentsVariants['MuiOutlinedInput']
} = {
    styleOverrides: {
        root: {
            borderRadius: 10,
            '& fieldset': {
                border: `1px solid ${BorderColors.lighterGrey}`,
            },
        },
    },
    variants: [
        {
            props: { className: 'without-legend' },
            style: {
                overflow: 'hidden',
                '& legend': {
                    width: 0,
                    display: 'none',
                },
                height: '45px',
                '& fieldset': {
                    top: 0,
                    height: '45px',
                },
            },
        },
    ],
}
