import { Table, Tooltip, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { styled } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import {
    Dialog,
    Paper,
    Box,
    IconButton,
    Divider,
    Stepper,
    Step,
    StepButton,
    Alert,
    Input,
    Button,
    Autocomplete,
    TextField,
    InputAdornment,
    AutocompleteRenderInputParams,
    FormControlLabel,
    Checkbox,
} from '@mui/material'
import { getPurchaseOrderByIdRequest, GetPurchaseOrderRelation } from '../../../api/purchase-orders'
import { CallOff, Project, PurchaseOrder, PurchaseOrderOrderline } from '../../../models/models'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { useAppSelector } from '../../../store/hooks'
import CTRLoader from '../../ui/loader/CTRloader'
import moment, { Moment } from 'moment'
import DeleteIcon from '@mui/icons-material/Delete'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { InputSection } from '../../ui/InputFields'
import { getProjectsRequest } from '../../../api/projects/projects'
import CallOffsTable from '../../tables/CallOffsTable'
import {
    getCallOffsRequest,
    GetManyCallOffRelations,
    GetManyCallOffSortBy,
} from '../../../api/call-off'
import {
    CreateOrderConfirmationDTO,
    createOrderConfirmationRequest,
} from '../../../api/order-confirmation'
import DatePicker from '../../ui/calendar/DatePicker'

const Container = styled(Box)({
    margin: '25px',
})

const Text = styled(Typography)(() => ({}))

const Label = (props: { text: string; isInvalid?: boolean }) => {
    const { text, isInvalid } = props
    return (
        <Box style={{ width: '180px' }}>
            <Text style={{ color: '#262626', opacity: '45%' }}>{text}</Text>
        </Box>
    )
}

const RowContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
})

interface ModalProps {
    active: boolean
    purchaseOrderId: number
    onOk: () => void
    onClose: () => void
}

enum PurchaseOrderState {
    Unused = 'Unused',
    Loading = 'Loading',
    Found = 'Found',
    NotFound = 'NotFound',
    Error = 'Error',
}

export enum CreateOcModalStep {
    Select,
    AssignCallOff,
    Confirm,
}

interface AmountInputs {
    [orderlineId: number]: string
}

export default function CreateOrderConfirmationModal({
    active,
    onOk,
    onClose,
    purchaseOrderId,
}: ModalProps) {
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>()
    const [purchaseOrderState, setPurchaseOrderState] = useState<PurchaseOrderState>(
        PurchaseOrderState.Unused
    )
    const [expectedCompletelyArrivedDate, setExpectedCompletelyArrivedDate] =
        useState<Moment | null>(null)
    const [code, setCode] = useState<string>('')
    const [activeStep, setActiveStep] = useState<CreateOcModalStep>(CreateOcModalStep.Select)
    const [amountInputs, setAmountInputs] = useState<AmountInputs>({})
    const [projectOptions, setProjectOptions] = useState<Project[]>([])
    const [projectNameSearchText, setProjectNameSearchText] = useState<string>('')
    const [projectSelectOpen, setProjectSelectOpen] = useState<boolean>(false)
    const [loadingProjectOptions, setLoadingProjectOptions] = useState<boolean>(false)
    const [selectedProject, setSelectedProject] = useState<Project>()
    const [projectCallOffs, setProjectCallOffs] = useState<CallOff[]>([])
    const [selectedCallOff, setSelectedCallOff] = useState<CallOff>()
    const { user: currentUser } = useAppSelector((state) => state.authentication)
    const [hasBeenDispatched, setHasBeenDispatched] = useState<boolean>(false)

    const close = () => {
        setPurchaseOrder(undefined)
        onClose()
    }

    useEffect(() => {
        if (activeStep === CreateOcModalStep.Select) {
            setSelectedProject(undefined)
            setSelectedCallOff(undefined)
            setProjectCallOffs([])
        }
    }, [activeStep])

    useEffect(() => {
        setLoadingProjectOptions(true)
        getProjectsRequest({
            //mui tables pages are zero-indexed
            like: projectNameSearchText,
        }).then((response) => {
            if (response.successful) {
                setProjectOptions(response.data?.projects || [])
            } else {
                setProjectOptions([])
                toastFailure(response.message)
            }
        })
    }, [projectNameSearchText, loadingProjectOptions])

    const getProjectCallOffs = async () => {
        if (!selectedProject) {
            return
        }

        const response = await getCallOffsRequest({
            relations: [
                GetManyCallOffRelations.Assemblies,
                GetManyCallOffRelations.BuiltItemCallOff,
                GetManyCallOffRelations.StockItemCallOff,
                GetManyCallOffRelations.ProjectBuiltItem,
                GetManyCallOffRelations.ProjectStockItem,
                GetManyCallOffRelations.Bom,
                GetManyCallOffRelations.BuiltItemProduct,
                GetManyCallOffRelations.Product,
                GetManyCallOffRelations.SalesOrder,
                GetManyCallOffRelations.Project,
            ],
            hasBeenDispatched: hasBeenDispatched ? undefined : false,
            sortBy: GetManyCallOffSortBy.CustomerDispatchDate,
            projectId: selectedProject.id,
        })

        if (response.successful) {
            setProjectCallOffs(response.data.callOffs)
        } else {
            toastFailure(response.message)
        }
    }

    useEffect(() => {
        if (selectedProject) {
            getProjectCallOffs()
        }
    }, [selectedProject, hasBeenDispatched])

    if (!currentUser) {
        toastFailure('Current user is not present')
        close()
    }

    const disabledDate = (current: Moment) => {
        // Can not select days before today inclusive
        return current && current < moment().endOf('day')
    }

    const createOC = async () => {
        if (!expectedCompletelyArrivedDate) {
            return
        }
        const body: CreateOrderConfirmationDTO = {
            expectedCompletelyArrivedDate: expectedCompletelyArrivedDate.utc().toDate(),
            orderlines: [],
            callOffId: selectedCallOff?.id,
            purchaseOrderId,
            code,
        }
        Object.entries(amountInputs).forEach((amountInput) => {
            // eslint-disable-next-line prefer-const
            let [orderlineId, amount] = amountInput
            amount = Number(amount)

            if (Number(amount) > 0) {
                body.orderlines.push({ orderlineId: Number(orderlineId), amount })
            }
        })
        if (body.orderlines.length === 0) {
            toastFailure('Cannot create OC without allocated products')
            return
        }
        const response = await createOrderConfirmationRequest(body)
        if (response.successful) {
            toastSuccess('Successfully created the OC')
            onOk()
        } else {
            toastFailure(response.message)
        }
    }

    const getPurchaseOrder = async () => {
        if (!currentUser) {
            return
        }
        setPurchaseOrderState(PurchaseOrderState.Loading)
        const response = await getPurchaseOrderByIdRequest(purchaseOrderId, {
            relations: [
                GetPurchaseOrderRelation.Deliveries,
                GetPurchaseOrderRelation.OrderConfirmations,
                GetPurchaseOrderRelation.Orderlines,
            ],
            withAmounts: true,
        })
        if (response.successful) {
            if (!response.data.activatedDate) {
                setPurchaseOrderState(PurchaseOrderState.Error)
                toastFailure('The purchase order has not been activated')
                return
            }
            setPurchaseOrder(response.data)
            const amountInputs: AmountInputs = response.data.orderlines.reduce<AmountInputs>(
                (acc, cur) => {
                    acc[cur.id] = ''
                    return acc
                },
                {}
            )
            setAmountInputs(amountInputs)
            setPurchaseOrderState(PurchaseOrderState.Found)
        } else if (response.status === 404) {
            toastFailure(response.message)
            setPurchaseOrderState(PurchaseOrderState.NotFound)
        } else {
            setPurchaseOrderState(PurchaseOrderState.Error)
            toastFailure(response.message)
        }
    }

    useEffect(() => {
        getPurchaseOrder()
    }, [])

    const selectStepColumns = [
        {
            title: 'code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Amount',
            key: 'Amount',
            render: (orderline: PurchaseOrderOrderline) => {
                if (orderline?.lineQuantity !== undefined) {
                    return orderline.lineQuantity
                }
                return 'Indecisive'
            },
        },
        {
            title: 'Allocated',
            key: 'Allocated',
            render: (orderline: PurchaseOrderOrderline) => {
                if (orderline?.lineQuantity !== undefined) {
                    return `${
                        Number(orderline.allocatedAmount) + Number(amountInputs[orderline.id])
                    }/${orderline.lineQuantity}`
                }
                return 'Indecisive'
            },
        },
        {
            title: 'Amount on OC',
            key: 'AmountOnOc',
            render: (orderline: PurchaseOrderOrderline) => {
                return (
                    <Input
                        type="number"
                        value={amountInputs[orderline.id]}
                        disabled={
                            orderline?.allocatedAmount === orderline.lineQuantity &&
                            amountInputs[orderline.id] === ''
                        }
                        onChange={(e) => {
                            const clone = { ...amountInputs }
                            if (orderline?.allocatedAmount === undefined) {
                                return
                            }
                            if (e.target.value === '') {
                                clone[orderline.id] = e.target.value
                                setAmountInputs(clone)
                                return
                            }
                            const newValue = Number(e.target.value)
                            if (newValue > orderline.lineQuantity - orderline.allocatedAmount) {
                                clone[orderline.id] = String(
                                    orderline.lineQuantity - orderline.allocatedAmount
                                )
                            } else {
                                clone[orderline.id] = newValue.toString()
                            }
                            setAmountInputs(clone)
                        }}
                    />
                )
            },
        },
    ]

    const confirmStepColumns = [
        {
            title: 'code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'OC amount',
            key: 'OCAmount',
            render: (orderline: PurchaseOrderOrderline) => {
                return amountInputs[orderline.id]
            },
        },
        {
            title: 'Amount on PO',
            key: 'Amount',
            render: (orderline: PurchaseOrderOrderline) => {
                if (orderline?.lineQuantity !== undefined) {
                    return orderline.lineQuantity
                }
                return 'Indecisive'
            },
        },
        {
            title: 'PO orderline after allocation',
            key: 'After allocation',
            render: (orderline: PurchaseOrderOrderline) => {
                if (orderline?.lineQuantity !== undefined) {
                    return `${
                        Number(orderline.allocatedAmount) + Number(amountInputs[orderline.id])
                    }/${orderline.lineQuantity}`
                }
                return 'Indecisive'
            },
        },
    ]

    return (
        <Dialog
            open={active}
            onClose={close}
            fullWidth
            sx={{ zIndex: '100', minHeight: '500px' }}
            maxWidth={'lg'}
        >
            <Paper>
                <Box
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        Create OC {purchaseOrder?.documentNo && `for ${purchaseOrder?.documentNo}`}{' '}
                    </div>
                    <IconButton aria-label="close" onClick={close}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Container>
                    {purchaseOrderState === PurchaseOrderState.Unused && <></>}
                    {purchaseOrderState === PurchaseOrderState.Loading && <></>}
                    {purchaseOrderState === PurchaseOrderState.Error && (
                        <>Something went wrong finding the purchase order</>
                    )}
                    {purchaseOrderState === PurchaseOrderState.NotFound && (
                        <>Could not find the purchase order</>
                    )}
                    {purchaseOrderState === PurchaseOrderState.Loading && (
                        <Paper style={{ height: '500px' }}>
                            <CTRLoader />
                        </Paper>
                    )}
                    {purchaseOrder && purchaseOrderState === PurchaseOrderState.Found && (
                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Stepper
                                    activeStep={activeStep}
                                    nonLinear
                                    orientation="horizontal"
                                    style={{ width: '500px', marginBottom: '20px' }}
                                >
                                    <Step>
                                        <StepButton
                                            onClick={() => setActiveStep(CreateOcModalStep.Select)}
                                        >
                                            <Typography>OC details</Typography>
                                        </StepButton>
                                    </Step>
                                    {/*

                                    <Step>
                                        <StepButton
                                            onClick={() =>
                                                setActiveStep(CreateOcModalStep.AssignCallOff)
                                            }
                                        >
                                            <Typography>Assign call off</Typography>
                                        </StepButton>
                                    </Step>
                                    */}
                                    <Step>
                                        <StepButton>
                                            <Typography>Confirm details</Typography>
                                        </StepButton>
                                    </Step>
                                </Stepper>
                            </Box>

                            {activeStep === CreateOcModalStep.Select && (
                                <Box sx={{ display: 'flex', gap: '50px', flexDirection: 'column' }}>
                                    <RowContainer>
                                        <Label text="Expected Delivery Date" />
                                        <DatePicker
                                            style={{ width: '300px' }}
                                            format="DD-MM-YYYY"
                                            value={expectedCompletelyArrivedDate}
                                            disabledDate={disabledDate}
                                            onChange={(value) =>
                                                setExpectedCompletelyArrivedDate(value)
                                            }
                                        />
                                    </RowContainer>
                                    <RowContainer>
                                        <Label text="OC Code" />
                                        <Input
                                            prefix={'Code:'}
                                            style={{
                                                borderRadius: '10px',
                                                width: '300px',
                                            }}
                                            value={code}
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                    </RowContainer>
                                    <Table
                                        size={'small'}
                                        dataSource={
                                            purchaseOrder?.orderlines?.sort(
                                                (a, b) => a.lineNumber - b.lineNumber
                                            ) || []
                                        }
                                        columns={selectStepColumns}
                                        pagination={{ pageSize: 100 }}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginRight: '50px',
                                        }}
                                    >
                                        <Button
                                            sx={{ width: '150px' }}
                                            variant="outlined"
                                            disabled={
                                                Object.values(amountInputs).reduce(
                                                    (acc, cur) => acc + Number(cur),
                                                    0
                                                ) < 1 ||
                                                !expectedCompletelyArrivedDate ||
                                                code.length < 5
                                            }
                                            onClick={() => setActiveStep(CreateOcModalStep.Confirm)}
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                            {/*
                            activeStep === CreateOcModalStep.AssignCallOff && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '50px',
                                        flexDirection: 'column',
                                        minHeight: '600px',
                                    }}
                                >
                                    {!selectedProject && (
                                        <InputSection text="Set Project">
                                            <Autocomplete
                                                id="tags-outlined"
                                                sx={{
                                                    width: '100%',
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                fullWidth
                                                disablePortal
                                                open={projectSelectOpen}
                                                onOpen={() => {
                                                    setProjectSelectOpen(true)
                                                }}
                                                onClose={() => {
                                                    setProjectSelectOpen(false)
                                                }}
                                                value={null}
                                                onChange={(_, project) => {
                                                    if (!project) {
                                                        return
                                                    }
                                                    setSelectedProject(project)
                                                }}
                                                inputValue={projectNameSearchText}
                                                onInputChange={(_, newInputValue) => {
                                                    setProjectNameSearchText(newInputValue)
                                                }}
                                                getOptionLabel={(option) => option.name || ''}
                                                options={projectOptions}
                                                renderInput={(
                                                    params: AutocompleteRenderInputParams
                                                ) => <TextField {...params} />}
                                            />
                                        </InputSection>
                                    )}
                                    {selectedProject && (
                                        <>
                                            <InputSection text="Selected Project">
                                                <TextField
                                                    key="Project"
                                                    id="outlined-read-only-input"
                                                    //removing the label
                                                    sx={{
                                                        width: '100%',
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    value={selectedProject.name}
                                                    disabled={true}
                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    edge="end"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        setSelectedProject(
                                                                            undefined
                                                                        )
                                                                        setSelectedCallOff(
                                                                            undefined
                                                                        )
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </InputSection>

                                            <Container
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'right',
                                                }}
                                            >
                                                <FormControlLabel
                                                    value="start"
                                                    control={
                                                        <Checkbox
                                                            value={hasBeenDispatched}
                                                            onChange={() => {
                                                                setHasBeenDispatched(
                                                                    !hasBeenDispatched
                                                                )
                                                            }}
                                                        />
                                                    }
                                                    label="Show Dispatched Call-Offs"
                                                    labelPlacement="start"
                                                />
                                            </Container>
                                            <CallOffsTable
                                                callOffs={projectCallOffs}
                                                greenRows={
                                                    selectedCallOff
                                                        ? [selectedCallOff.id]
                                                        : undefined
                                                }
                                                actionComponents={[
                                                    (callOffId: number) => {
                                                        if (selectedCallOff?.id === callOffId) {
                                                            return (
                                                                <Button
                                                                    variant={'outlined'}
                                                                    onClick={() => {
                                                                        setSelectedCallOff(
                                                                            undefined
                                                                        )
                                                                    }}
                                                                >
                                                                    Unselect
                                                                </Button>
                                                            )
                                                        }
                                                        if (
                                                            projectCallOffs.find((pco) => {
                                                                return expectedCompletelyArrivedDate?.isAfter(
                                                                    moment(pco.dateToBeCompletedBy)
                                                                )
                                                            })
                                                        ) {
                                                            return (
                                                                <RowContainer
                                                                    sx={{
                                                                        justifyContent:
                                                                            'space-evenly',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <Tooltip title="You cannot select a call-off that are to be completed prior to the delivery of the order confirmation">
                                                                        <WarningAmberIcon htmlColor="red" />
                                                                    </Tooltip>
                                                                    <Button
                                                                        variant={'outlined'}
                                                                        disabled={true}
                                                                        onClick={() => {
                                                                            undefined
                                                                        }}
                                                                    >
                                                                        Select
                                                                    </Button>
                                                                </RowContainer>
                                                            )
                                                        }
                                                        return (
                                                            <Button
                                                                variant={'outlined'}
                                                                onClick={() => {
                                                                    setSelectedCallOff(
                                                                        projectCallOffs.find(
                                                                            (c) =>
                                                                                c.id === callOffId
                                                                        )
                                                                    )
                                                                }}
                                                            >
                                                                Select
                                                            </Button>
                                                        )
                                                    },
                                                ]}
                                            />
                                        </>
                                    )}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            height: '100%',
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={() => setActiveStep(CreateOcModalStep.Confirm)}
                                        >
                                            {selectedCallOff ? 'Next' : 'Skip'}
                                        </Button>
                                    </Box>
                                </Box>
                            )}      
                            */}
                            {activeStep === CreateOcModalStep.Confirm && (
                                <Box sx={{ display: 'flex', gap: '50px', flexDirection: 'column' }}>
                                    <Alert severity="info">
                                        Make sure that the amounts specified are correct. If they
                                        are not, then it might result in blocking call-offs related
                                        to the OC.
                                    </Alert>
                                    <RowContainer>
                                        <Label text="Expected Delivery Date" />
                                        <Text>
                                            {expectedCompletelyArrivedDate?.format('DD-MM-YYYY')}
                                        </Text>
                                    </RowContainer>
                                    <RowContainer>
                                        <Label text="OC Code" />
                                        <Text>{code}</Text>
                                    </RowContainer>
                                    <RowContainer>
                                        <Label text="For Project" />
                                        <Text>
                                            {selectedCallOff && selectedProject
                                                ? selectedProject.name
                                                : 'None'}
                                        </Text>
                                    </RowContainer>
                                    <RowContainer>
                                        <Label text="For Call off" />
                                        <Text>{selectedCallOff ? selectedCallOff.id : 'None'}</Text>
                                    </RowContainer>
                                    <Table
                                        size={'small'}
                                        dataSource={purchaseOrder?.orderlines?.filter(
                                            (o) => Number(amountInputs[o.id]) > 0
                                        )}
                                        columns={confirmStepColumns}
                                        pagination={{ pageSize: 100 }}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginRight: '50px',
                                        }}
                                    >
                                        <Button
                                            sx={{ width: '150px' }}
                                            variant="outlined"
                                            onClick={createOC}
                                        >
                                            Create OC
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )}
                </Container>
            </Paper>
        </Dialog>
    )
}
