import { styled } from '@mui/styles'
import { useSelector } from 'react-redux'
import { Button, Input } from 'antd'
import { ChangeEvent } from 'react'
import { RootState } from '../../../store/store'
import { useAppDispatch } from '../../../store/hooks'
import {
    ActiveModal,
    BomFilters,
    ExportBomFilters,
    openModal,
    setBomFilters,
} from '../../../store/product-authorization-slice'
import { Label } from '../../ui/Label'
import { FieldContainer } from '../../ui/FIeldContainer'

const Container = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}))

const InputsContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0px 0px 10px 0px',
    gap: '5px',
}))

const ActionsContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0px 0px 10px 0px',
    gap: '5px',
}))

export const ExportBomsTopBar = () => {
    const { exportBomFilters } = useSelector((state: RootState) => state.productAuthorization)
    const dispatch = useAppDispatch()

    const mutateExportBomFilters = (key: keyof ExportBomFilters, value: string | undefined) => {
        dispatch(setBomFilters({ ...exportBomFilters, [key]: value }))
    }

    return (
        <Container>
            <InputsContainer></InputsContainer>
            <ActionsContainer>
                <Button
                    type="primary"
                    onClick={() =>
                        dispatch(
                            openModal({
                                activeModal: ActiveModal.ExportBoms,
                            })
                        )
                    }
                >
                    Export To Sage
                </Button>
            </ActionsContainer>
        </Container>
    )
}
