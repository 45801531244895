import { TextField } from '@mui/material'
import { Box, styled } from '@mui/system'
import { Assembly } from '../../../models/models'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '50%',
    gap: '20px',
})

interface Props {
    assembly: Assembly
}
export default function ProductTab(props: Props) {
    const product = props.assembly?.builtItemCallOff?.sopBuiltItemOrderline?.builtItem?.product
    return (
        <Container>
            <TextField
                key="Code"
                id="outlined-read-only-input"
                label="Code"
                defaultValue={product?.code}
                InputProps={{
                    readOnly: true,
                }}
                disabled={true}
            />
            <TextField
                key="Description"
                id="outlined-read-only-input"
                label="Description"
                defaultValue={product?.description}
                InputProps={{
                    readOnly: true,
                }}
                disabled={true}
            />
            <TextField
                key="Name"
                id="outlined-read-only-input"
                label="Name"
                defaultValue={product?.name}
                InputProps={{
                    readOnly: true,
                }}
                disabled={true}
            />
        </Container>
    )
}
