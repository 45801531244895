import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { Input } from 'antd'
import { SalesOrder } from '../../../models/models'
import { Label } from '../../ui/Label'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

const GeneralDetailsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    margin: '20px',
})

const AddressContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    margin: '20px',
})

interface Props {
    salesOrder: SalesOrder
}

export default function DeliveryAddressTab({ salesOrder }: Props) {
    return (
        <Container>
            <GeneralDetailsContainer>
                <Label>Contact</Label>
                <Input
                    key={salesOrder.documentNo}
                    defaultValue={salesOrder?.deliveryAddress?.contact}
                    readOnly={true}
                />
                <Label>Telephone Number</Label>
                <Input
                    key={salesOrder?.customer?.name}
                    defaultValue={salesOrder?.deliveryAddress?.telephoneNumber}
                    readOnly={true}
                />
                <Label>Fax Number</Label>
                <Input
                    key={salesOrder?.customer?.balance}
                    defaultValue={salesOrder?.deliveryAddress?.faxNumber}
                    readOnly={true}
                />
                <Label>Tax Number</Label>
                <Input
                    key={salesOrder?.customer?.creditLimit}
                    defaultValue={salesOrder?.deliveryAddress?.taxNumber}
                    readOnly={true}
                />
            </GeneralDetailsContainer>
            <AddressContainer>
                <Label>Postal Name</Label>
                <Input
                    key={salesOrder.documentNo}
                    defaultValue={salesOrder?.deliveryAddress?.postalName}
                    readOnly={true}
                />
                <Label>Country</Label>
                <Input
                    key={salesOrder?.customer?.name}
                    defaultValue={salesOrder?.deliveryAddress?.country}
                    readOnly={true}
                />
                <Label>Address 1</Label>
                <Input
                    key={salesOrder?.customer?.balance}
                    defaultValue={salesOrder?.deliveryAddress?.address1}
                    readOnly={true}
                />
                <Label>Address 2</Label>
                <Input
                    key={salesOrder?.customer?.creditLimit}
                    defaultValue={salesOrder?.deliveryAddress?.address2}
                    readOnly={true}
                />
                <Label>Address 3</Label>
                <Input
                    key={salesOrder?.customer?.customerLocationAddressLine1}
                    defaultValue={salesOrder?.deliveryAddress?.address3}
                    readOnly={true}
                />
                <Label>Address 4</Label>
                <Input
                    key={salesOrder?.customer?.name}
                    defaultValue={salesOrder?.deliveryAddress?.address4}
                    readOnly={true}
                />
                <Label>City</Label>
                <Input
                    key={salesOrder?.customer?.balance}
                    defaultValue={salesOrder?.deliveryAddress?.city}
                    readOnly={true}
                />
                <Label>Post code</Label>
                <Input
                    key={salesOrder?.customer?.customerLocationAddressLine1}
                    defaultValue={salesOrder?.deliveryAddress?.postcode}
                    readOnly={true}
                />
            </AddressContainer>
        </Container>
    )
}
