import { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Input, Modal } from 'antd'
import moment, { Moment } from 'moment'
import { Task, TaskDepartment, UserRole, WarehouseId } from '../../../models/models'
import { toastFailure } from '../../../util/toast'
import { CircularProgress, styled } from '@mui/material'
import '../../tables/table-styles.css'
import { getTaskByIdRequest, TaskRelation } from '../../../api/tasks'
import { OrderedListOutlined } from '@ant-design/icons'
import { getUsersRequest } from '../../../api/users'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 47%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

interface UpdateTaskModalProps {
    taskId: number
    closeModal: () => void
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

export default function InspectTaskModal({ taskId, closeModal }: UpdateTaskModalProps) {
    const [description, setDescription] = useState<string>('')
    const [dateToBeCompleted, setDateToBeCompleted] = useState<Moment | null>(null)
    const [completedAt, setCompletedAt] = useState<Moment | null>(null)
    const [warehouseId, setWarehouseId] = useState<WarehouseId>()
    const [userOptions, setUserOptions] = useState<{ value: number; label: string }[]>([])
    const [selectedUserId, setSelectedUserId] = useState<number>()
    const [minutes, setMinutes] = useState<number>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const [taskDepartment, setTaskDepartment] = useState<TaskDepartment>(TaskDepartment.ANY)
    const [task, setTask] = useState<Task>()

    useEffect(() => {
        getTask()
    }, [])

    useEffect(() => {
        if (modalState === ModalState.Success) {
            getUsers()
        }
    }, [taskDepartment, warehouseId, modalState])

    const getUsers = async () => {
        const roles = {
            [TaskDepartment.ASSEMBLY]: [UserRole.Assembler],
            [TaskDepartment.LOGISTICS]: [UserRole.LogisticsEmployee],
            [TaskDepartment.ANY]: [UserRole.Assembler, UserRole.LogisticsEmployee],
        }
        const response = await getUsersRequest({ warehouseId, roles: roles[taskDepartment] })

        if (!response.successful) {
            toastFailure('Could not retrieve available users')
            closeModal()
            return
        }

        const userOptions = response.data.entities.map((u) => ({
            value: u.id,
            label: `${u.firstName} ${u.lastName}`,
        }))

        setUserOptions(userOptions)

        if (!userOptions.find((option) => option.value === selectedUserId)) {
            setSelectedUserId(undefined)
        }
    }

    const getTask = async () => {
        const response = await getTaskByIdRequest(taskId, {
            relations: [
                TaskRelation.AssignedTo,
                TaskRelation.CreatedBy,
                TaskRelation.Type,
                TaskRelation.Project,
            ],
        })

        if (response.successful) {
            const task = response.data
            setTask(task)
            setDescription(task.description)
            setTaskDepartment(task.department)
            setCompletedAt(moment(task.completedAt))
            setSelectedUserId(task.assignedUserId)
            setMinutes(task.minutes)
            setWarehouseId(task.warehouseId)
            setDateToBeCompleted(moment(task.dateToBeCompletedBy))
            setModalState(ModalState.Success)
        } else {
            if (response.status === 404) {
                setModalState(ModalState.NotFound)
            } else {
                setModalState(ModalState.Error)
            }
            toastFailure(response.message)
        }
    }

    return (
        <Modal open={true} footer={null} onCancel={closeModal}>
            {modalState === ModalState.Loading && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        margin: '20px',
                        width: '100%',
                        height: '500px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                </Box>
            )}
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.NotFound && (
                <Box>Could not find the task with the id of {taskId}</Box>
            )}
            {modalState === ModalState.Success && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Inspect task {taskId}</Title>
                    </Header>
                    <Divider />
                    <div>
                        <Label>Type </Label>
                        <Input value={task?.type?.title} readOnly={true} />
                    </div>
                    <div>
                        <Label>Description</Label>
                        <Input.TextArea
                            value={description}
                            readOnly={true}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{ minHeight: 200 }}
                        />
                    </div>
                    <InputRow>
                        <RowElement>
                            <Label>Date to be completed</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                value={moment(dateToBeCompleted).format('DD/MM/YYYY')}
                                onWheel={(e) => e.preventDefault()}
                                onChange={(e) => setMinutes(Number(e.target.value))}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Completed Date</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                value={moment(completedAt).format('DD/MM/YYYY')}
                                onWheel={(e) => e.preventDefault()}
                                onChange={(e) => setMinutes(Number(e.target.value))}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow>
                        <RowElement>
                            <Label>Warehouse</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                value={
                                    [
                                        { value: WarehouseId.Monument, label: 'Monument' },
                                        { value: WarehouseId.Scandia, label: 'Scandia' },
                                        { value: null, label: 'None' },
                                    ].find((option) => option.value === warehouseId)?.label ||
                                    'None'
                                }
                                onWheel={(e) => e.preventDefault()}
                                onChange={(e) => setMinutes(Number(e.target.value))}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Department</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                value={
                                    [
                                        { value: TaskDepartment.ASSEMBLY, label: 'Assembly' },
                                        { value: TaskDepartment.LOGISTICS, label: 'Logistics' },
                                        { value: TaskDepartment.ANY, label: 'Any' },
                                    ].find((option) => option.value === taskDepartment)?.label ||
                                    'None'
                                }
                                onWheel={(e) => e.preventDefault()}
                                onChange={(e) => setMinutes(Number(e.target.value))}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow>
                        <RowElement>
                            <Label>Assigned User</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                value={
                                    userOptions.find((option) => option.value === selectedUserId)
                                        ?.label || 'None'
                                }
                                onWheel={(e) => e.preventDefault()}
                                onChange={(e) => setMinutes(Number(e.target.value))}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Minutes</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                type="number"
                                value={minutes}
                                onWheel={(e) => e.preventDefault()}
                                onChange={(e) => setMinutes(Number(e.target.value))}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow>
                        <RowElement>
                            <Label>Link</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                value={task?.link || 'None'}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Project</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                value={task?.project?.name || 'None'}
                            />
                        </RowElement>
                    </InputRow>
                </Box>
            )}
        </Modal>
    )
}
