import { Box, Paper, Typography } from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import { Breadcrumbs } from './breadcrumbs/Breadcrumbs'

export interface Breadcrumb {
    name: string
    link?: string
    onClickAction?: () => void
}

interface Props {
    title: string | ReactElement
    breadcrumbs: Breadcrumb[]
    titleLeftIcon?: ReactNode | null
    children?: ReactNode | null
}

export default function PageHeader({
    title,
    titleLeftIcon,
    breadcrumbs,
    children,
}: Props): ReactElement {
    return (
        <Paper
            elevation={0}
            square
            sx={{
                flexDirection: 'column',
                padding: '16px 24px',
            }}
        >
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <Box
                sx={{
                    display: 'flex',
                    gap: '20px',
                    alignItems: 'center',
                    marginTop: '20px',
                }}
            >
                {titleLeftIcon}
                <Typography component="h2" variant="h2">
                    {title}
                </Typography>
            </Box>

            {children}
        </Paper>
    )
}
