import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

export interface ProjectMasterState {
    filterProjectManager: string
    filterAccountManager: string
    filterOpportunityStatus: string
    filterProjectName: string
}
const initialState: ProjectMasterState = {
    filterProjectManager: '',
    filterAccountManager: '',
    filterOpportunityStatus: '',
    filterProjectName: '',
}

export const projectMasterSlice = createSlice({
    name: 'project-master-slice',
    initialState,
    reducers: {
        resetState(state) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state = initialState
        },
        setFilterProjectManager(state, action: PayloadAction<string>) {
            state.filterProjectManager = action.payload
        },
        setFilterAccountManager(state, action: PayloadAction<string>) {
            state.filterAccountManager = action.payload
        },
        setFilterProjectName(state, action: PayloadAction<string>) {
            state.filterProjectName = action.payload
        },
        setFilterOpportunityStatus(state, action: PayloadAction<string>) {
            state.filterOpportunityStatus = action.payload
        },
    },
})

export const selectActiveView = (state: RootState) => state.callOff.activeView

export const {
    setFilterProjectManager,
    setFilterAccountManager,
    setFilterProjectName,
    setFilterOpportunityStatus,
} = projectMasterSlice.actions

export default projectMasterSlice.reducer
