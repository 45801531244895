import { Box } from '@mui/system'
import { Divider, Button, Modal } from 'antd'
import { styled, Typography } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { OrderedListOutlined } from '@ant-design/icons'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

interface ConfimrationModalProps {
    isVisible: boolean
    action: () => void
    onClose: () => void
}

export const ConfimrationModal = ({ isVisible, action, onClose }: ConfimrationModalProps) => {
    return (
        <Modal open={isVisible} onCancel={onClose} footer={null}>
            <Box>
                <Header>
                    <OrderedListOutlined style={{ fontSize: '20px' }} />
                    <Title>Confirm</Title>
                </Header>
                <Divider />
            </Box>
            <Box>
                <Typography style={{ textAlign: 'left', fontSize: '20px' }}>
                    Are you sure
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                <Button style={{ marginTop: '20px' }} disabled={false} onClick={onClose}>
                    No
                </Button>
                <Button
                    type={'primary'}
                    style={{ marginTop: '20px', marginLeft: '10PX' }}
                    disabled={false}
                    onClick={action}
                >
                    Yes
                </Button>
            </Box>
        </Modal>
    )
}
