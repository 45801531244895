import { Response } from '../util/with-response-formatter-interceptor'
import {
    Contact,
    CreditStatus,
    OpportunityStatus,
    Project,
    ProjectEvent,
} from '../../models/models'
import backendConnection from '../util/backend-connection'
import moment from 'moment'

export interface ProjectDTO {
    opportunityReference?: string
    projectName?: string
    valueInGBP?: number
    customerId?: number
    opportunityStatusId?: number
    postcode?: string
    address?: string
    creditStatusId?: number
    applicationsEngineerUserIds: number[]
    accountManagerUserIds: number[]
    projectManagerUserIds: number[]
    consultantOrganisationIds: number[]
    contractorOrganisationIds: number[]
    clientOrganisationIds: number[]
}

export const createProjectRequest = (data: ProjectDTO): Promise<Response<Project>> =>
    backendConnection.request({
        url: '/projects',
        method: 'POST',
        data,
    })

export const updateProjectRequest = (id: number, data: ProjectDTO): Promise<Response<Project>> =>
    backendConnection.request({
        url: `/projects/${id}`,
        method: 'PUT',
        data,
    })

interface GetProjectsQueryParamsDTO {
    skip?: number
    limit?: number
    like?: string
    projectManagerId?: string
    accountManagerId?: string
    opportunityStatusId?: string
}

export const getProjectsRequest = (
    params?: GetProjectsQueryParamsDTO
): Promise<Response<{ projects: Project[]; totalCount: number }>> =>
    backendConnection.request({
        url: '/projects',
        method: 'GET',
        params,
    })

export const findProjectsRequest = (opportunityReference: string): Promise<Response<Project>> =>
    backendConnection.request({
        url: `/projects/find?reference=${opportunityReference}`,
        method: 'GET',
    })

export const getProjectByIdRequest = (id: number): Promise<Response<Project>> =>
    backendConnection.request({
        url: `/projects/id/${id}`,
        method: 'GET',
    })

export const importProjectByReferenceRequest = (
    opportunityReference: string
): Promise<Response<Project>> =>
    backendConnection.request({
        url: `/projects/import/opportunity-reference/${opportunityReference}`,
        method: 'GET',
    })

/* Contact */
export const createContactRequest = (
    projectId: number,
    attributes: {
        [key: string]: string
    }
): Promise<Response<Contact>> =>
    backendConnection.request({
        url: `/projects/${projectId}/contacts`,
        method: 'POST',
        data: attributes,
    })

export const updateContactRequest = (
    id: number,
    attributes: {
        [key: string]: string
    }
): Promise<Response<Contact>> =>
    backendConnection.request({
        url: `/projects/contacts/${id}`,
        method: 'PUT',
        data: attributes,
    })

export const deleteContactRequest = (contactId: number): Promise<Response<Contact>> =>
    backendConnection.request({
        url: `/projects/contacts/${contactId}`,
        method: 'DELETE',
    })

export const createOpportunityStatusRequest = (attributes: {
    [key: string]: string
}): Promise<Response<OpportunityStatus>> =>
    backendConnection.request({
        url: '/projects/opportunity-statuses',
        method: 'POST',
        data: attributes,
    })

export const opportunityStatusesRequest = (): Promise<Response<OpportunityStatus[]>> =>
    backendConnection.request({
        url: '/projects/opportunity-statuses',
        method: 'GET',
    })

export const pinProject = (projectId: number, isPinned: boolean): Promise<Response<Project>> =>
    backendConnection.request({
        //Backend implementation is retarded ID, should be path param
        url: 'projects/pin',
        method: 'PUT',
        data: {
            projectId,
            isPinned,
        },
    })

export const deleteOpportunityStatusRequest = (id: number | undefined): Promise<Response<void>> =>
    backendConnection.request({
        url: `/projects/opportunity-statuses/${id}`,
        method: 'DELETE',
    })

/* CreditStatus */
export const creditStatusesRequest = (): Promise<Response<CreditStatus[]>> =>
    backendConnection.request({
        url: '/projects/credit-statuses',
        method: 'GET',
    })

export const deleteCreditStatusRequest = (id: number | undefined): Promise<Response<void>> =>
    backendConnection.request({
        url: `/projects/credit-statuses/${id}`,
        method: 'DELETE',
    })

export interface ProjectValueDto {
    totalValue: number
    dispatchedValue: number
}

export const getProjectValue = (id: number): Promise<Response<ProjectValueDto>> =>
    backendConnection.request({
        url: `/projects/value/${id}`,
        method: 'GET',
    })

/* History */
export const projectHistoryRequest = (projectId: number): Promise<Response<ProjectEvent[]>> =>
    backendConnection.request({
        url: `/projects/${projectId}/history`,
        method: 'GET',
        ...{
            mapper(events: any[]) {
                return events.map((event) => ({
                    ...event,
                    timeCreatedAt: moment(event.timeCreatedAt),
                }))
            },
        },
    })
