import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import { BuiltItemCallOff, CallOff, StockItemCallOff } from '../../../models/models'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Table } from 'antd'
import { callOffBuiltItemsCompletedDetails } from '../../../util/util'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    gap: '30px',
    width: '100%',
})

interface Props {
    callOff: CallOff
}

export default function ProductsTab(props: Props) {
    const callOff = props.callOff
    const builtItemCompletedDetails = callOffBuiltItemsCompletedDetails(callOff)

    const stockItemColumns = [
        {
            title: 'Code',
            key: 'code',
            render: (element: StockItemCallOff) => {
                return element.sopStockItemOrderline.product.code
            },
        },
        {
            title: 'Description',
            key: 'description',
            render: (element: StockItemCallOff) => {
                return element.sopStockItemOrderline.product.description
            },
        },
        {
            title: 'Amount',
            key: 'amount',
            render: (element: StockItemCallOff) => {
                return element.amount
            },
        },
    ]
    const builtItemColumns = [
        {
            title: 'Code',
            key: 'code',
            render: (element: BuiltItemCallOff) => {
                return element.sopBuiltItemOrderline.builtItem.bomCode
            },
        },
        {
            title: 'Description',
            key: 'description',
            render: (element: BuiltItemCallOff) => {
                return element.sopBuiltItemOrderline.builtItem.product.description
            },
        },
        {
            title: 'Amount',
            key: 'amount',
            render: (element: BuiltItemCallOff) => {
                return element.amount
            },
        },
        {
            title: 'Allocated',
            key: 'allocated',
            render: (element: BuiltItemCallOff) => {
                return `${
                    builtItemCompletedDetails &&
                    builtItemCompletedDetails[element.id].assemblyAllocatedAmount
                }/${element.amount}`
            },
        },
        {
            title: 'Completed',
            key: 'completed',
            render: (element: BuiltItemCallOff) => {
                return `${
                    builtItemCompletedDetails &&
                    builtItemCompletedDetails[element.id].completedAmount
                }/${element.amount}`
            },
        },
    ]

    return (
        <Container>
            <div style={{ boxShadow: '0px 0px 30px -10px rgba(150,170,180,0.5)' }}>
                <Accordion disabled={callOff.builtItemCallOffs.length === 0} key={'builtitem'}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Bom</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table
                            dataSource={callOff.builtItemCallOffs}
                            columns={builtItemColumns}
                            pagination={false}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>
            <div style={{ boxShadow: '0px 0px 30px -10px rgba(150,170,180,0.5)' }}>
                <Accordion disabled={callOff.stockItemCallOffs.length === 0} key={'stockItem'}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Stock Items</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table
                            dataSource={callOff.stockItemCallOffs}
                            columns={stockItemColumns}
                            pagination={false}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>
        </Container>
    )
}
