import { Button, Chip, Divider, styled, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Assembly } from '../../../../models/models'
import moment from 'moment'
import { toastWarn } from '../../../../util/toast'
import { findHiu, isFlatstation } from '../../../../util/util'

interface TransferCardProps {
    warehouseId: number
    assembly: Assembly
    onClick: () => void
    openDetailsModal: (assembly: Assembly) => void
}
const CardRow = (props: { description: string; content: string; disableDivider?: boolean }) => {
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '10px',
    }))

    const RowContent = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: '6px',
    }))

    const RowDescriptionContainer = styled(Box)(() => ({
        paddingLeft: '10px',
        width: '50%',
    }))

    const RowContentContainer = styled(Box)(() => ({
        width: '50%',
    }))

    const { description, content, disableDivider } = props
    return (
        <Row>
            <RowContent>
                <RowDescriptionContainer>
                    <Typography sx={{ color: '#26262690' }}>{description}</Typography>
                </RowDescriptionContainer>
                <RowContentContainer>
                    <Typography>{content}</Typography>
                </RowContentContainer>
            </RowContent>
            {!disableDivider && <Divider />}
        </Row>
    )
}

const CardHeader = (props: {
    assembly: Assembly
    openDetailsModal: (assembly: Assembly) => void
}) => {
    const { assembly, openDetailsModal } = props
    const Row = styled(Box)(() => ({
        backgroundColor: '#ffcccb',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        padding: '5px',
    }))

    const ProjectName = styled(Box)(() => ({
        justifyContent: 'center',
        alignSelf: 'center',
        paddingLeft: '10px',
    }))

    const DetailsButton = styled(Button)(() => ({
        padding: '0px',
        minWidth: '0px',
    }))

    return (
        <div>
            <Row>
                <ProjectName>{assembly.callOff?.project?.name}</ProjectName>
                <DetailsButton
                    variant="text"
                    size="small"
                    onClick={() => openDetailsModal(assembly)}
                >
                    <Typography>Inspect Assembly</Typography>
                </DetailsButton>
            </Row>
            <Row>
                {(assembly.callOff.isOnHold || assembly.isOnHold) && (
                    <Chip
                        sx={{
                            marginTop: '10px',
                            color: '#ff1a1a',
                            whiteSpace: 'normal',
                            flexWrap: 'wrap',
                            borderColor: '#ff1a1a',
                            backgroundColor: '#ffcccc',
                        }}
                        label="Is on hold"
                        variant="outlined"
                    />
                )}
            </Row>
            <Row>
                {assembly.callOff?.isOnHold && (
                    <Chip
                        sx={{
                            marginTop: '10px',
                            color: '#ff1a1a',
                            whiteSpace: 'normal',
                            flexWrap: 'wrap',
                            borderColor: '#ff1a1a',
                            backgroundColor: '#ffcccc',
                        }}
                        label="Is on hold"
                        variant="outlined"
                    />
                )}
            </Row>
        </div>
    )
}

const CardFooter = (props: { onClick: () => void }) => {
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
    }))

    const TransferButton = styled(Button)(() => ({
        marginTop: '100%',
        padding: '0px',
        minWidth: '0px',
        margin: '10px 10px 5px 0px',
    }))

    return (
        <Row>
            <TransferButton variant="outlined" size="small" onClick={props.onClick}>
                Assembly transfer
            </TransferButton>
        </Row>
    )
}

interface CardContainerProps {
    dateToBeCompletedBy: Date
}

const CardContainer = styled(Box)<CardContainerProps>((props: CardContainerProps) => ({
    border: moment(props.dateToBeCompletedBy).isBefore(moment(), 'day') ? 'solid 1px red' : 'none',
    display: 'flex',
    flexDirection: 'column',
    width: '380px',
    margin: '20px',
    paddingBottom: '10px',
    backgroundColor: '#FFF',
    boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
}))

export default function AssemblyTransferCard(props: TransferCardProps) {
    const { assembly, openDetailsModal, onClick } = props

    return (
        <CardContainer dateToBeCompletedBy={props.assembly.dateToBeCompletedBy}>
            <CardHeader assembly={assembly} openDetailsModal={openDetailsModal} />
            <CardRow description="Assembly id" content={assembly.id.toString()} />
            <CardRow description="Call off id" content={assembly.callOffId.toString()} />

            {isFlatstation(assembly.builtItemCallOff.sopBuiltItemOrderline.builtItem) ? (
                <CardRow
                    description="Product"
                    content={
                        findHiu(assembly.builtItemCallOff.sopBuiltItemOrderline.builtItem)?.code ??
                        'Could not find Hiu'
                    }
                />
            ) : (
                <CardRow
                    description="Product to build"
                    content={assembly.builtItemCallOff.sopBuiltItemOrderline.builtItem.bomCode}
                />
            )}
            <CardRow description="Units amount" content={assembly.amount.toString()} />

            <CardFooter
                onClick={() => {
                    if (assembly.callOff.isOnHold) {
                        toastWarn('Cannot transfer since the worksheet is on hold')
                        return
                    }
                    if (assembly.isOnHold) {
                        toastWarn('Cannot transfer since the worksheet is on hold')
                        return
                    }
                    onClick()
                }}
            ></CardFooter>
        </CardContainer>
    )
}
