import React, { useEffect, useMemo, useState } from 'react'
import { Select } from 'antd'
import type { SelectProps } from 'antd'
import useDebounce from '../project-master/single-project-view/hooks'

type Value = string | number | null | undefined

export const sortLabelAlphaNumerically = (
    a: {
        value: Value
        label: string
    },
    b: {
        value: Value
        label: string
    }
) => {
    if (a.label === b.label) {
        return 0
    }

    const aLabel = a.label.toLowerCase()
    const bLabel = b.label.toLowerCase()

    if (aLabel < bLabel) {
        return -1
    }

    return 1
}

interface Props {
    placeholder: string
    style: React.CSSProperties
    optionSorter?: (
        a: {
            value: Value
            label: string
        },
        b: {
            value: Value
            label: string
        }
    ) => number
    selectedValue: Value
    initialSearchValue?: string
    showLoading?: boolean
    disableSearch?: boolean
    addonAfter?: React.ReactNode
    excludeValues?: Value[]
    setSelectedValue: (v: any) => void
    request: (searchString: string) => Promise<
        {
            value: Value
            label: string
        }[]
    >
}

export const SearchInput = ({
    placeholder,
    request,
    selectedValue,
    initialSearchValue,
    optionSorter,
    setSelectedValue,
    style = {},
    disableSearch = false,
    showLoading = false,
    addonAfter,
    excludeValues,
}: Props) => {
    const [data, setData] = useState<SelectProps['options']>([])
    const [search, setSearch] = useState<string>(initialSearchValue ?? '')
    const [isLoading, setIsLoading] = useState(false)

    const deps = useDebounce(search, 300)

    const updateSearch = (value: string) => {
        if (disableSearch) {
            return
        }
        setSearch(value)
    }

    useEffect(() => {
        setIsLoading(true)
        setSelectedValue(undefined)
        request(search || '').then((d) => {
            if (optionSorter) {
                d.sort(optionSorter)
            }
            setData(d)
            setIsLoading(false)
        })
    }, [deps])

    const options = useMemo(() => {
        const options = (data || []).map((d) => ({
            value: d.value,
            label: d.label,
        }))

        if (excludeValues) {
            return options.filter((o) => !excludeValues.includes(o.value))
        }

        return options
    }, [data, excludeValues])

    return (
        <div style={{ position: 'relative', display: 'inline-block', ...style }}>
            <Select
                showSearch={!disableSearch}
                value={selectedValue || search}
                placeholder={placeholder}
                loading={showLoading && isLoading}
                style={style}
                suffixIcon={addonAfter}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={updateSearch}
                onSelect={(v) => {
                    setSelectedValue(v)
                }}
                onChange={setSelectedValue}
                notFoundContent={null}
                options={options}
            />
            {addonAfter && (
                <div
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        pointerEvents: 'none',
                    }}
                >
                    {addonAfter}
                </div>
            )}
        </div>
    )
}
