import { TextField } from '@mui/material'
import { Box, styled } from '@mui/system'
import { Assembly } from '../../../models/models'
import moment from 'moment'
const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
})

const InformationContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

const AnalysisCodesContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    margin: '20px',
})

const SalesOrderInformationContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    margin: '20px',
})

interface Props {
    assembly: Assembly
}
export default function CallOffTab(props: Props) {
    const assembly = props.assembly
    return (
        <Container>
            <InformationContainer>
                <SalesOrderInformationContainer>
                    <TextField
                        key="Created at"
                        id="outlined-read-only-input"
                        label="Created at"
                        defaultValue={moment(assembly.callOff.timeCreatedAt).format('DD/MM/YYYY')}
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        key="Customer dispatch date"
                        id="outlined-read-only-input"
                        label="Customer dispatch date"
                        defaultValue={moment(assembly.callOff.customerDispatchDate).format(
                            'DD/MM/YYYY'
                        )}
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        key="Delivery date"
                        id="outlined-read-only-input"
                        label="Delivery date"
                        defaultValue={moment(assembly.callOff.deliveryDate).format('DD/MM/YYYY')}
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        key="Date to be completed by"
                        id="outlined-read-only-input"
                        label="Date to be completed by"
                        defaultValue={moment(assembly.callOff.dateToBeCompletedBy).format(
                            'DD/MM/YYYY'
                        )}
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled={true}
                    />
                </SalesOrderInformationContainer>
                <AnalysisCodesContainer>
                    <TextField
                        key={assembly.callOffId}
                        id="outlined-basic"
                        variant="outlined"
                        label="Call Off Id"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={assembly.callOffId}
                    />
                    <TextField
                        key={assembly.callOff.status}
                        id="outlined-basic"
                        variant="outlined"
                        label="Status"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={assembly.callOff.status}
                    />
                    <TextField
                        key={assembly.callOff.status}
                        id="outlined-basic"
                        variant="outlined"
                        label="Is all goods in"
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={assembly.callOff.isAllGoodsIn ? 'Yes' : 'No'}
                    />
                </AnalysisCodesContainer>
            </InformationContainer>
        </Container>
    )
}
