import { Divider, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'

interface InfoColumnProps {
    description: string
    content: string
    disableDivider?: boolean
}

const Column = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
})

const ColumnContent = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '6px',
})

const RowDescriptionContainer = styled(Box)({
    paddingLeft: '10px',
    paddingRight: '40px',
})

const RowContentContainer = styled(Box)({
    paddingRight: '10px',
})

export const InfoColumn = (props: InfoColumnProps) => {
    const { description, content, disableDivider } = props

    return (
        <Column>
            <ColumnContent>
                <RowDescriptionContainer>
                    <Typography sx={{ color: '#26262690' }}>{description}</Typography>
                </RowDescriptionContainer>
                <RowContentContainer>
                    <Typography>{content}</Typography>
                </RowContentContainer>
            </ColumnContent>
            {!disableDivider && <Divider orientation="vertical" />}
        </Column>
    )
}
