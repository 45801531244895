import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { Input } from 'antd'
import { SalesOrder } from '../../../models/models'
import { Label } from '../../ui/Label'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

const AnalysisCodesContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    margin: '20px',
})

const SalesOrderInformationContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    margin: '20px',
})

interface Props {
    salesOrder: SalesOrder
}
export default function DetailsTab({ salesOrder }: Props) {
    return (
        <Container>
            <SalesOrderInformationContainer>
                <Label>Document No</Label>
                <Input
                    key={salesOrder.documentNo}
                    defaultValue={salesOrder.documentNo}
                    readOnly={true}
                />
                <Label>Name</Label>
                <Input
                    key={salesOrder?.customer?.name}
                    defaultValue={salesOrder?.customer?.name}
                    readOnly={true}
                />
                <Label>Customer Balance</Label>
                <Input
                    key={salesOrder?.customer?.balance}
                    defaultValue={salesOrder?.customer?.balance}
                    readOnly={true}
                />
                <Label>Customer Credit Limit</Label>
                <Input
                    key={salesOrder?.customer?.creditLimit}
                    defaultValue={salesOrder?.customer?.creditLimit}
                    readOnly={true}
                />
                <Label>Customer Address</Label>
                <Input
                    key={salesOrder?.customer?.customerLocationAddressLine1}
                    defaultValue={salesOrder?.customer?.customerLocationAddressLine1}
                    readOnly={true}
                />
            </SalesOrderInformationContainer>
            <AnalysisCodesContainer>
                <Label>Project Name</Label>
                <Input
                    key={salesOrder.analysisCodes.analysisCode1}
                    readOnly={true}
                    defaultValue={salesOrder.analysisCodes.analysisCode1}
                />
                <Label>Project Number</Label>
                <Input
                    key={salesOrder.analysisCodes.analysisCode2}
                    readOnly={true}
                    defaultValue={salesOrder.analysisCodes.analysisCode2}
                />
                <Label>Project Manager</Label>
                <Input
                    key={salesOrder.analysisCodes.analysisCode3}
                    readOnly={true}
                    defaultValue={salesOrder.analysisCodes.analysisCode3}
                />
                <Label>Region</Label>
                <Input
                    key={salesOrder.analysisCodes.analysisCode4}
                    readOnly={true}
                    defaultValue={salesOrder.analysisCodes.analysisCode4}
                />
                <Label>Case Number</Label>
                <Input
                    key={salesOrder.analysisCodes.analysisCode5}
                    readOnly={true}
                    defaultValue={salesOrder.analysisCodes.analysisCode5}
                />
                <Label>Dispatch from Master Order</Label>
                <Input
                    key={salesOrder.analysisCodes.analysisCode6}
                    readOnly={true}
                    defaultValue={salesOrder.analysisCodes.analysisCode6}
                />
            </AnalysisCodesContainer>
        </Container>
    )
}
