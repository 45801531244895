import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WarehouseId } from '../models/models'
import { RootState } from './store'

export enum ActiveModal {
    GeneralAssemblyCreate = 'GeneralAssemblyCreate',
    GeneralAssemblyUpdate = 'GeneralAssemblyUpdate',
    GeneralAssemblyDelete = 'GeneralAssemblyDelete',
    GeneralAssemblyInspect = 'GeneralAssemblyInspect',
    None = 'None',
}

export interface GeneralAssemblyState {
    activeView: GeneralAssemblyView
    filterWarehouseId: WarehouseId
    filterDateToBeCompletedBydDateRangeBegin?: number
    filterDateToBeCompletedByDateRangeEnd?: number
    filterCompletedDateRangeBegin?: number
    filterCompletedDateRangeEnd?: number
    activeModal: ActiveModal
    modalEntityId?: number
    // token that tells to update the table
    updateTableToken?: string
}

export enum GeneralAssemblyView {
    GeneralAssemblies = 'GeneralAssemblies',
}

const initialState: GeneralAssemblyState = {
    activeView: GeneralAssemblyView.GeneralAssemblies,
    filterWarehouseId: WarehouseId.Monument,
    filterDateToBeCompletedBydDateRangeBegin: undefined,
    filterDateToBeCompletedByDateRangeEnd: undefined,
    filterCompletedDateRangeBegin: undefined,
    filterCompletedDateRangeEnd: undefined,
    activeModal: ActiveModal.None,
    modalEntityId: undefined,
    updateTableToken: undefined,
}

export const taskSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        resetState(state) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state = initialState
        },
        openModal(
            state,
            action: PayloadAction<{ activeModal: ActiveModal; modalEntityId?: number }>
        ) {
            state.activeModal = action.payload.activeModal
            state.modalEntityId = action.payload.modalEntityId
        },
        closeModal(state, action: PayloadAction<string | undefined>) {
            state.activeModal = ActiveModal.None
            state.updateTableToken = action.payload
            state.modalEntityId = undefined
        },
        setFilterDateToBeCompletedByDateRangeBegin(
            state,
            action: PayloadAction<number | undefined>
        ) {
            state.filterDateToBeCompletedBydDateRangeBegin = action.payload
        },
        setFilterDateToBeCompletedByDateRangeEnd(state, action: PayloadAction<number | undefined>) {
            state.filterDateToBeCompletedByDateRangeEnd = action.payload
        },
        setFilterCompletedDateRangeBegin(state, action: PayloadAction<number | undefined>) {
            state.filterCompletedDateRangeBegin = action.payload
        },
        setFilterCompletedDateRangeEnd(state, action: PayloadAction<number | undefined>) {
            state.filterCompletedDateRangeEnd = action.payload
        },
        setFilterWarehouseId(state, action: PayloadAction<WarehouseId>) {
            state.filterWarehouseId = action.payload
        },
        setActiveView(state, action: PayloadAction<GeneralAssemblyView>) {
            state.activeView = action.payload
        },
    },
})

export const selectActiveView = (state: RootState) => state.callOff.activeView

export const {
    resetState,
    setActiveView,
    setFilterWarehouseId,
    setFilterCompletedDateRangeBegin,
    setFilterCompletedDateRangeEnd,
    setFilterDateToBeCompletedByDateRangeEnd,
    setFilterDateToBeCompletedByDateRangeBegin,
    openModal,
    closeModal,
} = taskSlice.actions

export default taskSlice.reducer
