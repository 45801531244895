import { Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from '../../../store/hooks'
import { ActiveModal, openModal, SelectedType, unSelect } from '../../../store/warehouse-map-slice'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import ViewStreamOutlinedIcon from '@mui/icons-material/ViewStreamOutlined'
import AddIcon from '@mui/icons-material/Add'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { ActionBarContainer } from './ActionBarContainer'
import { useMemo } from 'react'

export const RegularActionBar = () => {
    const { selected } = useAppSelector((state) => state.warehouseMap)
    const dispatch = useDispatch()

    //if a single pallet is selected, return the id
    const selectedPalletId = useMemo(() => {
        return selected.type === SelectedType.Pallet && selected.selectedId
    }, [selected])

    return (
        <ActionBarContainer>
            {selected.type === SelectedType.None && (
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => dispatch(openModal({ modal: ActiveModal.AddPallet }))}
                    style={{ margin: '5px 23px', padding: '6px 14px 6px 9px' }}
                >
                    <AddIcon style={{ marginRight: '4px' }} /> Add
                </Button>
            )}
            {selected.type !== SelectedType.None && (
                <Button
                    variant={'contained'}
                    color={'secondary'}
                    onClick={() => dispatch(unSelect())}
                    style={{ margin: '5px 23px', padding: '6px 14px 6px 9px' }}
                >
                    <ClearOutlinedIcon style={{ marginRight: '4px' }} />
                    {`Unselect ${selected.type === SelectedType.Pallet ? 'Pallet' : 'Stack'}`}
                </Button>
            )}
            {selectedPalletId && (
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() =>
                        dispatch(
                            openModal({
                                modal: ActiveModal.InspectPallet,
                                palletId: selectedPalletId,
                            })
                        )
                    }
                    style={{ margin: '5px 23px', padding: '6px 14px 6px 9px' }}
                >
                    <SearchOutlinedIcon style={{ marginRight: '4px' }} /> Inspect Pallet
                </Button>
            )}
            {selectedPalletId && (
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() =>
                        dispatch(
                            openModal({
                                modal: ActiveModal.UpdatePallet,
                                palletId: selectedPalletId,
                            })
                        )
                    }
                    style={{ margin: '5px 23px', padding: '6px 14px 6px 9px' }}
                >
                    <ModeEditOutlineOutlinedIcon style={{ marginRight: '4px' }} /> Update Pallet
                </Button>
            )}
            {selectedPalletId && (
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() =>
                        dispatch(
                            openModal({
                                modal: ActiveModal.SplitPallet,
                                palletId: selectedPalletId,
                            })
                        )
                    }
                    style={{ margin: '5px 23px', padding: '6px 14px 6px 9px' }}
                >
                    <ViewStreamOutlinedIcon style={{ marginRight: '4px' }} /> Split Pallet
                </Button>
            )}
            {selectedPalletId && (
                <Button
                    variant={'contained'}
                    color="error"
                    onClick={() =>
                        dispatch(
                            openModal({
                                modal: ActiveModal.DeletePallet,
                                palletId: selectedPalletId,
                            })
                        )
                    }
                    style={{ margin: '5px 23px', padding: '6px 14px 6px 9px' }}
                >
                    <DeleteOutlineIcon style={{ marginRight: '4px' }} /> Delete Pallet
                </Button>
            )}
        </ActionBarContainer>
    )
}
