import { Response } from '../util/with-response-formatter-interceptor'
import { ProjectDocument, ProjectDocumentType, ProjectDocumentVersion } from '../../models/models'
import backendConnection from '../util/backend-connection'

export const projectDocumentTypesRequest = (): Promise<Response<ProjectDocumentType[]>> =>
    backendConnection.request({
        url: `/projects/document-types`,
        method: 'GET',
    })

export const projectDocumentsRequest = (projectId: number): Promise<Response<ProjectDocument[]>> =>
    backendConnection.request({
        url: `/projects/${projectId}/documents`,
        method: 'GET',
    })

export const createDocumentTypeRequest = (name: string): Promise<Response<ProjectDocumentType>> =>
    backendConnection.request({
        url: `/projects/document-types`,
        method: 'POST',
        data: {
            name,
        },
    })

export const createDocumentRequest = (
    projectId: number | undefined,
    typeId: number | null
): Promise<Response<ProjectDocument>> =>
    backendConnection.request({
        url: `/projects/${projectId}/documents`,
        method: 'POST',
        data: {
            typeId,
        },
    })

export const createDocumentVersionRequest = (
    document: ProjectDocument,
    comment: string | null
): Promise<Response<ProjectDocumentVersion>> =>
    backendConnection.request({
        url: `/projects/documents/${document.id}/versions`,
        method: 'POST',
        data: {
            comment,
        },
    })

export const uploadDocumentVersionFile = (
    documentVersionId: number | undefined,
    file: File
): Promise<Response<ProjectDocument>> => {
    const formData = new FormData()
    formData.append('file', file)

    return backendConnection.request({
        url: `/projects/documents/versions/${documentVersionId}/upload`,
        method: 'POST',
        data: formData,
    })
}

export const downloadDocumentVersionFile = async (
    documentVersion: ProjectDocumentVersion,
    filename: string
) => {
    const response = await backendConnection.request({
        url: `/projects/documents/versions/${documentVersion.id}/download`,
        responseType: 'arraybuffer',
    })

    if (response.successful) {
        const objectURL = window.URL.createObjectURL(new Blob([response.data]))
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = objectURL
        a.download = filename
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(objectURL)
    }
}

export const updateDocumentVersionRequest = (
    documentVersionId: number,
    comment: string | null
): Promise<Response<ProjectDocument>> =>
    backendConnection.request({
        url: `/projects/documents/versions/${documentVersionId}`,
        method: 'PATCH',
        data: { comment },
    })

export const submitDocumentVersionRequest = (
    documentVersionId: number
): Promise<Response<ProjectDocument>> =>
    backendConnection.request({
        url: `/projects/documents/versions/${documentVersionId}/submit`,
        method: 'POST',
    })

export const archiveDocumentVersionRequest = (
    documentVersionId: number
): Promise<Response<ProjectDocument>> =>
    backendConnection.request({
        url: `/projects/documents/versions/${documentVersionId}/archive`,
        method: 'POST',
    })
