import { createAsyncThunk, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import {
    Contact,
    CreditStatus,
    Customer,
    OpportunityStatus,
    Project,
    User,
    UserRole,
} from '../models/models'
import {
    createContactRequest,
    createOpportunityStatusRequest,
    createProjectRequest,
    creditStatusesRequest,
    getProjectByIdRequest,
    opportunityStatusesRequest,
    ProjectDTO,
    updateContactRequest,
    updateProjectRequest,
    importProjectByReferenceRequest,
    findProjectsRequest,
    deleteContactRequest,
} from '../api/projects/projects'
import { getUsersRequest } from '../api/users'
import _ from 'lodash'
import { RootState } from './store'
import { resetFormState, updateFormState, updateFormStateFromProject } from './project-info-slice'
import { toastFailure, toastSuccess } from '../util/toast'

export enum EntityModalMode {
    OpportunityStatus = 'OPPORTUNITY_STATUS',
    Contact = 'CONTACT',
    Customer = 'CUSTOMER',
}

export enum EntityModalAttribute {
    Name = 'name',
    HexColor = 'hexColor',
    Address = 'address',
    Email = 'email',
    PhoneNumber = 'phoneNumber',
    Type = 'type',
    Search = 'search',
}

interface EntityModalState {
    updatingId: number | null
    showValidation: boolean
    visible: boolean
    mode: EntityModalMode | null
    typeOptions: { [key: string]: string }
    title: string | null
    fields: EntityModalAttribute[]
    tableOptions: TableOptions | null
    selectedCustomer: Customer | null
    type: string | null
    name: string
    hexColor: string
    search: string
    address: string
    email: string
    phoneNumber: string
    saveProject: boolean
    extraCreationAttributes: { [key: string]: string }
}

export enum TableType {
    AssignCustomer = 'AssignCustomer',
}

interface TableOptions {
    type: TableType
    page: number
    amountPerPage: number
}

const initialEntityModalState: EntityModalState = {
    updatingId: null,
    showValidation: false,
    visible: false,
    mode: null,
    type: null,
    title: null,
    fields: [],
    typeOptions: {},
    selectedCustomer: null,
    tableOptions: null,
    name: '',
    hexColor: '',
    address: '',
    search: '',
    email: '',
    phoneNumber: '',
    saveProject: false,
    extraCreationAttributes: {},
}

export enum View {
    ProjectInfo = 'project-info',
    History = 'history',
    Contacts = 'contact-info',
    ProductInfo = 'product-info',
    DocumentControl = 'document-control',
    ReturnReworks = 'return-reworks',
    SerialLog = 'serial-log',
    CallOff = 'call-off',
    SalesOrders = 'sale-orders',
}

interface SingleProjectViewState {
    isLoading: boolean
    activeView: View | null
    projectBeingUpdated: Project | null
    projectMatchingOpportunityReference: Project | null
    opportunityStatuses: OpportunityStatus[]
    accountManagers: User[]
    applicationsEngineers: User[]
    projectManagers: User[]
    creditStatuses: CreditStatus[]
    customer: Customer | null
    clients: Contact[]
    consultants: Contact[]
    contractors: Contact[]
    entityModal: EntityModalState
}

const initialState: SingleProjectViewState = {
    isLoading: true,
    activeView: null,
    projectBeingUpdated: null,
    projectMatchingOpportunityReference: null,
    customer: null,
    opportunityStatuses: [],
    accountManagers: [],
    applicationsEngineers: [],
    projectManagers: [],
    creditStatuses: [],
    clients: [],
    consultants: [],
    contractors: [],
    entityModal: { ...initialEntityModalState },
}

export const fetchEntities = createAsyncThunk('singleProjectView/fetchEntities', async () => {
    const creditStatusesPromise = creditStatusesRequest()
    const opportunityStatusesPromise = opportunityStatusesRequest()
    const usersPromise = getUsersRequest()

    return Promise.all([creditStatusesPromise, opportunityStatusesPromise, usersPromise])
})

export const singleProjectViewSlice = createSlice({
    name: 'singleProjectView',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true
        },

        stopLoading(state) {
            state.isLoading = false
        },

        showEntityModalValidation(state) {
            state.entityModal.showValidation = true
        },

        addContact(state, action: PayloadAction<Contact>) {
            if (state.projectBeingUpdated) {
                state.projectBeingUpdated.contacts = [
                    ...state.projectBeingUpdated.contacts.filter((c) => c.id !== action.payload.id),
                    action.payload,
                ]
            }
        },

        removeContact(state, action: PayloadAction<number>) {
            if (state.projectBeingUpdated) {
                state.projectBeingUpdated.contacts = state.projectBeingUpdated.contacts.filter(
                    (c) => c.id !== action.payload
                )
            }
        },

        addOpportunityStatus(state, action) {
            state.opportunityStatuses.push(action.payload.opportunityStatus)
        },

        addCustomer(state, action: PayloadAction<Customer>) {
            state.customer = action.payload
        },

        setActiveView(state, action: PayloadAction<View>) {
            state.activeView = action.payload
        },

        showCreateProject(state) {
            state.projectBeingUpdated = null
        },

        setProjectBeingUpdated(state, action: PayloadAction<Project>) {
            state.projectBeingUpdated = action.payload
        },

        setProjectBeingUpdatedIsPinned(state, action: PayloadAction<boolean>) {
            if (state.projectBeingUpdated) {
                state.projectBeingUpdated.isPinned = action.payload
            }
        },

        setProjectMatchingOpportunityReference(state, action) {
            state.projectMatchingOpportunityReference = action.payload
        },

        showCreateOpportunityStatusModal(state) {
            state.entityModal.visible = true
            state.entityModal.type = null
            state.entityModal.mode = EntityModalMode.OpportunityStatus
            state.entityModal.title = 'Create opportunity status'
            state.entityModal.hexColor = '#000000'
            state.entityModal.saveProject = false
            state.entityModal.extraCreationAttributes = {}
            state.entityModal.fields = [EntityModalAttribute.Name, EntityModalAttribute.HexColor]
        },

        showCustomerModal(state) {
            state.entityModal.visible = true
            state.entityModal.mode = EntityModalMode.Customer
            state.entityModal.title = 'Assign Customer'
            state.entityModal.saveProject = false
            state.entityModal.selectedCustomer = null
            state.entityModal.tableOptions = {
                amountPerPage: 7,
                page: 0,
                type: TableType.AssignCustomer,
            }
            state.entityModal.fields = [EntityModalAttribute.Search]
        },

        showCreateContactModal(state) {
            state.entityModal.visible = true
            state.entityModal.type = null
            state.entityModal.mode = EntityModalMode.Contact
            state.entityModal.title = 'Create contact'
            state.entityModal.saveProject = true
            state.entityModal.extraCreationAttributes = {}
            state.entityModal.fields = [
                EntityModalAttribute.Name,
                EntityModalAttribute.Email,
                EntityModalAttribute.PhoneNumber,
                EntityModalAttribute.Address,
            ]
        },

        showUpdateContactModal(state, action: PayloadAction<Contact>) {
            state.entityModal.visible = true
            state.entityModal.mode = EntityModalMode.Contact
            state.entityModal.title = 'Update contact'
            state.entityModal.saveProject = false
            state.entityModal.extraCreationAttributes = {}
            state.entityModal.fields = [
                EntityModalAttribute.Name,
                EntityModalAttribute.Email,
                EntityModalAttribute.PhoneNumber,
                EntityModalAttribute.Address,
            ]

            state.entityModal.updatingId = action.payload.id
            state.entityModal.name = action.payload.name
            state.entityModal.address = action.payload.address
            state.entityModal.email = action.payload.email
            state.entityModal.phoneNumber = action.payload.phoneNumber
        },

        closeEntityModal(state) {
            state.entityModal = { ...initialEntityModalState }
        },

        updateEntityModalState(state, action) {
            Object.entries(action.payload).forEach(([key, value]) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                state.entityModal[key] = value
            })
        },
    },

    extraReducers: (builder) => {
        builder.addCase(fetchEntities.fulfilled, (state, action) => {
            const [creditStatusesResponse, opportunityStatusesResponse, usersResponse] =
                action.payload

            if (creditStatusesResponse.successful) {
                state.creditStatuses = creditStatusesResponse.data
            }

            if (opportunityStatusesResponse.successful) {
                state.opportunityStatuses = opportunityStatusesResponse.data
            }

            if (usersResponse.successful) {
                const usersGroupedByRole = _.groupBy(
                    usersResponse.data.entities,
                    (user: User) => user.role
                )
                state.projectManagers =
                    [
                        ...(usersGroupedByRole[UserRole.ProjectManager] || []),
                        ...(usersGroupedByRole[UserRole.Scheduler] || []),
                    ] || []
                state.accountManagers = usersGroupedByRole[UserRole.AccountManager] || []
                state.applicationsEngineers = usersGroupedByRole[UserRole.ApplicationEngineer] || []
            }

            state.isLoading = false
        })
    },
})

export const importProject = (opportunityReference: string) => async (dispatch: Dispatch) => {
    dispatch(resetFormState())
    const projectsResponse = await importProjectByReferenceRequest(opportunityReference)

    if (!projectsResponse.successful) {
        toastFailure(projectsResponse)
        return projectsResponse
    }

    const project = projectsResponse.data
    dispatch(setProjectBeingUpdated(project))
    dispatch(updateFormStateFromProject(project))
}

export const checkOpportunityReference =
    (opportunityReference: string) => async (dispatch: Dispatch) => {
        const response = await findProjectsRequest(opportunityReference)
        dispatch(setProjectMatchingOpportunityReference(response.successful ? response.data : null))

        if (response.is5xx) {
            toastFailure(
                'An error occurred while searching for a project matching opportunity reference.'
            )
        }
    }

export const startUpdating = (projectId: number) => async (dispatch: Dispatch) => {
    dispatch(resetFormState())
    const projectsResponse = await getProjectByIdRequest(projectId)

    if (!projectsResponse.successful) {
        toastFailure('Could not fetch project to update.')
        return
    }

    const project = projectsResponse.data
    dispatch(setProjectBeingUpdated(project))
    dispatch(updateFormStateFromProject(project))
}

export const deleteContact = (contact: Contact) => async (dispatch: Dispatch) => {
    const contactDeleteResponse = await deleteContactRequest(contact.id)

    if (!contactDeleteResponse.successful) {
        toastFailure('Could not delete contact.')
        return
    }

    dispatch(removeContact(contact.id))
    toastSuccess('The contact was successfully deleted.')
}

export const startCreating = () => async (dispatch: Dispatch) => {
    await Promise.all([
        dispatch(setActiveView(View.ProjectInfo)),
        dispatch(resetFormState()),
        dispatch(showCreateProject()),
    ])
}

export const save = () => async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()
    const { projectName } = state.projectInfo.formState
    if (!projectName) {
        return
    }
    const projectBeingUpdated = getState().singleProjectView.projectBeingUpdated
    const projectDTO: ProjectDTO = {
        ...state.projectInfo.formState,
        customerId: state.projectInfo.formState?.customer?.id,
    }

    await dispatch(startLoading())

    const response = projectBeingUpdated
        ? await updateProjectRequest(projectBeingUpdated.id, projectDTO)
        : await createProjectRequest(projectDTO)

    if (response.successful) {
        const project: Project = response.data
        dispatch(setProjectBeingUpdated(project))
        dispatch(updateFormStateFromProject(project))

        toastSuccess(
            projectBeingUpdated
                ? 'The project was successfully updated.'
                : 'The project was successfully created.'
        )
    } else {
        toastFailure(
            projectBeingUpdated
                ? 'Something went wrong while updating the project.'
                : 'Something went wrong while creating the project.'
        )
    }

    await dispatch(stopLoading())
}

export const saveEntityModal = () => async (dispatch: Dispatch, getState: () => RootState) => {
    const entityModalState = getState().singleProjectView.entityModal
    const projectBeingUpdated = getState().singleProjectView.projectBeingUpdated
    const mode = entityModalState.mode
    const attributes = getEntityModalAttributes(entityModalState)

    await dispatch(startLoading())

    if (mode === EntityModalMode.OpportunityStatus) {
        const response = await createOpportunityStatusRequest(attributes)
        if (response.successful) {
            const opportunityStatus: OpportunityStatus = response.data
            await dispatch(addOpportunityStatus({ opportunityStatus }))
            dispatch(updateFormState({ opportunityStatusId: opportunityStatus.id }))
            dispatch(closeEntityModal())
            toastSuccess('The opportunity status was successfully created.')
        } else {
            toastFailure('The opportunity status could not be created.')
        }
    }

    if (mode === EntityModalMode.Customer) {
        if (!entityModalState.selectedCustomer) {
            toastFailure('Something went wrong')
            return
        }

        dispatch(updateFormState({ customer: entityModalState.selectedCustomer }))
        dispatch(closeEntityModal())
    }

    if (mode === EntityModalMode.Contact) {
        const response = entityModalState.updatingId
            ? await updateContactRequest(entityModalState.updatingId, attributes)
            : await createContactRequest(projectBeingUpdated!.id, attributes)

        if (!response.successful) {
            toastFailure(
                entityModalState.updatingId
                    ? 'The contact could not be updated.'
                    : 'The contact could not be created.'
            )
            return
        }

        await dispatch(addContact(response.data))

        dispatch(closeEntityModal())

        if (!entityModalState.saveProject || !projectBeingUpdated) {
            toastSuccess(
                entityModalState.updatingId
                    ? 'The contact was successfully updated.'
                    : 'The contact was successfully created.'
            )
            await dispatch(stopLoading())
            return
        }
    }

    await dispatch(stopLoading())
}

const getEntityModalAttributes = (modalState: EntityModalState) => {
    const attributes: { [key: string]: string } = { ...modalState.extraCreationAttributes }

    modalState.fields.forEach((field) => {
        const value = modalState[field]
        if (value !== '') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            attributes[field.toString()] = modalState[field]
        }
    })

    return attributes
}

export const invalidEntityModalModalFieldsView = (state: RootState) => {
    const fields = state.singleProjectView.entityModal.fields
    const invalidFields = new Set<EntityModalAttribute>()

    if (fields.includes(EntityModalAttribute.Name)) {
        const value = state.singleProjectView.entityModal[EntityModalAttribute.Name]
        if (!value || value.trim().length < 1) {
            invalidFields.add(EntityModalAttribute.Name)
        }
    }

    if (fields.includes(EntityModalAttribute.Email)) {
        const value = state.singleProjectView.entityModal[EntityModalAttribute.Email]
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (value && !re.test(value.toLowerCase())) {
            invalidFields.add(EntityModalAttribute.Email)
        }
    }

    if (fields.includes(EntityModalAttribute.Type)) {
        const value = state.singleProjectView.entityModal[EntityModalAttribute.Type]
        if (!value) {
            invalidFields.add(EntityModalAttribute.Type)
        }
    }

    return invalidFields
}

export const {
    setActiveView,
    addContact,
    removeContact,
    addOpportunityStatus,
    showCreateProject,
    setProjectBeingUpdated,
    setProjectBeingUpdatedIsPinned,
    setProjectMatchingOpportunityReference,
    closeEntityModal,
    showEntityModalValidation,
    updateEntityModalState,
    showCreateOpportunityStatusModal,
    showCreateContactModal,
    startLoading,
    stopLoading,
    showUpdateContactModal,
    showCustomerModal,
} = singleProjectViewSlice.actions

export default singleProjectViewSlice.reducer
