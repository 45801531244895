import {
    AssemblyStatusBackgrounds,
    getAssemblyStatus,
    setActiveWorksheetView,
} from '../../../store/assembly-worksheets-slice'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import { ReactNode, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { Assembly, AssemblyActivity, Schedule, UserRole } from '../../../models/models'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import {
    getAssembliesCompletedMinutes,
    getAssembliesMinutes,
} from '../../../util/production-schedule'
import { camelCaseToWords, uniqueBy } from '../../../util/util'
import { ActiveModal, openModal } from '../../../store/products-slice'

interface Props {
    schedule?: Schedule
    assembly: Assembly
    onClick: null | (() => void)
}

function AttributeName({ text }: { text: string }) {
    return (
        <Typography
            sx={{
                fontSize: 14,
                color: '#26262690',
            }}
        >
            {text}
        </Typography>
    )
}

function AttributeValue({ text }: { text: ReactNode }) {
    return (
        <Typography
            sx={{
                fontSize: 14,
            }}
        >
            {text}
        </Typography>
    )
}

function SplitRow({ left, right }: { left: ReactNode; right: ReactNode }) {
    return (
        <Box
            sx={{
                borderTop: '1px solid #ddd',
                display: 'flex',
            }}
        >
            <Box
                sx={{
                    width: '130px',
                    padding: '20px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {left}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {right}
            </Box>
        </Box>
    )
}

function Assemblers({ activities }: { activities: AssemblyActivity[] }) {
    return (
        <>
            {uniqueBy(activities, (a1, a2) => a1.userId === a2.userId).map((activity) => (
                <Box
                    key={activity.id}
                    sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                        }}
                    >
                        {activity?.user
                            ? `${activity?.user.firstName} ${activity?.user.lastName}`
                            : 'No assembler'}{' '}
                    </Typography>
                </Box>
            ))}
        </>
    )
}

function Progress({
    assembly,
    activities,
}: {
    assembly: Assembly
    activities: AssemblyActivity[]
}) {
    const isBehindSchedule =
        moment(assembly.dateToBeCompletedBy) < moment().startOf('day') && !assembly.startedAt

    return (
        <Box
            sx={{
                borderTop: '1px solid #ddd',
                background: isBehindSchedule
                    ? 'red'
                    : AssemblyStatusBackgrounds[getAssemblyStatus(assembly)],
                color: '#fff',
                padding: '15px 20px',
            }}
        >
            <Typography sx={{ fontWeight: 'bold' }}>{`${camelCaseToWords(
                getAssemblyStatus(assembly)
            )} ${isBehindSchedule ? '- Delayed!' : ''}`}</Typography>
            {activities.length > 0 && <Assemblers activities={activities} />}
        </Box>
    )
}

function AssemblyWorksheetCard(props: Props) {
    const dispatch = useAppDispatch()
    const authenticatedUser = useAppSelector((state) => state.authentication.user)
    const { assembly } = props
    const projectManagers = assembly.callOff.project.projectManagers
    const project = assembly.callOff.project
    const builtItem = assembly.builtItemCallOff.sopBuiltItemOrderline.builtItem

    const minutes = getAssembliesMinutes([assembly])
    const completedMinutes = getAssembliesCompletedMinutes([assembly])

    const unavailableAssemblers = useMemo(() => {
        if (!props.schedule) {
            return []
        }

        const absentUsers = props.schedule.absentDays
            .filter((a) => {
                return assembly.activities.find((split) => {
                    return split?.userId === a.daysOff.userId
                })
            })
            .map((absentDay) => {
                return absentDay.daysOff.user
            })
            .filter((u) => !!u)

        return absentUsers
    }, [props.schedule])

    if (!authenticatedUser) {
        return null
    }

    const authenticatedUserRole = authenticatedUser.role
    const canOpen = [
        UserRole.Assembler,
        UserRole.SystemAdmin,
        UserRole.Scheduler,
        UserRole.SavAdmin,
        UserRole.ProjectManager,
    ].includes(authenticatedUserRole)
    const canReAssign =
        [
            UserRole.ProjectManager,
            UserRole.SystemAdmin,
            UserRole.SavAdmin,
            UserRole.Scheduler,
        ].includes(authenticatedUserRole) && assembly.activities.length > 0

    const onClick =
        props.onClick ||
        (canOpen
            ? () => {
                  dispatch(setActiveWorksheetView(assembly))
              }
            : null)

    return (
        <Box
            onClick={() => canOpen && onClick && onClick()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '370px',
                background: '#fff',
                cursor: onClick ? 'pointer' : 'initial',
            }}
        >
            <Typography
                sx={{
                    fontSize: 16,
                    padding: '20px',
                    fontWeight: 500,
                }}
            >
                {project.name}
            </Typography>
            {assembly.isOnHold && (
                <Box
                    sx={{
                        borderTop: '1px solid #ddd',
                        background: 'orange',
                        color: '#fff',
                        padding: '15px 20px',
                    }}
                >
                    <Typography sx={{ fontWeight: 'bold' }}>On hold</Typography>
                </Box>
            )}
            {unavailableAssemblers.length > 0 && !assembly.isOnHold && (
                <Tooltip title={'These people have absences for the completion date'}>
                    <Box
                        sx={{
                            borderTop: '1px solid #ddd',
                            background: '#DC582A',
                            color: '#fff',
                            padding: '15px 20px',
                        }}
                    >
                        <ExclamationCircleOutlined />
                        {unavailableAssemblers.map((a) => {
                            return ` ${a?.firstName} ${a?.lastName}`
                        })}
                    </Box>
                </Tooltip>
            )}
            <Progress assembly={assembly} activities={assembly.activities} />
            <SplitRow
                left={<AttributeName text={'Product'} />}
                right={
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            dispatch(
                                openModal({
                                    activeModal: ActiveModal.BomInspect,
                                    modalEntityId: builtItem.id,
                                })
                            )
                        }}
                    >
                        {builtItem.bomCode}
                    </Button>
                }
            />
            <SplitRow
                left={<AttributeName text={'Project manager(s)'} />}
                right={
                    <AttributeValue
                        text={projectManagers
                            .map((user) => `${user.firstName} ${user.lastName}`)
                            .join(', ')}
                    />
                }
            />
            <SplitRow
                left={<AttributeName text={'Quantity'} />}
                right={<AttributeValue text={assembly.amount} />}
            />
            <SplitRow
                left={<AttributeName text={'Minutes completed'} />}
                right={<AttributeValue text={`${completedMinutes}/${minutes}`} />}
            />
            <SplitRow
                left={<AttributeName text={'Assembly Comment'} />}
                right={<AttributeValue text={assembly?.assemblyComment} />}
            />
            <SplitRow
                left={<AttributeName text={'Assembly id'} />}
                right={<AttributeValue text={assembly.id} />}
            />
            <SplitRow
                left={<AttributeName text={'Started at'} />}
                right={
                    <AttributeValue
                        text={
                            assembly.startedAt
                                ? moment(assembly.startedAt).format('DD-MM-YYYY HH:mm')
                                : 'Not started'
                        }
                    />
                }
            />
            <SplitRow
                left={<AttributeName text={'Date to be completed'} />}
                right={
                    <AttributeValue
                        text={moment(assembly.dateToBeCompletedBy).format('DD-MM-YYYY')}
                    />
                }
            />
            <SplitRow
                left={<AttributeName text={'On hold'} />}
                right={<AttributeValue text={assembly.isOnHold} />}
            />
        </Box>
    )
}

export default AssemblyWorksheetCard
