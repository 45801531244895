import { Task, TaskDepartment } from '../models/models'
import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'

export enum TaskRelation {
    CreatedBy = 'CreatedBy',
    AssignedTo = 'AssignedTo',
    Type = 'Type',
    Project = 'Project',
}

export interface GetTasksDto {
    relations?: TaskRelation[]
    departments?: TaskDepartment[]
    isAssigned?: boolean
    ids?: number[]
    warehouseId?: number
    withDeleted?: boolean
    isCompleted?: boolean
    dateToBeCompletedBydDateRangeBegin?: Date
    dateToBeCompletedByDateRangeEnd?: Date
    completedDateRangeBegin?: Date
    completedDateRangeEnd?: Date
    projectId?: number
    skip?: number
    limit?: number
    orderDirection?: 'ASC' | 'DESC'
    orderField?: string
}

export const getTaskByIdRequest = (id: number, dto: GetTasksDto): Promise<Response<Task>> =>
    backendConnection.request({
        url: `/tasks/${id}`,
        params: dto,
        method: 'GET',
    })

export interface UpdateTaskDto {
    title?: string
    description?: string
    dateToBeCompleted?: Date
    department?: TaskDepartment
    minutes?: number
    link?: string
    assignedUserId?: number | null
    completedAt?: Date | null
    warehouseId?: number
    projectId?: number | null
}

export const updateTaskRequest = (id: number, dto: UpdateTaskDto): Promise<Response<Task>> =>
    backendConnection.request({
        url: `/tasks/${id}`,
        data: dto,
        method: 'PATCH',
    })

export const getTasksRequest = (
    dto: GetTasksDto
): Promise<Response<{ entities: Task[]; count: number }>> =>
    backendConnection.request({
        url: `/tasks`,
        params: dto,
        method: 'GET',
    })

export const deleteTasksRequest = (taskId: number): Promise<Response<Task>> =>
    backendConnection.request({
        url: `/tasks/${taskId}`,
        method: 'DELETE',
    })

export const completeTasksRequest = (taskId: number): Promise<Response<Task>> =>
    backendConnection.request({
        url: `/tasks/${taskId}/complete`,
        method: 'POST',
    })

export interface CreateTaskDto {
    typeId: number
    description: string
    dateToBeCompleted: Date
    department: TaskDepartment
    link: string
    minutes: number
    warehouseId: number
    assignedUserId?: number
    completedAt?: Date
    projectId?: number
}

export const createTaskRequest = (task: CreateTaskDto): Promise<Response<Task>> =>
    backendConnection.request({
        url: `/tasks`,
        method: 'POST',
        data: task,
    })
