import { Box } from '@mui/material'
import { Button } from 'antd'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getGeneralAssembliesRequest, GeneralAssemblyRelation } from '../../api/general-assemblies'
import { useAppDispatch } from '../../store/hooks'
import { RootState } from '../../store/store'
import { ActiveModal, openModal } from '../../store/general-assemblies-slice'
import GeneralAssembliesTable, {
    GeneralAssembliesTableColumns,
} from '../tables/GeneralAssembliesTable'
import FilterBar from './FilterBar'
import { GeneralAssembly } from '../../models/models'

export default function GeneralAssemblies() {
    const {
        filterWarehouseId,
        filterDateToBeCompletedByDateRangeEnd,
        filterDateToBeCompletedBydDateRangeBegin,
        filterCompletedDateRangeBegin,
        filterCompletedDateRangeEnd,
        updateTableToken,
    } = useSelector((state: RootState) => state.generalAssemblies)

    const dispatch = useAppDispatch()

    return (
        <Box>
            <FilterBar />
            <GeneralAssembliesTable
                pagination={true}
                expandable={true}
                columns={[
                    GeneralAssembliesTableColumns.Id,
                    GeneralAssembliesTableColumns.Description,
                    GeneralAssembliesTableColumns.Amount,
                    GeneralAssembliesTableColumns.DateToBeCompletedBy,
                    GeneralAssembliesTableColumns.CompletedAt,
                ]}
                actionComponents={[
                    (generalAssembly: GeneralAssembly) => (
                        <Button
                            style={{ marginRight: '10px' }}
                            type="default"
                            onClick={() => {
                                dispatch(
                                    openModal({
                                        activeModal: ActiveModal.GeneralAssemblyInspect,
                                        modalEntityId: generalAssembly.id,
                                    })
                                )
                            }}
                        >
                            Inspect
                        </Button>
                    ),
                    (generalAssembly: GeneralAssembly) => {
                        if (generalAssembly.completedAt) {
                            return <></>
                        }
                        return (
                            <Button
                                style={{ marginRight: '10px' }}
                                type="default"
                                onClick={() => {
                                    dispatch(
                                        openModal({
                                            activeModal: ActiveModal.GeneralAssemblyUpdate,
                                            modalEntityId: generalAssembly.id,
                                        })
                                    )
                                }}
                            >
                                Update
                            </Button>
                        )
                    },
                    (generalAssembly: GeneralAssembly) => {
                        if (generalAssembly.completedAt) {
                            return <></>
                        }
                        return (
                            <Button
                                style={{ marginRight: '10px' }}
                                type="default"
                                onClick={() => {
                                    dispatch(
                                        openModal({
                                            activeModal: ActiveModal.GeneralAssemblyDelete,
                                            modalEntityId: generalAssembly.id,
                                        })
                                    )
                                }}
                            >
                                Delete
                            </Button>
                        )
                    },
                ]}
                dependencies={[
                    filterWarehouseId,
                    filterDateToBeCompletedBydDateRangeBegin,
                    filterDateToBeCompletedByDateRangeEnd,
                    filterCompletedDateRangeBegin,
                    filterCompletedDateRangeEnd,
                    updateTableToken,
                ]}
                request={(pagination) =>
                    getGeneralAssembliesRequest({
                        dateToBeCompletedByDateRangeEnd:
                            typeof filterDateToBeCompletedByDateRangeEnd === 'number'
                                ? moment.unix(filterDateToBeCompletedByDateRangeEnd).toDate()
                                : undefined,
                        dateToBeCompletedBydDateRangeBegin:
                            typeof filterDateToBeCompletedBydDateRangeBegin === 'number'
                                ? moment.unix(filterDateToBeCompletedBydDateRangeBegin).toDate()
                                : undefined,
                        completedDateRangeBegin:
                            typeof filterCompletedDateRangeBegin === 'number'
                                ? moment.unix(filterCompletedDateRangeBegin).toDate()
                                : undefined,
                        completedDateRangeEnd:
                            typeof filterCompletedDateRangeEnd === 'number'
                                ? moment.unix(filterCompletedDateRangeEnd).toDate()
                                : undefined,
                        warehouseId: filterWarehouseId,
                        joins: [
                            GeneralAssemblyRelation.CreatedBy,
                            GeneralAssemblyRelation.Lines,
                            GeneralAssemblyRelation.Bom,
                            GeneralAssemblyRelation.LinesAssembledBy,
                        ],
                        skip: pagination?.skip,
                        limit: pagination?.limit,
                    })
                }
            />
        </Box>
    )
}
