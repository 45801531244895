import moment, { Moment } from 'moment'
import { Box } from '@mui/system'
import { Schedule, Warehouse } from '../../../../../../../models/models'
import {
    getScheduleAvailableMinutes,
    getTotalMinutes,
} from '../../../../../../../util/production-schedule'

interface DateCellProps {
    date: Moment
    schedule: Schedule | undefined
    warehouse: Warehouse
    selectedDate: Moment | undefined
}

enum Type {
    Past = 'Past',
    Full = 'Full',
    Space = 'Space',
    Empty = 'Empty',
    Weekend = 'Weekend',
    Error = 'Error',
}
enum Color {
    Grey = '#D9D9D9',
    White = '#FFF',
    Green = '#DCF7E9',
    Yellow = '#FEF8E6',
    Red = '#FFE1E2',
}

const getColor = (type: Type): Color => {
    if (type === Type.Error) {
        return Color.Red
    } else if (type === Type.Past) {
        return Color.Grey
    } else if (type === Type.Weekend) {
        return Color.White
    } else if (type === Type.Full) {
        return Color.Red
    } else if (type === Type.Space) {
        return Color.Yellow
    } else if (type === Type.Empty) {
        return Color.Green
    } else {
        return Color.Red
    }
}

const getCursor = (type: Type, date: Moment) => {
    if (
        type === Type.Empty ||
        type === Type.Space ||
        type === Type.Full ||
        date.isBefore(moment().startOf('month')) ||
        date.isAfter(moment().endOf('month'))
    ) {
        return 'pointer'
    } else {
        return 'default'
    }
}

export default function DateCell({ date, schedule, warehouse, selectedDate }: DateCellProps) {
    const getType = () => {
        if (date.day() === 6 || date.day() === 0) {
            return Type.Weekend
        } else if (date.isBefore(moment(), 'day')) {
            return Type.Past
        } else if (schedule !== undefined) {
            const usedPercentage =
                (getScheduleAvailableMinutes(schedule) /
                    getTotalMinutes(moment(schedule.day), schedule.assemblers)) *
                100
            if (usedPercentage > 70) {
                return Type.Empty
            } else if (usedPercentage > 20) {
                return Type.Space
            } else {
                return Type.Full
            }
        } else {
            return Type.Past
        }
    }

    return (
        <Box
            style={{
                backgroundColor: getColor(getType()),
                height: '40px',
                margin: '5px',
                display: 'flex',
                outline:
                    schedule?.day && moment(schedule?.day).isSame(selectedDate, 'day')
                        ? '2px black solid'
                        : 'none',
                borderRadius: '2pt',
                flexDirection: 'column',
                boxShadow: '1px 1px 1px #ccc',
                cursor: getCursor(getType(), date),
            }}
        >
            <Box style={{ margin: '5px' }}>
                <p
                    style={{
                        fontFamily: 'roboto',
                        lineHeight: 2,
                        fontSize: 17,
                        fontWeight: 400,
                        textAlign: 'right',
                    }}
                >
                    {date.date()}
                </p>
            </Box>
        </Box>
    )
}
