import { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Input, Modal, Select, Button } from 'antd'
import moment, { Moment } from 'moment'
import { GeneralAssembly, User, UserRole, WarehouseId } from '../../../models/models'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { styled } from '@mui/material'
import '../../tables/table-styles.css'
import {
    CreateGeneralAssemblyDto,
    createGeneralAssemblyRequest,
} from '../../../api/general-assemblies'
import { OrderedListOutlined } from '@ant-design/icons'
import { getUsersRequest } from '../../../api/users'
import DatePicker from '../../ui/calendar/DatePicker'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import { SearchInput } from '../../ui/SearchInput'
import { getBomsRequest } from '../../../api/bom'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 47%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

interface CreateGeneralAssemblyModalProps {
    warehouseId: number
    closeModal: (task?: GeneralAssembly) => void
}

export default function CreateGeneralAssemblyModal({
    warehouseId,
    closeModal,
}: CreateGeneralAssemblyModalProps) {
    const [description, setDescription] = useState<string>('')
    const [dateToBeCompleted, setDateToBeCompleted] = useState<Moment | null>(null)
    const [selectedBuiltItemId, setSelectedBuiltItemId] = useState<number>()
    const [amount, setAmount] = useState<number>()

    const disableSubmit =
        typeof description !== 'string' || Number(amount) <= 0 || !warehouseId || !dateToBeCompleted

    const onOk = async () => {
        if (
            typeof amount !== 'number' ||
            amount < 0 ||
            !dateToBeCompleted ||
            !selectedBuiltItemId
        ) {
            return
        }

        const response = await createGeneralAssemblyRequest({
            amount,
            builtItemId: selectedBuiltItemId,
            dateToBeCompleted: dateToBeCompleted.toDate(),
            description,
            warehouseId,
        })

        if (!response.successful) {
            toastFailure(response.message)
            return
        }

        toastSuccess(`Created general assembly ${response.data.id}`)
        closeModal(response.data)
    }

    return (
        <Modal open={true} footer={null} onCancel={() => closeModal()}>
            <Box>
                <Header>
                    <OrderedListOutlined style={{ fontSize: '20px' }} />
                    <Title>Create General Assembly</Title>
                </Header>
                <Divider />
                <>
                    <div>
                        <Label>Description</Label>
                        <Input.TextArea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{ minHeight: 200 }}
                        />
                    </div>
                    <InputRow>
                        <RowElement>
                            <Label>Date to be completed</Label>

                            <DatePicker
                                style={{ width: '100%' }}
                                value={dateToBeCompleted}
                                onChange={setDateToBeCompleted}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Warehouse</Label>
                            <Input
                                style={{ width: '100%' }}
                                readOnly={true}
                                value={
                                    warehouseId
                                        ? {
                                              [WarehouseId.Monument]: 'Monument',
                                              [WarehouseId.Scandia]: 'Scandia',
                                          }[warehouseId]
                                        : 'None'
                                }
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow>
                        <RowElement>
                            <Label>Built Item</Label>
                            <SearchInput
                                style={{ width: '100%' }}
                                placeholder="SAV-JUNCTION"
                                selectedValue={selectedBuiltItemId}
                                initialSearchValue="SAV-JUNCTION"
                                setSelectedValue={setSelectedBuiltItemId}
                                request={(search: string) =>
                                    getBomsRequest({
                                        bomCode: search,
                                        limit: 100,
                                    }).then((r) =>
                                        r.successful
                                            ? r.data.entities.map((b) => ({
                                                  label: b.bomCode,
                                                  value: b.id,
                                              }))
                                            : [{ label: 'None', value: 2 }]
                                    )
                                }
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Amount</Label>
                            <Input
                                style={{ width: '100%' }}
                                type="number"
                                value={amount}
                                onWheel={(e) => e.preventDefault()}
                                onChange={(e) => setAmount(Number(e.target.value))}
                            />
                        </RowElement>
                    </InputRow>
                </>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                    <Button type={'primary'} disabled={disableSubmit} onClick={onOk}>
                        Create
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}
