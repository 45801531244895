import { NavigationOption } from './navigationOptions'
import ListItemButton from '@mui/material/ListItemButton'
import { Link } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText, List } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useState } from 'react'

interface DrawerItemProps {
    option: NavigationOption
    isMenuOpen: boolean
}

export const DrawerItem = ({ option, isMenuOpen }: DrawerItemProps) => {
    const [open, setOpen] = useState<boolean>(false)

    const handleClick = (): void => {
        if (option.options) {
            setOpen(!open)
            return
        }
        option.onClick?.()
    }

    return (
        <>
            {option.link ? (
                <ListItemButton key={option.text} component={Link} to={option.link}>
                    <ListItemIcon>{option.icon}</ListItemIcon>
                    <ListItemText primary={option.text} />
                </ListItemButton>
            ) : (
                <ListItem button key={option.text} onClick={handleClick}>
                    <ListItemIcon>{option.icon}</ListItemIcon>
                    <ListItemText primary={option.text} />
                    {option.options ? open ? <ExpandLess /> : <ExpandMore /> : null}
                </ListItem>
            )}
            {option.options && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {option.options.map((item) =>
                            item.link ? (
                                <ListItemButton
                                    key={item.text}
                                    component={Link}
                                    to={item.link}
                                    sx={{ pl: isMenuOpen ? 4 : 2, transition: 'all 0.3s' }}
                                >
                                    <ListItemIcon style={{ fontSize: '24px' }}>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.text} />
                                </ListItemButton>
                            ) : (
                                <ListItem
                                    button
                                    key={item.text}
                                    sx={{ pl: isMenuOpen ? 4 : 2, transition: 'all 0.3s' }}
                                    onClick={item.onClick}
                                >
                                    <ListItemIcon style={{ fontSize: '24px' }}>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.text} />
                                </ListItem>
                            )
                        )}
                    </List>
                </Collapse>
            )}
        </>
    )
}
