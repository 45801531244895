import { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Modal, Button, Typography } from 'antd'
import { TaskType } from '../../../models/models'
import { toastFailure } from '../../../util/toast'
import { CircularProgress, styled } from '@mui/material'
import '../../tables/table-styles.css'
import {
    deleteTaskTypesRequest,
    getTaskTypeByIdRequest,
    TaskTypeRelation,
} from '../../../api/task-types'
import { OrderedListOutlined } from '@ant-design/icons'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

interface UpdateTaskTypeModalProps {
    taskTypeId: number
    closeModal: (taskType?: TaskType) => void
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

export default function DeleteTaskTypeModal({ taskTypeId, closeModal }: UpdateTaskTypeModalProps) {
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const [taskType, setTaskType] = useState<TaskType>()

    useEffect(() => {
        getTaskType()
    }, [])

    const getTaskType = async () => {
        const response = await getTaskTypeByIdRequest(taskTypeId, {
            relations: [TaskTypeRelation.CreatedBy],
        })

        if (response.successful) {
            const taskType = response.data
            setTaskType(taskType)
            setModalState(ModalState.Success)
        } else {
            if (response.status === 404) {
                setModalState(ModalState.NotFound)
            } else {
                setModalState(ModalState.Error)
            }
            toastFailure(response.message)
        }
    }

    const deleteTaskType = async () => {
        const response = await deleteTaskTypesRequest(taskTypeId)
        if (response.successful) {
            closeModal(response.data)
            return
        }
        toastFailure(`Something went wrong deleting task template ${taskTypeId}`)
    }

    return (
        <Modal visible={true} footer={null} onCancel={() => closeModal()}>
            {modalState === ModalState.Loading && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        margin: '20px',
                        width: '100%',
                        height: '500px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                </Box>
            )}
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.NotFound && (
                <Box>Could not find the taskType with the id of {taskTypeId}</Box>
            )}
            {modalState === ModalState.Success && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Delete taskType {taskTypeId}</Title>
                    </Header>
                    <Divider />

                    <Typography.Text type="danger">
                        Are you sure you want to delete this taskType? Its time allocation might be
                        subtracted from the production-schedule.
                    </Typography.Text>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                        <Button type="primary" danger onClick={deleteTaskType}>
                            Delete
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    )
}
