import { Table, TableColumnType } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { Response } from '../../api/util/with-response-formatter-interceptor'
import { DocumentStatus, SalesOrder, WarehouseId } from '../../models/models'
import { toastFailure } from '../../util/toast'
import useDebounce from '../project-master/single-project-view/hooks'
import moment from 'moment'
import { ExpandableConfig } from 'antd/lib/table/interface'

interface Props {
    pagination?: boolean
    //antd ColumnType<T> does not have title property
    columns?: Array<TableColumnType<SalesOrder>>
    expandable?: ExpandableConfig<SalesOrder>
    actionComponents?: Array<(salesOrder: SalesOrder) => JSX.Element>
    dependencies?: any
    request: (pagination?: {
        skip: number
        limit: number
    }) => Promise<Response<{ count: number; entities: SalesOrder[] }>>
}

export const SalesOrdersTableColumns: { [key in string]: TableColumnType<SalesOrder> } = {
    Id: {
        title: 'Id',
        key: 'id',
        render: (salesOrder: SalesOrder) => {
            return salesOrder.id
        },
    },
    DocumentNo: {
        title: 'Document Number',
        dataIndex: 'documentNo',
        key: 'documentNo',
    },
    Warehouse: {
        title: 'Warehouse',
        dataIndex: 'warehouseId',
        key: 'warehouse',
        render: (warehouseId: number) => {
            if (warehouseId === WarehouseId.Monument) {
                return 'Monument'
            } else if (warehouseId === WarehouseId.Scandia) {
                return 'Scandia'
            }
        },
    },
    PromisedDeliveryDate: {
        title: 'Promised delivery',
        key: 'promisedDeliveryDate',
        render: (salesOrder: SalesOrder) => {
            return moment(salesOrder.promisedDeliveryDate).format('DD/MM/YYYY')
        },
    },
    OrderlinesAmount: {
        title: 'Orderlines amount',
        key: 'stock item',
        render: (salesOrder: SalesOrder) => {
            return salesOrder.stockItemOrderlines.length + salesOrder.builtItemOrderlines.length
        },
    },
    Status: {
        title: 'Status',
        key: 'stock item',
        render: (salesOrder: SalesOrder) => {
            return DocumentStatus[salesOrder.documentStatus as keyof typeof DocumentStatus]
        },
    },
} as const

const DEFAULT_COLUMNS = [
    SalesOrdersTableColumns.Id,
    SalesOrdersTableColumns.DocumentNo,
    SalesOrdersTableColumns.Warehouse,
    SalesOrdersTableColumns.PromisedDeliveryDate,
    SalesOrdersTableColumns.OrderlinesAmount,
    SalesOrdersTableColumns.Status,
]

export const SalesOrdersTable = ({
    request,
    columns,
    pagination,
    actionComponents,
    dependencies,
    expandable,
}: Props) => {
    const [salesOrders, setSalesOrders] = useState<SalesOrder[]>([])
    const [salesOrdersAmount, setSalesOrdersAmount] = useState<number>(0)
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)

    const getSalesOrdersDeps = useDebounce(JSON.stringify(dependencies), 200)

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const tableColumns = useMemo(() => {
        let cols = columns ? Object.values(columns) : DEFAULT_COLUMNS
        if (actionComponents) {
            cols = [
                ...cols,
                {
                    title: 'Actions',
                    key: 'actions',
                    render: (salesOrder: SalesOrder) => {
                        return (<>{actionComponents.map((a) => a(salesOrder))}</>) as any
                    },
                },
            ]
        }
        return cols
    }, [columns])

    const getSalesOrders = async (resetPage = false) => {
        const response = await request({
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        })

        if (response.successful) {
            const { count, entities } = response.data
            resetPage && setPage(1)
            setSalesOrdersAmount(count)
            setSalesOrders(entities)
        } else {
            toastFailure(response.message)
        }
    }

    // this happens when dependencies change outside the table, e.g filters given in with getSalesOrdersDeps
    useEffect(() => {
        getSalesOrders(true)
    }, [getSalesOrdersDeps, rowsPerPage])

    // this happens when dependencies change outside the table, e.g filters given in with getSalesOrdersDeps
    useEffect(() => {
        getSalesOrders()
    }, [page])

    return (
        <Table
            rowKey="id"
            columns={tableColumns}
            dataSource={salesOrders}
            expandable={expandable}
            size="small"
            pagination={
                pagination
                    ? {
                          defaultPageSize: 10,
                          current: page,
                          onChange: pageChange,
                          total: salesOrdersAmount,
                      }
                    : false
            }
        />
    )
}
