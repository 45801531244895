import Box from '@mui/material/Box'
import {
    Button,
    CircularProgress,
    Dialog,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { Pallet, PalletProduct } from '../../../models/models'
import { useAppSelector } from '../../../store/hooks'
import { TextInput } from '../../ui/InputFields'
import { useEffect, useState } from 'react'
import { styled } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import {
    addTransfer,
    selectPalletTransfers,
    selectRemandingAmountOfProductsToTransfer,
} from '../../../store/warehouse-map-slice'
import { getPalletRequest } from '../../../api/warehouse-map'
import { toastFailure } from '../../../util/toast'
import { findHiu } from '../../../util/util'

const InfoRow = (props: { description: string; content: string; disableDivider?: boolean }) => {
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '10px',
    }))

    const RowContent = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: '6px',
    }))

    const RowDescriptionContainer = styled(Box)(() => ({
        paddingLeft: '10px',
        width: '45%',
    }))

    const RowContentContainer = styled(Box)(() => ({
        width: '55%',
    }))

    const { description, content, disableDivider } = props
    return (
        <Row>
            <RowContent>
                <RowDescriptionContainer>
                    <Typography sx={{ color: '#26262690' }}>{description}</Typography>
                </RowDescriptionContainer>
                <RowContentContainer>
                    <Typography>{content}</Typography>
                </RowContentContainer>
            </RowContent>
            {!disableDivider && <Divider />}
        </Row>
    )
}

const ModalFooter = (props: { onClick: () => void }) => {
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
    }))

    const AddButton = styled(Button)(() => ({
        padding: '0px',
        minWidth: '0px',
        margin: '10px 10px 5px 0px',
    }))

    return (
        <Row>
            <AddButton variant="outlined" size="small" onClick={props.onClick}>
                Add
            </AddButton>
        </Row>
    )
}

interface PalletInfoModalProps {
    isOpen: boolean
    onClose: () => void
    palletId: number
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Success = 'Success',
    Loading = 'Loading',
}

export const AssemblyTransferModal = ({ isOpen, onClose, palletId }: PalletInfoModalProps) => {
    const { assemblyToTransfer } = useAppSelector((state) => state.warehouseMap)
    const palletTransfers = useSelector(selectPalletTransfers)
    const [pallet, setPallet] = useState<Pallet>()
    const [palletProduct, setPalletProduct] = useState<PalletProduct>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)
    const [amount, setAmount] = useState<string>(
        palletTransfers.find((p) => p.id === palletId)?.amountToTransfer.toString() || ''
    )
    const remaindingAmountProducts = useSelector(selectRemandingAmountOfProductsToTransfer)
    const dispatch = useDispatch()

    if (!assemblyToTransfer) {
        return null
    }

    useEffect(() => {
        getPalletRequest(palletId).then((response) => {
            if (response.successful) {
                if (!response.data) {
                    toastFailure('Could not find the pallet')
                    setModalState(ModalState.NotFound)
                    onClose()
                }
                setModalState(ModalState.Success)
                setPallet(response.data)
                const palletProduct = response.data.palletProducts.filter((palletProduct) => {
                    return (
                        palletProduct.product.code ===
                        findHiu(assemblyToTransfer.builtItemCallOff.sopBuiltItemOrderline.builtItem)
                            ?.code
                    )
                })
                if (palletProduct.length !== 1) {
                    toastFailure(`Pallet don't have the product`)
                    onClose()
                }
                setPalletProduct(palletProduct[0])
            } else {
                if (response.status === 404) {
                    toastFailure('Could not find the pallet')
                    setModalState(ModalState.NotFound)
                    onClose()
                } else {
                    toastFailure('Something went wrong retrieving the pallet')
                    setModalState(ModalState.Error)
                    onClose()
                }
            }
        })
    }, [])

    return (
        <Dialog open={isOpen} onClose={onClose} style={{ zIndex: 100 }} fullWidth maxWidth={'sm'}>
            <Box style={{ width: '100%', padding: '20px' }}>
                {modalState === ModalState.Loading && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            margin: '20px',
                            width: '100%',
                            height: '500px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress size={75} style={{ color: 'rgb(53, 0, 160)' }} />
                    </Box>
                )}
                {modalState === ModalState.Error && <Box>Something went wrong</Box>}
                {modalState === ModalState.NotFound && (
                    <Box>Could not find the pallet with id {palletId}</Box>
                )}
                {pallet && palletProduct && modalState === ModalState.Success && (
                    <>
                        <Typography fontSize={20}>Transfer state</Typography>
                        <InfoRow
                            description="Amount on assembly"
                            content={assemblyToTransfer.amount.toString()}
                        />
                        <InfoRow
                            description="Currently selected amount"
                            content={(
                                assemblyToTransfer.amount - remaindingAmountProducts
                            ).toString()}
                        />
                        <InfoRow
                            description="Remainding to order"
                            content={remaindingAmountProducts.toString()}
                        />
                        <Typography fontSize={20}>Pallet</Typography>
                        <InfoRow
                            description="Document no"
                            content={`${
                                pallet?.delivery?.orderConfirmation.purchaseOrder?.documentNo ||
                                'None'
                            }`}
                        />
                        <InfoRow
                            description="Project"
                            content={`${pallet?.project?.name || 'None'}`}
                        />
                        <Typography fontSize={20}>HIU</Typography>
                        <Box>
                            <Table size="medium">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Quantity</TableCell>
                                        <TableCell>Amount to split</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={palletProduct.product?.code}>
                                        <TableCell>{palletProduct.product?.code}</TableCell>
                                        <TableCell>{palletProduct.amount}</TableCell>
                                        <TableCell>
                                            <TextInput
                                                type="number"
                                                value={amount}
                                                onChange={(value) => {
                                                    if (
                                                        Number(value) > remaindingAmountProducts ||
                                                        Number(value) > palletProduct.amount
                                                    ) {
                                                        setAmount(
                                                            Math.min(
                                                                palletProduct.amount,
                                                                remaindingAmountProducts
                                                            ).toString()
                                                        )
                                                    } else {
                                                        setAmount(value)
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                        <ModalFooter
                            onClick={() => {
                                dispatch(
                                    addTransfer({
                                        palletId: pallet.id,
                                        transfer: {
                                            amountToTransfer: Number(amount),
                                            palletProductId: palletProduct.id,
                                        },
                                    })
                                )
                                onClose()
                            }}
                        />
                    </>
                )}
            </Box>
        </Dialog>
    )
}
