import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'
import { UnauthorizedBom } from '../models/models'

const URL_PREFIX = '/unauthorized-bom'

export enum UnauthorizedBomRelation {
    Product = 'Product',
    Components = 'Components',
    BomVersion = 'BomVersion',
    BomGroup = 'BomGroup',
    BomVersionBuiltItem = 'BomVersionBuiltItem',
    RequestedByUser = 'RequestedByUser',
    RequestedForProject = 'RequestedForProject',
}

export interface GetByIdQueryParamDTO {
    relations?: UnauthorizedBomRelation[]
}

export interface GetManyQueryParamDTO {
    relations?: UnauthorizedBomRelation[]
    withCount?: boolean
    onlyUnauthorized?: boolean
    onlySubmittable?: boolean
    hasProduct?: boolean
    code?: string
    skip?: number
    limit?: number
}

export const getManyUnauthorizedBomsRequest = (
    params: GetManyQueryParamDTO
): Promise<Response<{ entities: UnauthorizedBom[]; count: number }>> =>
    backendConnection.request({
        url: `${URL_PREFIX}`,
        method: 'GET',
        params: params,
    })

export const getUnauthorizedBomByIdRequest = (
    id: number,
    params: GetByIdQueryParamDTO
): Promise<Response<UnauthorizedBom>> =>
    backendConnection.request({
        url: `${URL_PREFIX}/${id}`,
        method: 'GET',
        params,
    })

export const updateUnauthorizedBomNoteRequest = (
    id: number,
    note: string
): Promise<Response<UnauthorizedBom>> =>
    backendConnection.request({
        url: `${URL_PREFIX}/note/${id}`,
        method: 'PUT',
        params: { note },
    })

export interface StockItemDTO {
    id: number
    amount: number
}

export interface CreateUnauthorizedBomDto {
    createSpecificVersion?: number
    bomVersion?: number
    components: StockItemDTO[]
    productId: number
    bomGroupId: number
    code: string
    description: string
    name: string
    instructionLink?: string
    assemblyMinutes: number
}

export const createUnauthorizedBomRequest = (
    dto: CreateUnauthorizedBomDto
): Promise<Response<any>> =>
    backendConnection.request({
        url: `${URL_PREFIX}/bom`,
        method: 'POST',
        data: dto,
    })

export const uploadOtherBoms = (file: File): Promise<Response<any>> => {
    const formData = new FormData()
    formData.append('file', file)

    return backendConnection.request({
        url: `${URL_PREFIX}/upload`,
        method: 'POST',
        data: formData,
    })
}
