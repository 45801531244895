import { TextField } from '@mui/material'
import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { PurchaseOrder } from '../../../models/models'
import moment from 'moment'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

const FinancialDetailsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    margin: '20px',
})

const GeneralDetailsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    margin: '20px',
})

interface Props {
    purchaseOrder: PurchaseOrder
}
export default function DetailsTab({ purchaseOrder }: Props) {
    return (
        <Container>
            <GeneralDetailsContainer>
                <TextField
                    key={purchaseOrder.documentNo}
                    id="outlined-read-only-input"
                    label="Document No"
                    defaultValue={purchaseOrder.documentNo}
                    InputProps={{
                        readOnly: true,
                    }}
                    disabled={true}
                />

                <TextField
                    key={purchaseOrder?.supplierDispatchDate?.toString()}
                    id="outlined-read-only-input"
                    label="Supplier dispatch date"
                    defaultValue={moment(purchaseOrder.supplierDispatchDate).format('DD-MM-YYYY')}
                    InputProps={{
                        readOnly: true,
                    }}
                    disabled={true}
                />
                <TextField
                    key={purchaseOrder?.expectedDeliveryDate?.toString()}
                    id="outlined-read-only-input"
                    label="Expected delivered"
                    defaultValue={moment(purchaseOrder.expectedDeliveryDate).format('DD-MM-YYYY')}
                    InputProps={{
                        readOnly: true,
                    }}
                    disabled={true}
                />
                <TextField
                    key={purchaseOrder?.documentStatus}
                    id="outlined-read-only-input"
                    label="Document Status"
                    defaultValue={purchaseOrder.documentStatus}
                    InputProps={{
                        readOnly: true,
                    }}
                    disabled={true}
                />
                <TextField
                    key={purchaseOrder?.supplierName}
                    id="outlined-read-only-input"
                    label="Supplier"
                    defaultValue={purchaseOrder.supplierName}
                    InputProps={{
                        readOnly: true,
                    }}
                    disabled={true}
                />
                <TextField
                    key={purchaseOrder?.type}
                    id="outlined-read-only-input"
                    label="Type"
                    defaultValue={purchaseOrder.type}
                    InputProps={{
                        readOnly: true,
                    }}
                    disabled={true}
                />
            </GeneralDetailsContainer>
            <FinancialDetailsContainer>
                <TextField
                    key={purchaseOrder?.subtotalGoodsValue}
                    id="outlined-basic"
                    variant="outlined"
                    label="Total net value"
                    InputProps={{
                        readOnly: true,
                    }}
                    defaultValue={purchaseOrder.totalNetValue}
                />
                <TextField
                    key={purchaseOrder?.totalTaxValue}
                    id="outlined-basic"
                    variant="outlined"
                    label="Total tax value"
                    InputProps={{
                        readOnly: true,
                    }}
                    defaultValue={purchaseOrder.totalTaxValue}
                />
                <TextField
                    key={purchaseOrder?.totalGrossValue}
                    id="outlined-basic"
                    variant="outlined"
                    label="Total gross value"
                    InputProps={{
                        readOnly: true,
                    }}
                    defaultValue={purchaseOrder.totalGrossValue}
                />
                <TextField
                    key={purchaseOrder?.subtotalGoodsValue}
                    id="outlined-basic"
                    variant="outlined"
                    label="Subtotal goods value"
                    InputProps={{
                        readOnly: true,
                    }}
                    defaultValue={purchaseOrder.subtotalGoodsValue}
                />
            </FinancialDetailsContainer>
        </Container>
    )
}
