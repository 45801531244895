import { Button, Dialog, Typography, IconButton, Divider } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { createCustomerAddressRequest, DeliveryAddress } from '../../../../../api/customers'
import Error from './createCustomerModal/Error'
import Loading from './createCustomerModal/Loading'
import NotFound from './createCustomerModal/NotFound'
import Ready from './createCustomerModal/Ready'
import CloseIcon from '@mui/icons-material/Close'
import { TaxCode } from '../../../../../models/models'
import { getTaxCodes } from '../../../../../api/tax-codes'
import { toastFailure, toastSuccess } from '../../../../../util/toast'

interface CreateCustomerAddressModal {
    delivery?: DeliveryAddress
    closeModal: () => void
    isOpen: boolean
    onOk: (deliveryAddress: DeliveryAddress) => void
    customerId: number
}

enum ModalState {
    NotFound = 'NotFound',
    Error = 'Error',
    Ready = 'Ready',
    Loading = 'Loading',
}

type ModalStateKeys = keyof typeof ModalState
type ModalStateValues = typeof ModalState[ModalStateKeys]

const Content = (props: {
    state: ModalState
    address1: string
    setAddress1: (value: string) => void
    address2: string
    setAddress2: (value: string) => void
    address3: string
    setAddress3: (value: string) => void
    address4: string
    setAddress4: (value: string) => void
    postcode: string
    setPostcode: (value: string) => void
    county: string
    setCounty: (value: string) => void
    description: string
    setDescription: (value: string) => void
    taxCodeOptions: TaxCode[]
    selectedTaxCode: TaxCode | undefined
    setSelectedTaxCode: (value: TaxCode | undefined) => void
}) => {
    const {
        state,
        address1,
        address2,
        address3,
        address4,
        county,
        postcode,
        description,
        setAddress1,
        setAddress2,
        setAddress3,
        setAddress4,
        setCounty,
        setPostcode,
        setDescription,
        taxCodeOptions,
        selectedTaxCode,
        setSelectedTaxCode,
    } = props

    const ContentPicker: { [key in ModalStateValues]: JSX.Element } = {
        NotFound: <NotFound />,
        Error: <Error />,
        Ready: (
            <Ready
                address1={address1}
                setAddress1={setAddress1}
                address2={address2}
                setAddress2={setAddress2}
                address3={address3}
                setAddress3={setAddress3}
                address4={address4}
                setAddress4={setAddress4}
                postcode={postcode}
                setPostcode={setPostcode}
                county={county}
                setCounty={setCounty}
                taxCodeOptions={taxCodeOptions}
                selectedTaxCode={selectedTaxCode}
                setSelectedTaxCode={setSelectedTaxCode}
                description={description}
                setDescription={setDescription}
            />
        ),
        Loading: <Loading />,
    }

    return <Box sx={{ margin: '10px' }}>{ContentPicker[state]}</Box>
}

export default function CreateCustomerAddressModal(props: CreateCustomerAddressModal) {
    const { delivery, isOpen, closeModal, onOk, customerId } = props
    const [address1, setAddress1] = useState<string>(delivery?.address1 || '')
    const [address2, setAddress2] = useState<string>(delivery?.address2 || '')
    const [address3, setAddress3] = useState<string>(delivery?.address3 || '')
    const [address4, setAddress4] = useState<string>(delivery?.address4 || '')
    const [postcode, setPostcode] = useState<string>(delivery?.postcode || '')
    const [description, setDescription] = useState<string>('')
    const [county, setCounty] = useState<string>(delivery?.county || '')
    const [taxCodeOptions, setTaxCodeOptions] = useState<TaxCode[]>([])
    const [selectedTaxCode, setSelectedTaxCode] = useState<TaxCode | undefined>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Loading)

    const fetchTaxCodes = async () => {
        const response = await getTaxCodes()
        if (response.successful) {
            setTaxCodeOptions(response.data)
            setModalState(ModalState.Ready)
        } else {
            setModalState(ModalState.Error)
        }
    }
    useEffect(() => {
        fetchTaxCodes()
    }, [])

    const onCreate = async () => {
        if (!selectedTaxCode) {
            return
        }
        setModalState(ModalState.Loading)
        const response = await createCustomerAddressRequest({
            address1,
            address2,
            address3,
            address4,
            description,
            taxCodeId: selectedTaxCode.id,
            postcode,
            county,
            customerId,
        })
        if (response.successful) {
            setModalState(ModalState.Ready)
            toastSuccess('Created the customer address')
            closeModal()
            onOk(response.data)
        } else {
            setModalState(ModalState.Error)
            toastFailure(response.message)
        }
    }

    const disableSubmit =
        address1 === '' || postcode === '' || description === '' || !selectedTaxCode

    return (
        <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="sm">
            <Box
                sx={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: '15px 15px 10px 23px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Box>
                    <Typography fontSize={18}>Create Customer Address</Typography>
                </Box>
                <IconButton aria-label="close" onClick={closeModal}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider />
            <Content
                state={modalState}
                address1={address1}
                setAddress1={setAddress1}
                address2={address2}
                setAddress2={setAddress2}
                address3={address3}
                setAddress3={setAddress3}
                address4={address4}
                setAddress4={setAddress4}
                postcode={postcode}
                setPostcode={setPostcode}
                county={county}
                setCounty={setCounty}
                taxCodeOptions={taxCodeOptions}
                description={description}
                setDescription={setDescription}
                selectedTaxCode={selectedTaxCode}
                setSelectedTaxCode={setSelectedTaxCode}
            />
            <Divider />
            <Box sx={{ margin: '15px' }}>
                <Button disabled={disableSubmit} variant="outlined" onClick={onCreate}>
                    Create
                </Button>
            </Box>
        </Dialog>
    )
}
