import { Box, styled } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
    LOCAL_STORAGE_ASSEMBLY_WORKSHEET_ID_REFRESH_KEY,
    fetchAssemblers,
    fetchGeneralAssemblies,
    fetchWorksheets,
    getAssemblyStatus,
    initializeAssembly,
    initializeGeneralAssembly,
    setActiveAssembly,
    setActiveGeneralAssembly,
} from '../../store/assembly-worksheets-slice'
import PageHeader from '../ui/PageHeader'
import { useEffect, useState } from 'react'
import AssemblyWorksheetsGrid from './assembly/AssembliesGrid'
import _ from 'lodash'
import { Urls } from '../../util/urls'
import AssemblyStartModal from './assembly/modals/AssemblyStartModal'
import TaskBar from './tasks/TaskBar'
import { Assembly, GeneralAssembly, Schedule, TaskDepartment } from '../../models/models'
import { Filters } from './assembly/Filters'
import { getProductionSchedules } from '../../api/production-schedule'
import { toastFailure } from '../../util/toast'
import moment from 'moment'
import ActiveGeneralAssembly from './assembly/active-general-assembly/ActiveGeneralAssembly'
import { GetAssemblyWorksheetsDTO } from '../../api/assemblies'
import { ActiveAssembly } from './assembly/active-assembly/ActiveAssembly'
import { InspectBomModal } from '../products/modals/InspectBomModal'

const TaskBarContainer = styled('div')`
    margin-bottom: 20px;
`

export default function WorksheetsAssembly() {
    const dispatch = useAppDispatch()
    const {
        assemblies,
        activeAssembly,
        assemblers,
        filterAssemblerId,
        activeGeneralAssembly,
        generalAssemblies,
        filterStartDate,
        filterEndDate,
        filterIsOnHold,
        filterProjectIds,
        filterStatus,
        filterWarehouseId,
    } = useAppSelector((state) => state.assemblyWorksheets)
    const authenticatedUser = useAppSelector((state) => state.authentication.user)
    const { activeModal, modalEntityId } = useAppSelector((state) => state.products)

    const [schedules, setSchedules] = useState<Schedule[]>([])

    const loadProductionSchedule = () => {
        getProductionSchedules({
            from: moment.unix(filterStartDate).toDate(),
            to: moment.unix(filterEndDate).toDate(),
            warehouseId: filterWarehouseId,
        }).then((response) => {
            if (response.successful) {
                setSchedules(response.data)
            } else {
                toastFailure(response.message)
            }
        })
    }

    const loadWorksheets = () => {
        const filters: GetAssemblyWorksheetsDTO = {
            id: activeAssembly?.id,
            dateToBeCompletedByStart: activeAssembly?.id
                ? undefined
                : moment.unix(filterStartDate).toDate(),
            dateToBeCompletedByEnd: activeAssembly?.id
                ? undefined
                : moment.unix(filterEndDate).toDate(),
            showPrecedingUncompleted: true,
            isOnHold: filterIsOnHold ? true : undefined,
            assemblerId: filterAssemblerId ? Number(filterAssemblerId) : undefined,
            status: filterStatus,
            warehouseId: filterWarehouseId,
        }

        Promise.all([
            dispatch(fetchGeneralAssemblies(filters)),
            dispatch(fetchWorksheets(filters)),
            dispatch(fetchAssemblers()),
            loadProductionSchedule(),
        ]).then(() => {
            const activeAssemblyId = localStorage.getItem(
                LOCAL_STORAGE_ASSEMBLY_WORKSHEET_ID_REFRESH_KEY
            )
            if (activeAssemblyId) {
                dispatch(initializeAssembly(Number(activeAssemblyId)))
                localStorage.removeItem(LOCAL_STORAGE_ASSEMBLY_WORKSHEET_ID_REFRESH_KEY)
            }
        })
    }

    useEffect(() => {
        const filters: GetAssemblyWorksheetsDTO = {
            id: activeAssembly?.id,
            dateToBeCompletedByStart: activeAssembly?.id
                ? undefined
                : moment.unix(filterStartDate).toDate(),
            dateToBeCompletedByEnd: activeAssembly?.id
                ? undefined
                : moment.unix(filterEndDate).toDate(),

            showPrecedingUncompleted: true,
            isOnHold: filterIsOnHold ? true : undefined,
            assemblerId: filterAssemblerId ? Number(filterAssemblerId) : undefined,
            status: filterStatus,
        }
        loadWorksheets()
        const timeout = setTimeout(() => {
            dispatch(fetchWorksheets(filters))
        }, 60_000)

        return () => clearTimeout(timeout)
    }, [
        activeAssembly,
        activeGeneralAssembly,
        filterStartDate,
        filterEndDate,
        filterIsOnHold,
        filterStatus,
        filterWarehouseId,
        filterAssemblerId,
    ])

    if (!authenticatedUser) {
        return null
    }

    const filteredAssemblies = assemblies.filter(
        (assembly) =>
            (!filterStatus || filterStatus === getAssemblyStatus(assembly)) &&
            (filterProjectIds.length < 1 ||
                filterProjectIds.includes(assembly.callOff.projectId)) &&
            assembly.callOff.warehouseId === filterWarehouseId &&
            ((filterIsOnHold && assembly.isOnHold) || !filterIsOnHold)
    )

    const filteredGeneralAssemblies = generalAssemblies.filter(
        (generalAssembly: GeneralAssembly) =>
            generalAssembly.warehouseId === filterWarehouseId &&
            ((filterIsOnHold && generalAssembly.isOnHold) || !filterIsOnHold)
    )

    return (
        <Box>
            <PageHeader
                title={activeAssembly ? activeAssembly.callOff.project.name : 'Assembly Worksheets'}
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        name: 'Assembly Worksheets',
                        link: Urls.WorksheetsAssembly,
                        onClickAction: () => {
                            dispatch(setActiveAssembly(null))
                            dispatch(setActiveGeneralAssembly(null))
                        },
                    },
                    ...(activeAssembly
                        ? [
                              {
                                  name: activeAssembly.callOff.project.name,
                              },
                          ]
                        : []),
                    ...(activeGeneralAssembly
                        ? [
                              {
                                  name: activeGeneralAssembly.builtItem.bomCode,
                              },
                          ]
                        : []),
                ]}
            />
            <Box sx={{ margin: '20px' }}>
                {!activeAssembly && !activeGeneralAssembly && (
                    <>
                        <TaskBarContainer>
                            <TaskBar
                                department={TaskDepartment.ASSEMBLY}
                                warehouseId={filterWarehouseId}
                            />
                        </TaskBarContainer>
                        <Filters
                            user={authenticatedUser}
                            allWarehouses={_.uniqBy(
                                assemblies.map((assembly) => assembly.callOff.warehouse),
                                (warehouse) => warehouse.id
                            )}
                            allProjects={_.uniqBy(
                                assemblies.map((assembly) => assembly.callOff.project),
                                (project) => project.id
                            )}
                            assemblers={assemblers}
                        />
                    </>
                )}
                {activeAssembly && (
                    <ActiveAssembly user={authenticatedUser} activeAssembly={activeAssembly} />
                )}

                {activeGeneralAssembly && (
                    <ActiveGeneralAssembly
                        user={authenticatedUser}
                        generalAssembly={activeGeneralAssembly}
                        hideCompleteButton={false}
                        hideHeader={false}
                    />
                )}

                {!activeAssembly && !activeGeneralAssembly && (
                    <AssemblyWorksheetsGrid
                        schedules={schedules}
                        assemblies={filteredAssemblies}
                        generalAssemblies={filteredGeneralAssemblies}
                        assemblyCardOnClick={(assembly: Assembly) => {
                            dispatch(initializeAssembly(assembly.id))
                        }}
                        generalAssemblyCardOnClick={(generalAssembly: GeneralAssembly) => {
                            dispatch(initializeGeneralAssembly(generalAssembly.id))
                        }}
                    />
                )}
            </Box>
            <InspectBomModal />
            <AssemblyStartModal />
        </Box>
    )
}
