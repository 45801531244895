import styled from '@emotion/styled'
import { Box } from '@mui/system'
import { BinHolding, BomComponent, Product } from '../../../models/models'
import { Checkbox, Table } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { Button } from '@mui/material'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: '50px',
})

interface OtherContentProps {
    product: Product
    setIsSubmitDisabled?: (v: boolean) => void
}

const expandedRowRender = (bomComponent: BomComponent) => {
    const columns = [
        {
            title: 'Name',
            key: 'Name',
            render: (element: BinHolding) => {
                return element.name
            },
        },
        {
            title: 'Priority',
            key: 'Name',
            render: (element: BinHolding) => {
                return element.allocationPriority
            },
        },
        {
            title: 'Free stock quantity',
            key: 'price',
            render: (element: BinHolding) => {
                return element.freeStockAvailable
            },
        },
    ]

    const data = bomComponent.product.binHoldings
    return (
        <Table
            size="small"
            style={{ width: '100%' }}
            dataSource={data}
            columns={columns}
            pagination={false}
        />
    )
}

interface Checks {
    [otherBuiltItemStockItemId: number]: boolean
}
export const BomComponentsChecklist = (props: OtherContentProps) => {
    const { product, setIsSubmitDisabled } = props
    const [checks, setChecks] = useState<Checks>(
        product.bom.bomComponents.reduce<Checks>((acc, cur) => {
            acc[cur.id] = false
            return acc
        }, {})
    )

    const hasUnchecked = useMemo(() => {
        return Object.values(checks).some((checked) => !checked)
    }, [checks])

    useEffect(() => {
        if (!setIsSubmitDisabled) {
            return
        }
        if (hasUnchecked) {
            setIsSubmitDisabled(true)
            return
        }
        setIsSubmitDisabled(false)
    }, [checks, setIsSubmitDisabled])

    const columns = [
        {
            title: 'Name',
            render: (o: BomComponent) => {
                return o.product.code
            },
            key: 'name',
        },
        {
            title: 'Amount to pick',
            render: (o: BomComponent) => {
                return o.amount
            },
            key: 'quantityInStock',
        },
        {
            title: 'Free stock quantity',
            render: (o: BomComponent) => {
                return o.product.freeStockQuantity
            },
            key: 'quantityInStock2',
        },
        {
            title: 'Location',
            render: (o: BomComponent) => {
                return o?.product?.binHoldings.sort(
                    (b1, b2) => b1.allocationPriority - b2.allocationPriority
                )[0]?.name
            },

            key: 'location',
        },
        {
            title: 'Collected',
            render: (o: BomComponent) => {
                return (
                    <Checkbox
                        checked={checks[o.id]}
                        onClick={() => {
                            setChecks({ ...checks, ...{ [o.id]: !checks[o.id] } })
                        }}
                    />
                )
            },

            key: 'collected',
        },
    ]

    return (
        <Container>
            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                <Button
                    sx={{ width: '120px' }}
                    variant="outlined"
                    onClick={() => {
                        const clone = { ...checks }
                        for (const key of Object.keys(clone)) {
                            clone[Number(key)] = hasUnchecked
                        }
                        setChecks(clone)
                    }}
                >
                    {hasUnchecked ? 'Select All' : 'Unselect All'}
                </Button>
            </Box>
            <Table
                rowKey="id"
                dataSource={props.product.bom.bomComponents}
                columns={columns}
                expandable={{ expandedRowRender }}
                pagination={false}
            />
        </Container>
    )
}
