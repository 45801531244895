import { Box, styled } from '@mui/system'
import { Table } from 'antd'
import { Assembly, AssemblyLine } from '../../../models/models'
import moment from 'moment'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
})

interface Props {
    assembly: Assembly
}

const columns = [
    {
        title: 'Completed at',
        key: 'code',
        render: (element: AssemblyLine) => {
            return element.completedAt
                ? moment(element.completedAt).format('DD/MM/YYYY')
                : 'Not completed'
        },
    },
    {
        title: 'Status',
        key: 'code',
        render: (element: AssemblyLine) => {
            return element.completedAt ? 'Completed' : 'In Progress'
        },
    },
    {
        title: 'Returned at',
        key: 'code',
        render: (element: AssemblyLine) => {
            return element.returnedAt
                ? moment(element.returnedAt).format('DD/MM/YYYY')
                : 'Not returned'
        },
    },
]
export default function StatusTab(props: Props) {
    return (
        <Container>
            <Table dataSource={props?.assembly?.lines || []} columns={columns} pagination={false} />
        </Container>
    )
}
