import { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/system'
import { Button, Divider, Input, InputNumber, Modal, Table } from 'antd'
import { Tooltip, styled } from '@mui/material'
import '../../tables/table-styles.css'
import { EditOutlined, OrderedListOutlined } from '@ant-design/icons'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../store/hooks'
import { ActiveModal, closeModal } from '../../../store/products-slice'
import { Bom, BomGroup } from '../../../models/models'
import { BomRelation, UpdateBomDto, getBomById, updateBomRequest } from '../../../api/bom'
import { Response } from '../../../api/util/with-response-formatter-interceptor'
import { BomComponent } from '../../../models/models'
import { SearchInput } from '../../ui/SearchInput'
import { getBomGroupByIdRequest, getBomGroupsRequest } from '../../../api/bom-groups'
import { toastSuccess } from '../../../util/toast'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 50%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

enum ModalState {
    Error = 'Error',
    Ready = 'Ready',
}

export const UpdateBomModal = () => {
    const { activeModal, modalEntityId } = useSelector((state: RootState) => state.products)
    const [bom, setBom] = useState<Bom>()
    // setState for all the property fields
    const [assemblyMinutes, setAssemblyMinutes] = useState<number>()
    const [instructionLink, setInstructionLink] = useState<string>()
    const [bomGroup, setBomGroup] = useState<BomGroup>()
    const [modalState, setModalState] = useState<ModalState>(ModalState.Ready)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (activeModal === ActiveModal.BomUpdate && modalEntityId) {
            getBomById(modalEntityId, {
                relations: [BomRelation.BomComponents, BomRelation.BomGroup, BomRelation.Product],
            }).then((response: Response<Bom>) => {
                if (!response.successful) {
                    setModalState(ModalState.Error)
                } else {
                    setBom(response.data)
                    setAssemblyMinutes(response.data.assemblyMinutes)
                    setInstructionLink(response.data.instructionLink)
                    setBomGroup(response.data.group)
                    setModalState(ModalState.Ready)
                }
            })
        }
    }, [activeModal, modalEntityId])

    const resetState = () => {
        setBom(undefined)
        setModalState(ModalState.Ready)
    }

    const onClose = () => {
        resetState()
        dispatch(closeModal())
    }

    const isSubmitDisabled = useMemo(() => {
        if (!bom) {
            return true
        }
        return (
            bom.assemblyMinutes === assemblyMinutes &&
            bom.instructionLink === instructionLink &&
            bom.group?.id === bomGroup?.id
        )
    }, [assemblyMinutes, instructionLink, bomGroup])

    const onOk = async () => {
        if (!bom) {
            return
        }
        const dto: UpdateBomDto = {}

        if (bom.assemblyMinutes !== assemblyMinutes) {
            dto.assemblyMinutes = assemblyMinutes
        }

        if (bom.instructionLink !== instructionLink) {
            dto.instructionLink = instructionLink
        }

        if (bom.group?.id !== bomGroup?.id) {
            dto.groupId = bomGroup?.id
        }

        const response = await updateBomRequest(bom.id, dto)
        if (!response.successful) {
            return
        }

        toastSuccess('Bom updated successfully')
        resetState()
        dispatch(closeModal(JSON.stringify(response.data)))
    }

    return (
        <Modal
            open={activeModal === ActiveModal.BomUpdate}
            footer={null}
            onCancel={onClose}
            width={900}
        >
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.Ready && bom && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Update Bom</Title>
                    </Header>
                    <Divider />
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Code</Label>
                            <Input style={{ width: '100%' }} value={bom.bomCode} readOnly={true} />
                        </RowElement>
                        <RowElement>
                            <Label>Name</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={bom.product?.name}
                                readOnly={true}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Product Group</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={bom.product?.productGroupCode}
                                readOnly={true}
                            />
                        </RowElement>

                        <RowElement>
                            <Label>Bom Group</Label>
                            <SearchInput
                                style={{ width: '100%' }}
                                placeholder="Bom Group"
                                selectedValue={bomGroup?.id}
                                addonAfter={<EditOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
                                setSelectedValue={(id) => {
                                    if (!id) {
                                        return
                                    }
                                    getBomGroupByIdRequest(id, {}).then((r) => {
                                        if (r.successful) {
                                            setBomGroup(r.data)
                                        }
                                    })
                                }}
                                request={(search: string) =>
                                    getBomGroupsRequest({ name: search }).then((r) =>
                                        r.successful
                                            ? r.data.entities.map((bomGroup) => ({
                                                  label: bomGroup.name,
                                                  value: bomGroup.id,
                                              }))
                                            : [{ label: 'None', value: 2 }]
                                    )
                                }
                            />
                        </RowElement>
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Assembly Minutes</Label>
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0.1}
                                value={assemblyMinutes}
                                addonAfter={
                                    <Tooltip title="The assembly minutes is editable">
                                        <EditOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                                status={!assemblyMinutes || assemblyMinutes > 0 ? '' : 'error'}
                                onChange={(value: number | null) => {
                                    if (!value) {
                                        return
                                    }
                                    setAssemblyMinutes(value)
                                }}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Instructions</Label>
                            <Input
                                style={{ width: '100%' }}
                                suffix={
                                    <Tooltip title="The instruction link is editable">
                                        <EditOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                                value={instructionLink}
                                onChange={(e) => setInstructionLink(e.target.value)}
                            />
                        </RowElement>
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Description</Label>
                            <Input.TextArea
                                style={{ width: '100%', minHeight: 120 }}
                                readOnly={true}
                                value={bom.product?.description}
                            />
                        </RowElement>
                        <RowElement />
                    </InputRow>

                    <Label>Components</Label>
                    <Table
                        dataSource={bom.bomComponents}
                        columns={[
                            {
                                title: 'Code',
                                key: 'code',
                                render: (bomComponent: BomComponent) => {
                                    return bomComponent.product?.code
                                },
                            },
                            {
                                title: 'Name',
                                key: 'name',
                                render: (bomComponent: BomComponent) => {
                                    return bomComponent.product?.name
                                },
                            },
                            {
                                title: 'Amount',
                                key: 'amount',
                                render: (bomComponent: BomComponent) => {
                                    return bomComponent.amount
                                },
                            },
                        ]}
                        pagination={false}
                    />
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                        <Button type={'primary'} disabled={isSubmitDisabled} onClick={onOk}>
                            Update
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    )
}
