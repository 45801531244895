import { Button, Checkbox, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import { useEffect, useState } from 'react'
import { GetStockItemQueryParams, getStockItemsRequest } from '../../api/stock-item'
import { Product, Warehouse } from '../../models/models'
import { Modal, Input, Table } from 'antd'
import { isTrackedByWarehouse } from './util'

const InputsContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-evenly',
    justifyContent: 'center',
}))

const Container = styled(Box)(() => ({
    margin: '10px 20px 10px 20px',
}))
const FooterContainer = styled(Box)(() => ({
    margin: '10px 20px 10px 20px',
}))

const FooterButton = styled(Button)(() => ({
    margin: '10px',
}))

const Text = styled(Typography)(() => ({}))

interface Props {
    warehouse: Warehouse
    onOk: (changes: Changes) => void
    onCancel: () => void
    visible: boolean
}

/**
 * key stock-item id
 * value warehouse ids
 */
export interface Changes {
    [key: number]: number[]
}

enum View {
    Regular = 'Regular',
    Changes = 'Changes',
}

export default function AddStockTrackingModal(props: Props) {
    const [stockItems, setStockItems] = useState<Product[]>([])
    const [code, setCode] = useState<string>('')
    const [name, setName] = useState<string>('')
    const rowsPerPageOptions = [10, 20]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20
    const [page, setPage] = useState<number>(1)
    const [view, setView] = useState<View>(View.Regular)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)
    const [totalRows, setTotalRows] = useState<number>(0)
    const [changes, setChanges] = useState<Changes>({})
    const { warehouse, onOk, onCancel, visible } = props

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }
    useEffect(() => {
        retrieveAndSetStockItems()
    }, [code, name, rowsPerPage, page, view])

    useEffect(() => {
        if (view === View.Changes) {
            retrieveAndSetStockItems()
        }
    }, [changes])

    const hasBeenChanged = (stockItem: Product) => {
        return changes[stockItem.id] !== undefined
    }

    const change = (stockItem: Product) => {
        const tempChanges = { ...changes }
        const tempStockItemWarehousesIds = [...stockItem.stockTrackingWarehouses].map((w) => w.id)
        if (tempStockItemWarehousesIds.includes(warehouse.id)) {
            tempChanges[stockItem.id] = tempStockItemWarehousesIds.filter(
                (id) => id !== warehouse.id
            )
        } else {
            tempStockItemWarehousesIds.push(warehouse.id)
            tempChanges[stockItem.id] = tempStockItemWarehousesIds
        }
        setChanges(tempChanges)
    }

    const removeChange = (stockItem: Product) => {
        const tempChanges = { ...changes }
        delete tempChanges[stockItem.id]
        setChanges(tempChanges)
    }

    const changeIsTracked = async (stockItem: Product) => {
        if (hasBeenChanged(stockItem)) {
            removeChange(stockItem)
        } else {
            change(stockItem)
        }
    }

    const retrieveAndSetStockItems = async () => {
        const config: GetStockItemQueryParams = {
            hasSageStockWithWarehouseIds:
                warehouse.sageWarehouses?.map((w) => w.sageWarehouseId) || [],
            likeCode: code,
            likeName: name,
            withCount: true,
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        }
        if (view === View.Changes) {
            if (Object.keys(changes).length === 0) {
                return setStockItems([])
            }
            config.ids = Object.keys(changes).map((s): number => Number(s))
        }
        const response = await getStockItemsRequest(config)
        if (response.successful) {
            setStockItems(response.data.entities)
            setTotalRows(response.data.count || 0)
        }
    }

    const columns = [
        {
            title: 'Code',
            key: 'code',
            dataIndex: 'code',
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: 'Tracked',
            key: 'tracked',
            render: (stockItem: Product) => {
                return (
                    <Checkbox
                        checked={isTrackedByWarehouse(stockItem, warehouse, changes)}
                        onChange={() => {
                            changeIsTracked(stockItem)
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    ></Checkbox>
                )
            },
        },
    ]

    return (
        <Modal
            title={`Add tracked stock for ${warehouse.name}`}
            visible={visible}
            width={1100}
            onCancel={() => {
                onCancel()
            }}
            footer={null}
        >
            <Container>
                <InputsContainer>
                    <Input
                        prefix={'Code: '}
                        size="middle"
                        style={{
                            padding: 10,
                            borderRadius: '10px',
                            width: '100%',
                            marginRight: 10,
                        }}
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                    <Input
                        prefix={'Name: '}
                        size="middle"
                        style={{
                            padding: 10,
                            borderRadius: '10px',
                            width: '100%',
                            marginRight: 10,
                        }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderRadius: '10px',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Checkbox
                            onChange={() => {
                                if (view === View.Changes) {
                                    setView(View.Regular)
                                } else {
                                    setView(View.Changes)
                                }
                            }}
                            //odd, but required for controlled MUI checkboxes
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={view === View.Changes}
                        ></Checkbox>
                        <Text>Show changes</Text>
                    </Box>
                </InputsContainer>
                <Table
                    dataSource={stockItems}
                    columns={columns}
                    pagination={{
                        defaultPageSize: 10,
                        current: page,
                        onChange: pageChange,
                        total: totalRows,
                    }}
                />
                <FooterContainer>
                    <FooterButton
                        variant="outlined"
                        onClick={() => {
                            onCancel()
                        }}
                    >
                        Close
                    </FooterButton>
                    <FooterButton
                        variant="outlined"
                        disabled={Object.keys(changes).length === 0}
                        onClick={() => {
                            onOk(changes)
                        }}
                    >
                        {Object.keys(changes).length === 0
                            ? 'OK'
                            : `Change ${Object.keys(changes).length} 
                            ${Object.keys(changes).length > 1 ? 'items' : 'item'}`}
                    </FooterButton>
                </FooterContainer>
            </Container>
        </Modal>
    )
}
